import { SnapInput, SnapSelectMenu } from "suit";
import { counterpartyToAdd } from ".";
import { useSpendGetInstitutionByRoutingNumberLazyQuery } from "graphql/generated";
import { useEffect, useState } from "react";
import { counterpartyToAddErrors } from "types/errors";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";

type AddCounterpartyProps = {
  counterpartyToAdd: counterpartyToAdd;
  counterpartyToAddErrors: counterpartyToAddErrors;
  setCounterpartyToAddErrors: React.Dispatch<
    React.SetStateAction<counterpartyToAddErrors>
  >;
  accountNumberRef: (node: HTMLDivElement | null) => void;
  confirmAccountNumberRef: (node: HTMLDivElement | null) => void;
  entityOptions: SnapSelectMenuOption[];
  accountTypeOptions: SnapSelectMenuOption[];
};

function AddCounterparty({
  counterpartyToAdd,
  counterpartyToAddErrors,
  setCounterpartyToAddErrors,
  accountNumberRef,
  confirmAccountNumberRef,
  entityOptions,
  accountTypeOptions,
}: AddCounterpartyProps) {
  const [instName, setInstName] = useState("");

  const [getInst, { loading, data }] =
    useSpendGetInstitutionByRoutingNumberLazyQuery();

  useEffect(() => {
    if (!loading) {
      if (data && data.spendGetInstitutionByRoutingNumber) {
        counterpartyToAddErrors.routingNumberError = false;
        setInstName(data.spendGetInstitutionByRoutingNumber.name);
      }
    }
    // eslint-disable-next-line
  }, [loading, data]);

  return (
    <div className="flex flex-col my-6 mx-16">
      <SnapInput
        _id={"Payee Name"}
        label="Payee Name"
        error={counterpartyToAddErrors.nameError}
        onSnap-input-change={(e) => {
          counterpartyToAdd.name = e.detail.target.value;
          setCounterpartyToAddErrors({
            ...counterpartyToAddErrors,
            nameError: false,
          });
        }}
      />
      <SnapInput
        _id={"Routing Number"}
        label="Routing Number"
        className="mt-4"
        maxlength="9"
        error={counterpartyToAddErrors.routingNumberError}
        onSnap-input-change={(e) => {
          counterpartyToAdd.routingNumber = e.detail.target.value;
          setCounterpartyToAddErrors({
            ...counterpartyToAddErrors,
            routingNumberError: false,
          });
          if (e.detail.target.value.length === 9) {
            getInst({
              variables: {
                routingNumber: e.detail.target.value,
              },
            });
          }
        }}
      />
      <SnapInput
        _id={"Bank"}
        label="Bank"
        className="mt-4"
        disabled
        value={instName}
        icon="lock-closed-solid"
      />
      <p className="mt-4 text-sm font-medium text-gray-700">Account Number</p>
      <div
        ref={accountNumberRef}
        id="ACCOUNT_NUMBER_INPUT"
        className={`h-10 border-[2px] rounded-[10px] pl-3 border-gray-200`}
      ></div>
      <p className="mt-4 text-sm font-medium text-gray-700">
        Confirm Account Number
      </p>
      <div
        ref={confirmAccountNumberRef}
        id="CONFIRM_ACCOUNT_NUMBER_INPUT_NAME"
        className={`h-10 border-[2px] rounded-[10px] pl-3 border-gray-200`}
      ></div>
      <SnapSelectMenu
        label="Entity Type"
        className="mt-4"
        selectMenuOptions={entityOptions}
        error={counterpartyToAddErrors.entityTypeError}
        onSnap-select-menu-updated={(e) => {
          const selectedItem = e.detail.find((item) => item.selected);
          counterpartyToAdd.entityType = selectedItem?.name!;
          setCounterpartyToAddErrors({
            ...counterpartyToAddErrors,
            entityTypeError: false,
          });
        }}
      />
      <SnapSelectMenu
        label="Account Type"
        className="mt-4"
        selectMenuOptions={accountTypeOptions}
        error={counterpartyToAddErrors.accountTypeError}
        onSnap-select-menu-updated={(e) => {
          const selectedItem = e.detail.find((item) => item.selected);
          counterpartyToAdd.accountType = selectedItem?.name!;
          setCounterpartyToAddErrors({
            ...counterpartyToAddErrors,
            accountTypeError: false,
          });
        }}
      />
    </div>
  );
}

export default AddCounterparty;
