import { useLazyQuery, useMutation } from "@apollo/client";
import DisplayContext from "context/display-context";
import GroupContext from "context/group-context";
import UserContext from "context/user-context";
import { SpendGroup } from "graphql/generated";
import { UPDATE_SPEND_SEASON } from "graphql/mutations/group";
import { GET_GROUPS_FILTERED } from "graphql/queries/group";
import { FormatMoney } from "helpers/format-money";
import {
  BudgetSummaryConvertDataToUiModels,
  getHexColor,
} from "helpers/summary-related";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import ColorBar from "shared-components/color-bar";
import Divider from "shared-components/divider";
import BudgetItemDetails from "shared-components/modal/budget-item-details";
import { SnapLabeledToggle } from "suit";
import {
  BudgetSummaryRecord,
  BudgetSummaryResponse,
  BudgetTotalValue,
  UnreconciledRecord,
  budgetItemDetails,
} from "types/budget-summary";
import { SpendPermissions } from "types/roles-permissions";
import { BudgetSummaryRowLG } from "./budget-summary-row-lg";
import { BudgetSummaryRowSM } from "./budget-summary-row-sm";
import ToastContext from "context/toast-context";
import SeasonContext from "context/season-context";

type BudgetSummaryProps = {
  budgetSummary: BudgetSummaryResponse | undefined;
  ActiveGroup?: SpendGroup | undefined;
  type: "program" | "group";
  canEditBudget?: boolean;
};

function Summary({
  budgetSummary,
  ActiveGroup,
  type,
  canEditBudget,
}: BudgetSummaryProps) {
  const { isOpen: isBudgetItemOpen, toggle: budgetItemToggle } = useModal();
  const Display = useContext(DisplayContext);
  const groupContext = useContext(GroupContext);
  const Season = useContext(SeasonContext);
  const toast = useContext(ToastContext);
  const [
    updateSpendBudgetSummary,
    { loading: updateLoading, data: loadingData },
  ] = useMutation(UPDATE_SPEND_SEASON);
  const [getGroupId, { loading: loadingGroup, data: groupData }] =
    useLazyQuery(GET_GROUPS_FILTERED);

  const [unreconciledData, setUnreconciledData] = useState<UnreconciledRecord>({
    credit: { count: 0, total: 0 },
    debit: { count: 0, total: 0 },
  });
  const [incomeBudgetData, setIncomeBudgetData] = useState<
    BudgetSummaryRecord[] | []
  >([]);
  const [expenseBudgetData, setExpenseBudgetData] = useState<
    BudgetSummaryRecord[] | []
  >([]);
  const [incomeBudgetTotal, setIncomeBudgetTotal] = useState<BudgetTotalValue>({
    budgetTotal: 0,
    reconciledTotal: 0,
    percent: 0,
  });
  const [expenseBudgetTotal, setExpenseBudgetTotal] =
    useState<BudgetTotalValue>({
      budgetTotal: 0,
      reconciledTotal: 0,
      percent: 0,
    });

  const [summaryShare, setSummaryShare] = useState(false);
  const canShareWithParent =
    useContext(UserContext)?.checkSpendPermission(
      SpendPermissions.groupBudgetUpdate
    ) ?? false;
  const [selectedBudgetItem, setSelectedBudgetItem] =
    useState<budgetItemDetails>({
      budgetId: "",
      category: "",
      type: "",
      budgetItemName: "",
      dueDate: "",
      budgetAmount: 0,
      reconciledAmount: 0,
    });

  useEffect(() => {
    if (!loadingGroup && groupData && groupData.spendGroupsFiltered) {
      toast?.setToast({
        message: `Budget summary ${
          groupData.spendGroupsFiltered.groups.at(0).latestSeason.isBudgetShared
            ? "shared"
            : "unshared"
        } with parents`,
        type: "success",
      });
      groupContext?.setAndStoreActiveGroup(
        groupData.spendGroupsFiltered.groups.at(0)
      );
    }
    // eslint-disable-next-line
  }, [loadingGroup, groupData]);

  useEffect(() => {
    if (loadingData && loadingData.spendSeasonUpdate) {
      getGroupId({
        variables: {
          where: {
            ids: [ActiveGroup?.id],
          },
        },
        fetchPolicy: "network-only",
      });
    }
    // eslint-disable-next-line
  }, [updateLoading, loadingData]);

  useEffect(() => {
    if (Season?.selectedSeason) {
      setSummaryShare(Season.selectedSeason.isBudgetShared ?? false);
    }
    if (budgetSummary?.summaryByCategory) {
      BudgetSummaryConvertDataToUiModels(
        budgetSummary,
        setIncomeBudgetData,
        setExpenseBudgetData,
        setIncomeBudgetTotal,
        setExpenseBudgetTotal,
        setUnreconciledData
      );
    }
  }, [budgetSummary, Season?.selectedSeason]);

  const handleSetModal = (selectedItem: budgetItemDetails) => {
    setSelectedBudgetItem(selectedItem);
    budgetItemToggle();
  };

  return (
    <div className="lg:mt-6 mt-4">
      <div className="lg:flex justify-end">
        {ActiveGroup && canEditBudget && !groupContext?.isArchived && (
          <SnapLabeledToggle
            disabled={!canShareWithParent}
            label="Share Summary With Parents"
            checked={summaryShare}
            onClick={() => {
              let flip = !summaryShare;
              updateSpendBudgetSummary({
                variables: {
                  id: Season?.selectedSeason?.id,
                  input: {
                    endDateAt: Season?.selectedSeason?.endDateAt,
                    startDateAt: Season?.selectedSeason?.startDateAt,
                    name: Season?.selectedSeason?.name,
                    isLinkEnabled: Season?.selectedSeason?.isLinkEnabled,
                    isBudgetShared: flip,
                    groupId: ActiveGroup?.id,
                  },
                },
                fetchPolicy: "network-only",
              });
              setSummaryShare(flip);
            }}
            aria-labelledby="Share with parents"
          />
        )}
      </div>
      <div>
        <h1 className="lg:text-lg font-medium lg:font-semibold text-gray-600 lg:tetx-gray-800">
          Income Summary
        </h1>
        {incomeBudgetData &&
          incomeBudgetData.map((iBudgetDat) => {
            let percent =
              (iBudgetDat.budgetReconciled / iBudgetDat.budgetAmount) * 100;
            let color = getHexColor(
              iBudgetDat.budgetAmount,
              iBudgetDat.budgetReconciled,
              false,
              groupContext?.isArchived!
            );
            return (
              <div key={iBudgetDat.name}>
                {Display?.isDesktop ? (
                  <BudgetSummaryRowLG
                    budgetData={iBudgetDat}
                    percent={percent}
                    color={color}
                    handleSetModal={handleSetModal}
                    type="Income"
                    isArchived={groupContext?.isArchived!}
                  />
                ) : (
                  <BudgetSummaryRowSM
                    budgetData={iBudgetDat}
                    percent={percent}
                    color={color}
                    handleSetModal={handleSetModal}
                    type="Income"
                    isArchived={groupContext?.isArchived!}
                  />
                )}
              </div>
            );
          })}
        <div className="border px-4 py-2 rounded-lg mt-4 lg:flex bg-gray-100 border-gray-200 hidden">
          <p className="font-semibold flex items-center mr-auto">
            Income Total
          </p>
          <div className="border-r pr-3">
            <p className="text-xs font-bold text-gray-500">Budgeted</p>
            <p>{FormatMoney(incomeBudgetTotal.budgetTotal)}</p>
          </div>
          <div className="border-r  pl-3 pr-3">
            <p className="text-xs font-bold text-gray-500">Reconciled</p>
            <p>{FormatMoney(incomeBudgetTotal.reconciledTotal)}</p>
          </div>
          <div className="w-72 mr-9 ml-3 flex items-center pr-3">
            <ColorBar
              percent={incomeBudgetTotal.percent}
              colorHex={getHexColor(
                incomeBudgetTotal.budgetTotal,
                incomeBudgetTotal.reconciledTotal,
                false,
                groupContext?.isArchived!
              )}
            />
          </div>
        </div>
        <div className="border rounded-lg p-4 mt-4 bg-gray-100 border-gray-200 lg:hidden">
          <p className="font-semibold mb-1">Income Total</p>
          <ColorBar
            percent={incomeBudgetTotal.percent}
            colorHex={getHexColor(
              incomeBudgetTotal.budgetTotal,
              incomeBudgetTotal.reconciledTotal,
              false,
              groupContext?.isArchived!
            )}
          />
          <Divider isVisibleOnMobile />
          <div className="mt-4 flex justify-between">
            <p className="text-xs font-bold text-gray-500">Budget</p>
            <p>{FormatMoney(incomeBudgetTotal.budgetTotal)}</p>
          </div>
          <div className="mt-4 flex justify-between">
            <p className="text-xs font-bold text-gray-500">Reconciled</p>
            <p>{FormatMoney(incomeBudgetTotal.reconciledTotal)}</p>
          </div>
        </div>
        <Divider isVisibleOnMobile />
        <h1 className="lg:text-lg font-medium lg:font-semibold text-gray-600 lg:tetx-gray-800 mt-4">
          Expense Summary
        </h1>
        {expenseBudgetData &&
          expenseBudgetData.map((eBudgetDat) => {
            let percent =
              (eBudgetDat.budgetReconciled / eBudgetDat.budgetAmount) * 100;
            let color = getHexColor(
              eBudgetDat.budgetAmount,
              eBudgetDat.budgetReconciled,
              false,
              groupContext?.isArchived!
            );
            return (
              <div key={eBudgetDat.name}>
                {Display?.isDesktop ? (
                  <BudgetSummaryRowLG
                    budgetData={eBudgetDat}
                    percent={percent}
                    color={color}
                    handleSetModal={handleSetModal}
                    type="Expense"
                    isArchived={groupContext?.isArchived!}
                  />
                ) : (
                  <BudgetSummaryRowSM
                    budgetData={eBudgetDat}
                    percent={percent}
                    color={color}
                    handleSetModal={handleSetModal}
                    type="Expense"
                    isArchived={groupContext?.isArchived!}
                  />
                )}
              </div>
            );
          })}
        <div className="border px-4 py-2 rounded-lg mt-4 lg:flex bg-gray-100 border-gray-200 hidden">
          <p className="font-semibold flex items-center mr-auto">
            Expense Total
          </p>
          <div className="border-r pr-3">
            <p className="text-xs font-bold text-gray-500">Budgeted</p>
            <p>{FormatMoney(expenseBudgetTotal.budgetTotal)}</p>
          </div>
          <div className="border-r  pl-3 pr-3">
            <p className="text-xs font-bold text-gray-500">Reconciled</p>
            <p>{FormatMoney(expenseBudgetTotal.reconciledTotal)}</p>
          </div>
          <div className="w-72 mr-9 ml-3 flex items-center pr-3">
            <ColorBar
              percent={expenseBudgetTotal.percent}
              colorHex={getHexColor(
                expenseBudgetTotal.budgetTotal,
                expenseBudgetTotal.reconciledTotal,
                false,
                groupContext?.isArchived!
              )}
            />
          </div>
        </div>
        <div className="border rounded-lg p-4 mt-4 bg-gray-100 border-gray-200 lg:hidden">
          <p className="font-semibold mb-1">Expense Total</p>
          <ColorBar
            percent={expenseBudgetTotal.percent}
            colorHex={getHexColor(
              expenseBudgetTotal.budgetTotal,
              expenseBudgetTotal.reconciledTotal,
              false,
              groupContext?.isArchived!
            )}
          />
          <Divider isVisibleOnMobile />
          <div className="mt-4 flex justify-between">
            <p className="text-xs font-bold text-gray-500">Budget</p>
            <p>{FormatMoney(expenseBudgetTotal.budgetTotal)}</p>
          </div>
          <div className="mt-4 flex justify-between">
            <p className="text-xs font-bold text-gray-500">Reconciled</p>
            <p>{FormatMoney(expenseBudgetTotal.reconciledTotal)}</p>
          </div>
        </div>
        <Divider isVisibleOnMobile />
        <div className="border px-4 py-2 rounded-lg mt-4 lg:flex bg-gray-100 border-gray-200 hidden">
          <p className="font-semibold flex items-center mr-auto">Net Total</p>
          <div className="border-r pr-3">
            <p className="text-xs font-bold text-gray-500">Budgeted</p>
            <p>
              {FormatMoney(
                incomeBudgetTotal.budgetTotal - expenseBudgetTotal.budgetTotal
              )}
            </p>
          </div>
          <div className="pl-3 pr-3">
            <p className="text-xs font-bold text-gray-500">Reconciled</p>
            <p>
              {FormatMoney(
                incomeBudgetTotal.reconciledTotal -
                  expenseBudgetTotal.reconciledTotal
              )}
            </p>
          </div>
          <div className="w-72 mr-9 ml-3 flex items-center pr-3"></div>
        </div>
        <div className="border rounded-lg p-4 mt-4 bg-gray-100 border-gray-200 lg:hidden">
          <p className="font-semibold mb-1">Net Total</p>
          <Divider isVisibleOnMobile />
          <div className="mt-4 flex justify-between">
            <p className="text-xs font-bold text-gray-500">Budget</p>
            <p>
              {FormatMoney(
                incomeBudgetTotal.budgetTotal - expenseBudgetTotal.budgetTotal
              )}
            </p>
          </div>
          <div className="mt-4 flex justify-between">
            <p className="text-xs font-bold text-gray-500">Reconciled</p>
            <p>
              {FormatMoney(
                incomeBudgetTotal.reconciledTotal -
                  expenseBudgetTotal.reconciledTotal
              )}
            </p>
          </div>
        </div>
        <Divider isVisibleOnMobile />
        <div className="border px-4 py-2 rounded-lg mt-4 lg:flex hidden">
          <p className="font-semibold flex items-center mr-auto">
            Unreconciled
          </p>
          <div className="border-r pr-3 mr-32">
            <p className="text-xs font-bold text-gray-500">
              Debits ({unreconciledData.debit.count})
            </p>
            <p>{FormatMoney(unreconciledData.debit.total)}</p>
          </div>
          <div className="mr-3">
            <p className="text-xs font-bold text-gray-500">
              Credits ({unreconciledData.credit.count})
            </p>
            <p>{FormatMoney(unreconciledData.credit.total)}</p>
          </div>
        </div>
        <div className="border rounded-lg p-4 mt-4 bg-white border-gray-200 lg:hidden">
          <p className="font-semibold mb-1">Unreconciled</p>
          <Divider isVisibleOnMobile />
          <div className="mt-4 flex justify-between">
            <p className="text-xs font-bold text-gray-500">
              Debits ({unreconciledData.debit.count})
            </p>
            <p>{FormatMoney(unreconciledData.debit.total)}</p>
          </div>
          <div className="mt-4 flex justify-between">
            <p className="text-xs font-bold text-gray-500">
              Credits ({unreconciledData.credit.count})
            </p>
            <p>{FormatMoney(unreconciledData.credit.total)}</p>
          </div>
        </div>

        {/* RESPONSIVE SM- */}
        {/* <div className="md:hidden pt-4 pb-10">
          <div className="text-base text-gray-600">Income Summary</div>
          {incomeBudgetData &&
            incomeBudgetData.map((b: BudgetSummaryRecord, idx: number) => {
              let percent = Math.floor(
                (b.budgetReconciled / b.budgetAmount) * 100
              );
              return (
                <TableRowSm
                  key={idx}
                  name={b.name}
                  budgetAmount={b.budgetAmount}
                  budgetReconciled={b.budgetReconciled}
                  percent={percent}
                  expandedData={b.expandedData}
                  handleSetModal={handleSetModal}
                  type="Income"
                />
              );
            })}

          <div className="mt-4">
            <div className="text-base text-gray-600">Expense Summary</div>
            {expenseBudgetData &&
              expenseBudgetData.map((b: any, idx: number) => {
                let percent = Math.floor(
                  (b.budgetReconciled / b.budgetAmount) * 100
                );
                return (
                  <TableRowSm
                    key={idx}
                    name={b.name}
                    budgetAmount={b.budgetAmount}
                    budgetReconciled={b.budgetReconciled}
                    percent={percent}
                    expandedData={b.expandedData}
                    handleSetModal={handleSetModal}
                    type="Expense"
                  />
                );
              })}
          </div>
          <div className="text-base text-gray-600 mt-4">Net Total</div>
          <div className="flex flex-row justify-between mt-4">
            <div className="font-bold text-gray-500 text-xs">
              Total Budgetted
            </div>
            <div
              className="text-gray-800 text-right font-semibold"
              style={{ backgroundColor: "#FFF" }}
            >
              {FormatMoney(
                incomeBudgetTotal.budgetTotal - expenseBudgetTotal.budgetTotal
              )}
            </div>
          </div>
          <div className="flex flex-row justify-between mt-4">
            <div className="font-bold text-gray-500 text-xs">
              Total Reconciled
            </div>
            <div
              className="text-gray-800 text-right font-semibold"
              style={{ backgroundColor: "#FFF" }}
            >
              {FormatMoney(
                incomeBudgetTotal.reconciledTotal -
                  expenseBudgetTotal.reconciledTotal
              )}
            </div>
          </div>
          <hr className="my-6" />
          <div className="mt-4">
            <div className="bg-white px-4 pt-4 mt-4 pb-12 rounded-lg border">
              <div className="flex flex-row justify-between">
                <div className="flex font-bold text-gray-500">
                  Credits
                  <SnapBadge className="ml-2" color="blue" size="sm">
                    8
                  </SnapBadge>
                </div>
                <div
                  className="text-gray-700 text-right"
                  style={{ backgroundColor: "#FFF" }}
                >
                  {FormatMoney(70000)}
                </div>
              </div>
              <hr className="mt-4" />
              <div
                className="float-right my-3"
                style={{ backgroundColor: "#FFF" }}
              >
                <SnapLink size="base" color="#2563EB">
                  See Report
                </SnapLink>
              </div>
            </div>
          </div>
        </div> */}

        {/* RESPONSIVE MD+ */}
        <div className="hidden md:block">
          {/* <SnapTable>
            <table className="celled table-auto">
              <thead>
                <tr>
                  <th className="text-base whitespace-nowrap w-[23%] capitalize">
                    Income Summary
                  </th>
                  <th className="text-right w-[15%]">Budgetted</th>
                  <th className="text-right w-[15%]">Reconciled</th>
                  <th className="w-[35%]"></th>
                  <th className="w-[12%]"></th>
                </tr>
              </thead>
              <tbody>
                {incomeBudgetData &&
                  incomeBudgetData.map(
                    (b: BudgetSummaryRecord, idx: number) => {
                      let percent = Math.floor(
                        (b.budgetReconciled / b.budgetAmount) * 100
                      );
                      return (
                        <TableRowLg
                          key={idx}
                          name={b.name}
                          budgetAmount={b.budgetAmount}
                          budgetReconciled={b.budgetReconciled}
                          percent={percent}
                          expandedData={b.expandedData}
                          handleSetModal={handleSetModal}
                          type="Income"
                        />
                      );
                    }
                  )}
                <tr key={"expenseSumRow"} className="h-12 bg-[#F1F5F9]">
                  <td className="font-bold">Income Total</td>
                  <td className="text-gray-700 text-right">
                    {FormatMoney(incomeBudgetTotal.budgetTotal)}
                  </td>
                  <td className="text-gray-700 text-right">
                    {FormatMoney(incomeBudgetTotal.reconciledTotal)}
                  </td>
                  <td className="flex-row">
                    <ColorBar
                      percent={incomeBudgetTotal.percent}
                      colorHex={getHexColor(incomeBudgetTotal.budgetTotal, incomeBudgetTotal.reconciledTotal, false)}
                    />
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </SnapTable> */}
          {/* Modal Expense Summary */}
          {/* <SnapTable>
            <table className="celled table-fixed">
              <thead>
                <tr>
                  <th className="text-base whitespace-nowrap w-[23%] capitalize">
                    Expense Summary
                  </th>
                  <th className="text-right w-[15%]">Budgetted</th>
                  <th className="text-right w-[15%]">Reconciled</th>
                  <th className="w-[35%]"></th>
                  <th className="w-[12%]"></th>
                </tr>
              </thead>
              <tbody>
                {expenseBudgetData &&
                  expenseBudgetData.map((b: any, idx: number) => {
                    let percent = Math.floor(
                      (b.budgetReconciled / b.budgetAmount) * 100
                    );
                    return (
                      <TableRowLg
                        key={idx}
                        name={b.name}
                        budgetAmount={b.budgetAmount}
                        budgetReconciled={b.budgetReconciled}
                        percent={percent}
                        expandedData={b.expandedData}
                        handleSetModal={handleSetModal}
                        type="Expense"
                      />
                    );
                  })}
                <tr key={"expenseSumRow"} className="h-12 bg-[#F1F5F9]">
                  <td className="font-bold">Expense Total</td>
                  <td className="text-gray-700 text-right">
                    {FormatMoney(expenseBudgetTotal.budgetTotal)}
                  </td>
                  <td className="text-gray-700 text-right">
                    {FormatMoney(expenseBudgetTotal.reconciledTotal)}
                  </td>
                  <td className="flex-row">
                    <ColorBar
                      percent={expenseBudgetTotal.percent}
                      colorHex={getHexColor(expenseBudgetTotal.budgetTotal, expenseBudgetTotal.reconciledTotal, false)}
                    />
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </SnapTable>
          <SnapTable>
            <table className="celled table-fixed border-0 drop-shadow-none">
              <tbody>
                <tr>
                  <td className="text-base font-semibold whitespace-nowrap w-[23%] capitalize">
                    Net Total
                  </td>
                  <td className="text-right text-sm font-bold w-[15%]">
                    {FormatMoney(
                      incomeBudgetTotal.budgetTotal -
                        expenseBudgetTotal.budgetTotal
                    )}
                  </td>
                  <td className="text-right text-sm font-bold w-[15%]">
                    {FormatMoney(
                      incomeBudgetTotal.reconciledTotal -
                        expenseBudgetTotal.reconciledTotal
                    )}
                  </td>
                  <td className="w-[47%]"></td>
                </tr>
              </tbody>
            </table>
          </SnapTable>
          <SnapTable>
            <table className="celled table-fixed">
              <thead>
                <tr>
                  <th className="text-base font-semibold whitespace-nowrap w-[23%] capitalize">
                    Unreconciled
                  </th>
                  <th className="text-right text-xs font-bold w-[15%]">
                    Total
                  </th>
                  <th className="w-[62%]"></th>
                </tr>
              </thead>
              <tbody>
                <tr className="h-12">
                  <td className="text-base font-semibold whitespace-nowrap capitalize">
                    <div className="flex">
                      Credits
                      <SnapBadge className="ml-2" color="blue" size="sm">
                        {unreconciledData.credit.count ?? 0}
                      </SnapBadge>
                    </div>
                  </td>
                  <td className="text-right text-sm font-bold">
                    {FormatMoney(unreconciledData.credit.total ?? 0)}
                  </td>
                  <td></td>
                </tr>
                <tr className="h-12">
                  <td className="text-base font-semibold whitespace-nowrap capitalize">
                    <div className="flex">
                      Debits
                      <SnapBadge className="ml-2" color="blue" size="sm">
                        {unreconciledData.debit.count ?? 0}
                      </SnapBadge>
                    </div>
                  </td>
                  <td className="text-right text-sm font-bold">
                    {FormatMoney(unreconciledData.debit.total ?? 0)}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </SnapTable> */}
        </div>
      </div>
      {isBudgetItemOpen && (
        <BudgetItemDetails
          isBudgetItemOpen={isBudgetItemOpen}
          budgetItemToggle={budgetItemToggle}
          levelType={type}
          budgetItem={selectedBudgetItem}
        />
      )}
    </div>
  );
}

export default Summary;
