type DividerProps = { isVisibleOnMobile?: boolean; className?: string };

function Divider({ isVisibleOnMobile, className }: DividerProps) {
  return isVisibleOnMobile ? (
    <div className={`border-gray-200 border-b mt-4 ${className}`}></div>
  ) : (
    <div
      className={`border-gray-200 border-b mt-4 hidden lg:flex ${className}`}
    ></div>
  );
}

export default Divider;
