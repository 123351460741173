import FixedModal from "shared-components/fixed-modal/fixed-modal";

type CancelPaymentScheduleEditModalProps = {
  isOpen: boolean;
  toggle: () => void;
  proceedAction: () => void;
  cancelAction: () => void;
};

function CancelPaymentScheduleEditModal({
  isOpen,
  toggle,
  proceedAction,
  cancelAction,
}: CancelPaymentScheduleEditModalProps) {
  return (
    <FixedModal
      isOpen={isOpen}
      toggle={toggle}
      title={"Cancel Payment Schedule Edits"}
      icon="exclamation-solid"
      iconColor="#FEF3C7"
      btn1={{
        text: "Save Changes",
        btnStyle: "primary",
        onClick: () => {
          proceedAction();
          toggle();
        },
      }}
      btn2={{
        text: "Discard Changes",
        btnStyle: "tertiary",
        onClick: () => {
          cancelAction();
          toggle();
        },
      }}
    >
      <div>
        <p className="text-sm text-gray-500 text-center">
          Would you like to save changes or cancel all changes?
        </p>
        <p className="text-sm text-gray-500 text-center">
          <b>Note</b>: Saving changes will only save added invoices
        </p>
      </div>
    </FixedModal>
  );
}

export default CancelPaymentScheduleEditModal;
