import { getBadgeColor } from "helpers/status-color";
import Divider from "shared-components/divider";
import { SnapBadge, SnapInput, SnapRadioButton } from "suit";
import { StepProps } from ".";
import { useState } from "react";
import { Maybe, SpendGroupRoster } from "graphql/generated";

function StepThree({ selectedDetails, setSelectedDetails }: StepProps) {
  const [participants] = useState<Maybe<SpendGroupRoster>[]>(
    selectedDetails.season?.groupRoster ?? []
  );
  return (
    <div>
      <p className="text-lg font-semibold mt-4">Select Participant</p>
      <p className="text-sm text-gray-500 mb-4">
        Please specify the partcipant this payment applies to.
      </p>
      <SnapInput _id={""} placeholder="Search this page" />
      <div className="h-[100%] overflow-y-scroll">
        {participants
          .filter((p) => !p?.isArchived)
          .map((participant, idx) => {
            let status = !!participant?.joinedAt
              ? "Signed Up"
              : "Not Signed Up";
            return (
              participant && (
                <div
                  key={idx}
                  className={`flex px-6 py-4 border rounded-lg mt-4 ${
                    selectedDetails.participant?.id === participant.id &&
                    "border-blue-400 bg-blue-50"
                  }`}
                >
                  <SnapRadioButton
                    checked={selectedDetails.participant?.id === participant.id}
                    onClick={() =>
                      setSelectedDetails({
                        ...selectedDetails,
                        participant: participant,
                      })
                    }
                    className="mt-1"
                  />
                  <div className="w-full lg:items-center">
                    <p className="text-base font-medium">
                      {participant?.roster?.name}
                    </p>
                    <p className="text-sm text-gray-500">
                      {participant?.roster?.email}
                    </p>
                    <Divider isVisibleOnMobile className="mt-2" />
                    <div className="flex mt-2">
                      <p className="mr-auto text-xs font-semibold text-gray-500">
                        Status
                      </p>
                      <SnapBadge color={getBadgeColor(status)}>
                        {status}
                      </SnapBadge>
                    </div>
                  </div>
                </div>
              )
            );
          })}
      </div>
    </div>
  );
}

export default StepThree;
