import useModal from "hooks/use-modal";
import { SnapDropDown, SnapIcon, SnapIndicator } from "suit";
import { RESEND_USER_INVITE } from "graphql/mutations/invite";
import { useLazyQuery, useMutation } from "@apollo/client";
import { EXPORT_USERS_EMAILS } from "graphql/queries/user";
import { useContext, useEffect, useState } from "react";
import { exportToCsv } from "helpers/export-csv";
import { ToastType } from "hooks/use-toast";
import { SpendRosterResend } from "types/group-roster";
import { useLocation } from "react-router-dom";
import SeasonContext from "context/season-context";
import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/components";
import GroupContext from "context/group-context";
import { PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON } from "graphql/queries/payment-schedule";
type BulkActionsProps = {
  numOfItemsSelected: number;
  deselectAllAction: () => void;
  sendEmailToggle: () => void;
  selectedItems?: SpendRosterResend[];
  errorMessageToggle?: () => void;
  toast: ToastType;
  removeParticipantToggle?: () => void;
  paymentScheduleStatus?: string;
};

function BulkActions({
  numOfItemsSelected,
  deselectAllAction,
  sendEmailToggle,
  selectedItems,
  errorMessageToggle,
  toast,
  removeParticipantToggle,
  paymentScheduleStatus,
}: BulkActionsProps) {
  const Group = useContext(GroupContext);

  const { isOpen: actionMenuOpen, toggle: actionMenuToggle } = useModal();
  const Season = useContext(SeasonContext);
  const location = useLocation();
  const [dropDownItems, setDropDownItems] = useState<DropdownMenuItem[]>([]);
  const [initBtn, setInitBtn] = useState({
    text: "Send Email",
    action: sendEmailToggle,
  });

  useEffect(() => {
    let options = [];

    if (location.pathname.endsWith("/collections")) {
      let noInviteSent = selectedItems?.every(
        ({ id, status }) => id === "" || status === "canceled"
      );

      if (noInviteSent) {
        setInitBtn({
          text: "Send Invite",
          action: handleCheckActiveUserForResend,
        });
      } else {
        setInitBtn({
          text: "Send Email",
          action: sendEmailToggle,
        });
      }
    }
    const statuses = [
      undefined,
      "pending",
      "canceled",
      "sent",
      "No Invite Sent",
      "expired",
      "created",
    ];
    if (selectedItems?.every((item) => statuses.includes(item.status))) {
      options.push({
        name: "Remove Participant",
        text: "Remove Participant",
        value: "Remove Participant",
      });
    }
    options.push({
      name: "Resend Invite",
      text: "Resend Invite",
      value: "Resend Invite",
    });
    options.push({
      name: "Export Email Address",
      text: "Export Email Address",
      value: "Export Email Address",
    });
    setDropDownItems(options);
    // eslint-disable-next-line
  }, [selectedItems, Group?.groups]);

  const [reSendInvitesCollection] = useMutation(RESEND_USER_INVITE, {
    refetchQueries: location.pathname.includes("collections")
      ? [
          "SpendGroupRostersBySeason",
          {
            query: PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON,
            variables: {
              seasonId: Season?.selectedSeason?.id,
              groupIdOrSeasonId: Season?.selectedSeason?.id,
            },
            fetchPolicy: "network-only",
          },
        ]
      : ["SpendRostersFiltered", "SpendInvitesFiltered"],
    onCompleted: () => {
      toast.setToast({
        message: "Invite(s) has been sent",
        type: "success",
      });
      deselectAllAction();
    },
  });
  const [
    ExportEmails,
    { loading: loadingExportedEmails, data: exportEmailsData },
  ] = useLazyQuery(EXPORT_USERS_EMAILS);

  const handleExport = (emails: string[]) => {
    ExportEmails({
      variables: {
        input: {
          emails: emails,
        },
      },
    });
  };

  useEffect(() => {
    if (exportEmailsData && exportEmailsData.spendUserExportEmails) {
      exportToCsv(exportEmailsData.spendUserExportEmails);
      deselectAllAction();
    }
  }, [loadingExportedEmails, exportEmailsData, deselectAllAction]);

  const handleCheckActiveUserForResend = () => {
    if (
      location.pathname === "/groups/collections" &&
      paymentScheduleStatus !== "Published"
    ) {
      toast.setToast({
        message: "Can not send invites while payment schedule is not published",
        type: "warning",
      });
    } else {
      selectedItems?.forEach((user) => {
        reSendInvitesCollection({
          variables: {
            input: {
              id: user.id,
              groupId: user.groupId,
              email: user.email,
              userId: user.userId,
              seasonId: user.seasonId,
              groupRosterId: user.groupRosterId,
            },
          },
        });
      });
      toast.setToast({
        message: "Resending invites - this may take a minute",
        type: "warning",
      });
    }
  };

  return (
    <div
      className={`${
        actionMenuOpen ? "static lg:fixed" : "fixed"
      }  flex lg:bottom-0 bottom-14 left-0 z-[9] bg-white border border-t border-black w-full py-4`}
    >
      <div className="flex mr-auto cursor-pointer items-center lg:ml-80 pl-2 lg:pl-0">
        <SnapIndicator text={numOfItemsSelected} color="gray" size="sm" />
        <p
          className="ml-3 text-blue-600 border-l border-gray-200 pl-4 font-bold"
          onClick={deselectAllAction}
        >
          Deselect All
        </p>
      </div>
      <div className="flex cursor-pointer items-center mr-4">
        <SnapIcon icon="mail-solid" color="#3B82F6" size="xs"></SnapIcon>
        <p
          className="ml-3 text-blue-600 mr-4 font-bold"
          onClick={initBtn.action}
        >
          {initBtn.text}
        </p>
        <SnapDropDown
          onClick={actionMenuToggle}
          id="mobile-menu"
          size="lg"
          modalType="drawer"
          minimal
          menuPosition="top"
          options={dropDownItems}
          onSnap-dropdown-item-selected={(e) => {
            switch (e.detail.name) {
              case "Resend Invite":
                handleCheckActiveUserForResend();
                break;
              case "Export Email Address":
                let emails: string[] =
                  selectedItems?.map((item) => item.email) ?? [];
                handleExport(emails);
                break;
              case "Remove Participant":
                removeParticipantToggle!();
                break;
            }
          }}
        />
      </div>
    </div>
  );
}

export default BulkActions;
