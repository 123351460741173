import { useMutation, useQuery } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import UserContext from "context/user-context";
import cuid from "cuid";
import {
  Maybe,
  PaymentsApiCustomerPaymentMethod,
  SpendBankAccount,
  SpendGroupRoster,
  usePaymentsApiCustomerMutation,
} from "graphql/generated";
import {
  GET_GROUP,
  GET_PARENT_INVOICES_CHANGE_PAYMENT,
} from "graphql/queries/group";
import {
  GET_GUARDIAN_HIGHLIGHT_2,
  USER_BANK_ACCOUNTS,
} from "graphql/queries/user";
import useToast from "hooks/use-toast";
import React, { useContext, useEffect, useState } from "react";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import LinkBank from "shared-components/banking/link-bank";
import Divider from "shared-components/divider";
import { OffSessionPaymentComponent } from "shared-components/payment-component/off-session-payment-component";
import { SnapButton, SnapCheckboxButton, SnapSelectMenu } from "suit";
import { SelectedInvoice } from "types/invoice";
import CustomModal from "..";
import { defaultSelectedPaymentOption } from "../make-payment/make-payment-helper";
import { SubHeaderView } from "../make-payment/sub-header-view";
import { ChangePaymentCard } from "./change-payment-card";
import { ChangePaymentTable } from "./change-payment-table";
import { SelectedInvoicesCard } from "./selected-invoices-card";
import { SelectedInvoicesTable } from "./selected-invoices-table";
import { CHANGE_INVOICE_PAYMENT_METHOD } from "graphql/mutations/invoice";
import VerticalLabelValue, {
  VerticalValueStyle,
} from "shared-components/vertical-label-value";
import Spinner from "shared-components/spinner";
import { extractBankLabel } from "helpers/banking";

type AddChangePaymentType = {
  isOpen: boolean;
  toggle: () => void;
};
export type PlayerInfoType = {
  team: Maybe<string> | undefined;
  player: Maybe<string> | undefined;
  invoices: Maybe<SelectedInvoice>[] | undefined;
  // selected: boolean;
};

export const ChangePaymentModal = ({
  isOpen,
  toggle,
}: AddChangePaymentType) => {
  const extPayId = cuid();
  const ActiveUser = useContext(UserContext);
  // eslint-disable-next-line
  const [
    getPaymentMethods,
    { loading: loadingPaymentMethod, data: paymentMethodData },
  ] = usePaymentsApiCustomerMutation();
  const { loading: loadingGroup, data: groupData } = useQuery(GET_GROUP, {
    skip: !isOpen,
  });
  const { data: getGroupData, loading: loadingGroupData } = useQuery(
    GET_PARENT_INVOICES_CHANGE_PAYMENT,
    {
      variables: {
        filterBy: "memberId",
        filterValue: ActiveUser?._session?.userId,
      },
      skip: !ActiveUser?._session?.userId || !isOpen,
    }
  );
  const { data: accountsData, loading: accountsLoading } = useQuery(
    USER_BANK_ACCOUNTS,
    {
      skip: !ActiveUser?._session?.userId || !isOpen,
    }
  );
  // eslint-disable-next-line
  const [bankAccounts, setBankAccounts] = useState<SpendBankAccount[]>([]);
  const [accountStatus, setAccountStatus] = useState("");
  // eslint-disable-next-line
  const [selectedBankAccount, setSelectedBankAccount] = useState<
    SpendBankAccount | undefined
  >();
  const [isAuthorized, setIsAuthorized] = useState(false);
  // eslint-disable-next-line
  const [bankOptions, setBankOptions] = useState<SnapSelectMenuOption[]>([]);
  const [playersInfo, setPlayerInfo] = useState<PlayerInfoType[]>([]);
  const [selectedInvoices, setSelectedInvoices] = useState<PlayerInfoType[]>(
    []
  );
  const [cardBlock, setCardBlock] = useState(false);
  const [isSelectBoxChecked, setSelectBoxChecked] = useState(false);
  const [step, setStep] = useState(0);
  const [paymentOptions, setPaymentOptions] = useState<SnapSelectMenuOption[]>(
    defaultSelectedPaymentOption
  );
  const [selectedPaymentTypeOption, setSelectedPaymentTypeOption] = useState<
    `Pay by: ${"Card" | "Bank"}` | undefined
  >();
  const [groupAccountId, setGroupAccountId] = useState("");
  const [paymentMethods, setPaymentMethods] = useState<
    PaymentsApiCustomerPaymentMethod[]
  >([]);
  const { isToastOpen, ...toast } = useToast();
  const [isBtnActive, setIsBtnActive] = useState(false);

  const [isNextDisabled, setIsNextDisabled] = useState(false);
  useEffect(() => {
    const amounts = selectedInvoices.flatMap((selectedInvoice) =>
      selectedInvoice.invoices?.map((inv) => inv?.balanceDue)
    );
    setCardBlock(
      selectedPaymentTypeOption === "Pay by: Card" &&
        amounts.some((am) => am && am <= 50)
    );
  }, [selectedPaymentTypeOption, selectedInvoices]);
  useEffect(() => {
    if (paymentMethodData && paymentMethodData.paymentsApiCustomer) {
      setPaymentMethods(
        paymentMethodData.paymentsApiCustomer
          .paymentMethods as PaymentsApiCustomerPaymentMethod[]
      );
    }
  }, [loadingPaymentMethod, paymentMethodData]);
  useEffect(() => {
    if (groupData && groupData.spendGroup) {
      setGroupAccountId(groupData.spendGroup.accountId);
    }
  }, [loadingGroup, groupData]);

  useEffect(() => {
    if (ActiveUser?.getEmail() && isOpen) {
      getPaymentMethods();
    }
  }, [ActiveUser, isOpen, getPaymentMethods]);

  const [plaidConfig, setPlaidConfig] = useState<PlaidLinkOptions>({
    onSuccess: (public_token, metadata) => {},
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: "",
  });
  const { open, ready } = usePlaidLink(plaidConfig);
  const openPlaid = () => {
    if (ready) {
      open();
    }
  };

  const handlePlayerData = (playerArray: SpendGroupRoster[]) => {
    const selectedInvoiceIds = selectedInvoices.flatMap((gr) =>
      gr.invoices?.map((inv) => inv!.id)
    );
    let newInfo = playerArray
      ?.map((player) => {
        return {
          team: player.group?.name,
          player: player.roster?.name,
          invoices: player.invoices
            ?.filter((invoice) => invoice?.paid === false)
            .map((invoice) => ({
              ...invoice,
              selected: selectedInvoiceIds.includes(invoice?.id),
            })),
        };
      })
      .filter((player) => player.invoices?.length !== 0);

    setPlayerInfo(newInfo);
  };
  const paymentMethodCreate = () => {
    // setWidgetOpen(false);
    getPaymentMethods();
  };

  const handleSelectedInvoices = (invoiceIdx: number, playerIdx: number) => {
    let tempPlayersInvoices = [...playersInfo];
    let selectInvoice: Maybe<SelectedInvoice> =
      tempPlayersInvoices[playerIdx].invoices![invoiceIdx];
    let newSelectedInvoice: SelectedInvoice = {
      ...selectInvoice,
      selected: !selectInvoice?.selected,
    };

    tempPlayersInvoices[playerIdx].invoices?.splice(
      invoiceIdx,
      1,
      newSelectedInvoice
    );
    setPlayerInfo(tempPlayersInvoices);
  };

  useEffect(() => {
    let invoiceSelected = playersInfo
      .map((player) => {
        return {
          team: player.team,
          player: player.player,
          invoices: player.invoices?.filter(
            (invoice) => invoice?.selected === true
          ),
        };
      })
      .filter((x) => x.invoices?.length !== 0);

    setSelectedInvoices(invoiceSelected);
  }, [playersInfo]);

  const handleSelectAll = (isChecked: boolean) => {
    let tempPlayersInvoices = [...playersInfo];
    if (!isChecked) {
      let allSelectedPlayers = tempPlayersInvoices.map((player) => {
        return {
          ...player,
          invoices: player.invoices?.map((invoice) => {
            return {
              ...invoice,
              selected: true,
            };
          }),
        };
      });
      setPlayerInfo(allSelectedPlayers);
    } else {
      let allSelectedPlayers = tempPlayersInvoices.map((player) => {
        return {
          ...player,
          invoices: player.invoices?.map((invoice) => {
            return {
              ...invoice,
              selected: false,
            };
          }),
        };
      });
      setPlayerInfo(allSelectedPlayers);
    }
  };

  useEffect(() => {
    if (!loadingGroupData && getGroupData && getGroupData?.spendGroupRosters) {
      handlePlayerData(getGroupData?.spendGroupRosters.groupRosters);
    }
    if (!accountsLoading && accountsData?.spendUserBankAccounts) {
      const tempAccounts = [
        ...accountsData.spendUserBankAccounts.externalAccounts,
      ];
      setAccountStatus(accountsData?.spendUserBankAccounts.status);
      setBankAccounts(tempAccounts);

      setBankOptions(
        tempAccounts.map((tAccount, idx) => ({
          name: extractBankLabel(tAccount),
          selected: idx === 0,
        }))
      );
      setSelectedBankAccount(
        tempAccounts.length > 0 ? tempAccounts[0] : undefined
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getGroupData, loadingGroupData, accountsData, accountsLoading]);

  const [changePaymentMethod] = useMutation(CHANGE_INVOICE_PAYMENT_METHOD, {
    refetchQueries: [
      {
        query: GET_PARENT_INVOICES_CHANGE_PAYMENT,
        variables: {
          filterBy: "memberId",
          filterValue: ActiveUser?._session?.userId,
        },
      },
      {
        query: GET_GUARDIAN_HIGHLIGHT_2,
      },
    ],
  });
  const handleChangeInvoicePayment = () => {
    let invoicesIds = selectedInvoices.flatMap((player) =>
      player.invoices?.map((invoice) => invoice?.id)
    );
    let paymentData = {
      paymentMethodId: "",
      paymentMethodSource: "",
    };
    if (selectedPaymentTypeOption === "Pay by: Bank") {
      paymentData = {
        paymentMethodId: selectedBankAccount?.accountId ?? "",
        paymentMethodSource: "ACH",
      };
    } else {
      paymentData = {
        paymentMethodId: paymentMethods.at(0)?.id ?? "",
        paymentMethodSource: "CARD",
      };
    }
    changePaymentMethod({
      variables: {
        input: {
          ids: invoicesIds,
          paymentMethodId: paymentData.paymentMethodId,
          paymentMethodSource: paymentData.paymentMethodSource,
        },
      },
    })
      .then(() => {
        resetFormAndClose();
      })
      .catch((e) => {
        toast.setToastProps({
          message: e.message || "Unable to save payment method.",
          type: "danger",
        });
      })
      .finally(() => {
        setIsBtnActive(false);
      });
  };
  useEffect(() => {
    const hasNoPaymentOrNoInvoices =
      selectedPaymentTypeOption === undefined || selectedInvoices.length === 0;
    const isPayByBankAndNoBank =
      selectedPaymentTypeOption === "Pay by: Bank" &&
      selectedBankAccount == null;
    const isPayByCardAndPmEmpty =
      selectedPaymentTypeOption === "Pay by: Card" &&
      paymentMethods.length === 0;
    const hasNoAuthOrBtnActive = !isAuthorized || isBtnActive;
    if (
      step === 0 &&
      (hasNoPaymentOrNoInvoices ||
        isPayByBankAndNoBank ||
        isPayByCardAndPmEmpty)
    ) {
      setIsNextDisabled(true);
    } else if (step === 1 && hasNoAuthOrBtnActive) {
      setIsNextDisabled(true);
    } else {
      setIsNextDisabled(false);
    }
  }, [
    isAuthorized,
    isBtnActive,
    paymentMethods.length,
    selectedBankAccount,
    selectedInvoices.length,
    selectedPaymentTypeOption,
    step,
  ]);
  const resetFormAndClose = () => {
    setSelectedInvoices([]);
    setPaymentOptions(paymentOptions.map((op) => ({ ...op, selected: false })));
    setSelectedPaymentTypeOption(undefined);
    toggle();
  };
  return (
    <>
      <CustomModal
        isOpen={isOpen}
        toggle={resetFormAndClose}
        title="Change Payment Method"
        customStyle="lg:w-[974px]"
      >
        <div className="mx-3 mt-2 modal-card lg:h-[500px] overflow-auto">
          {step === 0 && (
            <React.Fragment>
              <SubHeaderView text={"Invoices"} hideDivider={true} />
              <div className="hidden lg:flex">
                <ChangePaymentTable
                  players={playersInfo}
                  handleSelectedInvoices={handleSelectedInvoices}
                  handleSelectAll={handleSelectAll}
                  setSelectBoxChecked={setSelectBoxChecked}
                  isSelectBoxChecked={isSelectBoxChecked}
                />
              </div>
              <div>
                <ChangePaymentCard
                  players={playersInfo}
                  handleSelectedInvoices={handleSelectedInvoices}
                  handleSelectAll={handleSelectAll}
                  setSelectBoxChecked={setSelectBoxChecked}
                  isSelectBoxChecked={isSelectBoxChecked}
                />
              </div>
              <SubHeaderView text={"Payment Method"} />
              <div className="mb-10">
                <p className=" text-sm mt-2 mb-2">Select a Payment Method</p>
                <SnapSelectMenu
                  placeholder="- Select Payment Method -"
                  selectMenuOptions={paymentOptions}
                  onSnap-select-menu-updated={(e) => {
                    const op = e.detail.find((option) => option.selected)?.name;
                    if (!op) {
                      setSelectedPaymentTypeOption(undefined);
                      setPaymentOptions(e.detail);
                    } else {
                      setSelectedPaymentTypeOption(
                        op === "Pay by: Card" ? "Pay by: Card" : "Pay by: Bank"
                      );
                      setPaymentOptions(e.detail);
                    }
                  }}
                />
                <div>
                  {selectedPaymentTypeOption === "Pay by: Bank" ? (
                    <>
                      {bankAccounts.length !== 0 ? (
                        <VerticalLabelValue
                          label="Linked Bank"
                          labelStyle="text-sm font-normal"
                          value={extractBankLabel(bankAccounts.at(0))}
                          valueStyle={VerticalValueStyle.ActionLabel}
                          customContainerStyle={
                            "border-none lg:text-start flex flex-col"
                          }
                          actionLabelClassname="flex-col"
                        />
                      ) : (
                        <LinkBank
                          openPlaid={openPlaid}
                          setPlaidConfig={setPlaidConfig}
                          toast={toast}
                          type={"user"}
                          labelText={
                            accountStatus === "pending_manual_verification"
                              ? "Verify Account"
                              : "Add Account"
                          }
                        />
                      )}
                    </>
                  ) : selectedPaymentTypeOption === "Pay by: Card" ? (
                    <>
                      {paymentMethods.length && !cardBlock ? (
                        paymentMethods.map((method, idx) => {
                          return (
                            <div
                              key={`${method.identifier} ${idx}`}
                              className="flex"
                            >
                              <p>{method.identifier}</p>
                            </div>
                          );
                        })
                      ) : cardBlock ? (
                        <p className="mt-3">
                          Unable to make card payments of $0.50 or less. Please
                          select another payment method.
                        </p>
                      ) : (
                        <OffSessionPaymentComponent
                          returnUrl={`${document.baseURI}&externalId=${extPayId}`}
                          payerDetails={{
                            id: ActiveUser?.getUserId() ?? "",
                            name: ActiveUser?.getName() ?? "",
                            email: ActiveUser?.getEmail() ?? "",
                          }}
                          destination={groupAccountId}
                          externalPaymentId={extPayId}
                          paymentMethodCreate={paymentMethodCreate}
                        />
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </React.Fragment>
          )}
          {step === 1 && (
            <React.Fragment>
              <SubHeaderView text={"Summary"} hideDivider={true} />
              <p className="mt-4">Invoices</p>
              <SelectedInvoicesTable
                selectedPlayerInvoice={selectedInvoices}
                selectedPaymentTypeOption={selectedPaymentTypeOption}
              />
              <SelectedInvoicesCard
                selectedPlayerInvoice={selectedInvoices}
                selectedPaymentTypeOption={selectedPaymentTypeOption}
              />
              <Divider />
              <div className="flex mt-5">
                <p>Payment Method</p>
                {selectedPaymentTypeOption === "Pay by: Bank" ? (
                  <p className=" ml-10">{bankAccounts.at(0)?.name}</p>
                ) : (
                  <p className=" ml-10">{paymentMethods.at(0)?.identifier}</p>
                )}
                {/* <p className=" ml-10">{selectedPaymentTypeOption}</p> */}
              </div>
              <Divider isVisibleOnMobile />
              <SnapCheckboxButton
                className="mt-5"
                label="I authorize Snap! Spend to charge my payment method according to the above schedule."
                checked={isAuthorized}
                onClick={(e) => setIsAuthorized(e.currentTarget.checked)}
              />
              <Divider isVisibleOnMobile />
              <div className="mt-5">
                <ul>
                  <li>
                    The payment method noted above will be applied to all
                    invoices listed above.
                  </li>
                  <li>
                    You will be charged on the billing date listed above for
                    each invoice.
                  </li>
                </ul>
              </div>
            </React.Fragment>
          )}

          <div className="flex justify-end space-x-3 mt-5 m-3">
            <SnapButton
              icon="arrow-narrow-left-line"
              variant="tertiary"
              onClick={() => {
                if (step === 1) {
                  setStep(0);
                } else {
                  resetFormAndClose();
                }
              }}
            >
              Back
            </SnapButton>
            <SnapButton
              icon={step === 0 ? "arrow-narrow-right-line" : undefined}
              variant="primary"
              disabled={isNextDisabled}
              onClick={() => {
                if (
                  step === 0 &&
                  selectedPaymentTypeOption !== undefined &&
                  selectedInvoices.length !== 0
                ) {
                  setStep(1);
                } else if (step === 1 && isAuthorized) {
                  setIsBtnActive(true);
                  handleChangeInvoicePayment();
                }
              }}
            >
              {step === 0 && <p>Next</p>}
              {step === 1 && isBtnActive ? (
                <Spinner size="small" className="mx-6" />
              ) : (
                step === 1 && "Authorize Billing"
              )}
            </SnapButton>
          </div>
        </div>
      </CustomModal>
    </>
  );
};
