import { SpinnerContainer } from "shared-components/spinner";
import VerticalLabelValue from "shared-components/vertical-label-value";
import { SnapCheckboxButton } from "suit";
import { GroupRosterChecked } from "types/group-roster";

type AddParticipantCardProps = {
  list: GroupRosterChecked[];
  isLoading: boolean;
  onRosterSelect: (id: number) => void;
};
function AddParticipantCard({
  list,
  isLoading,
  onRosterSelect,
}: AddParticipantCardProps) {
  return (
    <div>
      {list.map((groupRoster, idx) => {
        return (
          <div
            key={`card_add_existing_participant_${groupRoster.roster?.id}`}
            className="border border-gray-200 p-5 mt-4 rounded-lg lg:hidden flex"
          >
            <SnapCheckboxButton
              checked={groupRoster.isChecked}
              onClick={() => {
                onRosterSelect(idx);
              }}
            />
            <div>
              <VerticalLabelValue
                label={"Participant"}
                value={groupRoster.roster?.name}
                customContainerStyle={"flex-col"}
              />
              <VerticalLabelValue
                label={"Parent Email"}
                value={groupRoster.roster?.email}
                customContainerStyle={"flex-col"}
              />
              <VerticalLabelValue
                label={"Group"}
                value={groupRoster.group?.name}
                customContainerStyle={"flex-col"}
              />
            </div>
          </div>
        );
      })}
      {!isLoading && list.length === 0 && (
        <div className="text-center my-3">No Results</div>
      )}
      {isLoading && <SpinnerContainer loading={isLoading} />}
    </div>
  );
}

export default AddParticipantCard;
