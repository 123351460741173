/**
 * Calculate sum of all object's specific property
 * @param data Data set of any type that contains the prop as a valid property
 * @param prop Name of number type property to sum
 * @returns
 */
export function MapAndCalcSum(data: any[], prop: string) {
  return Math.round(
    data
      .map((anyData) => anyData[prop] as number)
      .reduce((sum, current) => sum + current, 0) ?? 0
  );
}
export function CalcSum(data: number[]) {
  return Math.round(data.reduce((sum, current) => sum + current, 0));
}
