const AuthTokenData = "Authorization-Token-Data";
export const getAuthTokenData = () => {
  return JSON.parse(localStorage.getItem(AuthTokenData) || "{}").token;
};
export const getAuthTokenInfo = () => {
  const jsonData = JSON.parse(localStorage.getItem(AuthTokenData) || "{}");
  return {
    token: jsonData.token,
    tokenExpires: jsonData.tokenExpires,
    hex: jsonData.hex,
  };
};
export const setAuthTokenData = (tokenData: {
  token: any;
  tokenExpires: Date;
  hex?: string;
}) => {
  localStorage.setItem(AuthTokenData, JSON.stringify(tokenData));
};
export const removeAuthToken = () => {
  localStorage.removeItem(AuthTokenData);
};
