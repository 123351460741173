import noAccount from "assets/noAccount.svg";

type NoAccountProps = {
  openPlaid: () => void;
};

function NoAccount({ openPlaid }: NoAccountProps) {
  return (
    <div className="flex justify-center">
      <div>
        <img src={noAccount} alt="" />
        <p className="text-center">No linked Account</p>
        <p
          className="text-center text-base font-bold text-blue-600 cursor-pointer"
          onClick={openPlaid}
        >
          Link External Bank Account
        </p>
      </div>
    </div>
  );
}

export default NoAccount;
