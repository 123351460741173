import { FormatDate, DateFormatSupported } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { calcTotalPlusFee } from "../make-payment/make-payment-helper";
import GroupContext from "context/group-context";
import { useContext } from "react";
import { PlayerInfoType } from "./change-payment-modal";
import DataCard from "shared-components/data-card";
import React from "react";

type SelectedInvoicesCardProps = {
  selectedPlayerInvoice: PlayerInfoType[];
  selectedPaymentTypeOption: string | undefined;
};
export const SelectedInvoicesCard = ({
  selectedPlayerInvoice,
  selectedPaymentTypeOption,
}: SelectedInvoicesCardProps) => {
  const activeGroup = useContext(GroupContext)?.activeGroup;
  return (
    <React.Fragment>
      {selectedPlayerInvoice &&
        selectedPlayerInvoice.map((player) => {
          return player.invoices?.map((invoice, idx) => {
            return (
              <DataCard
                key={`${idx}`}
                className="flex lg:hidden"
                title={player.team ?? ""}
                kvLeft={[
                  {
                    key: "Participant",
                    value: player.player!,
                    customContainerStyle: "flex-col",
                    className: "ml-0",
                  },
                  {
                    key: "Description",
                    value: invoice?.description!,
                    customContainerStyle: "flex-col",
                    className: "ml-0",
                  },
                ]}
                kvRight={[
                  {
                    key: "Billing Date",
                    value: FormatDate(
                      invoice?.dueDate!,
                      DateFormatSupported.Words
                    ),
                  },
                  {
                    key: "Amount",
                    value:
                      selectedPaymentTypeOption === "Pay by: Card"
                        ? FormatMoney(
                            calcTotalPlusFee(
                              invoice?.balanceDue ?? 0,
                              activeGroup?.organizationFees?.cardPercent ?? 0,
                              activeGroup?.organizationFees?.cardBaseFee ?? 0
                            )
                          )
                        : FormatMoney(
                            calcTotalPlusFee(
                              invoice?.balanceDue ?? 0,
                              activeGroup?.organizationFees?.achPercent ?? 0,
                              activeGroup?.organizationFees?.achBaseFee ?? 0
                            )
                          ),
                  },
                ]}
                action={0}
              />
            );
          });
        })}
    </React.Fragment>
    //     {selectedPlayerInvoice?.map((player, playerIdx) => {
    //         return player.invoices?.map((invoice, idx) => {
  );
};
