import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";

const PayeeStates: Array<SnapSelectMenuOption> = [
  { name: "Alabama", value: "AL", selected: false },
  { name: "Alaska", value: "AK", selected: false },
  { name: "Arizona", value: "AZ", selected: false },
  { name: "Arkansas", value: "AR", selected: false },
  { name: "California", value: "CA", selected: false },
  { name: "Colorado", value: "CO", selected: false },
  { name: "Connecticut", value: "CT", selected: false },
  { name: "Delaware", value: "DE", selected: false },
  { name: "Florida", value: "FL", selected: false },
  { name: "Georgia", value: "GA", selected: false },
  { name: "Hawaii", value: "HI", selected: false },
  { name: "Idaho", value: "ID", selected: false },
  { name: "Illinois", value: "IL", selected: false },
  { name: "Indiana", value: "IN", selected: false },
  { name: "Iowa", value: "IA", selected: false },
  { name: "Kansas", value: "KS", selected: false },
  { name: "Kentucky", value: "KY", selected: false },
  { name: "Louisiana", value: "LA", selected: false },
  { name: "Maine", value: "ME", selected: false },
  { name: "Maryland", value: "MD", selected: false },
  { name: "Massachusetts", value: "MA", selected: false },
  { name: "Michigan", value: "MI", selected: false },
  { name: "Minnesota", value: "MN", selected: false },
  { name: "Mississippi", value: "MS", selected: false },
  { name: "Missouri", value: "MO", selected: false },
  { name: "Montana", value: "MT", selected: false },
  { name: "Nebraska", value: "NE", selected: false },
  { name: "Nevada", value: "NV", selected: false },
  { name: "New Hampshire", value: "NH", selected: false },
  { name: "New Jersey", value: "NJ", selected: false },
  { name: "New Mexico", value: "NM", selected: false },
  { name: "New York", value: "NY", selected: false },
  { name: "North Carolina", value: "NC", selected: false },
  { name: "North Dakota", value: "ND", selected: false },
  { name: "Ohio", value: "OH", selected: false },
  { name: "Oklahoma", value: "OK", selected: false },
  { name: "Oregon", value: "OR", selected: false },
  { name: "Pennsylvania", value: "PA", selected: false },
  { name: "Rhode Island", value: "RI", selected: false },
  { name: "South Carolina", value: "SC", selected: false },
  { name: "South Dakota", value: "SD", selected: false },
  { name: "Tennessee", value: "TN", selected: false },
  { name: "Texas", value: "TX", selected: false },
  { name: "Utah", value: "UT", selected: false },
  { name: "Vermont", value: "VT", selected: false },
  { name: "Virginia", value: "VA", selected: false },
  { name: "Washington", value: "WA", selected: false },
  { name: "West Virginia", value: "WV", selected: false },
  { name: "Wisconsin", value: "WI", selected: false },
  { name: "Wyoming", value: "WY", selected: false },
];

export default PayeeStates;
