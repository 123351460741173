import { SnapIndicator } from "suit";

type LabelValueProps = {
  color?: "gray" | "white" | "red" | "yellow" | "green" | "blue";
  label: string;
  value: string;
  labelColor: string;
  valueColor: string;
  className?: string;
  bgColor?: string;
};

function LabelValue({
  color,
  label,
  value,
  labelColor,
  valueColor,
  className,
  bgColor,
}: LabelValueProps) {
  return (
    <div className={`flex ${className} ${bgColor}`}>
      {color && (
        <SnapIndicator color={color} className="self-center mr-1" size="tiny" />
      )}
      <p className={`mr-auto text-sm font-bold ${labelColor}`}>{label}</p>
      <p className={`text-sm font-semibold ${valueColor}`}>{value}</p>
    </div>
  );
}

export default LabelValue;
