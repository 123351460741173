import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";

const DebitCardStates: Array<SnapSelectMenuOption> = [
  { name: "AL", value: "AL", selected: false },
  { name: "AK", value: "AK", selected: false },
  { name: "AZ", value: "AZ", selected: false },
  { name: "AR", value: "AR", selected: false },
  { name: "CA", value: "CA", selected: false },
  { name: "CO", value: "CO", selected: false },
  { name: "CT", value: "CT", selected: false },
  { name: "DE", value: "DE", selected: false },
  { name: "FL", value: "FL", selected: false },
  { name: "GA", value: "GA", selected: false },
  { name: "HI", value: "HI", selected: false },
  { name: "ID", value: "ID", selected: false },
  { name: "IL", value: "IL", selected: false },
  { name: "IN", value: "IN", selected: false },
  { name: "IA", value: "IA", selected: false },
  { name: "KS", value: "KS", selected: false },
  { name: "KY", value: "KY", selected: false },
  { name: "LA", value: "LA", selected: false },
  { name: "ME", value: "ME", selected: false },
  { name: "MD", value: "MD", selected: false },
  { name: "MA", value: "MA", selected: false },
  { name: "MI", value: "MI", selected: false },
  { name: "MN", value: "MN", selected: false },
  { name: "MS", value: "MS", selected: false },
  { name: "MO", value: "MO", selected: false },
  { name: "MT", value: "MT", selected: false },
  { name: "NE", value: "NE", selected: false },
  { name: "NV", value: "NV", selected: false },
  { name: "NH", value: "NH", selected: false },
  { name: "NJ", value: "NJ", selected: false },
  { name: "NM", value: "NM", selected: false },
  { name: "NY", value: "NY", selected: false },
  { name: "NC", value: "NC", selected: false },
  { name: "ND", value: "ND", selected: false },
  { name: "OH", value: "OH", selected: false },
  { name: "OK", value: "OK", selected: false },
  { name: "OR", value: "OR", selected: false },
  { name: "PA", value: "PA", selected: false },
  { name: "RI", value: "RI", selected: false },
  { name: "SC", value: "SC", selected: false },
  { name: "SD", value: "SD", selected: false },
  { name: "TN", value: "TN", selected: false },
  { name: "TX", value: "TX", selected: false },
  { name: "UT", value: "UT", selected: false },
  { name: "VT", value: "VT", selected: false },
  { name: "VA", value: "VA", selected: false },
  { name: "WA", value: "WA", selected: false },
  { name: "WV", value: "WV", selected: false },
  { name: "WI", value: "WI", selected: false },
  { name: "WY", value: "WY", selected: false },
];

export default DebitCardStates;
