import React from 'react';
import { buttonDivStyle, buttonStyle } from './internal';

interface NewPmToggleProps {
  setUseNewPm: (value: boolean) => void;
  useNewPm: boolean;
}

export default function NewPmToggle({
  setUseNewPm,
  useNewPm,
}: NewPmToggleProps) {
  const pmSetter = () => {
    setUseNewPm(!useNewPm);
  };
  return (
    <div style={buttonDivStyle} className="toggle-new-pm">
      <button type="button" onClick={pmSetter} style={buttonStyle}>
        Use {useNewPm ? 'Existing' : 'New'} Payment Method
      </button>
    </div>
  );
}
