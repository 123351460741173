import FixedModal from "shared-components/fixed-modal/fixed-modal";
import SendCheck from "../send-check";
import useModal from "hooks/use-modal";
import ACHCredit from "../ach-credit";

type SendMoneyProps = {
  isSendMoneyOpen: boolean;
  sendMoneyToggle: () => void;
  type: "Program" | "Group";
};

function SendMoney({ isSendMoneyOpen, sendMoneyToggle, type }: SendMoneyProps) {
  const { isOpen: isSendCheckOpen, toggle: sendCheckToggle } = useModal();
  const { isOpen: isSendACHOpen, toggle: sendACHToggle } = useModal();

  return (
    <>
      {!isSendCheckOpen && !isSendACHOpen && (
        <FixedModal
          isOpen={isSendMoneyOpen}
          toggle={sendMoneyToggle}
          title={"Send Money"}
          icon="cash-solid"
          iconColor="#DBEAFE"
          btn1={{
            text: "Check",
            btnStyle: "secondary",
            onClick: () => {
              sendCheckToggle();
            },
          }}
          btn2={{
            text: "ACH",
            btnStyle: "primary",
            onClick: () => {
              sendACHToggle();
            },
          }}
        >
          <p className=" text-sm text-gray-500">
            Which method would you like to send money?
          </p>
        </FixedModal>
      )}
      {isSendCheckOpen && (
        <SendCheck
          isSendCheckOpen={isSendCheckOpen}
          sendCheckToggle={sendCheckToggle}
          sendMoneyToggle={sendMoneyToggle}
          type={type}
        />
      )}
      {isSendACHOpen && (
        <ACHCredit
          isSendACHOpen={isSendACHOpen}
          sendACHToggle={sendACHToggle}
          sendMoneyToggle={sendMoneyToggle}
          type={type}
        />
      )}
    </>
  );
}

export default SendMoney;
