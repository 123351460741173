import {
  DropdownMenuItem,
  PieChartItem,
} from "@snap-mobile/snap-ui/dist/types/utils";
import { FormatMoney } from "helpers/format-money";
import useModal from "hooks/use-modal";
import { useEffect, useState } from "react";
import BadgedTabs from "shared-components/badged-tabs";
import Divider from "shared-components/divider";
import CustomModal from "shared-components/modal";
import Spinner from "shared-components/spinner";
import { SnapPieChart, SnapTable } from "suit";
import TableRowLg from "./table-row-lg";
import TableRowSm from "./table-row-sm";
import { BudgetTotalValue, budgetItemDetails } from "types/budget-summary";

type TablePieChartProps = {
  budgetData: any[];
  budgetTotal: BudgetTotalValue;
  summaryName: string;
  budgetedIncomeData: PieChartItem[];
  reconciledIncomeBudgetData: PieChartItem[];
  handleSetModal: (selectedItem: budgetItemDetails) => void;
  type: "Income" | "Expense";
};

function TablePieChart({
  budgetData,
  budgetTotal,
  summaryName,
  budgetedIncomeData,
  reconciledIncomeBudgetData,
  handleSetModal,
  type,
}: TablePieChartProps) {
  const { isOpen, toggle } = useModal();
  const [selectedTab, setSelectedTab] = useState(-1);
  const [pieOptions, setPieOptions] =
    useState<PieChartItem[]>(budgetedIncomeData);
  const [pieTabs, setPieTabs] = useState<DropdownMenuItem[]>([]);

  useEffect(() => {
    handleSetPieOptions(selectedTab);
    // eslint-disable-next-line
  }, [selectedTab, budgetData]);

  useEffect(() => {
    setTimeout(() => {
      setSelectedTab(0);
    }, 1000);
    let tabs: DropdownMenuItem[] = [];
    if (budgetTotal.budgetTotal !== 0) {
      tabs.push({
        name: "Budgeted",
        text: "Budgeted",
        value: "Budgeted",
        selected: true,
      });
    }
    if (budgetTotal.reconciledTotal !== 0) {
      tabs.push({
        name: "Reconciled",
        text: "Reconciled",
        value: "Reconciled",
        selected: false,
      });
    }
    setPieTabs(tabs);
    // eslint-disable-next-line
  }, [budgetTotal, setSelectedTab]);

  const handleSetPieOptions = (tabSelected: number) => {
    setPieOptions(
      tabSelected === 0 ? budgetedIncomeData : reconciledIncomeBudgetData
    );
  };

  return (
    <div className={`${summaryName === "Expense Summary" && "mt-8"}`}>
      {/* RESPONSIVE SM- */}
      <div className="lg:hidden pt-4">
        <div className="text-lg font-semibold text-gray-500">{summaryName}</div>
        <div className="flex justify-between mt-3">
          <p className="text-xs font-bold text-gray-500">Total Budgeted</p>
          <p className="text-sm font-semibold">
            {FormatMoney(budgetTotal.budgetTotal)}
          </p>
        </div>
        <div className="flex justify-between mt-3">
          <p className="text-xs font-bold text-gray-500">Total Reconciled</p>
          <p className="text-sm font-semibold">
            {FormatMoney(budgetTotal.reconciledTotal)}
          </p>
        </div>
        <div
          className="flex text-blue-600 mt-4 cursor-pointer"
          onClick={toggle}
        >
          <p className="text-base font-bold">
            View Full {summaryName.includes("Income") ? "Income" : "Expense"}{" "}
            Summary
          </p>
        </div>
      </div>
      {/* RESPONSIVE MD+ */}
      <div className="hidden lg:grid grid-cols-2">
        <SnapTable>
          <table className="celled table-auto">
            <thead>
              <tr>
                <th className="text-base whitespace-nowrap w-[23%] capitalize">
                  {summaryName}
                </th>
                <th className="text-right w-[15%]">Budgeted</th>
                <th className="text-right w-[15%]">Reconciled</th>
                <th className="w-[12%]">{/* Action */}</th>
              </tr>
            </thead>
            <tbody>
              {budgetData &&
                budgetData.map((b: any, idx: number) => {
                  return (
                    <TableRowLg
                      key={idx}
                      name={b.name}
                      budgetAmount={b.budgetAmount}
                      budgetReconciled={b.budgetReconciled}
                      expandedData={b.expandedData}
                      handleSetModal={handleSetModal}
                      type={type}
                    />
                  );
                })}
              <tr key={"expenseSumRow"} className="h-12 bg-[#F1F5F9]">
                <td className="font-bold">
                  {summaryName === "Income Summary"
                    ? "Income Total"
                    : "Expense Total"}
                </td>
                <td className="text-gray-700 text-right">
                  {FormatMoney(budgetTotal.budgetTotal)}
                </td>
                <td className="text-gray-700 text-right">
                  {FormatMoney(budgetTotal.reconciledTotal)}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </SnapTable>
        <div className="flex flex-col self-center">
          {selectedTab === -1 ? (
            <Spinner />
          ) : (
            <>
              {budgetData.length !== 0 && (
                <>
                  <SnapPieChart
                    height={280}
                    innerRadius={0}
                    options={pieOptions}
                  />
                  <div className="self-center" id="pieTabs">
                    <BadgedTabs
                      tabs={pieTabs}
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {summaryName === "Income Summary" && <Divider isVisibleOnMobile />}
      <CustomModal isOpen={isOpen} toggle={toggle} title={summaryName}>
        <div className="modal-card">
          <div className="flex justify-between ">
            <p className="text-xs font-bold text-gray-500">Total Budgeted</p>
            <p className="text-sm font-semibold">
              {FormatMoney(budgetTotal.budgetTotal)}
            </p>
          </div>
          <div className="flex justify-between mt-3">
            <p className="text-xs font-bold text-gray-500">Total Reconciled</p>
            <p className="text-sm font-semibold">
              {FormatMoney(budgetTotal.reconciledTotal)}
            </p>
          </div>
          {budgetData &&
            budgetData.map((b: any, idx: number) => {
              return (
                <TableRowSm
                  key={idx}
                  name={b.name}
                  budgetAmount={b.budgetAmount}
                  budgetReconciled={b.budgetReconciled}
                  expandedData={b.expandedData}
                  handleSetModal={handleSetModal}
                  type={type}
                />
              );
            })}
        </div>
      </CustomModal>
    </div>
  );
}

export default TablePieChart;
