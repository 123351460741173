import { SpendGroup } from "graphql/generated";
import Divider from "shared-components/divider";
import { SnapInput, SnapRadioButton } from "suit";
import { StepProps } from ".";

type StepPropsWithGroups = StepProps & {
  groups: SpendGroup[];
};

function StepOne({
  selectedDetails,
  setSelectedDetails,
  groups,
}: StepPropsWithGroups) {
  return (
    <div>
      <p className="text-lg font-semibold mt-4">Select Group</p>
      <p className="text-sm text-gray-500 mb-4">
        Please specify the group this payment applies to.
      </p>
      <SnapInput _id={""} placeholder="Search this page" />
      {groups.map((team, idx) => {
        return (
          <div
            key={idx}
            className={`flex lg:items-center px-6 py-4 border rounded-lg mt-4 ${
              selectedDetails.team?.name === team.name &&
              "border-blue-400 bg-blue-50"
            }`}
          >
            <SnapRadioButton
              checked={selectedDetails.team?.id === team.id}
              onClick={() =>
                setSelectedDetails({ ...selectedDetails, team: team })
              }
              className="mt-1 lg:mt-0"
            />
            <div className="w-full lg:flex lg:items-center">
              <p className="mr-auto font-semibold">{team.name}</p>
              <Divider isVisibleOnMobile className="lg:hidden" />
              <div className="flex mt-2 lg:flex-col">
                <p className="text-xs font-bold text-gray-500 self-end">
                  Season
                </p>
                <p className="text-sm font-semibold text-end ml-auto lg:ml-0">
                  {team.seasons?.at(0)?.name}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default StepOne;
