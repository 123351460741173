import { useContext } from "react";
import CustomModal, { BtnState, BtnType } from "shared-components/modal";
import { SnapAlert, SnapAlertDescription, SnapAlertTitle } from "suit";
import ToastContext from "context/toast-context";
import { useMutation } from "@apollo/client";
import { REMOVE_PARTICIPANT_FROM_GROUP_ROSTER } from "graphql/mutations/group";
import SeasonContext from "context/season-context";
import { SpendRosterResend } from "types/group-roster";
import { useLocation } from "react-router-dom";
import { PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON } from "graphql/queries/payment-schedule";
type RemoveParticipantProps = {
  removeParticipantOpen: boolean;
  removeParticipantToggle: () => void;
  selectedItems: SpendRosterResend[];
  deselectAllAction: () => void;
};

function RemoveParticipant({
  removeParticipantOpen,
  removeParticipantToggle,
  selectedItems,
  deselectAllAction,
}: RemoveParticipantProps) {
  const Toast = useContext(ToastContext);
  const Season = useContext(SeasonContext);
  const location = useLocation();
  const [removeParticipants, { loading }] = useMutation(
    REMOVE_PARTICIPANT_FROM_GROUP_ROSTER,
    {
      refetchQueries:
        location.pathname === "/people/participants"
          ? ["SpendRostersFiltered"]
          : [
              "SpendGroupRostersBySeason",
              {
                query: PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON,
                variables: {
                  seasonId: Season?.selectedSeason?.id,
                  groupIdOrSeasonId: Season?.selectedSeason?.id,
                },
                fetchPolicy: "network-only",
              },
            ],
      onCompleted: () => {
        Toast?.setToast({
          message: "Participant(s) have been removed.",
          type: "success",
        });
        removeParticipantToggle();
        deselectAllAction();
      },
    }
  );

  const handleRemoveParticipant = () => {
    const groupRosterIds = selectedItems.map((item) => item.groupRosterId);
    removeParticipants({
      variables: {
        input: {
          groupRosterIds: groupRosterIds,
        },
      },
    });
  };
  const btn1: BtnType = {
    text: "Remove",
    btnStyle: "danger",
    onClick: () => handleRemoveParticipant(),
    btnState: loading ? BtnState.DISABLED : BtnState.BASE,
  };

  const btn2: BtnType = {
    text: "Cancel",
    btnStyle: "tertiary",
    onClick: removeParticipantToggle,
  };

  return (
    <CustomModal
      isOpen={removeParticipantOpen}
      toggle={removeParticipantToggle}
      title={"Remove Participants"}
      btn1={btn1}
      btn2={btn2}
    >
      <div className="modal-card pl-6">
        <SnapAlert type="warning" className="mb-3">
          <SnapAlertTitle>
            Are you sure you want to remove the {selectedItems.length} selected{" "}
            {selectedItems.length > 1 ? "participants" : "participant"} from
            this team?
          </SnapAlertTitle>
          <SnapAlertDescription>
            <p>
              This will delete all unpaid invoices for this season from the
              removed participant or participants.
            </p>
            <p className="my-1">
              These unpaid invoices will not be billed nor used in the
              calculation of Upcoming and Past Due amounts.
            </p>
            <p>
              Parents of removed participants will be notified via email (unless
              the player was tagged as No Invite Sent or Not Signed Up).
            </p>
          </SnapAlertDescription>
        </SnapAlert>
      </div>
    </CustomModal>
  );
}

export default RemoveParticipant;
