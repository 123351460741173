export const invoiceSortingOptions = [
  {
    name: "Due Date (Latest - Earliest)",
    text: "Due Date (Latest - Earliest)",
    value: "dueDate:DESC",
  },
  {
    name: "Due Date (Earliest - Latest)",
    text: "Due Date (Earliest - Latest)",
    value: "dueDate:ASC",
  },
  {
    name: "Description",
    text: "Description",
    value: "description:ASC",
  },
  {
    name: "Status",
    text: "Status",
    value: "status:ASC",
  },
  {
    name: "Group",
    text: "Group",
    value: "groupName:ASC",
  },
  {
    name: "Season",
    text: "Season",
    value: "seasonName:ASC",
  },
  {
    name: "Invoice Amount (Highest - Lowest)",
    text: "Invoice Amount (Highest - Lowest)",
    value: "amount:DESC",
  },
  {
    name: "Invoice Amount (Lowest - Highest)",
    text: "Invoice Amount (Lowest - Highest)",
    value: "amount:ASC",
  },
];
