import { useMutation } from "@apollo/client";
import GroupContext from "context/group-context";
import ToastContext from "context/toast-context";
import {
  DELETE_GROUP_PLAID_ACCESS,
  DELETE_ORGANIZATION_PLAID_ACCESS,
  DELETE_USER_PLAID_ACCESS,
} from "graphql/mutations/plaid";
import { GET_GROUP_BANK_ACCOUNTS } from "graphql/queries/group";
import { GET_ORG_BANK_ACCOUNTS } from "graphql/queries/organization";
import {
  CREATE_GROUP_PLAID_LINK,
  CREATE_ORGANIZATION_PLAID_LINK,
  CREATE_USER_PLAID_LINK,
} from "graphql/queries/plaid";
import { USER_BANK_ACCOUNTS } from "graphql/queries/user";
import { ToastType } from "hooks/use-toast";
import { useContext, useEffect } from "react";
import FixedModal from "shared-components/fixed-modal/fixed-modal";

type RemoveAccountProps = {
  removeOpen: boolean;
  removeToggle: () => void;
  toast?: Omit<ToastType, "isToastOpen">;
  accountType: "program" | "group";
  groupId: string;
};

function RemoveAccount({
  removeOpen,
  removeToggle,
  accountType,
  groupId,
}: RemoveAccountProps) {
  const ActiveGroup = useContext(GroupContext);
  const Toast = useContext(ToastContext);
  const [deleteOrgPlaidAccess, { loading, data }] = useMutation(
    accountType === "program"
      ? DELETE_ORGANIZATION_PLAID_ACCESS
      : accountType === "group"
      ? DELETE_GROUP_PLAID_ACCESS
      : DELETE_USER_PLAID_ACCESS,
    {
      variables: { spendGroupBankAccessTokenDeleteId: groupId },
      refetchQueries:
        accountType === "program"
          ? [
              {
                query: GET_ORG_BANK_ACCOUNTS,
                fetchPolicy: "network-only",
              },
              {
                query: CREATE_ORGANIZATION_PLAID_LINK,
              },
            ]
          : accountType === "group"
          ? [
              {
                query: GET_GROUP_BANK_ACCOUNTS,
                variables: {
                  groupId: ActiveGroup?.activeGroup?.id,
                },
                fetchPolicy: "network-only",
              },
              {
                query: CREATE_GROUP_PLAID_LINK,
                variables: {
                  spendGroupBankLinkTokenCreateId: ActiveGroup?.activeGroup?.id,
                },
              },
            ]
          : [
              {
                query: USER_BANK_ACCOUNTS,
                fetchPolicy: "network-only",
              },
              {
                query: CREATE_USER_PLAID_LINK,
              },
            ],
      fetchPolicy: "network-only",
    }
  );
  useEffect(() => {
    if (!loading && data) {
      Toast?.setToast({
        message: "Bank link removed successfully",
        type: "success",
      });
      removeToggle();
    }
    // eslint-disable-next-line
  }, [loading, data]);

  return (
    <FixedModal
      isOpen={removeOpen}
      toggle={removeToggle}
      title={"Remove Account"}
      icon="exclamation-solid"
      iconColor="#FEE2E2"
      btn1={{
        text: "Remove Link",
        btnStyle: "danger",
        onClick: () => {
          deleteOrgPlaidAccess();
        },
      }}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: removeToggle,
      }}
    >
      <div>
        <p className="text-center text-sm text-gray-500">
          Are you sure you want to remove the link to this bank account?
        </p>
      </div>
    </FixedModal>
  );
}

export default RemoveAccount;
