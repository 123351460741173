import { SpendSortInput, SpendSortOrderEnum } from "../graphql/generated";
import { useState } from "react";

export const useSpendPagination = () => {
  const [sort, setSort] = useState<SpendSortInput | undefined>(undefined);
  const [page, setPage] = useState(0);

  const toggleSort = (sortField: string) => {
    setSort({
      field: sortField,
      order:
        sortField !== sort?.field
          ? SpendSortOrderEnum.Asc
          : sort?.order === SpendSortOrderEnum.Asc
          ? SpendSortOrderEnum.Desc
          : SpendSortOrderEnum.Asc,
    });
  };
  const toggleMobileSort = (sortField: string, direction: string) => {
    setSort({
      field: sortField,
      order:
        direction === "ASC" ? SpendSortOrderEnum.Asc : SpendSortOrderEnum.Desc,
    });
  };

  const handleSortValue = (sortValue: string) => {
    const [field, order] = sortValue.split(":");
    if (field) {
      setSort({
        field: field,
        order: (order || SpendSortOrderEnum.Asc) as SpendSortOrderEnum,
      });
    } else {
      console.log(`invalid sort value ${sortValue}`);
    }
  };
  const sortValue = sort?.field
    ? sort.field + ":" + (sort?.order || "asc")
    : "";

  return {
    sort,
    sortValue,
    page,
    setPage,
    setSort, // it not need to exposed, since we have toggleSort and handleSortValue.
    toggleSort,
    handleSortValue,
    toggleMobileSort,
  };
};
