import { SpendGroupRoster } from "graphql/generated";
import TableRowLabelValue from "shared-components/table-row-label-value";
type SelectedPlayerProps = {
  playerSelected?: SpendGroupRoster;
};
export const SelectedPlayer = ({ playerSelected }: SelectedPlayerProps) => {
  return (
    <table className="w-full">
      <tbody>
        <TableRowLabelValue label="Group" value={playerSelected?.group?.name} />
        <TableRowLabelValue
          label="Season"
          value={playerSelected?.season?.name}
        />
        <TableRowLabelValue
          label="Participant"
          value={playerSelected?.roster?.name}
        />
      </tbody>
    </table>
  );
};
