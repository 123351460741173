import { gql } from "@apollo/client";

export const CREATE_CREDIT_MEMO = gql`
  mutation SpendCreditMemoCreate($input: SpendCreditMemoInput!) {
    spendCreditMemoCreate(input: $input) {
      ids
    }
  }
`;

export const UPDATE_CREDIT_MEMO = gql`
  mutation SpendCreditMemoUpdate($input: SpendCreditMemoUpdateInput!) {
    spendCreditMemoUpdate(input: $input) {
      id
    }
  }
`;
