import { useMutation } from "@apollo/client";
import GroupContext from "context/group-context";
import ToastContext from "context/toast-context";
import { SpendCounterparty } from "graphql/generated";
import { DELETE_GROUP_COUNTERPARTY } from "graphql/mutations/group";
import { DELETE_ORG_COUNTERYPARTY } from "graphql/mutations/organization";
import { ToastProp } from "hooks/use-toast";
import { useContext, useEffect } from "react";
import FixedModal from "shared-components/fixed-modal/fixed-modal";

type DeleteCounterpartyProps = {
  isDeleteOpen: boolean;
  toggleDelete: () => void;
  selectedCounterparty: SpendCounterparty | undefined;
  type: "Program" | "Group";
};

function DeleteCounterparty({
  isDeleteOpen,
  toggleDelete,
  selectedCounterparty,
  type,
}: DeleteCounterpartyProps) {
  const group = useContext(GroupContext);
  const toast = useContext(ToastContext);

  const successToast: ToastProp = {
    message: "Payee successfully deleted",
    type: "success",
  };
  const errorToast: ToastProp = {
    message: "There was an error deleting the payee",
    type: "danger",
  };

  const [
    deleteOrgCounterparty,
    {
      loading: loadingDeleteOrg,
      data: deleteOrgCounterpartyData,
      error: deleteOrgCounterpartyError,
    },
  ] = useMutation(DELETE_ORG_COUNTERYPARTY, {
    refetchQueries: ["SpendGetOrganizationCounterparties"],
    fetchPolicy: "network-only",
  });
  const [
    deleteGroupCounterparty,
    {
      loading: loadingDeleteGroup,
      data: deleteGroupCounterpartyData,
      error: deleteGroupCounterpartyError,
    },
  ] = useMutation(DELETE_GROUP_COUNTERPARTY, {
    refetchQueries: ["SpendGetGroupCounterparties"],
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!loadingDeleteOrg) {
      if (
        deleteOrgCounterpartyData &&
        deleteOrgCounterpartyData.spendOrganizationCounterpartyDelete
      ) {
        toast?.setToast(successToast);
        toggleDelete();
      }

      if (deleteOrgCounterpartyError) {
        toast?.setToast(errorToast);
        toggleDelete();
      }
    }
    // eslint-disable-next-line
  }, [
    loadingDeleteOrg,
    deleteOrgCounterpartyData,
    deleteGroupCounterpartyError,
  ]);

  useEffect(() => {
    if (!loadingDeleteGroup) {
      if (
        deleteGroupCounterpartyData &&
        deleteGroupCounterpartyData.spendGroupCounterpartyDelete
      ) {
        toast?.setToast(successToast);
        toggleDelete();
      }

      if (deleteOrgCounterpartyError) {
        toast?.setToast(errorToast);
        toggleDelete();
      }
    }
    // eslint-disable-next-line
  }, [
    loadingDeleteGroup,
    deleteGroupCounterpartyData,
    deleteOrgCounterpartyError,
  ]);

  const handleDelete = () => {
    if (selectedCounterparty?.id) {
      let variables: {
        counterpartyId: string;
        groupId?: string;
      } = {
        counterpartyId: selectedCounterparty.id,
      };

      if (type === "Program") {
        deleteOrgCounterparty({
          variables,
        });
      } else {
        variables = {
          ...variables,
          groupId: group?.activeGroup?.id ?? "",
        };
        deleteGroupCounterparty({
          variables,
        });
      }
    }
  };

  return (
    <FixedModal
      isOpen={isDeleteOpen}
      toggle={toggleDelete}
      title="Delete Payee"
      icon="exclamation-solid"
      iconColor="#FEE2E2"
      btn1={{
        text: "Delete",
        btnStyle: "danger",
        onClick: handleDelete,
      }}
      btn2={{
        text: "Cancel",
        onClick: toggleDelete,
      }}
    >
      <div className="modal-card flex flex-col items-center text-gray-500">
        <p>Are you sure you want to remove</p>
        <p>{selectedCounterparty?.name}?</p>
      </div>
    </FixedModal>
  );
}

export default DeleteCounterparty;
