import React from "react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import { getClient } from "./graphql/client";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./App.router";
import { init as initFullStory, FullStory } from "@fullstory/browser";
import { SplitFactory } from "@splitsoftware/splitio-react";

import { StripeClient } from "@snap-mobile/payments-widget-frontend";
export const stripePromise = StripeClient(
  process.env.REACT_APP_STRIPE_API_KEY as string
);

const client = getClient();
// TODO: re-change to env var later.
// const ORG_ID = process.env.REACT_APP_FULLSTORY_ORG_ID || "";
initFullStory({ orgId: "190J6X" });

FullStory("setProperties", {
  type: "page",
  properties: {
    platform: "spend",
  },
});

// https://help.split.io/hc/en-us/articles/360038825091-React-SDK Split IO React reference
const split_config: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: process.env.REACT_APP_SPLIT_IO_KEY ?? "",
    key: "key",
  },
  debug: false, // change to true to debug on local
};
const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <SplitFactory config={split_config}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </ApolloProvider>
    </SplitFactory>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
