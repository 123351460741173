
import { Context, useContext } from "react";

export function useContextStrict<T>(context: Context<T>) {
  const resolved = useContext<T>(context);
  if (!resolved) {
    throw new Error(`Could not resolve ${context.displayName}`);
  }
  return resolved;
}
