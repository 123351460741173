"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.paypalApproveOrder = exports.paypalCreateOrder = void 0;
const utils_1 = require("./utils");
const paypalEndpoint = `${utils_1.endpoint}/paypal`;
const paypalCreateOrderEndpoint = `${paypalEndpoint}/orders`;
const paypalApproveEndpoint = (id) => `${paypalCreateOrderEndpoint}/${id}/approve`;
async function paypalCreateOrder(data) {
    const resp = await fetch(paypalCreateOrderEndpoint, {
        method: 'post',
        body: JSON.stringify(data),
    });
    return resp.json();
}
exports.paypalCreateOrder = paypalCreateOrder;
async function paypalApproveOrder(orderID, data) {
    const resp = await fetch(paypalApproveEndpoint(orderID), {
        method: 'post',
        body: JSON.stringify(data),
    });
    return resp.json();
}
exports.paypalApproveOrder = paypalApproveOrder;
