import { useLazyQuery, useMutation } from "@apollo/client";
import ToastContext from "context/toast-context";
import { CREATE_NOTIFICATIONS } from "graphql/mutations/notification";
import { EMAIL_INVOICE_HISTORY } from "graphql/queries/invoices";
import { useContext, useEffect, useState } from "react";
import CustomModal, { BtnState } from "shared-components/modal";
import { SnapCheckboxButton, SnapInput } from "suit";
import { ParticipantDetailType } from "..";
type EmailGuardianProps = {
  isOpen: boolean;
  toggle: () => void;
  type: string;
  participant: ParticipantDetailType;
};
type GroupType = {
  name: string;
  season: string;
  joinedDate: string;
  status: string;
  email: string;
  isSelected: boolean;
  groupId: string;
  guardian: string;
};
function EmailGuardian({
  isOpen,
  toggle,
  type,
  participant,
}: EmailGuardianProps) {
  const toast = useContext(ToastContext);

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isCC, setIsCC] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<GroupType[]>([]);
  const [userGroups, setUserGroups] = useState<GroupType[]>([]);
  const [isBtnActive, setIsBtnActive] = useState(true);

  const dedupeGroups = (groups: GroupType[]): GroupType[] => {
    const seen = new Set<GroupType["groupId"]>();
    const out: GroupType[] = [];
    for (const group of groups) {
      if (!seen.has(group.groupId)) {
        out.push(group);
        seen.add(group.groupId);
      }
    }
    return out;
  };

  const [
    createNotification,
    {
      loading: loadingNotification,
      data: notificationData,
      error: notificationError,
    },
  ] = useMutation(CREATE_NOTIFICATIONS);
  const [
    emailInvoiceHistory,
    {
      loading: loadingInvoiceHistory,
      data: invoiceHistoryData,
      error: invoiceHistoryError,
    },
  ] = useLazyQuery(EMAIL_INVOICE_HISTORY);

  useEffect(() => {
    if (participant) {
      setUserGroups(dedupeGroups(participant.Group));
    }
  }, [participant]);

  useEffect(() => {
    if (!loadingNotification) {
      if (notificationData && notificationData.spendNotificationCreate) {
        toast?.setToast({
          message: "Email(s) has been sent",
          type: "success",
        });
      } else if (notificationError) {
        toast?.setToast({
          message: "Error email not sent",
          type: "danger",
        });
        setIsBtnActive(true);
      }
    }
    // eslint-disable-next-line
  }, [loadingNotification, notificationData, notificationError]);

  useEffect(() => {
    if (!loadingInvoiceHistory) {
      if (invoiceHistoryData && invoiceHistoryData.spendInvoiceHistory) {
        toast?.setToast({
          message: "Email(s) have been sent",
          type: "success",
        });
        toggle();
      } else if (invoiceHistoryError) {
        toast?.setToast({
          message: invoiceHistoryError.message,
          type: "danger",
        });
      }
      setIsBtnActive(true);
    }
    // eslint-disable-next-line
  }, [loadingInvoiceHistory, invoiceHistoryData, invoiceHistoryError]);

  const handleCheckbox = (idx: number) => {
    let tempGroups = [...userGroups];

    let selectedGroup = {
      ...tempGroups[idx],
      isSelected: !tempGroups[idx].isSelected,
    };
    tempGroups.splice(idx, 1, selectedGroup);
    setUserGroups(dedupeGroups(tempGroups));
    let selectedGroups = tempGroups.filter((group) => group.isSelected);
    setSelectedGroups(dedupeGroups(selectedGroups));
  };

  const handleSendEmail = () => {
    if (isGroupsSelected()) {
      selectedGroups.forEach((group) => {
        if (type === "message") {
          createNotification({
            variables: {
              input: {
                email: group.email,
                groupId: group.groupId,
                isEmailCCed: isCC,
                message: message,
                subject: subject,
              },
            },
          });
        } else {
          emailInvoiceHistory({
            variables: {
              input: {
                email: group.email,
                userId: participant?.userId,
              },
            },
          });
        }
      });
      setIsBtnActive(false);
    }
  };

  const isGroupsSelected = () => {
    if (selectedGroups.length === 0) {
      toast?.setToast({
        message: "Must select an address to send email.",
        type: "danger",
      });
    }
    return selectedGroups.length !== 0;
  };

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={() => {
        setIsBtnActive(true);
        toggle();
      }}
      title={type === "history" ? "Email Payment History" : "Email Guardian"}
      btn1={{
        text: "Send",
        btnStyle: "primary",
        btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
        onClick: handleSendEmail,
      }}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: () => {
          if (toast?.isToastOpen) {
            toast.toggleToast();
          }
          setIsBtnActive(isBtnActive);
          toggle();
        },
      }}
    >
      <div className="modal-card">
        <div className="flex mb-4">
          <p className="mr-4">To</p>
          <div>
            {userGroups &&
              userGroups.map((group, idx) => {
                return (
                  <div key={idx} className="flex first:mt-0 mt-4">
                    <SnapCheckboxButton
                      className="mt-0.5"
                      checked={group.isSelected}
                      onClick={() => handleCheckbox(idx)}
                    />
                    <div>
                      <p>{`${group.guardian} <${group.email}>`}</p>
                      <p>{group.name}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        {type === "message" && (
          <>
            <SnapInput
              _id={""}
              label="Subject"
              onSnap-input-change={(e) => setSubject(e.detail.target.value)}
            />
            <div className="mt-4">
              <SnapInput
                _id={""}
                label="Message"
                onSnap-input-change={(e) => setMessage(e.detail.target.value)}
                textarea
              />
            </div>
            <SnapCheckboxButton
              label="CC my email"
              className="mt-4"
              checked={isCC}
              onClick={() => setIsCC(!isCC)}
            />
          </>
        )}
      </div>
    </CustomModal>
  );
}

export default EmailGuardian;
