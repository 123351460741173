import { useMutation } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import ToastContext from "context/toast-context";
import {
  SpendBankTransaction,
  SpendBudgetId,
  SpendCategory,
  SpendLegacyTransaction,
  useSpendTransactionReconcileV2Mutation,
} from "graphql/generated";
import { CREATE_BUDGET_ITEM } from "graphql/mutations/budget";
import { ORGANIZATION } from "graphql/queries/organization";
import { getDatePlusTime } from "helpers/current-time";
import { getDatePickerValue } from "helpers/date-picker";
import { FormatDate } from "helpers/format-date";
import { ParseMoney } from "helpers/format-money";
import { isNullOrEmpty } from "helpers/null-or-empty";
import { useContext, useEffect, useState } from "react";
import DatePicker from "shared-components/date-picker";
import InputMask from "shared-components/input-mask";
import CustomModal from "shared-components/modal";
import { SnapInput, SnapSelectMenu } from "suit";
import { selectedBudgetItem } from "types/budgets";
import { budgetError } from "types/errors";

type SpendOrLegacySpendTrx =
  | SpendBankTransaction
  | (SpendLegacyTransaction & { totalReconciled?: number; direction: string });
type AddBudgetItemProps = {
  type: "Program" | "Group";
  directionType: "Income" | "Expense";
  budgetItemOpen: boolean;
  budgetItemToggle: () => void;
  transaction?: SpendOrLegacySpendTrx;
};

function AddBudgetItem({
  type,
  directionType,
  budgetItemOpen,
  budgetItemToggle,
  transaction,
}: AddBudgetItemProps) {
  const [amount, setAmount] = useState("");
  const [newBudgetItem, setNewBudgetItem] = useState<selectedBudgetItem>({
    budgetId: undefined,
    catId: undefined,
    category: "",
    budgetItem: "",
    date: "",
    amountApplied: "",
  });
  const [hasErrors, setHasErrors] = useState<budgetError>({
    categoryError: false,
    budgetItemError: false,
    amountError: false,
    dateError: false,
  });
  const program = useContext(ProgramContext);
  const group = useContext(GroupContext);
  const toast = useContext(ToastContext);
  const [categories, setCategories] = useState<SnapSelectMenuOption[]>([]);
  const [
    addNewBudgetItem,
    { data: addBudgetData, loading: addBudgetLoading, error: addBudgetError },
  ] = useMutation<{ spendBudgetCreate: SpendBudgetId }>(CREATE_BUDGET_ITEM, {
    refetchQueries: [{ query: ORGANIZATION }],
  });
  const [
    reconcileTransaction,
    { data: reconcileData, loading: reconcileLoading, error: reconcileError },
  ] = useSpendTransactionReconcileV2Mutation({
    refetchQueries: ["SpendTransactionsWhered"],
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (
      reconcileData &&
      reconcileData.spendTransactionReconcileV2 &&
      !reconcileLoading
    ) {
      budgetItemToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reconcileData, reconcileLoading, reconcileError]);

  useEffect(() => {
    if (addBudgetData && addBudgetData.spendBudgetCreate && !addBudgetLoading) {
      if (
        transaction?.id &&
        transaction.amount &&
        addBudgetData.spendBudgetCreate.id
      ) {
        const factor =
          transaction.direction?.toLowerCase() === "credit" ? 1 : -1;
        const transactionValue =
          ((transaction.amount || 0) - (transaction.snapAmount || 0)) * factor;
        let transactionType = transactionValue <= 0 ? "Expense" : "Income";
        reconcileTransaction({
          variables: {
            input: {
              ledgerTransactionId: transaction.id,
              ledgerTransactionAmount: transaction.amount,
              budgets: [
                {
                  budgetItemId: addBudgetData.spendBudgetCreate.id,
                  amount: transaction.amount,
                },
              ],
              reverse: transactionType !== directionType,
            },
          },
        });
      }
    }
  }, [
    addBudgetData,
    addBudgetLoading,
    addBudgetError,
    transaction,
    reconcileTransaction,
    directionType,
  ]);

  useEffect(() => {
    const categories = program?.getCategories(directionType);
    if (categories) {
      setCategories(
        categories.map((category, idx) => ({
          name: `${category.name}`,
          selected: idx === 0,
        }))
      );
    }
  }, [program, directionType]);

  const saveBudgetItem = () => {
    const selectedCat = categories.filter((item) => item.selected);
    if (selectedCat.length === 0) {
      setHasErrors({ ...hasErrors, categoryError: true });
      hasErrors.categoryError = true;
    }
    if (newBudgetItem.budgetItem === "") {
      setHasErrors({ ...hasErrors, budgetItemError: true });
      hasErrors.budgetItemError = true;
    }
    if (amount === "0.00" || isNullOrEmpty(amount)) {
      setHasErrors({ ...hasErrors, amountError: true });
      hasErrors.amountError = true;
    }
    setHasErrors({
      ...hasErrors,
      dateError: isNullOrEmpty(newBudgetItem.date),
    });
    hasErrors.dateError = isNullOrEmpty(newBudgetItem.date);
    let { categoryError, budgetItemError, amountError, dateError } = hasErrors;
    if (categoryError || budgetItemError || amountError || dateError) {
      toast?.setToast({
        message: "Please fill in all the input fields",
        type: "danger",
      });
    } else {
      const programCategories = program?.getCategories(directionType);
      const selectedCatId = programCategories?.find(
        (cat: SpendCategory) => cat.name === selectedCat[0].name
      )?.id;
      const input = {
        description: newBudgetItem.budgetItem,
        isDefault: false,
        targetAmount: ParseMoney(amount),
        seasonId:
          type === "Group" ? group?.activeGroup?.currentSeason?.id : null,
        targetDateAt: getDatePlusTime(newBudgetItem.date),
        vaultId: null,
        categoryId: selectedCatId,
      };
      addNewBudgetItem({
        variables: {
          input,
        },
      });
    }
  };
  return (
    <CustomModal
      title={`Add ${directionType} Budget Item`}
      isOpen={budgetItemOpen}
      toggle={budgetItemToggle}
      btn1={{
        text: "Submit",
        btnStyle: "primary",
        onClick: () => {
          saveBudgetItem();
        },
      }}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: budgetItemToggle,
      }}
    >
      <div className="modal-card lg:grid grid-cols-2">
        <SnapSelectMenu
          label="Category"
          className="lg:mr-6"
          selectMenuOptions={categories}
          onSnap-select-menu-updated={(e) => setCategories(e.detail)}
          error={hasErrors.categoryError}
        />
        <SnapInput
          _id={""}
          label="Budget Item Name"
          className="lg:mt-0 mt-6"
          value={newBudgetItem.budgetItem}
          onSnap-input-change={(e) => {
            setNewBudgetItem({
              ...newBudgetItem,
              budgetItem: e.detail.target.value,
            });
            setHasErrors({
              ...hasErrors,
              budgetItemError: false,
            });
          }}
          error={hasErrors.budgetItemError}
          errorIcon={hasErrors.budgetItemError}
        />
        <div className="lg:mr-6 mt-6">
          <InputMask
            amount={amount}
            setAmount={setAmount}
            hasError={hasErrors.amountError}
            onChange={() => setHasErrors({ ...hasErrors, amountError: false })}
          />
        </div>
        <DatePicker
          className={"mt-6"}
          label={"Date"}
          date={getDatePickerValue(newBudgetItem.date)}
          setDate={(e) => {
            setNewBudgetItem({
              ...newBudgetItem,
              date: FormatDate(e),
            });
          }}
          hasError={hasErrors.dateError}
        />
      </div>
    </CustomModal>
  );
}

export default AddBudgetItem;
