import { createElement, ReactElement } from 'react';
import {
  Appearance,
  Layout,
  LayoutObject,
  Stripe as StripeClient,
  StripeElements,
  StripeElementsOptionsMode,
  StripeExpressCheckoutElementConfirmEvent,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import {
  StripeLayout,
  StripePaymentMethod,
} from '@snap-mobile/payments-widget-utils';

export interface WidgetAppearance {
  fontFamily?: string;
  layout?: StripeLayout;
  paymentMethodOrder?: Array<StripePaymentMethod>;
  submitButtonContent?: string | ReactElement;
}

export interface SubmitData {
  elements?: StripeElements;
  expressEvent?: StripeExpressCheckoutElementConfirmEvent;
  paymentMethodId?: string;
  stripe: StripeClient;
}

export interface PMProps {
  disabled: boolean;
  setUseNewPm: (value: boolean) => void;
  submit: (data: SubmitData) => Promise<void>;
  submitButtonContent: ReactElement;
  useNewPm: boolean;
}
export const buttonStyle = {
  border: 'none',
  background: 'none',
  borderRadius: 'none',
  backgroundColor: 'none',
  color: 'rgb(37, 99, 235)',
  cursor: 'pointer',
};

export const buttonDivStyle: {
  border: 'none';
  whiteSpace: 'nowrap';
  overflow: 'hidden';
  textOverflow: 'ellipse';
} = {
  border: 'none',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipse',
};

export function getSrId(sr: any) {
  return sr.id;
}

export interface PaymentConfiguration {
  stripeAccountId?: string;
  stripePaymentMethodConfigurationId: string;
}

const stripeAppearance = {
  theme: 'stripe' as 'stripe',
  variables: {
    fontFamily: 'Inter',
    fontSizeBase: '16px',
    spacingUnit: '6px',
    borderRadius: '8px',
    colorPrimary: '#2563EB',
    colorBackground: '#FFFFFF',
    colorText: '#1E293B',
    colorDanger: '#DC2626',
    gridRowSpacing: '20px',
  },
  rules: {
    '.Input': {
      paddingBottom: '10px',
      paddingTop: '10px',
    },
  },
};

function appearanceOptions(appearance?: WidgetAppearance): {
  layout: LayoutObject;
  paymentMethodOrder?: Array<StripePaymentMethod>;
} {
  const layout = appearance?.layout ?? StripeLayout.ACCORDION;
  switch (layout) {
    case StripeLayout.ACCORDION:
      return {
        layout: {
          type: 'accordion' as Layout,
          defaultCollapsed: false,
          radios: true,
          spacedAccordionItems: false,
        },
        paymentMethodOrder: appearance?.paymentMethodOrder,
      };
    case StripeLayout.TABS:
      return {
        layout: { type: 'tabs' as Layout, defaultCollapsed: false },
        paymentMethodOrder: appearance?.paymentMethodOrder,
      };
    default:
      throw new Error(`Invalid layout: ${layout}`);
  }
}

export function makeStripeOptions(
  paymentMethodConfiguration?: PaymentConfiguration,
  widgetAppearance?: WidgetAppearance,
  walletsEnabled: boolean = true
): {
  elementsOptions: StripeElementsOptionsMode;
  paymentElementOptions: StripePaymentElementOptions;
  submitButtonContent: ReactElement;
} {
  const { layout, paymentMethodOrder } = appearanceOptions(widgetAppearance);
  const appearance: Appearance = Object.assign({}, stripeAppearance);
  if (widgetAppearance?.fontFamily) {
    appearance.variables!.fontFamily = widgetAppearance.fontFamily;
  }
  const elementsOptions: StripeElementsOptionsMode = {
    appearance,
    currency: 'usd',
    onBehalfOf: paymentMethodConfiguration?.stripeAccountId,
    paymentMethodCreation: 'manual',
  };
  if ((widgetAppearance?.paymentMethodOrder ?? []).length > 0) {
    elementsOptions.paymentMethodTypes = widgetAppearance!.paymentMethodOrder;
  }
  const enabled: 'auto' | 'never' = walletsEnabled ? 'auto' : 'never';
  const paymentElementOptions = {
    layout,
    paymentMethodOrder,
    wallets: { applePay: enabled, googlePay: enabled },
  };
  const sbc = widgetAppearance?.submitButtonContent ?? 'Submit';
  let submitButtonContent: ReactElement;
  if (typeof sbc === 'string') {
    submitButtonContent = createElement('span', {}, sbc);
  } else {
    submitButtonContent = sbc;
  }
  return { elementsOptions, paymentElementOptions, submitButtonContent };
}
