import { SpendGroup } from "graphql/generated";
import { DateFormatSupported, FormatDate } from "./format-date";

function getGroupSubtitle(group: SpendGroup) {
  const latestSeason = group.latestSeason ?? group.seasons?.at(0);

  const startEndDate = `${FormatDate(
    latestSeason?.startDateAt || "",
    DateFormatSupported.Numbers
  )} - ${FormatDate(
    latestSeason?.endDateAt || "",
    DateFormatSupported.Numbers
  )}`;
  let subtitle = latestSeason?.name || startEndDate;
  return subtitle;
}

export default getGroupSubtitle;
