import { createElement, ReactElement } from 'react';
import {
  Appearance,
  Layout,
  LayoutObject,
  Stripe as StripeClient,
  StripeElements,
  StripeElementsOptionsMode,
  StripeExpressCheckoutElementConfirmEvent,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import {
    PaymentMethodType,
  StripeLayout,
  StripePaymentMethod,
} from '@snap-mobile/payments-widget-utils';
import { AcceptedPayments, WidgetAppearanceV2 } from '../context/types';

export interface WidgetAppearance {
  fontFamily?: string;
  layout?: StripeLayout;
  paymentMethodTypes: Array<StripePaymentMethod | "external_paypal">
}

export interface SubmitData {
  elements?: StripeElements;
  expressEvent?: StripeExpressCheckoutElementConfirmEvent;
  paymentMethodId?: string;
  stripe: StripeClient;
}

export interface PMProps {
  disabled: boolean;
  setUseNewPm: (value: boolean) => void;
  submit: (data: SubmitData) => Promise<void>;
  submitButtonContent: ReactElement;
  useNewPm: boolean;
}

export function getSrId(sr: any) {
  return sr.id;
}

export interface PaymentConfiguration {
  stripeAccountId?: string;
  stripePaymentMethodConfigurationId: string;
}

const stripeAppearance = {
  theme: 'stripe' as 'stripe',
  variables: {
    fontFamily: 'Inter',
    fontSizeBase: '16px',
    spacingUnit: '6px',
    borderRadius: '8px',
    colorPrimary: '#2563EB',
    colorBackground: '#FFFFFF',
    colorText: '#1E293B',
    colorDanger: '#DC2626',
    gridRowSpacing: '20px',
  },
  rules: {
    '.Input': {
      paddingBottom: '10px',
      paddingTop: '10px',
    },
  },
};

function appearanceOptions(appearance?: WidgetAppearanceV2, paymentMethodOrder?: StripePaymentMethod[] ): {
  layout: LayoutObject;
  paymentMethodOrder?: Array<StripePaymentMethod>;
} {
  const layout = appearance?.layout ?? StripeLayout.ACCORDION;
  switch (layout) {
    case StripeLayout.ACCORDION:
      return {
        layout: {
          type: 'accordion' as Layout,
          defaultCollapsed: false,
          radios: true,
          spacedAccordionItems: false,
        },
        paymentMethodOrder: paymentMethodOrder,
      };
    case StripeLayout.TABS:
      return {
        layout: { type: 'tabs' as Layout, defaultCollapsed: false },
        paymentMethodOrder: paymentMethodOrder,
      };
    default:
      throw new Error(`Invalid layout: ${layout}`);
  }
}

export function makeStripeOptions(
  paymentMethodConfiguration?: PaymentConfiguration,  
  widgetAppearance?: WidgetAppearanceV2,
  walletsEnabled: boolean = true,
  paymentMethodTypes: Array<AcceptedPayments> = [],
): {
  elementsOptions: StripeElementsOptionsMode;
  paymentElementOptions: StripePaymentElementOptions;
  externalPayments: string[];
} {

  const paymentMethodOrder: StripePaymentMethod[] = [];

  const { stripe: stripePmTypes, external: externalPmTypes } = paymentMethodTypes.reduce((acc, item) => {
    if (item.startsWith('external_')) {
      acc['external'].push(item);
    } else  {
        acc['stripe'].push(item as StripePaymentMethod);
    }
    
    return acc;
  }, {stripe: [], external: []} as {
    stripe: StripePaymentMethod[];
    external: string[];
  });


  const { layout } = appearanceOptions(widgetAppearance, stripePmTypes);
  const appearance: Appearance = Object.assign({}, stripeAppearance);
  if (widgetAppearance?.fontFamily) {
    appearance.variables!.fontFamily = widgetAppearance.fontFamily;
  }
  const elementsOptions: StripeElementsOptionsMode = {
    appearance,
    currency: 'usd',
    onBehalfOf: paymentMethodConfiguration?.stripeAccountId,
    paymentMethodCreation: 'manual',
    // @ts-expect-error exclude error below due to mismatch type
    externalPaymentMethodTypes: externalPmTypes.length ? externalPmTypes : undefined,    
  };
  if (stripePmTypes.length > 0) {    
    elementsOptions.paymentMethodTypes = stripePmTypes;
  }
  const enabled: 'auto' | 'never' = walletsEnabled ? 'auto' : 'never';
  const paymentElementOptions = {
    layout,
    paymentMethodOrder,
    wallets: { applePay: enabled, googlePay: enabled },    
  };

  return { elementsOptions, paymentElementOptions, externalPayments: externalPmTypes };
}
