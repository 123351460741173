import React, { useRef } from 'react';
import * as paypal from '@paypal/paypal-js';
import { V2 } from '@snap-mobile/payments-widget-client';
import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
  
} from '@paypal/react-paypal-js';
import { type PayPalButtonsComponentOptions } from '@paypal/paypal-js';

// public credential, no need for 12fa etc.
const PAYPAL_CLIENT_ID =
  'AVkgdPCdacthVN5bxdasTawhOgJEpK6WgTK25y9JpYU-_enKjF49Xg1xiBbFur5fdBg2ywigARFrxvNY';

export interface PaypalWidgetProps {
  externalPaymentId: string;
  onApprove: (data: V2.PaypalOrderConfirmResponse) => Promise<void>;
  onError: PayPalButtonsComponentOptions['onError'];
  product: string;
  secondaryId?: string;
  snapAmount: number;
  totalAmount: number;
  returnUrl: string;
  cancelUrl: string;
}

export type ComboPaypalWidgetProps = Pick<
  PaypalWidgetProps,
  'onApprove' | 'onError' | 'product'
>;

export function PaypalPaymentWidget(props: PaypalWidgetProps) {
  return (
    <div
      className="payments-widget paypal-widget"
      style={{
        fontFamily:
          'sans-serif !important; width: 100% !important; min-width: 100% !important',
      }}
    >
      <PayPalScriptProvider
        options={{
          clientId: PAYPAL_CLIENT_ID,
          vault: true,
          components: 'buttons',
          disableFunding: 'bancontact,blik,paylater,card',
        }}
      >
        <div style={{ marginLeft: '10%' }}>
          <InnerPaypalWidget {...props} />
        </div>
      </PayPalScriptProvider>
    </div>
  );
}

function InnerPaypalWidget({
  externalPaymentId,
  onApprove,
  onError,
  product,
  secondaryId,
  snapAmount,
  totalAmount,
  returnUrl,
  cancelUrl,
}: PaypalWidgetProps) {
  const [{ isPending }] = usePayPalScriptReducer();

  const createOrder = async () => {
    const data: V2.CreatePaypalOrderParams = {
      externalPaymentId,
      product,
      secondaryId,
      snapAmount,
      totalAmount,
      returnUrl,
      cancelUrl,
    };
    const resp = await V2.paypalCreateOrder(data);
    return resp.id;
  };

  const oa = async (data: paypal.OnApproveData) => {
    const { orderID, ...rest } = data;
    const resp = await V2.paypalApproveOrder(orderID!, {...rest, externalPaymentId});
    await onApprove(resp);
  };

  if (isPending) {
    return <div className="loading">Loading...</div>;
  } else {
    return (
      <PayPalButtons
        createOrder={createOrder}
        onApprove={oa}
        onError={onError}
        style={{ layout: 'vertical', tagline: false, label: 'pay' }}
      />
    );
  }
}
