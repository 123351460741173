import { PieChartItem } from "@snap-mobile/snap-ui/dist/types/utils";
import { Maybe, SpendOrganizationDebitCardCount } from "graphql/generated";
import LabelValue from "pages/groups/collections/label-value";
import { useEffect, useState } from "react";
import Spinner from "shared-components/spinner";
import { SnapPieChart } from "suit";

type TeamDebitCardsProps = {
  programDebitCards: Maybe<SpendOrganizationDebitCardCount> | undefined;
};

function TeamDebitCards({ programDebitCards }: TeamDebitCardsProps) {
  const [debitCardPieData, setDebitCardPieData] = useState<PieChartItem[]>([]);

  useEffect(() => {
    setTimeout(() => {
      setDebitCardPieData([
        {
          category: "Assigned",
          value: programDebitCards?.assigned || 0,
          color: "#60A5FA",
        },
        // {
        //   category: "Shipped",
        //   value: programDebitCards?.shipped || 0,
        //   color: "#FBBF24",
        // },
        {
          category: "Activated",
          value: programDebitCards?.activated || 0,
          color: "#34D399",
        },
      ]);
    }, 1000);
  }, [
    setDebitCardPieData,
    programDebitCards?.assigned,
    programDebitCards?.shipped,
    programDebitCards?.activated,
  ]);

  return (
    <div className="card">
      <p className="text-lg font-semibold text-gray-500 lg:text-gray-800">
        Group Debit Cards
      </p>
      <div className="grid grid-cols-2">
        <div className="w-[70%]">
          <p className="mt-4 text-5xl front-medium lg:mt-6">
            {programDebitCards?.assigned != null &&
            programDebitCards.activated != null
              ? programDebitCards?.assigned + programDebitCards?.activated
              : "0"}
          </p>
          <LabelValue
            color="blue"
            label="Assigned"
            value={programDebitCards?.assigned?.toString() || "0"}
            valueColor={"text-gray-800"}
            labelColor={"text-gray-500"}
            className="mt-4 lg:mt-9"
          />
          {/* <LabelValue
            color="yellow"
            label="Shipped"
            value={programDebitCards?.shipped?.toString() || "0"}
            valueColor={"text-gray-800"}
            labelColor={"text-gray-500"}
            className="mt-4"
          /> */}
          <LabelValue
            color="green"
            label="Activated"
            value={programDebitCards?.activated?.toString() || "0"}
            valueColor={"text-gray-800"}
            labelColor={"text-gray-500"}
            className="mt-4"
          />
        </div>
        <div className="hidden lg:inline-block self-center mt-2">
          {debitCardPieData.length === 0 && <Spinner />}
          <SnapPieChart
            height={200}
            innerRadius={60}
            options={debitCardPieData}
          />
        </div>
        <div className="lg:hidden mt-16 ml-16">
          {debitCardPieData.length === 0 && <Spinner />}
          <SnapPieChart
            height={60}
            innerRadius={50}
            options={debitCardPieData}
          />
        </div>
      </div>
    </div>
  );
}

export default TeamDebitCards;
