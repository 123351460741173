import React from 'react';

export default function Processing() {
  return (
    <div
      className="payments-widget-processing"
      onClick={(e) => e.stopPropagation()}
      style={{
        zIndex: 10000,
        height: '100%',
        width: '100%',
        position: 'absolute',
        color: 'white',
      }}
    >
      <p style={{ marginTop: '50%', marginBottom: '50%' }}>Processing...</p>
    </div>
  );
}