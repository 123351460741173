export const HandleRoleNameChangeToFront = (roleName: string) => {
  let roleFrontEndName;
  switch (roleName) {
    case "program_staff":
      roleFrontEndName = "Program Staff";
      break;
    case "program_observer":
      roleFrontEndName = "Program Observer";
      break;
    case "group_staff":
      roleFrontEndName = "Group Staff";
      break;
    case "group_observer":
      roleFrontEndName = "Group Observer";
      break;
    default:
      roleFrontEndName = "";
  }
  return roleFrontEndName;
};
