import { usePaymentsWidgetContext } from '../context';
import React from 'react';

/**
 * Props for `SubmitButton` Component
 * @prop {React.ReactNode} children - Optional. Custom content to be displayed inside the button.
 *                                    If not provided, a default button with the payment amount
 *                                    will be rendered.
 * @prop {React.ButtonHTMLAttributes<HTMLButtonElement>} ...props - Standard button attributes
 *                                                                 that can be passed to the component,
 *                                                                 such as `onClick`, `className`, etc.
 *
 * The component utilizes `usePaymentsWidgetContext` to access the currently selected payment method
 * and payment data. If the selected payment method begins with 'external_', the button is not rendered.
 * This behavior allows for the integration of external payment methods that might require a different
 * submission process.
 *
 * By default, if no children are provided, the button displays the total payment amount, extracted from
 * the payment data in the widget context.
 *
 * Example Usage:
 * <SubmitButton>
 *   <CustomButton type='submit'>text here</CustomButton>
 * </SubmitButton>
 * or
 * <SubmitButton onClick={customClickHandler} />
 * or
 * <SubmitButton className="custom-class" />
 */
export const SubmitButton = ({
  children,
  ...props
}: React.PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>) => {
  const { selectedPaymentMethod, paymentData } = usePaymentsWidgetContext();

  // Do not render this button when it is external payment method
  if (selectedPaymentMethod.startsWith('external_')) {
    return null;
  }

  return children ?? <button {...props} type="submit">Pay ${paymentData.totalAmount/100}</button>;
};
