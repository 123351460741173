import { CustomExpressButtonTypes } from '../CustomExpressCheckout';
import styles from './ApplePayButton.module.scss';

/**
 * `ApplePayButton` is a functional React component used for rendering an Apple Pay button.
 * It is primarily intended for initiating Apple Pay transactions.
 *
 * Props:
 * @prop {boolean} [disabled=false] - Optional. If true, the button will not be rendered.
 * @prop {() => void} [onClick] - Optional. A callback function that is called when the button is clicked.
 *                                This function is triggered after preventing the default click event.
 * @prop {CustomExpressButtonTypes} [type=CustomExpressButtonTypes.None] - Optional. Specifies the type of the Apple Pay button,
 *                                                                          which influences the button's styling. Defaults to 'None'.
 *
 * The component dynamically applies styles based on the `type` prop, choosing between 'Donate', 'Buy', and a default 'Pay' style.
 * These styles are defined in 'ApplePayButton.module.scss'.
 *
 * Behavior:
 * - When the `disabled` prop is true, the component renders nothing (returns null).
 * - On click, it prevents the default event and then executes the provided `onClick` function, if available.
 * - The button's label and style change according to the `type` prop.
 *
 * Usage Example:
 * ```jsx
 * <ApplePayButton
 *   disabled={false}
 *   onClick={() => console.log('Apple Pay Clicked')}
 *   type={CustomExpressButtonTypes.Donate}
 * />
 * ```
 */

export const ApplePayButton = ({
  disabled,
  onClick,
  type = CustomExpressButtonTypes.None,
}: {
  disabled?: boolean;
  onClick?: () => void;
  type?: CustomExpressButtonTypes;
}): React.JSX.Element | null => {
  if (disabled) {
    return null;
  }

  const oc = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClick?.();
  };
  const typeStyling =
    type === CustomExpressButtonTypes.Donate
      ? styles.buttonDonate
      : type === CustomExpressButtonTypes.Buy
      ? styles.buttonBuy
      : styles.buttonPay;
  const buttonStyle = [styles.button, typeStyling].join(' ');

  return (
    <button
      disabled={disabled}
      onClick={oc}
      className={`${styles.button} ${typeStyling}`}
      aria-label={`Apple Pay ${type}`}
    >
      {type !== CustomExpressButtonTypes.None ? (
        <span className={styles.buttonText}>{type} with</span>
      ) : null}
      <span className={styles.buttonIcon}></span>
      <span className={styles.buttonText}>Pay</span>
    </button>
  );
};
