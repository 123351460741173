import { useEffect } from "react";

type UnAuthorizedProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

function UnAuthorized({ setIsLoading }: UnAuthorizedProps) {
  useEffect(() => {
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex justify-center items-center h-screen">
      <div>
        <p className="flex justify-center font-bold">Not Authorized</p>
        <p className="flex justify-center">
          You do not have permission to access this page.
        </p>
        <p>
          If you feel you have reached this page in error, please contact
          support.
        </p>
      </div>
    </div>
  );
}

export default UnAuthorized;
