import { gql } from "@apollo/client";

export const CREATE_PAYMENT_SCHEDULE = gql`
  mutation SpendPaymentScheduleCreate($input: SpendPaymentScheduleInput!) {
    spendPaymentScheduleCreate(input: $input) {
      id
    }
  }
`;

export const UPDATE_PAYMENT_SCHEDULE = gql`
  mutation SpendPaymentScheduleUpdate(
    $spendPaymentScheduleUpdateId: String!
    $input: SpendPaymentScheduleInput!
  ) {
    spendPaymentScheduleUpdate(
      id: $spendPaymentScheduleUpdateId
      input: $input
    ) {
      id
    }
  }
`;

export const MAKE_ACH_PAYMENT = gql`
  mutation SpendUserAchPayment($input: SpendAchPaymentInput!) {
    spendUserAchPayment(input: $input) {
      id
      status
      amount
      descriptor
      transactionIdList
    }
  }
`;

export const CANCEL_ACH_PAYMENT = gql`
  mutation SpendUserAchPaymentCancel($input: SpendAchPaymentCancelInput!) {
    spendUserAchPaymentCancel(input: $input) {
      id
    }
  }
`;

export const DELETE_USER_CARD = gql`
  mutation DeleteCardPayment($input: PaymentsApiDetachPaymentMethodInput) {
    paymentsApiDetachPaymentMethod(input: $input) {
      paymentMethodId
    }
  }
`;

export const DETACH_PAYMENT_METHODS = gql`
  mutation PaymentsApiDetachPaymentMethod(
    $input: PaymentsApiDetachPaymentMethodInput
  ) {
    paymentsApiDetachPaymentMethod(input: $input) {
      stripeEnv
      paymentMethodId
    }
  }
`;
export const PAYMENT_METHOD_GET_OR_CREATE = gql`
  mutation PaymentsApiCustomer {
    paymentsApiCustomer: paymentsApiCustomerGetOrCreate(stripeEnv: SPEND) {
      customerId
      email
      name
      paymentMethods {
        billingAddress {
          city
          country
          line1
          line2
          postalCode
          state
        }
        brand
        expiration {
          month
          year
        }
        id
        identifier
        type
        zipCode
      }
    }
  }
`;
