import { ExpressCheckoutElement } from '@stripe/react-stripe-js';
import type {
  PaymentIntent,
  StripeExpressCheckoutElementClickEvent,
  StripeExpressCheckoutElementConfirmEvent,
  StripeExpressCheckoutElementReadyEvent,
} from '@stripe/stripe-js';
import { usePaymentsCustomer } from '../hooks/usePaymentsCustomer';
import { usePaymentsConfirmElement } from '../hooks/usePaymentsConfirmElement';
import { usePaymentsWidgetContext } from '../context';

export type ExpressCheckOutProps = {
  disabled?: boolean;
  onReady?: (e: StripeExpressCheckoutElementReadyEvent) => void;
  onClick?: (e: StripeExpressCheckoutElementClickEvent) => void;
  disabledOnClick?: () => void;
  onError?: (e?: string) => void;
  onSuccess?: (e?: PaymentIntent | null) => void;
  wallets: { applePay: 'auto' | 'never'; googlePay: 'auto' | 'never' };
};

/**
 * Props for ExpressCheckout Component
 *
 * @prop {boolean} disabled - Indicates whether the ExpressCheckoutElement is disabled.
 *                            When true, the element will not respond to user interactions.
 * @prop {function} onReady - Optional callback function that is called when the
 *                            ExpressCheckoutElement is fully loaded and ready to be interacted with.
 *                            Receives a StripeExpressCheckoutElementReadyEvent as its argument.
 * @prop {function} onClick - Optional callback function that is called when the
 *                            ExpressCheckoutElement is clicked. Must be resolved within 1 second.
 *                            Receives a StripeExpressCheckoutElementClickEvent as its argument. See onClick documentation for more details.
 * @prop {function} disabledOnClick - Optional callback function that is called when the express element is clicked while disabled is true. Use this to trigger form validation.
 * @prop {function} onError - Optional callback function that is called when there is an error
 *                            during the submission process. Receives an error message string as its argument.
 * @prop {{applePay: 'auto' | 'never', googlePay: 'auto' | 'never'}} wallets - Configuration object for
 *                                                                              specifying the behavior of payment wallets like Apple Pay and Google Pay.
 *                                                                              The values 'auto' or 'never' determine whether the wallet is available or not.
 */
export const ExpressCheckout = (props: ExpressCheckOutProps) => {
  const { onReady, disabled, wallets, onSuccess, disabledOnClick, onClick } =
    props;

  const { createCustomer } = usePaymentsCustomer();
  const { confirmPayment } = usePaymentsConfirmElement();
  const { setProcessing } = usePaymentsWidgetContext();

  const onConfirm = async (e: StripeExpressCheckoutElementConfirmEvent) => {
    setProcessing(true);
    const cus = await createCustomer();
    const paymentConfirmResponse = await confirmPayment(cus, e);
    if(paymentConfirmResponse){
      onSuccess?.(paymentConfirmResponse);
    }
    setProcessing(false);
  };

  const oc = (e: StripeExpressCheckoutElementClickEvent) => {
    if (disabled) {
      disabledOnClick?.();
      return;
    }
    e.resolve();
  };

  return (
    <ExpressCheckoutElement
      onConfirm={onConfirm}
      onReady={onReady}
      onClick={(e) => onClick?.(e) ?? oc(e)}
      options={{
        wallets,
      }}
    />
  );
};
