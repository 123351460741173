import { SpendBankTransaction } from "graphql/generated";
import FixedModal, { BtnState } from "./fixed-modal";
import { useMutation, useQuery } from "@apollo/client";
import { CANCEL_ORG_CHECK } from "graphql/mutations/organization";
import { CANCEL_GROUP_CHECK } from "graphql/mutations/group";
import { useContext, useEffect, useState } from "react";
import { CANCEL_ACH_PAYMENT } from "graphql/mutations/payment";
import ToastContext from "context/toast-context";
import { GET_TRANSACTION_BY_ID } from "graphql/queries/transactions";
import { SpinnerContainer } from "shared-components/spinner";

type RequestCancelProps = {
  requestCancelOpen: boolean;
  requestCancelToggle: () => void;
  focusOnTransaction: SpendBankTransaction | undefined;
  type: "Program" | "Group";
};

function RequestCancel({
  requestCancelOpen,
  requestCancelToggle,
  focusOnTransaction,
  type,
}: RequestCancelProps) {
  const toast = useContext(ToastContext);

  const [isCancelable, setIsCancelable] = useState(true);
  const [checkStatus, setCheckStatus] = useState(undefined);

  const { data: transactionData, loading: loadingTransactionData } = useQuery(
    GET_TRANSACTION_BY_ID,
    {
      variables: { spendTransactionId: focusOnTransaction?.id },
      fetchPolicy: "no-cache",
    }
  );

  const [cancelCheck, { loading, data, error }] = useMutation(
    type === "Program" ? CANCEL_ORG_CHECK : CANCEL_GROUP_CHECK
  );

  const [
    cancelACH,
    { loading: loadingCancelAch, data: cancelACHData, error: cancelACHError },
  ] = useMutation(CANCEL_ACH_PAYMENT);

  useEffect(() => {
    if (!loading) {
      if (
        data &&
        (data.spendOrgnizationCheckCancel || data.spendGroupCheckCancel)
      ) {
        toast?.setToast({
          message: "Check canceled successfully",
          type: "success",
        });
        requestCancelToggle();
      }

      if (error) {
        toast?.setToast({
          message: `Error: Check is no longer in pending status.`,
          type: "danger",
        });
        requestCancelToggle();
      }
    }
    // eslint-disable-next-line
  }, [loading, data]);

  useEffect(() => {
    if (!loadingCancelAch) {
      if (cancelACHData && cancelACHData.spendUserAchPaymentCancel) {
        toast?.setToast({
          message: "Transfer canceled successfully",
          type: "success",
        });
        requestCancelToggle();
      }

      if (cancelACHError) {
        toast?.setToast({
          message: `${cancelACHError.message}`,
          type: "danger",
        });
        requestCancelToggle();
      }
    }
    // eslint-disable-next-line
  }, [loadingCancelAch, cancelACHData]);

  useEffect(() => {
    if (
      !loadingTransactionData &&
      transactionData &&
      transactionData.spendTransaction &&
      focusOnTransaction?.transactionType === "checkPayment"
    ) {
      const history =
        transactionData.spendTransaction.transactionDetail.history;
      const cancelableStatuses = ["new", "pending"];
      setIsCancelable(
        cancelableStatuses.includes(
          history[history.length - 1].status.toLowerCase()
        )
      );
      setCheckStatus(history[history.length - 1].status);
    }
    // eslint-disable-next-line
  }, [loadingTransactionData, transactionData]);

  return (
    <FixedModal
      isOpen={requestCancelOpen}
      toggle={requestCancelToggle}
      title={""}
      icon="exclamation-solid"
      iconColor="#FEE2E2"
      btn1={{
        text: "Yes",
        btnStyle: "danger",
        onClick: () => {
          const unitId = focusOnTransaction?.correlationId?.split("_")[1];
          if (focusOnTransaction?.transactionType === "checkPayment") {
            cancelCheck({
              variables: {
                checkId: unitId,
              },
            });
          } else if (focusOnTransaction?.transactionType === "ach") {
            cancelACH({
              variables: {
                id: unitId,
              },
            });
          }
          console.log(focusOnTransaction);
        },
        btnState: isCancelable ? BtnState.BASE : BtnState.HIDDEN,
      }}
      btn2={{
        text: isCancelable ? "No" : "Cancel",
        btnStyle: "tertiary",
        onClick: requestCancelToggle,
      }}
    >
      <div className="text-center">
        <p className="text-lg font-medium">Request Cancel</p>
        {loadingTransactionData && (
          <SpinnerContainer loading={loadingTransactionData} />
        )}
        {!loadingTransactionData && isCancelable && (
          <p className="text-sm text-gray-500">
            Are you sure you want to cancel this transaction
          </p>
        )}
        {!loadingTransactionData && !isCancelable && (
          <p className="text-sm text-gray-500">
            This check is {checkStatus} and is no longer cancelable
          </p>
        )}
      </div>
    </FixedModal>
  );
}

export default RequestCancel;
