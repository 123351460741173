import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import ToastContext from "context/toast-context";
import { SpendGroupRoster } from "graphql/generated";
import { useContext, useEffect, useState } from "react";
import CustomModal, { BtnState, BtnType } from "shared-components/modal";
import { creditMemoStepOne } from "types/errors";
import StepOne from "./step-one";
import StepTwo from "./step-two";
import { FormatMoney, ParseMoney } from "helpers/format-money";
import { ParticipantDetailType } from "../..";
import { useMutation } from "@apollo/client";
import { CREATE_CREDIT_MEMO } from "graphql/mutations/creditMemos";

type CreditMemoProps = {
  isOpen: boolean;
  toggle: () => void;
  participantData: SpendGroupRoster[];
  participantDetails: ParticipantDetailType;
};

export type StepOneValues = {
  groups: SnapSelectMenuOption[];
  seasons: SnapSelectMenuOption[];
  roster: SpendGroupRoster[];
  creditAmount: string;
  titleCredit: string;
  date: string;
  note: string;
  participantName: string;
};

function CreditMemo({
  isOpen,
  toggle,
  participantData,
  participantDetails,
}: CreditMemoProps) {
  const toast = useContext(ToastContext);
  const [isBtnActive, setIsBtnActive] = useState(true);
  const [step, setStep] = useState(0);
  const [amountToApplyError, setAmountToApplyError] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>(
    undefined
  );
  const [selectedSeasonId, setSelectedSeasonId] = useState<string | undefined>(
    undefined
  );
  const [stepOneErrors, setStepOneErrors] = useState<creditMemoStepOne>({
    groupError: false,
    seasonError: false,
    creditAmountError: false,
    dateError: false,
  });
  const [stepOneValues, setStepOneValues] = useState<StepOneValues>({
    groups: [],
    seasons: [],
    roster: [],
    creditAmount: "0.00",
    titleCredit: "0.00",
    date: "",
    note: "",
    participantName: "",
  });
  const [invoicePlusAmount, setInvoicePlusAmount] = useState<
    { amount: string; invoiceId: string; invoiceError: boolean }[]
  >([]);

  const [
    createCreditMemo,
    { loading: loadingCreditMemo, data: creditMemoData },
  ] = useMutation(CREATE_CREDIT_MEMO, {
    refetchQueries: ["GetParticipantsDetails"],
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    let groupMenuOptions: SnapSelectMenuOption[] =
      participantDetails?.Group.filter((option, index, arr) => {
        return (
          arr.findIndex((item) => item.groupId === option.groupId) === index
        );
      }).map((group) => {
        let option: SnapSelectMenuOption = {
          name: group.name,
          value: group.groupId,
          selected: false,
        };
        return option;
      }) ?? [];
    if (groupMenuOptions && groupMenuOptions.length === 1) {
      groupMenuOptions[0].selected = true;
      setSelectedGroupId(groupMenuOptions.at(0)?.value);
    }
    setStepOneValues({
      ...stepOneValues,
      groups: groupMenuOptions,
      participantName: participantDetails?.participantName!,
    });

    // eslint-disable-next-line
  }, [participantDetails]);

  useEffect(() => {
    const foundGroupOption = stepOneValues.groups.find(
      (option) => option.selected
    );
    setSelectedGroupId(foundGroupOption?.value);
    let seasonMenuOptions: SnapSelectMenuOption[] =
      participantDetails?.Group.filter(
        (group) => group.groupId === foundGroupOption?.value
      ).map((group) => {
        let option: SnapSelectMenuOption = {
          name: group.season,
          value: group.seasonId,
          selected: false,
        };
        return option;
      }) ?? [];
    if (seasonMenuOptions && seasonMenuOptions.length === 1) {
      seasonMenuOptions[0].selected = true;
      setSelectedSeasonId(seasonMenuOptions.at(0)?.value);
    }
    if (seasonMenuOptions.length) {
      setStepOneValues({
        ...stepOneValues,
        seasons: seasonMenuOptions,
      });
    }
    // eslint-disable-next-line
  }, [stepOneValues.groups]);

  useEffect(() => {
    const foundSeasonOption = stepOneValues.seasons.find(
      (option) => option.selected
    );
    setSelectedSeasonId(foundSeasonOption?.value);

    let rosterOptions = participantData.filter(
      (data) =>
        data.group?.id === selectedGroupId &&
        data.seasonId === foundSeasonOption?.value
    );
    let tempIPLusA: {
      amount: string;
      invoiceId: string;
      invoiceError: boolean;
    }[] = rosterOptions.flatMap((p) => {
      return (
        p.invoices
          ?.filter((i) => !i?.paid)
          .map((i) => {
            return {
              amount: "",
              invoiceId: i?.id ?? "",
              invoiceError: false,
            };
          }) ?? []
      );
    });
    if (rosterOptions.length && tempIPLusA.length) {
      setInvoicePlusAmount(tempIPLusA);
      setStepOneValues({
        ...stepOneValues,
        roster: rosterOptions,
      });
    }

    // eslint-disable-next-line
  }, [stepOneValues.seasons]);

  useEffect(() => {
    if (!loadingCreditMemo && creditMemoData) {
      toast?.setToast({
        message: "Credit Memo Applied Successfully",
        type: "success",
      });
      setIsBtnActive(true);
      setStep(0);
      toggle();
    }
    // eslint-disable-next-line
  }, [loadingCreditMemo, creditMemoData]);

  const handleAmountInput = (
    amountToSet: string,
    idx: number,
    invoiceAmount: number
  ) => {
    let creditAmount = ParseMoney(stepOneValues.titleCredit);
    let tempInvoicePlusAmount = [...invoicePlusAmount];
    tempInvoicePlusAmount.splice(idx, 1, {
      ...tempInvoicePlusAmount[idx],
      amount: amountToSet,
      invoiceError: ParseMoney(amountToSet) > invoiceAmount,
    });
    setInvoicePlusAmount(tempInvoicePlusAmount);
    tempInvoicePlusAmount.forEach((i) => {
      creditAmount = creditAmount - ParseMoney(i.amount);
    });
    setStepOneValues({
      ...stepOneValues,
      creditAmount: FormatMoney(creditAmount).replace("$", ""),
    });
  };

  const stepOneBtn1: BtnType = {
    text: "Next",
    btnStyle: "primary",
    onClick: () => {
      let groupError = !selectedGroupId;
      let seasonError = !selectedSeasonId;
      let creditAmountError =
        stepOneValues.creditAmount === "" ||
        stepOneValues.creditAmount === "0.00";
      let dateError = stepOneValues.date === "";
      if (groupError || seasonError || creditAmountError || dateError) {
        setStepOneErrors({
          groupError,
          seasonError,
          creditAmountError,
          dateError,
        });
        toast?.setToast({
          message: "Please fill in all the input fields",
          type: "danger",
        });
      } else {
        setStep(1);
      }
    },
  };

  const stepTwoBTn1: BtnType = {
    text: "Add Credit Memo",
    btnStyle: "primary",
    btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
    onClick: () => {
      if (Number(stepOneValues.creditAmount) * 100 > 0) {
        setAmountToApplyError(true);
      } else if (Number(stepOneValues.creditAmount) * 100 < 0) {
        toast?.setToast({
          message: "Amount Applied to invoices cannot exceed the credit amount",
          type: "danger",
        });
      } else {
        setAmountToApplyError(false);
        let input = {
          creditAmount: ParseMoney(stepOneValues.titleCredit),
          dateToApply: stepOneValues.date,
          invoicesApplied: invoicePlusAmount
            .filter((i) => i.amount !== "")
            .filter((i) => i.amount !== "0.00")
            .map((i) => {
              return {
                invoiceId: i.invoiceId,
                amount: ParseMoney(i.amount),
              };
            }),
          note: stepOneValues.note,
        };
        setIsBtnActive(false);
        createCreditMemo({
          variables: {
            input,
          },
        });
      }
    },
  };

  const btn2: BtnType = {
    text: "Cancel",
    btnStyle: "tertiary",
    onClick: () => {
      setIsBtnActive(true);
      toggle();
    },
  };

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={() => {
        setIsBtnActive(true);
        toggle();
      }}
      title={step === 0 ? "Add Credit Memo" : ""}
      hasBackBtn={
        step === 1 ? { text: "", onClick: () => setStep(0) } : undefined
      }
      btn1={step === 0 ? stepOneBtn1 : stepTwoBTn1}
      btn2={btn2}
      customStyle="lg:mt-10"
    >
      <div className="modal-card">
        {
          {
            0: (
              <StepOne
                stepOneValues={stepOneValues}
                setStepOneValues={setStepOneValues}
                stepOneErrors={stepOneErrors}
                setStepOneErrors={setStepOneErrors}
              />
            ),
            1: (
              <StepTwo
                roster={stepOneValues.roster}
                creditAmount={stepOneValues.creditAmount}
                titleCredit={stepOneValues.titleCredit}
                handleAmountInput={handleAmountInput}
                invoicePlusAmount={invoicePlusAmount}
                amountToApplyError={amountToApplyError}
                stepOneValues={stepOneValues}
              />
            ),
          }[step]
        }
      </div>
    </CustomModal>
  );
}

export default CreditMemo;
