import { FormatMoney } from "helpers/format-money";
import React, { useState } from "react";
import Divider from "shared-components/divider";
import { budgetItemDetails } from "types/budget-summary";

type TableRowSmProps = {
  name: string;
  budgetAmount: number;
  budgetReconciled: number;
  expandedData: any;
  handleSetModal: (selectedItem: budgetItemDetails) => void;
  type: string;
};

function TableRowSm({
  name,
  budgetAmount,
  budgetReconciled,
  expandedData,
  handleSetModal,
  type,
}: TableRowSmProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <React.Fragment>
      <div className="bg-white px-4 pt-4 mt-4 pb-12 rounded-lg border">
        <div
          className="text-gray-800 capitalize font-semibold"
          style={{ backgroundColor: "#FFF" }}
        >
          {name}
        </div>
        <Divider isVisibleOnMobile />
        <div className="flex flex-row justify-between my-4">
          <div className="font-bold text-gray-500 text-sm">Budget</div>
          <div
            className="text-gray-700 text-right"
            style={{ backgroundColor: "#FFF" }}
          >
            {FormatMoney(budgetAmount)}
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="font-bold text-gray-500 text-sm">Reconciled</div>
          <div
            className="text-gray-700 text-right"
            style={{ backgroundColor: "#FFF" }}
          >
            {FormatMoney(budgetReconciled)}
          </div>
        </div>
        <hr className="mt-4" />
        {isExpanded && (
          <>
            <p className="text-sm text-gray-500 mt-2">Expanded Details</p>
            {expandedData.map((b: any, idx: number) => {
              return (
                <div
                  key={idx}
                  className="bg-blue-50 px-2 pt-2 mt-4 pb-4 rounded-xl border"
                >
                  <div
                    className="text-blue-600 capitalize font-semibold"
                    onClick={() =>
                      handleSetModal({
                        budgetId: b.id,
                        category: name,
                        type,
                        budgetItemName: b.name,
                        budgetAmount: b.budgetAmount,
                        reconciledAmount: b.budgetReconciled,
                        dueDate: b.targetDate,
                      })
                    }
                  >
                    {b.name}
                  </div>
                  <Divider isVisibleOnMobile />
                  <div className="flex flex-row justify-between my-4">
                    <div className="font-bold text-gray-500 text-sm">
                      Budget
                    </div>
                    <div className="text-gray-700 text-right">
                      {FormatMoney(b.budgetAmount)}
                    </div>
                  </div>
                  <div className="flex flex-row justify-between">
                    <div className="font-bold text-gray-500 text-sm">
                      Reconciled
                    </div>
                    <div className="text-gray-700 text-right">
                      {FormatMoney(b.budgetReconciled)}
                    </div>
                  </div>
                </div>
              );
            })}
            <hr className="mt-4" />
          </>
        )}
        <div className="float-right my-3" style={{ backgroundColor: "#FFF" }}>
          <p
            className="text-blue-600 font-bold cursor-pointer"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? "Collapse -" : "Expand +"}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TableRowSm;
