import { FormatMoney, ParseMoney } from "helpers/format-money";
import LabelValue from "pages/groups/collections/label-value";
import Divider from "shared-components/divider";
import { SnapInput } from "suit";
import { InvoiceReconciliationForm, TransactionAmountDistribution } from ".";
import { MapAndCalcSum } from "helpers/map-and-reduce";
import { useState, useEffect } from "react";
import { DateFormatSupported, FormatDate } from "helpers/format-date";

type CardsProps = {
  selectedDetails: InvoiceReconciliationForm;
  setSelectedDetails: React.Dispatch<
    React.SetStateAction<InvoiceReconciliationForm>
  >;
  className?: string;
};

function Cards({ selectedDetails, setSelectedDetails, className }: CardsProps) {
  const [initialAmounts] = useState<TransactionAmountDistribution[]>([
    ...(selectedDetails.distribution ?? []),
  ]);
  const [balance, setBalance] = useState<number>(0);
  const getValue = (id: string | undefined | null) => {
    if (id == null) return 0;
    return initialAmounts.find((amt) => amt.invoice.id === id)?.amount ?? 0;
  };
  useEffect(() => {
    setBalance(
      (selectedDetails.transaction?.amount ?? 0) -
        MapAndCalcSum(selectedDetails.distribution ?? [], "amount")
    );
  }, [initialAmounts, selectedDetails]);
  return (
    <div className={`${className}`}>
      {selectedDetails.distribution?.map((amtInvoice) => {
        return (
          amtInvoice && (
            <div
              className="border rounded-lg p-4 mt-4"
              key={`distrib-card-${amtInvoice.invoice.id}`}
            >
              <p className="text-sm font-semibold text-gray-500">
                Invoice Description
              </p>
              <p className="text-sm">{amtInvoice.invoice.description}</p>
              <Divider isVisibleOnMobile />
              <LabelValue
                label={"Due Date"}
                value={FormatDate(
                  amtInvoice.invoice.dueDate ?? "",
                  DateFormatSupported.NumbersLight
                )}
                labelColor={"text-gray-500"}
                valueColor={""}
                className="mt-2 mb-3"
              />
              <LabelValue
                label={"Amount Due"}
                value={FormatMoney(amtInvoice.invoice.balanceDue ?? 0)}
                labelColor={"text-gray-500"}
                valueColor={""}
              />
              <Divider isVisibleOnMobile className="my-2" />
              <p className="text-sm text-gray-500 font-semibold">
                Amount to apply
              </p>
              <SnapInput
                _id={""}
                _type="number"
                _prepend="$"
                value={`${getValue(amtInvoice.invoice.id ?? undefined) / 100}`}
                onSnap-input-change={(e) => {
                  const distribution = selectedDetails.distribution?.find(
                    (target) => target.invoice.id === amtInvoice.invoice.id
                  );
                  if (distribution) {
                    distribution.amount = ParseMoney(e.detail.target.value);
                    setSelectedDetails({ ...selectedDetails });
                  }
                }}
              />
            </div>
          )
        );
      })}
      <div className="mt-4 flex">
        <p className="mr-auto text-sm">Balance Remaining</p>
        <p className={`font-bold ${balance !== 0 ? "text-red-500" : ""}`}>
          {FormatMoney(balance)}
        </p>
      </div>
    </div>
  );
}

export default Cards;
