import { SpendBankTransaction } from "graphql/generated";
import { getTransactionTitle } from "helpers/transaction";
import useModal from "hooks/use-modal";
import { useState } from "react";
import DataCard from "shared-components/data-card";
import RequestCancel from "shared-components/fixed-modal/request-cancel";
import TransactionDetails from "shared-components/modal/transaction-details.tsx";
import ShowingResults from "shared-components/showing-results";
import { SpinnerContainer } from "shared-components/spinner";
import { LabelValueObject } from "types/label-value-object";

type PendingCheckTransactionsProps = {
  pendingCheckTransactions: SpendBankTransaction[];
  loadingPending: boolean;
  prepLeftData: (transaction: SpendBankTransaction) => LabelValueObject[];
  prepRightData: (transaction: SpendBankTransaction) => LabelValueObject[];
  canUpdateBudgetItem: boolean;
  menuItemFilter: (transaction: SpendBankTransaction) => any[];
  groupIdOrgId: string;
  type: "Program" | "Group";
};

function PendingCheckTransactions({
  pendingCheckTransactions,
  loadingPending,
  prepLeftData,
  prepRightData,
  canUpdateBudgetItem,
  menuItemFilter,
  groupIdOrgId,
  type,
}: PendingCheckTransactionsProps) {
  const {
    isOpen: transactionDetailsOpen,
    toggle: transactionDetailsToggle,
    tabSelectedValue,
    setSelectedTab,
  } = useModal();
  const { isOpen: requestCancelOpen, toggle: requestCancelToggle } = useModal();

  const [selectedModal, setSelectedModal] = useState(0);
  const [selectedTran, setSelectedTran] = useState<
    SpendBankTransaction | any | undefined
  >(undefined);

  return (
    <>
      <p className="mt-4 font-semibold text-lg">Pending Checks</p>
      <ShowingResults
        startingNumOfResults={1}
        numOfResultsBeingDisplayed={pendingCheckTransactions.length}
        totalNumOfResults={pendingCheckTransactions.length}
      />
      <div className="relative">
        {!loadingPending ? (
          pendingCheckTransactions.map((transaction, idx) => {
            return (
              <DataCard
                title={getTransactionTitle(transaction)}
                key={transaction.id || idx}
                titleAction={() => {
                  setSelectedTran(transaction);
                  transactionDetailsToggle();
                }}
                kvLeft={prepLeftData(transaction)}
                kvRight={prepRightData(transaction)}
                action={canUpdateBudgetItem ? 1 : 0}
                menuItems={menuItemFilter(transaction)}
                menuClickListener={(title) => {
                  setSelectedTran(transaction);
                  requestCancelToggle();
                }}
              />
            );
          })
        ) : (
          <SpinnerContainer loading={loadingPending} />
        )}
      </div>
      {selectedTran && transactionDetailsOpen && (
        <TransactionDetails
          isOpen={transactionDetailsOpen}
          toggle={transactionDetailsToggle}
          tabSelectedValue={tabSelectedValue}
          selectedTran={selectedTran}
          selectedModal={selectedModal}
          setSelectedModal={setSelectedModal}
          setSelectedTab={setSelectedTab}
          canEditNotesAndAttachments={canUpdateBudgetItem}
          perspectiveId={groupIdOrgId ?? undefined}
        />
      )}
      {selectedTran && requestCancelOpen && (
        <RequestCancel
          requestCancelOpen={requestCancelOpen}
          requestCancelToggle={requestCancelToggle}
          focusOnTransaction={selectedTran}
          type={type}
        />
      )}
    </>
  );
}

export default PendingCheckTransactions;
