import React, { useEffect, useRef, useState } from 'react';
import {
  PaymentsWidgetContext as IPaymentsWidgetContext,
  PaymentsWidgetContextProps,
} from './types';
import { Elements } from '@stripe/react-stripe-js';
import { useContextStrict } from './utils';
import { makeStripeOptions } from '../helpers/stripe-transformer';
import { User } from '@snap-mobile/payments-widget-utils';
import { StripeElementsOptionsMode } from '@stripe/stripe-js';
import { usePaymentsConfiguration } from '../hooks/usePaymentsConfiguration';
import { PaymentWidgetContainer } from '../components/PaymentWidgetContainer';

type ChildrenState = {
  initiating: boolean;
  processing: boolean;
  error: string;
  valid?: boolean;
  selectedPaymentMethod?: string;
  setSelectedPaymentMethod?: (method: string) => void;
}

const PaymentsWidgetContext =
  React.createContext<IPaymentsWidgetContext | null>(null);

export type PaymentWidgetProviderProps = PaymentsWidgetContextProps & {
  children?: React.ReactNode | ((childrenState: ChildrenState) => React.ReactNode);
};

export const PaymentsWidgetProvider = (props: PaymentWidgetProviderProps) => {
  const {
    children,
    appearance,
    walletsEnabled,
    stripePromise,
    setupFutureUsage = 'on_session',
    paymentData,
    ...cleanProps
  } = props;
  const [error, setError] = useState('');
  const [processing, setProcessing] = useState(false); // any payment confirmation is processing
  const [valid, setValid] = useState(props.invalidated);
  const [customer, setCustomer] = useState<User | undefined>(undefined);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('card');

  const { getPaymentConfiguration, paymentConfiguration, loading } =
    usePaymentsConfiguration(
      paymentData,
      cleanProps.stripeEnvironment,
      setError
    );

  const { elementsOptions, paymentElementOptions, externalPayments } =
    makeStripeOptions(
      paymentConfiguration,
      appearance,
      walletsEnabled,
      props.paymentMethodTypes
    );

  const prevAmount = useRef(paymentData.totalAmount);
  const getAmount = () => {
    // fallback amount to prevent payment widget element throw error
    const FALLBACK_AMOUNT = 10;

    if (paymentData.totalAmount < 50) {
      return prevAmount.current || FALLBACK_AMOUNT;
    }
    return Number(paymentData.totalAmount) || FALLBACK_AMOUNT;
  };
  const amount = getAmount();

  const validate = (): {
    [errorField: string]: string | undefined;
  } => {
    if (paymentData.totalAmount < 50) {
      return {
        totalAmount: 'amount must be larger than 50 cents',
      };
    }
    return {};
  };

  useEffect(() => {
    getPaymentConfiguration();
  }, []);

  useEffect(() => {
    const errors = validate();
    const field = Object.keys(errors)[0];
    if (errors && field) {
      setError(`${field} - ${errors[field]}`);
      setValid(false);
    } else {
      setValid(true);
      setError('');
    }
  }, [paymentData.totalAmount]); // need add more

  const stripeOptions: StripeElementsOptionsMode = {
    ...elementsOptions,
    amount: amount,
    mode: 'payment',
    setup_future_usage: setupFutureUsage,
  };

  useEffect(() => {
    if (error && props.onError) {
      props.onError(error);
    }
  }, [error]);

  const _children =
    typeof children === 'function'
      ? children({
          initiating: loading,
          processing,
          error,
          valid,
          selectedPaymentMethod,
          setSelectedPaymentMethod
        })
      : children;

  if (!paymentConfiguration) {
    return error ? <div>{error}</div> : <div>Loading...</div>;
  }

  const defaultContextValue: IPaymentsWidgetContext = {
    ...cleanProps,
    paymentData,
    invalidated: props.invalidated,
    stripePromise: props.stripePromise,
    paymentConfiguration,
    elementOptions: paymentElementOptions,
    setupFutureUsage,
    setProcessing: setProcessing,
    setError: (v) => setError(v || ''),
    customer: customer,
    setCustomer,
    valid: Boolean(!props.invalidated && valid),
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    externalPayments: externalPayments,
  };

  return (
    <PaymentsWidgetContext.Provider value={defaultContextValue}>
      {/* {error && <div>{error}</div>} */}
      <PaymentWidgetContainer>
        <Elements stripe={stripePromise} options={stripeOptions}>
          {_children}
        </Elements>
      </PaymentWidgetContainer>
    </PaymentsWidgetContext.Provider>
  );
};

export const usePaymentsWidgetContext = () =>
  useContextStrict(PaymentsWidgetContext);
