import React, { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { PaymentMethod, User } from '@snap-mobile/payments-widget-utils';
import NewPmToggle from './NewPmToggle';
import { buttonDivStyle, buttonStyle, PMProps } from './internal';

export type ExistingPaymentMethodWidgetProps = PMProps & {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  presetPaymentMethod?: PaymentMethod;
  user?: User;
};

export default function ({
  disabled,
  onClick,
  presetPaymentMethod,
  setUseNewPm,
  submit,
  submitButtonContent,
  useNewPm,
  user,
}: ExistingPaymentMethodWidgetProps) {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | undefined>(
    presetPaymentMethod
  );
  const stripe = useStripe();

  const oc = onClick ?? (async () => {});

  useEffect(() => {
    if (!(paymentMethod || user)) {
      console.log('no payment method or user');
      setUseNewPm(true);
    }
  }, [paymentMethod, user]);

  const s = async () =>
    await submit({
      paymentMethodId: paymentMethod!.id,
      stripe: stripe!,
    });

  return (
    <div className="existing-payment-method">
      {paymentMethod ? (
        <ConfirmPayment
          disabled={disabled}
          onClick={oc}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          submit={s}
          submitButtonContent={submitButtonContent}
        />
      ) : (
        <PaymentMethodPicker
          setPm={setPaymentMethod}
          setUseNewPm={setUseNewPm}
          useNewPm={useNewPm}
          user={user}
        />
      )}
    </div>
  );
}

interface PaymentMethodPickerProps {
  setPm: (pm: PaymentMethod) => void;
  setUseNewPm: (value: boolean) => void;
  useNewPm: boolean;
  user?: User;
}

interface PaymentMethodComponentProps {
  paymentMethod: PaymentMethod;
  onSelect: (pm: PaymentMethod) => void;
  key: string;
}

function PaymentMethodComponent({
  paymentMethod,
  onSelect,
}: PaymentMethodComponentProps) {
  return (
    <li className="payment-method">
      <button
        type="button"
        onClick={() => onSelect(paymentMethod)}
        style={buttonStyle}
        ref={(el) =>
          el && el.style.setProperty('font-family', 'sans-serif', 'important')
        }
      >
        {`${paymentMethod.identifier}`}
      </button>
    </li>
  );
}

function PaymentMethodPicker({
  setUseNewPm,
  setPm,
  useNewPm,
  user,
}: PaymentMethodPickerProps) {
  return (
    <div className="payment-method-picker">
      <ul
        style={{
          listStyle: 'none',
          listStyleType: 'none',
          padding: '0',
          margin: '0',
        }}
      >
        {(user?.paymentMethods ?? []).map((pm: PaymentMethod) => (
          <PaymentMethodComponent
            paymentMethod={pm}
            onSelect={() => setPm(pm)}
            key={pm.id}
          />
        ))}
        <li>
          <NewPmToggle useNewPm={useNewPm} setUseNewPm={setUseNewPm} />
        </li>
      </ul>
    </div>
  );
}

function ConfirmPayment({
  disabled,
  onClick,
  paymentMethod,
  setPaymentMethod,
  submit,
  submitButtonContent,
}: {
  disabled: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  paymentMethod: PaymentMethod;
  setPaymentMethod: (pm: PaymentMethod | undefined) => void;
  submit: () => Promise<void>;
  submitButtonContent: React.ReactElement;
}) {
  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await onClick(e);
    await submit();
  };

  return (
    <div className="confirm-payment">
      {paymentMethod ? (
        <div className="selected-payment-method">
          {paymentMethod.identifier}
        </div>
      ) : null}
      <div style={buttonDivStyle}>
        <button
          disabled={disabled}
          type="button"
          onClick={handleSubmit}
          style={buttonStyle}
        >
          {submitButtonContent}
        </button>
        <button
          disabled={disabled}
          id="back-btn"
          className="back"
          type="button"
          onClick={() => setPaymentMethod(undefined)}
          style={buttonStyle}
        >
          Back
        </button>
      </div>
    </div>
  );
}
