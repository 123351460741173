import { SnapLink } from "suit";

type SubHeaderViewProps = {
  text: string;
  hideDivider?: boolean;
  hasLink?: boolean;
  link?: string;
  linkText?: string;
};

export const SubHeaderView = ({
  text,
  hideDivider = false,
  hasLink = false,
  link,
  linkText,
}: SubHeaderViewProps) => (
  <div>
    <hr key={`${text}-border`} className={`my-3 ${hideDivider && "hidden"}`} />
    <div className="flex">
      <h1 key={`${text}-title`} className="font-semibold text-lg mr-2">
        {text}
      </h1>
      {hasLink && (
        <>
          <p className="mr-2">-</p>
          <SnapLink href={link} target="_blank">
            {linkText}
          </SnapLink>
        </>
      )}
    </div>
  </div>
);
