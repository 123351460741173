import { sha256 } from "crypto-hash";

export const generateIdempotencyKey = async (
  idList: string[],
  options?: { sort?: boolean; includeDate?: boolean; hash?: boolean }
): Promise<string> => {
  const date = new Date();
  const compound = options?.sort ? idList.sort().join("") : idList.join("");
  const keyResult = `${compound}${
    options?.includeDate ? `_${date.getDate()}_${date.getMinutes()}` : ""
  }`;
  return options?.hash
    ? await sha256(keyResult, { outputFormat: "hex" })
    : keyResult;
};
