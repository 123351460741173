export const calcTotalPlusFee = (
  baseAmount: number | string,
  cardPercent: number,
  cardBase: number
): number => {
  if (baseAmount === 0) {
    return 0;
  }
  return Math.round(
    Number(baseAmount) + calcFee(Number(baseAmount), cardPercent, cardBase)
  );
};

export const calcFee = (
  baseAmount: number,
  cardPercent: number,
  cardBase: number
): number => {
  if (baseAmount === 0) {
    return 0;
  }
  return Math.floor(baseAmount * cardPercent + cardBase);
};

export const defaultSelectedPaymentOption = [
  { name: "Pay by: Card", selected: false },
  { name: "Pay by: Bank", selected: false },
];
