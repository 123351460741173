import React, { useContext, useState } from "react";
import { PlayerInfoType } from "./change-payment-modal";
import DataCard from "shared-components/data-card";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { calcTotalPlusFee } from "../make-payment/make-payment-helper";
import GroupContext from "context/group-context";

type ChangePaymentTableProps = {
  players: PlayerInfoType[];
  handleSelectedInvoices: (invoiceIdx: number, playerIdx: number) => void;
  handleSelectAll: (isChecked: boolean) => void;
  setSelectBoxChecked: (value: boolean) => void;
  isSelectBoxChecked: boolean;
};

export const ChangePaymentCard = ({
  players,
  handleSelectedInvoices,
  handleSelectAll,
}: ChangePaymentTableProps) => {
  const activeGroup = useContext(GroupContext)?.activeGroup;
  const [isSelectBoxChecked, setSelectBoxChecked] = useState(false);
  return (
    <React.Fragment>
      <p
        className="text-blue-600 font-bold lg:hidden"
        onClick={() => {
          setSelectBoxChecked(!isSelectBoxChecked);
          handleSelectAll(isSelectBoxChecked);
        }}
      >
        Select All
      </p>
      {players &&
        players.map((player, playerIdx) => {
          return player.invoices?.map((invoice, idx) => {
            const cardAmount = calcTotalPlusFee(
              invoice?.balanceDue ?? 0,
              activeGroup?.organizationFees?.cardPercent ?? 0,
              activeGroup?.organizationFees?.cardBaseFee ?? 0
            );
            return (
              <DataCard
                key={`${idx}`}
                className="flex lg:hidden"
                title={player.team ?? ""}
                hasCheckbox
                isChecked={invoice?.selected}
                checkboxAction={() => handleSelectedInvoices(idx, playerIdx)}
                kvLeft={[
                  {
                    key: "Participant",
                    value: player.player!,
                    customContainerStyle: "flex-col",
                    className: "ml-0",
                  },
                  {
                    key: "Description",
                    value: invoice?.description!,
                    customContainerStyle: "flex-col",
                    className: "ml-0",
                  },
                ]}
                kvRight={[
                  {
                    key: "Billing Date",
                    value: FormatDate(
                      invoice?.dueDate!,
                      DateFormatSupported.Words
                    ),
                  },
                  {
                    key: "Current Payment Method",
                    value: invoice?.paymentMethodSource!,
                  },
                  {
                    key: "Pay by Bank",
                    value: FormatMoney(
                      calcTotalPlusFee(
                        invoice?.balanceDue ?? 0,
                        activeGroup?.organizationFees?.achPercent ?? 0,
                        activeGroup?.organizationFees?.achBaseFee ?? 0
                      )
                    ),
                  },
                  {
                    key: "Pay by Card",
                    value:
                      cardAmount > 50
                        ? FormatMoney(cardAmount)
                        : "Amount too small",
                  },
                ]}
                action={0}
              />
            );
          });
        })}
    </React.Fragment>
  );
};
