import { StripeEnvironment } from '@snap-mobile/payments-widget-utils';
import { PaymentConfiguration } from '../../V1/internal';
import { useState } from 'react';
import { PaymentsWidgetContextProps } from '../context/types';
import { V2 as api } from '@snap-mobile/payments-widget-client';

export const usePaymentsConfiguration = (
  paymentData: PaymentsWidgetContextProps['paymentData'],
  stripeEnvironment: StripeEnvironment,
  setError: (v: string) => void
) => {
  const [paymentConfiguration, setPaymentConfiguration] =
    useState<PaymentConfiguration>();
  const [loading, setLoading] = useState(false);

  const getPaymentConfiguration = async () => {
    try {
      setLoading(true);
      const res: PaymentConfiguration | null =
        await api.getPaymentConfiguration(paymentData.destination, {
          stripeEnvironment: stripeEnvironment,
        });
      if (res) {
        setPaymentConfiguration(res);
        setLoading(false);
      }
    } catch (e: any) {
      setError(e.message || 'Error getting configuration.');
      setLoading(false);
      // maybe we do handle error here as well
    }
  };

  return {
    paymentConfiguration,
    getPaymentConfiguration,
    loading,
    setLoading,
  };
};
