import { FormatMoney } from "helpers/format-money";
import React, { useState } from "react";
import { budgetItemDetails } from "types/budget-summary";

type TableRowLgProps = {
  name: string;
  budgetAmount: number;
  budgetReconciled: number;
  expandedData: any;
  handleSetModal: (selectedItem: budgetItemDetails) => void;
  type: string;
};

function TableRowLg({
  name,
  budgetAmount,
  budgetReconciled,
  expandedData,
  handleSetModal,
  type,
}: TableRowLgProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <React.Fragment>
      <tr className="h-12">
        <td
          data-label="Income Summary"
          className="text-gray-700 capitalize font-medium bg-white"
        >
          {name}
        </td>
        <td
          data-label="Budgetted"
          className="text-gray-700 text-right bg-white"
        >
          {FormatMoney(budgetAmount)}
        </td>
        <td
          data-label="Reconciled"
          className="text-gray-700 text-right bg-white"
        >
          {FormatMoney(budgetReconciled)}
        </td>
        <td className="w-[85px] bg-white">
          <p
            className="text-blue-600 font-bold cursor-pointer text-end mr-3 whitespace-nowrap"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? "Collapse -" : "Expand +"}
          </p>
        </td>
      </tr>
      {isExpanded &&
        expandedData.map((b: any, idx: number) => {
          return (
            <tr className="h-12" key={idx}>
              <td
                data-label="Income Summary"
                className="text-blue-600 capitalize font-medium cursor-pointer"
                style={{
                  backgroundColor: "#EFF6FF",
                }}
                onClick={() =>
                  handleSetModal({
                    budgetId: b.id,
                    category: name,
                    type,
                    budgetItemName: b.name,
                    budgetAmount: b.budgetAmount,
                    reconciledAmount: b.budgetReconciled,
                    dueDate: b.targetDate,
                  })
                }
              >
                {b.name}
              </td>
              <td
                data-label="Budgetted"
                className="text-gray-700 text-right"
                style={{
                  backgroundColor: "#EFF6FF",
                }}
              >
                {FormatMoney(b.budgetAmount)}
              </td>
              <td
                data-label="Reconciled"
                className="text-gray-700 text-right"
                style={{
                  backgroundColor: "#EFF6FF",
                }}
              >
                {FormatMoney(b.budgetReconciled)}
              </td>
              <td
                className="w-[85px]"
                style={{
                  backgroundColor: "#EFF6FF",
                }}
              ></td>
            </tr>
          );
        })}
    </React.Fragment>
  );
}

export default TableRowLg;
