import ToastContext from "context/toast-context";
import { HandleRoleNameChangeToBack } from "helpers/back-end-role-change";
import { HandleRoleNameChangeToFront } from "helpers/front-end-role-change";
import React, { useContext, useEffect, useState } from "react";
import CustomModal, { BtnState, BtnType } from "shared-components/modal";
import { SnapSelectMenu } from "suit";
import { RoleErrors } from "types/roles-permissions";
import { GroupType } from "..";
import { InvitePlus } from "types/invite";
type ChangeUserRoleModalProps = {
  isOpen: boolean;
  toggle: () => void;
  data: InvitePlus;
  handleRoleChange: (user: string, role: string, group: string | null) => void;
  allGroups: GroupType;
  isBtnActive: boolean;
  setIsBtnActive: React.Dispatch<React.SetStateAction<boolean>>;
};

function ChangeUserRoleModal({
  isOpen,
  toggle,
  data,
  handleRoleChange,
  allGroups,
  isBtnActive,
  setIsBtnActive,
}: ChangeUserRoleModalProps) {
  const toast = useContext(ToastContext);

  const [tempUserData, setTempUserData] = useState<InvitePlus | undefined>(
    undefined
  );
  const [hasErrors, setHasErrors] = useState<RoleErrors>({
    TypeError: false,
    GroupError: false,
  });
  useEffect(() => {
    setTempUserData(undefined);
  }, []);
  const btn1: BtnType = {
    text: "Change Role",
    btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
    onClick: () => {
      if (!tempUserData) {
        hasErrors.TypeError = true;
        setHasErrors({ ...hasErrors, TypeError: true });
      }
      if (
        (tempUserData?.type === "group_staff" ||
          tempUserData?.type === "group_observer") &&
        tempUserData.groupId === null
      ) {
        hasErrors.TypeError = false;
        hasErrors.GroupError = true;
        setHasErrors({ TypeError: false, GroupError: true });
      }
      let { GroupError, TypeError } = hasErrors;
      if (GroupError || TypeError) {
        toast?.setToast({
          message: `${TypeError ? "Role" : "Group"} not selected`,
          type: "danger",
        });
        toast?.toggleToast();
      } else {
        setIsBtnActive(false);
        handleRoleChange(
          tempUserData?.userId || "",
          tempUserData?.type || "",
          tempUserData?.type === "program_staff" ||
            tempUserData?.type === "program_observer"
            ? null
            : tempUserData?.groupId || ""
        );
        setHasErrors({ TypeError: false, GroupError: false });
      }
    },
  };
  const btn2: BtnType = {
    text: "Cancel",
    onClick: () => {
      setTempUserData(undefined);
      if (toast?.isToastOpen) {
        toast?.toggleToast();
      }
      setHasErrors({ TypeError: false, GroupError: false });
      setIsBtnActive(true);
      toggle();
    },
  };

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      title={"Change Role"}
      btn1={btn1}
      btn2={btn2}
    >
      <>
        <div className="modal-card">
          <div className="flex flex-col lg:items-center lg:mt-8 lg:mb-20">
            <div className="flex">
              <p className="text-gray-500 mr-6">User Name</p>
              <p>{`${data?.firstName} ${data?.lastName}`}</p>
            </div>
            <div className="flex mt-4">
              <p className="text-gray-500 mr-6">Current Role</p>
              <p className="capitalize">{`${HandleRoleNameChangeToFront(
                data?.type || ""
              )}`}</p>
            </div>
            <div className="flex flex-col mt-4 lg:w-[40%]">
              <div className="text-sm text-gray-800">New Role</div>
              <SnapSelectMenu
                error={hasErrors.TypeError}
                modalTitle="Select a role"
                placeholder="Select Role"
                selectMenuOptions={[
                  {
                    name: "Program Staff",
                    selected: tempUserData?.type === "program_staff",
                  },
                  {
                    name: "Program Observer",
                    selected: tempUserData?.type === "program_observer",
                  },
                  {
                    name: "Group Staff",
                    selected: tempUserData?.type === "group_staff",
                  },
                  {
                    name: "Group Observer",
                    selected: tempUserData?.type === "group_observer",
                  },
                ]}
                onSnap-select-menu-updated={(e) => {
                  if (data) {
                    setTempUserData({
                      ...data,
                      type: HandleRoleNameChangeToBack(
                        e.detail.find((option) => option.selected)?.name ?? ""
                      ),
                    });
                    if (toast?.isToastOpen) toast?.toggleToast();
                    setHasErrors({ ...hasErrors, TypeError: false });
                  }
                }}
              />
              {tempUserData?.type === "group_staff" ||
              tempUserData?.type === "group_observer" ? (
                <React.Fragment>
                  <div className=" mt-5 text-sm text-gray-800">Group</div>
                  <SnapSelectMenu
                    error={hasErrors.GroupError}
                    modalTitle="Select a group"
                    placeholder="Select Group"
                    selectMenuOptions={allGroups}
                    onSnap-select-menu-updated={(e) => {
                      let selected =
                        e.detail.find((r) => r.selected)?.name ?? "";
                      let selectedId =
                        allGroups.find((group) => group.name === selected)
                          ?.id ?? "";
                      setTempUserData({ ...tempUserData, groupId: selectedId });
                      if (toast?.isToastOpen) toast?.toggleToast();
                      setHasErrors({ ...hasErrors, GroupError: false });
                    }}
                  />
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </>
    </CustomModal>
  );
}

export default ChangeUserRoleModal;
