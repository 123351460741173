import { FormatMoney } from "helpers/format-money";
import { getHexColor } from "helpers/summary-related";
import { useState } from "react";
import ColorBar from "shared-components/color-bar";
import Divider from "shared-components/divider";
import { SnapIcon } from "suit";
import { BudgetSummaryRecord, budgetItemDetails } from "types/budget-summary";

type BudgetSummaryRowSMProps = {
  budgetData: BudgetSummaryRecord;
  percent: number;
  color: string;
  handleSetModal: (selectedItem: budgetItemDetails) => void;
  type: string;
  isArchived: boolean;
};

export const BudgetSummaryRowSM = ({
  budgetData,
  percent,
  color,
  handleSetModal,
  type,
  isArchived,
}: BudgetSummaryRowSMProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="lg:hidden border rounded-lg p-4 mt-4">
      <p className="font-semibold mb-2">{budgetData.name}</p>
      <ColorBar percent={percent} colorHex={color} />
      <Divider isVisibleOnMobile />
      <div className="flex mt-4">
        <p className="text-xs font-bold text-gray-500 mr-auto">Budget</p>
        <p className="tetx-sm font-semibold">
          {FormatMoney(budgetData.budgetAmount)}
        </p>
      </div>
      <div className="flex mt-4">
        <p className="text-xs font-bold text-gray-500 mr-auto">Reconciled</p>
        <p className="tetx-sm font-semibold">
          {FormatMoney(budgetData.budgetReconciled)}
        </p>
      </div>
      <Divider isVisibleOnMobile />
      {isOpen &&
        budgetData.expandedData.map((ex) => {
          let exPercent = (ex.budgetReconciled / ex.budgetAmount) * 100;
          return (
            <div key={ex.name} className="mt-2">
              <p
                className="font-semibold mb-2 text-blue-500"
                onClick={() =>
                  handleSetModal({
                    budgetId: ex.id,
                    category: budgetData.name,
                    type,
                    budgetItemName: ex.name,
                    budgetAmount: ex.budgetAmount,
                    reconciledAmount: ex.budgetReconciled,
                    dueDate: ex.targetDate,
                  })
                }
              >
                {ex.name}
              </p>
              <ColorBar
                percent={exPercent}
                colorHex={getHexColor(
                  ex.budgetAmount,
                  ex.budgetReconciled,
                  true,
                  isArchived
                )}
              />
              <Divider isVisibleOnMobile />
              <div className="flex mt-4">
                <p className="text-xs font-bold text-gray-500 mr-auto">
                  Budget
                </p>
                <p className="tetx-sm font-semibold">
                  {FormatMoney(ex.budgetAmount)}
                </p>
              </div>
              <div className="flex mt-4">
                <p className="text-xs font-bold text-gray-500 mr-auto">
                  Reconciled
                </p>
                <p className="tetx-sm font-semibold">
                  {FormatMoney(ex.budgetReconciled)}
                </p>
              </div>
              <Divider isVisibleOnMobile />
            </div>
          );
        })}
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex text-blue-500 font-bold justify-end mt-3"
      >
        <p>Show {isOpen ? "Less" : "More"}</p>
        <SnapIcon icon={isOpen ? "chevron-up-solid" : "chevron-down-solid"} />
      </div>
    </div>
  );
};
