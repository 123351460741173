import UserContext from "context/user-context";
import { SpendBankAccount, SpendDebitCard } from "graphql/generated";
import UnitComponentGenerator from "unit/unit-component-generator";
import { getBadgeColor } from "helpers/status-color";
import useModal from "hooks/use-modal";
import RemoveAccount from "pages/programs/banking/modals/remove-account";
import { useContext, useState } from "react";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import LinkBank from "shared-components/banking/link-bank";
import EmptyCards from "shared-components/debit-cards/empty-cards";
import { SnapBadge, SnapIcon } from "suit";
import { SpendPermissions } from "types/roles-permissions";
import { extractBankLabel } from "helpers/banking";

type AvailableBalanceProps = {
  debitCards: SpendDebitCard[];
  accountId: string;
  accountType: "program" | "group";
  groupId: string;
  linkedAccount: SpendBankAccount | undefined;
  accountStatus: string;
};

function AvailableBalance({
  debitCards,
  accountId,
  accountType,
  groupId,
  linkedAccount,
  accountStatus,
}: AvailableBalanceProps) {
  const sessionUser = useContext(UserContext);
  const canEditExternalBank =
    sessionUser?.checkSpendPermission(
      SpendPermissions.programExternalBankUpdate
    ) ?? false;

  const { isOpen: removeOpen, toggle: removeToggle } = useModal();

  const [plaidConfig, setPlaidConfig] = useState<PlaidLinkOptions>({
    onSuccess: (_public_token, _metadata) => {},
    onExit: (_err, _metadata) => {},
    onEvent: (_eventName, _metadata) => {},
    token: "",
  });

  const { open, ready } = usePlaidLink(plaidConfig);
  const openPlaid = () => {
    if (ready) {
      open();
    }
  };

  function formatExpirationDate(expiration: string) {
    const [year, month] = expiration.split("-");
    return `${month}/${year.slice(-2)}`;
  }

  return (
    <div className="card">
      <div className="lg:grid grid-cols-2">
        <div className="lg:mr-6">
          <UnitComponentGenerator
            type={"account"}
            data={{
              accountId,
            }}
          />
          <div className="border border-gray-200 rounded-lg py-4 px-2 mt-5 lg:mt-4 lg:py-6 lg:px-4 lg:grid grid-cols-2">
            {linkedAccount ? (
              <div className="flex justify-between lg:flex-col">
                <p className="pr-20 whitespace-nowrap text-xs font-semibold text-gray-500 self-center lg:self-start lg:mb-2">
                  Linked Bank
                  {canEditExternalBank && (
                    <span
                      className="text-blue-600 font-bold ml-2 cursor-pointer"
                      onClick={removeToggle}
                    >
                      Change
                    </span>
                  )}
                </p>
                <p className="text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden">
                  {extractBankLabel(linkedAccount)}
                </p>
              </div>
            ) : (
              <>
                {canEditExternalBank && (
                  <LinkBank
                    openPlaid={openPlaid}
                    setPlaidConfig={setPlaidConfig}
                    type={accountType}
                    labelText={
                      accountStatus === "pending_manual_verification"
                        ? "Verify Account"
                        : "Add Account"
                    }
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="my-4 lg:my-0">
          <p className="mb-4 lg:mb-6  text-base font-medium text-gray-500">
            Debit Cards
          </p>
          {!debitCards.length && (
            <div className="flex justify-center lg:h-72 lg:-mt-20">
              <EmptyCards message={"No available debit cards"} />
            </div>
          )}
          {debitCards.map((card) => {
            return (
              <div
                className="flex justify-between border border-gray-200 rounded-lg p-2 mt-2"
                key={card.id}
              >
                <div className="flex">
                  <SnapIcon
                    icon="credit-card-solid"
                    color="#64748B"
                    size="sm"
                    state="rounded"
                    bg-color="#F1F5F9"
                  />
                  <div className="ml-2">
                    <p className="text-base font-medium">{`*${card.lastFour}`}</p>
                    <p className="text-sm font-medium text-gray-500">
                      {formatExpirationDate(card.expiration ?? "")}
                    </p>
                  </div>
                </div>
                <div className="self-center">
                  <SnapBadge size="sm" color={getBadgeColor(card.status || "")}>
                    {card.status}
                  </SnapBadge>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <RemoveAccount
        removeOpen={removeOpen}
        removeToggle={removeToggle}
        accountType={accountType}
        groupId={groupId}
      />
    </div>
  );
}

export default AvailableBalance;
