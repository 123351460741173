import { SpendGroupRoster } from "graphql/generated";
import { SnapRadioButton } from "suit";

type Step0Props = {
  players: SpendGroupRoster[] | undefined;
  playerSelected: SpendGroupRoster | undefined;
  errors: Record<string, string>;
  setErrors: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  selectPlayer: (player: SpendGroupRoster) => void;
};

export default function Step0({
  players,
  playerSelected,
  errors,
  setErrors,
  selectPlayer,
}: Step0Props) {
  return (
    <ul className="my-3 space-y-2">
      {players &&
        players.map((player, index) => {
          return (
            <li key={`${player.id}-${index}`}>
              <div
                className={`flex rounded-2xl p-4 border-2 ${
                  player.paymentScheduleStatus !== "published"
                    ? "border-gray-200 bg-gray-50"
                    : "hover:border-blue-400"
                }`}
              >
                <SnapRadioButton
                  className={`mt-[2px] grow ${
                    player.paymentScheduleStatus !== "published"
                      ? "opacity-60"
                      : ""
                  }`}
                  label={`${player.roster?.name} (${player.group?.name} | ${player.season?.name})`}
                  checked={playerSelected === player}
                  onClick={(e) => {
                    if (player.paymentScheduleStatus !== "published") {
                      setErrors({
                        ...errors,
                        [player.id || index]:
                          "Payment schedule is not published",
                      });
                    } else {
                      setErrors({});
                      selectPlayer(player);
                    }
                  }}
                  disabled={player.paymentScheduleStatus !== "published"}
                />
                {player.paymentScheduleStatus !== "published" && (
                  <span className={"justify-end font-semibold text-red-400"}>
                    (On Hold)
                  </span>
                )}
              </div>
              {errors[player.id || index] && (
                <span className={"ml-4 text-xs italic text-red-600"}>
                  {errors[player.id || index]}
                </span>
              )}
            </li>
          );
        })}
    </ul>
  );
}
