import { useMutation } from "@apollo/client";
import ToastContext from "context/toast-context";
import { Maybe, SpendPastDueInvoice } from "graphql/generated";
import { CREATE_NOTIFICATIONS } from "graphql/mutations/notification";
import { useContext, useEffect, useState } from "react";
import CustomModal, { BtnState, BtnType } from "shared-components/modal";
import { SnapCheckboxButton, SnapIcon, SnapInput } from "suit";
import { emailErrors } from "types/errors";

type SendEmailProps = {
  isOpen: boolean;
  toggle: () => void;
  selectedInvoice: Maybe<SpendPastDueInvoice> | undefined;
};

function SendEmail({ isOpen, toggle, selectedInvoice }: SendEmailProps) {
  const toast = useContext(ToastContext);

  const [isBtnActive, setIsBtnActive] = useState(true);
  const [isCCed, setIsCCed] = useState(false);
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [hasErrors, setHasErrors] = useState<emailErrors>({
    subject: false,
    message: false,
  });

  const [createNotification, { loading, data, error }] =
    useMutation(CREATE_NOTIFICATIONS);

  useEffect(() => {
    if (!loading) {
      if (data && data.spendNotificationCreate) {
        toast?.setToast({
          message: "Email Sent",
          type: "success",
        });
        handleReset();
        toggle();
      } else if (error) {
        toast?.setToast({
          message: "Error email not sent",
          type: "danger",
        });
        setIsBtnActive(true);
      }
    }
    // eslint-disable-next-line
  }, [loading, data, error]);

  const handleSendEmail = () => {
    if (subject === "") {
      hasErrors.subject = true;
      setHasErrors({ ...hasErrors, subject: true });
    }
    if (message === "") {
      hasErrors.message = true;
      setHasErrors({ ...hasErrors, message: true });
    }
    if (hasErrors.subject || hasErrors.message) {
      toast?.setToastProps({
        message: "Please fill in all the input fields",
        type: "danger",
      });
    } else {
      setIsBtnActive(false);
      createNotification({
        variables: {
          input: {
            email: selectedInvoice?.email,
            groupId: selectedInvoice?.groupId,
            isEmailCCed: isCCed,
            message: message,
            subject: subject,
          },
        },
      });
    }
  };

  const handleReset = () => {
    setIsBtnActive(true);
    setHasErrors({ subject: false, message: false });
    setSubject("");
    setMessage("");
    setIsCCed(false);
  };
  const btn1: BtnType = {
    text: "Send Email",
    btnStyle: "primary",
    btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
    onClick: handleSendEmail,
  };
  const btn2: BtnType = {
    text: "Cancel",
    btnStyle: "tertiary",
    onClick: () => {
      handleReset();
      toggle();
    },
  };
  return (
    <CustomModal
      isOpen={isOpen}
      toggle={() => {
        handleReset();
        toggle();
      }}
      title="Email Parent"
      btn1={btn1}
      btn2={btn2}
      customStyle="lg:mt-14 lg:w-[70%]"
    >
      <div className="modal-card">
        <p>To</p>
        <div className="bg-gray-100 flex py-2 px-3 rounded-xl border border-gray-300">
          <p className="mr-auto">
            &lt;{selectedInvoice?.email}&gt; | {selectedInvoice?.groupName}
          </p>
          <SnapIcon icon={"lock-closed-solid"} color={"#94A3B8"} />
        </div>
        <div className="mt-6">
          <SnapInput
            label="Subject"
            _id="input-with-label"
            name="Subject"
            _type="text"
            placeholder="Enter Text Here"
            help-text=""
            show-password-btn="false"
            error={hasErrors.subject}
            onBlur={(e) => {
              setSubject(e.target.value.trim());
              setHasErrors({ ...hasErrors, subject: false });
            }}
          ></SnapInput>
        </div>
        <div className="mt-6">
          <SnapInput
            _id="input-with-label"
            label="Message"
            placeholder="Please Write a Message"
            textarea
            error={hasErrors.message}
            onBlur={(e) => {
              setMessage(e.target.value.trim());
              setHasErrors({ ...hasErrors, message: false });
            }}
          />
        </div>
        <div className="mt-6">
          <SnapCheckboxButton
            label="CC my Email"
            onClick={() => setIsCCed(!isCCed)}
            checked={isCCed}
          ></SnapCheckboxButton>
        </div>
      </div>
    </CustomModal>
  );
}

export default SendEmail;
