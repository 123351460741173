import React, { MouseEvent, useState } from 'react';
import {
  PaymentWalletsOption,
  StripeExpressCheckoutElementConfirmEvent,
  StripeExpressCheckoutElementClickEvent,
  StripeExpressCheckoutElementReadyEvent,
} from '@stripe/stripe-js';
import {
  useElements,
  useStripe,
  ExpressCheckoutElement,
} from '@stripe/react-stripe-js';
import { PMProps } from './internal';

export interface ExpressCheckoutWidgetProps {
  disabled: boolean;
  disabledOnClick?: (e: MouseEvent<HTMLDivElement>) => Promise<void>;
  onClick?: (e: StripeExpressCheckoutElementClickEvent) => any;
  onReady?: (e: StripeExpressCheckoutElementReadyEvent) => any;
  paymentMethodOrder?: Array<string>;
  submit: PMProps['submit'];
  wallets: PaymentWalletsOption;
}

export default function ExpressCheckoutWidget({
  disabled,
  disabledOnClick,
  onClick,
  onReady,
  paymentMethodOrder,
  submit,
  wallets,
}: ExpressCheckoutWidgetProps) {
  const elements = useElements();
  const stripe = useStripe();
  const [isDisabled, setIsDisabled] = useState(false);
  const onConfirm = async (event: StripeExpressCheckoutElementConfirmEvent) => {
    setIsDisabled(true);
    await submit({ elements: elements!, stripe: stripe!, expressEvent: event });
    setIsDisabled(false);
  };
  return (
    <>
      {disabled || isDisabled ? (
        <div
          onClick={async (e) => {
            e.stopPropagation();
            if (disabledOnClick) {
              await disabledOnClick(e);
            }
          }}
          style={{
            zIndex: 10000,
            opacity: '100%',
            height: '100%',
            width: '100%',
            position: 'absolute',
            cursor: 'pointer'
          }}
        ></div>
      ) : null}
      <ExpressCheckoutElement
        onConfirm={onConfirm}
        onClick={onClick}
        onReady={onReady}
        options={{
          paymentMethodOrder,
          wallets,
        }}
      />
    </>
  );
}
