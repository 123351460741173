import { Filters } from "types/filter-types";
import { isNullOrEmpty } from "./null-or-empty";

export const updateFilterCount = (filters: Filters[]) => {
  let activeFilters = filters.map((filter) => {
    return filter.filterOptions?.filter((option) => option.selected);
  });
  activeFilters = activeFilters.filter((filter) => filter !== undefined);

  const count = activeFilters.map((active) => active?.length);
  filters.map((filter) => {
    if (
      filter.filterName?.includes("Date") &&
      !isNullOrEmpty(filter.placeholder)
    ) {
      count.push(1);
    } else {
      count.push(0);
    }
    return [];
  });

  return count.reduce((a, b) => a! + b!);
};
