import UserContext from "context/user-context";
import { SnapLink } from "suit";
import {
  Maybe,
  SpendGroup,
  SpendSeason,
  SpendSortInput,
} from "graphql/generated";
import { FormatMoney } from "helpers/format-money";
import getGroupSubtitle from "helpers/group-subtitle";
import { getBadgeColor } from "helpers/status-color";
import { useContext } from "react";
import SortIcon from "shared-components/table/sort-icon";
import { SnapBadge, SnapDropDown, SnapIcon, SnapTable } from "suit";
import { SpendPermissions } from "types/roles-permissions";
import { SpinnerContainer } from "shared-components/spinner";
type TableProps = {
  groupList: SpendGroup[];
  handleModalData: (selectedOption: number, groupSelected: SpendGroup) => void;
  sort: SpendSortInput | undefined;
  toggleSort: (field: string) => void;
  isArchived: boolean;
  handleNavigate: (
    group: SpendGroup,
    latestSeason: Maybe<SpendSeason> | undefined
  ) => void;
  loading?: boolean;
};

function Table({
  groupList,
  handleModalData,
  sort,
  toggleSort,
  isArchived,
  handleNavigate,
  loading,
}: TableProps) {
  const user = useContext(UserContext);
  const canArchiveGroup = user?.checkSpendPermission(
    SpendPermissions.programGroupsArchive
  );
  const canUpdateGroup = user?.checkSpendPermission(
    SpendPermissions.programGroupsUpdate
  );
  const groupActionablesList: { name: string; text: string; value: string }[] =
    [];
  if (canUpdateGroup) {
    groupActionablesList.push({
      name: "Copy Group",
      text: "Copy Group",
      value: "Copy Group",
    });
  }
  if (canArchiveGroup) {
    groupActionablesList.push({
      name: "Archive Group",
      text: "Archive Group",
      value: "Archive Group",
    });
  }

  return (
    <SnapTable>
      <table className="ui celled hidden lg:table">
        <thead>
          <tr>
            <th className="min-w-[15%] max-w-[25%] py-5">
              Groups
              <SortIcon
                field="name"
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            <th className="py-5">
              Participants
              <SortIcon
                field="playerCount"
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            <th className="py-5">
              Payment Schedule
              <SortIcon
                field="paymentScheduleStatus"
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            <th className="numeric-cell py-5 pr-4">
              Paid
              <SortIcon
                field="paid"
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            <th className="numeric-cell py-5 pr-4">
              Processing
              <SortIcon
                field="processing"
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            <th className="numeric-cell py-5 pr-4">
              Upcoming
              <SortIcon
                field="upcoming"
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            <th className="numeric-cell py-5 pr-4">
              Past Due
              <SortIcon
                field="pastDue"
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            <th className="numeric-cell py-5 pr-4">
              Credited
              <SortIcon
                field="credited"
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            {!isArchived && (
              <th className="action-cell w-[5%]">{/* Action */}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            groupList.map((group: SpendGroup, idx) => {
              const latestSeason = group.latestSeason ?? group.seasons?.at(0);

              const total = {
                paid: latestSeason?.transactionTotals?.paid || 0,
                processing: latestSeason?.transactionTotals?.processing || 0,
                upcoming: latestSeason?.transactionTotals?.upcoming || 0,
                pastDue: latestSeason?.transactionTotals?.pastDue || 0,
                credited: latestSeason?.transactionTotals?.credited || 0,
              };
              return (
                <tr key={group.id || idx}>
                  <td className="py-4">
                    <SnapLink
                      className="text-sm text-blue-600 cursor-pointer"
                      sr-only="srOnly"
                      onClick={() => {
                        if (group.id) {
                          handleNavigate(group, latestSeason);
                        }
                      }}
                    >
                      {group.name}
                    </SnapLink>
                    <p
                      className="text-sm text-blue-600 cursor-pointer"
                      onClick={() => {
                        if (group.id) {
                          handleNavigate(group, latestSeason);
                        }
                      }}
                    ></p>
                    <p className="text-xs text-gray-500 block mt-0.5">
                      {getGroupSubtitle(group)}
                    </p>
                  </td>
                  <td>
                    <p className="flex justify-center">
                      {latestSeason?.playerCount ?? 0}
                    </p>
                  </td>
                  <td>
                    <SnapBadge
                      color={
                        isArchived
                          ? "gray"
                          : getBadgeColor(
                              latestSeason?.paymentScheduleStatus || "none"
                            )
                      }
                      className="capitalize flex justify-center"
                    >
                      {latestSeason?.paymentScheduleStatus || "N/A"}
                    </SnapBadge>
                  </td>
                  <td className="numeric-cell">
                    <p
                      className={`${
                        isArchived
                          ? "text-gray-600"
                          : total.paid > 0
                          ? "text-blue-500"
                          : ""
                      }`}
                    >
                      {FormatMoney(total.paid)}
                    </p>
                  </td>
                  <td className="numeric-cell">
                    <p className={isArchived ? "text-gray-600" : "text-black"}>
                      {FormatMoney(total.processing)}
                    </p>
                  </td>
                  <td className="numeric-cell">
                    <p className={isArchived ? "text-gray-600" : "text-black"}>
                      {FormatMoney(total.upcoming)}
                    </p>
                  </td>
                  <td className="numeric-cell">
                    <p
                      className={`flex justify-end ${
                        isArchived
                          ? "text-gray-600"
                          : total.pastDue > 0
                          ? "text-red-500"
                          : ""
                      }`}
                    >
                      {total.pastDue > 0 && (
                        <SnapIcon
                          icon="exclamation-solid"
                          // color="#EF4444"
                          size="sm"
                          className="mr-0.5"
                        />
                      )}
                      {FormatMoney(total.pastDue)}
                    </p>
                  </td>
                  <td className="numeric-cell">
                    <p className={isArchived ? "text-gray-600" : "text-black"}>
                      {FormatMoney(total.credited)}
                    </p>
                  </td>
                  {!isArchived && (
                    <td>
                      {groupActionablesList.length > 0 && (
                        <SnapDropDown
                          id="mobile-menu"
                          size="lg"
                          minimal
                          modal-type="drawer"
                          buttonText={group.name || ""}
                          options={groupActionablesList}
                          onSnap-dropdown-item-selected={(e) => {
                            e.detail.name.includes("Copy")
                              ? handleModalData(1, group)
                              : handleModalData(2, group);
                          }}
                        />
                      )}
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <SpinnerContainer loading={loading} inTable colSpan={8} />
          )}
        </tbody>
      </table>
    </SnapTable>
  );
}
export default Table;
