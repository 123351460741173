import { useLazyQuery } from "@apollo/client";
import { FullStory } from "@fullstory/browser";
import {
  Maybe,
  SpendAccountLimitsResponse,
  SpendBudget,
  SpendGroup,
  useSpendGroupsWithSeasonRosterQuery,
  useSpendOrganizationAccountLimitsLazyQuery,
} from "graphql/generated";
import { GET_GROUP, GET_GROUPS_FILTERED } from "graphql/queries/group";
import { maybeExtract } from "helpers/maybe-helper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SpendGroupType } from "./use-program";
import { ActiveUser } from "./use-user";
export default function useGroup(user: ActiveUser) {
  const navigate = useNavigate();
  const [activeGroup, setActiveGroup] = useState<SpendGroupType | undefined>(
    undefined
  );
  const [participant, setParticipant] = useState<string>("");
  const [isArchived, setIsArchived] = useState(false);
  const [groups, setGroups] = useState<SpendGroupType[]>([]);
  const [accountLimits, setAccountLimits] = useState<
    SpendAccountLimitsResponse | undefined
  >();
  const [loadedGroup, setLoadedGroup] = useState(false);
  const [getGroupQuery, { loading: loadingGroup, data: groupData }] =
    useLazyQuery(GET_GROUP);
  const [groupsWithSeasonsRoster, setGroupWithSeasonsRoster] = useState<
    Maybe<Maybe<SpendGroup>[]> | undefined
  >([]);
  const [includeArchiveGroupsWithSeasons, setIncludeArchiveGroupsWithSeasons] =
    useState(true);
  const [
    getAccountLimits,
    { loading: loadingAccountLimits, data: dataAccountLimits },
  ] = useSpendOrganizationAccountLimitsLazyQuery();
  const {
    loading: loadingGroupsWithSeasons,
    data: groupsWithSeasonsRosterData,
  } = useSpendGroupsWithSeasonRosterQuery({
    variables: {
      includeArchive: includeArchiveGroupsWithSeasons,
    },
  });

  ///Update this to not call all the information for the group - group name / id
  //Make a lazy query to retrieve the data we need.
  //also possibly utilize local storage if there is already a group
  //Check for group in local storage if match use local storage else call lazy query to get new data.

  const [getGroupsFiltered, { loading: loadingGroups, data: groupsData }] =
    useLazyQuery(GET_GROUPS_FILTERED);
  useEffect(() => {
    const programRoles = ["program_admin", "program_staff", "program_observer"];

    if (user.getIsUserReady()) {
      if (programRoles.includes(user?._session?.role?.name ?? "")) {
        getGroupsFiltered();
      } else {
        getGroupQuery();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.getIsUserReady()]);

  useEffect(() => {
    if (
      !loadingAccountLimits &&
      dataAccountLimits?.spendOrganizationAccountLimits
    ) {
      setAccountLimits(dataAccountLimits.spendOrganizationAccountLimits);
    }
  }, [loadingAccountLimits, dataAccountLimits]);
  useEffect(() => {
    if (!loadingGroup && groupData && groupData.spendGroup) {
      setGroups([groupData.spendGroup]);
      setLoadedGroup(true);
      setIsArchived(groupData.spendGroup.isArchived);
      setAndStoreActiveGroup(groupData.spendGroup);
    }
    if (!loadingGroups && groupsData && groupsData.spendGroupsFiltered) {
      setGroups(groupsData.spendGroupsFiltered.groups);
      setLoadedGroup(true);
    }
  }, [loadingGroup, groupData, loadingGroups, groupsData]);

  useEffect(() => {
    if (activeGroup) {
      getAccountLimits({
        variables: {
          groupId: activeGroup?.id,
        },
      });
    }
  }, [activeGroup, getAccountLimits]);

  useEffect(() => {
    let fetchedGroup = localStorage.getItem("ActiveGroup");
    if (fetchedGroup) {
      try {
        let group = JSON.parse(fetchedGroup);
        setActiveGroup(group);
        setIsArchived(group.isArchived);
      } catch {
        localStorage.removeItem("ActiveGroup");
      }
    } else {
      setActiveGroup(undefined);
    }
  }, []);

  useEffect(() => {
    if (!loadingGroupsWithSeasons && groupsWithSeasonsRosterData) {
      setGroupWithSeasonsRoster(
        groupsWithSeasonsRosterData.spendGroups?.groups
      );
    }
  }, [loadingGroupsWithSeasons, groupsWithSeasonsRosterData]);

  const getBudgets = (seasonId?: string, type?: string): SpendBudget[] => {
    const budgets =
      activeGroup?.seasons?.find((season) => season?.id === seasonId)
        ?.budgets ?? [];
    return type != null
      ? maybeExtract(budgets).filter(
          (budget) => budget.category?.type === type.toLowerCase()
        )
      : maybeExtract(budgets);
  };

  const setAndStoreActiveGroup = (group: SpendGroupType | undefined): void => {
    localStorage.setItem("ActiveGroup", JSON.stringify(group));
    setIsArchived(group?.isArchived ?? false);
    setActiveGroup(group);

    FullStory("setProperties", {
      type: "page",
      properties: {
        platform: "spend",
        group: group?.id,
      },
    });
  };

  const switchActiveGroupId = (groupId: string) => {
    const group = getGroupById(groupId);
    if (group) {
      setAndStoreActiveGroup(group);
      return true;
    }
    return false;
  };
  const navigateGroupPage = (
    page:
      | "banking"
      | "collections"
      | "cards"
      | "budget"
      | "staff"
      | "settings"
      | string,
    groupId: string
  ) => {
    if (!switchActiveGroupId(groupId)) return;

    switch (page) {
      case "banking":
        navigate("/groups/group-banking");
        break;
      case "collections":
        navigate("/groups/collections");
        break;
      case "cards":
        navigate("/groups/debit-cards");
        break;
      case "budget":
        navigate("/groups/budget");
        break;
      case "staff":
        navigate("/groups/staff");
        break;
      case "settings":
        navigate("/groups/settings");
        break;
      default:
        navigate("/groups");
        break;
    }
  };

  const getGroupById = (id: string): SpendGroupType | null => {
    return groups?.find((group) => group.id === id) ?? null;
  };
  const getGroup = (): SpendGroupType | undefined => {
    return groups?.at(0);
  };
  const getGroups = (): string[] => {
    return groups?.map((group) => group.name ?? "") || [];
  };

  return {
    activeGroup,
    setAndStoreActiveGroup,
    getBudgets,
    participant,
    setParticipant, // setRoster
    switchActiveGroupId,
    navigateGroupPage,
    isArchived,
    setIsArchived,
    groups,
    setGroups,
    getGroupById,
    getGroup,
    getGroups,
    loadedGroup,
    accountLimits,
    groupsWithSeasonsRoster,
    setIncludeArchiveGroupsWithSeasons,
  };
}
