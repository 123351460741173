import { useCallback } from 'react';
import {
  GetOrCreateCustomerData,
  User,
} from '@snap-mobile/payments-widget-utils';
import { V2 as api } from '@snap-mobile/payments-widget-client';
import { usePaymentsWidgetContext } from '../context';

export const usePaymentsCustomer = (): {
  createCustomer: () => Promise<User | undefined>;
} => {
  const { setCustomer, paymentData, user, stripeEnvironment, setError } =
    usePaymentsWidgetContext();

  const { idempotencyKey, presetCustomerId } = paymentData;

  const createCustomer = useCallback(async () => {
    let data: GetOrCreateCustomerData = {
      email: '',
      idempotencyKey: `${idempotencyKey}-customer`,
      metadata: {},
    };

    if (presetCustomerId) {
      data.existingCustomerId = presetCustomerId;
    } else if (user && user.email) {
      data.description = user.description;
      data.email = user.email;
      data.name = user.name;
    } else {
      return;
    }

    try {
      const cus = await api.getOrCreateCustomer(data, { stripeEnvironment });

      if (!cus) {
        throw new Error('Error creating customer.');
      }
      if ((cus as any).message) {
        throw new Error((cus as any).message);
      }

      setCustomer(cus);

      return cus;
    } catch (e: any) {
      setError(e.message || 'Error creating customer.');
    }
  }, [user, presetCustomerId, idempotencyKey, setCustomer]);

  return { createCustomer };
};
