import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { formatNumber } from "helpers/format-phone-number";
import { getBadgeColor } from "helpers/status-color";
import useModal from "hooks/use-modal";
import useToast from "hooks/use-toast";
import { useContext, useEffect, useState } from "react";
import Divider from "shared-components/divider";
import FloatingActionBtn from "shared-components/floating-action-btn";
import ToastMessage from "shared-components/toast-message";
import { SnapActionSheet, SnapBadge, SnapButton } from "suit";
import { ParticipantDetailType } from ".";
import AddInvoice from "./modals/add-invoice";
import CreditMemo from "./modals/credit-memo";
import EmailGuardian from "./modals/email-guardian";
import { SpendGroupRoster } from "graphql/generated";
import GroupContext from "context/group-context";
import { useNavigate } from "react-router-dom";
import { useSpendGroupsFilteredLazyQuery } from "graphql/generated";
import { SpinnerContainer } from "shared-components/spinner";
import SeasonContext from "context/season-context";
import EditParticipant from "./modals/edit-participant";

type DetailsProps = {
  participantDetails: ParticipantDetailType;
  participantData: SpendGroupRoster[];
  canEditParticipants: boolean;
  loading?: boolean;
};

function Details({
  participantDetails,
  participantData,
  canEditParticipants,
  loading,
}: DetailsProps) {
  const { isOpen: fabOpen, toggle: fabToggle } = useModal();
  const { isOpen: memoOpen, toggle: memoToggle } = useModal();
  const { isToastOpen, toggleToast, ...toast } = useToast();
  const { isOpen: invoiceOpen, toggle: invoiceToggle } = useModal();
  const { isOpen: emailGuardianOpen, toggle: emailGuardianToggle } = useModal();
  const { isOpen: editParticipantOpen, toggle: editParticipantToggle } =
    useModal();
  const { isOpen: paymentHistoryOpen, toggle: paymentHistoryToggle } =
    useModal();
  const [hasPendingInvite, setHasPendingInvite] = useState(false);

  function ActionBtns() {
    return (
      <>
        {hasUnpaidInvoices && (
          <SnapButton
            variant="secondary"
            icon="credit-card-solid"
            fullWidth
            onClick={memoToggle}
          >
            Add Credit Memo
          </SnapButton>
        )}
        {participantData.every(({ isArchived }) => !isArchived) && (
          <SnapButton
            fullWidth
            className="mt-4"
            variant="secondary"
            icon="document-add-solid"
            onClick={invoiceToggle}
          >
            Add Invoice
          </SnapButton>
        )}
        <SnapButton
          className="mt-4"
          variant="secondary"
          icon="mail-solid"
          fullWidth
          onClick={emailGuardianToggle}
        >
          Email Guardian
        </SnapButton>
        <SnapButton
          fullWidth
          className="mt-4"
          variant="secondary"
          icon="currency-dollar-solid"
          onClick={paymentHistoryToggle}
        >
          Email Payment History
        </SnapButton>
        {canEditParticipants && (
          <SnapButton
            fullWidth
            className="mt-4"
            variant="secondary"
            icon="pencil-alt-line"
            onClick={editParticipantToggle}
          >
            Edit Participant
          </SnapButton>
        )}
      </>
    );
  }

  const [selectedSeasonId, setSelectedSeasonId] = useState("");
  const [userEmails, setUserEmails] = useState<string[]>([]);
  const [hasUnpaidInvoices, setHasUnpaidInvoices] = useState(false);
  useEffect(() => {
    if (participantDetails) {
      let userEmailArray: string[] = [];
      let hasPI = false;
      participantDetails.Group.forEach((gr) => {
        if (!userEmailArray.includes(gr.email) && gr.email !== undefined) {
          userEmailArray.push(gr.email);
        }
        if (gr.status !== "Signed Up") {
          hasPI = true;
        }
      });
      setUserEmails(userEmailArray);
      setHasPendingInvite(hasPI);
    }
    if (participantData) {
      let invoices = participantData.flatMap((gr) => {
        return gr.invoices?.filter((i) => !i?.paid);
      });
      setHasUnpaidInvoices(invoices.length > 0);
    }
  }, [participantDetails, participantData]);

  const Group = useContext(GroupContext);
  const season = useContext(SeasonContext);
  const navigate = useNavigate();
  const [getGroup, { data: groupData, loading: loadingGroup }] =
    useSpendGroupsFilteredLazyQuery();

  useEffect(() => {
    if (!loadingGroup && groupData) {
      const group = groupData.spendGroupsFiltered?.groups?.at(0);
      Group?.setAndStoreActiveGroup({
        ...group!,
        currentSeason: group?.latestSeason || undefined,
      });
      season?.handleSelectedSeason({
        seasonId: selectedSeasonId,
        seasonsToSet: group?.seasons,
      });

      navigate("/groups/collections");
    }
    // eslint-disable-next-line
  }, [loadingGroup, groupData]);

  const handleGroupLink = (groupId: string, seasonId: string) => {
    getGroup({
      variables: {
        where: {
          ids: [groupId ?? ""],
          archived: false,
        },
      },
    });
    setSelectedSeasonId(seasonId);
  };
  return (
    <div className="card flex justify-between">
      {isToastOpen && (
        <ToastMessage
          message={toast.message}
          isToastOpen={isToastOpen}
          toggleToast={toggleToast}
          type={toast.type}
        />
      )}

      <div>
        <p className="text-lg font-semibold">Participant Details</p>
        {!loading ? (
          <>
            <div className="flex mt-5">
              <p className="text-sm mt-2 text-gray-500">Guardian</p>
              <p className="mt-1 ml-8">{participantDetails?.guardian}</p>
            </div>
            <div className="flex">
              <p className="text-sm mt-2 text-gray-500">Email</p>
              <div>
                {userEmails.map((email: string, idx) => {
                  return (
                    <p
                      key={`group_email_${idx}`}
                      className="mt-1  ml-14 break-all"
                    >
                      {email}
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="flex">
              <p className="text-sm mt-2 text-gray-500">Phone</p>
              <p className=" mt-1 ml-12">
                {participantDetails?.phone === ""
                  ? "N/A"
                  : formatNumber(participantDetails?.phone ?? "")}
              </p>
            </div>
            <div className="flex">
              <p className="text-sm mt-2 text-gray-500">Group</p>
              <div className="ml-12 mt-1">
                {participantDetails?.Group.map((gr, idx) => {
                  return (
                    <div key={`group_details_${idx}`}>
                      <div className="flex">
                        <p
                          onClick={() => {
                            handleGroupLink(
                              gr.groupId ?? "",
                              gr.seasonId ?? ""
                            );
                          }}
                          className="mt-1 font-semibold text-blue-500 cursor-pointer"
                        >
                          {gr.name}
                        </p>
                        {gr.status === "Signed Up" ? (
                          <p className="ml-2 text-gray-500 text-sm self-center mt-1">
                            Signed up{" "}
                            {FormatDate(
                              gr.joinedDate,
                              DateFormatSupported.Numbers
                            )}
                          </p>
                        ) : (
                          <div className="self-center ml-2">
                            <SnapBadge
                              color={getBadgeColor(gr.status)}
                              size="tiny"
                            >
                              {gr.status}
                            </SnapBadge>
                          </div>
                        )}
                      </div>
                      <p className="text-sm">{gr.season}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <div className="h-full w-full flex items-center justify-center">
            <SpinnerContainer loading={loading} />
          </div>
        )}
      </div>
      {canEditParticipants && (
        <>
          <div className="mr-14 border-l-2 pl-16 pt-5 hidden lg:inline">
            <ActionBtns />
          </div>
          <FloatingActionBtn
            onClick={fabToggle}
            icon={"dots-horizontal-solid"}
          />
        </>
      )}
      {fabOpen && (
        <SnapActionSheet onClick={fabToggle}>
          <Divider isVisibleOnMobile className="mb-4" />
          <div className="mx-4">
            <ActionBtns />
          </div>
        </SnapActionSheet>
      )}
      {hasUnpaidInvoices && memoOpen && (
        <CreditMemo
          isOpen={memoOpen}
          toggle={memoToggle}
          participantData={participantData}
          participantDetails={participantDetails}
        />
      )}
      {invoiceOpen && (
        <AddInvoice
          isOpen={invoiceOpen}
          toggle={invoiceToggle}
          participant={participantDetails}
        />
      )}
      <EmailGuardian
        isOpen={emailGuardianOpen}
        toggle={emailGuardianToggle}
        type="message"
        participant={participantDetails}
      />
      <EmailGuardian
        isOpen={paymentHistoryOpen}
        toggle={paymentHistoryToggle}
        type="history"
        participant={participantDetails}
      />
      <EditParticipant
        isOpen={editParticipantOpen}
        toggle={editParticipantToggle}
        participantDetails={participantDetails}
        hasPendingInvite={hasPendingInvite}
      />
    </div>
  );
}

export default Details;
