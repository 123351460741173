import { FormatDate, DateFormatSupported } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { SnapTable } from "suit";
import { calcTotalPlusFee } from "../make-payment/make-payment-helper";
import GroupContext from "context/group-context";
import { useContext } from "react";
import { PlayerInfoType } from "./change-payment-modal";

type SelectedInvoicesTableProps = {
  selectedPlayerInvoice: PlayerInfoType[];
  selectedPaymentTypeOption: string | undefined;
};
export const SelectedInvoicesTable = ({
  selectedPlayerInvoice,
  selectedPaymentTypeOption,
}: SelectedInvoicesTableProps) => {
  const activeGroup = useContext(GroupContext)?.activeGroup;
  return (
    <SnapTable className="hidden lg:flex">
      <table className="ui table table-fixed mb-5">
        <thead>
          <tr>
            <th>Group</th>
            <th>Participant</th>
            <th>Description</th>
            <th className="text-right">Billing Date</th>
            <th className="text-right">Amount</th>
          </tr>
        </thead>
        <tbody>
          {selectedPlayerInvoice?.map((player, playerIdx) => {
            return player.invoices?.map((invoice, idx) => {
              return (
                <tr className="h-8" key={idx}>
                  <td>{player.team}</td>
                  <td>{player.player}</td>
                  <td>{invoice?.description}</td>
                  <td className="text-right">
                    {" "}
                    {FormatDate(invoice?.dueDate!, DateFormatSupported.Words)}
                  </td>
                  <td className="text-right">
                    {selectedPaymentTypeOption === "Pay by: Card"
                      ? FormatMoney(
                          calcTotalPlusFee(
                            invoice?.balanceDue ?? 0,
                            activeGroup?.organizationFees?.cardPercent ?? 0,
                            activeGroup?.organizationFees?.cardBaseFee ?? 0
                          )
                        )
                      : FormatMoney(
                          calcTotalPlusFee(
                            invoice?.balanceDue ?? 0,
                            activeGroup?.organizationFees?.achPercent ?? 0,
                            activeGroup?.organizationFees?.achBaseFee ?? 0
                          )
                        )}
                  </td>
                </tr>
              );
            });
          })}
        </tbody>
      </table>
    </SnapTable>
  );
};
