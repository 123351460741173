import { Maybe } from "graphql/jsutils/Maybe";
import { getBadgeColor } from "helpers/status-color";
import { SnapBadge, SnapIcon } from "suit";

export enum TableValueStyle {
  TEXT,
  LINK,
  BADGE,
  TEXTICON,
}

export type TableRowLabelValueProps = {
  label: string;
  value: Maybe<string> | undefined;
  valueStyle?: TableValueStyle;
  className?: string;
  customContainerStyle?: string;
  labelStyle?: string;
  onClick?: () => void;
};

function TableRowLabelValue({
  label,
  value,
  valueStyle = TableValueStyle.TEXT,
  className,
  customContainerStyle,
  labelStyle,
  onClick,
}: TableRowLabelValueProps) {
  return (
    <tr
      className={`${customContainerStyle ? customContainerStyle : "table-row"}`}
    >
      <td className="mr-auto">
        <p
          className={`font-normal text-sm text-gray-500 ${className} ${labelStyle}`}
        >
          {label}
        </p>
      </td>
      <td
        className={`${
          !customContainerStyle && "flex justify-end "
        } lg:table-cell`}
      >
        {
          {
            0: (
              <p className={`font-medium text-sm ${className}`}>
                {value ?? "-"}
              </p>
            ),
            1: (
              <p
                className={`font-medium text-base text-blue-600 cursor-pointer`}
                onClick={onClick}
              >
                {value ?? "-"}
              </p>
            ), //TODO Replace with SnapLink
            2: (
              <SnapBadge
                className="text-sm capitalize whitespace-nowrap"
                color={getBadgeColor(value ?? "")}
                size="sm"
              >
                {value}
              </SnapBadge>
            ),
            3: (
              <div className={`flex ${className}`}>
                <SnapIcon icon="exclamation-solid" color="#EF4444" />
                <p className="font-medium text-sm">{value ?? "-"}</p>
              </div>
            ),
          }[valueStyle.valueOf()]
        }
      </td>
    </tr>
  );
}

export default TableRowLabelValue;
