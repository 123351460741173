import { SnapButton } from "suit";

type FloatingActionBtnProps = {
  onClick: () => void;
  icon: "dots-horizontal-solid" | "plus-solid" | "switch-horizontal-solid";
};

function FloatingActionBtn({ onClick, icon }: FloatingActionBtnProps) {
  return (
    <div className="lg:hidden flex fixed bottom-[66px] left-7 md:left-28 md:bottom-2">
      <SnapButton icon={icon} onClick={onClick} variant="primary" size="xl" />
    </div>
  );
}

export default FloatingActionBtn;
