const NoResults = ({
  loading,
  inTable,
  message,
  colSpan,
}: {
  loading: boolean;
  inTable?: boolean;
  message?: string;
  colSpan?: number;
}) => {
  const renderDiv = () => {
    return (
      <div className="flex justify-center">
        <p>{message || "No Results"}</p>
      </div>
    );
  };
  const renderTableRow = () => {
    return (
      <tr>
        <td colSpan={colSpan || 1} className="py-3">
          {renderDiv()}
        </td>
      </tr>
    );
  };
  return loading ? (inTable ? renderTableRow() : renderDiv()) : null;
};
export default NoResults;
