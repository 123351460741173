import React, { useContext } from "react";
import CustomModal from "shared-components/modal";
import QRCode from "react-qr-code";
import { downloadFile } from "helpers/export-csv";
import ProgramContext from "context/program-context";

type QRCodeModalProps = {
  link: string;
  isQrCodeOpen: boolean;
  qrCodeToggle: () => void;
};

function QRCodeModal({ link, isQrCodeOpen, qrCodeToggle }: QRCodeModalProps) {
  const program = useContext(ProgramContext);

  const handleSaveQrCode = () => {
    const qrcode = document.getElementById("qr-code");
    const downloadable = qrcode?.innerHTML;
    downloadFile({
      data: downloadable,
      fileName: `${program?.organization?.legalName} sign up link`,
      fileType: "image/svg+xml",
    });
  };

  return (
    <CustomModal
      isOpen={isQrCodeOpen}
      toggle={qrCodeToggle}
      title={"Sign Up Link QR Code"}
      btn1={{
        text: "Save Code",
        onClick: handleSaveQrCode,
      }}
      btn2={{
        text: "Close",
        onClick: qrCodeToggle,
      }}
    >
      <div
        className="modal-card h-full flex justify-center items-center"
        id="qr-code"
      >
        <QRCode
          value={link}
          style={{
            display: "flex",
            margin: "auto",
            alignItems: "center",
            height: "100%",
          }}
        />
      </div>
    </CustomModal>
  );
}

export default QRCodeModal;
