import { gql } from "@apollo/client";

export const PermissionsDocument = gql`
  query MyPermissions {
    me {
      email
      permissions
      id
      firstName
      lastName
      phoneNumber
      parents
    }
  }
`;
