import { useLazyQuery } from "@apollo/client";
import ProgramContext from "context/program-context";
import ToastContext from "context/toast-context";
import {
  SpendBudget,
  SpendGroupRoster,
  SpendInvoice,
  useSpendInvoiceRefundMutation,
} from "graphql/generated";
import { GET_BUDGET_BY_ID } from "graphql/queries/budgets";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney, ParseMoney } from "helpers/format-money";
import { MapAndCalcSum } from "helpers/map-and-reduce";
import useModal from "hooks/use-modal";
import useToast from "hooks/use-toast";
import { useContext, useEffect, useState } from "react";
import InputMask from "shared-components/input-mask";
import CustomModal, { BtnState } from "shared-components/modal";
import TableRowLabelValue, {
  TableRowLabelValueProps,
} from "shared-components/table-row-label-value";
import ToastMessage from "shared-components/toast-message";
import Tooltip from "shared-components/tooltip";
import { SnapAlert, SnapAlertTitle, SnapIcon } from "suit";

type RefundPaymentProps = {
  refundOpen: boolean;
  refundToggle: () => void;
  invoice: SpendInvoice;
  participant: SpendGroupRoster;
};

function RefundPayment({
  refundOpen,
  refundToggle,
  invoice,
  participant,
}: RefundPaymentProps) {
  const toast = useContext(ToastContext);
  const program = useContext(ProgramContext);

  const { isToastOpen: isErrorToastOpen, ...errorToast } = useToast();
  const { isOpen: tooltipOpen, toggle: tooltipToggle } = useModal();

  const [amount, setAmount] = useState("");
  const [isBtnActive, setIsBtnActive] = useState(true);
  const [budgetItem, setBudgetItem] = useState<SpendBudget>();

  const availableBalance = program?.accounts?.find(
    (account) => account.id === participant.group?.accountId
  );

  const [refundMutation, { data, loading, error }] =
    useSpendInvoiceRefundMutation({
      refetchQueries: ["GetParticipantsDetails"],
      fetchPolicy: "network-only",
    });

  const [getBudgetItemById, { loading: loadingBudget, data: budgetData }] =
    useLazyQuery(GET_BUDGET_BY_ID);

  useEffect(() => {
    getBudgetItemById({
      variables: {
        spendBudgetId: invoice.budgetItemId,
      },
    });
    // eslint-disable-next-line
  }, [invoice]);

  useEffect(() => {
    if (!loadingBudget && budgetData) {
      setBudgetItem(budgetData.spendBudget.budget);
    }
  }, [loadingBudget, budgetData]);

  useEffect(() => {
    if (!loading && data?.spendInvoiceRefund?.invoiceId) {
      toast?.setToastProps({ message: "Refund success.", type: "success" });
      toast?.toggleToast();
      setAmount("");
      refundToggle();
    } else if (!loading && error) {
      errorToast?.setToastProps({ message: error.message, type: "danger" });
      errorToast?.toggleToast();
    }
    setIsBtnActive(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  const prepData = () => {
    let data: TableRowLabelValueProps[] = [];
    data.push({ label: "Group", value: participant.group?.name ?? "-" });
    data.push({
      label: "Season",
      value: participant.group?.latestSeason?.name ?? "-",
    });
    data.push({ label: "Participant", value: participant.roster?.name ?? "-" });
    const discountAndCredits =
      (invoice.discountAmount || 0) +
      MapAndCalcSum(invoice.creditMemos ?? [], "creditApplied");
    data.push({
      label: "Invoice Amount",
      value: FormatMoney(invoice.amount),
    });
    const amount = (invoice.amount || 0) - discountAndCredits;
    data.push({
      label: "Refundable Amount",
      value: FormatMoney(amount - (invoice.balanceDue ?? 0)),
    });
    data.push({
      label: "Discounts & Credits",
      value: FormatMoney(discountAndCredits),
    });
    data.push({ label: "Description", value: invoice.description ?? "-" });
    data.push({
      label: "Date Paid",
      value: FormatDate(invoice.dueDate ?? "", DateFormatSupported.Numbers),
    });
    data.push({
      label: `${budgetItem?.category.type} budget item`,
      value: `${budgetItem?.description} | ${FormatMoney(
        budgetItem?.targetAmount ?? 0
      )}`,
      customContainerStyle: "flex flex-col lg:table-row",
      labelStyle: "capitalize",
    });
    data.push({
      label: "Available Balance",
      value: FormatMoney(availableBalance?.available || 0),
      className: "text-blue-600 cursor-pointer",
    });
    return data;
  };

  const refund = () => {
    const parsedMoney = ParseMoney(amount);
    const discountAndCredits =
      (invoice.discountAmount || 0) +
      MapAndCalcSum(invoice.creditMemos ?? [], "creditApplied");
    const refundableAmount =
      (invoice.amount || 0) - discountAndCredits - (invoice.balanceDue ?? 0);
    if ((availableBalance?.available || 0) >= parsedMoney) {
      if (parsedMoney === 0) {
        errorToast?.setToastProps({
          message: "Refund amount cannot be 0",
          type: "danger",
        });
        errorToast?.toggleToast();
      } else if (parsedMoney > refundableAmount) {
        errorToast?.setToastProps({
          message: "Refund amount cannot exceed refundable amount",
          type: "danger",
        });
        errorToast?.toggleToast();
      } else if (invoice.id) {
        setIsBtnActive(false);
        refundMutation({
          variables: {
            input: {
              amount: parsedMoney,
              invoiceId: invoice.id,
            },
          },
        });
      }
    } else {
      errorToast?.setToastProps({
        message: "Refund amount cannot exceed Available Balance",
        type: "danger",
      });
      errorToast?.toggleToast();
    }
  };

  return (
    <CustomModal
      isOpen={refundOpen}
      toggle={() => {
        setIsBtnActive(true);
        refundToggle();
      }}
      title={"Refund Payment"}
      btn1={{
        text: "Refund Payment",
        btnStyle: "primary",
        btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
        onClick: refund,
      }}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: () => {
          setIsBtnActive(true);
          refundToggle();
        },
      }}
      customStyle="lg:mt-10 lg:w-[1082px]"
    >
      <div className="modal-card mb-5">
        {isErrorToastOpen && (
          <ToastMessage
            message={errorToast.message}
            isToastOpen={isErrorToastOpen}
            toggleToast={function (): void {
              errorToast.toggleToast();
            }}
            type={errorToast.type}
          />
        )}
        <SnapAlert type="warning" className="mb-3 lg:mb-6">
          <SnapAlertTitle>
            <p>
              <span className="font-bold">Note:</span> Make sure you have
              adequate funds in your account to cover the refund amount{" "}
              <em>before</em> submitting.
            </p>
          </SnapAlertTitle>
        </SnapAlert>
        <div className="mb-4 relative">
          <div className="flex lg:hidden" onClick={tooltipToggle}>
            <SnapIcon icon="information-circle-solid" size="sm" color="gray" />
            <p className="text-sm text-gray-500 ml-2">About Refunds</p>
          </div>
          <Tooltip tooltipOpen={tooltipOpen} tooltipToggle={tooltipToggle}>
            <div className="flex">
              <div className="pr-3">
                <p>
                  Refunds will be credited to the payer's account through used
                  payment method within 10 business days. Card payment
                  processing fees will be covered by Snap! Spend. Payer will not
                  be charged again for this invoice.
                </p>
                <p className="mt-4">
                  The refund amount will be deducted from the income budget item
                  shown below.
                </p>
              </div>
            </div>
          </Tooltip>
        </div>
        <p className="text-sm text-gray-500 hidden lg:flex">
          Refunds will be credited to the payer's account through used payment
          method within 10 business days. Card payment processing fees will be
          covered by Snap! Spend. Payer will not be charged again for this
          invoice.
        </p>
        <p className="text-sm text-gray-500 border-b-2 mt-4 pb-4 hidden lg:flex">
          The refund amount will be deducted from the income budget item shown
          below.
        </p>
        <table className="w-full lg:w-auto">
          <tbody>
            {prepData().map((p, idx) => {
              return (
                <TableRowLabelValue
                  key={idx}
                  label={p.label}
                  value={p.value?.toString()}
                  className={`lg:ml-24 mt-2 ${p.className}`}
                  customContainerStyle={p.customContainerStyle}
                  labelStyle={p.labelStyle}
                />
              );
            })}
          </tbody>
        </table>
        <div className="lg:w-72 mt-4">
          <InputMask
            amount={amount}
            setAmount={setAmount}
            hasError={false}
            onChange={function (): void {}}
          />
        </div>
      </div>
    </CustomModal>
  );
}

export default RefundPayment;
