export const HandleRoleNameChangeToBack = (roleName: string) => {
  let roleBackEndName;
  switch (roleName) {
    case "Program Staff":
      roleBackEndName = "program_staff";
      break;
    case "Program Observer":
      roleBackEndName = "program_observer";
      break;
    case "Group Staff":
      roleBackEndName = "group_staff";
      break;
    case "Group Observer":
      roleBackEndName = "group_observer";
      break;
    default:
      roleBackEndName = "";
  }
  return roleBackEndName;
};
