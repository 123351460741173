import { SpendSortInput, SpendSortOrderEnum } from "../../graphql/generated";
import { SnapIcon } from "suit";

const SortIcon = ({
  field,
  currentSort,
  toggleSort,
}: {
  field: string;
  currentSort?: SpendSortInput;
  toggleSort: (field: string) => void;
}) => {
  let icon: "ascending-solid" | "descending-solid" | "selector-solid" =
    "selector-solid";

  if (currentSort?.field && currentSort?.field === field) {
    icon =
      currentSort?.order === SpendSortOrderEnum.Asc
        ? "ascending-solid"
        : "descending-solid";
  }

  return (
    <SnapIcon
      className="cursor-pointer"
      icon={icon}
      size="xs"
      onClick={() => toggleSort(field)}
    />
  );
};

export default SortIcon;
