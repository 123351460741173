import Details from "./details";
import Invoices from "./invoices";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GroupRosterFilterEnum,
  SpendGroupRoster,
  useGetParticipantsDetailsQuery,
} from "graphql/generated";
import GroupContext from "context/group-context";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import UserContext from "context/user-context";
import { SpendPermissions } from "types/roles-permissions";
export type ParticipantDetailType =
  | {
      userId: string;
      rosterId: string;
      participantName: string;
      guardian: string;
      email: string;
      phone: string;
      Group: {
        name: string;
        season: string;
        joinedDate: string;
        status: string;
        email: string;
        isSelected: boolean;
        groupId: string;
        guardian: string;
        seasonId: string;
        groupRosterId: string;
        sharedAccount: boolean;
      }[];
    }
  | undefined;
function ParticipantDetails() {
  const canEditParticipants =
    useContext(UserContext)?.checkSpendPermission(
      SpendPermissions.groupBudgetUpdate
    ) ?? false;
  const groupContext = useContext(GroupContext);
  const [participantData, setParticipantData] = useState<SpendGroupRoster[]>(
    []
  );
  const [detailData, setDetailData] =
    useState<ParticipantDetailType>(undefined);
  const { id } = useParams();
  const { data, loading } = useGetParticipantsDetailsQuery({
    variables: {
      filterBy: GroupRosterFilterEnum.RosterId,
      filterValue: id,
    },
    fetchPolicy: "network-only",
    skip: !id,
  });

  useEffect(() => {
    if (
      !loading &&
      data &&
      data.spendGroupRosters &&
      data.spendGroupRosters.groupRosters
    ) {
      const firstGroupRoster = data.spendGroupRosters.groupRosters.at(0);
      if (firstGroupRoster) {
        groupContext?.setParticipant(firstGroupRoster.roster?.name ?? "");
        setParticipantData(
          data.spendGroupRosters.groupRosters as SpendGroupRoster[]
        );
        let pd: ParticipantDetailType = {
          userId: firstGroupRoster.userId ?? "",
          rosterId: firstGroupRoster.rosterId ?? "",
          participantName: firstGroupRoster.roster?.name ?? "",
          guardian: firstGroupRoster.guardianName ?? "N/A",
          email: firstGroupRoster?.roster?.email ?? "N/A",
          phone: firstGroupRoster?.phoneNumber ?? "N/A",
          Group: data.spendGroupRosters.groupRosters
            .filter((roster) => !roster?.isArchived)
            .map((roster) => {
              let seasonName;
              let foundSeason = roster?.group?.seasons?.find(
                (season) => season?.id === roster.seasonId
              );
              seasonName = {
                name: foundSeason?.name,
                startDateAt: foundSeason?.startDateAt,
                endDateAt: foundSeason?.endDateAt,
              };
              return {
                name: roster?.group?.name ?? "N/A",
                season:
                  seasonName?.name ??
                  `${FormatDate(
                    seasonName?.startDateAt ?? "",
                    DateFormatSupported.NumbersLight
                  )} - ${FormatDate(
                    seasonName?.endDateAt ?? "",
                    DateFormatSupported.NumbersLight
                  )}`,
                joinedDate: roster?.joinedAt ?? "N/A",
                status:
                  roster?.invite?.status === "accepted"
                    ? "Signed Up"
                    : "Not Signed Up",
                email: roster?.roster?.email || "",
                isSelected: false,
                groupId: roster?.group?.id || "",
                seasonId: roster?.seasonId ?? "",
                guardian: firstGroupRoster.guardianName ?? "N/A",
                groupRosterId: roster?.id ?? "",
                sharedAccount: roster?.group?.sharedAccount ?? false,
              };
            }),
        };
        setDetailData(pd);
      }
    }
    // eslint-disable-next-line
  }, [data, loading]);
  return (
    <div className="wrapper">
      {
        <>
          <Details
            participantDetails={detailData}
            participantData={participantData}
            canEditParticipants={canEditParticipants}
            loading={loading}
          />
          <Invoices
            participant={participantData}
            canEditParticipants={canEditParticipants}
            loading={loading}
          />
        </>
      }
    </div>
  );
}

export default ParticipantDetails;
