import { useContext } from "react";
import CustomModal, { BtnState, BtnType } from "shared-components/modal";
import { SnapAlert, SnapAlertDescription, SnapAlertTitle } from "suit";
import ToastContext from "context/toast-context";
import { useMutation } from "@apollo/client";
import { ARCHIVE_PARTICIPANT_FROM_SEASON } from "graphql/mutations/group";
import SeasonContext from "context/season-context";
import { SpendRosterResend } from "types/group-roster";
import { PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON } from "graphql/queries/payment-schedule";
type ArchiveParticipantProps = {
  archiveParticipantOpen: boolean;
  archiveParticipantToggle: () => void;
  selectedItems: SpendRosterResend[];
  deselectAllAction: () => void;
};

function ArchiveParticipant({
  archiveParticipantOpen,
  archiveParticipantToggle,
  selectedItems,
  deselectAllAction,
}: ArchiveParticipantProps) {
  const Toast = useContext(ToastContext);
  const Season = useContext(SeasonContext);
  const [archiveParticipants, { loading }] = useMutation(
    ARCHIVE_PARTICIPANT_FROM_SEASON,
    {
      refetchQueries: [
        "SpendGroupRostersBySeason",
        {
          query: PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON,
          variables: {
            seasonId: Season?.selectedSeason?.id,
            groupIdOrSeasonId: Season?.selectedSeason?.id,
          },
          fetchPolicy: "network-only",
        },
      ],
      onCompleted: () => {
        Toast?.setToast({
          message: "Participant(s) have been archived.",
          type: "success",
        });
        archiveParticipantToggle();
        deselectAllAction();
      },
    }
  );

  const handleArchiveParticipant = () => {
    const groupRosterIds = selectedItems.map((item) => item.groupRosterId);
    archiveParticipants({
      variables: {
        input: {
          groupRosterIds: groupRosterIds,
          isArchived: true,
        },
      },
    });
  };
  const btn1: BtnType = {
    text: "Archive",
    btnStyle: "danger",
    onClick: () => handleArchiveParticipant(),
    btnState: loading ? BtnState.DISABLED : BtnState.BASE,
  };

  const btn2: BtnType = {
    text: "Cancel",
    btnStyle: "tertiary",
    onClick: archiveParticipantToggle,
  };

  return (
    <CustomModal
      isOpen={archiveParticipantOpen}
      toggle={archiveParticipantToggle}
      title={"Archive Participants"}
      btn1={btn1}
      btn2={btn2}
    >
      <div className="modal-card pl-6">
        <SnapAlert type="warning" className="mb-3">
          <SnapAlertTitle>
            Are you sure you want to archive the selected participant(s) from
            this team?
          </SnapAlertTitle>
          <SnapAlertDescription>
            <p>
              This will archive all unpaid invoices for this season from the
              archived participant or participants.
            </p>
            <p className="my-1">
              These unpaid invoices will not be billed nor used in the
              calculation of Upcoming and Past Due amounts.
            </p>
            <p>Parents of archive participants will be notified via email.</p>
          </SnapAlertDescription>
        </SnapAlert>
      </div>
    </CustomModal>
  );
}

export default ArchiveParticipant;
