import { SpendInvoice, SpendSortInput } from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { getBadgeColor } from "helpers/status-color";
import { SpinnerContainer } from "shared-components/spinner";
import SortIcon from "shared-components/table/sort-icon";
import { SnapBadge, SnapTable } from "suit";
type TableProps = {
  listItems: SpendInvoice[] | undefined;
  handleToggleModal: (item: SpendInvoice) => void;
  handleNavGroup: (groupId: string, seasonId: string) => void;
  handleNavigateParticipant: (userId: string) => void;
  toggleSort: (field: string) => void;
  currentSort: SpendSortInput | undefined;
  loadingInvoices?: boolean;
  exporting?: boolean;
};

const prepStatusBadge = (item: SpendInvoice) => {
  let status = item.status?.replace("_", " ");
  const color = getBadgeColor(item.status ?? "");
  return { status, color };
};

function Table({
  listItems,
  handleToggleModal,
  handleNavGroup,
  handleNavigateParticipant,
  toggleSort,
  currentSort,
  loadingInvoices,
  exporting,
}: TableProps) {
  return (
    <SnapTable>
      <table className="ui celled lg:table hidden">
        <thead>
          <tr>
            <th className="w-[15%]">Invoice</th>
            <th className="w-[15%]">
              Group
              <SortIcon
                field={"groupName"}
                toggleSort={toggleSort}
                currentSort={currentSort}
              />
            </th>
            <th className="w-[15%]">
              Participant
              <SortIcon
                field={"participant"}
                toggleSort={toggleSort}
                currentSort={currentSort}
              />
            </th>
            <th>Status</th>
            <th>
              Due Date
              <SortIcon
                field={"dueDate"}
                toggleSort={toggleSort}
                currentSort={currentSort}
              />
            </th>
            <th>
              Invoice Amount
              <SortIcon
                field={"invoiceAmount"}
                toggleSort={toggleSort}
                currentSort={currentSort}
              />
            </th>
            <th>
              Balance Due
              <SortIcon
                field={"balanceDue"}
                toggleSort={toggleSort}
                currentSort={currentSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {
            !loadingInvoices && !exporting ? (
              listItems?.map((item: SpendInvoice, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <p
                        className="text-blue-600 cursor-pointer"
                        onClick={() => handleToggleModal(item)}
                      >
                        {item.description}
                      </p>
                    </td>
                    <td>
                      <p
                        className="text-sm text-blue-600 cursor-pointer"
                        onClick={() =>
                          handleNavGroup(
                            item.groupRoster?.group?.id ?? "",
                            item.groupRoster?.season?.id ?? ""
                          )
                        }
                      >
                        {item.groupRoster?.group?.name}
                      </p>
                      <p className="text-xs text-gray-500">
                        {item.groupRoster?.season?.name}
                      </p>
                    </td>
                    <td>
                      <p
                        className="text-sm text-blue-600 cursor-pointer"
                        onClick={() =>
                          handleNavigateParticipant(
                            item.groupRoster?.roster?.id ?? ""
                          )
                        }
                      >
                        {item.groupRoster?.roster?.name}
                      </p>
                      <p className="text-xs text-gray-500">
                        {item.groupRoster?.roster?.email}
                      </p>
                    </td>
                    <td className="py-4">
                      <SnapBadge color={prepStatusBadge(item).color}>
                        <p className="min-w-[55px] text-center capitalize">
                          {prepStatusBadge(item).status}
                        </p>
                      </SnapBadge>
                    </td>
                    <td>
                      <p className="text-sm">
                        {FormatDate(
                          item.dueDate || "",
                          DateFormatSupported.Numbers
                        )}
                      </p>
                    </td>
                    <td align="center">
                      <p>{FormatMoney(item.amount || 0)}</p>
                    </td>
                    <td align="center">
                      <p>{FormatMoney(item.balanceDue || 0)}</p>
                    </td>
                  </tr>
                );
              })
            ) : (
              <SpinnerContainer
                loading={(loadingInvoices || exporting) ?? false}
                inTable
                colSpan={6}
              />
            )
          }
        </tbody>
      </table>
    </SnapTable>
  );
}

export default Table;
