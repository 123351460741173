import { SpendBankAccount } from "graphql/generated";

export const extractBankLabel = (
  linkedBank: SpendBankAccount | null | undefined
): string => {
  if (linkedBank == null) {
    return "";
  }
  const { name, mask } = linkedBank;
  const linkName = linkedBank.institution?.name || name;
  return `${linkName} (${mask})`;
};
