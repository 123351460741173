import { useQuery } from "@apollo/client";
import DisplayContext from "context/display-context";
import GroupContext from "context/group-context";
import UserContext from "context/user-context";
import { SpendBankAccount } from "graphql/generated";
import { GET_GROUP_BANK_ACCOUNTS } from "graphql/queries/group";

import useModal from "hooks/use-modal";
import useToast from "hooks/use-toast";
import { tabs } from "pages/programs/banking/metadata/tabs";
import { transactionMenuItems } from "pages/programs/banking/metadata/transaction-menu-items";
import { useEffect, useState } from "react";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import AccountOverviewCard from "shared-components/account-overview-card";
import BadgedTabs from "shared-components/badged-tabs";
import Transfer from "shared-components/banking/transfer";
import Divider from "shared-components/divider";
import FloatingActionBtn from "shared-components/floating-action-btn";
import ToastMessage from "shared-components/toast-message";
import { SnapActionSheet, SnapButton, SnapLabeledToggle } from "suit";
import { bankActions } from "types/club-banking";
import { SpendPermissions } from "types/roles-permissions";
import { useContextStrict } from "../../../helpers/context-strict";
import ProgramContext from "context/program-context";
import { useNavigate } from "react-router-dom";
import LegacyTransactions from "shared-components/banking/legacy-transactions";
import SendMoney from "shared-components/banking/send-money";
import MigrationAlert from "shared-components/banking/migration-alert";
import TransactionsContainer from "pages/programs/banking/transactions-container";

function TeamBanking() {
  const navigate = useNavigate();
  const display = useContextStrict(DisplayContext);
  const activeGroup = useContextStrict(GroupContext);
  const program = useContextStrict(ProgramContext);
  const user = useContextStrict(UserContext);

  useEffect(() => {
    if (activeGroup.activeGroup?.sharedAccount) navigate("/group/collections");
  }, [activeGroup.activeGroup, navigate]);

  const { isOpen: transferOpen, toggle: transferToggle } = useModal();
  const { isOpen: isSendMoneyOpen, toggle: sendMoneyToggle } = useModal();
  const { isToastOpen, ...toast } = useToast();
  const { loading: accountLoading, data: accountData } = useQuery(
    GET_GROUP_BANK_ACCOUNTS,
    {
      variables: {
        groupId: activeGroup?.activeGroup?.id,
      },
    }
  );

  useEffect(() => {
    if (!activeGroup.activeGroup) {
      activeGroup.setAndStoreActiveGroup(activeGroup?.groups?.at(0));
    }
  }, [activeGroup, program]);

  const [hasLinkedAccount, setHasAccount] = useState(false);
  const [isBankOptionsOpen, setIsBankOptionsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [linkedAccount, setLinkedAccount] = useState<
    SpendBankAccount | undefined
  >(undefined);
  const [unitBalance, setUnitBalance] = useState(0);
  const [settleCharge, setSettleCharge] = useState(false);
  const [bankingActions, setBankingActions] = useState<bankActions[]>([]);
  const [legacyTransactionsOpen, setLegacyTransactionsOpen] = useState(false);
  const [accountStatus, setAccountStatus] = useState("");

  const canUpdateBanking =
    user.checkSpendPermission(
      !activeGroup.activeGroup
        ? SpendPermissions.programBankUpdate
        : SpendPermissions.groupBankUpdate
    ) ?? false;
  const canAddAccount =
    user.checkSpendPermission(SpendPermissions.programBankUpdate) ?? false;
  const canUpdateBudget =
    user.checkSpendPermission(SpendPermissions.groupBudgetUpdate) ?? false;

  const guardedTransactionMenuItems = canUpdateBudget
    ? transactionMenuItems
    : [];

  const actions: bankActions[] = [
    {
      icon: "cash-solid",
      label: "Deposit",
      onClick: () => {
        display?.setSelectedUnitAccount(
          activeGroup?.activeGroup?.accountId ?? "",
          activeGroup?.activeGroup?.name ?? "",
          activeGroup?.activeGroup?.id ?? "",
          program?.organization?.nickname ||
            program?.organization?.legalName ||
            ""
        );
      },
      className: "mt-2 lg:hidden",
    },
    {
      icon: "switch-horizontal-solid",
      label: "Transfer",
      onClick: transferToggle,
      className: "mt-4",
    },
    {
      icon: "cash-solid",
      label: "Send Money",
      onClick: sendMoneyToggle,
      className: "mt-4",
    },
  ];

  useEffect(() => {
    if (accountData && accountData.spendGroupBankAccounts) {
      if (activeGroup.isArchived) {
        setUnitBalance(activeGroup.activeGroup?.unitAmount ?? 0);
      }
      setAccountStatus(accountData.spendGroupBankAccounts.status);
      setLinkedAccount(
        accountData.spendGroupBankAccounts.externalAccounts.at(0)
      );
      setHasAccount(accountData.spendGroupBankAccounts.externalAccounts.length);
    }

    if (canUpdateBanking && !activeGroup.isArchived) {
      setBankingActions(actions);
    } else if (canUpdateBanking && (unitBalance > 0 || unitBalance < 0)) {
      setBankingActions([
        {
          icon:
            unitBalance < 0 ? "settlements-solid" : "switch-horizontal-solid",
          label: unitBalance < 0 ? "Settle Charge" : "Transfer",
          onClick: () => {
            if (unitBalance < 0) {
              setSettleCharge(true);
            }
            transferToggle();
          },
          className: "mt-4",
        },
      ]);
    }

    // eslint-disable-next-line
  }, [accountLoading, accountData, activeGroup]);

  const [plaidConfig, setPlaidConfig] = useState<PlaidLinkOptions>({
    onSuccess: (public_token, metadata) => {},
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: "",
  });
  const { open, ready } = usePlaidLink(plaidConfig);
  const openPlaid = () => {
    if (ready) {
      open();
    }
  };

  return (
    <>
      {activeGroup.activeGroup?.sharedAccount === false && (
        <div className="wrapper">
          {isToastOpen && (
            <ToastMessage
              title={toast.title}
              message={toast.message}
              isToastOpen={isToastOpen}
              toggleToast={toast.toggleToast}
              type={toast.type}
            />
          )}
          <MigrationAlert />
          <AccountOverviewCard
            bankingActions={bankingActions}
            hasLinkedAccount={hasLinkedAccount}
            openPlaid={openPlaid}
            setPlaidConfig={setPlaidConfig}
            toast={toast}
            accountType="group"
            linkedAccount={linkedAccount}
            canUpdateBanking={canUpdateBanking}
            canAddAccount={canAddAccount}
            isArchived={activeGroup.isArchived!}
            accountStatus={accountStatus}
            isExternalTransferEnabled={canAddAccount}
          />
          <div className="card">
            <BadgedTabs
              tabs={tabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <TransactionsContainer
              isCheckTab={selectedTab === 1}
              guardedTransactionMenuItems={guardedTransactionMenuItems}
              canUpdateBudgetItem={canUpdateBudget}
              groupIdOrgId={activeGroup.activeGroup?.id ?? ""}
              groupIsArchived={activeGroup.activeGroup.isArchived ?? true}
              type="Group"
            />
            {activeGroup.activeGroup?.legacyAccountId != null && (
              <div>
                <div>
                  <SnapLabeledToggle
                    checked={legacyTransactionsOpen}
                    label="Legacy Transactions"
                    onClick={() =>
                      setLegacyTransactionsOpen(!legacyTransactionsOpen)
                    }
                  />
                </div>
                {legacyTransactionsOpen && (
                  <div>
                    <LegacyTransactions
                      group={activeGroup.activeGroup}
                      menuItems={guardedTransactionMenuItems}
                      canUpdateBudgetItem={canUpdateBudget}
                      legacyAccountId={activeGroup.activeGroup.legacyAccountId}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {canUpdateBanking && bankingActions.length > 0 && (
            <FloatingActionBtn
              onClick={() => setIsBankOptionsOpen(true)}
              icon={"dots-horizontal-solid"}
            />
          )}
          {isBankOptionsOpen && (
            <SnapActionSheet
              header="Banking Actions"
              onClick={() => setIsBankOptionsOpen(false)}
            >
              <Divider isVisibleOnMobile className="mt-0" />
              {bankingActions.map((actionItem, idx) => {
                return (
                  <div className="mx-6" key={idx}>
                    <SnapButton
                      variant="secondary"
                      fullWidth
                      className={`${actionItem.className} mx-6`}
                      onClick={actionItem.onClick}
                    >
                      {actionItem.label}
                    </SnapButton>
                  </div>
                );
              })}
            </SnapActionSheet>
          )}
          <Transfer
            isTransferOpen={transferOpen}
            transferToggle={transferToggle}
            toast={toast}
            type={"group"}
            hasLinkedAccount={hasLinkedAccount}
            openPlaid={openPlaid}
            linkedAccount={linkedAccount}
            isArchived={activeGroup.isArchived!}
            settleCharge={settleCharge}
            unitAmount={unitBalance}
          />
          <SendMoney
            isSendMoneyOpen={isSendMoneyOpen}
            sendMoneyToggle={sendMoneyToggle}
            type={"Group"}
          />
        </div>
      )}
    </>
  );
}

export default TeamBanking;
