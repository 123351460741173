import { SpendMemo } from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import Divider from "shared-components/divider";

type CardsProps = {
  memos: SpendMemo[];
  setSelectedCreditMemo: React.Dispatch<React.SetStateAction<SpendMemo>>;
  handleDelete: (memo: SpendMemo) => void;
};

function Cards({ memos, setSelectedCreditMemo, handleDelete }: CardsProps) {
  return (
    <>
      {memos.map((memo) => {
        return (
          <div className="border rounded-lg p-4" key={memo.id}>
            <p>{`${FormatMoney(memo.creditApplied)} credit applied`}</p>
            <p>{memo.note}</p>
            <Divider isVisibleOnMobile />
            <div className="flex justify-between mt-2">
              <p className=" text-xs text-gray-600 font-semibold self-center">
                Date
              </p>
              <p>
                {FormatDate(memo.dateToApply, DateFormatSupported.NumbersLight)}
              </p>
            </div>
            <div className="flex justify-between mt-2">
              <p className=" text-xs text-gray-600 font-semibold self-center">
                Amount
              </p>
              <p>{FormatMoney(memo.creditApplied)}</p>
            </div>
            <Divider isVisibleOnMobile />
            <div className="flex justify-evenly mt-2">
              <p
                className="text-blue-600 text-base font-bold cursor-pointer"
                onClick={() => setSelectedCreditMemo(memo)}
              >
                Edit
              </p>
              <p
                className="text-blue-600 text-base font-bold cursor-pointer"
                onClick={() => handleDelete(memo)}
              >
                Delete
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default Cards;
