import { V2 as api } from '@snap-mobile/payments-widget-client';
import { useCallback } from 'react';
import {
  CreatePaymentIntentData,
  PaymentMethod,
  User,
} from '@snap-mobile/payments-widget-utils';
import { useStripe } from '@stripe/react-stripe-js';
import { usePaymentsWidgetContext } from '../context';
import { PaymentIntent } from '@stripe/stripe-js';

export const usePaymentsConfirm = (paymentMethod: PaymentMethod) => {
  const {
    setError,
    setProcessing,
    paymentData,
    stripeEnvironment,
    paymentConfiguration,
    customer: _customer,
    invalidated,
  } = usePaymentsWidgetContext();
  const stripe = useStripe();

  const handleError = (...args: any) => {
    setError(args);
  };

  const {
    // payment info related
    description,
    externalPaymentId,
    idempotencyKey,
    metadata,
    snapAmount,
    // totalAmount,
    statementDescriptorSuffix,

    // payment method related
    permittedPaymentMethods,
    destination,
    finalDestination,

    // payment setup related
    automatic,
    returnUrl,
    refresh,
  } = paymentData;

  const confirmPayment = useCallback(
    async (customer?: User) => {
      if (invalidated) {
        console.warn('data props is invalid');
        return null;
      }
      if (!stripe) {
        console.error('stripe is not assigned');
        return null;
      }
      if (!paymentMethod.id) {
        console.error('paymentMethodId is not assigned');
        return null;
      }
      const cus = customer || _customer;
      if (!cus) {
        console.error('customer is not assigned');
        return;
      }
      setProcessing(true);
      try {
        const data: CreatePaymentIntentData = {
          automaticPaymentMethods: { enabled: automatic ?? true },
          customer: cus!.customerId,
          description,
          destination: paymentConfiguration?.stripeAccountId ?? destination,
          externalPaymentId,
          finalDestination,
          idempotencyKey: idempotencyKey || '',
          paymentMethod: paymentMethod.id,
          metadata: metadata || {},
          permittedPaymentMethods,
          statementDescriptorSuffix: statementDescriptorSuffix || 'SnapRaise',
          setupFutureUsage: 'on_session',
          snapAmount: snapAmount,
          totalAmount: paymentData.totalAmount,
        };

        const pi = await api.createPaymentIntent(data, { stripeEnvironment });
        if (pi.message) {
          setError(pi.message);
          //   handleError(pi.message);
          setProcessing(false);
          return;
        }
        const redirect = refresh === false ? 'if_required' : undefined;
        const confirmData = {
          clientSecret: pi.client_secret,
          confirmParams: { return_url: returnUrl ?? window.location.href },
          redirect: redirect as any,
        };
        const { error, paymentIntent } = await stripe.confirmPayment(
          confirmData
        );
        if (error) {
          setError(error?.message || 'Error confirming payment.');
          return;
        }
        setProcessing(false);

        return paymentIntent;
      } catch (e: any) {
        setProcessing(false);
        handleError(e);
      }
    },
    [_customer, stripe, paymentData, paymentMethod.id]
  );

  return {
    confirmPayment,
  };
};
