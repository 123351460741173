import { SpendMemo } from "graphql/generated";
import { getDatePickerValue, setDatePickerValue } from "helpers/date-picker";
import { FormatMoney } from "helpers/format-money";
import React, { useEffect, useState } from "react";
import DatePicker from "shared-components/date-picker";
import InputMask from "shared-components/input-mask";
import { SnapInput } from "suit";
import { editMemoErrors } from "types/errors";

type EditProps = {
  selectedCreditMemo: SpendMemo;
  setSelectedCreditMemo: React.Dispatch<React.SetStateAction<SpendMemo>>;
  hasErrors: editMemoErrors;
  setHasErrrors: React.Dispatch<React.SetStateAction<editMemoErrors>>;
};

function Edit({
  selectedCreditMemo,
  setSelectedCreditMemo,
  hasErrors,
  setHasErrrors,
}: EditProps) {
  const [amount, setAmount] = useState(
    FormatMoney(selectedCreditMemo.creditApplied).replace("$", "")
  );

  useEffect(() => {
    setSelectedCreditMemo({
      ...selectedCreditMemo,
      creditApplied: Number(amount) * 100,
    });
    // eslint-disable-next-line
  }, [amount]);

  return (
    <>
      <div className="lg:grid grid-cols-2 gap-4">
        <InputMask
          amount={amount}
          setAmount={setAmount}
          hasError={hasErrors.amountError}
          onChange={() => {
            setHasErrrors({ ...hasErrors, amountError: false });
          }}
        />
        <div className="mt-4 lg:mt-0">
          <DatePicker
            label={"Date"}
            date={getDatePickerValue(selectedCreditMemo.dateToApply)}
            setDate={(e) => {
              const newDate = setDatePickerValue(e);
              setSelectedCreditMemo({
                ...selectedCreditMemo,
                dateToApply: newDate,
              });
              setHasErrrors({ ...hasErrors, dateError: false });
            }}
            hasError={hasErrors.dateError}
          />
        </div>
      </div>
      <div className="mt-4">
        <SnapInput
          _id={"Credit memo note input"}
          textarea
          label="Note"
          value={selectedCreditMemo.note ?? ""}
          helpText="This note will be visible to the participant"
          onSnap-input-change={(e) => {
            setSelectedCreditMemo({
              ...selectedCreditMemo,
              note: selectedCreditMemo.note,
            });
          }}
          onBlur={(e) => {
            setSelectedCreditMemo({
              ...selectedCreditMemo,
              note: e.target.value,
            });
          }}
        />
      </div>
    </>
  );
}

export default Edit;
