import LabelValue from "pages/groups/collections/label-value";
import Divider from "shared-components/divider";
import { SpendInvite } from "graphql/generated";
import { useEffect, useState } from "react";
import { SnapIcon, SnapPieChart } from "suit";
import { PieChartItem } from "@snap-mobile/snap-ui/dist/types/utils";
import Spinner from "shared-components/spinner";

type TeamAssistantInvitesProps = {
  invites: SpendInvite[];
};

function TeamAssistantInvites({ invites }: TeamAssistantInvitesProps) {
  const [sent, setSent] = useState(0);
  const [active, setActive] = useState(0);
  const [unaccepted, setUnaccepted] = useState(0);
  const [undelivered, setUndeliverable] = useState(0);
  const [pieChartOptions, setPieChartOptions] = useState<PieChartItem[]>([]);
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (sent && active) {
      setPercentage(Math.trunc((active / sent) * 100));
    }
  }, [active, sent]);

  useEffect(() => {
    let activeInvites = invites.filter(
      (invite) => invite.status === "accepted" && invite.isDeliverable
    ).length;
    let unacceptedInvites = invites.filter(
      (invite) => invite.status !== "accepted" && invite.isDeliverable
    ).length;
    let undeliveredInvites = invites.filter(
      (invite) => !invite.isDeliverable
    ).length;
    let sent = activeInvites + unacceptedInvites + undeliveredInvites;
    setActive(activeInvites);
    setUnaccepted(unacceptedInvites);
    setUndeliverable(undeliveredInvites);
    setSent(sent);

    setPieChartOptions([
      {
        category: "Active",
        value: activeInvites,
        color: "#F87171",
      },
      {
        category: "Unaccepted",
        value: unacceptedInvites,
        color: "#FBBF24",
      },
      {
        category: "Undelivered",
        value: undeliveredInvites,
        color: "#34D399",
      },
    ]);
  }, [invites]);

  return (
    <div className="card">
      <p className="text-lg font-semibold text-gray-500 lg:text-gray-800">
        Group Assistant Invites
      </p>

      <div className="grid grid-cols-2">
        <div>
          <div className="w-[70%]">
            <div className="mt-4 front-medium lg:mt-6">
              <p className="text-5xl font-normal">{`${percentage}%`}</p>
              <p className="text-lg text-gray-500">Accepted</p>
            </div>
            <LabelValue
              label={"Invitation Sent"}
              value={sent.toString()}
              labelColor={"text-gray-500"}
              valueColor={""}
              className="mt-5"
            />
            <LabelValue
              label={"Active"}
              value={active.toString()}
              labelColor={"text-gray-500"}
              valueColor={""}
              className="mt-2"
            />
            <LabelValue
              label={"Unaccepted"}
              value={unaccepted.toString()}
              labelColor={"text-gray-500"}
              valueColor={""}
              className="mt-2"
            />
            <LabelValue
              label={"Undelivered"}
              value={undelivered.toString()}
              labelColor={"text-gray-500"}
              valueColor={""}
              className="mt-2"
            />
          </div>
        </div>
        <div className="hidden lg:inline-block self-center mt-2">
          {pieChartOptions.length === 0 && <Spinner />}
          <SnapPieChart
            height={200}
            innerRadius={60}
            options={pieChartOptions}
          />
        </div>
        <div className="lg:hidden self-center mt-2">
          {pieChartOptions.length === 0 && <Spinner />}
          <SnapPieChart
            height={60}
            innerRadius={50}
            options={pieChartOptions}
          />
        </div>
      </div>
      <Divider isVisibleOnMobile />
      {unaccepted !== 0 && (
        <div className="flex mt-4 lg:mt-3">
          <SnapIcon
            icon="exclamation-solid"
            color="#EF4444"
            size="sm"
            className="mr-3"
          />
          <p className="text-sm font-medium text-gray-500 cursor-pointer">
            You have{" "}
            <span>
              {unaccepted.toString()} unaccepted group assistants invites
            </span>
          </p>
        </div>
      )}
      {undelivered !== 0 && (
        <div className="flex mt-2">
          <SnapIcon
            icon="exclamation-solid"
            color="#EF4444"
            size="sm"
            className="mr-3"
          />
          <p className="text-sm font-medium text-gray-500 cursor-pointer">
            You have{" "}
            <span>
              {undelivered.toString()} undelivered group assistants invites
            </span>
          </p>
        </div>
      )}
    </div>
  );
}

export default TeamAssistantInvites;
