import { SpendMemo } from "graphql/generated";
import { FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { SnapTable } from "suit";

type TableProps = {
  memos: SpendMemo[];
  setSelectedCreditMemo: React.Dispatch<React.SetStateAction<SpendMemo>>;
  handleDelete: (memo: SpendMemo) => void;
};

function Table({ memos, setSelectedCreditMemo, handleDelete }: TableProps) {
  return (
    <SnapTable>
      <table>
        <thead>
          <tr>
            <th>Memo</th>
            <th>Date</th>
            <th>Amount</th>
            <th>{/* EDIT */}</th>
            <th>{/* DELETE */}</th>
          </tr>
        </thead>
        <tbody>
          {memos.map((memo) => {
            return (
              <tr key={memo.id}>
                <td>
                  <p>{`${FormatMoney(memo.creditApplied)} credit applied`}</p>
                  <p>{memo.note}</p>
                </td>
                <td>{FormatDate(memo.dateToApply)}</td>
                <td>{FormatMoney(memo.creditApplied)}</td>
                <td>
                  <p
                    className="text-blue-600 font-bold text-sm cursor-pointer"
                    onClick={() => {
                      setSelectedCreditMemo(memo);
                    }}
                  >
                    Edit
                  </p>
                </td>
                <td>
                  <p
                    className="text-blue-600 font-bold text-sm py-4 cursor-pointer"
                    onClick={() => handleDelete(memo)}
                  >
                    Delete
                  </p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </SnapTable>
  );
}

export default Table;
