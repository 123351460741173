import FixedModal from "shared-components/fixed-modal/fixed-modal";
import { BtnState, BtnType } from "shared-components/modal";
import { InvitePlus } from "types/invite";

type RemoveUserModalProps = {
  isOpen: boolean;
  toggle: () => void;
  data: InvitePlus;
  handleRemove: (id: string) => void;
  isBtnActive: boolean;
  setIsBtnActive: React.Dispatch<React.SetStateAction<boolean>>;
};

function RemoveUserModal({
  isOpen,
  toggle,
  data,
  handleRemove,
  isBtnActive,
  setIsBtnActive,
}: RemoveUserModalProps) {
  const btn1: BtnType = {
    text: "Remove Permissions",
    btnStyle: "danger",
    onClick: () => {
      setIsBtnActive(false);
      handleRemove(data.id || "");
    },
    btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
  };
  const btn2: BtnType = {
    text: "Cancel",
    btnStyle: "tertiary",
    onClick: () => {
      setIsBtnActive(true);
      toggle();
    },
  };

  return (
    <FixedModal
      isOpen={isOpen}
      toggle={() => {
        setIsBtnActive(true);
        toggle();
      }}
      title={"Are you sure you want to remove user permissions?"}
      icon="exclamation-solid"
      iconColor="#FEE2E2"
      btn1={btn1}
      btn2={btn2}
    >
      <div>
        <p className="text-sm text-gray-500 text-center">
          This will revoke user permissions for this program. The user's account
          will not be deleted.
        </p>
      </div>
    </FixedModal>
  );
}

export default RemoveUserModal;
