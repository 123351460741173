import { SnapIcon } from "suit";
import debounce from "lodash.debounce";
import { DEBOUNCE_TIME } from "../constants";
import { useEffect, useMemo } from "react";

type SearchInputProps = {
  setSearchValue: (value: string) => void;
  debounceTime?: number;
};

export const SearchInput = ({
  setSearchValue,
  debounceTime,
}: SearchInputProps) => {
  const debouncedEventHandler = useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
      }, debounceTime || DEBOUNCE_TIME),
    [debounceTime, setSearchValue]
  );

  useEffect(() => {
    return () => {
      debouncedEventHandler.cancel();
    };
  }, [debouncedEventHandler]);

  return (
    <div className="flex border-2 border-gray-200 w-full rounded-xl py-1 px-2 gap-2">
      <SnapIcon icon="search-solid" size="sm" color="#475569" />
      <input
        type="text"
        placeholder="Search"
        onChange={debouncedEventHandler}
        className="w-full outline-none"
      />
    </div>
  );
};
