import FixedModal from "./fixed-modal";
import { InvitePlus } from "types/invite";

type EnableApproverProps = {
  isEnableApproverOpen: boolean;
  enableApproverToggle: () => void;
  data: InvitePlus;
  handleApproverSettingUpdate: (
    userId: string,
    type: string,
    isEnabled: boolean,
    groupId?: string
  ) => void;
};

function EnableApprover({
  isEnableApproverOpen,
  enableApproverToggle,
  data,
  handleApproverSettingUpdate,
}: EnableApproverProps) {
  return (
    <FixedModal
      isOpen={isEnableApproverOpen}
      toggle={enableApproverToggle}
      title={"Enable Approver"}
      icon="exclamation-solid"
      iconColor="#D1FAE5"
      btn1={{
        text: "Submit",
        onClick: () => {
          handleApproverSettingUpdate(
            data.userId!,
            data.type!,
            true,
            data.groupId ?? undefined
          );
        },
      }}
      btn2={{
        text: "Cancel",
        onClick: enableApproverToggle,
      }}
    >
      <div className="modal-card flex text-center">
        <p>
          Are you sure you want to enable {data.firstName} {data.lastName} to be
          an approver?
        </p>
      </div>
    </FixedModal>
  );
}

export default EnableApprover;
