import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import DisplayContext from "context/display-context";
import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import SeasonContext from "context/season-context";
import UserContext from "context/user-context";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TabNavLink from "shared-components/tab-nav-link";
import { SnapButton, SnapDropDown } from "suit";
import { NavTabs } from "types/nav-tabs";
import { SpendPermissions } from "types/roles-permissions";

export function DisplayPageHeader({
  title,
  tabs,
  visibleTabs,
  hasAutoPay,
  hasPayNow,
  changePaymentToggle,
  addGroupToggle,
  authorizeAutoPayToggle,
}: {
  title: string;
  tabs: NavTabs[];
  visibleTabs: NavTabs[];
  hasAutoPay: boolean;
  hasPayNow: boolean;
  changePaymentToggle: () => void;
  addGroupToggle: () => void;
  authorizeAutoPayToggle: () => void;
}) {
  const location = useLocation();
  const activeUser = useContext(UserContext);
  const program = useContext(ProgramContext);
  const group = useContext(GroupContext);
  const season = useContext(SeasonContext);
  const display = useContext(DisplayContext);
  const canUpdateGroup = activeUser?.checkSpendPermission(
    SpendPermissions.programGroupsUpdate
  );
  const [buttonState, setButtonState] = useState<
    "none" | "group-add" | "parent-actions"
  >("none");
  useEffect(() => {
    if (canUpdateGroup && location.pathname === "/groups/active") {
      setButtonState("group-add");
    } else if (activeUser?.isParent()) {
      setButtonState("parent-actions");
    } else {
      setButtonState("none");
    }
  }, [activeUser, canUpdateGroup, location.pathname]);

  const SpendLayout = () => {
    if (buttonState === "parent-actions") {
      return <GuardianLayout />;
    }
    if (location.pathname === "/dashboard") {
      return <Dashboard />;
    }
    if (location.pathname.includes("/participant-details")) {
      return (
        <h1 className="text-2xl font-semibold pb-[17px]">
          {group?.participant || "Participant Details"}
        </h1>
      );
    }
    if (group?.activeGroup && season?.multipleSeasons()) {
      return <GroupWithSeason />;
    }
    return <GroupOrGeneric />;
  };
  const GuardianLayout = () => {
    let titleValue: string | undefined;
    switch (location.pathname) {
      case "/dashboard":
        titleValue = "Dashboard";
        break;
      case "/invoices":
        titleValue = "Invoices";
        break;
      case "/payment-methods":
        titleValue = "Payment Methods";
        break;
      default:
        titleValue = "";
    }
    return (
      <div className="flex flex-col-reverse lg:flex-row justify-between lg:w-[50%]">
        <p className="text-2xl font-semibold pb-4">{titleValue}</p>
        <p className="text-md font-semibold my-auto lg:text-lg lg:pb-[17px]">
          {program?.organization?.nickname ?? program?.organization?.legalName}
        </p>
      </div>
    );
  };
  const Dashboard = () => {
    return (
      <div className="flex pb-4">
        {
          !!program?.organization?.logo && (
            <img
              src={program?.organization?.logo ?? ""}
              alt="Organization Logo"
              className="h-10 w-10"
            />
          )
        }
        <div className="flex justify-center self-center ml-2">
          <SnapDropDown
            button-text={display?.selectedDropdownOption?.name}
            title=""
            description=""
            left-hang
            size="lg"
            indicator=""
            leading-label=""
            avatar-img=""
            switch-profile-link=""
            search-field="true"
            modal-type="fullscreen"
            max-height=""
            search-method="start"
            options={display?.dropdownMenuOptions}
            onSnap-dropdown-updated={(e) => {
              const selectedItem = e.detail.find(
                (item: any) => item.selected
              ) as DropdownMenuItem;
              if (selectedItem) {
                display?.setSelectedDropdownOption(selectedItem);
                display?.setDropdownMenuOptions(e.detail as DropdownMenuItem[]);
                if (selectedItem?.section === "Group") {
                  let groupFound = group?.groups?.find(
                    (g) => g.id === selectedItem?.value
                  );
                  group?.setAndStoreActiveGroup(groupFound);
                } else {
                  group?.setAndStoreActiveGroup(undefined);
                }
              }
            }}
          />
        </div>
      </div>
    );
  };
  const GroupWithSeason = () => {
    if (season == null) return null;
    return (
      <div
        className={`flex flex-col-reverse lg:flex-row justify-between lg:w-[50%]`}
      >
        <div className="flex flex-col">
          <p className="text-sm font-semibold">{group?.activeGroup?.name}</p>
          <SnapDropDown
            button-text={season.selectedSeason?.name}
            title=""
            description=""
            left-hang
            size="lg"
            indicator=""
            leading-label=""
            avatar-img=""
            switch-profile-link=""
            modal-type="fullscreen"
            max-height=""
            search-method="start"
            options={season.seasonOptions}
            onSnap-dropdown-updated={(e) => {
              const foundSeason = e.detail?.find(
                (season: any) => season.selected
              ) as DropdownMenuItem;
              season.handleSelectedSeason({ seasonId: foundSeason.value });
            }}
          />
        </div>
        <p className="text-md font-semibold my-auto lg:text-lg lg:pb-[17px]">
          {program?.organization?.nickname ?? program?.organization?.legalName}
        </p>
      </div>
    );
  };
  const GroupOrGeneric = () => {
    return (
      <div className="flex flex-col-reverse lg:flex-row justify-between lg:w-[50%]">
        <p
          className={`text-2xl font-semibold pb-[17px] ${
            group?.isArchived && group.activeGroup && "text-gray-500"
          }`}
        >
          {group?.activeGroup?.name || title}
        </p>
        <p className="text-md font-semibold my-auto lg:text-lg lg:pb-[17px]">
          {program?.organization?.nickname ?? program?.organization?.legalName}
        </p>
      </div>
    );
  };
  const GuardianButtons = () => {
    return (
      <>
        {activeUser?.isParent() && (
          <div className="hidden lg:flex gap-2 mr-6">
            {hasPayNow && (
              <SnapButton
                variant="primary"
                size="md"
                onClick={authorizeAutoPayToggle}
              >
                Authorize AutoPay
              </SnapButton>
            )}
            {hasAutoPay && (
              <SnapButton
                variant="primary"
                size="md"
                onClick={changePaymentToggle}
                role={"button"}
              >
                Change Payment Method
              </SnapButton>
            )}
            {group?.activeGroup && (
              <SnapButton
                variant="primary"
                size="md"
                onClick={() =>
                  display?.setMakePaymentDataModalOpen({ isOpen: true })
                }
                role={"button"}
              >
                Make Payment
              </SnapButton>
            )}
          </div>
        )}
      </>
    );
  };
  return (
    <div className="pl-6 pt-6 shadow-md bg-white">
      <div className="flex flex-row justify-between">
        <SpendLayout />
        <GuardianButtons />
        {buttonState === "group-add" && (
          <SnapButton
            className="mr-6 hidden lg:inline"
            variant="primary"
            onClick={addGroupToggle}
            role={"button"}
          >
            + Add group
          </SnapButton>
        )}
      </div>
      {location.pathname.includes("/people/participants/participant-details")
        ? null
        : tabs.length !== 1 && (
            <TabNavLink
              links={visibleTabs}
              currentLocation={location.pathname.split("/")[2]}
              selectedItem={group?.activeGroup ?? null}
            />
          )}
    </div>
  );
}
