import { useEffect, useState } from "react";
import { SnapIcon, SnapInput } from "suit";
import {
  SpendBankTransactionDetail,
  useSpendUserAchPaymentCancelMutation,
} from "../../../graphql/generated";
import { useContextStrict } from "../../../helpers/context-strict";
import ToastContext from "../../../context/toast-context";
import ScrollableModal, {
  BtnState,
  BtnType,
} from "../../../shared-components/modal/scrollable-modal";

type ParentCancelAchModalProps = {
  isOpen: boolean;
  toggle: () => void;
  transaction: SpendBankTransactionDetail;
  transactionTitle: string;
  onSuccess?: () => void;
};

function ParentCancelAchModal({
  isOpen,
  toggle,
  transaction,
  transactionTitle,
  onSuccess,
}: ParentCancelAchModalProps) {
  const [validateMe, setValidateMe] = useState("");
  const [hasError, setHasError] = useState(false);
  const { setToast } = useContextStrict(ToastContext);

  const [doCancelAch, { loading, called }] =
    useSpendUserAchPaymentCancelMutation({});
  const cancelAch = () => {
    let achPaymentId = transaction.metadata?.externalId || "";
    if (achPaymentId.startsWith("achPayment_")) {
      achPaymentId = achPaymentId.replace("achPayment_", "");
    }
    const externalId = transaction.metadata?.tags?.spendExternalId;
    if (!externalId) {
      setHasError(true);
      setToast({
        message: "The input is invalid.",
        type: "warning",
      });
      return;
    }

    doCancelAch({
      variables: {
        input: {
          id: achPaymentId,
          externalId: externalId,
        },
      },
      onCompleted: async ({ spendUserAchPaymentCancel }) => {
        if (spendUserAchPaymentCancel?.id) {
          setToast({ message: "Succeed cancel ACH payment", type: "success" });
          onSuccess && onSuccess();
          toggle();
        }
      },
      onError: (error) => {
        setHasError(true);
        setToast({
          message: error.message,
          type: "danger",
        });
        toggle();
      },
    });
  };

  const formSubmit = () => {
    if (validateMe === "Cancel") {
      setValidateMe("");
      setHasError(false);
      cancelAch();
    } else {
      setHasError(true);
    }
  };

  let btn1: BtnType = {
    text: "Cancel Payment",
    onClick: formSubmit,
    btnStyle: "danger",
  };
  let btn2: BtnType = { text: "Cancel", onClick: toggle };

  useEffect(() => {
    if (isOpen) {
      setValidateMe("");
      setHasError(false);
    }
  }, [isOpen]);

  return (
    <ScrollableModal
      isOpen={isOpen}
      toggle={toggle}
      title={"Cancel ACH Payment"}
      header={`For Payment ${transactionTitle}`}
      btn1={{
        ...btn1,
        btnState:
          loading || called || validateMe !== "Cancel"
            ? BtnState.DISABLED
            : undefined,
      }}
      btn2={btn2}
    >
      <div className="modal-card">
        <div className="flex bg-red-100 py-4 pr-4 pl-4 rounded-lg">
          <SnapIcon
            icon="exclamation-solid"
            color="red"
            className="self-start pr-2"
          ></SnapIcon>
          <div>
            <p className="text-sm font-semibold">
              Cancel ACH Payment will marked the invoice as unpaid, and autopay
              for corresponding invoice will be disabled.
            </p>
            <p className="text-sm mt-2">
              Type "<b>Cancel</b>" and then click "Cancel Payment" to cancel ACH
              payment.
            </p>
            <div className="lg:w-[65%]">
              <SnapInput
                _id={"cancel-ach-input"}
                onInput={(e) =>
                  setValidateMe((e.target as HTMLSnapInputElement)?.value)
                }
                error={hasError}
              ></SnapInput>
            </div>
          </div>
        </div>
      </div>
    </ScrollableModal>
  );
}

export default ParentCancelAchModal;
