import { useMutation } from "@apollo/client";
import { UNIT_APPLICATION } from "graphql/mutations/session";
import { useEffect } from "react";

function ApplicationForm() {
  const [applicationFormGetCreate, { loading, data }] =
    useMutation(UNIT_APPLICATION);

  useEffect(() => {
    applicationFormGetCreate();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loading && data) {
      const timeout = setTimeout(() => {
        window.location.replace(data.spendUnitApplication.url);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [loading, data]);

  return null;
}

export default ApplicationForm;
