import { SnapLoader } from "./snap-loader";

export const LoadingBackdrop = ({ loading }: { loading: boolean }) => {
  return loading ? (
    <>
      <div className="min-h-[100px]"></div>
      <div className="absolute inset-0 bg-white-400/40 rounded">
        <div className="relative top-1/2 -translate-y-1/2">
          <SnapLoader />
        </div>
      </div>
    </>
  ) : null;
};
