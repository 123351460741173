import DisplayContext from "context/display-context";
import GroupContext from "context/group-context";
import SeasonContext from "context/season-context";
import { useContext, useEffect, useState } from "react";
import Divider from "shared-components/divider";
import { SnapAlert, SnapAlertTitle } from "suit";
import { SpendPaymentScheduleWithFees } from "../../participants-roster";
import PaymentScheduleCard from "./card";
import PaymentScheduleTable from "./table";

type ViewPaymentScheduleProps = {
  invoices: SpendPaymentScheduleWithFees[];
  paymentScheduleStatus: string;
};

function ViewPaymentSchedule({
  invoices,
  paymentScheduleStatus,
}: ViewPaymentScheduleProps) {
  const Group = useContext(GroupContext);
  const Season = useContext(SeasonContext);
  const Display = useContext(DisplayContext);
  const [showPSS, setShowPSS] = useState(false);
  useEffect(() => {
    setShowPSS(paymentScheduleStatus.toLowerCase() !== "published");
  }, [paymentScheduleStatus]);
  return (
    <div className="modal-card">
      {showPSS && (
        <SnapAlert type="warning" className="lg:inline mb-3">
          <SnapAlertTitle>
            Payment collection temporarily suspended until you finish editing
            and publish changes. Invoices will be modified only for participants
            invoices without payment or credits applied.
          </SnapAlertTitle>
        </SnapAlert>
      )}
      <p className={`text-lg font-semibold ${showPSS ? "mt-3" : ""}`}>
        Details
      </p>
      <table className="w-full mt-4">
        <tbody>
          <tr className="lg:table-row flex flex-col">
            <td className="mr-auto">
              <p className="mr-1 font-normal text-sm text-gray-500">Group</p>
            </td>
            <td className="flex lg:table-cell">{Group?.activeGroup?.name}</td>
          </tr>
          <tr className="lg:table-row flex flex-col">
            <td className="mr-auto">
              <p className="mr-1 font-normal text-sm text-gray-500">Season</p>
            </td>
            <td className="flex lg:table-cell">
              {Season?.selectedSeason?.name}
            </td>
          </tr>
        </tbody>
      </table>
      <SnapAlert
        type="info"
        right-link-text="Details"
        right-link-href=""
        className="mt-4"
      >
        <SnapAlertTitle>
          All participant added to the group will receive the invoices shown
          below. To make edits for an individual participant, go to that
          participant's details page.
        </SnapAlertTitle>
      </SnapAlert>
      <Divider isVisibleOnMobile />
      <p className="my-4 text-lg font-semibold">Payment Schedule Invoices</p>
      {Display?.isDesktop ? (
        <PaymentScheduleTable
          invoices={invoices}
          isEdit={false}
          categories={[]}
        />
      ) : (
        <PaymentScheduleCard
          invoices={invoices}
          isEdit={false}
          categories={[]}
        />
      )}
    </div>
  );
}

export default ViewPaymentSchedule;
