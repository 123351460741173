import { SpendGroupRoster, SpendInvoice } from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { useEffect, useState } from "react";
import Divider from "shared-components/divider";
import InputMask from "shared-components/input-mask";
import VerticalLabelValue from "shared-components/vertical-label-value";

type CardsProps = {
  roster: SpendGroupRoster[];
  handleAmountInput: (
    amount: string,
    idx: number,
    invoiceAmount: number
  ) => void;
  invoicePlusAmount: {
    amount: string;
    invoiceId: string;
    invoiceError: boolean;
  }[];
};

function Cards({ roster, handleAmountInput, invoicePlusAmount }: CardsProps) {
  return (
    <>
      {roster.map((r) => {
        return (
          r.invoices &&
          r.invoices
            .filter((i) => !i?.paid)
            .map((i, idx) => {
              return (
                <Card
                  key={i?.id}
                  idx={idx}
                  roster={r}
                  invoice={i as SpendInvoice}
                  handleAmountInput={handleAmountInput}
                  invoicePlusAmount={invoicePlusAmount[idx]}
                />
              );
            })
        );
      })}
    </>
  );
}

const Card = ({
  idx,
  roster,
  invoice,
  handleAmountInput,
  invoicePlusAmount,
}: {
  idx: number;
  invoice: SpendInvoice;
  roster: SpendGroupRoster;
  handleAmountInput: (
    amount: string,
    idx: number,
    invoiceAmount: number
  ) => void;
  invoicePlusAmount: {
    amount: string;
    invoiceId: string;
    invoiceError: boolean;
  };
}) => {
  const dividerClass = "mt-2 mb-2";
  const [amount, setAmount] = useState("");

  useEffect(() => {
    handleAmountInput(amount, idx, Number(invoice.balanceDue));
    // eslint-disable-next-line
  }, [amount]);
  return (
    <div className="border-2 p-4 mt-4 rounded-lg lg:hidden">
      <p className="text-base font-medium">{invoice.description}</p>
      <Divider isVisibleOnMobile className={dividerClass} />
      <VerticalLabelValue
        label={"Group"}
        value={roster.group?.name}
        customContainerStyle="flex flex-col"
      />
      <VerticalLabelValue
        label={"Due Date"}
        value={FormatDate(invoice?.dueDate ?? "", DateFormatSupported.Numbers)}
        customContainerStyle="flex flex-col"
      />
      <Divider isVisibleOnMobile className={dividerClass} />
      <VerticalLabelValue
        label={"Amount Due "}
        value={FormatMoney(Number(invoice.balanceDue))}
        customContainerStyle="mb-0"
      />
      <Divider isVisibleOnMobile className={dividerClass} />
      <InputMask
        label=""
        amount={amount}
        setAmount={setAmount}
        hasError={invoicePlusAmount.invoiceError}
        onChange={() => {}}
      />
    </div>
  );
};

export default Cards;
