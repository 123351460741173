import type { SpendRosterChecked } from "types/group-roster";
import { removeDupsById } from "./remove-dups-by-id";
import { InvitePlus } from "types/invite";

export const HandleAllCheckboxAction = (
  isChecked: boolean,
  array: (InvitePlus | SpendRosterChecked)[],
  setUserArray: (userArray: any[]) => void,
  setSelectedItems: (selectedItems: any[]) => void,
  selectedItems: (InvitePlus | SpendRosterChecked)[]
) => {
  if (!isChecked) {
    const tempUsersArray = array.map((user) => {
      return {
        ...user,
        isChecked: true,
      };
    });
    let all = [...selectedItems, ...tempUsersArray];
    let newSet = removeDupsById(all);
    setUserArray(tempUsersArray);
    setSelectedItems([...newSet]);
  } else {
    const tempUsersArray = array.map((user) => {
      return {
        ...user,
        isChecked: false,
      };
    });

    tempUsersArray.forEach((tempUserItems) => {
      selectedItems.forEach((item, idx) => {
        if (tempUserItems.id === item.id) {
          selectedItems.splice(idx, 1);
        }
      });
    });
    setUserArray(tempUsersArray);
    setSelectedItems([...selectedItems]);
  }
};

export const hasAllById = (
  s1: Array<{ id?: string | null }>,
  s2: Array<{ id?: string | null }>
): boolean => {
  const s = new Set(s1.map(({ id }) => id));
  return s2.every(({ id }) => s.has(id));
};
