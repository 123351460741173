import circleDisabled from "../assets/circle_disabled.svg";
import circleWithCheck from "../assets/circle_with_check.svg";
import circleWithDot from "../assets/circle_with_dot.svg";

type DotProgressProps = {
  state: number;
  markerCount?: number;
  className?: string;
};

// When using, if markerCount is not 3, className must adjust the w-[value] to accomodate the change in dots
function DotProgress({
  state,
  markerCount = 3,
  className = "",
}: DotProgressProps) {
  if (state >= markerCount) {
    console.warn(
      `State value '${state}' has attempted to render beyond the marker count of '${markerCount}' and has been reset to '${
        markerCount - 1
      }'`
    );
    state = markerCount - 1;
  } else if (state < 0) {
    console.warn(
      `State value '${state}' has attempted to render below the first marker and has been reset to 0`
    );
    state = 0;
  }
  const baseLineStyle =
    "relative h-full w-[77px] lg:w-[142px] border-t-2 top-[16px]";

  const current = (key?: string) => (
    <img key={key} src={circleWithDot} alt="" />
  );
  const check = (key?: string) => (
    <img key={key} src={circleWithCheck} alt="" />
  );
  const next = (key?: string) => <img key={key} src={circleDisabled} alt="" />;
  const gLine = (key?: string) => (
    <div key={key} className={`${baseLineStyle}`} />
  );
  const bLine = (key?: string) => (
    <div key={key} className={`${baseLineStyle} border-blue-600`} />
  );

  const generateLine = () => {
    const elements = [];
    for (let i = 0; i < markerCount; i++) {
      if (i < state) {
        elements.push(check(`dp-${elements.length}`));
        if (i + 1 !== state || state !== markerCount) {
          elements.push(bLine(`dp-${elements.length}`));
        }
      } else if (i === state && i < markerCount - 1) {
        elements.push(current(`dp-${elements.length}`));
        elements.push(gLine(`dp-${elements.length}`));
      } else if (i === state) {
        elements.push(current(`dp-${elements.length}`));
      } else if (i === markerCount - 1) {
        elements.push(next(`dp-${elements.length}`));
      } else {
        elements.push(next(`dp-${elements.length}`));
        elements.push(gLine(`dp-${elements.length}`));
      }
    }
    return elements;
  };
  return (
    <div className={`w-[220px] lg:w-[380px] mx-auto ${className}`}>
      <div className="text-center text-base mb-2">
        Step {state + 1} of {markerCount}
      </div>
      <div id="dot-progress2" className="relative mx-auto flex justify-center">
        <div className="flex">{generateLine()}</div>
      </div>
    </div>
  );
}

export default DotProgress;
