import { getDatePlusTime } from "./current-time";
import { FormatDate } from "./format-date";
import { isNullOrEmpty } from "./null-or-empty";

export const getDatePickerValue = (value: string) => {
  return isNullOrEmpty(value) ? undefined : new Date(value);
};

export const setDatePickerValue = (value: Date | undefined) => {
  return !value ? "" : getDatePlusTime(FormatDate(value));
};
