import { gql } from "@apollo/client";

export const GET_BUDGETS = gql`
  query SpendBudgets {
    spendBudgets {
      budgets {
        category {
          createdAt
          id
          isDefault
          isHidden
          name
          organizationId
          type
          updatedAt
        }
        createdAt
        description
        id
        invoices {
          amount
          balanceDue
          budgetItemId
          createdAt
          description
          dueDate
          groupRosterId
          id
          isOptional
          lastNotifyDate
          lastNotifyId
          notificationAttempts
          optedIn
          paid
          paidDate
          paymentScheduleInvoiceId
          updatedAt
        }
        isDefault
        season {
          id
          name
        }
        targetAmount
        targetDateAt
        updatedAt
        vaultId
      }
      count
    }
  }
`;
export const GET_GROUP_BUDGET_SUMMARY = gql`
  query GroupBudgetSummary(
    $groupId: String
    $filterBy: TransactionFilterEnum
    $filterValue: String
  ) {
    spendCategories(groupId: $groupId) {
      count
      categories {
        id
        name
        type
        budgets {
          targetAmount
          invoices {
            id
            amount
          }
        }
      }
    }
    spendTransactions(
      groupId: $groupId
      filterBy: $filterBy
      filterValue: $filterValue
    ) {
      count
      transactions {
        amount
        snapAmount
        metadata {
          source
          destination
        }
        reconciliation {
          id
        }
      }
    }
  }
`;

export const GET_SPEND_BUDGET_SUMMARY = gql`
  query SpendBudgetsSummary($groupId: String!, $seasonId: String!) {
    spendBudgetsSummary(groupId: $groupId, seasonId: $seasonId) {
      summaryByCategory {
        id
        name
        type
        budgets {
          id
          targetAmount
          targetDateAt
          description
          invoices {
            id
            amount
          }
          reconciledTransactions {
            amount
            budgetItemId
            id
            transactionId
            reconciledTransaction {
              amount
              id
              paymentId
              updatedAt
              createdAt
              type
            }
          }
          reconciledBudgetTotal
          reconciledInvoicesTotal
          reconciledTotal
        }
      }
      summaryUnreconciled {
        debits {
          count
          total
        }
        credits {
          count
          total
        }
      }
    }
  }
`;

export const GETorganization_BUDGET_SUMMARY = gql`
  query SpendBudgetsProgramSummary {
    spendBudgetsProgramSummary {
      summaryByCategory {
        id
        name
        type
        budgets {
          id
          targetAmount
          targetDateAt
          description
          invoices {
            id
            amount
          }
          reconciledBudgetTotal
          reconciledInvoicesTotal
        }
      }
      summaryUnreconciled {
        debits {
          count
          total
        }
        credits {
          count
          total
        }
      }
    }
  }
`;

export const EXPORT_BUDGET = gql`
  query SpendBudgetExport {
    spendBudgetExport {
      content
      fileName
    }
  }
`;

export const EMPTY = gql`
  query SpendBudgetsSummaryEmpty {
    spendBudgetsProgramSummary {
      summaryByCategory {
        id
      }
      summaryUnreconciled {
        credits {
          total
        }
        debits {
          total
        }
      }
    }
  }
`;

export const GET_RECONCILED_TRANSACTIONS = gql`
  query SpendBudget($spendBudgetId: String!, $spendGroupId: String) {
    spendBudget(id: $spendBudgetId, groupId: $spendGroupId) {
      budget {
        reconciledTransactions {
          reconciledTransaction {
            id
            amount
            createdAt
            transaction {
              totalReconciled
              type
              metadata {
                status
                description
                summary
              }
              effective
              history {
                date
                status
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_BUDGETS_SIMPLE = gql`
  query SpendBudgetsSummarySimple($groupId: String!, $seasonId: String!) {
    spendBudgetsSummary(groupId: $groupId, seasonId: $seasonId) {
      summaryByCategory {
        name
        budgets {
          description
          id
        }
        id
        type
      }
    }
  }
`;

export const GET_BUDGETS_ADD_INVOICE = gql`
  query SpendCategoriesAddInvoice(
    $groupId: String
    $seasonId: String
    $filterBy: CategoryFilterEnum
    $filterValue: String
  ) {
    spendCategories(
      groupId: $groupId
      seasonId: $seasonId
      filterBy: $filterBy
      filterValue: $filterValue
    ) {
      categories {
        budgets {
          id
          description
        }
      }
    }
  }
`;

export const GET_BUDGET_BY_ID = gql`
  query SpendBudgetById($spendBudgetId: String!) {
    spendBudget(id: $spendBudgetId) {
      budget {
        id
        description
        targetAmount
        category {
          name
          type
        }
      }
    }
  }
`;
