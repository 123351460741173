import { SnapDropdownCustomEvent } from "@snap-mobile/snap-ui/dist/types/components";
import {
  DropdownItemSelected,
  DropdownMenuItem,
} from "@snap-mobile/snap-ui/dist/types/utils";
import { useEffect, useState } from "react";
import { SnapCheckboxButton, SnapDropDown, SnapIcon, SnapLink } from "suit";
import {
  LabelValueObject,
  LabelValueObjectPlus,
  isLabelObjectPlus,
} from "types/label-value-object";
import HorizontalLabelValue, {
  HorizontalValueStyle,
} from "./horizontal-label-value";
import VerticalLabelValue, { VerticalValueStyle } from "./vertical-label-value";
import VerticalLabelValuePlus from "./vertical-label-value-plus";

//TODO Still needs to handle subtitle (notes?)

type DataCardProps = {
  title: string;
  titleAction?: () => void;
  titleExtra?: JSX.Element;
  titleHasBorder?: boolean;
  subTitle?: string;
  kvLeft: LabelValueObject[];
  kvLeft2?: LabelValueObject[];
  kvRight: LabelValueObject[] | LabelValueObjectPlus[];
  action?: number;
  actionClick?: () => void;
  menuItems?: DropdownMenuItem[];
  menuTitle?: string;
  menuClickListener?: (value: any) => void;
  className?: string;
  hasCheckbox?: boolean;
  isChecked?: boolean;
  checkboxAction?: (value: any) => void;
  footer?: LabelValueObject;
  isArchived?: boolean;
};

function DataCard({
  title,
  titleAction,
  titleExtra,
  titleHasBorder = true,
  subTitle,
  kvLeft,
  kvLeft2,
  kvRight,
  action,
  actionClick,
  menuItems,
  menuTitle,
  menuClickListener,
  className,
  hasCheckbox,
  isChecked,
  checkboxAction,
  footer,
  isArchived,
}: DataCardProps) {
  const titleStyles = `font-medium flex ${
    titleAction ? "text-blue-600 cursor-pointer" : ""
  }`;
  const [dropdownOptions, setDropdownOptions] = useState(menuItems);
  const menuClick = (ev: SnapDropdownCustomEvent<DropdownItemSelected>) => {
    menuClickListener && menuClickListener(ev.detail.value);
    menuItems && setDropdownOptions([...menuItems]);
  };
  useEffect(() => {
    setDropdownOptions(menuItems);
  }, [titleExtra, menuItems]);

  const c_className = `border shadow-sm rounded-xl p-4 mb-4 last:mb-0 break-words ${
    className || ""
  } ${isArchived ? "bg-gray-100 border-gray-200" : ""}`;
  return (
    <div className={c_className}>
      <div className="lg:flex">
        {hasCheckbox && (
          <SnapCheckboxButton
            className="lg:self-center pt-1 items-start"
            checked={isChecked}
            onClick={checkboxAction}
          />
        )}
      </div>
      <div className="flex flex-col gap-2 lg:flex-row justify-between w-full">
        {/* left side of card */}
        <div className="flex-col lg:w-1/2">
          <div
            className={`flex justify-between border-b border-gray-200 mb-2 lg:mb-0 lg:border-none ${
              !titleHasBorder && "border-none"
            } `}
          >
            <div className={`flex mb-3 lg:mb-1 ${isArchived && "opacity-50"}`}>
              <div className="flex flex-col">
                <div className={titleStyles} onClick={titleAction}>
                  {titleAction ? (
                    <SnapLink
                      className="text-sm text-blue-600 cursor-pointer"
                      sr-only="srOnly"
                      onClick={() => {}}
                    >
                      {title}
                    </SnapLink>
                  ) : (
                    <p
                      className="font-bold"
                      sr-only="srOnly"
                      onClick={() => {}}
                    >
                      {title}
                    </p>
                  )}
                  {titleExtra && (
                    <span className="min-w-fit">{titleExtra}</span>
                  )}
                </div>
                {subTitle && (
                  <div className="text-sm text-gray-500 mt-1">{subTitle}</div>
                )}
              </div>
              {/* {titleExtra && (
                <div className="hidden lg:block my-auto">{titleExtra}</div>
              )} */}
            </div>
            <div className="flex justify-end">
              {
                /* Mobile Buttons */
                {
                  1: dropdownOptions && dropdownOptions.length > 0 && (
                    <SnapDropDown
                      id="mobile-menu"
                      className="lg:hidden"
                      size="lg"
                      minimal
                      modal-type="drawer"
                      buttonText={menuTitle}
                      options={dropdownOptions}
                      onSnap-dropdown-item-selected={menuClick}
                    />
                  ),
                  2: (
                    <SnapIcon
                      icon="arrow-right-solid"
                      size="sm"
                      color="#2563EB"
                      className="lg:hidden self-start ml-2 cursor-pointer"
                      onClick={actionClick}
                    />
                  ),
                }[action || 0]
              }
            </div>
          </div>
          {
            /* iterate through list of label/value array */
            kvLeft &&
              kvLeft.map((kv: LabelValueObject, idx: number) => (
                <HorizontalLabelValue
                  key={kv.key + kv.value + idx}
                  label={kv.key}
                  labelAction={kv.labelAction}
                  value={kv.value}
                  secondValue={kv.secondValue}
                  valueStyle={kv.valueStyle as HorizontalValueStyle}
                  className={kv.className}
                  customContainerStyle={kv.customContainerStyle}
                />
              ))
          }

          {
            /* iterate through list of label/value array */
            kvLeft2 && (
              <div className="border-t border-gray-200 ml-10 mt-1 pt-1">
                {kvLeft2.map((kv: LabelValueObject, idx: number) => (
                  <HorizontalLabelValue
                    key={kv.key + kv.value + idx}
                    label={kv.key}
                    value={kv.value}
                    secondValue={kv.secondValue}
                    valueStyle={kv.valueStyle as HorizontalValueStyle}
                    className={kv.className}
                    customContainerStyle={kv.customContainerStyle}
                  />
                ))}
              </div>
            )
          }
        </div>
        {/* right side side of card */}
        <div
          className={`flex-row lg:flex lg:border-0 lg:pt-0 lg:justify-end lg:my-auto 
          ${kvRight.length && "mt-2 pt-2 border-t"}
          ${!kvLeft.length && "border-none mt-0"}`}
        >
          {/* list through right pane data */}
          {
            /* <VerticalLabelValue label="Amount Due" value={FormatMoney(25000)} hasWarning/> */
            kvRight &&
              kvRight.map(
                (kv: LabelValueObject | LabelValueObjectPlus, idx: number) => {
                  if (isLabelObjectPlus(kv)) {
                    return (
                      <VerticalLabelValuePlus
                        key={kv.label + title + idx}
                        label={kv.label}
                        valuePlus={kv.valuePlus}
                        customContainerStyle={kv.customContainerStyle}
                      />
                    );
                  } else {
                    return (
                      <VerticalLabelValue
                        key={kv.key + kv.value + idx}
                        label={kv.key}
                        value={kv.value}
                        secondValue={kv.secondValue}
                        valueStyle={kv.valueStyle as VerticalValueStyle}
                        valueColor={kv.valueColor}
                        hasWarning={kv.hasWarning}
                        customContainerStyle={kv.customContainerStyle}
                        className={kv.className}
                        labelAction={kv.labelAction}
                      />
                    );
                  }
                }
              )
          }
          {
            /* if actionable */
            {
              1: (
                <div className={dropdownOptions?.length === 0 ? "w-9" : ""}>
                  {dropdownOptions && dropdownOptions?.length > 0 && (
                    <SnapDropDown
                      id="mobile-menu"
                      className="hidden lg:block"
                      left-hang="false"
                      size="lg"
                      minimal
                      search-field="false"
                      modal-type="fullscreen"
                      options={dropdownOptions}
                      onSnap-dropdown-item-selected={menuClick}
                    />
                  )}
                </div>
              ),
              2: (
                <SnapIcon
                  icon="arrow-right-solid"
                  size="sm"
                  color="#2563EB"
                  className="my-auto hidden lg:block text-blue-600 cursor-pointer"
                  onClick={actionClick}
                />
              ),
              3: (
                <div className="cursor-pointer pt-2 flex justify-end">
                  <p
                    className="text-blue-600 text-sm font-bold"
                    onClick={actionClick}
                  >
                    Email Parent
                  </p>
                </div>
              ),
            }[action || 0]
          }
        </div>
      </div>
      {footer?.value && (
        <div className="flex">
          <p className="mr-2 text-sm text-gray-500 font-semibold">
            {footer.key}
          </p>
          <p className="text-sm font-medium whitespace-pre-line">
            {footer.value}
          </p>
        </div>
      )}
    </div>
  );
}

export default DataCard;
