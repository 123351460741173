export const defaultGroupInput = {
  enableDiscount: false,
  isBudgetShared: false,
  isLinkEnabled: false,
  name: "",
  seasonEndAt: "",
  seasonName: "",
  seasonStartAt: "",
  shareAccount: false,
};
