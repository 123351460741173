import { FormatMoney } from "helpers/format-money";
import { getHexColor } from "helpers/summary-related";
import { useState } from "react";
import ColorBar from "shared-components/color-bar";
import { SnapIcon } from "suit";
import { BudgetSummaryRecord, budgetItemDetails } from "types/budget-summary";

type BudgetSummaryRowLGProps = {
  budgetData: BudgetSummaryRecord;
  percent: number;
  color: string;
  handleSetModal: (selectedItem: budgetItemDetails) => void;
  type: string;
  isArchived: boolean;
};

export const BudgetSummaryRowLG = ({
  budgetData,
  percent,
  color,
  handleSetModal,
  type,
  isArchived,
}: BudgetSummaryRowLGProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="border px-4 py-2 rounded-lg mt-4 hidden lg:block">
      <div key={budgetData.name} className="flex">
        <p className="font-semibold flex items-center mr-auto">
          {budgetData.name}
        </p>
        <div className="border-r pr-3">
          <p className="text-xs font-bold text-gray-500">Budgeted</p>
          <p>{FormatMoney(budgetData.budgetAmount)}</p>
        </div>
        <div className="border-r  pl-3 pr-3">
          <p className="text-xs font-bold text-gray-500">Reconciled</p>
          <p>{FormatMoney(budgetData.budgetReconciled)}</p>
        </div>
        <div className="w-72 mr-3 ml-3 flex items-center border-r pr-3">
          <ColorBar percent={percent} colorHex={color} />
        </div>
        <SnapIcon
          icon={isOpen ? "chevron-up-solid" : "chevron-down-solid"}
          color="#2563EB"
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      {isOpen &&
        budgetData.expandedData.map((ex) => {
          let percent = (ex.budgetReconciled / ex.budgetAmount) * 100;
          return (
            <div key={ex.name} className="flex mt-4">
              <p
                className="font-semibold flex items-center mr-auto text-blue-600 pl-4 cursor-pointer"
                onClick={() =>
                  handleSetModal({
                    budgetId: ex.id,
                    category: budgetData.name,
                    type,
                    budgetItemName: ex.name,
                    budgetAmount: ex.budgetAmount,
                    reconciledAmount: ex.budgetReconciled,
                    dueDate: ex.targetDate,
                  })
                }
              >
                {ex.name}
              </p>
              <div className="border-r pr-3">
                <p className="text-xs font-bold text-gray-500">Budgeted</p>
                <p>{FormatMoney(ex.budgetAmount)}</p>
              </div>
              <div className="border-r  pl-3 pr-3">
                <p className="text-xs font-bold text-gray-500">Reconciled</p>
                <p>{FormatMoney(ex.budgetReconciled)}</p>
              </div>
              <div className="w-72 mr-9 ml-3 flex items-center pr-3">
                <ColorBar
                  percent={percent}
                  colorHex={getHexColor(
                    ex.budgetAmount,
                    ex.budgetReconciled,
                    true,
                    isArchived
                  )}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};
