export enum DateFormatSupported {
  Numbers,
  NumbersLight,
  Words,
  WordsWithDOW,
  Time,
}
export const FormatDate = (
  pastedInDate: string | Date | number | undefined | null,
  format?: DateFormatSupported,
  locale = "en-US",
  options?: Intl.DateTimeFormatOptions
): string => {
  if (pastedInDate?.toString().endsWith("T00:00:00.000Z")) {
    const dateNoTime = pastedInDate?.toString().split("T")[0];
    pastedInDate = `${dateNoTime}T08:00:00Z`;
  }

  if (pastedInDate == null) {
    return "No Date";
  }
  let date = new Date(pastedInDate);

  if (isNaN(date.valueOf())) {
    return "No Date";
  }
  if (!format) {
    format = DateFormatSupported.Numbers;
  }
  // if you want a custom format, need to use both local & options
  if (options != null) {
    return date.toLocaleDateString(locale, options);
  }
  if (format === DateFormatSupported.NumbersLight) {
    options = { month: "2-digit", day: "2-digit", year: "2-digit" };
    return date.toLocaleDateString(locale, options);
  }
  if (format === DateFormatSupported.Numbers) {
    options = { month: "2-digit", day: "2-digit", year: "numeric" };
    return date.toLocaleDateString(locale, options);
  }
  if (format === DateFormatSupported.Words) {
    options = { month: "short", day: "numeric", year: "numeric" };
    return date.toLocaleDateString(locale, options);
  }
  if (format === DateFormatSupported.Time) {
    options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    return date.toLocaleString(locale, options);
  }
  if (format === DateFormatSupported.WordsWithDOW) {
    options = {
      weekday: "long",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleString(locale, options);
  }
  // We can add time formats in the future
  // if (format === DateFormatSupported.Time) {
  //   return date.toLocaleTimeString(locale, {timeStyle: "short"})
  // }
  throw new Error("Unsupported Formatting");
};
//This will be used to send the date back over to the api
//   console.log(d.getTime())
