import { useContext } from "react";
import { SnapCheckboxButton, SnapIcon, SnapTable } from "suit";
import { FormatMoney } from "helpers/format-money";
import { calcTotalPlusFee } from "../make-payment/make-payment-helper";
import GroupContext from "context/group-context";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { PlayerInfoType } from "./change-payment-modal";

type ChangePaymentTableProps = {
  players: PlayerInfoType[];
  handleSelectedInvoices: (invoiceIdx: number, playerIdx: number) => void;
  handleSelectAll: (isChecked: boolean) => void;
  setSelectBoxChecked: (value: boolean) => void;
  isSelectBoxChecked: boolean;
};

export const ChangePaymentTable = ({
  players,
  handleSelectedInvoices: handleSelectedInvoives,
  handleSelectAll,
  setSelectBoxChecked,
  isSelectBoxChecked,
}: ChangePaymentTableProps) => {
  const activeGroup = useContext(GroupContext)?.activeGroup;
  const baseTextClass = `text-sm ml-2 overflow-hidden text-overflow-clip whitespace-pre-wrap break-words`;
  return (
    <div>
      <SnapTable>
        <table className="ui table table-fixed mb-5">
          <thead>
            <tr>
              <th className={`w-[24px]`}>
                <SnapCheckboxButton
                  checked={isSelectBoxChecked}
                  onClick={(e) => {
                    setSelectBoxChecked(e.currentTarget.checked);
                    handleSelectAll(isSelectBoxChecked);
                  }}
                />
              </th>
              <th>Group</th>
              <th>Participant</th>
              <th>Description</th>
              <th className="text-right">Billing Date</th>
              <th className="w-48 text-right">Current Payment Method</th>
              <th className="text-right">Pay By Bank</th>
              <th className="text-right">Pay By Card</th>
            </tr>
          </thead>
          <tbody>
            {players?.map((player, playerIdx) => {
              return player.invoices?.map((invoice, idx) => {
                const cardAmount = calcTotalPlusFee(
                  invoice?.balanceDue ?? 0,
                  activeGroup?.organizationFees?.cardPercent ?? 0,
                  activeGroup?.organizationFees?.cardBaseFee ?? 0
                );
                return (
                  <tr className="h-8" key={idx}>
                    <td>
                      <SnapCheckboxButton
                        checked={invoice?.selected}
                        onClick={() => handleSelectedInvoives(idx, playerIdx)}
                      />
                    </td>
                    <td>{player.team}</td>
                    <td>{player.player}</td>
                    <td className={baseTextClass}>{invoice?.description}</td>
                    <td className="text-right">
                      {" "}
                      {FormatDate(invoice?.dueDate!, DateFormatSupported.Words)}
                    </td>
                    <td className="text-right">
                      {(invoice?.paymentMethodId &&
                        invoice.paymentMethodId !== "" &&
                        invoice?.paymentMethodSource) ||
                        "None"}
                    </td>
                    <td className="text-right">
                      {FormatMoney(
                        calcTotalPlusFee(
                          invoice?.balanceDue ?? 0,
                          activeGroup?.organizationFees?.achPercent ?? 0,
                          activeGroup?.organizationFees?.achBaseFee ?? 0
                        )
                      )}
                    </td>
                    <td className="text-right">
                      {cardAmount > 50 ? (
                        FormatMoney(cardAmount)
                      ) : (
                        <SnapIcon
                          icon="exclamation-solid"
                          size="sm"
                          bg-bgColor="#FFF"
                          color="red"
                          title="Amount too small for card payments"
                        />
                      )}
                    </td>
                  </tr>
                );
              });
            })}
          </tbody>
        </table>
      </SnapTable>
    </div>
  );
};
