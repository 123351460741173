import FixedModal from "shared-components/fixed-modal/fixed-modal";

type EditModeWarningPaymentScheduleModalProps = {
  isOpen: boolean;
  toggle: () => void;
  groupName: string | null;
  publishDraftPaymentInvoices: (status: "published" | "draft") => void;
};

function EditModeWarningPaymentScheduleModal({
  isOpen,
  toggle,
  groupName,
  publishDraftPaymentInvoices,
}: EditModeWarningPaymentScheduleModalProps) {
  return (
    <FixedModal
      isOpen={isOpen}
      toggle={toggle}
      title={"Edit Payment Schedule"}
      icon="exclamation-solid"
      iconColor="#FEF3C7"
      btn1={{
        text: "Yes, suspend payment processing.",
        btnStyle: "primary",
        onClick: () => {
          publishDraftPaymentInvoices("draft");
          toggle();
        },
      }}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: toggle,
      }}
    >
      <div>
        <p className="text-sm text-gray-500 text-center">
          Editing will suspend processing of all <br />
          {groupName ?? "this group"} <br />
          Are you sure you want edit this payment <br />
          schedule?
        </p>
      </div>
    </FixedModal>
  );
}

export default EditModeWarningPaymentScheduleModal;
