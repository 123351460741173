import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  SpendPendingInvite,
  SpendRole,
  SpendSession,
  SpendSessionCreateMutation,
  SpendSessionCreateMutationVariables,
} from "graphql/generated";
import { CREATE_SESSION } from "graphql/mutations/session";
import { GET_GROUPS_FILTERED } from "graphql/queries/group";
import { PermissionsDocument } from "graphql/queries/permissions";
import { useEffect, useState } from "react";
import { dashboardHeader } from "types/admin-dashboard";
import { PermissionsResponse, Roles } from "types/roles-permissions";
import schoolIcon from "../assets/school_icon.svg";
import { redirectToSso } from "../helpers/sso";
import { GET_ALL_ROLES } from "graphql/queries/user";
// import useInvite from "./use-invite";
import { useLocation } from "react-router-dom";

export class ActiveUser {
  _user: PermissionsResponse | undefined;
  _session: SpendSession | undefined;
  _sessionError: string | undefined;
  _allRoles: SpendRole[] | undefined;

  constructor(
    active_user: PermissionsResponse | undefined,
    active_session: SpendSession | undefined,
    session_error: string | undefined,
    all_roles: SpendRole[] | undefined
  ) {
    this._user = active_user;
    this._session = active_session;
    this._sessionError = session_error;
    this._allRoles = all_roles;
  }
  getError(): string | undefined {
    return this._sessionError;
  }
  getIsUserReady(): boolean {
    return this._user != null && this._session != null;
  }
  getSessionId(): string | undefined {
    return this._session?.id || undefined;
  }
  isImpersonating(): boolean {
    return this._user?.me?.parents != null && this._user.me.parents.length > 0;
  }
  getEmail(): string {
    return `${this._user?.me?.email}`;
  }
  getName(): string {
    return `${this._user?.me?.firstName} ${this._user?.me?.lastName}`;
  }
  getProgram(): dashboardHeader[] {
    return [{ name: "Evanston Township High School", img: schoolIcon }];
  }

  isParent(): boolean {
    return this._session?.role?.name === Roles.guardian;
  }
  isProgramAdmin(): boolean {
    return this._session?.role?.name === Roles.programAdmin;
  }
  getRole(): string | undefined {
    if (
      this._session?.role?.name === Roles.groupStaff &&
      this.checkSpendPermission("program_bank:read")
    ) {
      return Roles.groupStaffWithProgramBankRead;
    }
    return this._session?.role?.name;
  }
  getSpendPermissions(): string[] | undefined {
    return this._session?.role?.permissions.map<string>((p) => String(p));
  }
  checkSpendPermission(permission: string): boolean {
    return this.getSpendPermissions()?.includes(permission) ?? false;
  }
  getOrgId(): string {
    return this._session?.role?.organizationId!;
  }
  getUserId(): string | null {
    return this._session?.userId || this._user?.me?.id || null;
  }
  getAllRoles(): SpendRole[] | null {
    return this._allRoles || null;
  }
  getInvite(): SpendPendingInvite | undefined {
    const inviteId = this._session?.inviteId;
    if (inviteId) {
      return this._session?.pendingInvites?.find((pi) => pi.id === inviteId);
    }
  }
  isObserver(): boolean | undefined {
    if (this._session?.role?.name != null) {
      return [Roles.groupObserver, Roles.programObserver].includes(
        this._session?.role.name
      );
    }
    return undefined;
  }
  isGroupLevel(): boolean {
    return this.getUserLevel() === "group";
  }
  getUserLevel(): "group" | "program" | "guardian" | undefined {
    const programLevels = [
      Roles.programAdmin,
      Roles.programObserver,
      Roles.programStaff,
    ];
    const groupLevels = [
      Roles.groupObserver,
      Roles.groupStaff,
      Roles.groupStaffWithProgramBankRead,
    ];
    const name = this._session?.role?.name;
    if (name != null) {
      if (programLevels.includes(name)) {
        return "program";
      }
      if (groupLevels.includes(name)) {
        return "group";
      }
      if (Roles.guardian === name) {
        return "guardian";
      }
    }
    return undefined;
  }
}

export default function useUser() {
  const location = useLocation();

  const { data, loading } = useQuery(PermissionsDocument, {
    onError: (error) => {
      const errorMessage = error.message.toLowerCase();
      if (
        errorMessage.includes("not authorised") ||
        errorMessage.includes("invalid token") ||
        errorMessage.includes("expired")
      ) {
        redirectToSso();
        // setError(errorMessage);
      } else {
        console.error(error);
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let inviteId: string | null = null;
  if (location.pathname.includes("/invite/guardian/")) {
    inviteId = location.pathname.split("/invite/guardian/")[1];
  } else if (location.pathname.includes("/invite/group_staff/")) {
    inviteId = location.pathname.split("/invite/group_staff/")[1];
  } else if (location.pathname.includes("/invite/group_observer/")) {
    inviteId = location.pathname.split("/invite/group_observer/")[1];
  } else if (location.pathname.includes("/invite/program_staff/")) {
    inviteId = location.pathname.split("/invite/program_staff/")[1];
  } else if (location.pathname.includes("/invite/program_observer/")) {
    inviteId = location.pathname.split("/invite/program_observer/")[1];
  }

  const [createSession, sessionCreateResponse] = useMutation<
    SpendSessionCreateMutation,
    SpendSessionCreateMutationVariables
  >(CREATE_SESSION, {
    variables: {
      inviteId: inviteId,
    },
    refetchQueries: ({ data }) => {
      if (!data?.spendSessionCreate) return [];
      switch (data.spendSessionCreate?.role?.name || "") {
        case "":
        case "guardian":
          return [];
        default:
          return [{ query: GET_GROUPS_FILTERED, fetchPolicy: "no-cache" }];
      }
    },
    awaitRefetchQueries: false,
  });
  const [getAllRoles, { loading: loadingUserRoles, data: userRolesData }] =
    useLazyQuery(GET_ALL_ROLES);

  const [activeUser, setActiveUser] = useState<PermissionsResponse | undefined>(
    undefined
  );
  const [activeSession, setActiveSession] = useState<SpendSession | undefined>(
    undefined
  );

  const [sessionError, setSessionError] = useState<string | undefined>(
    undefined
  );
  const [allRoles, setAllRoles] = useState<SpendRole[]>([]);

  useEffect(() => {
    if (!loading && data?.me) {
      setActiveUser(data ?? undefined);
    }
  }, [loading, data]);

  useEffect(() => {
    if (
      !!activeUser &&
      activeSession == null &&
      !location.pathname.includes("/application")
    ) {
      createSession();
    }
    // eslint-disable-next-line
  }, [activeSession, createSession, activeUser]);

  useEffect(() => {
    if (sessionCreateResponse.data?.spendSessionCreate) {
      setActiveSession(sessionCreateResponse.data.spendSessionCreate);
      getAllRoles();
    }
    if (sessionCreateResponse.error) {
      setSessionError(sessionCreateResponse.error.message);
    }
    // eslint-disable-next-line
  }, [sessionCreateResponse.data, sessionCreateResponse.error]);

  useEffect(() => {
    if (!loadingUserRoles && userRolesData && userRolesData.spendRoles) {
      setAllRoles(userRolesData.spendRoles.roles);
    }
  }, [loadingUserRoles, userRolesData]);

  return new ActiveUser(activeUser, activeSession, sessionError, allRoles);
}
