import { OffSessionPaymentWidget } from "@snap-mobile/payments-widget-frontend";
import { StripeEnvironment } from "@snap-mobile/payments-widget-utils";
import { stripePromise } from "index";
import { PayerDeets } from "./payment-component";

export type OffSessionPaymentType = {
  returnUrl: string;
  payerDetails: PayerDeets;
  className?: string;
  destination: string;
  externalPaymentId: string;
  paymentMethodCreate?: () => void;
};

export const OffSessionPaymentComponent = ({
  returnUrl,
  payerDetails,
  className,
  destination,
  externalPaymentId,
  paymentMethodCreate,
}: OffSessionPaymentType) => {
  return (
    <div className={`border-2 p-6 mb-10 ${className}`}>
      <OffSessionPaymentWidget
        appearance={{ submitButtonContent: "Confirm" }}
        returnUrl={returnUrl}
        stripePromise={stripePromise}
        user={{
          name: payerDetails.name,
          email: payerDetails.email,
        }}
        destination={destination}
        externalPaymentId={externalPaymentId}
        finalDestination={destination}
        stripeEnvironment={StripeEnvironment.SPEND}
        idempotencyKey={`${externalPaymentId}-${payerDetails.email}`}
        refresh={false}
        onSuccess={async (responseData) => {
          let resp = await responseData;
          if (resp && resp.id) {
            paymentMethodCreate && paymentMethodCreate();
          }
        }}
      />
    </div>
  );
};
