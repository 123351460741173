import { DateRangeEnum } from "graphql/generated";

export const transactionsFilters = [
  {
    name: "Unreconciled Transactions",
    text: "Unreconciled Transactions",
    value: "Unreconciled Transactions",
    selected: false,
  },
  {
    name: "Debits Without Attachments",
    text: "Debits Without Attachments",
    value: "Debits Without Attachments",
    selected: false,
  },
  {
    name: "Hide Failed Transactions",
    text: "Hide Failed Transactions",
    value: "Hide Failed Transactions",
    selected: false,
  },
  {
    name: "Hide Pending",
    text: "Hide Pending",
    value: "Hide Pending",
    selected: false,
  },
];

export const transactionDateRangeFilters = [
  {
    name: "Last 30 Days",
    text: "Last 30 Days",
    value: DateRangeEnum.ThirtyDays,
    selected: true,
  },
  {
    name: "Last 3 Months",
    text: "Last 3 Months",
    value: DateRangeEnum.ThreeMonths,
    selected: false,
  },
  {
    name: "Last 6 Months",
    text: "Last 6 Months",
    value: DateRangeEnum.SixMonths,
    selected: false,
  },
  {
    name: "Last 12 Months",
    text: "Last 12 Months",
    value: DateRangeEnum.TwelveMonths,
    selected: false,
  },
  {
    name: "Last 18 Months",
    text: "Last 18 Months",
    value: DateRangeEnum.EighteenMonths,
    selected: false,
  },
];
