import { SpendGroup } from "graphql/generated";
import { FormatMoney } from "helpers/format-money";
import Divider from "shared-components/divider";
import { SpinnerContainer } from "shared-components/spinner";
import VerticalLabelValue, {
  VerticalValueStyle,
} from "shared-components/vertical-label-value";
import { AllBudgetSummary } from "types/budget-summary";

type CardProps = {
  groups: AllBudgetSummary[];
  className?: string;
  handleGroupClick: (selectedGroup: SpendGroup) => void;
  spendGroups: SpendGroup[];
  groupsLoaded?: boolean;
  budgetExporting?: boolean;
};

function Cards({
  groups,
  className,
  handleGroupClick,
  spendGroups,
  groupsLoaded,
  budgetExporting,
}: CardProps) {
  return (
    <div className={`${className}`}>
      {groupsLoaded && !budgetExporting ? (
        groups.map((group, idx) => {
          return (
            <div
              key={group.groupName && group.groupName + idx + "card"}
              className="border rounded-lg p-4 mb-4"
            >
              <p
                className="text-blue-600 font-medium cursor-pointer"
                onClick={() => handleGroupClick(spendGroups[idx])}
              >
                {group.groupName}
              </p>
              <p className="text-xs font-medium text-gray-500">
                {group.seasonName}
              </p>
              <Divider isVisibleOnMobile className="mt-2" />
              <p className="text-sm my-2">Budgeted</p>
              <VerticalLabelValue
                label={"Income"}
                value={FormatMoney(group.budgetedIncomeTotal)}
                className="ml-auto"
              />
              <VerticalLabelValue
                label={"Expense"}
                value={FormatMoney(group.budgetExpenseTotal)}
                className="ml-auto"
              />
              <Divider isVisibleOnMobile className="my-2" />
              <p className="text-sm my-2">Reconciled</p>
              <VerticalLabelValue
                label={"Income"}
                value={FormatMoney(group.reconciledIncomeTotal)}
                className="ml-auto"
              />
              <VerticalLabelValue
                label={"Expense"}
                value={FormatMoney(group.reconciledExpenseTotal)}
                className="ml-auto"
                valueStyle={
                  group.reconciledExpenseTotal <= group.budgetExpenseTotal
                    ? VerticalValueStyle.Text
                    : VerticalValueStyle.HasIcon
                }
                icon="exclamation-solid"
                iconColor="#EF4444"
              />
              <Divider isVisibleOnMobile className="my-2" />
              <p className="text-sm my-2">Unreconciled</p>
              <VerticalLabelValue
                label={"Credits"}
                value={FormatMoney(group.unRecCredit)}
                className="ml-auto"
              />
              <VerticalLabelValue
                label={"Debits"}
                value={FormatMoney(group.unRecDebit)}
                className="ml-auto"
              />
            </div>
          );
        })
      ) : (
        <SpinnerContainer
          loading={(!groupsLoaded || budgetExporting) ?? false}
        />
      )}
    </div>
  );
}

export default Cards;
