import { useLazyQuery, useQuery } from "@apollo/client";
import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import UserContext from "context/user-context";
import { SpendDebitCard, SpendOrganization } from "graphql/generated";
import { GET_GROUP_DEBIT_CARDS } from "graphql/queries/group";
import { ORGANIZATION } from "graphql/queries/organization";
import { useContextStrict } from "helpers/context-strict";
import { filterDebitCards } from "helpers/filter-debit-cards";
import { FormatMoney } from "helpers/format-money";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import AssignDebitCard from "shared-components/debit-cards/assign-debit-card";
import UnitComponentGenerator from "unit/unit-component-generator";
import EmptyCards from "shared-components/debit-cards/empty-cards";
import Divider from "shared-components/divider";
import Spinner from "shared-components/spinner";
import { SnapButton } from "suit";
import { SpendPermissions } from "types/roles-permissions";

function GroupDebitCards() {
  const programContext = useContext(ProgramContext);
  const groupContext = useContextStrict(GroupContext);
  const activeGroupId = groupContext.activeGroup?.id;
  const activeGroupLimits = groupContext.accountLimits;

  const { isOpen: assignCardOpen, toggle: assignCardToggle } = useModal();
  const canAssignCard =
    useContext(UserContext)?.checkSpendPermission(
      SpendPermissions.groupDebitCardAssign
    ) ?? false;
  const canReadCard = useContext(UserContext)?.checkSpendPermission(
    SpendPermissions.groupDebitCardRead
  );

  const [getGroupDebitCards, { loading: loadingGroupCards, data: groupCards }] =
    useLazyQuery(GET_GROUP_DEBIT_CARDS, {
      variables: { spendGroupDebitCardsId: activeGroupId },
      fetchPolicy: "network-only",
    });
  const { loading: loadingOrgData, data: orgData } = useQuery(ORGANIZATION);

  const [debitCards, setDebitCards] = useState<SpendDebitCard[]>([]);
  const [inactiveCards, setInactiveCards] = useState<SpendDebitCard[]>([]);
  const [activeCards, setActiveCards] = useState<SpendDebitCard[]>([]);
  const [orgInfo, setOrgInfo] = useState<SpendOrganization | undefined>(
    undefined
  );

  useEffect(() => {
    if (!groupContext?.activeGroup) {
      groupContext?.setAndStoreActiveGroup(groupContext.groups.at(0));
    }
  }, [groupContext, programContext]);

  useEffect(() => {
    if (groupCards && groupCards.spendGroupDebitCards) {
      if (groupCards.spendGroupDebitCards.cards) {
        filterDebitCards(
          groupCards.spendGroupDebitCards.cards,
          setDebitCards,
          setInactiveCards,
          setActiveCards
        );
      }
    }

    if (orgData && orgData.spendOrganization) {
      setOrgInfo(orgData.spendOrganization);
    }
  }, [loadingGroupCards, groupCards, loadingOrgData, orgData]);

  useEffect(() => {
    getGroupDebitCards({
      variables: { spendGroupDebitCardsId: activeGroupId },
    });
  }, [getGroupDebitCards, activeGroupId]);

  useEffect(() => {
    window.addEventListener("unitCardStatusChanged", async function (e: any) {
      e.detail
        .then(function (data: any) {
          getGroupDebitCards({
            variables: { spendGroupDebitCardsId: activeGroupId },
          });
        })
        .catch((e: any) => {
          console.log(e);
        });
    });
    window.addEventListener("unitCardActivated", async function (e: any) {
      e.detail
        .then(function (data: any) {
          getGroupDebitCards({
            variables: { spendGroupDebitCardsId: activeGroupId },
          });
        })
        .catch((e: any) => {
          console.log(e);
        });
    });
  }, [getGroupDebitCards, activeGroupId]);
  return (
    <div className="wrapper">
      <div className="card">
        <div className="grid grid-cols-2 ">
          <div>
            <p className="text-sm font-semibold">
              A group can order up to three debit cards.
            </p>
            <p className="text-sm">
              Cards may be assigned to the Program Administrator or to Group
              Staff.
            </p>
            <p className="text-sm">
              Issued debit cards share the respective limits.
            </p>
            {activeGroupLimits?.card?.limits && (
              <>
                <p className="text-sm font-semibold">Limits:</p>
                <p className="text-sm">
                  Daily card purchases cannot exceed{" "}
                  {FormatMoney(activeGroupLimits.card.limits.dailyPurchase)}.
                </p>
                <p className="text-sm">
                  Daily card transactions (ie. Venmo, Cash App, Zelle, etc.)
                  cannot exceed{" "}
                  {FormatMoney(
                    activeGroupLimits.card.limits.dailyCardTransaction
                  )}
                  .
                </p>
                <p className="text-sm">
                  Daily card withdrawals cannot exceed{" "}
                  {FormatMoney(activeGroupLimits.card.limits.dailyWithdrawal)}.
                </p>
              </>
            )}
          </div>
          {debitCards.length < 3 && canAssignCard && (
            <div className="flex justify-end">
              <SnapButton
                variant="primary"
                onClick={assignCardToggle}
                fullWidth
                className="w-full lg:w-40"
                disabled={loadingGroupCards}
              >
                Order Debit Card
              </SnapButton>
            </div>
          )}
        </div>

        {loadingGroupCards ? (
          <Spinner />
        ) : !debitCards.length ? (
          <div className="flex justify-center">
            <EmptyCards message="No available debit cards." />
          </div>
        ) : (
          <>
            {inactiveCards.length !== 0 && canReadCard && (
              <div className="mt-6">
                <p className="text-sm font-medium text-gray-500">
                  Inactive Cards
                </p>
                <Divider isVisibleOnMobile />
                <div className="lg:flex">
                  {inactiveCards.map((card) => {
                    return (
                      <UnitComponentGenerator
                        key={card.id}
                        type={"card"}
                        data={{ cardId: card.id || undefined }}
                        permRequired={[SpendPermissions.groupDebitCardUpdate]}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            <div className="mt-6">
              <p className="text-sm font-medium text-gray-500">Active Cards</p>
              <Divider isVisibleOnMobile />
              {canReadCard &&
                (activeCards.length ? (
                  <div className="lg:flex">
                    {activeCards.map((card) => {
                      return (
                        <UnitComponentGenerator
                          key={card.id}
                          type={"card"}
                          data={{ cardId: card.id || undefined }}
                          permRequired={[SpendPermissions.groupDebitCardUpdate]}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div className="flex justify-center">
                    <EmptyCards message="No Active Debit Cards" />
                  </div>
                ))}
            </div>
          </>
        )}
        {assignCardOpen && (
          <AssignDebitCard
            assignDebitCardsOpen={assignCardOpen}
            assignDebitCardsToggle={assignCardToggle}
            orgInfo={orgInfo}
            groupId={activeGroupId}
            type="group"
            cards={[...activeCards, ...inactiveCards]}
          />
        )}
      </div>
    </div>
  );
}

export default GroupDebitCards;
