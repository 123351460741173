import { SpendInvoice } from "graphql/generated";
import { MapAndCalcSum } from "./map-and-reduce";

export const calcDiscount = (
  discountAmount: number | null | undefined,
  invoices: SpendInvoice[]
) => {
  const discountAry: { invoiceId: string; discountAmount: number }[] = [];
  if (!discountAmount) return discountAry;

  const total = MapAndCalcSum(invoices, "balanceDue");
  for (let i = 1; i < invoices.length; i++) {
    const invoice = invoices[i - 1];
    const percent = Number((invoice.balanceDue! / total).toFixed(2));
    discountAry.push({
      invoiceId: invoice.id!,
      discountAmount: Math.round(discountAmount * percent),
    });
  }
  const discountSoFar = MapAndCalcSum(discountAry, "discountAmount");
  discountAry.push({
    invoiceId: invoices[invoices.length - 1].id!,
    discountAmount: Math.round(discountAmount - discountSoFar),
  });
  return discountAry;
};

export const getBalanceDue = ({
  balanceDue,
  paid,
}: {
  balanceDue?: number | null;
  paid?: boolean | null;
}) => {
  return paid ? 0 : balanceDue ?? 0;
};
