import { useLazyQuery } from "@apollo/client";
import ProgramContext from "context/program-context";
import UserContext from "context/user-context";
import { SpendDebitCard } from "graphql/generated";
import { GET_ORGANIZATION_DEBIT_CARDS } from "graphql/queries/organization";
import { useContextStrict } from "helpers/context-strict";
import { filterDebitCards } from "helpers/filter-debit-cards";
import { FormatMoney } from "helpers/format-money";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import AssignDebitCard from "shared-components/debit-cards/assign-debit-card";
import UnitComponentGenerator from "unit/unit-component-generator";
import EmptyCards from "shared-components/debit-cards/empty-cards";
import Divider from "shared-components/divider";
import Spinner from "shared-components/spinner";
import { SnapButton } from "suit";
import { SpendPermissions } from "types/roles-permissions";

function ProgramDebitCards() {
  const programContext = useContextStrict(ProgramContext);
  const program = programContext.organization;
  const limits = programContext.accountLimits;
  const { isOpen: assignCardOpen, toggle: assignCardToggle } = useModal();
  const canAssignCard =
    useContext(UserContext)?.checkSpendPermission(
      SpendPermissions.programDebitCardAssign
    ) ?? false;

  const [getProgramCards, { loading: loadingOrgCards, data: orgCards }] =
    useLazyQuery(GET_ORGANIZATION_DEBIT_CARDS, { fetchPolicy: "network-only" });

  const [debitCards, setDebitCards] = useState<SpendDebitCard[]>([]);
  const [inactiveCards, setInactiveCards] = useState<SpendDebitCard[]>([]);
  const [activeCards, setActiveCards] = useState<SpendDebitCard[]>([]);

  useEffect(() => {
    if (orgCards && orgCards.spendOrganizationDebitCards) {
      if (orgCards.spendOrganizationDebitCards.cards) {
        filterDebitCards(
          orgCards.spendOrganizationDebitCards.cards,
          setDebitCards,
          setInactiveCards,
          setActiveCards
        );
      }
    }
  }, [loadingOrgCards, orgCards]);

  useEffect(() => {
    getProgramCards();
  }, [getProgramCards]);

  useEffect(() => {
    window.addEventListener("unitCardStatusChanged", async function (e: any) {
      e.detail
        .then(function (data: any) {
          getProgramCards();
        })
        .catch((e: any) => {
          console.log(e);
        });
    });
    window.addEventListener("unitCardActivated", async function (e: any) {
      e.detail
        .then(function (data: any) {
          getProgramCards();
        })
        .catch((e: any) => {
          console.log(e);
        });
    });
  }, [getProgramCards]);

  return (
    <div className="wrapper">
      <div className="card">
        <div className="lg:grid grid-cols-2 ">
          <div>
            <p className="text-sm font-semibold">
              Programs can order up to three debit cards.
            </p>
            <p className="text-sm">
              Cards may be assigned to the Program Administrator or to Program
              Staff.
            </p>
            <p className="text-sm">
              Issued debit cards share the respective limits.
            </p>
            {limits?.card?.limits && (
              <>
                <p className="text-sm font-semibold">Limits:</p>
                <p className="text-sm">
                  Daily card purchases cannot exceed{" "}
                  {FormatMoney(limits.card.limits.dailyPurchase)}.
                </p>
                <p className="text-sm">
                  Daily card transactions (ie. Venmo, Cash App, Zelle, etc.)
                  cannot exceed{" "}
                  {FormatMoney(limits.card.limits.dailyCardTransaction)}.
                </p>
                <p className="text-sm">
                  Daily card withdrawals cannot exceed{" "}
                  {FormatMoney(limits.card.limits.dailyWithdrawal)}.
                </p>
              </>
            )}
          </div>
          {debitCards.length < 3 && canAssignCard && (
            <div className="flex justify-end mt-4 lg:mt-4">
              <SnapButton
                variant="primary"
                onClick={assignCardToggle}
                fullWidth
                className="w-full lg:w-40"
                disabled={loadingOrgCards}
              >
                Order Debit Card
              </SnapButton>
            </div>
          )}
        </div>
        {loadingOrgCards ? (
          <Spinner />
        ) : !debitCards.length ? (
          <div className="flex justify-center">
            <EmptyCards message="No available debit cards." />
          </div>
        ) : (
          <>
            {inactiveCards.length !== 0 && canAssignCard && (
              <div className="mt-6">
                <p className="text-sm font-medium text-gray-500">
                  Inactive Cards
                </p>
                <Divider isVisibleOnMobile />
                <div className="lg:flex">
                  {inactiveCards.map((card) => {
                    return (
                      <UnitComponentGenerator
                        type={"card"}
                        data={{ cardId: card.id || undefined }}
                        permRequired={[SpendPermissions.groupDebitCardAssign]}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            <div className="mt-6">
              <p className="text-sm font-medium text-gray-500">Active Cards</p>
              <Divider isVisibleOnMobile />
              {canAssignCard &&
                (activeCards.length ? (
                  <div className="lg:flex">
                    {activeCards.map((card) => {
                      return (
                        <UnitComponentGenerator
                          key={card.id}
                          type={"card"}
                          data={{ cardId: card.id || undefined }}
                          permRequired={[SpendPermissions.groupDebitCardAssign]}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div className="flex justify-center">
                    <EmptyCards message="No Active Debit Cards" />
                  </div>
                ))}
            </div>
          </>
        )}
        {assignCardOpen && (
          <AssignDebitCard
            assignDebitCardsOpen={assignCardOpen}
            assignDebitCardsToggle={assignCardToggle}
            orgInfo={program}
            type="program"
            cards={[...activeCards, ...inactiveCards]}
          />
        )}
      </div>
    </div>
  );
}

export default ProgramDebitCards;
