import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import DisplayContext from "context/display-context";
import GroupContext from "context/group-context";
import ToastContext from "context/toast-context";
import UserContext from "context/user-context";
import {
  SpendGroupRosterStatusEnum,
  useSpendGroupsFilteredLazyQuery,
  useSpendRostersFilteredQuery,
} from "graphql/generated";
import {
  HandleAllCheckboxAction,
  hasAllById,
} from "helpers/all-checkbox-action";
import { useContextStrict } from "helpers/context-strict";
import { handleCalculations } from "helpers/summary-related";
import useModal from "hooks/use-modal";
import { useSpendPagination } from "hooks/use-spend-pagination";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BulkActions from "shared-components/bulk-actions";
import Divider from "shared-components/divider";
import Filter from "shared-components/filter";
import ErrorEmail from "shared-components/fixed-modal/error-email";
import SendEmail from "shared-components/modal/send-email";
import { SearchInput } from "shared-components/search-input";
import ShowingResults from "shared-components/showing-results";
import Sort from "shared-components/sort";
import { SnapPagination } from "suit";
import { SpendRosterChecked, SpendRosterResend } from "types/group-roster";
import { SpendPermissions } from "types/roles-permissions";
import Cards from "./cards";
import Table from "./table";
import RemoveParticipant from "pages/groups/collections/modals/remove-participant";
import { rosterStatusToText } from "helpers/status-text";
import { bulkActionsMapper } from "helpers/bulk-actions-mapper";
import PageLimit from "shared-components/page-limit";
import SeasonContext from "context/season-context";

const TEAM_FILTER_OPTIONS = [
  {
    name: "All Participants",
    text: "All Participants",
    value: "",
    selected: true,
  },
  {
    name: "Auto-pay Stopped",
    text: "Auto-pay Stopped",
    value: SpendGroupRosterStatusEnum.AutopayStop,
    selected: false,
  },
  {
    name: "Awaiting Approval",
    text: "Awaiting Approval",
    value: SpendGroupRosterStatusEnum.AwaitingApproval,
    selected: false,
  },
  {
    name: "No Invite Sent",
    text: "No Invite Sent",
    value: SpendGroupRosterStatusEnum.NoInviteSent,
    selected: false,
  },
  {
    name: "Not Signed Up",
    text: "Not Signed Up",
    value: SpendGroupRosterStatusEnum.NotSignedUp,
    selected: false,
  },
  {
    name: "Past Due Participants",
    text: "Past Due Participants",
    value: SpendGroupRosterStatusEnum.PastDue,
    selected: false,
  },
];
const PARTICIPANT_SORT_OPTIONS: DropdownMenuItem[] = [
  { name: "Name", text: "Name - A - Z", value: "name:asc" },
  { name: "Name", text: "Name - Z - A", value: "name:desc" },
  {
    name: "Teams",
    text: "Teams - Date first",
    value: "seasonDate:asc",
  },
  {
    name: "Teams",
    text: "Teams - Date last",
    value: "seasonDate:desc",
  },
  { name: "Paid", text: "Paid - Max first", value: "paid:desc" },
  { name: "Paid", text: "Paid - Min first", value: "paid:asc" },
  { name: "Upcoming", text: "Upcoming - Max first", value: "upcoming:desc" },
  { name: "Upcoming", text: "Upcoming - Min first", value: "upcoming:asc" },
  { name: "Past Due", text: "Past Due - Max first", value: "pastDue:desc" },
  { name: "Past Due", text: "Past Due - Min first", value: "pastDue:asc" },
  {
    name: "Days Past Due",
    text: "Days Past Due - Max first",
    value: "pastDueDays",
  },
  {
    name: "Days Past Due",
    text: "Days Past Due - Min first",
    value: "pastDueDays",
  },
];

function Participants() {
  const navigate = useNavigate();
  const display = useContextStrict(DisplayContext);
  const group = useContextStrict(GroupContext);
  const season = useContextStrict(SeasonContext);
  const toast = useContextStrict(ToastContext);
  const { isOpen: sendEmailOpen, toggle: sendEmailToggle } = useModal();
  const { isOpen: errorMessageOpen, toggle: errorMessageToggle } = useModal();
  const [selectedSeasonId, setSelectedSeasonId] = useState("");
  const [participants, setParticipants] = useState<SpendRosterChecked[]>([]);
  const [total, setTotal] = useState(0);
  const [isAllRecordsSelected] = useState(false);
  const [isItemSelected, setIsItemSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState<SpendRosterChecked[]>([]);
  const [selectedItemWithStatus, setSelectedItemsWithStatus] = useState<
    SpendRosterResend[]
  >([]);
  const [isNameBoxChecked, setNameBoxChecked] = useState(false);
  const { isOpen: removeParticipantOpen, toggle: removeParticipantToggle } =
    useModal();
  const [searchText, setSearchText] = useState("");

  const { page, setPage, sort, sortValue, toggleSort, handleSortValue } =
    useSpendPagination();
  const [filterType, setFilterType] = useState<SpendGroupRosterStatusEnum | "">(
    ""
  );
  const [pageLimit, setPageLimit] = useState(10);

  const canEditParticipants =
    useContext(UserContext)?.checkSpendPermission(
      SpendPermissions.groupRostersUpdate
    ) ?? false;

  const { data, loading } = useSpendRostersFilteredQuery({
    variables: {
      where: {
        nameIncludes:
          searchText && searchText.length > 2 ? searchText : undefined,
        status: filterType === "" ? undefined : filterType,
      },
      pagination: {
        limit: pageLimit,
        offset: page * pageLimit,
      },
      sort: sort,
    },
    onError: (error: any) => {
      toast.setToast({ message: error.message, type: "danger" });
    },
  });

  const [getGroup, { data: groupData, loading: loadingGroup }] =
    useSpendGroupsFilteredLazyQuery();

  useEffect(() => {
    if (!loading && data && data.spendRostersFiltered) {
      setTotal(data.spendRostersFiltered?.count || 0);
      if (data.spendRostersFiltered?.rosters) {
        let participantsRosters = data.spendRostersFiltered.rosters.map(
          (roster) => {
            return {
              ...roster,
              isChecked: !!selectedItems.find(
                (SI: SpendRosterChecked) => roster?.id === SI?.id
              ),
            };
          }
        );
        let selectedParticipants = participantsRosters.filter(
          (p) => p.isChecked
        );
        if (selectedParticipants.length === participantsRosters.length) {
          setNameBoxChecked(true);
        }
        setParticipants(participantsRosters);
      }
    }
    // eslint-disable-next-line
  }, [loading, data]);

  useEffect(() => {
    let participantsInviteIDs: SpendRosterResend[] =
      bulkActionsMapper(selectedItems);
    setSelectedItemsWithStatus(participantsInviteIDs);
  }, [selectedItems]);

  useEffect(() => {
    if (!loadingGroup && groupData) {
      const foundGroup = groupData.spendGroupsFiltered?.groups?.at(0);
      group?.setAndStoreActiveGroup({
        ...foundGroup!,
        currentSeason: foundGroup?.latestSeason || undefined,
      });
      season?.handleSelectedSeason({
        seasonId: selectedSeasonId,
        seasonsToSet: foundGroup?.seasons,
      });

      navigate("/groups/collections");
    }
    // eslint-disable-next-line
  }, [loadingGroup, groupData]);

  const navigateToDetailRoster = (userId: string) => {
    navigate(`/people/participants/participant-details/${userId}`);
  };

  const handleGroupNavigate = (groupId: string, seasonId: string) => {
    getGroup({
      variables: {
        where: {
          ids: [groupId ?? ""],
          archived: false,
        },
      },
    });
    setSelectedSeasonId(seasonId);
  };

  const handleCheckboxAction = (idx: number) => {
    const tempParticipants = [...participants];
    const currentSelected = tempParticipants[idx];
    let tempSelectedItem = {
      ...currentSelected,
      isChecked: !currentSelected.isChecked,
    };
    tempParticipants.splice(idx, 1, tempSelectedItem);
    setParticipants(tempParticipants);
    let currentlySelectedItems = [...selectedItems];
    if (tempSelectedItem.isChecked) {
      currentlySelectedItems.push(tempSelectedItem);
    } else {
      let idx = currentlySelectedItems.findIndex(
        (i) => i.id === tempSelectedItem.id
      );
      currentlySelectedItems.splice(idx, 1);
    }
    setSelectedItems(currentlySelectedItems);
    setNameBoxChecked(hasAllById(currentlySelectedItems, participants));
    setIsItemSelected(currentlySelectedItems.length > 0);
  };

  const handleDeselectAll = () => {
    const tempParticipantsArray = participants.map(
      (participant: SpendRosterChecked) => {
        return {
          ...participant,
          isChecked: false,
        };
      }
    );
    setParticipants(tempParticipantsArray);
    setSelectedItemsWithStatus([]);
    setSelectedItems([]);
    setIsItemSelected(false);
    setNameBoxChecked(false);
  };

  const handleNavToReportingInvoices = (
    groupRosterId: string,
    status: string
  ) => {
    navigate("/reporting/invoices", {
      state: {
        groupRosterId,
        status: status,
      },
    });
  };

  const getInviteStatusAndOptionCheck = (
    inviteStatus: string | undefined | null,
    inviteExpiresAt: string | undefined | null,
    groupRosterStatus: string | undefined | null
  ) => {
    let grStatus = "No Invite Sent";
    const resendInviteAllowedStatuses = [
      "No Invite Sent",
      "Expired",
      "Not Signed up",
    ];

    if (
      inviteStatus === "pending" &&
      new Date(inviteExpiresAt ?? "") < new Date()
    ) {
      grStatus = "Expired";
    } else if (inviteStatus === "pending") {
      grStatus = "Not Signed up";
    } else if (inviteStatus === "accepted") {
      grStatus = rosterStatusToText(groupRosterStatus ?? "");
    }
    const addOption = resendInviteAllowedStatuses.includes(grStatus);

    return { status: grStatus, addOption };
  };

  return (
    <div className="wrapper">
      <div className={`card ${isItemSelected && "mb-14"}`}>
        <SearchInput setSearchValue={setSearchText} />
        <Filter
          dropdownOptions={TEAM_FILTER_OPTIONS}
          className="lg:mt-4 mb-6 mt-8"
          handleSelect={(e) =>
            setFilterType((e.target as any).value as SpendGroupRosterStatusEnum)
          }
        />
        <Divider isVisibleOnMobile />
        <div className="text-lg font-semibold mt-4">Participants Overview</div>
        <div className="lg:flex">
          <ShowingResults
            totalNumOfResults={total}
            numOfResultsBeingDisplayed={
              total <= pageLimit
                ? total
                : pageLimit * page + pageLimit >= total
                ? total
                : pageLimit * page + pageLimit
            }
            startingNumOfResults={total === 0 ? 0 : pageLimit * page + 1}
            hasCheckbox={canEditParticipants}
            hideCheckboxOnWeb
            isNameBoxChecked={isNameBoxChecked}
            checkboxAction={(e) => {
              setNameBoxChecked(e.currentTarget.checked);
              HandleAllCheckboxAction(
                isNameBoxChecked,
                participants,
                setParticipants,
                setSelectedItems,
                selectedItems
              );
            }}
          />
          <PageLimit
            setPageLimit={setPageLimit}
            setPage={setPage}
            localStorageName={"participant-page-limit"}
          />
        </div>

        <Divider isVisibleOnMobile className="lg:hidden mt-2 mb-5" />
        {display?.isDesktop && (
          <Sort
            selectedSortOption={sortValue}
            options={PARTICIPANT_SORT_OPTIONS}
            handleSort={(e) => handleSortValue(e.value)}
          />
        )}

        <div className="relative">
          {display?.isDesktop ? (
            <Table
              participants={participants}
              loading={loading}
              handleNavigate={navigateToDetailRoster}
              handleGroupNavigate={handleGroupNavigate}
              handleCheckboxAction={handleCheckboxAction}
              setParticipants={setParticipants}
              isNameBoxChecked={isNameBoxChecked}
              setNameBoxChecked={setNameBoxChecked}
              sort={sort}
              toggleSort={toggleSort}
              canEditParticipants={canEditParticipants}
              handleNavToReportingInvoices={handleNavToReportingInvoices}
              setSelectedItems={setSelectedItems}
              selectedItems={selectedItems}
              getInviteStatusAndOptionCheck={getInviteStatusAndOptionCheck}
            />
          ) : (
            <Cards
              participants={participants}
              loading={loading}
              isAllRecordsSelected={isAllRecordsSelected}
              handleNavigate={navigateToDetailRoster}
              handleGroupNavigate={handleGroupNavigate}
              handleCalculations={handleCalculations}
              handleCheckboxAction={handleCheckboxAction}
              handleNavToReportingInvoices={handleNavToReportingInvoices}
              getInviteStatusAndOptionCheck={getInviteStatusAndOptionCheck}
            />
          )}
        </div>

        <SnapPagination
          currentPage={page}
          itemCount={total}
          pageSize={pageLimit}
          onSnap-pagination-page-changed={(e) => {
            setNameBoxChecked(false);
            setPage(e.detail);
          }}
        />
      </div>
      {selectedItems.length > 0 && (
        <BulkActions
          numOfItemsSelected={selectedItems.length}
          deselectAllAction={handleDeselectAll}
          sendEmailToggle={sendEmailToggle}
          toast={toast}
          selectedItems={selectedItemWithStatus}
          errorMessageToggle={errorMessageToggle}
          removeParticipantToggle={removeParticipantToggle}
        />
      )}
      <ErrorEmail isOpen={errorMessageOpen} toggle={errorMessageToggle} />
      {sendEmailOpen && (
        <SendEmail
          sendEmailOpen={sendEmailOpen}
          sendEmailToggle={sendEmailToggle}
          selectedItems={selectedItems}
          role="Participants"
        />
      )}
      <RemoveParticipant
        removeParticipantOpen={removeParticipantOpen}
        removeParticipantToggle={removeParticipantToggle}
        selectedItems={selectedItemWithStatus}
        deselectAllAction={handleDeselectAll}
      />
    </div>
  );
}

export default Participants;
