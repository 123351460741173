import { useMutation } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import ToastContext from "context/toast-context";
import { ORG_ACCOUNT_TRANSFER } from "graphql/mutations/organization";
import { GET_ORGANIZATION_ACCOUNTS } from "graphql/queries/organization";
import { FormatMoney } from "helpers/format-money";
import { useContext, useEffect, useState } from "react";
import Divider from "shared-components/divider";
import InputMask from "shared-components/input-mask";
import CustomModal, { BtnState } from "shared-components/modal";
import { SnapSelectMenu } from "suit";
import { transferError } from "types/errors";

type TransferProps = {
  transferOpen: boolean;
  transferToggle: () => void;
};

function ProgramInteralTransfer({
  transferOpen,
  transferToggle,
}: TransferProps) {
  const [
    accountTransfer,
    {
      loading: loadingAccountTransfer,
      data: accountTransferData,
      error: accountTransferError,
    },
  ] = useMutation(ORG_ACCOUNT_TRANSFER, {
    refetchQueries: [
      { query: GET_ORGANIZATION_ACCOUNTS, fetchPolicy: "no-cache" },
    ],
  });
  const ActiveProgram = useContext(ProgramContext);
  const Group = useContext(GroupContext);
  const Toast = useContext(ToastContext);
  const [optionsVal, setOptionsVal] = useState([{ name: "", id: "" }]);
  const [to, setTo] = useState<SnapSelectMenuOption[]>([]);
  const [from, setFrom] = useState<SnapSelectMenuOption[]>([]);
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [hasErrorsObj, setHasErrorsObj] = useState<transferError>({
    toError: false,
    fromError: false,
    noteError: false,
    amountError: false,
    availableError: false,
    macthingError: false,
  });
  const [isBtnActive, setIsBtnActive] = useState(true);

  useEffect(() => {
    if (!loadingAccountTransfer && accountTransferData) {
      let selectedToItem = to.filter((op) => op.selected);
      let selectFromItem = from.filter((op) => op.selected);
      Toast?.setToast({
        message: `${FormatMoney(
          Number(amount) * 100
        )} was Successfully Transfered to ${selectedToItem.at(0)?.name} from ${
          selectFromItem.at(0)?.name
        }`,
        type: "success",
      });
    }

    if (!loadingAccountTransfer && accountTransferError) {
      setHasErrorsObj({ ...hasErrorsObj, availableError: true });
      Toast?.setToastProps({
        title: "Unable to Transfer Funds",
        message: accountTransferError?.message ?? "",
        type: "danger",
      });
      setIsBtnActive(true);
    }
    // eslint-disable-next-line
  }, [loadingAccountTransfer, accountTransferData]);

  useEffect(() => {
    let program: SnapSelectMenuOption = {
      name:
        ActiveProgram?.organization?.nickname ||
        ActiveProgram?.organization?.legalName ||
        "",
      selected: false,
    };
    let group = Group?.groups?.map((group) => {
      let option: SnapSelectMenuOption = {
        name: group.name ?? "",
        selected: false,
      };
      return option;
    });
    let programVal = {
      name:
        ActiveProgram?.organization?.nickname ||
        ActiveProgram?.organization?.legalName ||
        "",
      id: ActiveProgram?.organization?.id ?? "",
    };

    let groupVal = Group?.groups?.map((group) => {
      return {
        name: group.name ?? "",
        id: group.id ?? "",
      };
    });
    setOptionsVal([programVal, ...(groupVal ?? [])]);
    setTo([program, ...(group ?? [])]);
    setFrom([program, ...(group ?? [])]);
  }, [ActiveProgram, Group?.groups]);

  const initDropdowns = () => {
    let program: SnapSelectMenuOption = {
      name:
        ActiveProgram?.organization?.nickname ||
        ActiveProgram?.organization?.legalName ||
        "",
      selected: false,
    };
    let programVal = {
      name:
        ActiveProgram?.organization?.nickname ||
        ActiveProgram?.organization?.legalName ||
        "",
      id: ActiveProgram?.organization?.id ?? "",
    };
    let group: SnapSelectMenuOption[] | undefined = [];

    let groupVal = Group?.groups?.map((group) => {
      return {
        name: group.name ?? "",
        id: group.id ?? "",
      };
    });

    group = Group?.groups?.map((group) => {
      let option: SnapSelectMenuOption = {
        name: group.name ?? "",
        selected: false,
        value: group.id ?? "",
      };
      return option;
    });
    setFrom([program, ...(group ?? [])]);
    setTo([...(group ?? [])]);

    setOptionsVal([programVal, ...(groupVal ?? [])]);
  };

  const handleTransfer = () => {
    let selectedToItem = to.filter((op) => op.selected);
    let selectFromItem = from.filter((op) => op.selected);
    if (selectedToItem.length === 0) {
      setHasErrorsObj({ ...hasErrorsObj, toError: true });
      hasErrorsObj.toError = true;
    }
    if (selectFromItem.length === 0) {
      setHasErrorsObj({ ...hasErrorsObj, fromError: true });
      hasErrorsObj.fromError = true;
    }
    if (amount === "0.00" || amount === "") {
      setHasErrorsObj({ ...hasErrorsObj, amountError: true });
      hasErrorsObj.amountError = true;
    }
    if (note === "") {
      setHasErrorsObj({ ...hasErrorsObj, noteError: true });
    }
    let { toError, fromError, amountError, noteError } = hasErrorsObj;
    if (toError || fromError || amountError || noteError) {
      Toast?.setToast({
        message: "Please fill in all the input fields",
        type: "danger",
      });
    } else {
      if (
        (ActiveProgram?.getProgramAccount()?.available ?? 0) <
        Number(amount) * 100
      ) {
        Toast?.setToast({
          type: "danger",
          message: "The Amount inputted exceeds the Available Funds",
        });
        setHasErrorsObj({ ...hasErrorsObj, availableError: true });
      } else if (
        optionsVal.find((op) => op.name === selectedToItem[0].name)?.id ===
        optionsVal.find((op) => op.name === selectFromItem[0].name)?.id
      ) {
        Toast?.setToast({
          type: "danger",
          message: "Transfer To and Transfer From must not match",
        });
      } else {
        accountTransfer({
          variables: {
            input: {
              amount: Number(amount.replace(",", "")) * 100,
              description: note,
              toGroupIdOrOrgId: optionsVal.find(
                (op) => op.name === selectedToItem[0].name
              )?.id,
              fromGroupIdOrOrgId: optionsVal.find(
                (op) => op.name === selectFromItem[0].name
              )?.id,
            },
          },
        });
        setIsBtnActive(false);
        transferToggle();
        setAmount("");
        setNote("");
        initDropdowns();
        setHasErrorsObj({
          toError: false,
          fromError: false,
          noteError: false,
          amountError: false,
          availableError: false,
          macthingError: false,
        });
      }
    }
  };

  const handleSelection = (type: string, options: SnapSelectMenuOption[]) => {
    if (type === "to") setTo(options);
    if (type === "from") setFrom(options);
  };

  return (
    <CustomModal
      isOpen={transferOpen}
      toggle={() => {
        transferToggle();
        setAmount("");
        setNote("");
        initDropdowns();
        setHasErrorsObj({
          toError: false,
          fromError: false,
          noteError: false,
          amountError: false,
          availableError: false,
          macthingError: false,
        });
        setIsBtnActive(true);
      }}
      title={"Transfer"}
      btn1={{
        text: "Submit",
        btnStyle: "primary",
        onClick: handleTransfer,
        btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
      }}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: () => {
          transferToggle();
          setAmount("");
          setNote("");
          initDropdowns();
          setHasErrorsObj({
            toError: false,
            fromError: false,
            noteError: false,
            amountError: false,
            availableError: false,
            macthingError: false,
          });
        },
      }}
    >
      <>
        <div className="modal-card">
          <p className="text-sm font-medium text-gray-500">
            Internal transfers usually settle in a few minutes.
          </p>
          <Divider isVisibleOnMobile />
          <div className="lg:grid grid-cols-3 gap-6 flex flex-col lg:mt-4">
            <SnapSelectMenu
              label="To"
              className="mt-4 lg:mt-0"
              selectMenuOptions={to}
              onSnap-select-menu-updated={(e) => {
                handleSelection("to", e.detail);
                setHasErrorsObj({ ...hasErrorsObj, toError: false });
              }}
              error={hasErrorsObj.toError}
            />
            <SnapSelectMenu
              label="From"
              className="mt-4 lg:mt-0"
              selectMenuOptions={from}
              onSnap-select-menu-updated={(e) => {
                handleSelection("from", e.detail);
                setHasErrorsObj({ ...hasErrorsObj, fromError: false });
              }}
              error={hasErrorsObj.fromError}
            />
            <InputMask
              amount={amount}
              setAmount={setAmount}
              hasError={hasErrorsObj.amountError || hasErrorsObj.availableError}
              onChange={() =>
                setHasErrorsObj({ ...hasErrorsObj, amountError: false })
              }
            />
          </div>
          <div className="flex flex-col mt-4 w-full">
            <p>Note</p>
            <textarea
              className={`border-2 border-gray-200 w-full rounded-lg pb-28 px-3 pt-3 ${
                hasErrorsObj.noteError && "border-red-500"
              }`}
              onChange={(e) => {
                setNote(e.target.value);
                setHasErrorsObj({ ...hasErrorsObj, noteError: false });
              }}
            />
          </div>
        </div>
      </>
    </CustomModal>
  );
}

export default ProgramInteralTransfer;
