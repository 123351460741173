import UserContext from "context/user-context";
import {
  useSpendUserInviteDismissMutation,
  useSpendUserRoleSetMutation,
} from "graphql/generated";
import { useContextStrict } from "helpers/context-strict";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SnapButton } from "suit";

function ExpiredInvite({
  setExpiredInvite,
}: {
  setExpiredInvite: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const userContext = useContextStrict(UserContext);
  const roles = userContext.getAllRoles();
  const invite = userContext.getInvite();
  const sessionStatus = userContext._session?.status;
  const [changeRole, { loading, data }] = useSpendUserRoleSetMutation();
  const [dismissInvite] = useSpendUserInviteDismissMutation();

  const canCancel =
    (userContext._session?.pendingInvites || []).length > 1 ||
    roles?.find((r) => !r.isNotSignedUp);

  useEffect(() => {
    if (!loading && data) {
      if (data.spendUserRoleSet) {
        setExpiredInvite(false);
        navigate("/dashboard");
      }
    }
    // eslint-disable-next-line
  }, [loading, data]);
  const handleCancelInvite = async () => {
    if (invite?.id) {
      dismissInvite({
        variables: {
          inviteId: invite.id,
        },
      });

      let similarRole = roles?.find(
        (role) =>
          role.isNotSignedUp === false && invite.groupId === role.groupId
      );
      if (!similarRole) {
        similarRole = roles?.find(
          (role) =>
            role.isNotSignedUp === false &&
            invite.organizationId === role.organizationId
        );
      }
      if (!similarRole) {
        similarRole = roles?.find((role) => role.isNotSignedUp === false);
      }

      if (similarRole) {
        changeRole({
          variables: {
            roleId: similarRole.id,
          },
        });
      }
    }
  };
  return (
    <div className="flex justify-center mt-10">
      <div>
        <p className="flex justify-center font-bold">Invitation Expired</p>
        {invite != null && (
          <>
            <p className="capitalize mt-3">
              <span className="font-semibold mr-2">Role:</span>
              {invite?.type}
            </p>
            <p>
              <span className="font-semibold mr-2">Group:</span>
              {invite?.groupName}
            </p>
          </>
        )}
        <p className=" font-bold mt-5">
          Please contact your program to have the invite resent.
        </p>
        {invite != null && sessionStatus === "expired" && canCancel && (
          <div className="flex">
            <SnapButton
              variant="danger"
              className="mx-auto mt-3 w-full lg:w-48"
              fullWidth
              onClick={handleCancelInvite}
            >
              Dismiss Invite
            </SnapButton>
          </div>
        )}
      </div>
    </div>
  );
}

export default ExpiredInvite;
