import { useMutation, useQuery } from "@apollo/client";
import UserContext from "context/user-context";
import { CategoryTypeEnum, SpendUpsertCategoryInput } from "graphql/generated";
import { GET_ALL_CATEGORIES } from "graphql/queries/categories";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import Divider from "shared-components/divider";
import Tooltip from "shared-components/tooltip";
import { SnapIcon } from "suit";
import { SpendPermissions } from "types/roles-permissions";
import BudgetCategory from "./budget-category";
import { CATEGORIES_BULK_UPSERT } from "graphql/mutations/category";
import DisplayContext from "context/display-context";
function Budgeting() {
  const Display = useContext(DisplayContext);

  const { isOpen: incomeIooltipOpen, toggle: incomeTooltipToggle } = useModal();
  const { isOpen: expenseTooltipOpen, toggle: expenseTooltipToggle } =
    useModal();
  const [defaultCategory, setDefaultCategory] =
    useState<SpendUpsertCategoryInput>({
      id: undefined,
      name: "",
      isHidden: false,
      isDefault: true,
      type: CategoryTypeEnum.Income,
    });
  const [categories, setCategories] = useState<SpendUpsertCategoryInput[]>([]);
  const [expenseCategories, setExpenseCategories] = useState<
    SpendUpsertCategoryInput[]
  >([]);
  const [incomeCategories, setIncomeCategories] = useState<
    SpendUpsertCategoryInput[]
  >([]);
  const [expenseCategoryNames, setExpenseCategoryNames] = useState<Set<string>>(
    new Set()
  );
  const [incomeCategoryNames, setIncomeCategoryNames] = useState<Set<string>>(
    new Set()
  );
  useEffect(() => {
    const tmpCategories = [...categories];
    const tmpIncomeCategories: Array<SpendUpsertCategoryInput> = [];
    const tmpIncomeCategoryNames: Set<string> = new Set();
    const tmpExpenseCategories: Array<SpendUpsertCategoryInput> = [];
    const tmpExpenseCategoryNames: Set<string> = new Set();
    let newIncome = false;
    let newExpense = false;
    const csort = (
      a: SpendUpsertCategoryInput,
      b: SpendUpsertCategoryInput
    ) => {
      const aNew = a.name === "" ? 1 : 0;
      const bNew = b.name === "" ? 1 : 0;
      return aNew - bNew || a.name.localeCompare(b.name);
    };
    tmpCategories.forEach((category) => {
      if (category.type === "expense") {
        if (category.name !== "" || !newExpense) {
          tmpExpenseCategories.push(category);
          tmpExpenseCategoryNames.add(category.name.toLowerCase());
          if (category.name === "") {
            newExpense = true;
          }
        }
      } else {
        if (category.name !== "" || !newIncome) {
          tmpIncomeCategories.push(category);
          tmpIncomeCategoryNames.add(category.name.toLowerCase());
        }
        if (category.name === "") {
          newIncome = true;
        }
      }
    });
    tmpIncomeCategories.sort(csort);
    setIncomeCategories(tmpIncomeCategories);
    setIncomeCategoryNames(tmpIncomeCategoryNames);
    tmpExpenseCategories.sort(csort);
    setExpenseCategories(tmpExpenseCategories);
    setExpenseCategoryNames(tmpExpenseCategoryNames);
  }, [categories]);

  const [categoriesBulkUpsert] = useMutation(CATEGORIES_BULK_UPSERT, {
    refetchQueries: [
      { query: GET_ALL_CATEGORIES, fetchPolicy: "network-only" },
    ],
    awaitRefetchQueries: true,
  });
  const { data, loading, error } = useQuery(GET_ALL_CATEGORIES);
  const canEditCategories =
    useContext(UserContext)?.checkSpendPermission(
      SpendPermissions.programSettingsUpdate
    ) ?? false;

  useEffect(() => {
    if (data?.spendCategories) {
      let categories = [...data?.spendCategories.categories];
      const defaultCat = data?.spendCategories.categories.find(
        (cat: SpendUpsertCategoryInput) => cat.isDefault
      );
      setCategories(categories);
      if (defaultCat) {
        setDefaultCategory(defaultCat);
      }
    }
  }, [data, loading, error]);

  const handleLockToggle = (
    selectedItem: SpendUpsertCategoryInput,
    inputValue: string,
    isHidden: boolean
  ) => {
    let tempCategories: SpendUpsertCategoryInput[] = [];
    const selectedCategory: SpendUpsertCategoryInput = selectedItem;
    for (const c of categories) {
      if (c.id === selectedCategory.id) {
        tempCategories.push({
          ...c,
          name: inputValue,
          isHidden: isHidden ?? c.isHidden,
        });
      } else {
        tempCategories.push(c);
      }
    }
    setCategories(tempCategories);
    handleSave(tempCategories);
  };

  const handleAddBudgetCategory = (type: CategoryTypeEnum) => {
    let tempCategories = [...categories];
    tempCategories.push({
      id: undefined,
      name: "",
      isHidden: false,
      isDefault: false,
      type: type,
    });
    setCategories(tempCategories);
    Display?.setSettingsSaved(false);
  };

  const handleSave = (updatedCategories: SpendUpsertCategoryInput[]) => {
    const newCatArray = [...updatedCategories, defaultCategory];
    categoriesBulkUpsert({
      variables: {
        input: newCatArray.map((cat) => {
          return {
            id: cat.id,
            name: cat.name,
            isHidden: cat.isHidden,
            isDefault: cat.isDefault,
            type: cat.type,
          };
        }),
      },
    });
    Display?.setSettingsSaved(true);
  };

  const handleChange = (
    selectedItem: SpendUpsertCategoryInput,
    inputValue: string,
    isHidden: boolean
  ) => {
    handleLockToggle(selectedItem, inputValue, isHidden);
  };

  return (
    <div className="wrapper">
      <div className="card">
        <p className="text-lg font-medium">Manage Budget Categories</p>
        <p className="text-sm text-gray-500 mt-2">
          Use this form to rename, add, or lock budget categories. Disabled
          budget categories cannot be used to create new budget items, but
          budget items created before the category was locked will still be
          available for budgeting and reconciliation.
        </p>
        <div className="lg:grid grid-cols-2 mt-6">
          <div className="flex flex-col border border-gray-200 rounded-lg p-4 lg:mr-6 lg:p-6">
            <div className="flex">
              <p className="text-base font-medium text-gray-600 pr-2">
                Income Categories
              </p>
              <div className="relative">
                <SnapIcon
                  icon="information-circle-solid"
                  className="justify-end mr-3 cursor-pointer"
                  color="#3B82F6"
                  onClick={incomeTooltipToggle}
                />
                <Tooltip
                  tooltipOpen={incomeIooltipOpen}
                  tooltipToggle={incomeTooltipToggle}
                  className=" w-60"
                >
                  <div>
                    <p className="text-sm font-semibold">
                      Budget Category Detail
                    </p>
                    <p className="text-xs font-semibold text-gray-500 mt-2">
                      Disable/Enable
                    </p>
                    <p className="text-sm">
                      Click disable to prevent a category from being used for
                      new budget items. Any budget items using the disabled
                      category previously will still be available to budget and
                      reconcile. You can enable again categories at any time.{" "}
                    </p>
                    <p className="text-xs font-semibold text-gray-500 mt-3">
                      Category Name
                    </p>
                    <p className="text-sm">
                      Changing the name of a budget category will change the
                      name for past, current, and future budget items.
                    </p>
                  </div>
                </Tooltip>
              </div>
            </div>
            <Divider isVisibleOnMobile />
            <p className="mt-5">Default Category</p>
            <input
              disabled={!canEditCategories}
              className={"border py-2 pl-3 rounded-xl w-full border-gray-200"}
              defaultValue={defaultCategory.name}
              onChange={(e) => {
                const c = {
                  id: defaultCategory.id,
                  name: e.target.value,
                  isHidden: defaultCategory.isHidden,
                  isDefault: defaultCategory.isDefault,
                  type: defaultCategory.type,
                };
                setDefaultCategory(c);
              }}
            />
            <p className="text-sm text-gray-500 mt-1">
              Edit to rename default label.
            </p>
            <div className="flex justify-between mt-6">
              <p>Other Categories</p>
            </div>
            {categories &&
              !loading &&
              incomeCategories.map((incomeItem, idx) => {
                return !incomeItem.isDefault ? (
                  <BudgetCategory
                    key={idx}
                    id={incomeItem.id || ""}
                    text={incomeItem.name}
                    otherCategories={incomeCategoryNames}
                    locked={incomeItem.isHidden}
                    handleChange={(inputValue: string, isHidden: boolean) =>
                      handleChange(incomeItem, inputValue, isHidden)
                    }
                    setIsSaved={Display?.setSettingsSaved}
                    canEditCategories={canEditCategories}
                  />
                ) : null;
              })}
            <p
              className="text-base font-bold text-blue-600 mt-6 cursor-pointer"
              onClick={() => handleAddBudgetCategory(CategoryTypeEnum.Income)}
              hidden={!canEditCategories}
            >
              + Add Income Budget Category
            </p>
          </div>
          <div className="flex flex-col border border-gray-200 rounded-lg mt-4 p-4 lg:mt-0 lg:p-6">
            <div className="flex">
              <p className="text-base font-medium text-gray-600 pr-2">
                Expense Categories
              </p>
              <div className="relative ">
                <SnapIcon
                  icon="information-circle-solid"
                  className="justify-end mr-3 cursor-pointer"
                  color="#3B82F6"
                  onClick={expenseTooltipToggle}
                />
                <Tooltip
                  tooltipOpen={expenseTooltipOpen}
                  tooltipToggle={expenseTooltipToggle}
                  className="w-60"
                >
                  <div>
                    <p className="text-sm font-semibold">
                      Budget Category Detail
                    </p>
                    <p className="text-xs font-semibold text-gray-500 mt-2">
                      Disable/Enable
                    </p>
                    <p className="text-sm">
                      Click disable to prevent a category from being used for
                      new budget items. Any budget items using the disabled
                      category previously will still be available to budget and
                      reconcile. You can enable again categories at any time.{" "}
                    </p>
                    <p className="text-xs font-semibold text-gray-500 mt-3">
                      Category Name
                    </p>
                    <p className="text-sm">
                      Changing the name of a budget category will change the
                      name for past, current, and future budget items.
                    </p>
                  </div>
                </Tooltip>
              </div>
            </div>
            <Divider isVisibleOnMobile />
            {categories &&
              !loading &&
              expenseCategories.map((expenseItem, idx) => {
                return (
                  <BudgetCategory
                    key={idx}
                    id={expenseItem.id || ""}
                    text={expenseItem.name}
                    locked={expenseItem.isHidden}
                    otherCategories={expenseCategoryNames}
                    handleChange={(inputValue: string, isHidden: boolean) =>
                      handleChange(expenseItem, inputValue, isHidden)
                    }
                    setIsSaved={Display?.setSettingsSaved}
                    canEditCategories={canEditCategories}
                  />
                );
              })}
            <p
              className="text-base font-bold text-blue-600 mt-6 cursor-pointer"
              onClick={() => handleAddBudgetCategory(CategoryTypeEnum.Expense)}
              hidden={!canEditCategories}
            >
              + Add Expense Budget Category
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Budgeting;
