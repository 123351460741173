import { Maybe } from "graphql/jsutils/Maybe";
import { getBadgeColor } from "helpers/status-color";
import { SnapBadge, SnapIcon } from "suit";

export enum VerticalValueStyle {
  Text, //0
  ColorText, //1
  ActionLabel, //2
  HasIcon, //3
  Badge, //4
  ReturnText, //5
}

type VerticalLabelValueProps = {
  label: string;
  value: Maybe<string> | Maybe<string>[] | undefined;
  secondValue?: Maybe<string> | Maybe<string>[] | undefined;
  valueStyle?: VerticalValueStyle;
  valueColor?: string;
  hasWarning?: boolean;
  customContainerStyle?: string;
  labelAction?: () => void;
  actionLabel?: string;
  actionLabelClassname?: string;
  icon?: "document-line" | "download-line" | "exclamation-solid";
  iconColor?: string;
  labelStyle?: string;
  className?: string;
};

function VerticalLabelValue({
  label,
  value,
  secondValue,
  valueStyle = VerticalValueStyle.Text,
  valueColor,
  hasWarning = false,
  customContainerStyle,
  labelAction,
  actionLabel,
  actionLabelClassname,
  icon,
  iconColor = "#3B82F6",
  labelStyle = "text-sm font-bold",
  className,
}: VerticalLabelValueProps) {
  // const defaultContainerStyle = "flex w-full lg:text-right lg:w-auto lg:flex-col mb-5 lg:mb-0 lg:border-r lg:pr-4 lg:mr-4"
  const isHidden = hasWarning ? "" : "hidden";
  let textValue: string | null;
  let secondTextValue = "";
  if (value == null || typeof value === "string") {
    textValue = value || null;
    if (typeof secondValue === "string") {
      secondTextValue = secondValue;
    }
  } else {
    textValue = value[0] || null;
  }

  return (
    // <div className={`flex w-full lg:text-right lg:w-auto lg:flex-col mb-5 lg:mb-0 lg:border-r lg:pr-4 lg:mr-4 ${customContainerStyle}`}>
    <div
      className={`flex w-full lg:text-right lg:w-auto lg:flex-col mb-3 lg:mb-0 lg:border-r lg:pr-4 lg:mr-4 ${
        customContainerStyle || ""
      }`}
    >
      <p
        className={`mr-auto lg:mr-0 text-xs ${labelStyle || ""} text-gray-500`}
      >
        {label}
      </p>
      {
        {
          0: (
            <p className={`text-sm ${className}`}>
              <SnapIcon
                className={`py-auto align-bottom ${isHidden}`}
                icon="exclamation-solid"
                size="sm"
                color="#EF4444"
              ></SnapIcon>
              {textValue}
            </p>
          ),
          1: (
            <p className={`text-sm ${valueColor}`}>
              <SnapIcon
                className={`py-auto align-bottom ${isHidden}`}
                icon="exclamation-solid"
                size="sm"
                color="#EF4444"
              ></SnapIcon>
              {textValue}
            </p>
          ),
          2: (
            <p className={`text-base flex ${actionLabelClassname ?? ""}`}>
              {textValue}
              <span
                className={`text-blue-600 font-bold text-base cursor-pointer ${
                  textValue?.length && !actionLabelClassname ? "ml-2" : ""
                }`}
                onClick={labelAction}
              >
                {actionLabel}
              </span>
            </p>
          ),
          3: (
            <p className={`text-sm ${valueColor}`}>
              <SnapIcon
                className={`py-auto align-bottom`}
                icon={icon}
                size="sm"
                color={iconColor}
              ></SnapIcon>
              {textValue}
            </p>
          ),
          4: (
            <div className={`my-auto flex ${className}`}>
              <SnapBadge
                className="text-sm ml-2 capitalize whitespace-nowrap justify-end"
                title="Test"
                color={getBadgeColor(textValue ?? "")}
                size="sm"
              >
                {textValue}
              </SnapBadge>
              {secondValue && (
                <SnapBadge
                  className="text-sm ml-2 capitalize whitespace-nowrap justify-end mt-1"
                  title="Test"
                  color={getBadgeColor(secondTextValue ?? "")}
                  size="sm"
                >
                  {secondTextValue}
                </SnapBadge>
              )}
            </div>
          ),
          5: (
            <p className={`text-sm ${valueColor} italic font-semibold`}>
              <SnapIcon
                className={`py-auto align-bottom ${isHidden}`}
                icon="exclamation-solid"
                size="sm"
              ></SnapIcon>
              {textValue}
            </p>
          ),
          6: <p>{value}</p>,
        }[valueStyle.valueOf()]
      }
    </div>
  );
}

export default VerticalLabelValue;
