import { gql } from "@apollo/client";

export const CREATE_VERIFICATION_TOKEN = gql`
  query SpendUserVerificationToken {
    spendUserVerificationToken {
      verificationToken
    }
  }
`;

export const CREATE_BEARER_TOKEN = gql`
  query SpendUserAccountBearerToken($input: SpendAccountBearerToken) {
    spendUserAccountBearerToken(input: $input) {
      expiresIn
      token
    }
  }
`;

export const GET_INST_BY_ROUTING_NUMBER = gql`
  query SpendGetInstitutionByRoutingNumber($routingNumber: String!) {
    spendGetInstitutionByRoutingNumber(routingNumber: $routingNumber) {
      routingNumber
      name
      address
      isACHSupported
      isWireSupported
    }
  }
`;
