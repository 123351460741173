import { useLazyQuery, useMutation } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import { SpendPaymentSchedule } from "graphql/generated";
import { CREATE_PAYMENT_SCHEDULE } from "graphql/mutations/payment";
import { PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON } from "graphql/queries/payment-schedule";
import { useContext, useEffect, useState } from "react";
import Spinner from "shared-components/spinner";
import { SnapButton, SnapSelectMenu } from "suit";
import ImportCard from "./import-card";
import ImportTable from "./import-table";
import DisplayContext from "context/display-context";
import SeasonContext from "context/season-context";

type ImportInvoicesProps = {
  categories: SnapSelectMenuOption[];
};

function ImportInvoices({ categories }: ImportInvoicesProps) {
  const Program = useContext(ProgramContext);
  const Group = useContext(GroupContext);
  const Season = useContext(SeasonContext);
  const Display = useContext(DisplayContext);

  const CalculateFee = (amountDue: number): number => {
    return (
      amountDue * (Program?.getSpendPercent() || 0) +
      (Program?.getSpendBaseFee() || 0)
    );
  };
  const [paymentSchedules] = useState<SpendPaymentSchedule[]>([]);
  const [selectedPaymentSchedule, setSelectedPaymentSchedule] = useState<
    (SpendPaymentSchedule & { deleted: boolean })[]
  >([]);
  const [
    getPaymentScheduleByGroupId,
    { loading: loadingPaymentSchedule, data: dataPaymentSchedule },
  ] = useLazyQuery(PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON, {
    fetchPolicy: "network-only",
  });
  const [importOptions, setImportOptions] = useState<SnapSelectMenuOption[]>(
    []
  );
  const [savePaymentSchedule, { data, loading }] = useMutation(
    CREATE_PAYMENT_SCHEDULE,
    {
      refetchQueries: [
        "SpendGroupRostersBySeason",
        {
          query: PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON,
          variables: {
            seasonId: Season?.selectedSeason?.id,
            groupIdOrSeasonId: Season?.selectedSeason?.id,
          },
          fetchPolicy: "network-only",
        },
      ],
    }
  );
  useEffect(() => {
    if (Group?.groups?.length && Group?.activeGroup) {
      setImportOptions(
        Group?.groups
          .filter((group) => group.id !== Group?.activeGroup?.id)
          .map((group) => ({
            name: group.name ?? "",
            value: group.id ?? "",
            selected: false,
          }))
      );
      setSelectedPaymentSchedule([]);
    }
  }, [Group?.activeGroup, Group?.groups]);
  useEffect(() => {
    const selectedOption = importOptions.find((option) => option.selected);
    if (selectedOption) {
      const selectedGroup = Group?.groups?.find(
        (group) => group.id === selectedOption.value
      );
      if (selectedGroup) {
        getPaymentScheduleByGroupId({
          variables: { groupIdOrSeasonId: selectedGroup.id },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Group?.groups, importOptions]);
  useEffect(() => {
    if (!loadingPaymentSchedule && dataPaymentSchedule?.spendPaymentSchedules) {
      setSelectedPaymentSchedule(
        dataPaymentSchedule.spendPaymentSchedules.map((x: any) => ({
          ...x,
          deleted: false,
        }))
      );
    }
  }, [loadingPaymentSchedule, dataPaymentSchedule?.spendPaymentSchedules]);
  useEffect(() => {
    if (!loading && data?.spendPaymentScheduleCreate) {
      setSelectedPaymentSchedule([]);
      setImportOptions(
        importOptions.map((option) => ({ ...option, selected: false }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data?.spendPaymentScheduleCreate]);
  const tryToSaveInvoices = (invoices: SpendPaymentSchedule[]) => {
    if (!invoices.length) {
      console.log("No invoices to save");
    }
    invoices.forEach((invoice) => {
      const invoiceInput = {
        amountDue: invoice.amountDue,
        budgetItemId: invoice.budgetItemId,
        description: invoice.description,
        dueDate: invoice.dueDate,
        groupId: Group?.activeGroup?.id,
        isOptional: invoice.isOptional,
        seasonId: Season?.selectedSeason?.id,
        status: "new",
      };
      savePaymentSchedule({
        variables: {
          input: invoiceInput,
        },
      });
    });
  };

  return (
    <>
      {paymentSchedules.length === 0 && (
        <div className="mt-4 lg:p-4 lg:border lg:border-gray-200 border-0 rounded-lg">
          <p className="text-lg lg:font-semibold font-medium mb-4">
            Import Payment Schedule
          </p>
          <SnapSelectMenu
            placeholder="Select a Group"
            className="lg:w-[40%]"
            selectMenuOptions={importOptions}
            onSnap-select-menu-updated={(e) => setImportOptions(e.detail)}
          />
          {importOptions.some((option) => option.selected) && (
            <div>
              {loadingPaymentSchedule && <Spinner />}

              {!loadingPaymentSchedule && selectedPaymentSchedule && (
                <div>
                  {Display?.isDesktop ? (
                    <ImportTable
                      selectedPaymentSchedule={selectedPaymentSchedule}
                      categories={categories}
                      setSelectedPaymentSchedule={setSelectedPaymentSchedule}
                      CalculateFee={CalculateFee}
                    />
                  ) : (
                    <ImportCard
                      selectedPaymentSchedule={selectedPaymentSchedule}
                      categories={categories}
                      setSelectedPaymentSchedule={setSelectedPaymentSchedule}
                      CalculateFee={CalculateFee}
                    />
                  )}
                  <div className="flex justify-end mt-4">
                    <SnapButton
                      variant="primary"
                      fullWidth
                      className="flec self-center mt-6"
                      onClick={() => {
                        tryToSaveInvoices(
                          selectedPaymentSchedule.filter((x) => !x.deleted)
                        );
                      }}
                    >
                      Add Invoices
                    </SnapButton>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ImportInvoices;
