import { V2 as api } from '@snap-mobile/payments-widget-client';
import { useCallback, useContext, useState } from 'react';

import {
  CreatePaymentIntentData,
  StripeEnvironment,
  User,
} from '@snap-mobile/payments-widget-utils';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { usePaymentsWidgetContext } from '../context';
import { StripeExpressCheckoutElementConfirmEvent } from '@stripe/stripe-js';

export const usePaymentsConfirmElement = () => {
  const {
    setError,
    setProcessing,
    paymentData,
    stripeEnvironment,
    paymentConfiguration,
    customer: _customer,
    invalidated,
  } = usePaymentsWidgetContext();
  const stripe = useStripe();
  const elements = useElements();

  const handleError = (...args: any) => {
    // TODO: add more validate logic there
    setError(args);
  };

  const {
    // payment info related
    description,
    externalPaymentId,
    idempotencyKey,
    metadata,
    snapAmount,
    // totalAmount,
    statementDescriptorSuffix,

    // payment method related
    permittedPaymentMethods,
    destination,
    finalDestination,

    // payment setup related
    automatic,
    returnUrl,
    refresh,
  } = paymentData;

  const confirmPayment = useCallback(
    async (
      customer?: User,
      expressEvent?: StripeExpressCheckoutElementConfirmEvent
    ) => {
      if (invalidated) {
        console.warn('data props is invalid');
        return null;
      }
      if (!stripe) {
        console.error('stripe is not assigned');
        return null;
      }
      const cus = customer || _customer;
      if (!cus) {
        console.error('customer is not assigned');
        return;
      }
      if (!elements) {
        console.error('elements is not assigned');
        return null;
      }
      setProcessing(true);
      const submitExpressEvent = () =>
        expressEvent?.paymentFailed({ reason: 'fail' });

      try {
        const data: CreatePaymentIntentData = {
          automaticPaymentMethods: { enabled: automatic ?? true },
          customer: cus!.customerId,
          description,
          destination: paymentConfiguration?.stripeAccountId ?? destination,
          externalPaymentId,
          finalDestination,
          idempotencyKey: idempotencyKey || '',
          metadata: metadata || {},
          permittedPaymentMethods,
          statementDescriptorSuffix: statementDescriptorSuffix || 'SnapRaise',
          setupFutureUsage: 'on_session',
          snapAmount: snapAmount,
          totalAmount: paymentData.totalAmount,
        };

        const pi = await api.createPaymentIntent(data, { stripeEnvironment });
        if (pi.message) {
          setError(pi.message);
          setProcessing(false);
          return;
        }
        const redirect = refresh === false ? 'if_required' : undefined;
        const confirmData = {
          elements: elements,
          clientSecret: pi.client_secret,
          confirmParams: { return_url: returnUrl ?? window.location.href },
          redirect: redirect as any,
        };
        const { error, paymentIntent } = await stripe.confirmPayment(
          confirmData
        );
        if (error) {
          setError(error?.message || 'Error confirming payment.');
          setProcessing(false);
          return;
        }
        setProcessing(false);
        return paymentIntent;
      } catch (e: any) {
        submitExpressEvent();
        setProcessing(false);
        handleError(e);
      }
    },
    [_customer, stripe, paymentData]
  );

  return {
    confirmPayment,
  };
};
