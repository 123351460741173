import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { StepProps } from ".";
import { SnapBadge, SnapSelectMenu } from "suit";
import { getBadgeColor } from "helpers/status-color";
import HorizontalLabelValue from "shared-components/horizontal-label-value";
import VerticalLabelValue from "shared-components/vertical-label-value";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import { useEffect, useState } from "react";
import { MapAndCalcSum } from "helpers/map-and-reduce";
import TableRowLabelValue from "shared-components/table-row-label-value";

function StepFive({
  selectedDetails,
  budgetItems,
  handleBudgetItemChange,
}: StepProps & {
  budgetItems: SnapSelectMenuOption[];
  handleBudgetItemChange: (index: number, budgetId: string) => void;
}) {
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [amountDistributed, setAmountDistributed] = useState<number>(0);
  const [note, setNote] = useState("-");
  useEffect(() => {
    const amountDistributed = MapAndCalcSum(
      selectedDetails.distribution ?? [],
      "amount"
    );
    setAmountDistributed(selectedDetails.transaction?.totalReconciled ?? 0);
    setRemainingBalance(
      Math.round((selectedDetails.transaction?.amount ?? 0) - amountDistributed)
    );
    if (
      selectedDetails.transaction?.transactionNote &&
      selectedDetails.transaction?.transactionNote.length
    ) {
      setNote(selectedDetails.transaction?.transactionNote);
    }
  }, [selectedDetails]);
  return (
    <div className="mt-4">
      <p className="text-base font-medium">Review & Apply Payment</p>
      <p className="text-xs text-gray-500">
        Review and confirm information is correct before choosing “Reconcile as”
        option. Select Back as needed to make changes.
      </p>
      <div className="mt-4 ml-4 flex">
        <table className="lg:w-[30%] w-full">
          <tbody>
            <TableRowLabelValue
              label={"Transaction Amount"}
              value={FormatMoney(selectedDetails.transaction?.amount ?? 0)}
            />
            {selectedDetails.transaction?.transactionType?.includes(
              "check"
            ) && (
              <TableRowLabelValue
                label={"Check Number"}
                value={
                  selectedDetails.transaction.metadata?.tags?.spendSourceId
                }
              />
            )}
            <TableRowLabelValue
              label={"Payment Date"}
              value={FormatDate(
                selectedDetails.transaction?.effective ?? "",
                DateFormatSupported.Numbers
              )}
            />
            <TableRowLabelValue
              label={"Description"}
              value={
                selectedDetails?.transaction?.metadata?.summary ||
                selectedDetails?.transaction?.metadata?.description
              }
            />
            <TableRowLabelValue label={"Note"} value={note} />
            {selectedDetails.transaction?.applicableAmount && (
              <TableRowLabelValue
                label={"Previously Applied Balance"}
                value={FormatMoney(amountDistributed)}
              />
            )}
            {remainingBalance !== 0 && (
              <TableRowLabelValue
                label={"Remaining Balance"}
                value={FormatMoney(remainingBalance)}
              />
            )}
          </tbody>
        </table>
      </div>
      {selectedDetails.distribution
        ?.filter((i) => i.amount !== 0)
        .map((i, idx) => {
          let status = selectedDetails.participant?.joinedAt
            ? "Signed Up"
            : "Not Signed Up";
          let menuItems = budgetItems.map((budgetItem) => {
            return {
              ...budgetItem,
              selected: budgetItem.value === i.invoice.budgetItemId,
            };
          });
          return (
            <div key={i.invoice.id + "-container"}>
              <div
                key={i.invoice.id}
                className="border rounded-lg p-4 mt-4 hidden lg:flex"
              >
                <div className="w-full">
                  <div className="flex gap-4">
                    <p>{selectedDetails.participant?.roster?.name}</p>
                    <div className="flex items-center">
                      <SnapBadge color={getBadgeColor(status)}>
                        {status}
                      </SnapBadge>
                    </div>
                  </div>
                  <HorizontalLabelValue
                    label={"Group"}
                    value={selectedDetails.team?.name}
                  />
                  <HorizontalLabelValue
                    label={"Season"}
                    value={
                      selectedDetails.season?.name ||
                      `${selectedDetails.season?.startDateAt} - ${selectedDetails.season?.endDateAt}`
                    }
                  />
                  <HorizontalLabelValue
                    label={"Description"}
                    value={i.invoice.description}
                  />
                </div>
                <div className="flex items-center w-full">
                  <VerticalLabelValue
                    label={"Due Date"}
                    value={FormatDate(i.invoice.dueDate ?? "")}
                  />
                  <VerticalLabelValue
                    label={"Amount Applied"}
                    value={FormatMoney(i.amount)}
                  />
                  <SnapSelectMenu
                    label="Reconcile as"
                    className="w-full"
                    selectMenuOptions={menuItems}
                    placeholder="Select a budget item"
                    onSnap-select-menu-updated={(e) => {
                      const budgetItemId = e.detail.find(
                        (d) => d.selected
                      )?.value;
                      handleBudgetItemChange(idx, budgetItemId!);
                    }}
                  />
                </div>
              </div>
              <div
                key={`${i.invoice.id}-md`}
                className="border rounded-lg p-4 mt-4 block lg:hidden"
              >
                <p className="text-md font-semibold text-gray-800 w-100 mb-1">
                  {selectedDetails.participant?.roster?.name}
                </p>
                <p className="text-sm font-semibold text-gray-500 mb-1">
                  <span className="font-normal">Group</span>&nbsp;
                  <span className="font-medium text-gray-800">
                    {selectedDetails.team?.name}
                  </span>
                </p>
                <p className="text-sm font-semibold text-gray-500 mb-1">
                  <span className="font-normal">Season</span>&nbsp;
                  <span className="font-medium text-gray-800">
                    {selectedDetails.season?.name}
                  </span>
                </p>
                <p className="text-sm font-semibold text-gray-500 mb-1">
                  <span className="font-normal">Description</span>&nbsp;
                  <span className="font-medium text-gray-800">
                    {i.invoice.description}
                  </span>
                </p>
                <hr className="my-2"></hr>
                <div className="flex w-100 justify-between">
                  <div className="text-gray-500 font-bold text-xs align-middle">
                    Due Date
                  </div>
                  <div className="text-gray-800 font-semibold text-sm">
                    {FormatDate(i.invoice.dueDate ?? "")}
                  </div>
                </div>
                <div className="flex w-100 justify-between mt-2">
                  <div className="text-gray-500 font-bold text-xs align-middle">
                    Amount Applied
                  </div>
                  <div>{FormatMoney(i.amount)}</div>
                </div>
                <hr className="my-2"></hr>
                <div>
                  <SnapSelectMenu
                    label="Reconcile as"
                    className="w-full"
                    selectMenuOptions={menuItems}
                    placeholder="Select a budget item"
                  />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default StepFive;
