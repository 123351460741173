import {
  Maybe,
  SpendBankTransaction,
  SpendMemoInvoice,
} from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { getTransactionTitle, getTransactionType } from "helpers/transaction";
import DataCard from "shared-components/data-card";
import { SpinnerContainer } from "shared-components/spinner";
import { VerticalValueStyle } from "shared-components/vertical-label-value";
import { LabelValueObject } from "types/label-value-object";

type CardsProps = {
  listItems: SpendBankTransaction[] | undefined;
  setModalDataAndShow: (transaction: SpendBankTransaction) => void;
  handleToggleInvoiceModal: (
    item: Maybe<SpendMemoInvoice> | undefined,
    creditApplied: number
  ) => void;
  loadingTransactions?: boolean;
  exporting?: boolean;
};

function Cards({
  listItems,
  setModalDataAndShow,
  handleToggleInvoiceModal,
  loadingTransactions,
  exporting,
}: CardsProps) {
  const prepLeftData = (d: SpendBankTransaction | any) => {
    const leftLabels: LabelValueObject[] = [];

    leftLabels.push({
      key: "Description",
      value: getTransactionTitle(d),
      customContainerStyle: "flex-col",
      className: "ml-0 text-blue-600",
      labelAction: () => {
        if (d.transactionType === "credit_memo") {
          handleToggleInvoiceModal(d.memo.invoice, d.creditMemo?.creditAmount);
        } else {
          setModalDataAndShow(d);
        }
      },
    });
    leftLabels.push({
      key: "",
      value: d.transactionNote ?? "",
      customContainerStyle: "flex-col mt-1",
      className: `ml-0 text-xs text-gray-500 ${
        (d.transactionNote === "" || !d.transactionNote) && "hidden"
      }`,
    });
    leftLabels.push({
      key: "Type",
      value: getTransactionType(d) || "",
      customContainerStyle: "flex-col mt-2",
      className: "ml-0 capitalize",
    });
    if (d.transactionType !== "credit_memo") {
      leftLabels.push({
        key: "Method",
        value:
          d.processor === "unit"
            ? "ACH"
            : d.processor === "stripe"
            ? "Card"
            : "Unknown" || "",
        customContainerStyle: "flex-col mt-2",
        className: "ml-0",
      });
    }

    return leftLabels;
  };

  const prepRightData = (d: SpendBankTransaction | any) => {
    const rightLabels: LabelValueObject[] = [];

    if (d.transactionType !== "credit_memo") {
      rightLabels.push({
        key: "Status",
        value: d.status.toLowerCase() || "Settled",
        valueStyle: VerticalValueStyle.Badge,
      });
    }

    rightLabels.push({
      key: "Gross",
      value: FormatMoney(d.amount || 0),
    });
    rightLabels.push({
      key: "Net",
      value: FormatMoney(d.amount - d.snapAmount || 0),
    });
    return rightLabels;
  };

  return (
    <>
      {!loadingTransactions && !exporting ? (
        listItems &&
        listItems.map((transaction: SpendBankTransaction, idx: number) => {
          return (
            <DataCard
              key={idx}
              title={FormatDate(
                transaction.effective || "",
                DateFormatSupported.Numbers
              )}
              kvLeft={prepLeftData(transaction)}
              kvRight={prepRightData(transaction)}
              action={0}
              className="lg:hidden"
            />
          );
        })
      ) : (
        <SpinnerContainer
          loading={(loadingTransactions || exporting) ?? false}
        />
      )}
    </>
  );
}

export default Cards;
