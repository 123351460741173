import ProgramContext from "context/program-context";
import { useContext, useEffect, useState } from "react";
import { SnapIcon } from "suit";

function SignUpLink() {
  const Program = useContext(ProgramContext);
  const [https] = useState("https://");
  const [signUpLink, setSignUpLink] = useState("");
  const url = Program?.settings?.signUpLink;
  useEffect(() => {
    let urlArr = url && url.split("https://");
    if (urlArr && urlArr?.length > 1) {
      setSignUpLink(urlArr[1]);
    } else {
      setSignUpLink(url ?? "");
    }
  }, [url]);

  return (
    <>
      <div className="flex flex-row mt-4">
        <p className="text-gray-500 text-sm bg-gray-100 py-2 px-3 border border-gray-300 rounded-l-lg">
          {https}
        </p>
        <p className="lg:w-[45%] whitespace-nowrap text-ellipsis overflow-hidden text-gray-500 text-sm bg-gray-100 py-2 px-3 border border-x-0 lg:rounded-none border-gray-300">
          {signUpLink}
        </p>
        <SnapIcon
          icon="lock-closed-solid"
          color="gray"
          className="bg-gray-100 border border-gray-300 border-l-0 lg:rounded-none rounded-r-lg pr-4"
          size="sm"
        />
        <div className="lg:flex hidden">
          <div
            className="flex bg-gray-100 px-4 border border-gray-300 border-l-0 text-blue-600 cursor-pointer"
            onClick={() =>
              navigator.clipboard.writeText(`${https}${signUpLink}`)
            }
          >
            <SnapIcon size="sm" icon="duplicate-solid" />
            <p className="self-center">Copy link</p>
          </div>
          <a
            href={`${https}${signUpLink}`}
            rel="noreferrer"
            target="_blank"
            className="flex bg-gray-100 px-4 border border-gray-300 border-l-0 text-blue-600 rounded-r-lg"
          >
            <SnapIcon size="sm" icon="link-solid" />
            <p className="self-center">Open URL</p>
          </a>
        </div>
      </div>
      <div className="lg:hidden grid grid-cols-2 mt-4">
        <div
          className="flex justify-center bg-gray-100 px-4 border border-gray-300 text-blue-600 rounded-l-xl py-1"
          onClick={() => navigator.clipboard.writeText(`${https}${signUpLink}`)}
        >
          <SnapIcon size="sm" icon="duplicate-solid" />
          <p>Copy link</p>
        </div>
        <a
          href={`${https}${signUpLink}`}
          rel="noreferrer"
          target="_blank"
          className="flex justify-center bg-gray-100 px-4 border border-gray-300 border-l-0 text-blue-600 rounded-r-xl py-1"
        >
          <SnapIcon size="sm" icon="link-solid" />
          <p>Open URL</p>
        </a>
      </div>
    </>
  );
}

export default SignUpLink;
