import CustomModal, { BtnState } from "shared-components/modal";
import { SnapAlert, SnapAlertTitle, SnapInput } from "suit";
import TableRowLabelValue from "shared-components/table-row-label-value";
import { useContext, useEffect, useState } from "react";
import ToastContext from "context/toast-context";
import { ParticipantDetailType } from "..";
import { useSpendUpdateGroupRosterMutation } from "graphql/generated";
import { emptyStringCheck } from "helpers/empty-string-check";
import { ValidateEmail } from "helpers/validation";

type EditParticipantProps = {
  isOpen: boolean;
  toggle: () => void;
  participantDetails: ParticipantDetailType;
  hasPendingInvite: boolean;
};
function EditParticipant({
  isOpen,
  toggle,
  participantDetails,
  hasPendingInvite,
}: EditParticipantProps) {
  const Toast = useContext(ToastContext);
  const [btnState, setBtnState] = useState(BtnState.BASE);
  const [name, setName] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [updateRoster, { data, loading, error }] =
    useSpendUpdateGroupRosterMutation({
      refetchQueries: ["GetParticipantsDetails"],
    });

  useEffect(() => {
    if (!loading && data) {
      Toast?.setToast({
        message: "Roster saved successfuly",
        type: "success",
      });
      toggle();
    } else if (!loading && error) {
      Toast?.setToast({
        message: "Error saving roster",
        type: "danger",
      });
    }
    setBtnState(BtnState.BASE);
    // eslint-disable-next-line
  }, [loading, data, error]);

  const handleSave = () => {
    setBtnState(BtnState.DISABLED);
    if (!participantDetails?.rosterId) {
      Toast?.setToast({
        message: "Missing participant roster id",
        type: "danger",
      });
      setBtnState(BtnState.BASE);
      return;
    }
    if (emptyStringCheck(email) && emptyStringCheck(name)) {
      Toast?.setToast({
        message: "Name or email field is required",
        type: "danger",
      });
      setBtnState(BtnState.BASE);
      return;
    }

    if (!emptyStringCheck(email) && !ValidateEmail(email!)) {
      Toast?.setToast({
        message: "A valid email address is required.",
        type: "danger",
      });
      setBtnState(BtnState.BASE);
      return;
    }

    updateRoster({
      variables: {
        input: {
          rosterId: participantDetails.rosterId,
          email,
          name,
        },
      },
    });
  };
  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      title={"Edit Roster"}
      btn1={{
        text: "Save Changes",
        btnStyle: "primary",
        btnState,
        onClick: () => {
          handleSave();
        },
      }}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: () => {
          toggle();
          setBtnState(BtnState.BASE);
        },
      }}
      customStyle="lg:mt-10 lg:w-[700px]"
    >
      <div className="modal-card mb-5">
        <SnapAlert type="info" className="mb-3 lg:mb-2">
          <SnapAlertTitle>
            <p>
              Changes made will be applied to all non-accepted and future
              invites for this participant.
            </p>
          </SnapAlertTitle>
        </SnapAlert>
        <table className="w-full lg:w-auto">
          <tbody>
            <TableRowLabelValue
              key={"parti-name"}
              label={"Name"}
              value={participantDetails?.participantName}
              className={`lg:ml-24 mt-2`}
            />
            <TableRowLabelValue
              key={"parti-email"}
              label={"Email"}
              value={participantDetails?.email}
              className={`lg:ml-24 mt-2`}
            />
          </tbody>
        </table>
        <div className="lg:w-96 mt-4">
          <SnapInput
            _id="email-input"
            _type="email"
            placeholder={""}
            onSnap-input-change={(e) => setName(e.detail.target.value || "")}
            label="New Name"
          />
        </div>
        <div className="lg:w-96 mt-4">
          <SnapInput
            _id="email-input"
            _type="email"
            placeholder={""}
            onSnap-input-change={(e) => setEmail(e.detail.target.value || "")}
            label="New Email"
            disabled={!hasPendingInvite}
          />
        </div>
      </div>
    </CustomModal>
  );
}

export default EditParticipant;
