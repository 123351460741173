export const ITEMS_PER_PAGE = 10;

export const LATEST_TRANSACTION_NUMBER = 10;

export const TOAST_TIMEOUT = 5000;
export const DEBOUNCE_TIME = 500;

export const PROGRAM = "program";
export const GROUP = "group";
export const USER = "user";
