import UserContext from "context/user-context";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { getBadgeColor } from "helpers/status-color";
import useModal from "hooks/use-modal";
import { useContext, useState } from "react";
import DataCard from "shared-components/data-card";
import { HorizontalValueStyle } from "shared-components/horizontal-label-value";
import { VerticalValueStyle } from "shared-components/vertical-label-value";
import { SnapBadge, SnapTable } from "suit";
import { LabelValueObject } from "types/label-value-object";
import { SpendPermissions } from "types/roles-permissions";
import SendEmail from "./send-email";
import GroupContext from "context/group-context";
import { useNavigate } from "react-router-dom";
import InvoiceDetails from "shared-components/modal/invoice-details";
import { TRLVItems } from "pages/reporting/invoices/invoice-list-item";
import { TableValueStyle } from "shared-components/table-row-label-value";
import { MapAndCalcSum } from "helpers/map-and-reduce";
import { SpinnerContainer } from "shared-components/spinner";
import DisplayContext from "context/display-context";
import {
  Maybe,
  PastDueSortOptions,
  SpendPastDueInvoice,
  SpendPastDueSort,
} from "graphql/generated";
import SortIcon from "shared-components/table/sort-icon";
import SeasonContext from "context/season-context";

type PastDueListItemsProps = {
  listItems: Maybe<SpendPastDueInvoice>[];
  loading: boolean;
  handleSort: (selectedItems: PastDueSortOptions) => void;
  sort: SpendPastDueSort | undefined;
  icon: "ascending-solid" | "descending-solid" | "selector-solid";
};

const PastDueListItemsV2 = ({
  listItems,
  loading,
  handleSort,
  sort,
}: PastDueListItemsProps) => {
  const Display = useContext(DisplayContext);
  const Group = useContext(GroupContext);
  const Season = useContext(SeasonContext);
  const canSendEmail = useContext(UserContext)?.checkSpendPermission(
    SpendPermissions.groupAdminsUpdate
  );

  const navigate = useNavigate();

  const { toggle: sendEmailToggle, isOpen: sendEmailOpen } = useModal();
  const { toggle: invoiceDetailsToggle, isOpen: invoiceDetailsOpen } =
    useModal();
  const [selectedInvoice, setSelectedInvoice] = useState<
    Maybe<SpendPastDueInvoice> | undefined
  >(undefined);

  const [modalData, setModalData] = useState<TRLVItems[]>([]);

  const setModalDataAndShow = (invoice: Maybe<SpendPastDueInvoice>) => {
    setSelectedInvoice(invoice);
    sendEmailToggle();
  };

  const prepLeftData = (d: Maybe<SpendPastDueInvoice>) => {
    const playerName = `${d?.name}`;
    const leftLabels: LabelValueObject[] = [];

    leftLabels.push({
      key: "Participant",
      value: playerName,
      valueStyle: HorizontalValueStyle.Link,
      className: "ml-0",
      customContainerStyle: "flex flex-col mb-1",
      labelAction: () => {
        handleNavigateToDetailRoster(d?.rosterId ?? "");
      },
    });
    leftLabels.push({
      key: "Description",
      value: d?.description || "",
      valueStyle: HorizontalValueStyle.Link,
      customContainerStyle: "flex flex-col",
      className: "ml-0",
      labelAction: () => {
        handleToggleModal(d);
      },
    });

    return leftLabels;
  };

  const prepRightData = (d: Maybe<SpendPastDueInvoice>) => {
    const rightLabels: LabelValueObject[] = [];
    rightLabels.push({
      key: "Status",
      value: d?.guardianStatus?.replace("_", "") ?? "",
      valueStyle: VerticalValueStyle.Badge,
    });
    rightLabels.push({
      key: "Due Date",
      value: FormatDate(d?.dueDate || "", DateFormatSupported.Numbers),
    });
    rightLabels.push({
      key: "Amount",
      value: FormatMoney(d?.amount || 0),
      customContainerStyle:
        "mb-0 lg:border-b-0 lg:pb-0 border-b border-b-gray-200 pb-2",
    });

    return rightLabels;
  };

  const handleNavGroup = (groupId: string, seasonId: string) => {
    const foundGroup = Group?.groups.find((group) => group.id === groupId);
    if (foundGroup) {
      Season?.handleSelectedSeason({
        seasonId: seasonId,
        seasonsToSet: foundGroup.seasons,
      });
    }

    Group?.navigateGroupPage("collections", groupId);
  };

  const handleNavigateToDetailRoster = (rosterId: string) => {
    navigate(`/people/participants/participant-details/${rosterId}`);
  };

  const handleToggleModal = (item: Maybe<SpendPastDueInvoice>) => {
    let items: TRLVItems[] = [];
    const playerName = `${item?.name}`;
    items.push({
      label: "Description",
      value: item?.description || "",
    });
    items.push({
      label: "Participant",
      value: playerName,
      valueStyle: TableValueStyle.LINK,
      onClick: () => {
        handleNavigateToDetailRoster(item?.rosterId ?? "");
      },
    });
    items.push({
      label: "Group",
      value: item?.groupName || "",
      valueStyle: TableValueStyle.LINK,
      onClick: () => {
        handleNavGroup(item?.groupId ?? "", item?.seasonId ?? "");
      },
    });
    items.push({
      label: "Status",
      value: item?.status?.replace("_", " ") ?? "",
      valueStyle: TableValueStyle.BADGE,
    });
    items.push({
      label: "Due Date",
      value: FormatDate(item?.dueDate || "", DateFormatSupported.Numbers),
    });
    items.push({
      label: "Season",
      value: item?.seasonName || "",
    });
    items.push({
      label: "Invoice Amount",
      value: FormatMoney(item?.amount || 0),
    });
    items.push({
      label: "Balance Due",
      value: FormatMoney(item?.amount || 0),
    });
    const discountAndCredits =
      (item?.discountAmount || 0) +
      MapAndCalcSum(item?.creditMemos ?? [], "creditApplied");
    if (discountAndCredits > 0) {
      items.push({
        label: "Credits & Discounts",
        value: FormatMoney(discountAndCredits),
      });
    }
    setModalData(items);
    invoiceDetailsToggle();
  };

  return (
    <>
      {listItems && listItems?.length === 0 ? (
        <div>
          <p>No Past Due Invoices</p>
        </div>
      ) : (
        <>
          {Display?.isDesktop && (
            <SnapTable>
              <table className="celled ui hidden lg:table mt-0">
                <thead>
                  <tr>
                    <th className="w-[15%]">
                      Groups
                      <SortIcon
                        field={"groups"}
                        currentSort={{
                          ...sort,
                          field: sort?.sortBy ?? "",
                        }}
                        toggleSort={() => handleSort(PastDueSortOptions.Groups)}
                      />
                    </th>
                    <th className="w-[15%]">
                      Participants
                      <SortIcon
                        field={"participantName"}
                        currentSort={{
                          ...sort,
                          field: sort?.sortBy ?? "",
                        }}
                        toggleSort={() =>
                          handleSort(PastDueSortOptions.ParticipantName)
                        }
                      />
                    </th>
                    <th className="w-[20%]">Description</th>
                    <th>
                      Status
                      <SortIcon
                        field={"parentStatus"}
                        currentSort={{
                          ...sort,
                          field: sort?.sortBy ?? "",
                        }}
                        toggleSort={() =>
                          handleSort(PastDueSortOptions.ParentStatus)
                        }
                      />
                    </th>
                    <th>
                      Due Date
                      <SortIcon
                        field={"dueDate"}
                        currentSort={{
                          ...sort,
                          field: sort?.sortBy ?? "",
                        }}
                        toggleSort={() =>
                          handleSort(PastDueSortOptions.DueDate)
                        }
                      />
                    </th>
                    <th>
                      Amount
                      <SortIcon
                        field={"amount"}
                        currentSort={{
                          ...sort,
                          field: sort?.sortBy ?? "",
                        }}
                        toggleSort={() => handleSort(PastDueSortOptions.Amount)}
                      />
                    </th>
                    {canSendEmail && (
                      <th className="w-[10%]">{/* Action */}</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {!loading ? (
                    listItems.map((item: Maybe<SpendPastDueInvoice>, idx) => {
                      let status =
                        item?.guardianStatus?.replace("_", " ") ?? "";
                      return (
                        <tr key={`${item?.rosterId}${idx}`}>
                          <td>
                            <p
                              className="text-sm text-blue-600 cursor-pointer py-4"
                              onClick={() => {
                                handleNavGroup(
                                  item?.groupId ?? "",
                                  item?.seasonId ?? ""
                                );
                              }}
                            >
                              {item?.groupName}
                            </p>
                          </td>
                          <td>
                            <p
                              className="text-sm text-blue-600 cursor-pointer"
                              onClick={() => {
                                handleNavigateToDetailRoster(
                                  item?.rosterId ?? ""
                                );
                              }}
                            >
                              {item?.name}
                            </p>
                          </td>
                          <td>
                            <p
                              className="text-sm text-blue-600 cursor-pointer"
                              onClick={() => {
                                handleToggleModal(item);
                              }}
                            >
                              {item?.description}
                            </p>
                          </td>

                          <td>
                            <SnapBadge
                              color={getBadgeColor(status)}
                              className="capitalize"
                            >
                              {status}
                            </SnapBadge>
                          </td>
                          <td>
                            <p>
                              {FormatDate(
                                item?.dueDate!,
                                DateFormatSupported.Numbers
                              )}
                            </p>
                          </td>
                          <td>
                            <p>{FormatMoney(item?.amount)}</p>
                          </td>
                          {canSendEmail && (
                            <td>
                              <p
                                className="text-sm text-blue-600 font-bold cursor-pointer"
                                onClick={() => setModalDataAndShow(item)}
                              >
                                Email Parent
                              </p>
                            </td>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <SpinnerContainer loading={loading} inTable colSpan={6} />
                  )}
                </tbody>
              </table>
            </SnapTable>
          )}
          {/* SM */}
          {Display?.isMobile && !loading ? (
            listItems.map((item: Maybe<SpendPastDueInvoice>, idx: number) => {
              return (
                <DataCard
                  className="lg:hidden"
                  key={`${item?.rosterId}${idx}`}
                  title={item?.groupName || ""}
                  titleAction={() =>
                    handleNavGroup(item?.groupId ?? "", item?.seasonId ?? "")
                  }
                  kvLeft={prepLeftData(item)}
                  kvRight={prepRightData(item)}
                  action={canSendEmail ? 3 : 0}
                  actionClick={() => setModalDataAndShow(item)}
                />
              );
            })
          ) : (
            <SpinnerContainer loading={loading} />
          )}
        </>
      )}
      <SendEmail
        isOpen={sendEmailOpen}
        toggle={sendEmailToggle}
        selectedInvoice={selectedInvoice}
      />
      <InvoiceDetails
        isOpen={invoiceDetailsOpen}
        toggle={invoiceDetailsToggle}
        modalData={modalData}
      />
    </>
  );
};
export default PastDueListItemsV2;
