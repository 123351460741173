import { gql } from "@apollo/client";

export const CREATE_USER_INVITE = gql`
  mutation SpendUserInviteCreate($input: SpendInviteInput!) {
    spendUserInviteCreate(input: $input) {
      id
    }
  }
`;

export const RESEND_USER_INVITE = gql`
  mutation SpendUserInviteResend($input: SpendInviteResendInput!) {
    spendUserInviteResend(input: $input) {
      id
    }
  }
`;

export const UPDATE_USER_INVITE = gql`
  mutation SpendUserInviteUpdate($spendUserInviteUpdateId: String!) {
    spendUserInviteUpdate(id: $spendUserInviteUpdateId) {
      id
    }
  }
`;

export const DELETE_USER_INVITE = gql`
  mutation SpendUserInviteDelete($spendUserInviteDeleteId: String!) {
    spendUserInviteDelete(id: $spendUserInviteDeleteId) {
      id
    }
  }
`;

export const ARCHIVE_USER_INVITE = gql`
  mutation SpendUserInviteArchive($spendUserInviteArchiveId: String!) {
    spendUserInviteArchive(id: $spendUserInviteArchiveId) {
      id
    }
  }
`;

export const UPDATE_USER_INVITE_STATUS = gql`
  mutation SpendUserInviteStatusUpdate(
    $spendUserInviteStatusUpdateId: String!
    $status: String!
  ) {
    spendUserInviteStatusUpdate(
      id: $spendUserInviteStatusUpdateId
      status: $status
    ) {
      id
    }
  }
`;

export const DISMISS_USER_INVITE = gql`
  mutation SpendUserInviteDismiss($inviteId: String!) {
    spendUserInviteDismiss(inviteId: $inviteId) {
      inviteId
    }
  }
`;
