import {
  PaymentsApiCustomerPaymentMethod,
  SpendBankAccount,
  SpendGroup,
  SpendInvoice,
  SpendSettings,
} from "graphql/generated";
import type { PaymentTimingValue } from "../../../types/invoice";
import ConfirmPayNowPayments from "./confirm-paynow-payments";
import ConfirmAutopayPayments from "./confirm-autopay-payments";
import ConfirmManuallyPayments from "./confirm-manually-payments";

type DynamicAuthorizePaymentsProps = {
  settings: SpendSettings;
  group: SpendGroup;
  invoices: SpendInvoice[];
  playerName: string;
  paymentTiming: PaymentTimingValue | undefined;
  selectedPaymentTypeOption: "Pay by: Card" | "Pay by: Bank" | undefined;
  total: number;
  setTotal: React.Dispatch<React.SetStateAction<number>>;
  agreed: boolean;
  setAgreed: React.Dispatch<React.SetStateAction<boolean>>;
  invoicesToPay: SpendInvoice[];
  bankAccount?: SpendBankAccount;
  paymentMethods: PaymentsApiCustomerPaymentMethod[];
  isAuthorized: boolean;
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
  dotState: number;
  dotMarker: number;
};

function DynamicAuthorizePayments(args: DynamicAuthorizePaymentsProps) {
  const PaymentTimingToComponent: Record<
    PaymentTimingValue,
    (...args: any) => any
  > = {
    All: ConfirmPayNowPayments,
    AutoPay: ConfirmAutopayPayments,
    Manually: ConfirmManuallyPayments,
  };

  const ConfirmPayments =
    PaymentTimingToComponent[args.paymentTiming || "AutoPay"];

  return <ConfirmPayments {...args} />;
}

export default DynamicAuthorizePayments;
