import { SpendDebitCard } from "graphql/generated";

type filterDebitCardProps = {
  cards: SpendDebitCard[];
  setDebitCards: React.Dispatch<React.SetStateAction<SpendDebitCard[]>>;
  setInactiveCards?: React.Dispatch<React.SetStateAction<SpendDebitCard[]>>;
  setActiveCards?: React.Dispatch<React.SetStateAction<SpendDebitCard[]>>;
};

export const filterDebitCards = (
  cards: filterDebitCardProps["cards"],
  setDebitCards: filterDebitCardProps["setDebitCards"],
  setInactiveCards?: filterDebitCardProps["setActiveCards"],
  setActiveCards?: filterDebitCardProps["setInactiveCards"]
) => {
  let active: SpendDebitCard[] = [];
  let inactive: SpendDebitCard[] = [];

  if (cards && cards.length) {
    cards.forEach((card: SpendDebitCard) => {
      switch (card.status) {
        case "Inactive":
        case "Frozen":
          inactive.push(card);
          break;
        case "Active":
          active.push(card);
          break;
      }
    });
  }
  setDebitCards([...active, ...inactive]);
  if (setInactiveCards && setActiveCards) {
    setInactiveCards(inactive);
    setActiveCards(active);
  }
};
