import { SpendRosterChecked, SpendRosterResend } from "types/group-roster";
import { InvitePlus } from "types/invite";

export const bulkActionsMapper = (users: SpendRosterChecked[]) => {
  let mappedItems: SpendRosterResend[] = [];
  users.forEach((item) =>
    item.groupRosters?.forEach((user) => {
      if (
        item.email &&
        user?.groupId &&
        user?.seasonId &&
        user?.userId &&
        user?.group?.name &&
        user?.id
      ) {
        mappedItems.push({
          id: user?.invite?.id ?? undefined,
          status: user.invite?.status ?? "",
          email: item.email,
          groupId: user.groupId,
          seasonId: user.seasonId,
          userId: user.userId,
          groupName: user.group.name,
          groupRosterId: user.id,
        });
      }
    })
  );
  return mappedItems;
};

export const bulkActionsInviteMapper = (users: InvitePlus[]) => {
  return users.map((item) => {
    return {
      id: item.id,
      status: item.status ?? "",
      email: item.email ?? "",
      userId: item.userId ?? "",
      groupId: item.groupId ?? "",
      seasonId: item.seasonId ?? "",
      groupName: item.group?.name ?? "",
      groupRosterId: "",
    };
  });
};
