import Divider from "shared-components/divider";
import CustomModal, { BtnType } from "shared-components/modal";
import { StopPaymentFormType } from "./ParentDashboard";
import { useEffect, useState } from "react";
import { SnapInput } from "suit";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { useSpendInvoicePaymentDeauthorizeMutation } from "graphql/generated";
import { GET_INVOICES_WITH_FILTER } from "graphql/queries/invoices";
import UserBankMethod from "../../../shared-components/banking/user-bank-method";
import UserCardMethod from "../../../shared-components/banking/user-card-method";
import ToastMessage from "shared-components/toast-message";
import useToast from "hooks/use-toast";
import { GET_GUARDIAN_HIGHLIGHT_2 } from "graphql/queries/user";
import TableRowLabelValue from "shared-components/table-row-label-value";

type ParentStopPaymentModalProps = {
  isOpen: boolean;
  toggle: () => void;
  stopPaymentForm: StopPaymentFormType;
  setStopPaymentForm: React.Dispatch<React.SetStateAction<StopPaymentFormType>>;
};

function ParentStopPaymentModal({
  isOpen,
  toggle,
  stopPaymentForm,
  setStopPaymentForm,
}: ParentStopPaymentModalProps) {
  const [deauthorize, { data, loading, error }] =
    useSpendInvoicePaymentDeauthorizeMutation({
      refetchQueries: [
        { query: GET_INVOICES_WITH_FILTER },
        {
          query: GET_GUARDIAN_HIGHLIGHT_2,
        },
      ],
    });
  useEffect(() => {
    if (!loading && data?.spendInvoicePaymentDeauthorize && !error) {
      toggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error]);
  const [noteError, setNoteError] = useState(false);
  const [note, setNote] = useState(stopPaymentForm.message);
  const { isToastOpen, toggleToast, ...toast } = useToast();
  const btn1: BtnType = {
    text: "Stop Payment",
    btnStyle: "danger",
    onClick: () => {
      if (note === "") {
        toast.setToastProps({ message: "Please add a note", type: "danger" });
        toggleToast();
        setNoteError(true);
      } else {
        if (!stopPaymentForm.invoice?.id) {
          console.log("Error saving stop payment");
          return;
        } else {
          deauthorize({
            variables: {
              input: {
                invoiceId: stopPaymentForm.invoice?.id,
                message: stopPaymentForm.message,
              },
            },
          });
        }
      }
    },
  };
  const btn2: BtnType = {
    text: "Cancel",
    btnStyle: "tertiary",
    onClick: toggle,
  };
  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      title={"Stop Payment"}
      customStyle={"lg:w-[1082px]"}
      btn1={btn1}
      btn2={btn2}
    >
      {stopPaymentForm.invoice && (
        <div className="modal-card">
          {isToastOpen && (
            <ToastMessage
              message={toast.message}
              isToastOpen={isToastOpen}
              toggleToast={toggleToast}
              type={toast.type}
              className="lg:w-[40%]"
            />
          )}
          {/* <Divider isVisibleOnMobile className="xs:mt-auto pt-5 lg:hidden" /> */}
          <div className="flex flex-col">
            <p className="mb-3">Details</p>
            <table className="w-full">
              <tbody>
                <TableRowLabelValue
                  label={"Group"}
                  value={stopPaymentForm.groupName}
                />
                <TableRowLabelValue
                  label={"Season"}
                  value={stopPaymentForm.seasonName}
                />
                <TableRowLabelValue
                  label={"Description"}
                  value={stopPaymentForm.invoice.description}
                />
                <TableRowLabelValue
                  label={"Due Date"}
                  value={FormatDate(
                    stopPaymentForm.invoice?.dueDate ?? "",
                    DateFormatSupported.Numbers
                  )}
                />
                {stopPaymentForm.invoice.paymentMethodSource === "ACH" && (
                  <UserBankMethod />
                )}
                {stopPaymentForm.invoice.paymentMethodSource === "CARD" && (
                  <>
                    {stopPaymentForm.invoice.paymentMethodId ? (
                      <UserCardMethod
                        accountIds={[stopPaymentForm.invoice.paymentMethodId]}
                      />
                    ) : (
                      <TableRowLabelValue
                        label={"Method"}
                        value={"No Card on File"}
                      />
                    )}
                  </>
                )}
                <TableRowLabelValue
                  label={"Amount Due"}
                  value={FormatMoney(stopPaymentForm.invoice?.balanceDue ?? 0)}
                />
              </tbody>
            </table>
          </div>

          <Divider isVisibleOnMobile className="mb-3" />
          <SnapInput
            label="Add a note for the program organizer explaining why you need to stop this scheduled payment and when you plan to resume the payment."
            _id={"message"}
            placeholder="Please Write a Message"
            textarea={true}
            error={noteError}
            onBlur={(e) => {
              setStopPaymentForm({
                ...stopPaymentForm,
                message: e.target.value.trim(),
              });
              setNote(e.target.value.trim());
              setNoteError(false);
            }}
          />
        </div>
      )}
    </CustomModal>
  );
}

export default ParentStopPaymentModal;
