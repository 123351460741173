import { SnapButton } from "suit";

type BankingActionsProps = {
  icon:
    | "cash-solid"
    | "switch-horizontal-solid"
    | "document-check-solid"
    | "settlements-solid";
  label: string;
  action: () => void;
  className: string;
};

function BankingActions({
  icon,
  label,
  action,
  className,
}: BankingActionsProps) {
  return (
    <SnapButton
      fullWidth
      icon={icon}
      onClick={action}
      className={className}
      variant="secondary"
      size="md"
    >
      {label}
    </SnapButton>
  );
}

export default BankingActions;
