import { useState } from "react";
import CustomModal from ".";
import { SnapButton } from "suit";

export type StripeResponse = {
  redirect_from?: string;
  redirect_status: string;
  payment_intent?: string;
  payment_intent_client_secret?: string;
  external_id?: string;
  data?: string;
};

type PaymentConfirmationModalProps = {
  stripeResponse: StripeResponse;
  toggleModal?: () => void;
};

export const PaymentConfirmationModal = ({
  stripeResponse,
  toggleModal,
}: PaymentConfirmationModalProps) => {
  const [isOpen, setIsOpen] = useState(stripeResponse.redirect_status != null);

  const toggleIt = () => {
    setIsOpen(!isOpen);
    toggleModal && toggleModal();
    window.location.reload();
  };

  return (
    <>
      <CustomModal
        isOpen={isOpen}
        toggle={toggleIt}
        title={`${stripeResponse.redirect_from} Payment Confirmation`}
        customStyle="lg:w-[974px]"
      >
        <div className="modal-card">
          <div className="mx-3">
            <ul>
              <li>Your payment was successful.</li>
            </ul>
          </div>

          <div className="flex justify-end space-x-3 mt-5 m-3">
            <SnapButton variant="primary" onClick={toggleIt}>
              Done
            </SnapButton>
          </div>
        </div>
      </CustomModal>
    </>
  );
};
