import FixedModal from "shared-components/fixed-modal/fixed-modal";

type DisputeTransactionProps = {
  disputeTransactionOpen: boolean;
  disputeTransactionToggle: () => void;
};

function DisputeTransaction({
  disputeTransactionOpen,
  disputeTransactionToggle,
}: DisputeTransactionProps) {
  return (
    <FixedModal
      isOpen={disputeTransactionOpen}
      toggle={disputeTransactionToggle}
      title={""}
      icon="credit-card-solid"
      iconColor="#FEF3C7"
      btn1={{
        text: "Okay",
        btnStyle: "primary",
        onClick: disputeTransactionToggle,
      }}
    >
      <div className="text-center">
        <p className="text-lg font-medium">Dispute Charge</p>
        <p className="text-sm text-gray-500">
          To submit a dispute, please call 833-852-1519.
        </p>
      </div>
    </FixedModal>
  );
}

export default DisputeTransaction;
