import { gql } from "@apollo/client";

export const CREATE_CATEGORY = gql`
  mutation SpendCategoryCreate($input: SpendCategoryInput!) {
    spendCategoryCreate(input: $input) {
      id
    }
  }
`;

export const CATEGORIES_BULK_UPSERT = gql`
  mutation SpendCategoryUpsertBulk($input: [SpendUpsertCategoryInput]) {
    spendCategoryUpsertBulk(input: $input) {
      id
    }
  }
`;
