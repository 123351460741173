import CustomModal, { BtnState } from "shared-components/modal";
import { SnapAlert, SnapAlertTitle, SnapInput } from "suit";
import { FormatMoney, ParseMoney } from "helpers/format-money";
import TableRowLabelValue from "shared-components/table-row-label-value";
import { LabelValueObject } from "types/label-value-object";
import { Maybe, SpendGroupRoster, SpendInvoice } from "graphql/generated";
import { UPDATE_INVOICE } from "graphql/mutations/invoice";
import { useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import InputMask from "shared-components/input-mask";
import ToastContext from "context/toast-context";

type EditInvoiceAmountProps = {
  editAmountOpen: boolean;
  editAmountToggle: () => void;
  participant: SpendGroupRoster;
  invoice: Maybe<SpendInvoice>;
};
function EditInvoiceAmount({
  editAmountOpen,
  editAmountToggle,
  participant,
  invoice,
}: EditInvoiceAmountProps) {
  const prepData = (
    participant: SpendGroupRoster,
    invoice: Maybe<SpendInvoice>
  ) => {
    let data: LabelValueObject[] = [];
    data.push({ key: "Participant", value: participant.roster?.name ?? "" });
    data.push({
      key: "Current Amount Paid/Credited",
      value: FormatMoney(invoice?.amount ?? 0),
    });
    return data;
  };
  const Toast = useContext(ToastContext);
  const [newInvoiceAmount, setNewInvoiceAmount] = useState("");
  const [newNote, setNewNote] = useState(invoice?.note ?? "");
  const [isBtnActive, setIsBtnActive] = useState(true);

  const [UpdateInvoice, { loading, data, error }] = useMutation(
    UPDATE_INVOICE,
    {
      refetchQueries: ["GetParticipantsDetails"],
    }
  );

  useEffect(() => {
    if (!loading && data) {
      Toast?.setToast({
        message: "Invoice Successfully Edited",
        type: "success",
      });
      editAmountToggle();
      setNewInvoiceAmount("0");
      setNewNote("");
    } else if (!loading && error) {
      Toast?.setToast({
        message: "Error Editing Invoice",
        type: "danger",
      });
    }
    setIsBtnActive(true);
    // eslint-disable-next-line
  }, [loading, data]);
  const handleSave = () => {
    const parsedAmount = ParseMoney(newInvoiceAmount);
    if (parsedAmount <= 0.5) {
      Toast?.setToast({
        message: "Invoice must be more than $0.50",
        type: "danger",
      });
      Toast?.toggleToast();
    } else {
      setIsBtnActive(false);
      UpdateInvoice({
        variables: {
          spendInvoiceUpdateId: invoice?.id,
          input: {
            amount: parsedAmount,
            note: newNote,
          },
        },
      });
    }
  };
  return (
    <CustomModal
      isOpen={editAmountOpen}
      toggle={editAmountToggle}
      title={"Edit Invoice Amount"}
      btn1={{
        text: "Save Changes",
        btnStyle: "primary",
        btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
        onClick: () => {
          handleSave();
        },
      }}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: () => {
          editAmountToggle();
          setNewInvoiceAmount("0");
          setNewNote("");
          setIsBtnActive(true);
        },
      }}
      customStyle="lg:mt-10 lg:w-[1082px]"
    >
      <div className="modal-card mb-5">
        <SnapAlert type="info" className="mb-3 lg:mb-2">
          <SnapAlertTitle>
            <p>Changes made will be applied only to this participant.</p>
          </SnapAlertTitle>
        </SnapAlert>
        <table className="w-full lg:w-auto">
          <tbody>
            {prepData(participant, invoice).map((p, idx) => {
              return (
                <TableRowLabelValue
                  key={idx}
                  label={p.key}
                  value={p.value.toString()}
                  className={`lg:ml-24 mt-2 ${p.className}`}
                  customContainerStyle={p.customContainerStyle}
                />
              );
            })}
          </tbody>
        </table>
        <div className="lg:w-96 mt-4">
          <SnapInput
            _id="input-with-leading-add-on"
            _type="number"
            placeholder={FormatMoney(invoice?.amount ?? 0)
              .split("")
              .slice(1)
              .join("")}
            label="Current Amount"
            _prepend="$"
            disabled
          />
        </div>
        <div className=" lg:w-96 mt-4">
          <InputMask
            label="New Amount"
            amount={newInvoiceAmount}
            hasError={false}
            setAmount={setNewInvoiceAmount}
            onChange={() => {}}
          />
        </div>
        <div className="mt-4">
          <div className="flex justify-between">
            <p className="text-sm">Note</p>
            <p className="text-sm text-gray-500">Optional</p>
          </div>
          <input
            type="text"
            className="w-full pb-28 px-2 pt-2 border-2 rounded-lg"
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
          />
          <p className="text-sm  text-gray-500">
            This note will be visble to the payer.
          </p>
        </div>
      </div>
    </CustomModal>
  );
}

export default EditInvoiceAmount;
