import React, {
  forwardRef,
  useImperativeHandle,
} from 'react';
import { PaymentMethod } from '@snap-mobile/payments-widget-utils';
import { SubmitButton } from './SubmitButton';
import { PaymentIntent } from '@stripe/stripe-js';
import { useExistingPaymentsMethodSubmit } from '../hooks/useExistingPaymentsMethodSubmit';

export type ExistingPaymentMethodWidgetProps = React.PropsWithChildren<{
  paymentMethod: PaymentMethod;
  renderSubmitButton?: React.ReactNode;
  onSuccess?: (data: PaymentIntent) => void | Promise<void>;
}>;

export type ExistingPaymentMethodRef = {
  submit: () => void;
};

/**
 * Props for `ExistingPaymentMethod` Component
 *
 * @prop {PaymentMethod} paymentMethod - An object representing the payment method to be used.
 *                                       This is a mandatory prop for processing the payment, {id:'', identifier: 'customer paymentMethodId'}
 * @prop {React.ReactNode} renderSubmitButton - Optional custom node to be rendered as the submit button.
 *                                              If not provided, a default submit button is used.
 * @prop {(data: PaymentIntent) => void} onSuccess - Optional callback function that is invoked upon successful
 *                                         payment confirmation. Receives the result of the payment confirmation
 *                                         as its argument. Useful for handling post-payment actions like
 *                                         updating UI or making additional API calls.
 ** Ref Methods:
 * @method submit - Triggers the payment submission process.
 * The `ExistingPaymentMethod` component integrates with custom hooks and context for managing payment
 * processing. It renders a form for submitting an existing payment method. The form submission
 * involves setting the processing state, creating a customer, and confirming the payment using the
 * provided method. If the payment confirmation is successful, the `onSuccess` callback is triggered.
 *
 * * Usage:
 * <PaymentsWidgetProvider {...props}>
 * <ExistingPaymentMethod
 *   paymentMethod={yourPaymentMethod}
 *   renderSubmitButton={<CustomSubmitButton />}
 *   onSuccess={(data) => console.log('Payment Successful', data)}
 * />
 * </PaymentsWidgetProvider>
 */
export const ExistingPaymentMethod = forwardRef<
  ExistingPaymentMethodRef,
  ExistingPaymentMethodWidgetProps
>(
  (
    {
      paymentMethod,
      renderSubmitButton,
      onSuccess,
    }: ExistingPaymentMethodWidgetProps,
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      submit() {
        onSubmit();
      },
    }));

    const { onSubmit } = useExistingPaymentsMethodSubmit({
      paymentMethod: paymentMethod,
      onSuccess: onSuccess,
    });

    return (
      <form id="existing-payment-method" onSubmit={onSubmit}>
        <SubmitButton>{renderSubmitButton}</SubmitButton>
      </form>
    );
  }
);
