import { gql } from "@apollo/client";

export const PAYMENT_SCHEDULE_BY_ID = gql`
  query SpendPaymentScheduleById($spendPaymentScheduleByIdId: String!) {
    spendPaymentScheduleById(id: $spendPaymentScheduleByIdId) {
      amountDue
      budgetItemId
      description
      dueDate
      groupId
      id
      isOptional
      note
      seasonId
      status
    }
  }
`;

export const PAYMENT_SCHEDULE = gql`
  query SpendPaymentSchedules {
    spendPaymentSchedules {
      amountDue
      budgetItemId
      description
      dueDate
      groupId
      id
      isOptional
      note
      seasonId
      status
    }
  }
`;

export const PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON = gql`
  query SpendPaymentSchedulesByGroupOrSeason($groupIdOrSeasonId: String) {
    spendPaymentSchedules(groupIdOrSeasonId: $groupIdOrSeasonId) {
      id
      amountDue
      dueDate
      description
      status
      note
      isOptional
      budgetItemId
      seasonId
      groupId
      budgetItem {
        id
        description
        targetAmount
        targetDateAt
        vaultId
        category {
          name
          type
          id
        }
        isDefault
        createdAt
        updatedAt
        reconciledBudgetTotal
        reconciledInvoicesTotal
        reconciledTotal
        invoices {
          balanceDue
          description
          paid
          id
          reconciledTransactions {
            amount
            id
            invoiceId
            transactionId
          }
          amount
        }
      }
      season {
        id
        name
        startDateAt
        endDateAt
      }
      group {
        id
        name
        organizationId
        programId
        createdAt
        archivedAt
        hasAccount
      }
    }
  }
`;
