export const transactionTypeFilters = [
  {
    name: "All Transactions Types",
    text: "All Transactions Types",
    value: "",
    selected: true,
  },
  { name: "ACH", text: "ACH", value: "ach", selected: false },
  {
    name: "Sent Check",
    text: "Sent Check",
    value: "checkPayment",
    selected: false,
  },
  {
    name: "Debit Card",
    text: "Debit Card",
    value: "debit_card",
    selected: false,
  },
  { name: "Deposit", text: "Deposit", value: "deposit", selected: false },
  { name: "Fee", text: "Fee", value: "fee", selected: false },
  {
    name: "Invoice Payment",
    text: "Invoice Payment",
    value: "invoice_payment",
    selected: false,
  },
  {
    name: "Invoice Refund",
    text: "Invoice Refund",
    value: "invoice_refund",
    selected: false,
  },
  { name: "Transfer", text: "Transfer", value: "transfer", selected: false },
  {
    name: "Withdrawal",
    text: "Withdrawal",
    value: "withdrawal",
    selected: false,
  },
];
