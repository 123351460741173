import { SnapSelectMenu } from "suit";
import { InvoiceCardView } from "../make-payment/invoice-card-view";
import { InvoiceTableView } from "../make-payment/invoice-table-view";
import { SelectedPlayer } from "../make-payment/selected-player";
import { SubHeaderView } from "../make-payment/sub-header-view";
import { OffSessionPaymentComponent } from "shared-components/payment-component/off-session-payment-component";
import LinkBank, {
  getRefetchQueries,
} from "shared-components/banking/link-bank";
import {
  PaymentsApiCustomerPaymentMethod,
  SpendBankAccount,
  SpendGroupRoster,
} from "graphql/generated";
import { SelectedInvoice } from "types/invoice";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import cuid from "cuid";
import { useContextStrict } from "helpers/context-strict";
import UserContext from "context/user-context";
import { useContext, useState } from "react";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import ToastContext from "context/toast-context";
import { useMutation } from "@apollo/client";
import { DELETE_USER_PLAID_ACCESS } from "graphql/mutations/plaid";

type Step1Props = {
  playerSelected: SpendGroupRoster | undefined;
  selectableInvoices: SelectedInvoice[];
  handleCheckId: (e: SelectedInvoice | null, more?: any) => void;
  handleOptStatus: (status: boolean, invoice: SelectedInvoice) => void;
  paymentOptions: SnapSelectMenuOption[];
  setSelectedPaymentTypeOption: React.Dispatch<
    React.SetStateAction<`Pay by: ${"Card" | "Bank"}` | undefined>
  >;
  setPaymentOptions: React.Dispatch<
    React.SetStateAction<SnapSelectMenuOption[]>
  >;
  payByCard: boolean;
  widgetOpen: boolean;
  paymentMethods: PaymentsApiCustomerPaymentMethod[];
  groupAccountId: string;
  paymentMethodCreate: () => void;
  payByBank: boolean;
  bankAccounts: SpendBankAccount[];
  bankOptions: SnapSelectMenuOption[];
  setBankOptions: React.Dispatch<React.SetStateAction<SnapSelectMenuOption[]>>;
  accountStatus: string;
};

export default function Step1({
  playerSelected,
  selectableInvoices,
  handleCheckId,
  handleOptStatus,
  paymentOptions,
  setSelectedPaymentTypeOption,
  setPaymentOptions,
  payByCard,
  widgetOpen,
  paymentMethods,
  groupAccountId,
  paymentMethodCreate,
  payByBank,
  bankAccounts,
  bankOptions,
  setBankOptions,
  accountStatus,
}: Step1Props) {
  const extPayId = cuid();
  const activeUser = useContextStrict(UserContext);
  const toast = useContext(ToastContext);

  const [deletePlaidLink] = useMutation(DELETE_USER_PLAID_ACCESS, {
    refetchQueries: getRefetchQueries("user"),
  });

  const [plaidConfig, setPlaidConfig] = useState<PlaidLinkOptions>({
    onSuccess: (public_token, metadata) => {},
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: "",
  });

  const { open, ready } = usePlaidLink(plaidConfig);
  const openPlaid = () => {
    if (ready) {
      open();
    }
  };

  return (
    <div className="mx-3 mt-2">
      <SelectedPlayer playerSelected={playerSelected} />
      <SubHeaderView text={"Invoices to Authorize for AutoPay"} />
      <div className="hidden lg:flex">
        <InvoiceTableView
          list={selectableInvoices}
          isReadonly={false}
          handleCheckId={handleCheckId}
          handleOptStatus={handleOptStatus}
          isAuthorizeFlow={true}
        />
      </div>
      <div>
        <InvoiceCardView
          list={selectableInvoices}
          isReadonly={false}
          handleCheckId={handleCheckId}
          handleOptStatus={handleOptStatus}
        />
      </div>
      <p className="text-sm text-gray-400 mt-4 lg:mt-0">
        *This includes fees to support our platform's technology and security
        safeguards
      </p>
      <SubHeaderView text={"Choose Payment Method"} />
      <div className="mb-10">
        <SnapSelectMenu
          placeholder="- Select Payment Method -"
          selectMenuOptions={paymentOptions}
          onSnap-select-menu-updated={(e) => {
            const op = e.detail.find((option) => option.selected)?.name;
            if (!op) {
              setSelectedPaymentTypeOption(undefined);
              setPaymentOptions(e.detail);
            } else {
              setSelectedPaymentTypeOption(
                op === "Pay by: Card" ? "Pay by: Card" : "Pay by: Bank"
              );
              setPaymentOptions(e.detail);
            }
          }}
        />
        {payByCard && extPayId && (
          <>
            {!widgetOpen ? (
              paymentMethods.map((method, idx) => {
                return (
                  <div key={`${method.identifier} ${idx}`} className="flex">
                    <p>{method.identifier}</p>
                  </div>
                );
              })
            ) : (
              <OffSessionPaymentComponent
                returnUrl={`${document.baseURI}&externalId=${extPayId}`}
                payerDetails={{
                  id: "",
                  name: activeUser?.getName() ?? "",
                  email: activeUser?.getEmail() ?? "",
                }}
                destination={groupAccountId}
                externalPaymentId={extPayId}
                paymentMethodCreate={paymentMethodCreate}
              />
            )}
          </>
        )}
        {payByBank && bankAccounts.length > 0 ? (
          <div className="lg:flex justify-between lg:flex-col mt-2">
            <div className="pr-20 whitespace-nowrap text-xs font-semibold text-gray-500 self-center lg:self-start lg:mb-2">
              <p>Linked Bank</p>
            </div>
            <SnapSelectMenu
              placeholder="- Select Bank Account -"
              selectMenuOptions={bankOptions}
            />
          </div>
        ) : (
          payByBank && (
            <div className="lg:flex">
              <LinkBank
                openPlaid={openPlaid}
                setPlaidConfig={setPlaidConfig}
                toast={toast}
                type={"user"}
                labelText={
                  accountStatus === "pending_manual_verification"
                    ? "Verify Account"
                    : "Add Account"
                }
                groupId={playerSelected?.group?.id}
              />
              {accountStatus === "pending_manual_verification" && (
                <p
                  className="self-center lg:mt-4 mt-10 text-red-500 font-bold cursor-pointer"
                  onClick={() => deletePlaidLink()}
                >
                  Delete Account
                </p>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
}
