import {
  Maybe,
  SpendInvoice,
  SpendCategory,
  SpendBudget,
} from "graphql/generated";
import {
  BudgetSummaryRecord,
  BudgetSummaryResponse,
  BudgetTotalValue,
  UnreconciledRecord,
} from "types/budget-summary";
import { FormatMoney } from "./format-money";
import { MapAndCalcSum } from "./map-and-reduce";
import { InvoiceSetOverview } from "types/collections";
import { FormatDate } from "./format-date";

export function TotalAmountReconciledInvoices(invoices: Maybe<SpendInvoice>[]) {
  return (
    invoices
      .filter((invoice) => (invoice as SpendInvoice).isReconciled)
      .map((invoice) => (invoice as SpendInvoice).amount ?? 0)
      .reduce((sum, current) => sum + current, 0) ?? 0
  );
}

const getTotalRec = (budgets: Maybe<Maybe<SpendBudget>[]> | undefined) => {
  let total = 0;
  budgets &&
    budgets.forEach((b) => {
      total += b?.reconciledBudgetTotal ?? 0;
    });
  return total;
};

export function BudgetSummaryGroupByType(
  categories: SpendCategory[],
  categoryType: string
) {
  return categories
    .filter(
      (category) =>
        category.budgets != null &&
        category.budgets.length > 0 &&
        category.type === categoryType
    )
    .map((filteredCategory: SpendCategory) => {
      return {
        name: filteredCategory.name,
        budgetAmount:
          filteredCategory
            .budgets!.map((budget) => budget!.targetAmount)
            .reduce((sum, current) => sum! + current!, 0) ?? 0,
        budgetReconciled: getTotalRec(filteredCategory?.budgets),
        expandedData: filteredCategory.budgets!.map((budget) => {
          return (
            {
              id: budget?.id ?? "",
              name: budget?.description ?? "",
              budgetAmount: budget?.targetAmount ?? 0,
              budgetReconciled: budget?.reconciledBudgetTotal ?? 0,
              targetDate: budget?.targetDateAt ?? "",
            } || []
          );
        }),
      };
    });
}
export function BudgetSummaryConvertDataToUiModels(
  data: BudgetSummaryResponse,
  setIncomeBudgetData: React.Dispatch<
    React.SetStateAction<[] | BudgetSummaryRecord[]>
  >,
  setExpenseBudgetData: React.Dispatch<
    React.SetStateAction<[] | BudgetSummaryRecord[]>
  >,
  setIncomeBudgetTotal: React.Dispatch<React.SetStateAction<BudgetTotalValue>>,
  setExpenseBudgetTotal: React.Dispatch<React.SetStateAction<BudgetTotalValue>>,
  setUnreconciledData?: React.Dispatch<React.SetStateAction<UnreconciledRecord>>
) {
  if (data.summaryByCategory && data.summaryByCategory.length > 0) {
    const incomeCategoriesWithBudgets = BudgetSummaryGroupByType(
      data.summaryByCategory,
      "income"
    );
    const expenseCategoriesWithBudgets = BudgetSummaryGroupByType(
      data.summaryByCategory,
      "expense"
    );
    setIncomeBudgetData(incomeCategoriesWithBudgets);
    setExpenseBudgetData(expenseCategoriesWithBudgets);
    let budgetTotal = incomeCategoriesWithBudgets
      .map((b) => b.budgetAmount)
      .reduce((p, n) => p + n, 0);
    let reconciledTotal = incomeCategoriesWithBudgets
      .map((b) => b.budgetReconciled)
      .reduce((p, n) => p + n, 0);
    setIncomeBudgetTotal({
      budgetTotal,
      reconciledTotal,
      percent: Math.floor((reconciledTotal / budgetTotal) * 100),
    });

    budgetTotal = expenseCategoriesWithBudgets
      .map((b) => b.budgetAmount)
      .reduce((p, n) => p + n, 0);
    reconciledTotal = expenseCategoriesWithBudgets
      .map((b) => b.budgetReconciled)
      .reduce((p, n) => p + n, 0);
    setExpenseBudgetTotal({
      budgetTotal,
      reconciledTotal,
      percent: Math.floor((reconciledTotal / budgetTotal) * 100),
    });
  } else {
    setIncomeBudgetData([]);
    setExpenseBudgetData([]);
    setIncomeBudgetTotal({
      budgetTotal: 0,
      reconciledTotal: 0,
      percent: 0,
    });
    setExpenseBudgetTotal({
      budgetTotal: 0,
      reconciledTotal: 0,
      percent: 0,
    });
  }

  const unreconciledInit = {
    credit: { count: 0, total: 0 },
    debit: { count: 0, total: 0 },
  };
  if (data.summaryUnreconciled) {
    unreconciledInit.credit.count =
      data.summaryUnreconciled.credits?.count ?? 0;
    unreconciledInit.credit.total =
      data.summaryUnreconciled.credits?.total ?? 0;
    unreconciledInit.debit.count = data.summaryUnreconciled.debits?.count ?? 0;
    unreconciledInit.debit.total = data.summaryUnreconciled.debits?.total ?? 0;
  }
  if (setUnreconciledData) setUnreconciledData(unreconciledInit);
}

export const handleCalculations = (
  invoices: Maybe<SpendInvoice>[],
  se568PastDue?: boolean
): InvoiceSetOverview => {
  const UpComingAndPastDue = (
    invoices: Maybe<SpendInvoice>[],
    totalPaid: number
  ) => {
    return {
      balances: invoices
        .map((invoice) => {
          if (invoice && invoice.balanceDue) {
            if (invoice.amount && invoice.balanceDue !== invoice.amount) {
              totalPaid += invoice.amount - invoice.balanceDue;
            }
            return invoice.balanceDue;
          }
          return 0;
        })
        .reduce((sum, current) => sum + current, 0),
      totalPaid: totalPaid,
    };
  };

  let currentDate = new Date();
  let totalPaid = MapAndCalcSum(
    invoices.filter((invoice) => invoice?.paid === true),
    "amount"
  );
  let invoicesStatus = "paid";
  let notFullyPaidInvoices = invoices.filter(
    (invoice) => invoice?.paid === false && invoice.status !== "opt_out"
  );
  if (notFullyPaidInvoices.length !== 0) {
    invoicesStatus = "not paid";
  }

  let upComingInvoices = notFullyPaidInvoices.filter(
    (invoice) => new Date(invoice?.dueDate ?? 0) > currentDate
  );

  let totalUpcomingData = UpComingAndPastDue(upComingInvoices, totalPaid);
  let totalUpcoming = totalUpcomingData.balances;
  totalPaid = totalUpcomingData.totalPaid;

  let pastDueInvoices = se568PastDue
    ? notFullyPaidInvoices.filter(
        (invoice) =>
          new Date(FormatDate(invoice?.dueDate ?? 0)) <
          new Date(FormatDate(currentDate))
      )
    : notFullyPaidInvoices.filter(
        (invoice) => new Date(invoice?.dueDate ?? 0) < currentDate
      );

  let totalPastDueData = UpComingAndPastDue(pastDueInvoices, totalPaid);
  let totalPastDue = totalPastDueData.balances;
  totalPaid = totalPastDueData.totalPaid;
  let daysPast = 0;
  if (pastDueInvoices.length > 0) {
    let biggestPastDue =
      pastDueInvoices
        .map((invoice) => new Date(invoice?.dueDate ?? 0).getTime())
        .sort((a, b) => b - a)
        .shift() ?? 0;
    daysPast = Math.ceil((currentDate.getTime() - biggestPastDue) / 86400000);
  }

  // TODO Processing
  let totalProcessing = 0;

  // TODO Credited
  let totalCredited = 0;
  return {
    status: invoicesStatus,
    daysPast: daysPast,
    totalPaid: FormatMoney(totalPaid),
    totalUpcoming: FormatMoney(totalUpcoming),
    totalPastDue: FormatMoney(totalPastDue),
    totalProcessing: FormatMoney(totalProcessing),
    credited: FormatMoney(totalCredited),
  };
};

export const getHexColor = (
  budgeted: number,
  reconciled: number,
  sub: boolean,
  isArchived: boolean
) => {
  let mainBlue = "#3B82F6";
  let subBlue = "#93C5FD";
  let mainGreen = "#10B981";
  let subGreen = "#6EE7B7";
  let mainRed = "#EF4444";
  let subRed = "#FCA5A5";
  let color = "";

  switch (true) {
    case budgeted === reconciled && !sub:
      color = mainGreen;
      break;
    case budgeted === reconciled && sub:
      color = subGreen;
      break;
    case budgeted > reconciled && !sub:
      color = mainBlue;
      break;
    case budgeted > reconciled && sub:
      color = subBlue;
      break;
    case budgeted < reconciled && !sub:
      color = mainRed;
      break;
    case budgeted < reconciled && sub:
      color = subRed;
      break;
  }
  if (isArchived) {
    color = "#94A3B8";
  }

  return color;
};
