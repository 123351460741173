import {
  InternalRefetchQueriesInclude,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import SeasonContext from "context/season-context";
import ToastContext from "context/toast-context";
import {
  SpendBudgetInput,
  SpendCategory,
  SpendSeason,
} from "graphql/generated";
import {
  CREATE_BUDGET_ITEM,
  UPDATE_BUDGET_ITEM,
} from "graphql/mutations/budget";
import {
  GETorganization_BUDGET_SUMMARY,
  GET_SPEND_BUDGET_SUMMARY,
} from "graphql/queries/budgets";
import { GET_CATEGORIES_SIMPLE } from "graphql/queries/categories";
import { useContext, useEffect, useState } from "react";
import InputMask from "shared-components/input-mask";
import CustomModal, { BtnType } from "shared-components/modal";
import { SnapInput, SnapSelectMenu } from "suit";
import { selectedBudgetItem } from "types/budgets";
import { budgetError } from "types/errors";
import { BtnState } from "./scrollable-modal";
import { GET_GROUP_BY_ID } from "graphql/queries/group";
import { ParseMoney } from "helpers/format-money";
import DatePicker from "shared-components/date-picker";
import { getDatePickerValue, setDatePickerValue } from "helpers/date-picker";

type EditBudgetProps = {
  isOpen: boolean;
  toggle: () => void;
  dataToEdit: selectedBudgetItem;
  categoryType: "income" | "expense";
  budgetType: "program" | "group";
};

function EditBudget({
  isOpen,
  toggle,
  dataToEdit,
  categoryType,
  budgetType,
}: EditBudgetProps) {
  const group = useContext(GroupContext);
  const season = useContext(SeasonContext);
  const toast = useContext(ToastContext);

  const [categories, setCategories] = useState<SnapSelectMenuOption[]>([]);
  const { data, loading, error } = useQuery(GET_CATEGORIES_SIMPLE, {
    variables: { filterBy: "type", filterValue: categoryType },
  });
  const [amount, setAmount] = useState("");
  const [hasErrors, setHasErrors] = useState<budgetError>({
    categoryError: false,
    budgetItemError: false,
    amountError: false,
    dateError: false,
  });
  const [isBtnActive, setIsBtnActive] = useState(true);

  const refetchContent: InternalRefetchQueriesInclude = [
    !group?.activeGroup
      ? { query: GETorganization_BUDGET_SUMMARY }
      : {
          query: GET_SPEND_BUDGET_SUMMARY,
          variables: {
            groupId: group?.activeGroup?.id,
            seasonId: season?.selectedSeason?.id,
          },
          fetchPolicy: "no-cache",
        },
  ];

  const [
    addNewBudgetItem,
    {
      data: createBudgetData,
      loading: createBudgetLoading,
      error: createBudgetError,
    },
  ] = useMutation(CREATE_BUDGET_ITEM, {
    refetchQueries: refetchContent,
  });

  const [
    updateBudgetItem,
    {
      data: updateBudgetData,
      loading: updateBudgetLoading,
      error: updateBudgetError,
    },
  ] = useMutation(UPDATE_BUDGET_ITEM, {
    refetchQueries: refetchContent,
  });

  const [getGroupById, { loading: groupLoading, data: groupData }] =
    useLazyQuery(GET_GROUP_BY_ID);

  const title =
    dataToEdit.budgetId == null
      ? `Add ${categoryType} Budget Item`
      : "Edit Budget Item";
  const buttonText = "Submit";
  const btn1: BtnType = {
    text: buttonText,
    onClick: () => {
      dataToEdit.amountApplied = amount;
      const selectedCat = categories.filter((item) => item.selected);
      if (selectedCat.length === 0) {
        setHasErrors({ ...hasErrors, categoryError: true });
        hasErrors.categoryError = true;
      }
      if (dataToEdit.budgetItem === "") {
        setHasErrors({ ...hasErrors, budgetItemError: true });
        hasErrors.budgetItemError = true;
      }
      if (amount === "0.00") {
        setHasErrors({ ...hasErrors, amountError: true });
        hasErrors.amountError = true;
      }
      if (dataToEdit.date === "") {
        setHasErrors({ ...hasErrors, dateError: true });
        hasErrors.dateError = true;
      }
      let { categoryError, budgetItemError, amountError, dateError } =
        hasErrors;
      if (categoryError || budgetItemError || amountError || dateError) {
        toast?.setToast({
          message: "Please fill in all the input fields",
          type: "danger",
        });
      } else {
        const selectedCatId = data.spendCategories.categories.filter(
          (cat: SpendCategory) => cat.name === selectedCat[0].name
        )[0].id;
        const seasonId =
          budgetType === "group" ? season?.selectedSeason?.id : undefined;
        let inputData: SpendBudgetInput = {
          categoryId: selectedCatId,
          description: dataToEdit.budgetItem,
          isDefault: false,
          seasonId: seasonId,
          targetAmount: ParseMoney(dataToEdit.amountApplied),
          targetDateAt: dataToEdit.date,
          vaultId: "",
        };
        setIsBtnActive(false);
        if (dataToEdit.budgetId == null) {
          addNewBudgetItem({
            variables: {
              input: inputData,
            },
          });
        } else {
          updateBudgetItem({
            variables: {
              spendBudgetUpdateId: dataToEdit.budgetId,
              input: inputData,
            },
          });
        }
      }
    },
    btnStyle: "primary",
    btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
  };
  const btn2: BtnType = {
    text: "Cancel",
    onClick: () => {
      toggle();
      setAmount("");
      setIsBtnActive(true);
    },
    btnStyle: "tertiary",
  };
  useEffect(() => {
    if (!createBudgetLoading && createBudgetData) {
      toast?.setToast({
        message: "Budget created successfully",
        type: "success",
      });
      toggle();
      getGroupById({
        variables: {
          spendGroupByIdId: group?.activeGroup?.id,
        },
      });
    }
    if (!updateBudgetLoading && updateBudgetData) {
      toast?.setToast({
        message: "Budget updated successfully",
        type: "success",
      });
      toggle();
    }
    setIsBtnActive(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createBudgetData,
    createBudgetLoading,
    createBudgetError,
    updateBudgetData,
    updateBudgetLoading,
    updateBudgetError,
  ]);
  useEffect(() => {
    if (dataToEdit.amountApplied) {
      dataToEdit.amountApplied = (
        Number(dataToEdit.amountApplied) / 100
      ).toString();
      let f = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      setAmount(
        f.format(Number(dataToEdit.amountApplied ?? 0)).replace("$", "")
      );
    }
    if (data) {
      let categories = data.spendCategories.categories;
      let selectedCat = categories.filter(
        (cat: SpendCategory) => cat.id === dataToEdit.catId
      );
      const newGroupList: SnapSelectMenuOption[] = categories.map(
        (category: SpendCategory) => {
          return {
            id: category.id,
            name: category.name,
            selected: selectedCat.length
              ? category.name === selectedCat[0].name
                ? true
                : false
              : category.isDefault,
          };
        }
      );
      if (
        newGroupList.length > 0 &&
        !newGroupList.some((option) => option.selected)
      ) {
        newGroupList[0].selected = true;
      }
      setCategories(newGroupList);
    }
  }, [data, loading, error, dataToEdit, group]);

  useEffect(() => {
    if (!groupLoading && groupData) {
      const seasons = groupData.spendGroupById.seasons;
      const foundSeason = seasons.find(
        (s: SpendSeason) => s.id === season?.selectedSeason?.id
      );
      season?.handleSelectedSeason({
        seasonId: foundSeason.id,
        seasonsToSet: seasons,
      });
    }
    // eslint-disable-next-line
  }, [groupLoading, groupData]);

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={() => {
        toggle();
        setAmount("");
        setIsBtnActive(true);
      }}
      title={title}
      btn1={btn1}
      btn2={btn2}
      customStyle={"lg:h-[400px]"}
    >
      <>
        <div className="modal-card lg:grid grid-cols-2">
          <SnapSelectMenu
            label="Category"
            className="lg:mr-6"
            selectMenuOptions={categories}
            onSnap-select-menu-updated={(e) => {
              setCategories(e.detail);
              setHasErrors({ ...hasErrors, categoryError: false });
            }}
            error={hasErrors.categoryError}
          />
          <SnapInput
            _id={""}
            label="Budget Item Name"
            className="lg:mt-0 mt-6"
            value={dataToEdit.budgetItem}
            onSnap-input-change={(e) => {
              dataToEdit.budgetItem = e.detail.target.value;
              setHasErrors({ ...hasErrors, budgetItemError: false });
            }}
            error={hasErrors.budgetItemError}
          />
          <div className="mt-6 mr-4">
            <InputMask
              amount={amount}
              setAmount={setAmount}
              hasError={hasErrors.amountError}
              onChange={() =>
                setHasErrors({ ...hasErrors, amountError: false })
              }
            />
          </div>
          <DatePicker
            className="mt-6"
            label={"Date"}
            date={getDatePickerValue(dataToEdit.date ?? "")}
            setDate={(e) => {
              const newDate = setDatePickerValue(e);
              dataToEdit.date = newDate;
              setHasErrors({ ...hasErrors, dateError: false });
            }}
            hasError={hasErrors.dateError}
          />
        </div>
      </>
    </CustomModal>
  );
}

export default EditBudget;
