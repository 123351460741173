import { gql } from "@apollo/client";

export const CREATE_INVOICE = gql`
  mutation SpendInvoiceCreate($input: SpendInvoiceInput!) {
    spendInvoiceCreate(input: $input) {
      id
    }
  }
`;
export const CREATE_INVOICE_REMINDER = gql`
  mutation SpendInvoiceReminderCreate($input: SpendInvoiceReminderInput!) {
    spendInvoiceReminderCreate(input: $input) {
      id
    }
  }
`;

export const UPDATE_INVOICE = gql`
  mutation SpendInvoiceUpdate(
    $spendInvoiceUpdateId: String!
    $input: SpendInvoiceInput!
  ) {
    spendInvoiceUpdate(id: $spendInvoiceUpdateId, input: $input) {
      id
    }
  }
`;

export const OPTINOROUT_INVOICE = gql`
  mutation SpendInvoiceOptInOrOut($input: SpendOptInOrOutInput!) {
    spendInvoiceOptInOrOut(input: $input) {
      id
    }
  }
`;

export const DEAUTHORIZE_INVOICE_PAYMENT = gql`
  mutation SpendInvoicePaymentDeauthorize(
    $input: SpendInvoicePaymentDeauthorizeInput!
  ) {
    spendInvoicePaymentDeauthorize(input: $input) {
      id
      notifSuccess
    }
  }
`;

export const INVOICE_REQUEST_CHANGE = gql`
  mutation SpendInvoiceChangeRequest($input: SpendInvoiceRequestChangeInput!) {
    spendInvoiceChangeRequest(input: $input) {
      id
    }
  }
`;

export const CHANGE_INVOICE_PAYMENT_METHOD = gql`
  mutation SpendInvoiceUpdatePaymentMethod(
    $input: SpendInvoicePaymentMethodUpdate!
  ) {
    spendInvoiceUpdatePaymentMethod(input: $input) {
      ids
    }
  }
`;

export const REFUND_INVOICE_PAYMENT = gql`
  mutation SpendInvoiceRefund($input: SpendInvoiceRefundInput!) {
    spendInvoiceRefund(input: $input) {
      invoiceId
      spendTransactionId
    }
  }
`;

export const ARCHIVE_PAYMENT_SCHEDULE_INVOICES = gql`
  mutation SpendPaymentScheduleArchive(
    $spendPaymentScheduleUpdateId: String!
    $input: SpendPaymentScheduleInput!
    $rosterIds: [String]
  ) {
    spendPaymentScheduleUpdate(
      id: $spendPaymentScheduleUpdateId
      input: $input
      rosterIds: $rosterIds
    ) {
      id
    }
  }
`;

export const REVERT_PAYMENT_SCHEDULE_INVOICE = gql`
  mutation SpendPaymentScheduleRevert($spendPaymentScheduleRevertId: String!) {
    spendPaymentScheduleRevert(id: $spendPaymentScheduleRevertId) {
      id
    }
  }
`;

export const REVERT_PAYMENT_SCHEDULE_INVOICES = gql`
  mutation SpendPaymentScheduleRevertMany(
    $spendPaymentScheduleRevertIds: [String!]!
  ) {
    spendPaymentScheduleRevertMany(ids: $spendPaymentScheduleRevertIds) {
      id
    }
  }
`;

export const UNRECONCILE_INVOICE_TRANSACTION = gql`
  mutation SpendTransactionInvoiceUnreconcile(
    $input: SpendTransactionInvoiceUnreconcileInput!
  ) {
    spendTransactionInvoiceUnreconcile(input: $input) {
      id
    }
  }
`;

export const ARCHIVE_INVOICE = gql`
  mutation SpendInvoiceArchive($spendInvoiceArchiveId: String!) {
    spendInvoiceArchive(id: $spendInvoiceArchiveId) {
      id
    }
  }
`;

export const AUTHORIZE_AUTOPAY = gql`
  mutation SpendAuthorizeAutoPay($input: SpendAutoPayInput) {
    spendAuthorizeAutoPay(input: $input) {
      invoiceIds
    }
  }
`;
