import { useMutation } from "@apollo/client";
import ToastContext from "context/toast-context";
import { SpendGroup } from "graphql/generated";
import { COPY_GROUP } from "graphql/mutations/group";
import getGroupSubtitle from "helpers/group-subtitle";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FixedModal, {
  BtnState,
} from "shared-components/fixed-modal/fixed-modal";
import { SnapIcon } from "suit";

type CopyGroupProps = {
  copyGroupOpen: boolean;
  copyGroupToggle: () => void;
  selectedGroup: SpendGroup | undefined;
};

function CopyGroup({
  copyGroupOpen,
  copyGroupToggle,
  selectedGroup,
}: CopyGroupProps) {
  const Toast = useContext(ToastContext);

  const navigate = useNavigate();
  const [isBtnActive, setIsBtnActive] = useState(true);
  const [copyGroup, { loading, data }] = useMutation(COPY_GROUP, {
    onCompleted: () => {
      navigate("/groups");
    },
    refetchQueries: ["SpendGroupsFiltered"],
  });

  useEffect(() => {
    if (!loading && data) {
      Toast?.setToast({
        message: "Group Copied Successfully",
        type: "success",
      });
      copyGroupToggle();
      setIsBtnActive(true);
    }
    // eslint-disable-next-line
  }, [loading, data]);

  return (
    <FixedModal
      isOpen={copyGroupOpen}
      toggle={() => {
        copyGroupToggle();
        setIsBtnActive(true);
      }}
      title={""}
      btn1={{
        text: "Create Duplicate Team",
        onClick: () => {
          setIsBtnActive(false);
          copyGroup({
            variables: { spendGroupDuplicateId: selectedGroup?.id },
          });
        },
        btnStyle: "primary",
        btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
      }}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: () => {
          copyGroupToggle();
          setIsBtnActive(true);
        },
      }}
    >
      <div className="flex flex-col justify-center gap-2 mx-auto text-center w-full modal-card pt-0">
        <SnapIcon
          icon="document-duplicate-solid"
          state="rounded"
          size="xl"
          color="#3B82F6"
          bgColor="#DBEAFE"
        />
        <h3 className="font-semibold">Copy {selectedGroup?.name}</h3>
        <h3 className="font-semibold">
          {getGroupSubtitle(selectedGroup ?? {})}
        </h3>
        <p className="text-sm text-center font-medium text-gray-500">
          This will duplicate the group’s season, participants, and payment
          schedule. Team Assistants are NOT copied. You can edit before
          publishing and sending invitations to guardians for the new team.
        </p>
      </div>
    </FixedModal>
  );
}

export default CopyGroup;
