import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MainNavigationOption } from "suit/components";
import { ActiveUser } from "./use-user";
import { Roles } from "types/roles-permissions";

type MainNavigationOptionWithRules = MainNavigationOption & {
  roleRequirement?: string[];
  overrideId?: string;
};

const loadingNavOptions: MainNavigationOptionWithRules[] = [
  {
    id: "page-1",
    text: "Dashboard",
    active: false,
    icon: "template-solid",
    path: "/dashboard",
    forceNavigation: false,
    roleRequirement: [
      Roles.programObserver,
      Roles.programStaff,
      Roles.programAdmin,
      Roles.guardian,
    ],
  },
];
const navigationOptions: MainNavigationOptionWithRules[] = [
  {
    id: "page-2",
    text: "Program",
    active: true,
    icon: "library-solid",
    path: "/programs",
    forceNavigation: false,
    roleRequirement: [
      Roles.programObserver,
      Roles.programStaff,
      Roles.programAdmin,
      Roles.groupStaffWithProgramBankRead,
    ],
  },
  {
    id: "page-3-single",
    text: "Group",
    active: false,
    icon: "teams-solid",
    path: "/group",
    forceNavigation: false,
    roleRequirement: [
      Roles.groupObserver,
      Roles.groupStaff,
      Roles.groupStaffWithProgramBankRead,
    ],
  },
  {
    id: "page-3-list",
    text: "Groups",
    active: false,
    icon: "teams-solid",
    path: "/groups",
    forceNavigation: false,
    roleRequirement: [
      Roles.programObserver,
      Roles.programStaff,
      Roles.programAdmin,
    ],
  },
  {
    id: "page-4",
    text: "People",
    active: false,
    icon: "user-group-solid",
    path: "/people",
    forceNavigation: false,
    roleRequirement: [
      Roles.programObserver,
      Roles.programStaff,
      Roles.programAdmin,
    ],
  },
  {
    id: "page-5",
    text: "Past Due",
    active: false,
    icon: "document-pastdue-solid",
    path: "/past-due",
    forceNavigation: false,
    roleRequirement: [
      Roles.programObserver,
      Roles.programStaff,
      Roles.programAdmin,
      Roles.groupObserver,
      Roles.groupStaff,
      Roles.groupStaffWithProgramBankRead,
    ],
  },
  {
    id: "page-6",
    text: "Reporting",
    active: false,
    icon: "chart-bar-solid",
    path: "/reporting",
    forceNavigation: false,
    roleRequirement: [
      Roles.programObserver,
      Roles.programStaff,
      Roles.programAdmin,
      Roles.groupObserver,
      Roles.groupStaff,
      Roles.groupStaffWithProgramBankRead,
    ],
  },
  {
    id: "page-7",
    text: "Settings",
    active: false,
    icon: "cog-solid",
    path: "/settings",
    forceNavigation: false,
    roleRequirement: [
      Roles.programObserver,
      Roles.programStaff,
      Roles.programAdmin,
      Roles.groupStaff,
      Roles.groupStaffWithProgramBankRead,
    ],
  },
  {
    id: "page-8",
    text: "Invoices",
    active: false,
    icon: "document-text-solid",
    path: "/invoices",
    forceNavigation: false,
    roleRequirement: [Roles.guardian],
  },
  {
    id: "page-9",
    text: "Payment Methods",
    active: false,
    icon: "credit-card-solid",
    path: "/payment-methods",
    forceNavigation: false,
    roleRequirement: [Roles.guardian],
  },
];
const generateNavigationOptions = (role: string): MainNavigationOption[] => {
  const filterRoles = (navOption: MainNavigationOptionWithRules) => {
    return (
      navOption.roleRequirement == null ||
      navOption.roleRequirement?.some((pr) => pr === role)
    );
  };
  return [
    ...loadingNavOptions.filter(filterRoles),
    ...navigationOptions.filter(filterRoles),
  ];
};

export const useSideNavigationTools = {
  useLocation: useLocation,
  navigationOptions,
};

export const useSideNavigation = (activeUser?: ActiveUser) => {
  const location = useSideNavigationTools.useLocation();
  const isParent = activeUser?.isParent();
  const isReady = activeUser?.getIsUserReady();
  const [sideNavOptions, setSideNavOptions] =
    useState<MainNavigationOption[]>(loadingNavOptions);

  useEffect(() => {
    // TODO filter sideNavOptions based on activeUser permissions
    const pathArray = location.pathname.split("/");
    const current = "/" + pathArray[1];
    if (isReady) {
      const generatedNavigationOptions = generateNavigationOptions(
        activeUser?.getRole() ?? ""
      );
      setSideNavOptions([
        ...generatedNavigationOptions.map((option) => {
          return {
            ...option,
            active: option.path === current,
          };
        }),
      ]);
    }
    // added disable lint because adding sideNavOptions as dependency caused an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isParent, isReady]);

  return sideNavOptions;
};
