import { SnapIcon } from "suit";
import { LabelValueObjectPlus } from "types/label-value-object";

const VerticalLabelValuePlus = ({
  label,
  valuePlus,
  customContainerStyle,
}: LabelValueObjectPlus) => {
  return (
    <div
      className={`flex w-full lg:text-right flex-col mb-5 lg:mb-0 lg:border-r lg:pr-4 lg:mr-4 lg:w-1/3 last:border-0 ${
        customContainerStyle ?? ""
      } `}
    >
      <div className="mr-auto lg:mr-0 font-bold text-xs text-gray-500 uppercase mb-2">
        {label}
      </div>
      {valuePlus.map((vp, idx) => {
        const isHidden = vp.hasWarning ? "block" : "hidden";
        let textValue: string | null;
        if (vp.value == null || typeof vp.value === "string") {
          textValue = vp.value || null;
        } else {
          textValue = vp.value[0] || null;
        }
        return (
          <div key={idx} className={`lg:flex lg:justify-end`}>
            <SnapIcon
              className={`hidden py-auto mr-2 align-bottom lg:${isHidden}`}
              icon="exclamation-solid"
              size="sm"
              color="#EF4444"
            ></SnapIcon>
            <div className="flex flex-row justify-between">
              <div className={`text-sm text-gray-500 mr-2 capitalize`}>
                {vp.label}
              </div>
              <div className={`flex font-semibold text-sm ${vp.valueColor}`}>
                <SnapIcon
                  className={`py-auto mr-2 align-bottom ${isHidden} lg:hidden`}
                  icon="exclamation-solid"
                  size="sm"
                  color="#EF4444"
                ></SnapIcon>
                {textValue}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default VerticalLabelValuePlus;
