import { gql } from "@apollo/client";
export const GET_CATEGORIES_SIMPLE = gql`
  query spendCategories($filterBy: CategoryFilterEnum, $filterValue: String!) {
    spendCategories(filterBy: $filterBy, filterValue: $filterValue) {
      categories {
        id
        name
        isDefault
        type
        organizationId
        isHidden
      }
    }
  }
`;

export const GET_ALL_CATEGORIES = gql`
  query SpendAllCategories {
    spendCategories {
      categories {
        organizationId
        name
        isHidden
        isDefault
        id
        createdAt
        updatedAt
        type
      }
    }
  }
`;

export const GET_CATEGORY_WITH_BUDGETS = gql`
  query SpendBudgetCategories(
    $filterBy: CategoryFilterEnum
    $filterValue: String
    $groupId: String
    $seasonId: String
  ) {
    spendCategories(
      filterBy: $filterBy
      filterValue: $filterValue
      groupId: $groupId
      seasonId: $seasonId
    ) {
      count
      categories {
        id
        name
        type
        isDefault
        isHidden
        organizationId
        createdAt
        updatedAt
        budgets {
          id
          description
        }
      }
    }
  }
`;
