import gql from "graphql-tag";

export const GET_SETTINGS = gql`
  query SpendSettings {
    spendSettings {
      signUpAgreement {
        content
        name
      }
    }
  }
`;

export const GET_USER_NOTIF_SETTINGS = gql`
  query SpendUserNotificationSetting {
    spendUserNotificationSetting {
      notificationSetting {
        id
        notifyOnFailedCardPayments
        notifyOnBankActivity
        copyPastDueInvoices
        userRoleId
        organizationId
        roleName
        groupId
      }
    }
  }
`;
