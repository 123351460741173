import { useContext, useState } from "react";

import {
  SpendBankAccount,
  useExternalAccountsQuery,
} from "../../graphql/generated";
import UserContext from "../../context/user-context";
import TableRowLabelValue from "shared-components/table-row-label-value";

type UserBankMethodProps = {};

function UserBankMethod(props: UserBankMethodProps) {
  const user = useContext(UserContext);

  const [bank, setBank] = useState<SpendBankAccount | undefined>();

  useExternalAccountsQuery({
    onCompleted: ({ spendUserBankAccounts }) => {
      if (spendUserBankAccounts?.externalAccounts?.[0]) {
        const bankAccount = spendUserBankAccounts?.externalAccounts[0];

        bankAccount && setBank(bankAccount);
      }
    },
    skip: !user?.isParent(),
  });

  return (
    <TableRowLabelValue
      label={"Method"}
      value={bank ? `${bank.name} - ${bank.mask}` : "Bank"}
    />
  );
}

export default UserBankMethod;
