import { useQuery } from "@apollo/client";
import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import SeasonContext from "context/season-context";
import UserContext from "context/user-context";
import { SpendCategory } from "graphql/generated";
import { GET_SPEND_BUDGET_SUMMARY } from "graphql/queries/budgets";
import { useContextStrict } from "helpers/context-strict";
import { tabs } from "pages/programs/budget/external-data";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BadgedTabs from "shared-components/badged-tabs";
import BudgetItems from "shared-components/budget-items";
import Summary from "shared-components/summary";
import { BudgetSummaryResponse } from "types/budget-summary";
import { SpendPermissions } from "types/roles-permissions";

function Budget() {
  const navigate = useNavigate();
  const Program = useContext(ProgramContext);
  const ActiveGroup = useContextStrict(GroupContext);
  const Season = useContext(SeasonContext);
  const [selectedTab, setSelectedTab] = useState(0);
  useEffect(() => {
    if (!ActiveGroup.activeGroup) {
      ActiveGroup.setAndStoreActiveGroup(ActiveGroup.groups.at(0));
    }
  }, [ActiveGroup, Program]);

  useEffect(() => {
    if (ActiveGroup.activeGroup?.sharedAccount) navigate("/programs/budget");
  }, [ActiveGroup.activeGroup, navigate]);
  const [spendSummary, setSpendSummary] = useState<
    BudgetSummaryResponse | undefined
  >();
  const [incomeBudgetItems, setIncomeBudgetItems] = useState<SpendCategory[]>();
  const [expenseBudgetItems, setExpenseBudgetItems] =
    useState<SpendCategory[]>();

  const { data, loading, refetch } = useQuery(GET_SPEND_BUDGET_SUMMARY, {
    variables: {
      groupId: ActiveGroup?.activeGroup?.id,
      seasonId: Season?.selectedSeason?.id,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [Season?.selectedSeason]);

  const canEditBudget =
    useContext(UserContext)?.checkSpendPermission(
      SpendPermissions.groupBudgetUpdate
    ) ?? false;

  useEffect(() => {
    if (data?.spendBudgetsSummary) {
      setSpendSummary(data.spendBudgetsSummary);
      const categories = data.spendBudgetsSummary.summaryByCategory;
      const iBudgetItems = categories.filter(
        (item: SpendCategory) => item.type === "income"
      );
      const eBudgetItems = categories.filter(
        (item: SpendCategory) => item.type === "expense"
      );
      setIncomeBudgetItems(iBudgetItems);
      setExpenseBudgetItems(eBudgetItems);
    }
  }, [data, loading]);
  return (
    <div className="wrapper">
      <div className="card">
        <BadgedTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={tabs}
        />
        {
          {
            0: (
              <Summary
                budgetSummary={spendSummary}
                ActiveGroup={ActiveGroup?.activeGroup}
                type="group"
                canEditBudget={canEditBudget}
              />
            ),
            1: (
              <BudgetItems
                budgetItems={incomeBudgetItems}
                type="income"
                budgetType="group"
              />
            ),
            2: (
              <BudgetItems
                budgetItems={expenseBudgetItems}
                type="expense"
                budgetType="group"
              />
            ),
          }[selectedTab]
        }
      </div>
    </div>
  );
}

export default Budget;
