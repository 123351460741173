export const Roles = {
  guardian: "guardian", //parent
  groupObserver: "group_observer", //group readonly
  groupStaff: "group_staff", //group_admin
  programObserver: "program_observer", // program readonly
  programStaff: "program_staff", // program_admin
  programAdmin: "program_admin", // org owner
  groupStaffWithProgramBankRead: "group_staff_withorganization_bank_read",
};

export const SpendPermissions = {
  programSuperAdmin: "program:super_admin",
  programBankUpdate: "program_bank:update",
  programBankRead: "program_bank:read",
  programExternalBankUpdate: "program_external_bank:update",
  programBudgetUpdate: "program_budget:update",
  programBudgetRead: "program_budget:read",
  programSettingsUpdate: "program_settings:update",
  programSettingsRead: "program_settings:read",
  programGroupsUpdate: "program_groups:update",
  programGroupsRead: "program_groups:read",
  programGroupsArchive: "program_groups:archive",
  programAdminsManage: "program_admins:manage",
  programAdminsRead: "program_admins:read",
  programAdminsUpdate: "program_admins:update",
  programReportsUpdate: "program_reports:update",
  programReportsRead: "program_reports:read",
  programDebitCardRead: "program_debit_card:read",
  programDebitCardUpdate: "program_debit_card:update",
  programDebitCardAssign: "program_debit_card:assign",
  programTransactionsRead: "program_transactions:read",

  groupSettingsUpdate: "group_settings:update",
  groupSettingsRead: "group_settings:read",
  groupCollectionsUpdate: "group_collections:update",
  groupCollectionsRead: "group_collections:read",
  groupAdminsUpdate: "group_admins:update",
  groupMembersUpdate: "group_members:update",
  groupMembersRead: "group_members:read",
  groupReportsUpdate: "group_reports:update",
  groupReportsRead: "group_reports:read",
  groupBankUpdate: "group_bank:update",
  groupBankRead: "group_bank:read",
  groupRostersUpdate: "group_rosters:update",
  groupRostersRead: "group_rosters:read",
  groupInvoicesUpdate: "group_invoices:update",
  groupInvoicesRead: "group_invoices:read",
  groupBudgetUpdate: "group_budget:update",
  groupBudgetRead: "group_budget:read",
  groupAdminsRead: "group_admins:read",
  groupTransactionsRead: "group_transactions:read",
  groupDebitCardRead: "group_debit_card:read",
  groupDebitCardUpdate: "group_debit_card:update",
  groupDebitCardAssign: "group_debit_card:assign",

  memberTransactionsRead: "member_transactions:read",
  memberCollectionsRead: "member_collections:read",

  approver: "approver:update",
  memberRead: "member:read",
  userDirectoryUser: "profile:edit",
};

export type PermissionsResponse = {
  __typename?: string;
  me?: {
    __typename?: string;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    permissions?: Array<string | null> | null;
    id?: string | null;
    phoneNumber?: string | null;
    parents?: string[] | null;
  } | null;
};

export type RoleErrors = {
  TypeError: boolean;
  GroupError: boolean;
};
