import ProgramContext from "context/program-context";
import {
  Maybe,
  SpendAccount,
  SpendBankTransaction,
  SpendMemoInvoice,
  SpendSortInput,
} from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { getBadgeColor } from "helpers/status-color";
import { getTransactionType, getTransactionTitle } from "helpers/transaction";
import { useContext } from "react";
import { SpinnerContainer } from "shared-components/spinner";
import SortIcon from "shared-components/table/sort-icon";
import { SnapBadge, SnapTable } from "suit";

type listItemsProps = {
  listItems: SpendBankTransaction[] | undefined;
  setModalDataAndShow: (transaction: SpendBankTransaction) => void;
  handleToggleInvoiceModal: (
    item: Maybe<SpendMemoInvoice> | undefined,
    number: number
  ) => void;
  loadingTransactions?: boolean;
  exporting?: boolean;
  toggleSort: (field: string) => void;
  currentSort: SpendSortInput | undefined;
};

function Table({
  listItems,
  setModalDataAndShow,
  handleToggleInvoiceModal,
  loadingTransactions,
  exporting,
  toggleSort,
  currentSort,
}: listItemsProps) {
  const Program = useContext(ProgramContext);
  const accounts = Program?.accounts ?? [];
  const prepStatus = (d: SpendBankTransaction | any) => {
    let status = d.status.toLowerCase() || "Settled";
    let endStatus = status.substring(1);
    let text = status[0].toUpperCase() + endStatus;
    let color = getBadgeColor(status || "");

    return { text, color };
  };
  const getAccountName = (item: SpendBankTransaction) => {
    let foundAccount = accounts.find(
      (a: SpendAccount) => item.metadata?.account === `account_${a.id}`
    )?.name;
    if (!foundAccount) {
      foundAccount =
        item.direction === "CREDIT" ? item.destination : item.source;
    }
    if (item.transactionType === "credit_memo") {
      foundAccount = item.creditMemo?.groupName;
    }
    return foundAccount;
  };
  return (
    <SnapTable>
      <table className="celled ui lg:table hidden mt-0">
        <thead>
          <tr>
            <th className="w-[10%]">
              Date
              <SortIcon
                field={"date"}
                toggleSort={toggleSort}
                currentSort={currentSort}
              />
            </th>
            <th>Account</th>
            <th className="w-[20%]">Description</th>
            <th className="w-[20%]">
              Type
              <SortIcon
                field={"type"}
                toggleSort={toggleSort}
                currentSort={currentSort}
              />
            </th>
            <th>
              Method
              <SortIcon
                field={"method"}
                toggleSort={toggleSort}
                currentSort={currentSort}
              />
            </th>
            <th>
              Status
              <SortIcon
                field={"status"}
                toggleSort={toggleSort}
                currentSort={currentSort}
              />
            </th>
            <th>
              Gross
              <SortIcon
                field={"gross"}
                toggleSort={toggleSort}
                currentSort={currentSort}
              />
            </th>
            <th>
              Net
              <SortIcon
                field={"net"}
                toggleSort={toggleSort}
                currentSort={currentSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {!loadingTransactions && !exporting ? (
            listItems &&
            listItems.map((item: SpendBankTransaction, idx) => {
              return (
                <tr key={idx}>
                  <td className="py-4">
                    <p className="text-sm">
                      {FormatDate(
                        item.effective || "",
                        DateFormatSupported.Numbers
                      )}
                    </p>
                  </td>
                  <td>{getAccountName(item)}</td>
                  <td>
                    <p
                      className="text-sm text-blue-600 cursor-pointer break-words"
                      onClick={() => {
                        if (item.transactionType === "credit_memo") {
                          handleToggleInvoiceModal(
                            item.creditMemo?.invoice,
                            item.creditMemo?.creditAmount ?? 0
                          );
                        } else {
                          setModalDataAndShow(item);
                        }
                      }}
                    >
                      {getTransactionTitle(item)}
                    </p>
                  </td>
                  <td>
                    <p className="capitalize">{getTransactionType(item)}</p>
                  </td>
                  <td>
                    <p className="uppercase">
                      {item.processor === "unit"
                        ? "ACH"
                        : item.processor === "stripe"
                        ? "Card"
                        : item.transactionType === "credit_memo"
                        ? null
                        : item.transactionType}
                    </p>
                  </td>
                  <td>
                    {item.transactionType !== "credit_memo" && (
                      <SnapBadge color={prepStatus(item).color}>
                        {prepStatus(item).text}
                      </SnapBadge>
                    )}
                  </td>
                  <td>{FormatMoney(item?.amount || 0)}</td>
                  <td>
                    {FormatMoney((item?.amount ?? 0) - (item.snapAmount || 0))}
                  </td>
                </tr>
              );
            })
          ) : (
            <SpinnerContainer
              loading={(loadingTransactions || exporting) ?? false}
              inTable
              colSpan={8}
            />
          )}
        </tbody>
      </table>
    </SnapTable>
  );
}

export default Table;
