import { gql } from "@apollo/client";

export const CREATE_USER_PLAID_ACCESS = gql`
  mutation SpendUserBankAccessTokenCreate(
    $publicToken: String!
    $groupId: String!
    $status: String
  ) {
    spendUserBankAccessTokenCreate(
      publicToken: $publicToken
      groupId: $groupId
      status: $status
    ) {
      id
    }
  }
`;

export const CREATE_GROUP_PLAID_ACCESS = gql`
  mutation SpendGroupBankAccessTokenCreate(
    $publicToken: String!
    $spendGroupBankAccessTokenCreateId: String
    $status: String
  ) {
    spendGroupBankAccessTokenCreate(
      publicToken: $publicToken
      id: $spendGroupBankAccessTokenCreateId
      status: $status
    ) {
      id
    }
  }
`;

export const CREATE_ORGANIZATION_PLAID_ACCESS = gql`
  mutation SpendOrganizationBankAccessTokenCreate(
    $publicToken: String!
    $status: String
  ) {
    spendOrganizationBankAccessTokenCreate(
      publicToken: $publicToken
      status: $status
    ) {
      id
    }
  }
`;

export const DELETE_USER_PLAID_ACCESS = gql`
  mutation SpendUserBankAccessTokenDelete {
    spendUserBankAccessTokenDelete {
      success
    }
  }
`;

export const DELETE_GROUP_PLAID_ACCESS = gql`
  mutation SpendGroupBankAccessTokenDelete(
    $spendGroupBankAccessTokenDeleteId: String
  ) {
    spendGroupBankAccessTokenDelete(id: $spendGroupBankAccessTokenDeleteId) {
      success
    }
  }
`;

export const DELETE_ORGANIZATION_PLAID_ACCESS = gql`
  mutation SpendOrganizationBankAccessTokenDelete {
    spendOrganizationBankAccessTokenDelete {
      success
    }
  }
`;
