import { useMutation } from "@apollo/client";
import {
  Maybe,
  SpendGroupRoster,
  SpendInvoice,
  SpendMemo,
} from "graphql/generated";
import { UPDATE_CREDIT_MEMO } from "graphql/mutations/creditMemos";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { useContext, useEffect, useState } from "react";
import CustomModal, { BtnState } from "shared-components/modal";
import TableRowLabelValue from "shared-components/table-row-label-value";
import { editMemoErrors } from "types/errors";
import Edit from "./edit";
import SelectCreditMemo from "./select";
import ToastContext from "context/toast-context";
import { isNullOrEmpty } from "helpers/null-or-empty";

type EditCreditMemoProps = {
  editMemoOpen: boolean;
  editMemoToggle: () => void;
  participant: SpendGroupRoster;
  invoice: Maybe<SpendInvoice>;
};

function EditCreditMemo({
  editMemoOpen,
  editMemoToggle,
  participant,
  invoice,
}: EditCreditMemoProps) {
  const toast = useContext(ToastContext);
  const [selectedCreditMemo, setSelectedCreditMemo] = useState<SpendMemo>({
    creditAmount: 0,
    creditApplied: 0,
    dateToApply: "",
    id: "",
    isArchived: false,
    note: "",
    title: "",
  });
  const [hasErrors, setHasErrors] = useState<editMemoErrors>({
    amountError: false,
    dateError: false,
  });
  const [isBtnActive, setIsBtnActive] = useState(true);

  const [updateMemo, { loading, data }] = useMutation(UPDATE_CREDIT_MEMO, {
    refetchQueries: ["GetParticipantsDetails"],
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!loading && data) {
      toast?.setToast({
        message: "Credit memo successfully updated",
        type: "success",
      });
      setIsBtnActive(true);
      editMemoToggle();
    }
    // eslint-disable-next-line
  }, [loading, data]);

  const handleSaveChanges = () => {
    let memosTotal = 0;
    invoice?.creditMemos?.forEach((memo) => {
      if (memo.id !== selectedCreditMemo.id) {
        memosTotal += memo.creditApplied;
      }
    });

    if (invoice?.amount! - memosTotal < selectedCreditMemo.creditApplied) {
      setHasErrors({
        ...hasErrors,
        amountError: true,
      });
      hasErrors.amountError = true;
      toast?.setToast({
        message: "Amount exceeds invoice amount",
        type: "danger",
      });
    } else if (isNullOrEmpty(selectedCreditMemo.dateToApply)) {
      toast?.setToast({
        message: "Date is missing",
        type: "danger",
      });
      setHasErrors({
        ...hasErrors,
        dateError: true,
      });
      hasErrors.dateError = true;
    } else {
      setIsBtnActive(false);
      updateMemo({
        variables: {
          input: {
            id: selectedCreditMemo.id,
            invoiceId: invoice?.id,
            dateToApply: selectedCreditMemo.dateToApply,
            note: selectedCreditMemo.note,
            isArchived: selectedCreditMemo.isArchived,
            creditApplied: selectedCreditMemo.creditApplied,
          },
        },
      });
    }
  };

  const handleDelete = (memo: SpendMemo) => {
    updateMemo({
      variables: {
        input: {
          id: memo.id,
          invoiceId: invoice?.id,
          dateToApply: memo.dateToApply,
          note: memo.note,
          isArchived: true,
          creditApplied: memo.creditApplied,
        },
      },
    });
    editMemoToggle();
  };

  const handleResetValues = () => {
    setSelectedCreditMemo({
      creditAmount: 0,
      creditApplied: 0,
      dateToApply: "",
      id: "",
      isArchived: false,
      note: "",
      title: "",
    });
  };

  const handleResetErrors = () => {
    setHasErrors({
      amountError: false,
      dateError: false,
    });
  };

  return (
    <CustomModal
      isOpen={editMemoOpen}
      toggle={() => {
        setIsBtnActive(true);
        editMemoToggle();
      }}
      title={selectedCreditMemo.id !== "" ? "" : "Edit Credit Memo"}
      btn1={{
        text: "Save Changes",
        onClick: handleSaveChanges,
        btnState:
          selectedCreditMemo.id === ""
            ? BtnState.HIDDEN
            : isBtnActive
            ? BtnState.BASE
            : BtnState.DISABLED,
      }}
      btn2={{
        text: "Cancel",
        onClick: () => {
          setIsBtnActive(true);
          handleResetValues();
          handleResetErrors();
          editMemoToggle();
        },
      }}
      hasBackBtn={
        selectedCreditMemo.id !== ""
          ? {
              text: "Back",
              onClick: () => {
                handleResetValues();
              },
            }
          : undefined
      }
    >
      <div className="modal-card">
        <table className="w-72">
          <tbody>
            <TableRowLabelValue
              label={"Participant"}
              value={participant.roster?.name ?? ""}
            />
            <TableRowLabelValue
              label={"Group"}
              value={participant.group?.name ?? ""}
              labelStyle="py-4"
              className="py-4"
            />
            <TableRowLabelValue
              label={"Season"}
              value={
                participant.group?.latestSeason?.name ??
                `${FormatDate(
                  participant.group?.latestSeason?.startDateAt ?? "",
                  DateFormatSupported.Numbers
                )} - ${FormatDate(
                  participant.group?.latestSeason?.endDateAt ?? "",
                  DateFormatSupported.Numbers
                )}`
              }
            />
            <TableRowLabelValue
              label={"Invoice"}
              value={invoice?.description ?? ""}
              labelStyle="py-4"
              className="py-4"
            />
          </tbody>
        </table>
        {selectedCreditMemo.id !== "" ? (
          <Edit
            selectedCreditMemo={selectedCreditMemo}
            setSelectedCreditMemo={setSelectedCreditMemo}
            hasErrors={hasErrors}
            setHasErrrors={setHasErrors}
          />
        ) : (
          <>
            <SelectCreditMemo
              memos={invoice?.creditMemos ?? []}
              setSelectedCreditMemo={setSelectedCreditMemo}
              handleDelete={handleDelete}
            />
          </>
        )}
      </div>
    </CustomModal>
  );
}

export default EditCreditMemo;
