import React, { MouseEvent, useEffect, useState } from 'react';
import { SetupIntent, StripeElementsOptionsMode } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { V2 as api } from '@snap-mobile/payments-widget-client';
import {
  CreateSetupIntentData,
  StripeEnvironment,
  StripePaymentMethod,
  StripePromise,
  User,
} from '@snap-mobile/payments-widget-utils';
import ExistingPaymentMethod from './ExistingPaymentMethod';
import NewPaymentMethod from './NewPaymentMethod';
import Processing from './Processing';
import { PaymentsUser } from './helpers';
import {
  PaymentConfiguration,
  SubmitData,
  WidgetAppearance,
  makeStripeOptions,
} from './internal';

export interface OffSessionPaymentWidgetProps {
  appearance?: WidgetAppearance;
  description?: string;
  destination: string;
  externalPaymentId: string;
  finalDestination?: string;
  idempotencyKey: string;
  metadata?: object;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => Promise<void>;
  onError?: (message: string) => Promise<void>;
  onSuccess: (data: SetupIntent) => Promise<void>;
  permittedPaymentMethods?: Array<StripePaymentMethod>;
  refresh?: boolean;
  returnUrl: string;
  stripeEnvironment?: StripeEnvironment;
  stripePromise: StripePromise;
  user: PaymentsUser;
}

export function OffSessionPaymentWidget({
  appearance,
  destination,
  externalPaymentId,
  finalDestination,
  idempotencyKey,
  metadata,
  onClick,
  onError,
  onSuccess,
  permittedPaymentMethods,
  refresh,
  returnUrl,
  stripeEnvironment,
  stripePromise,
  description,
  user,
}: OffSessionPaymentWidgetProps) {
  const [customer, setCustomer] = useState<User | undefined>(undefined);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [useNewPm, setUseNewPm] = useState<boolean>(false);
  const [paymentConfiguration, setPaymentConfiguration] =
    useState<PaymentConfiguration>();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [attempsSetKeyCounts, setAttemptsKeyCounts] = useState(0);
  const [idempotencyKeyValue, setIdempotencyKeyValue] =
    useState<string>(idempotencyKey);

  const handleError = !!onError
    ? async (msg: string) => {
        await onError(msg);
      }
    : async (msg: string) => setError(msg);

  const submit = async ({ elements, paymentMethodId, stripe }: SubmitData) => {
    if (!(!!elements && !!stripe)) {
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError.message || 'Error submitting elements.');
      return;
    }
    setDisabled(true);
    setIsProcessing(true);
    const redirect = refresh === false ? 'if_required' : undefined;
    try {
      const data: CreateSetupIntentData = {
        automaticPaymentMethods: { enabled: !permittedPaymentMethods?.length },
        customer: customer?.customerId || undefined,
        description,
        destination: paymentConfiguration?.stripeAccountId ?? destination,
        externalPaymentId,
        finalDestination,
        idempotencyKey: idempotencyKeyValue,
        metadata: metadata ?? {},
        paymentMethod: paymentMethodId,
        permittedPaymentMethods,
        usage: 'off_session',
      };
      const si = await api.createSetupIntent(data, { stripeEnvironment });
      if (si.message) {
        handleError(si.message);
        setDisabled(false);
        setIsProcessing(false);
        return;
      }
      const resp = await stripe.confirmSetup({
        elements,
        clientSecret: si.client_secret,
        confirmParams: { return_url: returnUrl ?? window.location.href },
        redirect: redirect as any,
      });
      if (resp.error) {
        handleError(resp.error.message || 'Error confirming setup intent.');
        setDisabled(false);
        setIsProcessing(false);
      } else if (onSuccess) {
        await onSuccess(resp.setupIntent as any);
        setIsProcessing(false);
      }
    } catch (e: any) {
      handleError(e.message);
      setAttemptsKeyCounts(attempsSetKeyCounts + 1);
      setDisabled(false);
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    const gu = () => {
      api
        .getOrCreateCustomer(
          {
            description: user.description,
            email: user.email,
            metadata: user.metadata ?? {},
            idempotencyKey,
            name: user.name,
          },
          { stripeEnvironment },
        )
        .then((cus) => setCustomer(cus))
        .catch((e) => handleError(e.message || 'Error getting customer.'));
    };
    gu();
  }, []);

  useEffect(() => {
    setIdempotencyKeyValue(`${idempotencyKey}_${attempsSetKeyCounts}`);
  }, [attempsSetKeyCounts]);

  useEffect(() => {
    const gpc = () => {
      api
        .getPaymentConfiguration(destination, { stripeEnvironment })
        .then((pc) => {
          setPaymentConfiguration(pc);
        })
        .catch((e) => handleError(e.message));
    };
    gpc();
  }, []);

  const { elementsOptions, paymentElementOptions, submitButtonContent } =
    makeStripeOptions(paymentConfiguration, appearance);
  const stripeOptions = Object.assign({}, elementsOptions, {
    mode: 'setup' as any,
    setupFutureUsage: 'off_session' as any,
  });

  return (
    <div
      className="payments-widget"
      style={{ fontFamily: 'sans-serif !important' }}
    >
      {error && <div className="error">{error}</div>}
      <div>
        {isProcessing && <Processing />}
        <Elements stripe={stripePromise} options={stripeOptions as any}>
          {!customer ? null : customer!.paymentMethods.length > 0 &&
            !useNewPm ? (
            <ExistingPaymentMethod
              disabled={disabled}
              onClick={onClick}
              setUseNewPm={setUseNewPm}
              submit={submit}
              submitButtonContent={submitButtonContent}
              useNewPm={useNewPm}
              user={customer}
            />
          ) : (
            <NewPaymentMethod
              disabled={disabled}
              elementOptions={paymentElementOptions}
              hasExistingMethods={customer.paymentMethods.length > 0}
              onClick={onClick}
              setUseNewPm={setUseNewPm}
              submit={submit}
              submitButtonContent={submitButtonContent}
              useNewPm={useNewPm}
            />
          )}
        </Elements>
      </div>
    </div>
  );
}
