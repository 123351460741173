import { useEffect, useState } from "react";
import { throttle } from "../helpers/throttle";

const MOBILE_WIDTH = 768;
const DESKTOP_WIDTH = 992;

export const useScreenSize = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  // should we use debounce instead of throttle?
  const throttleWindowSizeChange = throttle(handleWindowSizeChange, 300);

  useEffect(() => {
    window.addEventListener("resize", throttleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", throttleWindowSizeChange);
    };
  }, [throttleWindowSizeChange]);

  const isMobile = width <= MOBILE_WIDTH;
  const isDesktop = width >= DESKTOP_WIDTH;

  return { isMobile, isDesktop };
};
