import { useContext } from "react";
import UserContext from "../../context/user-context";
import { usePermissionsQuery } from "graphql/generated";
import ParentDashboard from "./parent/ParentDashboard";
import AdminDashboard from "./admin";

function Dashboard() {
  const ActiveUser = useContext(UserContext);
  const { loading } = usePermissionsQuery();
  const userDashboard = ActiveUser?.isParent() ? (
    <ParentDashboard />
  ) : (
    <AdminDashboard />
  );
  return loading ? <div>Loading</div> : userDashboard;
}

export default Dashboard;
