import type { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import {
  SpendGuardianComingSoonInvoice,
  SpendGuardianInvoice,
  SpendInvoice,
} from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { useState } from "react";
import DataCard from "shared-components/data-card";
import { HorizontalValueStyle } from "shared-components/horizontal-label-value";
import { LabelValueObject } from "types/label-value-object";
import ParentStopPaymentModal from "./ParentStopPaymentModal";
import ParentInvoiceChangeRequestModal from "./parent-invoice-change-request-modal";
import { InvoiceWithSeason } from "types/invoice";

type InvoiceComingDueProps = {
  invoices: SpendGuardianComingSoonInvoice[] | InvoiceWithSeason[];
};

function InvoicesComingDue({ invoices }: InvoiceComingDueProps) {
  const [sliceBy, setSliceBy] = useState(5);

  const [isInvoiceChangeRequestModalOpen, setInvoiceChangeRequestModalOpen] =
    useState(false);
  const [isStopPaymentModalOpen, setIsStopPaymentModalOpen] = useState(false);
  const [modalFormData, setModalFormData] = useState<{
    invoice?: SpendInvoice | SpendGuardianInvoice;
    message?: string;
    seasonName?: string;
    groupName?: string;
  }>({});

  const prepInvoicesComingLD = (
    d: SpendGuardianComingSoonInvoice | InvoiceWithSeason
  ) => {
    const leftLabels: LabelValueObject[] = [];

    leftLabels.push({
      key: "Season",
      value: d.seasonName ?? "",
    });

    leftLabels.push({
      key: "Participant",
      value: d.rosterName ?? "",
    });
    if (d.__typename === "SpendGuardianComingSoonInvoice") {
      let status = d.invoiceStatus?.replace("_", " ") ?? "";
      if (d.isOptional && !d.optedIn && d.invoiceOptedOutAt != null) {
        status = "opted out";
      } else if (d.invoiceAutoPayAuthorized) {
        if (d.isAuthorized && !!d.authorizedAt) {
          status = d.invoiceAutoPayStopped
            ? "autopay stopped"
            : "autopay enabled";
        } else {
          status = "awaiting approval";
        }
      } else if (
        new Date(FormatDate(d.invoiceDueDate ?? "")) <
        new Date(FormatDate(new Date()))
      ) {
        status = "Past Due";
      }

      leftLabels.push({
        key: "Group",
        value: d.groupName ?? "",
      });
      if (d.invoicePaymentMethod !== null) {
        leftLabels.push({
          key: "Method",
          value:
            d.invoicePaymentMethod === "ACH"
              ? "BANK"
              : d.invoicePaymentMethod ?? "n/a",
        });
      }
      leftLabels.push({
        key: "Status",
        value: status,
        valueStyle: HorizontalValueStyle.Badge,
      });
      leftLabels.push({
        key: "Note",
        value: d.invoiceNote ?? ""
      })
    }
    if (d.__typename === "SpendInvoice") {
      leftLabels.push({ key: "Description", value: d.description ?? "" });
      if (d.isAutoPayAuthorized && d.paymentMethodSource !== null) {
        leftLabels.push({
          key: "Method",
          value:
            d.paymentMethodSource === "ACH"
              ? "BANK"
              : d.paymentMethodSource ?? "n/a",
        });
      }
      leftLabels.push({
        key: "Status",
        value: d.paid ? "Paid" : "",
        valueStyle: HorizontalValueStyle.Badge,
      });
    }
    return leftLabels;
  };
  const prepInvoicesComingRD = (
    d: SpendGuardianComingSoonInvoice | SpendInvoice
  ) => {
    const rightLabels: LabelValueObject[] = [];
    if (d.__typename === "SpendGuardianComingSoonInvoice") {
      rightLabels.push({
        key: "Due Date",
        value: FormatDate(d.invoiceDueDate ?? "", DateFormatSupported.Numbers),
      });
      rightLabels.push({
        key: "Amount Due",
        value: FormatMoney(Number(d.invoiceAmountDue) ?? 0),
        hasWarning: d.invoiceStatus === "past_due",
      });
    }
    if (d.__typename === "SpendInvoice") {
      rightLabels.push({
        key: "Due Date",
        value: FormatDate(d.dueDate ?? "", DateFormatSupported.Numbers),
      });
      rightLabels.push({
        key: "Amount Due",
        value: FormatMoney(Number(d.balanceDue) ?? 0),
        hasWarning: d.status === "past_due",
      });
    }
    return rightLabels;
  };

  const invoiceActionSelectItems = (
    invoice: SpendGuardianComingSoonInvoice | SpendInvoice
  ) => {
    const options: DropdownMenuItem[] = [];
    if (invoice.__typename === "SpendGuardianComingSoonInvoice") {
      options.push({
        name: "Request Change",
        text: "Request Change",
        value: 0,
        selected: false,
      });
      if (invoice.invoiceAutoPayAuthorized) {
        options.push({
          name: "Stop Payment",
          text: "Stop Payment",
          value: 1,
          selected: false,
        });
      }
    }
    if (invoice.__typename === "SpendInvoice" && invoice.isAutoPayAuthorized) {
      options.push({
        name: "Stop Payment",
        text: "Stop Payment",
        value: 1,
        selected: false,
      });
    }

    return options;
  };

  return (
    <div className="mt-6">
      <div className="flex justify-between mb-4 lg:mb-3">
        <div className="text-gray-800 text-lg font-semibold">
          Upcoming Invoices
        </div>
        {invoices.length > 5 && (
          <p
            className="font-bold text-blue-600 cursor-pointer"
            onClick={() => {
              if (sliceBy === 5) {
                setSliceBy(invoices.length);
              } else {
                setSliceBy(5);
              }
            }}
          >
            See {sliceBy === 5 ? "All" : "Less"}
          </p>
        )}
      </div>
      {invoices.length === 0 && <p>No upcoming invoices </p>}
      {invoices &&
        invoices
          .slice(0, sliceBy)
          .map(
            (
              dat: SpendGuardianComingSoonInvoice | InvoiceWithSeason,
              idx: number
            ) => {
              let invoiceData: SpendInvoice;
              if (dat.__typename === "SpendGuardianComingSoonInvoice") {
                invoiceData = {
                  dueDate: dat.invoiceDueDate,
                  balanceDue: Number(dat.invoiceAmountDue),
                  id: dat.invoiceId,
                  description: dat.invoiceDescription,
                };
              }
              if (dat.__typename === "SpendInvoice") {
                invoiceData = {
                  dueDate: dat.dueDate,
                  balanceDue: Number(dat.balanceDue),
                  id: dat.id,
                  description: dat.description,
                };
              }
              return (
                <DataCard
                  className="mb-2"
                  key={`Invoice-${idx}`}
                  title={
                    dat.__typename === "SpendGuardianComingSoonInvoice"
                      ? dat.invoiceDescription ?? "Invoice"
                      : dat.__typename === "SpendInvoice"
                      ? dat.description ?? "Invoice"
                      : "Invoice"
                  }
                  kvLeft={prepInvoicesComingLD(dat)}
                  kvRight={prepInvoicesComingRD(dat)}
                  action={1} // 1 is menu and only needs menuClickListener
                  menuItems={invoiceActionSelectItems(dat)}
                  menuClickListener={(val: number) => {
                    setModalFormData({
                      invoice: {
                        dueDate: invoiceData.dueDate,
                        balanceDue: Number(invoiceData.balanceDue),
                        id: invoiceData.id,
                        description: invoiceData.description,
                      },
                      message: "",
                      seasonName: dat.seasonName ?? "",
                      groupName:
                        dat.__typename === "SpendInvoice"
                          ? dat.groupName!
                          : dat.__typename === "SpendGuardianComingSoonInvoice"
                          ? dat.groupName!
                          : "",
                    });
                    if (val === 0) {
                      setInvoiceChangeRequestModalOpen(true);
                    } else {
                      setIsStopPaymentModalOpen(true);
                    }
                  }}
                  titleHasBorder={false}
                />
              );
            }
          )}
      {isInvoiceChangeRequestModalOpen && (
        <ParentInvoiceChangeRequestModal
          isOpen={isInvoiceChangeRequestModalOpen}
          toggle={() => setInvoiceChangeRequestModalOpen(false)}
          formData={modalFormData}
        />
      )}
      {isStopPaymentModalOpen && (
        <ParentStopPaymentModal
          isOpen={isStopPaymentModalOpen}
          toggle={() => setIsStopPaymentModalOpen(!isStopPaymentModalOpen)}
          stopPaymentForm={modalFormData}
          setStopPaymentForm={setModalFormData}
        />
      )}
    </div>
  );
}

export default InvoicesComingDue;
