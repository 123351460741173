import { useState } from "react";

export type ToastLevel = "warning" | "danger" | "success" | "info";
export type ToastProp = {
  title?: string;
  message: string;
  type: ToastLevel;
};
export type ToastType = {
  isToastOpen: boolean;
  toggleToast: (toggle?: boolean) => void;
  title?: string;
  message: string;
  type: ToastLevel;
  setToastProps: React.Dispatch<React.SetStateAction<ToastProp>>;
  setToast: (data: ToastProp) => void;
};

export default function useToast(
  initMessage?: string,
  initType?: ToastLevel,
  initTitle?: string
): ToastType {
  const [isToastOpen, setisToastOpen] = useState(false);
  const [toastProps, setToastProps] = useState<ToastProp>({
    title: initTitle ?? "",
    message: initMessage ?? "",
    type: initType ?? "success",
  });
  const toggleToast = (toggle?: boolean) => {
    setisToastOpen(toggle ?? !isToastOpen);
  };

  const setToast = ({ message, type, title }: ToastProp) => {
    if (isToastOpen) {
      setisToastOpen(false);
    }

    setTimeout(() => {
      setToastProps({
        title: !title ? message : title,
        message: !title ? "" : message,
        type: type || "info",
      });
      setisToastOpen(true);
    }, 0);
  };

  return {
    isToastOpen,
    toggleToast,
    title: toastProps.title,
    message: toastProps.message,
    type: toastProps.type,
    setToastProps,
    setToast,
  };
}
