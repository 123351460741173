import React from "react";
import CustomModal, { BtnType } from "shared-components/modal";
import {
  SnapAlert,
  SnapAlertDescription,
  SnapAlertTitle,
  SnapInput,
} from "suit";

type VerifyCodeModalProps = {
  isOpen: boolean;
  toggle: () => void;
  addBankToggle: React.Dispatch<React.SetStateAction<boolean>>;
};

function VerifyCodeModal({
  isOpen,
  toggle,
  addBankToggle,
}: VerifyCodeModalProps) {
  const btn1: BtnType = {
    text: "Send",
    onClick: toggle,
  };
  const btn2: BtnType = {
    text: "Cancel",
    onClick: toggle,
    btnStyle: "tertiary",
  };
  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      title={"Verification Code"}
      btn1={btn1}
      btn2={btn2}
      customStyle={"lg:w-[595px]"}
    >
      <div className="modal-card">
        <SnapAlert type="info" className={`mt-2`}>
          <SnapAlertTitle>Enter the verification code sent to</SnapAlertTitle>
          <SnapAlertDescription className="font-bold">
            aaron+dev.owner.combo.ccc@ground.work
          </SnapAlertDescription>
        </SnapAlert>
        <div className="mt-6">Verification Code</div>
        <div className="flex justify-start">
          <SnapInput
            _id={"digit1"}
            maxlength="1"
            _type="text"
            className="mr-3 w-10"
          />
          <SnapInput
            _id={"digit2"}
            maxlength="1"
            _type="text"
            className="mr-3 w-10"
          />
          <SnapInput
            _id={"digit3"}
            maxlength="1"
            _type="text"
            className="mr-3 w-10"
          />
          <SnapInput
            _id={"digit4"}
            maxlength="1"
            _type="text"
            className="mr-3 w-10"
          />
          <SnapInput
            _id={"digit5"}
            maxlength="1"
            _type="text"
            className="mr-3 w-10"
          />
          <SnapInput
            _id={"digit6"}
            maxlength="1"
            _type="text"
            className="mr-3 w-10"
          />
        </div>
        <div className="text-gray-500 text-sm mt-1">
          Please enter your 6-digit verification code.
        </div>
      </div>
    </CustomModal>
  );
}

export default VerifyCodeModal;
