import GroupContext from "context/group-context";
import {
  Maybe,
  SpendBankTransactionDetail,
  SpendMemo,
} from "graphql/generated";
import { FormatMoney } from "helpers/format-money";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SnapIcon, SnapLink, SnapTable } from "suit";

type TransactionInvoicesCardProps = {
  transaction: SpendBankTransactionDetail | undefined;
  setSelectedModal: React.Dispatch<React.SetStateAction<number>>;
  setSelectedTransIdx: React.Dispatch<React.SetStateAction<number>>;
  isParentView?: boolean;
};

type InvoiceItem = {
  description: string;
  participantName: string;
  groupName: string;
  seasonName: string;
  amount: number;
  budgetItemName: string;
  budgetItemId: string;
  categoryName: string;
  categoryId: string;
  groupId: string;
  rosterId: string;
  creditMemos?: Maybe<Maybe<SpendMemo>[]> | undefined;
};

function TransactionInvoicesCard({
  transaction,
  setSelectedModal,
  setSelectedTransIdx,
  isParentView = false,
}: TransactionInvoicesCardProps) {
  const navigate = useNavigate();
  const Group = useContext(GroupContext);
  const handleNavGroup = (groupId: string) => {
    Group?.navigateGroupPage("collections", groupId);
  };

  const handleNavigateParticipant = (userId: string) => {
    navigate(`/people/participants/participant-details/${userId}`);
  };

  const [invoiceItems, setInvoices] = useState<InvoiceItem[]>([]);
  const [hasMemos, setHasMemos] = useState(false);

  useEffect(() => {
    if (
      transaction?.reconciliation?.invoiceTransactions &&
      transaction.reconciliation.reconciledTo
    ) {
      let arr: InvoiceItem[] = [];
      transaction.reconciliation.invoiceTransactions.forEach((IT) => {
        let participantName;
        let rosterId;
        let creditMemos;
        let foundItem = transaction.reconciliation?.reconciledTo?.find(
          (RT) => RT?.invoiceId === IT?.invoiceId
        );
        if (foundItem?.invoiceId === IT?.invoiceId) {
          participantName = IT?.rosterName;
          rosterId = IT?.rosterId;
          if (IT?.creditMemos?.length) {
            setHasMemos(true);
            creditMemos = IT?.creditMemos;
          }
        }
        arr.push({
          description: foundItem?.description ?? "",
          participantName: participantName ?? "",
          groupName: foundItem?.groupName ?? "",
          seasonName: foundItem?.seasonName ?? "",
          amount: foundItem?.amount ?? 0,
          budgetItemName: foundItem?.budgetName ?? "",
          budgetItemId: foundItem?.budgetName ?? "",
          categoryName: foundItem?.categoryName ?? "",
          categoryId: foundItem?.categoryId ?? "",
          groupId: foundItem?.groupId ?? "",
          rosterId: rosterId ?? "",
          creditMemos: creditMemos,
        });
      });
      setInvoices(arr);
    }
  }, [transaction]);

  return (
    <>
      <div className="mr-auto mb-0 pt-4">
        <div className="hidden">{transaction?.id}</div>
        <div className="flex font-semibold text-base">Invoice Items</div>
        <SnapTable>
          <table className="ui celled table">
            <thead>
              <tr>
                <th className="w-1/3 max-w-[33%]">Description</th>
                <th>Participant</th>
                <th>Group</th>
                <th>Season</th>
                {hasMemos && <th>Credit Amount</th>}
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {invoiceItems.map((inv: InvoiceItem, idx: number) => {
                const amnt_xs_styles =
                  "border-t-2 border-t-gray-200 flex justify-between";
                const amnt_md_styles =
                  "md:border-0 md:table-cell md:text-right";
                let memoAppliedAmount = 0;
                inv.creditMemos?.forEach(
                  (memo) => (memoAppliedAmount += memo?.creditApplied ?? 0)
                );

                return (
                  <tr key={idx}>
                    <td className="border-b-2 border-b-gray-200 md-border-b-0">
                      <div className="flex justify-between">
                        <div className="flex-col py-1">
                          <div className=" font-bold">
                            {inv.description?.slice(0, 22)}
                          </div>
                          <p className="text-xs">
                            {`${inv.categoryName} | ${inv.budgetItemName}`}{" "}
                            {!isParentView && (
                              <SnapLink
                                size="sm"
                                sr-only="srOnly"
                                text="Edit"
                                className="inline"
                                onClick={() => {
                                  setSelectedTransIdx(idx);
                                  setSelectedModal(1);
                                }}
                              ></SnapLink>
                            )}
                          </p>
                          {inv.creditMemos?.map((memo) => {
                            return (
                              <p key={memo?.id}>{`[CREDIT] ${memo?.title}`}</p>
                            );
                          })}
                        </div>
                        <SnapIcon
                          className="md:hidden"
                          icon="trash-solid"
                        ></SnapIcon>
                      </div>
                    </td>
                    <td data-label="Participant">
                      <div
                        className="text-blue-600 cursor-pointer"
                        onClick={() => handleNavigateParticipant(inv.rosterId)}
                      >
                        {inv.participantName}
                      </div>
                    </td>
                    <td data-label="Group">
                      <div
                        className="text-blue-600 cursor-pointer"
                        onClick={() => handleNavGroup(inv.groupId ?? "")}
                      >
                        {inv.groupName}
                      </div>
                    </td>

                    <td data-label="Season">{inv.seasonName}</td>
                    {hasMemos && (
                      <td data-label="Memo" className="md:text-center">
                        {FormatMoney(memoAppliedAmount)}
                      </td>
                    )}
                    <td
                      data-label="Amount"
                      className={`${amnt_xs_styles} ${amnt_md_styles}`}
                    >
                      {FormatMoney(inv.amount ?? 0)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </SnapTable>
      </div>
    </>
  );
}

export default TransactionInvoicesCard;
