import DisplayContext from "context/display-context";
import { SpendMemo } from "graphql/generated";
import { useContext } from "react";
import Cards from "./cards";
import Table from "./table";

type SelectCreditMemoProps = {
  memos: SpendMemo[];
  setSelectedCreditMemo: React.Dispatch<React.SetStateAction<SpendMemo>>;
  handleDelete: (memo: SpendMemo) => void;
};

function SelectCreditMemo({
  memos,
  setSelectedCreditMemo,
  handleDelete,
}: SelectCreditMemoProps) {
  const Display = useContext(DisplayContext);

  return (
    <>
      {Display?.isDesktop ? (
        <Table
          memos={memos}
          setSelectedCreditMemo={setSelectedCreditMemo}
          handleDelete={handleDelete}
        />
      ) : (
        <Cards
          memos={memos}
          setSelectedCreditMemo={setSelectedCreditMemo}
          handleDelete={handleDelete}
        />
      )}
    </>
  );
}

export default SelectCreditMemo;
