import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { SnapIcon, SnapSelectMenu, SnapTable } from "suit";
import { SpendPaymentScheduleWithFees } from "../../participants-roster";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import { GET_GROUP_ROSTER_IDS } from "graphql/queries/group";
import { useContext, useEffect, useState } from "react";
import GroupContext from "context/group-context";
import {
  SpendGroupRoster,
  SpendPaymentSchedule,
  useSpendPaymentScheduleUpdateMutation,
} from "graphql/generated";
import Spinner from "shared-components/spinner";
import { useMutation, useQuery } from "@apollo/client";
import { ARCHIVE_PAYMENT_SCHEDULE_INVOICES } from "graphql/mutations/invoice";
import SeasonContext from "context/season-context";
import { PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON } from "graphql/queries/payment-schedule";
import DatePicker from "shared-components/date-picker";
import { getDatePickerValue, setDatePickerValue } from "helpers/date-picker";

type PaymentScheduleTableProps = {
  invoices: SpendPaymentScheduleWithFees[];
  categories: SnapSelectMenuOption[];
  isEdit: boolean;
  setInvoices?: React.Dispatch<
    React.SetStateAction<SpendPaymentScheduleWithFees[]>
  >;
};

function PaymentScheduleTable({
  invoices,
  categories,
  isEdit,
  setInvoices,
}: PaymentScheduleTableProps) {
  const activeGroup = useContext(GroupContext)?.activeGroup;
  const Season = useContext(SeasonContext);
  const [idIsLoading, setIdIsLoading] = useState<string | undefined>();
  const { data, loading } = useQuery(GET_GROUP_ROSTER_IDS, {
    variables: { filterBy: "groupId", filterValue: activeGroup?.id },
    fetchPolicy: "network-only",
  });
  const [rosterIds, setRosterIds] = useState<string[]>([]);
  const [updatePSI] = useSpendPaymentScheduleUpdateMutation({
    refetchQueries: [
      "SpendGroupRostersBySeason",
      {
        query: PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON,
        variables: {
          seasonId: Season?.selectedSeason?.id,
          groupIdOrSeasonId: Season?.selectedSeason?.id,
        },
        fetchPolicy: "network-only",
      },
    ],
  });
  const [archiveInvoices] = useMutation(ARCHIVE_PAYMENT_SCHEDULE_INVOICES, {
    refetchQueries: [
      "SpendGroupRostersBySeason",
      {
        query: PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON,
        variables: {
          seasonId: Season?.selectedSeason?.id,
          groupIdOrSeasonId: Season?.selectedSeason?.id,
        },
        fetchPolicy: "network-only",
      },
    ],
  });
  const saveInvoice = (invoice: Partial<SpendPaymentSchedule>) => {
    if (!invoice.id) {
      console.error("Trying to save invoice without an Id", invoice);
      return;
    }
    updatePSI({
      variables: {
        spendPaymentScheduleUpdateId: invoice.id,
        input: {
          dueDate: invoice.dueDate && new Date(invoice.dueDate).toISOString(),
          budgetItemId: invoice.budgetItemId,
          status: invoice.status,
          seasonId: Season?.selectedSeason?.id,
        },
      },
    });
  };
  const archiveInvoice = (invoice: Partial<SpendPaymentSchedule>) => {
    if (!invoice.id) {
      console.error("Trying to save invoice without an Id", invoice);
      return;
    }
    archiveInvoices({
      variables: {
        spendPaymentScheduleUpdateId: invoice.id,
        input: {
          dueDate: invoice.dueDate && new Date(invoice.dueDate).toISOString(),
          budgetItemId: invoice.budgetItemId,
          status: invoice.status,
          seasonId: Season?.selectedSeason?.id,
        },
        rosterIds: rosterIds,
      },
    });
  };
  useEffect(() => {
    if (data && data.spendGroupRosters) {
      const allRosterIds = data.spendGroupRosters.groupRosters.map(
        (roster: SpendGroupRoster) => roster.rosterId
      );
      setRosterIds(allRosterIds);
    }
  }, [data, loading]);
  useEffect(() => {
    setIdIsLoading(undefined);
  }, [invoices]);
  const baseTextClass = `text-sm ml-2 overflow-hidden text-overflow-clip whitespace-pre-wrap break-words`;
  return (
    <SnapTable>
      {isEdit ? (
        <table className="ui lg:table hidden table-fixed">
          <thead>
            <tr>
              <th className="w-[15%]">Description</th>
              <th className="w-[15%]">Due Date</th>
              <th className="w-[25%]">Reconcile To</th>
              <th>Collecting</th>
              <th align="center">Fees</th>
              <th className="w-[15%]" align="center">
                Guardian Pays
              </th>
              <th align="center">Optional?</th>
              <th className="w-[4%]"></th>
            </tr>
          </thead>
          <tbody>
            {!invoices.length && (
              <tr>
                <td colSpan={8} className="py-4">
                  <p>Payment schedule empty, add invoices below.</p>
                </td>
              </tr>
            )}
            {invoices.map((invoice, idx) => {
              let options: SnapSelectMenuOption[] = categories.map(
                (cat: SnapSelectMenuOption) => {
                  return {
                    ...cat,
                    selected: invoice.budgetItemId === cat.value,
                  };
                }
              );
              const cellStyleOverride = {
                backgroundColor: `${
                  invoice.status === "deleted"
                    ? "#FFAAAA50"
                    : invoice.status === "new"
                    ? "#fefce8"
                    : ""
                }`,
              };
              return (
                <tr key={`${invoice.id}_${idx}+${invoice.status}`}>
                  <td className="py-4" style={cellStyleOverride}>
                    <p className={baseTextClass}>{invoice.description}</p>
                    <p className={`text-xs ${baseTextClass}`}>{invoice.note}</p>
                  </td>
                  <td style={cellStyleOverride}>
                    <DatePicker
                      label={""}
                      date={getDatePickerValue(invoice.dueDate ?? "")}
                      setDate={(e) => {
                        const newDate = setDatePickerValue(e);
                        saveInvoice({
                          id: invoice.id,
                          seasonId: invoice.seasonId,
                          dueDate: newDate,
                        });
                        invoices.splice(idx, 1, {
                          ...invoice,
                          dueDate: newDate,
                        });
                        setInvoices && setInvoices([...invoices]);
                      }}
                    />
                  </td>
                  <td style={cellStyleOverride}>
                    <SnapSelectMenu
                      placeholder="Select a budget"
                      selectMenuOptions={[...options]}
                      disabled={invoice.status === "deleted"}
                      error={invoice.hasError}
                      onSnap-select-menu-updated={(e) => {
                        const selected = e.detail.find(
                          (option) => option.selected
                        );
                        saveInvoice({
                          id: invoice.id,
                          seasonId: invoice.season?.id,
                          dueDate: invoice.dueDate,
                          budgetItemId: selected?.value,
                        });
                        invoices.splice(idx, 1, {
                          ...invoice,
                          hasError: false,
                        });
                        setInvoices && setInvoices([...invoices]);
                      }}
                    />
                  </td>
                  <td align="center" style={cellStyleOverride}>
                    <p className="text-green-600">
                      {FormatMoney(
                        invoice.received
                          ? invoice.received
                          : invoice.amountDue ?? 0
                      )}
                    </p>
                  </td>
                  <td align="center" style={cellStyleOverride}>
                    {FormatMoney(invoice.feeAmount ? invoice.feeAmount : 0)}
                  </td>
                  <td align="center" style={cellStyleOverride}>
                    {invoice.amountDue && FormatMoney(invoice.amountDue)}
                  </td>
                  <td align="center" style={cellStyleOverride}>
                    <p>{invoice.isOptional ? "Yes" : "No"}</p>
                  </td>
                  <td style={cellStyleOverride}>
                    {idIsLoading === invoice.id && <Spinner size={"small"} />}
                    {idIsLoading !== invoice.id && (
                      <SnapIcon
                        icon={`${
                          invoice.status === "deleted"
                            ? "refresh-line"
                            : "trash-solid"
                        }`}
                        color="#2563EB"
                        className="cursor-pointer"
                        onClick={() => {
                          if (!idIsLoading) {
                            setIdIsLoading(invoice.id || undefined);
                            const status = ["draft", "new"].includes(
                              invoice.status ?? ""
                            )
                              ? "deleted"
                              : "draft";
                            archiveInvoice({ ...invoice, status });
                          }
                        }}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <table className="ui lg:table hidden table-fixed">
          <thead>
            <tr>
              <th colSpan={2}>Description</th>
              <th>Due Date</th>
              <th>You Receive</th>
              <th>Fees</th>
              <th>Guardian Pays</th>
              <th>Optional?</th>
            </tr>
          </thead>
          <tbody>
            {!invoices.length && (
              <tr>
                <td colSpan={6} className="py-4">
                  Payment schedule empty, continue editing to add invoices.
                </td>
              </tr>
            )}
            {invoices.map((invoice, idx) => {
              return (
                <tr key={`${invoice.id}_${idx}`}>
                  <td colSpan={2} className="py-4">
                    <p className={baseTextClass}>{invoice.description}</p>
                    <p className={`text-xs ${baseTextClass}`}>{invoice.note}</p>
                  </td>
                  <td>
                    {invoice.dueDate &&
                      FormatDate(invoice.dueDate, DateFormatSupported.Numbers)}
                  </td>
                  <td>
                    {FormatMoney(
                      invoice.received
                        ? invoice.received
                        : invoice.amountDue ?? 0
                    )}
                  </td>
                  <td>
                    {FormatMoney(invoice.feeAmount ? invoice.feeAmount : 0)}
                  </td>
                  <td>{invoice.amountDue && FormatMoney(invoice.amountDue)}</td>
                  <td>
                    <p>{invoice.isOptional ? "Optional" : "Required"}</p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </SnapTable>
  );
}

export default PaymentScheduleTable;
