import { OnSessionPaymentWidget } from "@snap-mobile/payments-widget-frontend";
import { StripeEnvironment } from "@snap-mobile/payments-widget-utils";
import { stripePromise } from "../..";
import "./payment-component.css";
import { FormatMoney } from "helpers/format-money";

export type PayerDeets = {
  id: string;
  name: string;
  email: string;
};

/**
 * Unified metadata for transactions submitted by Spend-API to Payments-API
 *  In Stripe, it will be go to metadata. In Unit, it will go to tags
 */
type SpendPaymentMetadata = {
  spendExternalId: string; // cuid keep track in externalId in Transactions
  spendPaymentType:
    | "parentPayment"
    | "internalTransfer"
    | "externalTransfer"
    | "refund";
  spendSourceId: string;
  spendDestinationId: string;
  spendInvoiceIds?: string; // list of invoice ids, separated by comma
  spendOriginalPaymentId?: string; // used for refund, trace back the original payment
  spendUserId?: string;
} & {
  [key: string]: string;
};

export type PaymentComponentProps = {
  finalDestination: string;
  description: string;
  amount: number;
  snapAmount: number;
  externalPaymentId: string;
  returnUrl: string;
  payerDetails: PayerDeets;
  metadata: SpendPaymentMetadata;
  idempotencyKey: string;
  className?: string;
  options?: any;
};

function PaymentComponent({
  finalDestination,
  description: finalDestinationName,
  externalPaymentId,
  amount,
  snapAmount,
  returnUrl,
  payerDetails,
  metadata,
  className,
  idempotencyKey,
  options,
}: PaymentComponentProps) {
  /**
   * Stripe returns to the provided returnUrl with query params below
   * http://localhost:3000/invoices/?
   * payment_intent=pi_3Md4nKBAuPO34Ji60RN2eu2j
   * &payment_intent_client_secret=pi_3Md4nKBAuPO34Ji60RN2eu2j_secret_jQwY1fIYPIQdikgBc4GgixdT4
   * &redirect_status=succeeded
   *
   * additional query params are added for spend book-keeping
   * &external_id= cuid() generated id
   * &data= btoa() compiled data set of invoice id array
   */
  const paymentsObject = {
    destination: finalDestination,
    finalDestination: finalDestination,
    description: `${finalDestinationName}`.slice(0, 20),
    externalPaymentId: externalPaymentId,
    returnUrl: options.spe1741Webhook
      ? `${returnUrl}?redirect_from=stripe`
      : `${returnUrl}&external_id=${externalPaymentId}&redirect_from=stripe`,
    snapAmount: snapAmount,
    stripeEnvironment: StripeEnvironment.SPEND,
    stripePromise: stripePromise,
    totalAmount: amount,
    metadata: {
      ...metadata,
      product: "Spend",
    },
    user: {
      name: payerDetails.name,
      email: payerDetails.email,
      metadata: { id: payerDetails.id },
    },
    idempotencyKey,
  };
  return (
    <div className={`border-2 p-6 mb-10 ${className}`}>
      <OnSessionPaymentWidget
        appearance={{ submitButtonContent: `Pay ${FormatMoney(amount)} Now` }}
        destination={paymentsObject.destination}
        description={paymentsObject.description}
        finalDestination={paymentsObject.finalDestination}
        externalPaymentId={paymentsObject.externalPaymentId}
        returnUrl={paymentsObject.returnUrl}
        snapAmount={paymentsObject.snapAmount}
        stripeEnvironment={paymentsObject.stripeEnvironment}
        stripePromise={paymentsObject.stripePromise}
        totalAmount={paymentsObject.totalAmount}
        metadata={paymentsObject.metadata}
        statementDescriptorSuffix={"Spend"}
        user={paymentsObject.user}
        idempotencyKey={idempotencyKey}
        cancelUrl={window.location.href}
      />
    </div>
  );
}

export default PaymentComponent;
