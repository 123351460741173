import { useState } from "react";

export default function useModal() {
  const [isOpen, setisOpen] = useState(false);
  const [tabSelectedValue, setTabSelectedValue] = useState(0);
  const toggle = () => {
    setisOpen(!isOpen);
  };
  const setSelectedTab = (index: number) => {
    setTabSelectedValue(index);
  };

  return {
    isOpen,
    toggle,
    tabSelectedValue,
    setSelectedTab,
  };
}
