import awaitingDocuments from "assets/Incomplete_Application.svg";
import { Maybe } from "graphql/generated";
import { SnapButton } from "suit";

type AwaitingProps = {
  applicationURL: Maybe<string> | undefined;
};

function Awaiting({ applicationURL }: AwaitingProps) {
  return (
    <div className="flex flex-col items-center lg:mx-72 mx-10">
      <p className="text-lg font-semibold">Awaiting Documents</p>
      <img
        src={awaitingDocuments}
        alt="for document under review"
        className="mt-4"
      />
      <p className="text-sm text-center text-gray-500 mt-4">
        Looks like we nee a little more information about you to finish your
        request to access this program.
      </p>
      <SnapButton
        variant="primary"
        className="mt-5"
        onClick={() => {
          window.location.replace(applicationURL || "");
        }}
      >
        Finish Application
      </SnapButton>
    </div>
  );
}

export default Awaiting;
