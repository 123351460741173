import { SpendGroupRoster, SpendInvoice } from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { useEffect, useState } from "react";
import InputMask from "shared-components/input-mask";
import { SnapTable } from "suit";

type TableProps = {
  roster: SpendGroupRoster[];
  handleAmountInput: (
    amount: string,
    idx: number,
    invoiceAmount: number
  ) => void;
  invoicePlusAmount: {
    amount: string;
    invoiceId: string;
    invoiceError: boolean;
  }[];
};

function Table({ roster, handleAmountInput, invoicePlusAmount }: TableProps) {
  return (
    <SnapTable>
      <table className="ui celled hidden lg:table">
        <thead>
          <tr>
            <th className="w-40">Invoice Decription</th>
            <th className="w-40">Group</th>
            <th>Due Date</th>
            <th align="right" className="w-32">
              Amount Due
            </th>
            <th align="right">Amount to Apply</th>
          </tr>
        </thead>
        <tbody>
          {roster.map((r) => {
            return (
              r.invoices &&
              r.invoices
                .filter((i) => !i?.paid)
                .map((i, idx) => {
                  return (
                    <TR
                      key={i?.id}
                      idx={idx}
                      roster={r}
                      invoice={i as SpendInvoice}
                      handleAmountInput={handleAmountInput}
                      invoicePlusAmount={invoicePlusAmount[idx]}
                    />
                  );
                })
            );
          })}
        </tbody>
      </table>
    </SnapTable>
  );
}

const TR = ({
  idx,
  roster,
  invoice,
  handleAmountInput,
  invoicePlusAmount,
}: {
  idx: number;
  invoice: SpendInvoice;
  roster: SpendGroupRoster;
  handleAmountInput: (
    amount: string,
    idx: number,
    invoiceAmount: number
  ) => void;
  invoicePlusAmount: {
    amount: string;
    invoiceId: string;
    invoiceError: boolean;
  };
}) => {
  const [amount, setAmount] = useState("");

  useEffect(() => {
    handleAmountInput(amount, idx, Number(invoice.balanceDue));
    // eslint-disable-next-line
  }, [amount]);

  return (
    <tr>
      <td className="flex py-2 w-40">
        <p className="self-center overflow-hidden text-overflow-clip whitespace-pre-wrap break-words">
          {invoice?.description}
        </p>
      </td>
      <td className="w-40">
        <p className="self-center overflow-hidden text-overflow-clip whitespace-pre-wrap break-words">
          {roster.group?.name}
        </p>
      </td>
      <td>{FormatDate(invoice?.dueDate ?? "", DateFormatSupported.Numbers)}</td>
      <td align="right">{FormatMoney(Number(invoice?.balanceDue))}</td>
      <td>
        <InputMask
          label=""
          amount={amount}
          setAmount={setAmount}
          hasError={invoicePlusAmount.invoiceError}
          onChange={() => {}}
        />
      </td>
    </tr>
  );
};

export default Table;
