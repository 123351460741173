import { useEffect, useState } from "react";
import CustomModal, { BtnType } from "shared-components/modal";
import { SnapInput, SnapRadioButton } from "suit";

type BankAccount = {
  accountType: string;
  accountClass: string;
  routingNumber: string;
  accountNumber: string;
  confirmAccountNumber: string;
  streetAddress: string;
  streetAddress2: string;
  city: string;
  state: string;
  zipcode: string;
};

type AddBankModalProps = {
  isOpen: boolean;
  toggle: () => void;
};

function AddBankModal({ isOpen, toggle }: AddBankModalProps) {
  const [bankAccountForm, setBankAccountForm] = useState<BankAccount>({
    accountType: "personal",
    accountClass: "checking",
    routingNumber: "",
    accountNumber: "",
    confirmAccountNumber: "",
    streetAddress: "",
    streetAddress2: "",
    city: "",
    state: "",
    zipcode: "",
  });
  const [accountTypeValue, setAccountTypeValue] = useState<string>("personal");
  const [accountClassValue, setAccountClassValue] =
    useState<string>("checking");
  const btnAdd = () => {
    console.log(bankAccountForm);
    toggle();
  };
  useEffect(() => {
    const baForm = {
      ...bankAccountForm,
      accountType: accountTypeValue,
      accountClass: accountClassValue,
    };
    setBankAccountForm(baForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountTypeValue, accountClassValue]);
  const btn1: BtnType = {
    text: "Add",
    onClick: btnAdd,
  };
  const btn2: BtnType = {
    text: "Cancel",
    onClick: toggle,
    btnStyle: "tertiary",
  };

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      title={"Add Bank Account"}
      btn1={btn1}
      btn2={btn2}
      customStyle={"lg:w-[800px]"}
    >
      <div className="modal-card">
        <div className="grid lg:grid-cols-3 mb-4 lg:mb-2">
          <div className="mb-4">Account Type</div>
          <SnapRadioButton
            label="Personal"
            id="accountTypePersonal"
            className="mb-2"
            checked={accountTypeValue === "personal"}
            onClick={() => setAccountTypeValue("personal")}
          />
          <SnapRadioButton
            label="Business"
            id="accountTypeBusiness"
            checked={accountTypeValue === "business"}
            onClick={() => setAccountTypeValue("business")}
          />
        </div>
        <div className="grid lg:grid-cols-3 mb-4 lg:mb-2">
          <div className="mb-4">Account Class</div>
          <SnapRadioButton
            label="Checking"
            id="accountClassChecking"
            className="mb-2"
            checked={accountClassValue === "checking"}
            onClick={() => setAccountClassValue("checking")}
          />
          <SnapRadioButton
            label="Saving"
            id="accountClassSaving"
            checked={accountClassValue === "saving"}
            onClick={() => setAccountClassValue("saving")}
          />
        </div>
        <div className="grid mb-4">
          <SnapInput
            _id="routingNumber"
            _type="text"
            label="Routing Number"
            value={bankAccountForm.routingNumber}
            onBlur={(e) => console.log("RN", e.currentTarget.value)}
          />
        </div>
        <div className="grid lg:grid-cols-2 gap-4 lg:gap-4 mb-4">
          <SnapInput
            _id="accountNumber"
            _type="text"
            label="Account Number"
            value={bankAccountForm.accountNumber}
          />
          <SnapInput
            _id="confAccountNumber"
            _type="text"
            label="Confirm Account Number"
            value={bankAccountForm.confirmAccountNumber}
          />
        </div>
        <div className="grid mb-4">
          <SnapInput
            _id="streetAddress"
            _type="text"
            label="Street Address"
            value={bankAccountForm.streetAddress}
          />
        </div>
        <div className="grid mb-4">
          <SnapInput
            _id="streetAddress2"
            _type="text"
            label="Apartment/Suite/Unit"
            value={bankAccountForm.streetAddress2}
          />
        </div>
        <div className="grid lg:grid-cols-3 gap-4 lg:gap-4 mb-4">
          <SnapInput
            _id="city"
            _type="text"
            label="City"
            value={bankAccountForm.city}
          />
          <SnapInput
            _id="state"
            _type="text"
            label="State"
            value={bankAccountForm.state}
          />
          <SnapInput
            _id="zipcode"
            _type="text"
            label="Zip Code"
            value={bankAccountForm.zipcode}
          />
        </div>
      </div>
    </CustomModal>
  );
}

export default AddBankModal;
