import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { SnapSelectMenu } from "suit";
import { Filters } from "types/filter-types";
import DatePicker from "./date-picker";
import { getDatePickerValue, setDatePickerValue } from "helpers/date-picker";
import { isNullOrEmpty } from "helpers/null-or-empty";

type DisplayFilterOptionsProps = {
  filters: Filters[];
  isDate: boolean;
  handleFilters?: (
    filterName: string,
    detail: SnapSelectMenuOption[],
    date?: string
  ) => void;
  handleResetFilters?: () => void;
  startDate?: string;
  endDate?: string;
  setStartDate?: React.Dispatch<React.SetStateAction<string>>;
  setEndDate?: React.Dispatch<React.SetStateAction<string>>;
};

function DisplayFilterOptions({
  filters,
  isDate,
  handleFilters,
  handleResetFilters,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: DisplayFilterOptionsProps) {
  const formatDate = (selectedDate: string, dateType: string) => {
    let formattedDate = "";
    if (isNullOrEmpty(selectedDate ?? "")) {
      formattedDate = " ";
    } else {
      formattedDate = FormatDate(selectedDate, DateFormatSupported.Numbers);
    }
    switch (dateType) {
      case "Start Date":
        setStartDate && setStartDate(formattedDate);
        break;
      case "End Date":
        setEndDate && setEndDate(formattedDate);
        break;
    }

    handleFilters && handleFilters(dateType, [], formattedDate);
  };

  return (
    <>
      <div
        className={`grid ${
          filters.length <= 2
            ? "lg:grid-cols-2 grid-cols-1"
            : "lg:grid-cols-3 grid-cols-1"
        }`}
      >
        {filters.map((filter: Filters, idx) => {
          return (
            <div className={filter.className} key={idx}>
              {filter.type === "single" ? (
                <SnapSelectMenu
                  id={filter.filterName}
                  modalTitle={filter.filterName}
                  label={filter.filterName}
                  selectMenuOptions={filter.filterOptions}
                  modalType="drawer"
                  className="mt-4"
                  onSnap-select-menu-updated={(e) => {
                    handleFilters &&
                      handleFilters(filter.filterName || "", e.detail);
                  }}
                  placeholder={filter.placeholder || "All " + filter.filterName}
                ></SnapSelectMenu>
              ) : filter.type === "multi" ? (
                <SnapSelectMenu
                  modalTitle={filter.filterName}
                  label={filter.filterName}
                  selectMenuOptions={filter.filterOptions}
                  multi
                  modalType="drawer"
                  className="mt-4"
                  onSnap-select-menu-updated={(e) => {
                    handleFilters &&
                      handleFilters(filter.filterName || "", e.detail);
                  }}
                  placeholder={"All " + filter.filterName}
                ></SnapSelectMenu>
              ) : filter.type === "date" ? (
                <DatePicker
                  label={filter.filterName ?? ""}
                  date={
                    filter.filterName === "Start Date"
                      ? getDatePickerValue(startDate ?? "")
                      : getDatePickerValue(endDate ?? "")
                  }
                  setDate={(e) => {
                    const newDate = setDatePickerValue(e);
                    formatDate(newDate, filter.filterName ?? "");
                  }}
                  hasError={filter.hasError ?? false}
                />
              ) : null}
            </div>
          );
        })}
      </div>
    </>
  );
}

export default DisplayFilterOptions;
