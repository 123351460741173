import CustomModal from "shared-components/modal";
import TableRowLabelValue from "shared-components/table-row-label-value";
import { TRLVItems } from "../../pages/reporting/invoices/invoice-list-item";

type InvoiceDetailsProps = {
  isOpen: boolean;
  toggle: () => void;
  modalData: TRLVItems[];
};

function InvoiceDetails({ isOpen, toggle, modalData }: InvoiceDetailsProps) {
  return (
    <CustomModal isOpen={isOpen} toggle={toggle} title="Invoice Details">
      <div className="modal-card">
        <table className="w-full">
          <tbody>
            {modalData.map((data: TRLVItems, idx: number) => {
              return (
                <TableRowLabelValue
                  key={idx}
                  label={data.label}
                  value={data.value}
                  className={data.className}
                  valueStyle={data.valueStyle}
                  onClick={data.onClick}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </CustomModal>
  );
}

export default InvoiceDetails;
