import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import Divider from "shared-components/divider";
import HorizontalLabelValue, {
  HorizontalValueStyle,
} from "shared-components/horizontal-label-value";
import VerticalLabelValue from "shared-components/vertical-label-value";
import { SnapDropDown } from "suit";
import { SpendInvite } from "graphql/generated";
import { cardStatusToText, inviteStatusToText } from "helpers/status-text";
import { SpinnerContainer } from "shared-components/spinner";

type StaffCardProps = {
  staffInfo: SpendInvite[];
  menuItems: DropdownMenuItem[];
  menuClickListener: (idxOfStaffToDelete: number) => void;
  canEditStaff: boolean;
  isArchived: boolean;
  loading?: boolean;
};

function StaffCard({
  staffInfo,
  menuItems,
  menuClickListener,
  canEditStaff,
  isArchived,
  loading,
}: StaffCardProps) {
  return (
    <>
      {!loading ? (
        staffInfo.map((staffMember, idx) => {
          let memberStatus = inviteStatusToText(
            staffMember.status || "",
            "N/A"
          );
          let debitCards = staffMember.debitCards
            ?.map(
              (card) =>
                `${cardStatusToText(card?.status || "")} (${card?.lastFour})`
            )
            .join(", ");
          return (
            <div
              className="lg:hidden border border-gray-200 p-4 rounded-lg mt-4"
              key={idx}
            >
              <div className="flex justify-between">
                <p className="font-semibold">
                  {staffMember.firstName} {staffMember.lastName}
                </p>
                {canEditStaff && (
                  <SnapDropDown
                    id="mobile-menu"
                    left-hang="false"
                    size="lg"
                    minimal
                    modal-type="drawer"
                    options={menuItems}
                    onSnap-dropdown-item-selected={() => menuClickListener(idx)}
                  />
                )}
              </div>
              <Divider isVisibleOnMobile className="mt-2" />
              <VerticalLabelValue
                label={"Email"}
                value={staffMember.email}
                customContainerStyle={"mt-2 flex-col font-semibold"}
              />
              <VerticalLabelValue
                label={"Role"}
                value={staffMember.type?.replace("_", " ")}
                customContainerStyle={"mt-2 flex-col font-semibold"}
                className="capitalize"
              />
              <Divider isVisibleOnMobile className="my-4" />
              <HorizontalLabelValue
                label={"Status"}
                value={memberStatus}
                valueStyle={HorizontalValueStyle.Badge}
                className="ml-auto"
                isArchived={isArchived}
              />
              <div className="mt-5">
                <HorizontalLabelValue
                  label={"Debit Card"}
                  value={debitCards}
                  className="ml-auto"
                />
              </div>
            </div>
          );
        })
      ) : (
        <SpinnerContainer loading={loading} />
      )}
    </>
  );
}

export default StaffCard;
