import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import { useState } from "react";
import { SnapActionSheet, SnapIcon, SnapOption, SnapTabs } from "suit";
import Divider from "./divider";

type BadgedTabsProps = {
  tabs: DropdownMenuItem[];
  selectedTab: number;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
};

function BadgedTabs({ tabs, selectedTab, setSelectedTab }: BadgedTabsProps) {
  // let activeStyle = "bg-blue-100 rounded-lg text-gray-800 font-medium";
  const [isOpenTabsOpen, setIsOpenTabsOpen] = useState(false);
  let downloadStatments = document.querySelector("unit-elements-account");
  return (
    <>
      <SnapTabs
        className="hidden lg:inline-block"
        options={tabs}
        type="secondary"
        onSnap-tabs-item-selected={(e) => {
          if (e.detail.name === "Statements" && downloadStatments) {
            downloadStatments.dispatchEvent(
              new CustomEvent("unitRequestAccountAction", {
                detail: { action: "OpenAccountStatements" },
              })
            );
          } else {
            setSelectedTab(Number(e.detail.index));
          }
        }}
      />
      <div className="lg:hidden flex-row w-full">
        <div
          className="flex p-2 rounded-xl"
          onClick={() => setIsOpenTabsOpen(true)}
        >
          <SnapIcon icon="menu-solid" size="xs" />
          <p className="mx-2">{tabs[selectedTab]?.name}</p>
          <SnapIcon icon="chevron-down-solid" size="sm" color="#334454" />
        </div>
        <Divider isVisibleOnMobile />
        {isOpenTabsOpen && (
          <SnapActionSheet onClick={() => setIsOpenTabsOpen(false)}>
            {tabs.map((tab, idx) => {
              return (
                <div
                  key={idx}
                  className={`flex ${
                    tabs[selectedTab] === tab && "bg-blue-100"
                  }`}
                >
                  <SnapOption
                    key={idx}
                    onClick={() => {
                      if (tab.name === "Statements" && downloadStatments) {
                        downloadStatments.dispatchEvent(
                          new CustomEvent("unitRequestAccountAction", {
                            detail: { action: "OpenAccountStatements" },
                          })
                        );
                      } else {
                        setSelectedTab(idx);
                      }
                      setIsOpenTabsOpen(false);
                    }}
                  >
                    {tab.name}
                  </SnapOption>
                </div>
              );
            })}
          </SnapActionSheet>
        )}
      </div>
    </>
  );
}

export default BadgedTabs;
