export function distinct(value: any, index: number, array: any[]) {
  return array.indexOf(value) === index;
}
// A little bit simplified version
export function groupBy<T, K extends keyof any>(arr: T[], key: (i: T) => K) {
  const keys: K[] = [];
  const result = arr.reduce((groups, item) => {
    keys.push(key(item));
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);
  return {
    keys: keys.filter(distinct),
    result,
  };
}
