import { useMutation } from "@apollo/client";
import GroupContext from "context/group-context";
import SeasonContext from "context/season-context";
import ToastContext from "context/toast-context";
import {
  SpendGroup,
  SpendSeasonInput,
  useSpendGroupsFilteredLazyQuery,
} from "graphql/generated";
import { CREATE_NEW_SEASON } from "graphql/mutations/group";
import { getDatePickerValue, setDatePickerValue } from "helpers/date-picker";
import { emptyStringCheck } from "helpers/empty-string-check";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import React, { useContext, useEffect, useState } from "react";
import DatePicker from "shared-components/date-picker";
import CustomModal, { BtnState } from "shared-components/modal";
import SignUpLink from "shared-components/sign-up-link";
import TableRowLabelValue from "shared-components/table-row-label-value";
import {
  SnapAlert,
  SnapAlertTitle,
  SnapInput,
  SnapLabeledToggle,
  SnapRadioButton,
} from "suit";
import { SeasonErrorType } from "types/errors";

type AddNewSeasonProps = {
  addSeasonOpen: boolean;
  addSeasonToggle: () => void;

  selectedGroup: SpendGroup;
  setHasSeasonErrors: React.Dispatch<React.SetStateAction<SeasonErrorType>>;
  hasSeasonErrors: SeasonErrorType;
};

function AddNewSeason({
  addSeasonOpen,
  addSeasonToggle,
  selectedGroup,
  setHasSeasonErrors,
  hasSeasonErrors,
}: AddNewSeasonProps) {
  const Group = useContext(GroupContext);
  const Season = useContext(SeasonContext);
  const Toast = useContext(ToastContext);

  const [isBtnActive, setIsBtnActive] = useState(true);
  const [seasonToAdd, setSeasonToAdd] = useState<SpendSeasonInput>({
    name: "",
    groupId: "",
    endDateAt: "",
    startDateAt: "",
    isLinkEnabled: false,
    isBudgetShared: false,
    copyBudgetItems: null,
    copyPaymentSchedule: null,
  });

  const [
    createNewSeason,
    { loading: loadingNewSeason, data: newSeasonData, error },
  ] = useMutation(CREATE_NEW_SEASON, {
    refetchQueries: [
      "SpendGroupsFiltered",
      "SpendOrganizationGroupsCategories",
    ],
  });

  const [getGroup, { data: groupData, loading: loadingGroup }] =
    useSpendGroupsFilteredLazyQuery();

  useEffect(() => {
    if (!loadingNewSeason) {
      if (newSeasonData) {
        getGroup({
          variables: {
            where: {
              ids: [selectedGroup.id ?? ""],
            },
          },
        });
      }
      if (error) {
        Toast?.setToast({
          message: `${error.message}`,
          type: "danger",
        });
        setIsBtnActive(true);
      }
    }
    // eslint-disable-next-line
  }, [loadingNewSeason, newSeasonData]);

  useEffect(() => {
    if (!loadingGroup && groupData) {
      const group = groupData.spendGroupsFiltered?.groups?.at(0);
      Group?.setAndStoreActiveGroup(group!);
      Season?.handleSelectedSeason({ seasonsToSet: group?.seasons });
      Toast?.setToast({
        message: "Season Successfully Created",
        type: "success",
      });
      addSeasonToggle();
      setIsBtnActive(true);
    }
    // eslint-disable-next-line
  }, [loadingGroup, groupData]);

  return (
    <CustomModal
      isOpen={addSeasonOpen}
      toggle={addSeasonToggle}
      title={"Add New Season"}
      customStyle="lg:top-[10px] lg:mt-[10px]"
      btn1={{
        text: "Add Season",
        btnStyle: "primary",
        btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
        onClick: () => {
          if (
            emptyStringCheck(seasonToAdd.startDateAt) ||
            !seasonToAdd.startDateAt?.includes("/")
          ) {
            hasSeasonErrors.seasonStart = true;
            setHasSeasonErrors({ ...hasSeasonErrors, seasonStart: true });
          }
          if (
            emptyStringCheck(seasonToAdd.endDateAt) ||
            !seasonToAdd.endDateAt?.includes("/")
          ) {
            hasSeasonErrors.seasonEnd = true;
            setHasSeasonErrors({ ...hasSeasonErrors, seasonEnd: true });
          }
          if (
            new Date(seasonToAdd.startDateAt ?? "") >
            new Date(seasonToAdd.endDateAt ?? "")
          ) {
            hasSeasonErrors.seasonDate = true;
            setHasSeasonErrors({ ...hasSeasonErrors, seasonDate: true });
          }
          if (emptyStringCheck(seasonToAdd.name)) {
            seasonToAdd.name = `${FormatDate(
              seasonToAdd.startDateAt,
              DateFormatSupported.Numbers
            )} - ${FormatDate(
              seasonToAdd.endDateAt,
              DateFormatSupported.Numbers
            )}`;
          }

          if (hasSeasonErrors.seasonStart || hasSeasonErrors.seasonEnd) {
            Toast?.setToast({
              message: "Please fill in all the input fields.",
              type: "danger",
            });
          } else if (hasSeasonErrors.seasonDate) {
            Toast?.setToast({
              message: "End Date cannot be before start date.",
              type: "danger",
            });
          } else if (seasonToAdd.copyPaymentSchedule == null) {
            Toast?.setToast({
              message: "Please select an option for payment schedule.",
              type: "danger",
            });
          } else if (seasonToAdd.copyBudgetItems == null) {
            Toast?.setToast({
              message: "Please select an option for budget items.",
              type: "danger",
            });
          } else {
            setIsBtnActive(false);

            createNewSeason({
              variables: {
                input: {
                  ...seasonToAdd,
                  isBudgetShared: Season?.selectedSeason?.isBudgetShared,
                  groupId: selectedGroup.id,
                },
              },
            });
          }
        },
      }}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: addSeasonToggle,
      }}
    >
      <div className="modal-card">
        <SnapAlert type="warning" right-link-text="Details" className="mb-3">
          <SnapAlertTitle>
            Creating a new Season will replace the current season
          </SnapAlertTitle>
        </SnapAlert>
        <table className="w-full">
          <tbody>
            <TableRowLabelValue
              label={"Group Name"}
              value={selectedGroup.name}
            />
            <TableRowLabelValue
              label={"Funds"}
              value={
                selectedGroup.sharedAccount
                  ? "Continue to use program bank"
                  : "Continue to use same group-specific account"
              }
            />
          </tbody>
        </table>
        <div className="lg:grid flex flex-col grid-cols-2 mt-6">
          <>
            <DatePicker
              className="lg:mr-4"
              label={"Season Start Date"}
              date={getDatePickerValue(seasonToAdd.startDateAt ?? "")}
              setDate={(e) => {
                const newDate = setDatePickerValue(e);
                setSeasonToAdd({
                  ...seasonToAdd,
                  startDateAt: newDate,
                });
                setHasSeasonErrors({
                  ...hasSeasonErrors,
                  seasonStart: false,
                  seasonDate: false,
                });
              }}
              hasError={
                hasSeasonErrors.seasonStart || hasSeasonErrors.seasonDate
              }
            />
            <DatePicker
              className={""}
              label={"Season End Date"}
              date={getDatePickerValue(seasonToAdd.endDateAt ?? "")}
              setDate={(e) => {
                const newDate = setDatePickerValue(e);
                setSeasonToAdd({
                  ...seasonToAdd,
                  endDateAt: newDate,
                });
                setHasSeasonErrors({
                  ...hasSeasonErrors,
                  seasonEnd: false,
                  seasonDate: false,
                });
              }}
              hasError={hasSeasonErrors.seasonEnd || hasSeasonErrors.seasonDate}
            />
          </>
        </div>
        <SnapInput
          _id={""}
          label="Season Nickname"
          cornerHint="optional"
          helpText="Season Nickname is optional. If there is no nickname, start and end dates will be used to identify the season."
          className="flex mt-6"
          onBlur={(e) => {
            setSeasonToAdd({
              ...seasonToAdd,
              name: e.target.value.trim(),
            });
          }}
        />
        <SnapLabeledToggle
          className="flex mt-6"
          id="SignUpLinkToggle"
          checked={seasonToAdd.isLinkEnabled ?? false}
          sr-only="srOnly"
          label="Sign Up Link"
          description=""
          toggle-side="left"
          onSnap-labeled-toggle-click={(e) => {
            setSeasonToAdd({
              ...seasonToAdd,
              isLinkEnabled: e.detail.checked,
            });
          }}
        />
        {seasonToAdd.isLinkEnabled && <SignUpLink />}
        <p className="mt-4 text-sm text-gray-500">
          <span className="text-gray-800">Note:</span> All currently active
          debit cards and assistants will remain active. You should edit as
          needed in the new season.
        </p>
        <p className="mt-4">Copy over payment schedule?</p>
        <div className="flex">
          <SnapRadioButton
            label="Yes"
            checked={seasonToAdd.copyPaymentSchedule === true}
            onClick={() => {
              setSeasonToAdd({
                ...seasonToAdd,
                copyPaymentSchedule: true,
              });
            }}
          />
          <SnapRadioButton
            label="No"
            className="ml-4"
            checked={seasonToAdd.copyPaymentSchedule === false}
            onClick={() => {
              setSeasonToAdd({
                ...seasonToAdd,
                copyPaymentSchedule: false,
              });
            }}
          />
        </div>
        <p className="mt-4">Copy over budget items?</p>
        <div className="flex">
          <SnapRadioButton
            label="Yes"
            checked={seasonToAdd.copyBudgetItems === true}
            onClick={() => {
              setSeasonToAdd({
                ...seasonToAdd,
                copyBudgetItems: true,
              });
            }}
          />
          <SnapRadioButton
            label="No"
            className="ml-4"
            checked={seasonToAdd.copyBudgetItems === false}
            onClick={() => {
              setSeasonToAdd({
                ...seasonToAdd,
                copyBudgetItems: false,
              });
            }}
          />
        </div>
      </div>
    </CustomModal>
  );
}

export default AddNewSeason;
