import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import { SnapDropDown } from "suit";

type FilterProps = {
  className?: string;
  dropdownOptions: DropdownMenuItem[];
  handleSelect?: (e: CustomEvent) => void;
};

function Filter({ className, dropdownOptions, handleSelect }: FilterProps) {
  return (
    <div className={`flex ${className}`}>
      <p className="mr-2 text-xs font-medium text-gray-500 self-center">
        Show:
      </p>
      <SnapDropDown
        options={dropdownOptions}
        modalType="drawer"
        defaultValue={"All Users"}
        className="self-center"
        leftHang
        onSnap-dropdown-item-selected={handleSelect}
      />
    </div>
  );
}

export default Filter;
