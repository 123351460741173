import { useQuery } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import { Maybe, SpendGroup, SpendSeason } from "graphql/generated";
import { GET_GROUPS_PUBLIC } from "graphql/queries/group";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import DotProgress from "shared-components/dot-progress";
import { SnapInput, SnapSelectMenu } from "suit";
import { signUpErrors } from "types/errors";

type SignUpProps = {
  setParticipantName: React.Dispatch<React.SetStateAction<string>>;
  groups: SpendGroup[];
  setGroups: React.Dispatch<React.SetStateAction<SpendGroup[]>>;
  groupOptions: SnapSelectMenuOption[];
  setGroupOptions: React.Dispatch<React.SetStateAction<SnapSelectMenuOption[]>>;
  setSeasons: React.Dispatch<
    React.SetStateAction<Maybe<Maybe<SpendSeason>[]> | undefined>
  >;
  seasonOptions: SnapSelectMenuOption[];
  setSeasonOptions: React.Dispatch<
    React.SetStateAction<SnapSelectMenuOption[]>
  >;
  signUpErrors: signUpErrors;
  setSignUpErrors: React.Dispatch<React.SetStateAction<signUpErrors>>;
};

function SignUp({
  setParticipantName,
  groups,
  setGroups,
  groupOptions,
  setGroupOptions,
  setSeasons,
  seasonOptions,
  setSeasonOptions,
  signUpErrors,
  setSignUpErrors,
}: SignUpProps) {
  const location = useLocation();

  const { loading: loadingOrgGroups, data: orgGroupData } = useQuery(
    GET_GROUPS_PUBLIC,
    {
      variables: {
        orgId: location.pathname.split("/signup/parents/organization/")[1],
      },
    }
  );

  useEffect(() => {
    if (!loadingOrgGroups && orgGroupData && orgGroupData.spendGroupsPublic) {
      let groups: SpendGroup[] = [];
      orgGroupData.spendGroupsPublic.groups.forEach((group: SpendGroup) => {
        let foundSeasons = group.seasons?.filter(
          (season) => season?.isLinkEnabled && season.paymentScheduleStatus //CHECK IF IS PUBLISHED
        );
        if (foundSeasons?.length) {
          groups.push(group);
        }
      });
      setGroups(groups);
      setGroupOptions(
        groups.map((group: SpendGroup) => {
          let option: SnapSelectMenuOption = {
            name: group.name ?? "",
            value: group.id ?? "",
            selected: false,
          };
          return option;
        })
      );
      setSeasonOptions([]);
      setSeasons([]);
    }
    // eslint-disable-next-line
  }, [orgGroupData, loadingOrgGroups]);

  return (
    <div>
      <DotProgress state={0} markerCount={3} />
      <p className="text-2xl font-semibold mt-8">
        Add Your Participant and Group
      </p>
      <p className="text-lg">
        To set up payments, tell us child's name and select the group you are
        paying for.
      </p>
      <div className="mt-16">
        <SnapInput
          _id={""}
          label="Participant Name"
          error={signUpErrors.nameError}
          errorIcon={signUpErrors.nameError}
          onSnap-input-change={(e) => {
            setParticipantName(e.detail.target.value);
            setSignUpErrors({ ...signUpErrors, nameError: false });
          }}
          onBlur={(e) => {
            setParticipantName(e.target.value);
            setSignUpErrors({ ...signUpErrors, nameError: false });
          }}
        />
      </div>
      <div className="mt-6">
        <SnapSelectMenu
          label="Group"
          placeholder="Select Group"
          error={signUpErrors.groupError}
          selectMenuOptions={groupOptions}
          onSnap-select-menu-updated={(e) => {
            const selectedItem = e.detail.find((group) => group.selected);
            setGroupOptions(e.detail);
            const selectedGroup = groups.find((g) => {
              return g.id === selectedItem?.value;
            });
            setSeasons(selectedGroup?.seasons);
            setSeasonOptions(
              selectedGroup?.seasons
                ?.filter(
                  (season) =>
                    season?.isLinkEnabled && season.paymentScheduleStatus
                )
                .map((season) => {
                  let option: SnapSelectMenuOption = {
                    name: season?.name ?? "",
                    value: season?.id ?? "",
                    selected: false,
                  };
                  return option;
                }) ?? []
            );
            setSignUpErrors({ ...signUpErrors, groupError: false });
          }}
        />
      </div>
      <div className="mt-6">
        <SnapSelectMenu
          label="Season"
          placeholder="Select Season"
          error={signUpErrors.seasonError}
          selectMenuOptions={seasonOptions}
          onSnap-select-menu-updated={(e) => {
            const selectedItem = e.detail.find((season) => season.selected);
            const idx = e.detail.findIndex((season) => season.selected);
            seasonOptions.splice(idx, 1, selectedItem!);
            setSeasonOptions(seasonOptions);
            setSignUpErrors({ ...signUpErrors, seasonError: false });
          }}
        />
      </div>
    </div>
  );
}

export default SignUp;
