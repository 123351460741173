import { DateFormatSupported, FormatDate } from "helpers/format-date";
import Divider from "shared-components/divider";
import { SnapInput, SnapRadioButton } from "suit";
import { StepProps } from ".";

function StepTwo({ selectedDetails, setSelectedDetails }: StepProps) {
  return (
    <div>
      <p className="text-lg font-semibold mt-4">Select Season</p>
      <p className="text-sm text-gray-500 mb-4">
        Please specify the season this payment applies to.
      </p>
      <SnapInput _id={""} placeholder="Search this page" />
      {selectedDetails.team?.seasons?.map((season, idx) => {
        return (
          season && (
            <div
              key={idx}
              className={`flex lg:items-center px-6 py-4 border rounded-lg mt-4 ${
                selectedDetails.season?.id === season.id &&
                "border-blue-400 bg-blue-50"
              }`}
            >
              <SnapRadioButton
                checked={selectedDetails.season?.id === season.id}
                onClick={() =>
                  setSelectedDetails({
                    ...selectedDetails,
                    season: { ...season },
                  })
                }
                className="mt-1 lg:mt-0"
              />
              <div className="w-full lg:flex lg:items-center">
                <p className="mr-auto font-semibold">
                  {season.name ??
                    `${FormatDate(
                      season.startDateAt ?? "",
                      DateFormatSupported.Numbers
                    )} - ${FormatDate(
                      season.endDateAt ?? "",
                      DateFormatSupported.Numbers
                    )}`}
                </p>
                <Divider isVisibleOnMobile className="lg:hidden" />
                <div className="flex mt-2 lg:flex-col">
                  <p className="text-xs font-bold text-gray-500">Group</p>
                  <p className="text-sm font-semibold text-end ml-auto lg:ml-0">
                    {selectedDetails.team?.name}
                  </p>
                </div>
              </div>
            </div>
          )
        );
      })}
    </div>
  );
}

export default StepTwo;
