import DefaultPaymentMethod from "pages/parent-sign-up/new-authorize-payments/default-payment-method";
import { InvoiceCardView } from "../make-payment/invoice-card-view";
import { InvoiceTableView } from "../make-payment/invoice-table-view";
import { SelectedPlayer } from "../make-payment/selected-player";
import { SubHeaderView } from "../make-payment/sub-header-view";
import {
  PaymentsApiCustomerPaymentMethod,
  SpendBankAccount,
  SpendGroupRoster,
} from "graphql/generated";
import { SelectedInvoice } from "types/invoice";
import { SnapCheckboxButton } from "suit";

type Step2Props = {
  playerSelected: SpendGroupRoster | undefined;
  selectableInvoices: SelectedInvoice[];
  handleCheckId: (e: SelectedInvoice | null, more?: any) => void;
  handleOptStatus: (status: boolean, invoice: SelectedInvoice) => void;
  paymentMethods: PaymentsApiCustomerPaymentMethod[];
  selectedPaymentTypeOption: `Pay by: ${"Card" | "Bank"}` | undefined;
  bankAccounts: SpendBankAccount[];
  isAuthorized: boolean;
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Step2({
  playerSelected,
  selectableInvoices,
  handleCheckId,
  handleOptStatus,
  paymentMethods,
  selectedPaymentTypeOption,
  bankAccounts,
  isAuthorized,
  setIsAuthorized,
}: Step2Props) {
  return (
    <div className="mx-3 mt-2">
      <SelectedPlayer playerSelected={playerSelected} />
      <SubHeaderView text={"Invoices to Authorize for AutoPay"} />
      <div className="hidden lg:flex">
        <InvoiceTableView
          list={selectableInvoices.filter((i) => i.optedIn || !i.isOptional)}
          isReadonly={true}
          handleCheckId={handleCheckId}
          handleOptStatus={handleOptStatus}
        />
      </div>
      <InvoiceCardView
        list={selectableInvoices.filter((i) => i.optedIn || !i.isOptional)}
        isReadonly={true}
        handleCheckId={handleCheckId}
        handleOptStatus={handleOptStatus}
      />

      <DefaultPaymentMethod
        paymentMethods={paymentMethods}
        bankAccount={
          selectedPaymentTypeOption === "Pay by: Bank"
            ? bankAccounts.at(0)
            : undefined
        }
      />

      <h3 className="font-medium mt-4 md:mt-6">Summary</h3>
      <ul className="list-disc ml-6 mt-4 text-gray-500 mb-6">
        <li>
          You will be charged on the billing date listed above for each invoice.
        </li>
        <li>
          You can easily manage payments at any time from your Snap! Spend
          account.
        </li>
        <li>
          You will be sent a reminder email three days before each billing date.
        </li>
        <li>
          It can take up to 5 business days for the funds to be withdrawn from
          your checking account.
        </li>
      </ul>

      <SnapCheckboxButton
        checked={isAuthorized}
        label="I authorize Snap! Spend to charge my payment method according to the above schedule"
        onClick={(e: any) => setIsAuthorized(e.target.checked)}
      />
    </div>
  );
}
