import { SpendGroup } from "../../graphql/generated";

import UserContext from "../../context/user-context";
import { useContextStrict } from "../../helpers/context-strict";

function ParentInviteInfo({
  group,
  playerName,
  seasonId,
}: {
  group: SpendGroup;
  playerName: string;
  seasonId?: string;
}) {
  const User = useContextStrict(UserContext);

  return (
    <div className="mt-4 grid grid-cols-2 md:grid-cols-[200px_minmax(600px,_1fr)]">
      <p className="my-1">Group</p>
      <p className="my-0.5 font-medium">{group.name}</p>

      <p className="my-1">Season</p>
      <p className="my-0.5 font-medium">
        {
          group.seasons?.find(
            (season) => season?.id === seasonId || User._session?.role?.seasonId
          )?.name
        }
      </p>

      <p className="my-1">Participant</p>
      <p className="my-0.5 font-medium">{playerName}</p>
    </div>
  );
}

export default ParentInviteInfo;
