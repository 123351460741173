import { useQuery } from "@apollo/client";
import { SpendCategory } from "graphql/generated";
import { GETorganization_BUDGET_SUMMARY } from "graphql/queries/budgets";
import { useEffect, useState } from "react";
import BadgedTabs from "shared-components/badged-tabs";
import BudgetItems from "shared-components/budget-items";
import Summary from "shared-components/summary";
import { BudgetSummaryResponse } from "types/budget-summary";
import { tabs } from "./external-data";

function ProgramBudget() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [spendSummary, setSpendSummary] = useState<
    BudgetSummaryResponse | undefined
  >();
  const [incomeBudgetItems, setIncomeBudgetItems] = useState<SpendCategory[]>();
  const [expenseBudgetItems, setExpenseBudgetItems] =
    useState<SpendCategory[]>();

  const { data, loading } = useQuery(GETorganization_BUDGET_SUMMARY, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data?.spendBudgetsProgramSummary) {
      setSpendSummary(data.spendBudgetsProgramSummary);
      const categories = data.spendBudgetsProgramSummary.summaryByCategory;
      const iBudgetItems = categories.filter(
        (item: SpendCategory) => item.type === "income"
      );
      const eBudgetItems = categories.filter(
        (item: SpendCategory) => item.type === "expense"
      );
      setIncomeBudgetItems(iBudgetItems);
      setExpenseBudgetItems(eBudgetItems);
    }
  }, [data, loading]);

  return (
    <div className="wrapper">
      <div className="card">
        <BadgedTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={tabs}
        />
        {
          {
            0: <Summary budgetSummary={spendSummary} type="program" />,
            1: (
              <BudgetItems
                budgetItems={incomeBudgetItems}
                type="income"
                budgetType="program"
              />
            ),
            2: (
              <BudgetItems
                budgetItems={expenseBudgetItems}
                type="expense"
                budgetType="program"
              />
            ),
          }[selectedTab]
        }
      </div>
    </div>
  );
}

export default ProgramBudget;
