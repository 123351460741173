import { useMutation } from "@apollo/client";
import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import DisplayContext from "../../../context/display-context";
import { CREATE_USER_INVITE } from "graphql/mutations/invite";
import { GET_INVITES_FILTERED } from "graphql/queries/invites";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import FloatingActionBtn from "shared-components/floating-action-btn";
import CustomModal, { BtnState } from "shared-components/modal";
import Sort from "shared-components/sort";
import { SnapButton } from "suit";
import AddStaff from "./modals/add-staff";
import RemoveStaff from "./modals/remove-staff";
import StaffCard from "./staff-card";
import StaffTable from "./staff-table";
import {
  SpendInvite,
  SpendInviteType,
  SpendSortInput,
  SpendSortOrderEnum,
  useSpendInvitesFilteredQuery,
  useSpendUserInviteArchiveMutation,
} from "graphql/generated";
import { emptyStringCheck } from "helpers/empty-string-check";
import { ValidateEmail } from "helpers/validation";
import useToast from "hooks/use-toast";
import ToastMessage from "shared-components/toast-message";
import ToastContext from "context/toast-context";
import UserContext from "context/user-context";
import { SpendPermissions } from "types/roles-permissions";

export type hasStaffErrorsType = {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  sameEmail: boolean;
  role: boolean;
};
function Staff() {
  const Group = useContext(GroupContext);
  const Display = useContext(DisplayContext);
  const Toast = useContext(ToastContext);
  const canEditStaff =
    useContext(UserContext)?.checkSpendPermission(
      SpendPermissions.groupAdminsUpdate
    ) ?? false;

  const { isOpen, toggle } = useModal();
  const { isOpen: removeModal, toggle: removeToggle } = useModal();
  const [sort, setSort] = useState<SpendSortInput | undefined>();
  const [isBtnActive, setIsBtnActive] = useState(true);

  const handleSort = (data: any) => {
    const sortValue = data.value;
    const [field, order] = sortValue.split(":");
    if (!field) {
      setSort(undefined);
      return;
    }
    setSort({
      field,
      order: (order?.toUpperCase() ||
        SpendSortOrderEnum.Asc) as SpendSortOrderEnum,
    });
  };
  const { data, loading } = useSpendInvitesFilteredQuery({
    variables: {
      where: {
        type: [SpendInviteType.GroupStaff, SpendInviteType.GroupObserver],
        groupId: Group?.activeGroup?.id,
      },
      includeGroup: false,
      includeCards: true,
      sort: sort,
    },
  });
  const reFetchUser = {
    query: GET_INVITES_FILTERED,
    variables: {
      where: {
        type: [SpendInviteType.GroupStaff, SpendInviteType.GroupObserver],
        groupId: Group?.activeGroup?.id,
      },
      includeGroup: false,
      includeCards: true,
    },
  };
  const [createNewStaff, { loading: loadingNewStaff, data: newStaffData }] =
    useMutation(CREATE_USER_INVITE, {
      refetchQueries: [reFetchUser],
      fetchPolicy: "no-cache",
    });

  const [archiveUser, { loading: loadingArchiveUser, data: archiveUserData }] =
    useSpendUserInviteArchiveMutation({
      refetchQueries: [reFetchUser],
      fetchPolicy: "no-cache",
    });

  const [staffInfo, setStaffInfo] = useState<SpendInvite[]>([]);

  useEffect(() => {
    if (!loadingArchiveUser && archiveUserData) {
      toast.setToast({
        message: "Staff memeber deleted successfully",
        type: "success",
      });
      removeToggle();
      setIsBtnActive(true);
    }
    // eslint-disable-next-line
  }, [loadingArchiveUser, archiveUserData]);

  useEffect(() => {
    if (!loadingNewStaff && newStaffData) {
      Toast?.setToastProps({
        message: "Invite(s) have been sent",
        type: "success",
      });
      Toast?.toggleToast();
      handleCancelAddStaff();
    }
    // eslint-disable-next-line
  }, [loadingNewStaff, newStaffData]);

  useEffect(() => {
    if (!loading && data?.spendInvitesFiltered) {
      const groupStaffArray = data.spendInvitesFiltered
        .invites as SpendInvite[];
      setStaffInfo(groupStaffArray);
    }
  }, [data, loading]);
  let sortingOptions = [
    { name: "Staff A - Z", text: "Staff A - Z", value: "name:asc" },
    { name: "Staff Z - A", text: "Staff Z - A", value: "name:desc" },
    { name: "Email A - Z", text: "Email A - Z", value: "email:asc" },
    { name: "Email Z - A", text: "Email Z - A", value: "email:desc" },
    { name: "Role A - Z", text: "Role A - Z", value: "type:asc" },
    { name: "Role Z - A", text: "Role Z - A", value: "type:desc" },
    { name: "Status Active", text: "Status Active", value: "status:asc" },
    {
      name: "Status Not Signed Up",
      text: "Status Not Signed Up",
      value: "status:desc",
    },
    // {
    //   name: "Debit Card Active",
    //   text: "Debit Card Active",
    //   value: "Debit Card Active",
    // },
    // {
    //   name: "Debit Card Not Active",
    //   text: "Debit Card Not Active",
    //   value: "Debit Card Not Active",
    // },
  ];

  const [staffToRemove, setStaffToRemove] = useState(0);
  const [newStaff, setNewStaff] = useState([
    {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      type: "",
      status: "Not Signed Up",
      debitCard: "-",
    },
  ]);

  const handleRemoveToggle = (idxOfStaffToRemove: number) => {
    setStaffToRemove(idxOfStaffToRemove);
    removeToggle();
  };
  const [hasErrors, setHasErrors] = useState<hasStaffErrorsType[]>([
    {
      firstName: false,
      lastName: false,
      email: false,
      sameEmail: false,
      role: false,
    },
  ]);
  const { isToastOpen, ...toast } = useToast();
  const handleSendInvites = () => {
    let usersEmails = staffInfo.map((user) => user.email);
    newStaff.forEach((user, i) => {
      if (emptyStringCheck(user.firstName)) {
        hasErrors[i].firstName = true;
      }
      if (emptyStringCheck(user.lastName)) {
        hasErrors[i].lastName = true;
      }
      if (emptyStringCheck(user.type)) {
        hasErrors[i].role = true;
      }
      if (!ValidateEmail(user.email ?? "")) {
        hasErrors[i].email = true;
      }
      if (usersEmails.includes(user.email)) {
        hasErrors[i].sameEmail = true;
      }
      setHasErrors([...hasErrors]);
    });
    let validEntries = true;
    let validEmails = true;
    hasErrors.forEach((obj) => {
      if (obj.email || obj.firstName || obj.lastName) {
        return (validEntries = false);
      }
      if (obj.sameEmail) {
        return (validEmails = false);
      }
    });
    if (validEntries && validEmails) {
      setIsBtnActive(false);
      newStaff.forEach((staff) => {
        createNewStaff({
          variables: {
            input: {
              email: staff.email.toLowerCase(),
              firstName: staff.firstName,
              lastName: staff.lastName,
              type: staff.type,
              groupId: Group?.activeGroup?.id,
            },
          },
          refetchQueries: ["SpendInvitesFiltered"],
          fetchPolicy: "network-only",
        });
      });
    } else if (!validEmails) {
      toast.setToastProps({
        message: "Email already exist",
        type: "danger",
      });
      toast.toggleToast();
    } else {
      toast.setToastProps({
        message: "Please fill in all input fields",
        type: "danger",
      });
      toast.toggleToast();
    }
  };
  const handleCancelAddStaff = () => {
    setNewStaff([
      {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        type: "",
        status: "Not Signed Up",
        debitCard: "-",
      },
    ]);
    toggle();
    setIsBtnActive(true);
  };
  const handleRemoveStaff = () => {
    setIsBtnActive(false);
    const selectedStaff = staffInfo[staffToRemove];
    archiveUser({
      variables: {
        spendUserInviteArchiveId: selectedStaff.id!,
      },
    });
  };
  const menuItems: DropdownMenuItem[] = [
    { text: "Remove Staff", name: "Remove Staff", value: "Remove Staff" },
  ];

  return (
    <div className="wrapper">
      {Toast?.isToastOpen && (
        <ToastMessage
          message={Toast.message}
          isToastOpen={Toast.isToastOpen}
          toggleToast={Toast.toggleToast}
          type={Toast.type}
        />
      )}
      <div className="card">
        <div className="flex justify-between items-center">
          <p className="text-lg font-semibold">Staff</p>
          {canEditStaff && (
            <SnapButton
              variant="primary"
              icon="plus-solid"
              className="lg:flex hidden"
              onClick={handleCancelAddStaff}
            >
              Add Staff
            </SnapButton>
          )}
        </div>
        {!Display?.isDesktop && (
          <div className="my-5">
            <Sort
              selectedSortOption={"name:asc"}
              options={sortingOptions}
              handleSort={handleSort}
            />
          </div>
        )}
        {Display?.isDesktop ? (
          <StaffTable
            staffInfo={staffInfo}
            menuItems={menuItems}
            menuClickListener={handleRemoveToggle}
            sort={sort}
            setSort={setSort}
            canEditStaff={canEditStaff}
            isArchived={Group?.isArchived!}
            loading={loading}
          />
        ) : (
          <StaffCard
            staffInfo={staffInfo}
            menuItems={menuItems}
            menuClickListener={handleRemoveToggle}
            canEditStaff={canEditStaff}
            isArchived={Group?.isArchived!}
            loading={loading}
          />
        )}

        {canEditStaff && (
          <FloatingActionBtn icon="plus-solid" onClick={handleCancelAddStaff} />
        )}
        <CustomModal
          isOpen={isOpen}
          toggle={handleCancelAddStaff}
          title={"Add Staff"}
          btn1={{
            text: "Send Invites",
            btnStyle: "primary",
            onClick: handleSendInvites,
            btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
          }}
          btn2={{
            text: "Cancel",
            onClick: handleCancelAddStaff,
            btnStyle: "tertiary",
          }}
          customStyle={"overflow-y-scroll lg:mt-10 lg:w-[50%]"}
        >
          {isToastOpen && (
            <ToastMessage
              message={toast.message}
              isToastOpen={isToastOpen}
              type={toast.type}
              toggleToast={toast.toggleToast}
              className="lg:w-[30%] lg:ml-16"
            />
          )}

          <AddStaff
            newStaff={newStaff}
            setNewStaff={setNewStaff}
            hasErrors={hasErrors}
            setHasErrors={setHasErrors}
          />
        </CustomModal>
        <RemoveStaff
          removeModal={removeModal}
          removeToggle={removeToggle}
          handleRemoveStaff={handleRemoveStaff}
          isBtnActive={isBtnActive}
        />
      </div>
    </div>
  );
}

export default Staff;
