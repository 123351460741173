import React, { FormEvent, forwardRef, useImperativeHandle } from 'react';
import { V2 } from '@snap-mobile/payments-widget-client';
import { SubmitButton } from './SubmitButton';
import { usePaymentsWidgetContext } from '../context';

export type ExistingPaypalPaymentMethodWidgetProps = React.PropsWithChildren<{
  paymentMethodId: string;
  product: string;
  secondaryId?: string;
  renderSubmitButton?: React.ReactNode;
  onSuccess?: (data: V2.PaypalOrderConfirmResponse) => void | Promise<void>;
}>;

export type ExistingPaypalPaymentMethodRef = {
  submit: () => void;
};


/**
 * Props for `ExistingPaypalPaymentMethod` Component
 *
 * @prop {string} paymentMethodId - An object representing the payment method to be used.
 *                                       This is a mandatory prop for processing the payment
 * @prop {React.ReactNode} renderSubmitButton - Optional custom node to be rendered as the submit button.
 *                                              If not provided, a default submit button is used.
 * @prop {string} product - The identifier for the product being purchased.
 * @prop {string} [secondaryId] - Optional secondary identifier that can be used in the payment process.
 * @prop {(data: V2.PaypalOrderConfirmResponse) => void} [onSuccess] - Optional callback function triggered after a successful payment.
 *                                           Receives the payment response data as an argument.
 ** Ref Methods:
 * @method submit - Triggers the payment submission process.
 * The `ExistingPaypalPaymentMethod` component integrates with custom hooks and context for managing payment
 * processing. It renders a form for submitting an existing payment method. The form submission
 * involves setting the processing state, creating a customer, and confirming the payment using the
 * provided method. If the payment confirmation is successful, the `onSuccess` callback is triggered.
 *
 * * Usage:
 * <PaymentsWidgetProvider {...props}>
 * <ExistingPaypalPaymentMethod
 *   paymentMethodId={yourPaymentMethodId}
 *   product="exampleProduct"
 *   renderSubmitButton={<CustomSubmitButton />}
 *   onSuccess={(data) => console.log('Payment Successful', data)}
 * />
 * </PaymentsWidgetProvider>
 */
export const ExistingPaypalPaymentMethod = forwardRef<ExistingPaypalPaymentMethodRef, ExistingPaypalPaymentMethodWidgetProps>(({
  paymentMethodId,
  renderSubmitButton,
  product,
  secondaryId,
  onSuccess,
}: ExistingPaypalPaymentMethodWidgetProps, ref) => {

  useImperativeHandle(ref, () => ({
    submit() {
      onSubmit();
    },
  }));


  const { paymentData, setProcessing } = usePaymentsWidgetContext();
  const onSubmit = async (e?: FormEvent) => {
    e?.preventDefault();
    setProcessing(true);
    const data: V2.CreatePaypalOrderParams = {
      externalPaymentId: paymentData.externalPaymentId,
      product: product || 'snap',
      secondaryId: secondaryId,
      snapAmount: paymentData.snapAmount,
      totalAmount: paymentData.totalAmount,
      returnUrl: paymentData.returnUrl ?? window.location.href,
      cancelUrl: paymentData.cancelUrl ?? window.location.href,
      paymentMethodVaultId: paymentMethodId,
    };
    const resp = await V2.paypalReusePaymentMethod(data);
    if (resp) {
      onSuccess?.(resp);
    }
    setProcessing(false);
  };

  return (
    <form id="existing-paypal-payment-method" onSubmit={onSubmit}>
      <SubmitButton>{renderSubmitButton}</SubmitButton>
    </form>
  );
})
