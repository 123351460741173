const legacyPaymentMethods = {
  ACH: "ach",
  CARD: "card",
  RDC: "rdc",
  CUSTODY: "custody", // Synapse Custody Account
  DEPOSIT: "deposit", // Synapse Deposit Account
  MERCHANT: "merchant", // Paystand Merchant Account
  DEBIT_CARD: "debit card", // Card issued from a Synapse Deposit account
  CHECK_RECIPIENT: "check recipient", // recipient for bill pay
  GROUNDWORK: "groundwork", // our fee/reserve nodes
};
const legacyTransactionTypes = {
  ACH: "ach",
  BILL_PAY: "bill_pay",
  CREDIT_MEMO: "credit_memo",
  DEBIT_CARD: "debit_card",
  DEPOSIT: "deposit",
  DISPUTE: "dispute",
  FEE: "fee",
  PAYMENT: "payment",
  REFUND: "refund",
  TRANSFER: "transfer",
  WITHDRAWAL: "withdrawal",
  OTHER: "other",
};
export { legacyPaymentMethods, legacyTransactionTypes };
