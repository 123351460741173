import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import { Maybe, SpendSeason } from "graphql/generated";
import { createContext } from "react";

const SeasonContext = createContext<
  | {
      selectedSeason: Maybe<SpendSeason> | undefined;
      multipleSeasons: () => boolean;
      seasonOptions: DropdownMenuItem[];
      handleSelectedSeason: (data?: {
        seasonId?: string;
        seasonsToSet?: Maybe<Maybe<SpendSeason>[]>;
      }) => void;
    }
  | undefined
>(undefined);

export default SeasonContext;
