import { useLazyQuery, useQuery } from "@apollo/client";
import { ExportFile, SpendAccount } from "graphql/generated";
import {
  EXPORT_ACCOUNT_REPORT,
  GET_ORGANIZATION_ACCOUNTS,
} from "graphql/queries/organization";
import { FormatMoney } from "helpers/format-money";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import BadgedTabs from "shared-components/badged-tabs";
import ProgramInteralTransfer from "shared-components/banking/program-internal-transfer";
import Divider from "shared-components/divider";
import FloatingActionBtn from "shared-components/floating-action-btn";
import ShowingResults from "shared-components/showing-results";
import { SnapButton, SnapIcon } from "suit";
import AccountTable from "./account-table";
import { tabs } from "./tabs";
import { downloadFile } from "helpers/export-csv";
import UserContext from "context/user-context";
import { SpendPermissions } from "types/roles-permissions";
import { ITEMS_PER_PAGE } from "../../../constants";

function AllAcounts() {
  const { loading: loadingAccounts, data: accountData } = useQuery(
    GET_ORGANIZATION_ACCOUNTS,
    {
      fetchPolicy: "network-only",
    }
  );

  const [exportReport, { loading: loadingReport, data: exportData }] =
    useLazyQuery(EXPORT_ACCOUNT_REPORT, {
      fetchPolicy: "network-only",
    });

  const { isOpen: transferOpen, toggle: transferToggle } = useModal();
  const [selectedTab, setSelectedTab] = useState(0);
  const [programAccount, setProgramAccount] = useState<SpendAccount>({});
  const [activeAccounts, setActiveAccounts] = useState<SpendAccount[]>([]);
  const [archivedAccounts, setArchivedAccounts] = useState<SpendAccount[]>([]);
  const [data, setData] = useState<SpendAccount[]>([]);
  const [accountBalanceTotal, setAccountBalanceTotal] = useState(0);
  const [activeAccountTotal, setActiveAccountTotal] = useState(0);
  const [archiveAccountTotal, setArchiveAccountTotal] = useState(0);
  const [page, setPage] = useState(0);
  const canUpdateBanking = useContext(UserContext)?.checkSpendPermission(
    SpendPermissions.programBankUpdate
  );
  useEffect(() => {
    if (selectedTab === 0) {
      setData(activeAccounts);
    } else {
      setData(archivedAccounts);
    }
    setPage(0);
    // eslint-disable-next-line
  }, [selectedTab]);

  useEffect(() => {
    if (exportData && exportData.spendAccountExport) {
      handleExport(exportData.spendAccountExport);
    }
    // eslint-disable-next-line
  }, [exportData, loadingReport]);

  useEffect(() => {
    if (accountData && accountData.spendOrganizationAccounts) {
      let resp = accountData.spendOrganizationAccounts;
      setProgramAccount(
        resp.accounts.filter(
          (account: SpendAccount) =>
            account.status === "Open" && account.type === "main"
        )[0]
      );
      setActiveAccounts(
        resp.accounts.filter(
          (account: SpendAccount) =>
            account.type === "main" || account.groupStatus === "active"
        )
      );
      setArchivedAccounts(
        resp.accounts.filter(
          (account: SpendAccount) =>
            account.type !== "main" && account.groupStatus !== "active"
        )
      );
      setAccountBalanceTotal(resp.totalBalance);
      setActiveAccountTotal(resp.groupsBalance.active);
      setArchiveAccountTotal(resp.groupsBalance.archived);
      setData(
        resp.accounts.filter(
          (account: SpendAccount) =>
            account.type === "main" || account.groupStatus === "active"
        )
      );
    }
    // eslint-disable-next-line
  }, [loadingAccounts, accountData]);

  const handleExport = (contentToExport: ExportFile) => {
    downloadFile({
      data: [contentToExport.content].join("\n"),
      fileName: contentToExport.fileName,
      fileType: "text/csv",
    });
  };

  const handleSort = (selectedTab: string, icon: string) => {
    if (selectedTab === "Account Owner") {
      if (icon === "selector-solid" || icon === "descending-solid") {
        data.sort((a, b) =>
          a.name?.toLowerCase()! < b.name?.toLowerCase()!
            ? -1
            : a.name?.toLowerCase()! > b.name?.toLowerCase()!
            ? 1
            : 0
        );
      } else {
        data.sort((a, b) =>
          a.name?.toLowerCase()! > b.name?.toLowerCase()!
            ? -1
            : a.name?.toLowerCase()! < b.name?.toLowerCase()!
            ? 1
            : 0
        );
      }
    }
    if (selectedTab === "Available Balance") {
      if (icon === "selector-solid" || icon === "descending-solid") {
        data.sort((a, b) =>
          a.available! < b.available! ? -1 : a.available! > b.available! ? 1 : 0
        );
      } else {
        data.sort((a, b) =>
          a.available! > b.available! ? -1 : a.available! < b.available! ? 1 : 0
        );
      }
    }
  };

  let labelStyles = "tetx-base font-medium text-gray-500 mt-4 lg:mt-0";
  let valueStyles = "text-base font-semibold mt-4 ml-auto lg:ml-0";
  const borderStyles = "lg:border-l lg:pl-6 flex lg:flex-col";
  return (
    <div className={`wrapper`}>
      <div className="card">
        <p className="text-lg font-semibold mb-2 lg:mb-2">Available Balances</p>
        <Divider isVisibleOnMobile className="lg:hidden mt-0 mb-2" />
        <div className="grid lg:grid-cols-4">
          <div>
            <p className="text-base fonr-medium text-gray-500">Total</p>
            <p className="text-3xl font-semibold text-green-600 mt-1">
              {FormatMoney(accountBalanceTotal)}
            </p>
          </div>
          <div className={borderStyles}>
            <p className={labelStyles}>Program Account</p>
            <p className={valueStyles}>
              {FormatMoney(programAccount.balance || 0)}
            </p>
          </div>
          <div className={borderStyles}>
            <p className={labelStyles}>All Active Groups</p>
            <p className={valueStyles}>{FormatMoney(activeAccountTotal)}</p>
          </div>
          <div className={borderStyles}>
            <p className={labelStyles}>All Archived Groups</p>
            <p className={valueStyles}>{FormatMoney(archiveAccountTotal)}</p>
          </div>
        </div>
      </div>
      <div className="card">
        <BadgedTabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <Divider isVisibleOnMobile className="lg:hidden" />
        <div className="flex justify-between mt-4 lg:mt-0">
          <p className="text-lg font-semibold">Transactions and Statements</p>
          {canUpdateBanking && (
            <SnapButton
              className="hidden lg:inline-flex"
              variant="primary"
              onClick={transferToggle}
              icon="switch-horizontal-solid"
            >
              Internal Transfer
            </SnapButton>
          )}
        </div>
        <div className="flex justify-between">
          <ShowingResults
            totalNumOfResults={data.length}
            numOfResultsBeingDisplayed={
              data.length <= 10
                ? data.length
                : ITEMS_PER_PAGE * page + 10 >= data.length
                ? data.length
                : ITEMS_PER_PAGE * page + 10
            }
            startingNumOfResults={
              data.length === 0 ? 0 : ITEMS_PER_PAGE * page + 1
            }
          />
          <div
            className="flex items-center text-blue-600 font-bold lg:text-base sm:text-sm cursor-pointer"
            onClick={() =>
              exportReport({
                variables: {
                  status: selectedTab === 0 ? "active" : "archived",
                },
              })
            }
          >
            <SnapIcon
              size="xs"
              className="pb-1"
              icon="download-solid"
            ></SnapIcon>
            <p className="ml-2">Export as CSV</p>
          </div>
        </div>
        {
          {
            0: (
              <AccountTable
                accountData={data}
                page={page}
                setPage={setPage}
                handleSort={handleSort}
                loadingAccounts={loadingAccounts}
              />
            ),
            1: (
              <AccountTable
                accountData={data}
                page={page}
                setPage={setPage}
                handleSort={handleSort}
                loadingAccounts={loadingAccounts}
              />
            ),
          }[selectedTab]
        }
      </div>
      {canUpdateBanking && (
        <FloatingActionBtn
          onClick={transferToggle}
          icon={"switch-horizontal-solid"}
        />
      )}
      <ProgramInteralTransfer
        transferOpen={transferOpen}
        transferToggle={transferToggle}
      />
    </div>
  );
}

export default AllAcounts;
