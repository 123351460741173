import React from "react";
import emptyCard from "assets/card_empty.svg";

type EmptyCardsProps = {
  message: string;
};

function EmptyCards({ message }: EmptyCardsProps) {
  return (
    <div className="flex flex-col lg:mt-28 lg:mb-[30%] w-48 h-40">
      <img src={emptyCard} alt="No Debit Cards Created" />
      <p>{message}</p>
    </div>
  );
}

export default EmptyCards;
