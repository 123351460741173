import { gql } from "@apollo/client";

export const GET_INVITES = gql`
  query SpendInvites {
    spendInvites {
      count
      invites {
        createdAt
        email
        expiresAt
        firstName
        groupId
        id
        isUser
        lastName
        organizationId
        status
        type
        userId
      }
    }
  }
`;

export const GET_INVITED_USERS = gql`
  query SpendFilteredInvites(
    $filterBy: InviteFilterEnum
    $filterValue: String
  ) {
    spendInvites(filterBy: $filterBy, filterValue: $filterValue) {
      invites {
        email
        userId
        firstName
        lastName
        status
        type
        id
        groupId
        isDeliverable
        isArchived
      }
    }
  }
`;

export const GET_INVITES_FILTERED = gql`
  query SpendInvitesFiltered(
    $where: SpendInviteWhereInput
    $pagination: SpendPaginationInput
    $sort: SpendSortInput
    $includeGroup: Boolean = false
    $includeCards: Boolean = false
  ) {
    spendInvitesFiltered(where: $where, pagination: $pagination, sort: $sort) {
      count
      invites {
        id
        email
        userId
        firstName
        lastName
        status
        type
        expiresAt
        groupId
        group @include(if: $includeGroup) {
          id
          name
        }
        debitCard @include(if: $includeGroup)
        debitCards @include(if: $includeCards) {
          id
          lastFour
          status
        }
        isDeliverable
        isArchived
        isApprover
      }
    }
  }
`;

export const GET_DEBIT_CARD_INVITES = gql`
  query SpendDebitCardInvites($groupId: String) {
    spendDebitCardInvites(groupId: $groupId) {
      count
      invites {
        firstName
        lastName
        userId
        groupId
        organizationId
      }
    }
  }
`;

export const GROUP_INVITE = gql`
  query SpendEmailInvite($id: String!) {
    spendInvite(id: $id) {
      invites {
        id
        email
      }
    }
    me {
      id
      email
      firstName
      lastName
    }
  }
`;
export const GET_INVITE_BY_ID = gql`
  query SpendInvite($spendInviteId: String!) {
    spendInvite(id: $spendInviteId) {
      count
      invites {
        id
        isArchived
        status
        expiresAt
        group {
          isArchived
        }
      }
    }
  }
`;
