import { Maybe } from "graphql/jsutils/Maybe";

type NumberCardProps = {
  label: string;
  value: Maybe<string> | undefined;
  middle?: boolean;
};

function NumberCard({ label, value, middle }: NumberCardProps) {
  const baseStyles =
    "border rounded-xl px-6 pt-5 pb-6 flex flex-row-reverse justify-between";
  const lgStyles = "lg:flex-col lg:w-1/3";
  const middleStyles = middle ? "my-3 lg:my-0 lg:mx-3" : "";
  return (
    <div className={`${baseStyles} ${lgStyles} ${middleStyles}`}>
      <div className="text-[#1E293B] text-4xl font-bold">{value}</div>
      <div className="text-gray-500 lg:text-sm text-lg font-medium py-3 lg:py-0 lg:mt-3">
        {label}
      </div>
    </div>
  );
}

export default NumberCard;
