import {
  DropdownMenuItem,
  DropdownItemSelected,
} from "@snap-mobile/snap-ui/dist/types/utils";
import { SnapDropDown, SnapIcon } from "suit";

type SortProps = {
  selectedSortOption: string;
  options: DropdownMenuItem[];
  handleSort: (selectedSortingOption: DropdownItemSelected) => void;
  showOnWeb?: boolean;
};

function Sort({
  selectedSortOption,
  options,
  handleSort,
  showOnWeb,
}: SortProps) {
  return (
    <div className={`flex mb-4 ${!showOnWeb && "lg:hidden"}`}>
      <SnapIcon icon="sort-descending-line" size="sm" color="#64748B" />
      <div className="flex ml-1">
        <SnapDropDown
          buttonText="Sort"
          leftHang
          defaultValue={selectedSortOption}
          options={options}
          modalType="drawer"
          onSnap-dropdown-item-selected={(e) => handleSort(e.detail)}
        />
      </div>
    </div>
  );
}

export default Sort;
