export type BaseError = {
  messages: string[];
};
export type transferError = {
  toError: boolean;
  fromError: boolean;
  noteError: boolean;
  amountError: boolean;
  availableError: boolean;
  macthingError: boolean;
};

export type addInvoiceError = {
  descriptionError: boolean;
  dateError: boolean;
  amountError: boolean;
  budgetError: boolean;
  duplicateNameError: boolean;
};

export type budgetError = {
  categoryError: boolean;
  budgetItemError: boolean;
  amountError: boolean;
  dateError: boolean;
};

export type debitCardError = {
  cardLimit: boolean;
  cardAssignedTo: boolean;
  birthdate: boolean;
  shippingNotSelected: boolean;
  shippingAddress: {
    address: boolean;
    city: boolean;
    state: boolean;
    zip: boolean;
  };
};

export type emailErrors = {
  subject: boolean;
  message: boolean;
};

export type parentAddInvoicesErrors = {
  budgetItemError: boolean;
  descriptionErorr: boolean;
  dateErorr: boolean;
  amountGuardianPaysError: boolean;
  seasonError: boolean;
  groupError: boolean;
  minAmountError: boolean;
  rosterError: boolean;
};

export type payeeErrors = {
  nameError: boolean;
  address1Error: boolean;
  cityError: boolean;
  stateError: boolean;
  zipCodeError: boolean;
};

export type sendCheckErrors = {
  payeeError: boolean;
  amountError: boolean;
  memoError: boolean;
};

export type creditMemoStepOne = {
  groupError: boolean;
  seasonError: boolean;
  creditAmountError: boolean;
  dateError: boolean;
};

export type editMemoErrors = {
  amountError: boolean;
  dateError: boolean;
};

export type GroupErrorType = BaseError & {
  groupName: boolean;
};
export const defaultGroupError: GroupErrorType = {
  groupName: false,
  messages: [],
};

export type SeasonErrorType = BaseError & {
  seasonStart: boolean;
  seasonEnd: boolean;
  seasonDate: boolean;
};
export const defaultSeasonError: SeasonErrorType = {
  seasonStart: false,
  seasonEnd: false,
  seasonDate: false,
  messages: [],
};

export type DiscountErrorType = BaseError & {
  discountAmount: boolean;
  discountCutOffDate: boolean;
  minPurchAmount: boolean;
};
export const defaultDiscountError: DiscountErrorType = {
  discountAmount: false,
  discountCutOffDate: false,
  minPurchAmount: false,
  messages: [],
};

export type signUpErrors = {
  nameError: boolean;
  groupError: boolean;
  seasonError: boolean;
};

export type counterpartyToAddErrors = {
  nameError: boolean;
  routingNumberError: boolean;
  entityTypeError: boolean;
  accountTypeError: boolean;
};

export type achInputErrors = {
  counterpartyError: boolean;
  noteError: boolean;
  amountError: boolean;
};
