export const HandleSortingIcon = (
  tabName: string,
  selectedTab: string,
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>,
  icon: "ascending-solid" | "descending-solid" | "selector-solid",
  setIcon: React.Dispatch<
    React.SetStateAction<
      "ascending-solid" | "descending-solid" | "selector-solid"
    >
  >
) => {
  if (tabName !== selectedTab) {
    setSelectedTab(tabName);
    setIcon("ascending-solid");
  } else {
    switch (icon) {
      case "selector-solid":
        setIcon("ascending-solid");
        break;
      case "ascending-solid":
        setIcon("descending-solid");
        break;
      default:
        setIcon("ascending-solid");
        break;
    }
  }
};
