import { useLocation, useNavigate } from "react-router-dom";
import DataCard from "shared-components/data-card";
import { LabelValueObject } from "types/label-value-object";
import { collectionData } from "types/programs";
import GroupContext from "context/group-context";
import { useContext } from "react";
import { SpinnerContainer } from "shared-components/spinner";

type CardsProps = {
  collectionData: collectionData[];
  handleSelectall: (
    selectedItems: collectionData[],
    selectAll: boolean,
    isChecked: boolean
  ) => void;
  prepRightData: (
    collection: collectionData,
    status: string
  ) => LabelValueObject[];
  allSelected: boolean;
  canEditParticipant: boolean;
  isArchived: boolean;
  page?: number;
  queryLoading?: boolean;
  handleCollectionInviteStatus: (collectionData: collectionData) => string;
};

function Cards({
  collectionData,
  handleSelectall,
  prepRightData,
  allSelected,
  canEditParticipant,
  isArchived,
  queryLoading,
  handleCollectionInviteStatus,
}: CardsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const Group = useContext(GroupContext);

  return (
    <div className="mb-4">
      {!queryLoading ? (
        collectionData.map((collection) => {
          let status = handleCollectionInviteStatus(collection);
          return (
            <DataCard
              key={collection.groupRosterId}
              hasCheckbox={canEditParticipant && !isArchived}
              isChecked={collection.isChecked || allSelected}
              checkboxAction={(e) =>
                handleSelectall([collection], false, e.currentTarget.checked)
              }
              title={collection.title}
              titleAction={() => {
                Group?.setParticipant(collection.title);
                if (location.pathname.startsWith("/groups")) {
                  navigate(`/groups/participant-details/${collection.id}`);
                } else {
                  navigate(`/group/participant-details/${collection.id}`);
                }
              }}
              kvLeft={[]}
              kvRight={prepRightData(collection, status)}
              action={0}
              className={`flex ${
                collection.isChecked && "bg-blue-50 border-blue-400"
              }`}
            />
          );
        })
      ) : (
        <SpinnerContainer loading={queryLoading} />
      )}
    </div>
  );
}

export default Cards;
