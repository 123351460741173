import Divider from "shared-components/divider";
import { useContext, useEffect, useState } from "react";
import ProgramContext from "context/program-context";
import { SnapButton, SnapToggle } from "suit";
import InputMask from "shared-components/input-mask";
import SnapCard from "assets/snap_card.svg";
import { FormatMoney, ParseMoney } from "helpers/format-money";
import { useMutation } from "@apollo/client";
import { UPDATE_ORG_SETTINGS } from "graphql/mutations/organization";
import { GET_ORGANIZATION_SETTINGS } from "graphql/queries/organization";
import ToastContext from "context/toast-context";

function Approvals() {
  const program = useContext(ProgramContext);
  const toast = useContext(ToastContext);

  const [internalToggle, setInternalToggle] = useState(false);
  const [internalAmount, setInternalAmount] = useState("");
  const [externalToggle, setExternalToggle] = useState(false);
  const [externalAmount, setExternalAmount] = useState("");
  const [sendCheckToggle, setSendCheckToggle] = useState(false);
  const [sendCheckAmount, setSendCheckAmount] = useState("");
  const [debitCardToggle, setDebitCardToggle] = useState(false);

  const [updateSettings, { loading, data }] = useMutation(UPDATE_ORG_SETTINGS, {
    refetchQueries: [{ query: GET_ORGANIZATION_SETTINGS }],
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const settings = program?.settings;
    setInternalToggle(settings?.internalTransferApproval ?? false);
    setExternalToggle(settings?.externalTransferApproval ?? false);
    setSendCheckToggle(settings?.sendCheckApproval ?? false);
    setDebitCardToggle(settings?.debitCardApproval ?? false);
    setInternalAmount(
      FormatMoney(settings?.internalTransferLimit).replace("$", "")
    );
    setExternalAmount(
      FormatMoney(settings?.externalTransferLimit).replace("$", "")
    );
    setSendCheckAmount(FormatMoney(settings?.sendCheckLimit).replace("$", ""));
  }, [program?.settings]);

  useEffect(() => {
    if (!loading && data && data.spendSettingsUpdate) {
      toast?.setToast({
        message: "Settings updated successfully",
        type: "success",
      });
    }
    // eslint-disable-next-line
  }, [loading, data]);

  const handleSave = () => {
    let input = {
      internalTransferApproval: internalToggle,
      internalTransferLimit: ParseMoney(internalAmount),
      externalTransferApproval: externalToggle,
      externalTransferLimit: ParseMoney(externalAmount),
      sendCheckApproval: sendCheckToggle,
      sendCheckLimit: ParseMoney(sendCheckAmount),
      debitCardApproval: debitCardToggle,
    };

    updateSettings({
      variables: {
        input,
      },
    });
  };

  return (
    <div className="card">
      <p className="text-lg font-semibold">Approvals</p>
      <Divider />
      <div className="grid grid-cols-2 gap-4 mt-6">
        <div className="border rounded-lg p-4">
          <div className="flex mb-4">
            <SnapToggle
              checked={internalToggle}
              onSnap-button-toggle={(e) => {
                setInternalToggle(!internalToggle);
              }}
            />
            <div className="ml-4">
              <p className="text-sm font-medium">Transfers - Internal</p>
              <p className="text-sm text-gray-500">
                Specified transfer types greater than or equal to amount limit
                will require approval.
              </p>
            </div>
          </div>
          <InputMask
            label="Amount Limit"
            amount={internalAmount}
            setAmount={setInternalAmount}
            hasError={false}
            onChange={() => {}}
            disabled={!internalToggle}
          />
        </div>
        <div className="border rounded-lg p-4">
          <div className="flex mb-4">
            <SnapToggle
              checked={externalToggle}
              onSnap-button-toggle={(e) => {
                setExternalToggle(!externalToggle);
              }}
            />
            <div className="ml-4">
              <p className="text-sm font-medium">Transfers - External</p>
              <p className="text-sm text-gray-500">
                Specified transfer types greater than or equal to amount limit
                will require approval.
              </p>
            </div>
          </div>
          <InputMask
            label="Amount Limit"
            amount={externalAmount}
            setAmount={setExternalAmount}
            hasError={false}
            onChange={() => {}}
            disabled={!externalToggle}
          />
        </div>
        <div className="border rounded-lg p-4">
          <div className="flex mb-4">
            <SnapToggle
              checked={sendCheckToggle}
              onSnap-button-toggle={(e) => {
                setSendCheckToggle(!sendCheckToggle);
              }}
            />
            <div className="ml-4">
              <p className="text-sm font-medium">Send Checks</p>
              <p className="text-sm text-gray-500">
                Checks greater than or equal to amount limit will require
                approval.
              </p>
            </div>
          </div>
          <InputMask
            label="Amount Limit"
            amount={sendCheckAmount}
            setAmount={setSendCheckAmount}
            hasError={false}
            onChange={() => {}}
            disabled={!sendCheckToggle}
          />
        </div>
        <div className="border rounded-lg p-4">
          <div className="flex mb-4">
            <SnapToggle
              checked={debitCardToggle}
              onSnap-button-toggle={(e) => {
                setDebitCardToggle(!debitCardToggle);
              }}
            />
            <div className="ml-4">
              <p className="text-sm font-medium">New Debit Cards</p>
              <p className="text-sm text-gray-500">
                All cards ordered will require approval.
              </p>
              <img src={SnapCard} alt="Card Logo" className="mt-3 h-32" />
            </div>
          </div>
        </div>
      </div>
      <SnapButton
        variant="primary"
        className="flex justify-end mt-4"
        size="lg"
        onClick={handleSave}
      >
        Save
      </SnapButton>
    </div>
  );
}

export default Approvals;
