import { getDatePickerValue, setDatePickerValue } from "helpers/date-picker";
import { ParseMoney } from "helpers/format-money";
import { isNullOrEmpty } from "helpers/null-or-empty";
import { useEffect, useState } from "react";
import DatePicker from "shared-components/date-picker";
import InputMask from "shared-components/input-mask";
import { DiscountErrorType } from "types/errors";
import { GroupSettings } from "types/settings";

function DiscountSettings({
  canEditSettings,
  selectedGroup,
  setDiscountAmount,
  setDiscountCutOffDate,
  setDiscountMinimumPurchase,
  errors,
}: {
  canEditSettings: boolean;
  selectedGroup: Partial<GroupSettings>;
  setDiscountAmount: React.Dispatch<React.SetStateAction<number>>;
  setDiscountCutOffDate: React.Dispatch<React.SetStateAction<string>>;
  setDiscountMinimumPurchase: React.Dispatch<React.SetStateAction<number>>;
  errors: DiscountErrorType;
}) {
  const [discountAmount, setDiscAmount] = useState<string>(
    `${Number((selectedGroup.discountAmount || 0) / 100).toFixed(2)}`
  );
  const [minPurchAmount, setMinPurchAmount] = useState<string>(
    `${Number((selectedGroup.discountMinimumPurchase || 0) / 100).toFixed(2)}`
  );
  const [hasErrors, setHasErrors] = useState<DiscountErrorType>(errors);
  useEffect(() => {
    if (minPurchAmount) {
      setDiscountMinimumPurchase(ParseMoney(minPurchAmount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minPurchAmount]);

  useEffect(() => {
    if (discountAmount) {
      setDiscountAmount(ParseMoney(discountAmount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountAmount]);

  useEffect(() => {
    setHasErrors(errors);
  }, [errors]);
  return (
    <div className="lg:flex mt-2 lg:max-w-[1000px]">
      <InputMask
        amount={discountAmount}
        setAmount={setDiscAmount}
        hasError={hasErrors["discountAmount"] || false}
        onChange={() => {
          setHasErrors({ ...errors, discountAmount: false });
        }}
        label="Discount Amount"
        labelStyle="text-sm mb-1"
        disabled={!canEditSettings ? true : undefined}
      />
      <DatePicker
        className={"w-[30%] mx-5 lg:mt-0 mt-5"}
        label={"Cut-off Date"}
        date={getDatePickerValue(selectedGroup.discountCutOffDate ?? "")}
        setDate={(e) => {
          const newDate = setDatePickerValue(e);
          setDiscountCutOffDate(newDate);
          setHasErrors({ ...hasErrors, discountCutOffDate: false });
        }}
        hasError={hasErrors.discountCutOffDate}
      />
      <InputMask
        amount={minPurchAmount}
        setAmount={setMinPurchAmount}
        hasError={hasErrors["minPurchAmount"] || false}
        onChange={() => {
          setHasErrors({ ...hasErrors, minPurchAmount: false });
        }}
        label="Minimum Purchase"
        labelStyle="text-sm mb-1"
        disabled={!canEditSettings ? true : undefined}
      />
    </div>
  );
}

export function Validate(
  formValues: Partial<GroupSettings>
): DiscountErrorType {
  const {
    discountsEnabled,
    discountCutOffDate,
    discountAmount,
    discountMinimumPurchase,
  } = formValues;
  const errors = {
    discountAmount: false,
    discountCutOffDate: false,
    minPurchAmount: false,
    hasErrors: false,
    messages: [] as string[],
  };

  if (discountsEnabled) {
    //TODO add a better way to test for valid date
    if (discountCutOffDate && discountCutOffDate?.includes("/")) {
      errors.discountCutOffDate =
        new Date(discountCutOffDate).getTime() <= new Date().getTime();
      if (errors.discountCutOffDate)
        errors.messages.push("Date must be in future");
    } else if (isNullOrEmpty(discountCutOffDate)) {
      errors.discountCutOffDate = true;
      errors.messages.push("Cut-off date is required");
    }
    if (discountAmount) {
      if (discountAmount <= 0) {
        errors.discountAmount = true;
        errors.messages.push("Amount should be greater than $0.00");
      }
    } else {
      errors.discountAmount = true;
      errors.messages.push("Amount is required");
    }
    if (discountMinimumPurchase) {
      if (discountMinimumPurchase <= (discountAmount || 0)) {
        errors.minPurchAmount = true;
        errors.messages.push(
          "Minimum purchase must be greater than Discount Amount"
        );
      }
    } else {
      errors.minPurchAmount = true;
      errors.messages.push("Minimum purchase is required");
    }
  }

  return errors;
}

export default DiscountSettings;
