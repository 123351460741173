import DisplayContext from "context/display-context";
import useModal from "hooks/use-modal";
import UnsavedChanges from "pages/display-page/unsavedChanges";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavTabs } from "types/nav-tabs";

type TabNavLinkProps = {
  links?: NavTabs[];
  currentLocation: string;
  selectedItem?: any;
};

function TabNavLink({ links, currentLocation, selectedItem }: TabNavLinkProps) {
  const { isOpen, toggle } = useModal();
  const Display = useContext(DisplayContext);
  const navigate = useNavigate();
  const [pathToNav, setPathToNav] = useState("");
  return (
    <div
      className={`${
        links?.length === 2
          ? "grid grid-cols-2 lg:flex"
          : "flex overflow-x-scroll w-full no-scroll-bar"
      } `}
    >
      {links &&
        links.map((prop: NavTabs, idx: number) => {
          return (
            <p
              key={idx}
              onClick={() => {
                if (Display?.settingsSaved) {
                  navigate(
                    `${
                      Display?.settingsSaved
                        ? `/${prop.ParentName}/${prop.ChildName}`
                        : `${window.location.href}`
                    }`,
                    {
                      state: {
                        selectedItem: selectedItem,
                      },
                    }
                  );
                } else {
                  setPathToNav(`/${prop.ParentName}/${prop.ChildName}`);
                  toggle();
                }
              }}
              className={`${
                links.length === 2 && "flex justify-center"
              } whitespace-nowrap mr-8 pb-4 font-medium inline-block cursor-pointer ${
                currentLocation === prop.ChildName
                  ? "text-gray-800 border-solid border-gray-800 border-b-2"
                  : "text-gray-500"
              }`}
            >
              {prop.name}
            </p>
          );
        })}
      <UnsavedChanges
        savedChangesOpen={isOpen}
        savedChangesToggle={toggle}
        path={pathToNav}
      />
    </div>
  );
}

export default TabNavLink;
