import { ExportFile } from "graphql/generated";

export const downloadFile = ({ data, fileName, fileType }: any) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const exportToCsv = (contentToExport: ExportFile) => {
  let content = atob(contentToExport?.content ?? "");
  downloadFile({
    data: [content].join("\n"),
    fileName: contentToExport.fileName,
    fileType: "text/csv",
  });
};
