import { gql } from "@apollo/client";
import { SpendGroup } from "graphql/generated";

export const GET_ALL_BUDGETS = (groups: SpendGroup[]) => {
  const budgetSummaries = groups.map(
    (group) =>
      `${group.id}:spendBudgetsSummary(groupId: "${group.id}", seasonId: "${group.latestSeason?.id}") {
        summaryByCategory {
          budgets {
            category {
              name
              id
              type
            }
            createdAt
            description
            id
            isDefault
            reconciledBudgetTotal
            reconciledInvoicesTotal
            reconciledTotal
            targetAmount
            targetDateAt
            updatedAt
            vaultId
            season{
              id
              name
            }
          }
          createdAt
          id
          isDefault
          isHidden
          name
          organizationId
          type
          updatedAt
        }
        summaryUnreconciled {
          credits {
            count
            total
          }
          debits {
            count
            total
          }
        }
    }`
  );
  return gql`
    query allBudgetSummaries {
      ${budgetSummaries.join("\n")}
    }
  `;
};

export const GenerateInvoiceById = (invoiceList: string[]) => {
  const invoiceMap = invoiceList.map(
    (value) =>
      `${value}:spendInvoiceById(id: "${value}") {
          amount
          balanceDue
          budgetItemId
          createdAt
          description
          dueDate
          feesAmount
          groupRosterId
          id
          isArchived
          isAutoPayAuthorized
          isOptional
          isReconciled
          isRefunded
          lastNotifyId
          lastNotifyDate
          note
          notificationAttempts
          optedIn
          paid
          paidDate
          paymentMethodId
          paymentMethodSource
          paymentScheduleInvoiceId
          refundDate
          status
          updatedAt
          groupRoster {
            group {
              name
            }
            id
            roster {
              name
            }
            seasonId
            season {
              id
              name
            }
          }
          reconciledTransactions {
            amount
            id
            invoiceId
          }
      }`
  );
  return gql`
      query SpendInvoiceByIdBatch {
        ${invoiceMap.join("\n")}
      }
    `;
};
