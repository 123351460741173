import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from "@apollo/client";
import ToastContext from "context/toast-context";
import { Maybe } from "graphql/generated";
import { UPDATE_GROUP_PAYEE } from "graphql/mutations/group";
import { UPDATE_ORG_PAYEE } from "graphql/mutations/organization";
import { useContext, useEffect } from "react";
import FixedModal from "shared-components/fixed-modal/fixed-modal";
import { payeeData } from ".";

type DeletePayeeProps = {
  isDeletePayeeOpen: boolean;
  toggleDeletePayee: () => void;
  payeeToDelete: payeeData;
  refetchPayees: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
  type: "Program" | "Group";
  referenceId: Maybe<string> | undefined;
};

function DeletePayee({
  isDeletePayeeOpen,
  toggleDeletePayee,
  payeeToDelete,
  refetchPayees,
  type,
  referenceId,
}: DeletePayeeProps) {
  const toast = useContext(ToastContext);
  const [archivePayee, { loading, data }] = useMutation(
    type === "Program" ? UPDATE_ORG_PAYEE : UPDATE_GROUP_PAYEE
  );

  useEffect(() => {
    if (
      !loading &&
      data &&
      (data.spendOrganizationPayeeUpdate || data.spendGroupPayeeUpdate)
    ) {
      refetchPayees();
      toast?.setToast({
        message: `${payeeToDelete?.name} successfully deleted`,
        type: "success",
      });
      toggleDeletePayee();
    }
    // eslint-disable-next-line
  }, [loading, data]);

  const handleArchivePayee = () => {
    archivePayee({
      variables: {
        input: {
          id: payeeToDelete?.id,
          referenceId,
          isArchived: true,
          address1: payeeToDelete?.address1,
          city: payeeToDelete?.city,
          name: payeeToDelete?.name,
          state: payeeToDelete?.state,
          zipCode: payeeToDelete?.zipCode,
        },
      },
    });
  };

  return (
    <FixedModal
      isOpen={isDeletePayeeOpen}
      toggle={toggleDeletePayee}
      title="Delete Payee"
      icon="exclamation-solid"
      iconColor="#FEE2E2"
      btn1={{
        text: "Delete",
        btnStyle: "danger",
        onClick: handleArchivePayee,
      }}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: toggleDeletePayee,
      }}
    >
      <div className="modal-card text-center">
        <p className="text-gray-500 text-sm">
          Are you sure you want to remove {payeeToDelete?.name}
        </p>
      </div>
    </FixedModal>
  );
}

export default DeletePayee;
