import { InvitePlus } from "types/invite";
import FixedModal from "./fixed-modal";

type DisableApproverProps = {
  disableApproverOpen: boolean;
  disableApproverToggle: () => void;
  data: InvitePlus;
  handleApproverSettingUpdate: (
    userId: string,
    type: string,
    isEnabled: boolean,
    groupId?: string
  ) => void;
};

function DisableApprover({
  disableApproverOpen,
  disableApproverToggle,
  data,
  handleApproverSettingUpdate,
}: DisableApproverProps) {
  return (
    <FixedModal
      isOpen={disableApproverOpen}
      toggle={disableApproverToggle}
      title={"Disable Approver"}
      icon="exclamation-solid"
      iconColor="#FEE2E2"
      btn1={{
        text: "Submit",
        onClick: () => {
          handleApproverSettingUpdate(
            data.userId!,
            data.type!,
            false,
            data.groupId ?? undefined
          );
        },
      }}
      btn2={{
        text: "Cancel",
        onClick: disableApproverToggle,
      }}
    >
      <div className="modal-card flex text-center">
        <p>
          Are you sure you want to disable the approver setting for{" "}
          {data.firstName} {data.lastName}?
        </p>
      </div>
    </FixedModal>
  );
}

export default DisableApprover;
