import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import ToastContext from "context/toast-context";
import {
  SpendBankTransaction,
  SpendBudget,
  SpendLegacyTransaction,
  useSpendTransactionBudgetUnreconcileMutation,
  useSpendTransactionByIdQuery,
  useSpendTransactionReconcileV2Mutation,
} from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { getTransactionType, legacyTrx } from "helpers/transaction";
import LabelValue from "pages/groups/collections/label-value";
import { useContext, useEffect, useState } from "react";
import Divider from "shared-components/divider";
import CustomModal from "shared-components/modal";
import { SnapButton, SnapSelectMenu, SnapToggle } from "suit";
import Table, { BudgetReconciliationForm } from "./multi-budget-table";
import SeasonContext from "context/season-context";
import { useContextStrict } from "helpers/context-strict";
import Spinner from "shared-components/spinner";
import { ApplyingBankTransaction } from "types/team-banking";
type SpendLegacyTransactionRecon = SpendLegacyTransaction & {
  totalReconciled: number | null;
  legacyAccountId?: string;
};
type ReconcileAsProps = {
  type: "Program" | "Group";
  directionType: "Income" | "Expense";
  reconcileOpen: boolean;
  reconcileToggle: () => void;
  transaction?: SpendBankTransaction | SpendLegacyTransactionRecon;
};

function ReconcileAs({
  type,
  directionType,
  reconcileOpen,
  reconcileToggle,
  transaction,
}: ReconcileAsProps) {
  const isLegacyTransaction =
    transaction?.__typename === "SpendLegacyTransaction";
  const program = useContext(ProgramContext);
  const group = useContext(GroupContext);
  const season = useContextStrict(SeasonContext);
  const toast = useContext(ToastContext);
  const [budgetOptions, setBudgetOptions] = useState<SnapSelectMenuOption[]>(
    []
  );
  const [budgetForm, setBudgetForm] = useState<BudgetReconciliationForm>({
    team: group?.activeGroup,
    season: season.selectedSeason ?? undefined,
    transaction: transaction,
    distribution: [],
  });
  const [budgetError, setBudgetError] = useState(false);
  const [budgetSingle, setBudgetSingle] = useState(true);
  const [budgets, setBudgets] = useState<SpendBudget[]>([]);
  const {
    data: dataDetails,
    loading: loadingDetails,
    error: errorDetails,
  } = useSpendTransactionByIdQuery({
    variables: { spendTransactionId: transaction?.id },
    fetchPolicy: "network-only",
    skip: isLegacyTransaction,
  });

  const [reconcileTransaction, { data, loading, error }] =
    useSpendTransactionReconcileV2Mutation({
      refetchQueries: [
        "SpendTransactionsCompletedWhered",
        "SpendTransactionsLegacy",
      ],
      fetchPolicy: "network-only",
    });

  const [
    undoReconciliation,
    {
      loading: loadingUndoBudgetReconciliation,
      data: undoBudgetReconciliationData,
    },
  ] = useSpendTransactionBudgetUnreconcileMutation({
    refetchQueries: [
      "SpendTransactionsCompletedWhered",
      "SpendTransactionsLegacy",
    ],
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const budgets2 =
      type === "Program"
        ? program?.getBudgets(directionType)
        : season?.selectedSeason?.budgets
            ?.filter(
              (budget: any) =>
                budget?.category.type.toLowerCase() ===
                directionType.toLowerCase()
            )
            .map((x) => {
              return x!;
            });
    setBudgets(budgets2 as SpendBudget[]);
  }, [program, group, directionType, type, season?.selectedSeason?.budgets]);
  useEffect(() => {
    if (isLegacyTransaction) {
      const isReconciled = transaction?.reconciliation != null;
      const isSingle =
        !isReconciled ||
        transaction.reconciliation?.budgetTransactions?.length === 1;
      setBudgetSingle(isSingle);
      const firstReconcileBudgetId =
        isReconciled &&
        transaction.reconciliation?.budgetTransactions?.at(0)?.budgetItemId;
      setBudgetOptions(
        budgets?.map((budget, idx) => ({
          name: `${budget?.description}`,
          selected: isReconciled
            ? firstReconcileBudgetId === budget?.id
            : idx === 0,
        })) ?? []
      );

      setBudgetForm({
        team: group?.activeGroup,
        season: season.selectedSeason ?? undefined,
        transaction: transaction as SpendBankTransaction,
        distribution: budgets?.map((budget) => ({
          budget,
          amount:
            transaction?.reconciliation?.budgetTransactions?.find(
              (bt) => bt?.budgetItemId === budget.id
            )?.amount ?? 0,
        })),
      });
    }
  }, [
    budgets,
    group?.activeGroup,
    isLegacyTransaction,
    season.selectedSeason,
    transaction,
  ]);
  useEffect(() => {
    if (dataDetails?.spendTransaction?.transactionDetail && !loadingDetails) {
      const trx = dataDetails.spendTransaction.transactionDetail;
      const isReconciled = trx?.reconciliation != null;
      const isSingle =
        !isReconciled || trx.reconciliation?.budgetTransactions?.length === 1;
      setBudgetSingle(isSingle);
      const firstReconcileBudgetId =
        isReconciled &&
        trx.reconciliation?.budgetTransactions?.at(0)?.budgetItemId;
      setBudgetOptions(
        budgets?.map((budget, idx) => ({
          name: `${budget?.description}`,
          selected: isReconciled
            ? firstReconcileBudgetId === budget?.id
            : idx === 0,
        })) ?? []
      );

      setBudgetForm({
        team: group?.activeGroup,
        season: season.selectedSeason ?? undefined,
        transaction: trx as ApplyingBankTransaction,
        distribution: budgets?.map((budget) => ({
          budget,
          amount:
            trx.reconciliation?.budgetTransactions?.find(
              (bt) => bt?.budgetItemId === budget.id
            )?.amount ?? 0,
        })),
      });
    }
  }, [
    budgets,
    transaction,
    setBudgetForm,
    group?.activeGroup,
    season.selectedSeason,
    dataDetails,
    loadingDetails,
    errorDetails,
  ]);
  useEffect(() => {
    if (!loading && data && data.spendTransactionReconcileV2) {
      reconcileToggle();
      toast?.setToast({
        message: `Transaction successfully reconciled as ${directionType}`,
        type: "success",
      });
    }
    if (!loading && error) {
      toast?.setToastProps({ message: error.message, type: "danger" });
      toast?.toggleToast();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error]);

  useEffect(() => {
    if (!loadingUndoBudgetReconciliation && undoBudgetReconciliationData) {
      reconcileToggle();
      toast?.setToast({
        message: "Transaction Unreconciled",
        type: "success",
      });
    }
    // eslint-disable-next-line
  }, [loadingUndoBudgetReconciliation, undoBudgetReconciliationData]);

  const handleUndoBudgetReconciliation = () => {
    let reconciledTransactionId = undefined;
    let reconciledBudgetTransactionIds: string[] = [];
    if (budgetForm.transaction?.reconciliation?.id) {
      reconciledTransactionId = budgetForm.transaction?.reconciliation.id;
      budgetForm.transaction?.reconciliation?.budgetTransactions?.forEach(
        (bt) => {
          if (bt?.id) {
            reconciledBudgetTransactionIds.push(bt.id);
          }
        }
      );
    }

    if (reconciledTransactionId && reconciledBudgetTransactionIds.length) {
      const variables = {
        input: {
          reconciledTransactionId,
          reconciledBudgetTransactionIds,
        },
      };
      undoReconciliation({
        variables,
        refetchQueries: ["SpendTransactionsCompletedWhered"],
        fetchPolicy: "network-only",
      });
    } else {
      toast?.setToastProps({
        message: "Missing Id or Budget Transactions Ids",
        type: "danger",
      });
    }
  };

  const determineTransactionType = () => {
    if (!transaction) {
      return "";
    }
    if (isLegacyTransaction && transaction.legacyAccountId) {
      const lgTransaction = transaction as SpendLegacyTransactionRecon;
      return legacyTrx.getType(lgTransaction, lgTransaction.legacyAccountId!);
    }
    const sbTransaction = transaction as SpendBankTransaction;
    return getTransactionType(sbTransaction);
  };

  const getTransactionTotal = () => {
    return (transaction?.amount || 0) - (transaction?.snapAmount || 0);
  };

  return (
    <CustomModal
      isOpen={reconcileOpen}
      toggle={() => {
        reconcileToggle();
      }}
      title={`Reconcile as ${directionType}`}
      btn1={{
        text: "Submit",
        btnStyle: "primary",
        onClick: () => {
          const selectedOption = budgetOptions.find(
            (option) => option.selected
          );
          if (!selectedOption) {
            toast?.setToastProps({
              message: "Budget Item Not Selected",
              type: "danger",
            });
            toast?.toggleToast();
            setBudgetError(true);
          }
          if (transaction && selectedOption) {
            const selectedBudget = budgets.find(
              (budget) => budget.description === selectedOption.name
            );
            let budgetsInput: { amount: number; budgetItemId: string }[] = [];
            if (budgetSingle) {
              if (
                getTransactionTotal() != null &&
                getTransactionTotal() > 0 &&
                selectedBudget?.id != null
              ) {
                budgetsInput = [
                  {
                    amount: getTransactionTotal(),
                    budgetItemId: selectedBudget.id,
                  },
                ];
              } else {
                toast?.setToastProps({
                  message: "Reconcile save error (1)",
                  type: "danger",
                });
                toast?.toggleToast();
                console.log(
                  `transaction amount empty/0 or budget item id missing`,
                  getTransactionTotal(),
                  selectedBudget?.id
                );
              }
            } else {
              if (budgetForm.distribution) {
                budgetsInput = budgetForm.distribution
                  .filter((b) => b.amount > 0)
                  .map((b) => ({
                    budgetItemId: b.budget.id,
                    amount: b.amount,
                  }));
              } else {
                console.log(`budgetForm missing distribution`);
                toast?.setToastProps({
                  message: "Reconcile save error (1)",
                  type: "danger",
                });
                toast?.toggleToast();
              }
            }
            if (
              transaction?.id &&
              getTransactionTotal() &&
              budgetsInput?.length > 0
            ) {
              const factor =
                transaction.direction?.toLowerCase() === "credit" ? 1 : -1;
              const transactionValue = getTransactionTotal() * factor;
              let transactionType =
                transactionValue <= 0 ? "Expense" : "Income";
              const variables = {
                input: {
                  ledgerTransactionId: transaction.id,
                  ledgerTransactionAmount: getTransactionTotal(),
                  budgets: budgetsInput,
                  reverse: transactionType !== directionType,
                },
              };
              reconcileTransaction({
                variables,
              });
            } else {
              console.log(`Transaction null or budgets empty`);
              toast?.setToastProps({
                message: "Reconcile save error (2)",
                type: "danger",
              });
              toast?.toggleToast();
            }
          }
        },
      }}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: () => {
          reconcileToggle();
        },
      }}
    >
      <>
        <div className="modal-card">
          <p>Transaction Details</p>
          <LabelValue
            label={"Date Settled"}
            value={FormatDate(
              transaction?.effective ?? "",
              DateFormatSupported.Numbers
            )}
            labelColor={"text-gray-500"}
            valueColor={""}
            className="mt-3 lg:w-72"
          />
          <LabelValue
            label={"Description"}
            value={
              transaction?.metadata?.summary ||
              transaction?.metadata?.description ||
              "No Description"
            }
            labelColor={"text-gray-500"}
            valueColor={"break-all ml-20"}
            className="mt-3  lg:w-72"
          />
          <LabelValue
            label={"Transaction Type"}
            value={determineTransactionType()}
            labelColor={"text-gray-500"}
            valueColor={""}
            className="mt-3  lg:w-72"
          />
          <LabelValue
            label={"Notes"}
            value={transaction?.transactionNote ?? "N/A"}
            labelColor={"text-gray-500"}
            valueColor={""}
            className="mt-3  lg:w-72"
          />
          <LabelValue
            label={"Total Amount"}
            value={FormatMoney(getTransactionTotal() ?? 0)}
            labelColor={"text-gray-500"}
            valueColor={""}
            className="mt-3  lg:w-72"
          />

          <Divider className="mt-6" isVisibleOnMobile />
          {loadingDetails ? (
            <div className="flex justify-center mt-5 mb-3">
              <Spinner size="medium" />
            </div>
          ) : (
            <>
              <div className="flex justify-between align-middle pt-3">
                <label className="text-sm font-medium mt-2">Budget Item</label>
                <div className="mt-3 flex">
                  <SnapToggle
                    title={"Apply to multiple budget items"}
                    onSnap-button-toggle={() => {
                      setBudgetSingle(!budgetSingle);
                    }}
                    checked={!budgetSingle}
                  />
                  <div className="ml-3 text-sm font-medium text-center vertical-align">
                    Apply to multiple budget items
                  </div>
                </div>
              </div>
              {budgetSingle ? (
                <SnapSelectMenu
                  className="lg:w-72"
                  placeholder="Select Budget Item"
                  selectMenuOptions={budgetOptions}
                  onSnap-select-menu-updated={(e) => {
                    setBudgetOptions(e.detail);
                    setBudgetError(false);
                  }}
                  error={budgetError}
                />
              ) : (
                <Table
                  selectedDetails={budgetForm}
                  setSelectedDetails={setBudgetForm}
                  transactionAmount={getTransactionTotal() ?? 0}
                ></Table>
              )}
              {Math.abs(transaction?.totalReconciled ?? 0) > 0 && (
                <SnapButton
                  className="mt-5"
                  variant="danger"
                  size="xs"
                  disabled={loadingUndoBudgetReconciliation}
                  onClick={handleUndoBudgetReconciliation}
                >
                  {!loadingUndoBudgetReconciliation ? (
                    "Undo Reconciliation"
                  ) : (
                    <Spinner />
                  )}
                </SnapButton>
              )}
            </>
          )}
        </div>
      </>
    </CustomModal>
  );
}

export default ReconcileAs;
