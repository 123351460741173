import React, { useState } from 'react';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import {
  PaymentElement,
  PaymentElementProps,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import NewPmToggle from './NewPmToggle';
import { PaypalPaymentWidget, PaypalWidgetProps } from './PaypalPaymentWidget';
import { buttonDivStyle, buttonStyle, PMProps } from './internal';

export type NewPaymentMethodProps = PMProps & {
  elementOptions: StripePaymentElementOptions;
  hasExistingMethods: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  paypal?: PaypalWidgetProps;
};

export default function NewPaymentMethod({
  disabled,
  elementOptions,
  hasExistingMethods,
  onClick,
  paypal,
  setUseNewPm,
  submit,
  submitButtonContent,
  useNewPm,
}: NewPaymentMethodProps) {
  const elements = useElements();
  const stripe = useStripe();
  const [usePaypal, setUsePaypal] = useState<boolean>(false);
  const onChange: PaymentElementProps['onChange'] | undefined = paypal
    ? (e) => {
        setUsePaypal(e.value.type === 'external_paypal');
      }
    : undefined;

  const oc = onClick ?? (async () => {});

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (usePaypal) {
      return;
    }
    await submit({ elements: elements!, stripe: stripe! });
  };

  return (
    <div className="new-payment-method">
      {hasExistingMethods ? (
        <NewPmToggle setUseNewPm={setUseNewPm} useNewPm={useNewPm} />
      ) : null}
      <form onSubmit={handleSubmit}>
        <PaymentElement onChange={onChange} options={elementOptions} />
        <div style={buttonDivStyle}>
          {paypal && usePaypal ? (
            <PaypalPaymentWidget {...paypal} />
          ) : (
            <button
              style={buttonStyle}
              onClick={oc}
              disabled={disabled}
              type="submit"
            >
              {submitButtonContent}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
