import { useLazyQuery } from "@apollo/client";
import {
  SpendAccount,
  SpendAccountLimitsResponse,
  SpendBudget,
  SpendCategory,
  SpendGroup,
  SpendOrganization,
  SpendSeason,
  SpendSettings,
  useSpendOrganizationAccountLimitsLazyQuery,
  useSpendOrganizationLazyQuery,
} from "graphql/generated";
import { GET_ALL_CATEGORIES } from "graphql/queries/categories";
import {
  GET_ORGANIZATION_ACCOUNTS,
  GET_ORGANIZATION_SETTINGS,
} from "graphql/queries/organization";
import { useEffect, useState } from "react";
import { SpendPermissions } from "types/roles-permissions";
import { ActiveUser } from "./use-user";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import { ArchiveName } from "helpers/archive-name";
export type SpendGroupType = SpendGroup & {
  currentSeason?: SpendSeason;
};

export type ActiveProgram =
  | {
      organization: SpendOrganization | undefined;
      setOrganization: React.Dispatch<
        React.SetStateAction<SpendOrganization | undefined>
      >;
      categories: SpendCategory[];
      setCategories: React.Dispatch<React.SetStateAction<SpendCategory[]>>;
      accounts: SpendAccount[];
      setAccounts: React.Dispatch<React.SetStateAction<SpendAccount[]>>;
      settings: SpendSettings;
      setSettings: React.Dispatch<React.SetStateAction<SpendSettings>>;
      getAccounts: () => SpendAccount[] | undefined;
      getProgramAccount: () => SpendAccount | undefined;
      getBudgets: (type?: string) => SpendBudget[];
      getSpendBaseFee: () => number;
      getSpendPercent: () => number;
      getCardBaseFee: () => number;
      getCardPercent: () => number;
      getAchBaseFee: () => number;
      getAchPercent: () => number;
      getCategories: (type?: string) => SpendCategory[];
      getExternalTransferOutEnabled: () => boolean;
      accountLimits: SpendAccountLimitsResponse | undefined;
      getGroupMenuItems: (groupId?: string) => SnapSelectMenuOption[];
    }
  | undefined;

export default function useProgram(user: ActiveUser) {
  const [organization, setOrganization] = useState<
    SpendOrganization | undefined
  >(undefined);
  const [categories, setCategories] = useState<SpendCategory[]>([]);
  const [accounts, setAccounts] = useState<SpendAccount[]>([]);
  const [settings, setSettings] = useState<SpendSettings>({});
  const [accountLimits, setAccountLimits] = useState<
    SpendAccountLimitsResponse | undefined
  >();

  const [getOrg, { loading: loadingOrganization, data: organizationData }] =
    useSpendOrganizationLazyQuery({
      errorPolicy: "all",
    });

  const [
    getAllCategories,
    { loading: loadingCategories, data: categoriesData },
  ] = useLazyQuery(GET_ALL_CATEGORIES);

  const [getOrgAccounts, { loading: loadingAccounts, data: accountsData }] =
    useLazyQuery(GET_ORGANIZATION_ACCOUNTS);
  const [getOrgSettings, { loading: loadingSettings, data: settingsData }] =
    useLazyQuery(GET_ORGANIZATION_SETTINGS);

  const [
    getAccountLimits,
    { loading: loadingAccountLimits, data: dataAccountLimits },
  ] = useSpendOrganizationAccountLimitsLazyQuery();

  useEffect(() => {
    if (user.getIsUserReady()) {
      getOrg();
      getAllCategories();
      getOrgAccounts();
      getOrgSettings();
    }
    // eslint-disable-next-line
  }, [user._session]);

  useEffect(() => {
    if (accountsData && accountsData.spendOrganizationAccounts) {
      setAccounts(accountsData.spendOrganizationAccounts.accounts);
    }
    if (settingsData && settingsData.spendSettings) {
      setSettings(settingsData.spendSettings);
    }

    if (
      !loadingCategories &&
      categoriesData &&
      categoriesData.spendCategories &&
      categoriesData.spendCategories.categories
    ) {
      setCategories(categoriesData.spendCategories.categories);
    }
    if (
      !loadingOrganization &&
      organizationData &&
      organizationData.spendOrganization
    ) {
      setOrganization(organizationData.spendOrganization);
    }
  }, [
    loadingAccounts,
    accountsData,
    settingsData,
    loadingSettings,
    loadingCategories,
    categoriesData,
    loadingOrganization,
    organizationData,
  ]);
  useEffect(() => {
    if (
      user.getIsUserReady() &&
      organization &&
      user?.checkSpendPermission(SpendPermissions.programBankUpdate)
    ) {
      getAccountLimits();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, user._session]);
  useEffect(() => {
    if (
      !loadingAccountLimits &&
      dataAccountLimits?.spendOrganizationAccountLimits
    ) {
      setAccountLimits(dataAccountLimits.spendOrganizationAccountLimits);
    }
  }, [dataAccountLimits, loadingAccountLimits]);

  const getAccounts = (): SpendAccount[] | undefined => {
    return accounts;
  };
  const getProgramAccount = (): SpendAccount | undefined => {
    return accounts?.find((acc) => acc.type === "main");
  };

  const getBudgets = (type?: string): SpendBudget[] => {
    const budgets =
      organization?.budgets
        ?.filter((budget) => budget != null)
        .map((budget) => budget!) ?? [];
    return type
      ? budgets.filter((budget) => budget.category.type === type.toLowerCase())
      : budgets;
  };

  const getSpendBaseFee = (): number => {
    return organization?.spendBaseFee ?? 0;
  };
  const getSpendPercent = (): number => {
    return organization?.spendPercent ?? 0;
  };
  const getCardBaseFee = (): number => {
    return organization?.cardBaseFee ?? 0;
  };
  const getCardPercent = (): number => {
    return organization?.cardPercent ?? 0;
  };
  const getAchBaseFee = (): number => {
    return organization?.achBaseFee ?? 0;
  };
  const getAchPercent = (): number => {
    return organization?.achPercent ?? 0;
  };
  const getCategories = (type?: string): SpendCategory[] => {
    return type
      ? categories?.filter((category) => category.type === type.toLowerCase())
      : categories;
  };

  const getExternalTransferOutEnabled = (): boolean => {
    return organization?.externalTransferOutEnabled ?? false;
  };

  const getGroupMenuItems = (groupId?: string): SnapSelectMenuOption[] => {
    let groups = [...(organization?.groups ?? [])];
    if (groupId) {
      groups = groups?.filter((g) => g?.id === groupId);
    }

    if (groups?.length) {
      groups.sort((a, b) =>
        a!.name?.toLowerCase()! < b!.name?.toLowerCase()!
          ? -1
          : a!.name?.toLowerCase()! > b!.name?.toLowerCase()!
          ? 1
          : 0
      );
    }

    return (
      groups?.map((group) => {
        return {
          name: ArchiveName(group?.name ?? "", group?.isArchived ?? false),
          selected: false,
          value: group?.id!,
        };
      }) ?? []
    );
  };

  return {
    organization,
    setOrganization,
    categories,
    setCategories,
    accounts,
    setAccounts,
    settings,
    setSettings,
    getAccounts,
    getProgramAccount,
    getBudgets,
    getSpendBaseFee,
    getSpendPercent,
    getCardBaseFee,
    getCardPercent,
    getAchBaseFee,
    getAchPercent,
    getCategories,
    getExternalTransferOutEnabled,
    accountLimits,
    getGroupMenuItems,
  };
}
