import React from "react";
import FixedModal from "shared-components/fixed-modal/fixed-modal";

type ErrorEmailProps = {
  isOpen: boolean;
  toggle: () => void;
};

function ErrorEmail({ isOpen, toggle }: ErrorEmailProps) {
  return (
    <FixedModal
      isOpen={isOpen}
      toggle={toggle}
      title={"Send Invitation"}
      icon="exclamation-solid"
      iconColor="#FEF3C7"
      btn1={{
        text: "Okay",
        onClick: toggle,
        btnStyle: "warning",
      }}
    >
      <p className="text-gray-500 text-sm  ">
        Please select at least one Not Signed Up user.
      </p>
    </FixedModal>
  );
}

export default ErrorEmail;
