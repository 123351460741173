export function SpinnerContainer({
  loading,
  inTable,
  colSpan,
  size,
}: {
  loading: boolean;
  inTable?: boolean;
  colSpan?: number;
  size?: "small" | "medium" | "large" | undefined;
}) {
  const renderDiv = () => {
    return (
      <div className="flex justify-center">
        <Spinner size={size || "medium"} />
      </div>
    );
  };
  const renderTableRow = () => {
    return (
      <tr>
        <td colSpan={colSpan || 1} className="py-3">
          {renderDiv()}
        </td>
      </tr>
    );
  };
  return loading ? (inTable ? renderTableRow() : renderDiv()) : null;
}

function Spinner({
  size,
  className,
}: {
  size?: "small" | "medium" | "large" | undefined;
  className?: string;
}) {
  return (
    <div className={`flex justify-center ${className}`}>
      <div
        className={`inline-block ${
          size === "small"
            ? "h-4 w-4"
            : size === "large"
            ? "h-12 w-12"
            : "h-8 w-8"
        } animate-spin rounded-full ${
          size === "small" ? "border-2" : "border-4"
        } border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
    </div>
  );
}

export default Spinner;
