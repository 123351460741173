import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import React, { useState } from "react";
import Divider from "shared-components/divider";
import InputMask from "shared-components/input-mask";
import Spinner from "shared-components/spinner";
import { SnapSelectMenu } from "suit";
import { transferError } from "types/errors";

type InternalProgramProps = {
  to: SnapSelectMenuOption[];
  isLoading: boolean;
  from: SnapSelectMenuOption[];
  handleSelection: (
    type: string,
    selectedOption: SnapSelectMenuOption[]
  ) => void;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  setNote: React.Dispatch<React.SetStateAction<string>>;
  amount: string;
  hasErrorObj: transferError;
  setHasErrorObj: React.Dispatch<React.SetStateAction<transferError>>;
};

function InternalProgram({
  to,
  isLoading,
  from,
  handleSelection,
  setAmount,
  setNote,
  amount,
  hasErrorObj,
  setHasErrorObj,
}: InternalProgramProps) {
  const [limit, setLimit] = useState("");
  const [limitError, setLimitError] = useState(false);
  return (
    <div className="modal-card">
      <p className="text-sm font-medium text-gray-500">
        Internal transfers usually settle in a few minutes.
      </p>
      <Divider isVisibleOnMobile />
      <div className="lg:grid grid-cols-3 gap-6 flex flex-col lg:mt-4">
        {isLoading ? (
          <>
            <div className="flex flex-col">
              <p className="self-center">Loading Dropdown Items</p>
              <Spinner />
            </div>
            <div></div>
          </>
        ) : (
          <>
            <SnapSelectMenu
              label="To"
              className="mt-4 lg:mt-0"
              selectMenuOptions={to}
              onSnap-select-menu-updated={(e) =>
                handleSelection("to", e.detail)
              }
              error={hasErrorObj.toError || hasErrorObj.macthingError}
            />
            <SnapSelectMenu
              label="From"
              className="mt-4 lg:mt-0"
              selectMenuOptions={from}
              onSnap-select-menu-updated={(e) =>
                handleSelection("from", e.detail)
              }
              error={hasErrorObj.fromError || hasErrorObj.macthingError}
            />
          </>
        )}
        <InputMask
          amount={amount}
          setAmount={setAmount}
          hasError={hasErrorObj.amountError || hasErrorObj.availableError}
          onChange={() =>
            setHasErrorObj({ ...hasErrorObj, amountError: false })
          }
        />
      </div>
      <div className="flex flex-col mt-4 w-full">
        <p>Note</p>
        <textarea
          className={`border-2 border-gray-200 w-full rounded-lg pb-28 px-4 pt-3 ${
            (hasErrorObj.noteError || limitError) && "border-red-500"
          }`}
          onChange={(e) => {
            setLimit(e.target.value);
            if (e.target.value.length >= 81) {
              setLimitError(true);
            } else {
              setLimitError(false);
              setNote(e.target.value);
            }
            setHasErrorObj({ ...hasErrorObj, noteError: false });
          }}
        />
        <div className="flex justify-between">
          <p className="text-sm text-gray-500">Required</p>
          <p className="text-sm text-gray-500">Limit {limit.length} / 80</p>
        </div>
      </div>
    </div>
  );
}

export default InternalProgram;
