import { HorizontalValueStyle } from "shared-components/horizontal-label-value";
import { VerticalValueStyle } from "shared-components/vertical-label-value";

export type LabelValueObject = {
  key: string;
  value: string | string[];
  secondValue?: string | string[];
  valueStyle?: HorizontalValueStyle | VerticalValueStyle;
  valueColor?: string;
  hasWarning?: boolean;
  className?: string;
  customContainerStyle?: string;
  labelAction?: () => void;
  actionLabel?: string;
  icon?: "document-line" | "download-line" | "exclamation-solid";
};

export type LabelValueObjectPlus = {
  label: string;
  valuePlus: {
    label: string;
    value: string | string[] | undefined;
    hasWarning?: boolean;
    valueStyle?: VerticalValueStyle;
    valueColor?: string;
    labelAction?: () => void;
    actionLabel?: string;
    icon?: "document-line" | "download-line" | "exclamation-solid";
  }[];
  customContainerStyle?: string;
};

export function isLabelObjectPlus(
  labelObject: LabelValueObject | LabelValueObjectPlus
): labelObject is LabelValueObjectPlus {
  return (labelObject as LabelValueObjectPlus).valuePlus !== undefined;
}
