import HorizontalLabelValue from "../horizontal-label-value";
import { useContext, useEffect, useState } from "react";

import {
  PaymentsApiCustomerPaymentMethod,
  usePaymentsApiCustomerMutation,
} from "../../graphql/generated";
import UserContext from "../../context/user-context";

type UserCardMethodProps = {
  accountIds?: string[];
};

function UserCardMethod({ accountIds }: UserCardMethodProps) {
  const user = useContext(UserContext);

  const [cards, setCards] = useState<
    PaymentsApiCustomerPaymentMethod[] | undefined
  >();
  const [getCustomer, { data, loading }] = usePaymentsApiCustomerMutation();

  const cardIdentifiers = cards
    ? cards.map((card) => card.identifier).join(", ")
    : "Card";

  useEffect(() => {
    if (!loading && data?.paymentsApiCustomer.paymentMethods) {
      const allPaymentMethods = data.paymentsApiCustomer
        .paymentMethods as PaymentsApiCustomerPaymentMethod[];
      if (accountIds) {
        const filteredAccounts = allPaymentMethods.filter((paymentMethod) => {
          return accountIds.includes(paymentMethod.id);
        });
        setCards(filteredAccounts);
      } else {
        setCards(allPaymentMethods);
      }
    }
  }, [accountIds, data, loading]);

  useEffect(() => {
    if (user?.isParent() && user?.getEmail()) {
      getCustomer();
    }
  }, [getCustomer, user]);
  return <HorizontalLabelValue label={"Method"} value={cardIdentifiers} />;
}

export default UserCardMethod;
