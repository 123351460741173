import { SnapPaginationCustomEvent } from "@snap-mobile/snap-ui/dist/types/components";
import GroupContext from "context/group-context";
import SeasonContext from "context/season-context";
import {
  SpendAccount,
  useSpendGroupsFilteredLazyQuery,
} from "graphql/generated";
import { FormatMoney } from "helpers/format-money";
import { HandleSortingIcon } from "helpers/sorting-icon-change";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Divider from "shared-components/divider";
import { SpinnerContainer } from "shared-components/spinner";
import { SnapIcon, SnapLink, SnapPagination, SnapTable } from "suit";
import { ITEMS_PER_PAGE } from "../../../constants";

type AccountTableProps = {
  accountData: SpendAccount[];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  handleSort: (selectedTab: string, icon: string) => void;
  loadingAccounts?: boolean;
};

function AccountTable({
  accountData,
  page,
  setPage,
  handleSort,
  loadingAccounts,
}: AccountTableProps) {
  const [selectedTab, setSelectedTab] = useState("");
  const [icon, setIcon] = useState<
    "ascending-solid" | "descending-solid" | "selector-solid"
  >("selector-solid");
  const navigate = useNavigate();
  const Group = useContext(GroupContext);
  const Season = useContext(SeasonContext);

  const [getGroup, { data: groupData, loading: loadingGroup }] =
    useSpendGroupsFilteredLazyQuery();
  const handleGroupLink = (groupId: string) => {
    const group = Group?.groups?.find((gr) => gr.id === groupId);
    if (group) {
      Group?.navigateGroupPage("banking", groupId);
      Season?.handleSelectedSeason({ seasonsToSet: group.seasons });
    } else {
      getGroup({
        variables: {
          where: {
            ids: [groupId ?? ""],
            archived: true,
          },
        },
      });
    }
  };
  useEffect(() => {
    if (!loadingGroup && groupData) {
      const group = groupData.spendGroupsFiltered?.groups?.at(0);
      Group?.setAndStoreActiveGroup(group!);
      Season?.handleSelectedSeason({ seasonsToSet: group?.seasons });
      navigate("/groups/group-banking");
    }
    // eslint-disable-next-line
  }, [loadingGroup, groupData]);

  return (
    <div>
      <SnapTable>
        <table className="ui celled table mt-0">
          <thead>
            <tr>
              <th className="w-1/2 text-cell text-left pl-4">
                Account Owner
                <SnapIcon
                  className="cursor-pointer"
                  icon={
                    selectedTab === "Account Owner" ? icon : "selector-solid"
                  }
                  size="xs"
                  color="#64748B"
                  onClick={() => {
                    HandleSortingIcon(
                      "Account Owner",
                      selectedTab,
                      setSelectedTab,
                      icon,
                      setIcon
                    );
                    handleSort(
                      "Account Owner",
                      selectedTab === "Account Owner" ? icon : "selector-solid"
                    );
                  }}
                />
              </th>
              <th className="w-1/2 text-cell text-right pr-8">
                Available Balance
                <SnapIcon
                  className="cursor-pointer"
                  icon={
                    selectedTab === "Available Balance"
                      ? icon
                      : "selector-solid"
                  }
                  size="xs"
                  color="#64748B"
                  onClick={() => {
                    HandleSortingIcon(
                      "Available Balance",
                      selectedTab,
                      setSelectedTab,
                      icon,
                      setIcon
                    );
                    handleSort(
                      "Available Balance",
                      selectedTab === "Available Balance"
                        ? icon
                        : "selector-solid"
                    );
                  }}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {!loadingAccounts ? (
              accountData
                .slice(
                  page * ITEMS_PER_PAGE,
                  page * ITEMS_PER_PAGE + ITEMS_PER_PAGE
                )
                .map((item, idx) => {
                  return (
                    <tr key={item.id} className="lg:h-12">
                      <td>
                        <SnapLink
                          className="text-sm text-blue-600 cursor-pointer"
                          sr-only="srOnly"
                          onClick={() => {
                            handleGroupLink(item.groupId ?? "");
                          }}
                        >
                          {item.name}
                        </SnapLink>
                      </td>
                      <td
                        className="flex justify-between lg:table-cell text-gray-800 lg:text-right border-t-2 border-b-0 border-gray-200 text-sm"
                        data-label="Available Balance"
                      >
                        <p className="self-center">
                          {FormatMoney(item.balance || 0)}
                        </p>
                      </td>
                    </tr>
                  );
                })
            ) : (
              <SpinnerContainer loading={loadingAccounts} inTable colSpan={2} />
            )}
          </tbody>
        </table>
      </SnapTable>
      <Divider />
      <SnapPagination
        itemCount={accountData.length}
        currentPage={page}
        pageSize={ITEMS_PER_PAGE}
        onSnap-pagination-page-changed={(
          event: SnapPaginationCustomEvent<number>
        ) => setPage(event.detail)}
      ></SnapPagination>
    </div>
  );
}

export default AccountTable;
