"use client";

import * as React from "react";
import { DayPicker } from "react-day-picker";

import { cn } from "helpers/cn";

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  setDate: (value: Date | undefined) => void;
};

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  setDate,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3 bg-white w-64", className)}
      classNames={{
        selected: "bg-blue-500 text-white outline outline-2 outline-black",
        months: "flex flex-col",
        month_grid: "w-[100%] grid",
        weekdays: "grid grid-cols-7",
        week: "grid grid-cols-7",
        day: "flex justify-center",
        ...classNames,
      }}
      footer={
        <div className="flex mt-4">
          <p
            className="mr-auto text-sm text-blue-600 font-bold cursor-pointer"
            onClick={() => setDate(undefined)}
          >
            Clear
          </p>
          <p
            className="text-sm text-blue-600 font-bold cursor-pointer"
            onClick={() => setDate(new Date())}
          >
            Today
          </p>
        </div>
      }
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
