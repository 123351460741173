import { useEffect, useState } from "react";
import { SpinnerContainer } from "shared-components/spinner";
import { SnapCheckboxButton, SnapTable } from "suit";
import { GroupRosterChecked } from "types/group-roster";

type AddParticipantTableProps = {
  list: GroupRosterChecked[];
  isLoading: boolean;
  onRosterSelect: (id: number) => void;
};
function AddParticipantTable({
  list,
  isLoading,
  onRosterSelect,
}: AddParticipantTableProps) {
  const [selectableList, setSelecatbleList] =
    useState<GroupRosterChecked[]>(list);

  useEffect(() => {
    setSelecatbleList(list);
  }, [list]);
  return (
    <SnapTable>
      <table className="ui celled lg:table hidden">
        <thead>
          <tr>
            <th></th>
            <th>Participant</th>
            <th>Parent Email</th>
            <th className="icon-cell">Group</th>
          </tr>
        </thead>
        <tbody>
          {selectableList.map((groupRoster, idx) => {
            return (
              <tr
                key={`table_add_existing_participant_${groupRoster.roster?.id}`}
              >
                <td className="checkbox-cell">
                  <SnapCheckboxButton
                    input-name="input name"
                    id="checkbox1"
                    checked={groupRoster.isChecked}
                    onClick={() => onRosterSelect(idx)}
                  ></SnapCheckboxButton>
                </td>
                <td className="first-cell-header" data-label="Participant">
                  {groupRoster.roster?.name}
                </td>
                <td data-label="Parent Email" className="text-cell">
                  {groupRoster.roster?.email}
                </td>
                <td data-label="Groups" className="icon-cell">
                  {groupRoster.group?.name}
                </td>
              </tr>
            );
          })}
          {!isLoading && selectableList.length === 0 && (
            <tr>
              <td colSpan={4}>
                <p className="text-center my-3">No Results</p>
              </td>
            </tr>
          )}
          {isLoading && <SpinnerContainer loading={isLoading} />}
        </tbody>
      </table>
    </SnapTable>
  );
}

export default AddParticipantTable;
