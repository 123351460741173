import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";

export const tabs: DropdownMenuItem[] = [
  { name: "Summary", text: "", value: "", selected: true },
  { name: "Income Budget Items", text: "", value: "", selected: false },
  { name: "Expense Budget Items", text: "", value: "", selected: false },
];
export const incomeBudgetData = [
  {
    id: "1",
    category: "Member Dues",
    budgetItem: "Team Fees",
    date: "01/05/2022",
    amountApplied: "$0.00",
  },
  {
    id: "2",
    category: "Member Dues",
    budgetItem: "Corporate PLAYER Sponsorship",
    date: "01/05/2022",
    amountApplied: "$500.00",
  },
  {
    id: "3",
    category: "Fundraising",
    budgetItem: "Car Wash",
    date: "01/05/2022",
    amountApplied: "$200.00",
  },
];
