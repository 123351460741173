import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import { useEffect, useState } from "react";
import { SnapSelectMenu } from "suit";

const PAGE_LIMIT_OPTIONS: SnapSelectMenuOption[] = [
  {
    name: "10",
    selected: true,
  },
  {
    name: "100",
    selected: false,
  },
  {
    name: "200",
    selected: false,
  },
  {
    name: "500",
    selected: false,
  },
];

type PageLimitProps = {
  setPageLimit: React.Dispatch<React.SetStateAction<number>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  localStorageName: string;
};

function PageLimit({
  setPageLimit,
  setPage,
  localStorageName,
}: PageLimitProps) {
  const [pageLimitOptions, setPageLimitOptions] =
    useState<SnapSelectMenuOption[]>(PAGE_LIMIT_OPTIONS);

  useEffect(() => {
    const pageSize = localStorage.getItem(localStorageName);
    if (pageSize) {
      setPageLimitOptions(
        PAGE_LIMIT_OPTIONS.map((option) => {
          return {
            ...option,
            selected: option.name === pageSize,
          };
        })
      );
      setPageLimit(Number(pageSize));
    } else {
      setPageLimitOptions(PAGE_LIMIT_OPTIONS);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <SnapSelectMenu
      className="ml-auto"
      label="Page Limit"
      modalTitle="Page Limit"
      selectMenuOptions={pageLimitOptions}
      onSnap-select-menu-updated={(e) => {
        const selectedItem = e.detail.find((d) => d.selected);
        if (selectedItem?.name) {
          setPage(0);
          setPageLimit(Number(selectedItem?.name));
          localStorage.setItem(localStorageName, selectedItem?.name);
        }
      }}
    />
  );
}

export default PageLimit;
