import Pending from "shared-components/approvals/pending";

function GroupApprovals() {
  return (
    <div className="wrapper">
      <div className="card">
        <Pending />
      </div>
    </div>
  );
}
export default GroupApprovals;
