import UserContext from "context/user-context";
import { Maybe, SpendGroup, SpendSeason } from "graphql/generated";
import { FormatMoney } from "helpers/format-money";
import getGroupSubtitle from "helpers/group-subtitle";
import { useContext } from "react";
import DataCard from "shared-components/data-card";
import { SpinnerContainer } from "shared-components/spinner";
import { VerticalValueStyle } from "shared-components/vertical-label-value";
import { LabelValueObject } from "types/label-value-object";
import { SpendPermissions } from "types/roles-permissions";
type CardsProps = {
  groupList: SpendGroup[];
  handleModalData: (selectedOption: number, groupSelected: SpendGroup) => void;
  isArchived: boolean;
  handleNavigate: (
    group: SpendGroup,
    latestSeason: Maybe<SpendSeason> | undefined
  ) => void;
  loading?: boolean;
};
function Cards({
  groupList,
  handleModalData,
  isArchived,
  handleNavigate,
  loading,
}: CardsProps) {
  const user = useContext(UserContext);
  const canArchiveGroup = user?.checkSpendPermission(
    SpendPermissions.programGroupsArchive
  );
  const canUpdateGroup = user?.checkSpendPermission(
    SpendPermissions.programGroupsUpdate
  );
  const groupActionablesList: { name: string; text: string; value: string }[] =
    [];
  if (canUpdateGroup) {
    groupActionablesList.push({
      name: "Copy Group",
      text: "Copy Group",
      value: "Copy Group",
    });
  }
  if (canArchiveGroup) {
    groupActionablesList.push({
      name: "Archive Group",
      text: "Archive Group",
      value: "Archive Group",
    });
  }

  const prepRightData = (group: SpendGroup, idx: number) => {
    const rightData: LabelValueObject[] = [];

    const latestSeason = group?.latestSeason ?? group?.seasons?.at(idx);
    if (!latestSeason) return rightData;
    const totals = latestSeason.transactionTotals;
    rightData.push({
      key: "Participants",
      value: latestSeason.playerCount?.toString() || "",
    });
    rightData.push({
      key: "Payment Schedule",
      value: latestSeason.paymentScheduleStatus || "",
      valueStyle: latestSeason.paymentScheduleStatus
        ? VerticalValueStyle.Badge
        : undefined,
    });
    rightData.push({ key: "Paid", value: FormatMoney(totals?.paid ?? 0) });
    rightData.push({
      key: "Processing",
      value: FormatMoney(totals?.processing ?? 0),
    });
    rightData.push({
      key: "Upcoming",
      value: FormatMoney(totals?.upcoming ?? 0),
    });
    rightData.push({
      key: "Past Due",
      value: FormatMoney(totals?.pastDue ?? 0),
    });
    rightData.push({
      key: "Credited",
      value: FormatMoney(totals?.credited ?? 0),
    });
    return rightData;
  };
  return (
    <>
      {!loading ? (
        groupList?.map((group, idx) => {
          const latestSeason = group.latestSeason ?? group.seasons?.at(0);
          return (
            <DataCard
              key={`grpL${idx}`}
              className="lg:hidden last:mb-10"
              title={group.name ?? "N/A"}
              titleAction={() => {
                handleNavigate(group, latestSeason);
              }}
              subTitle={getGroupSubtitle(group)}
              kvLeft={[]}
              kvRight={prepRightData(group, idx)}
              action={!isArchived ? 1 : 0}
              menuItems={groupActionablesList}
              menuTitle={group.name ?? "N/A"}
              menuClickListener={(e) =>
                e.includes("Copy")
                  ? handleModalData(1, group)
                  : handleModalData(2, group)
              }
            />
          );
        })
      ) : (
        <SpinnerContainer loading={loading} />
      )}
    </>
  );
}
export default Cards;
