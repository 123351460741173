export type Maybe<T> = T | null;

export function maybeExtract<T>(data: Maybe<T | undefined>[]): T[] {
  return data.filter((x) => x !== null && x !== undefined) as T[];
}

export function maybeMaybeExtract<T, S>(
  data: Maybe<Maybe<T | undefined>[] | undefined>,
  defaultResponse?: S
): T[] | S | undefined {
  if (data) {
    return maybeExtract(data);
  }
  return defaultResponse;
}
