import { useLazyQuery, useQuery } from "@apollo/client";
import ToastContext from "context/toast-context";
import {
  SpendBankTransaction,
  SpendInvoice,
  SpendLegacyTransaction,
  SpendReconciledInvoiceTransaction,
  useSpendTransactionInvoiceUnreconcileMutation,
} from "graphql/generated";
import { GenerateInvoiceById } from "graphql/queries/dynamic-queries";
import { INVOICE_BY_ID } from "graphql/queries/invoices";
import { GET_TRANSACTION_BY_ID } from "graphql/queries/transactions";
import { useContextStrict } from "helpers/context-strict";
import { FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import React, { useEffect, useState } from "react";
import DataCard from "shared-components/data-card";
import CustomModal from "shared-components/modal";
type SpendOrLegacySpendTrx =
  | SpendBankTransaction
  | (SpendLegacyTransaction & { totalReconciled?: number });
type UndoApplyToPlayerInvoiceProps = {
  undoApplyToPlayerOpen: boolean;
  undoApplyToPlayerToggle: () => void;
  transaction: SpendOrLegacySpendTrx;
  startAt?: number;
};
type SelectedReconciledInvoiceTransaction =
  SpendReconciledInvoiceTransaction & {
    isSelected: boolean;
  };
export type StepProps = {
  selectedDetails: UndoInvoiceReconciliationForm;
  setSelectedDetails: React.Dispatch<
    React.SetStateAction<UndoInvoiceReconciliationForm>
  >;
};
export type UndoInvoiceReconciliationForm = {
  transaction: SpendOrLegacySpendTrx;
  distribution?: string[];
};

function UndoApplyToPlayerInvoice({
  transaction,
  undoApplyToPlayerOpen,
  undoApplyToPlayerToggle,
}: UndoApplyToPlayerInvoiceProps) {
  const isLegacyTransaction =
    transaction.__typename === "SpendLegacyTransaction";
  const Toast = useContextStrict(ToastContext);

  const { data: transactionData, loading: loadingTransactionData } = useQuery(
    GET_TRANSACTION_BY_ID,
    {
      variables: { spendTransactionId: transaction.id },
      fetchPolicy: "no-cache",
      skip: isLegacyTransaction,
    }
  );

  const [getQueries, { data, loading }] = useLazyQuery(INVOICE_BY_ID, {
    fetchPolicy: "network-only",
  });
  const [
    unreconcileTransaction,
    {
      data: unreconcileData,
      loading: unreconcileLoading,
      error: unreconcileError,
    },
  ] = useSpendTransactionInvoiceUnreconcileMutation({
    refetchQueries: ["SpendTransactionsWhered"],
    fetchPolicy: "network-only",
  });

  const [reconcileList, setReconcileList] = useState<
    (SpendReconciledInvoiceTransaction & { isSelected: boolean })[]
  >([]);
  const [invoiceList, setInvoiceList] = useState<SpendInvoice[]>([]);
  const [selectedForm, setSelectedForm] =
    useState<UndoInvoiceReconciliationForm>({ transaction: transaction });

  useEffect(() => {
    if (
      isLegacyTransaction &&
      transaction.reconciliation?.invoiceTransactions
    ) {
      const invoiceTransactions =
        transaction.reconciliation.invoiceTransactions;
      setSelectedForm({
        transaction,
      });
      setReconcileList(
        invoiceTransactions?.map((trx: any) => ({
          ...trx,
          isSelected: false,
        })) ?? []
      );
      const invoiceIds = invoiceTransactions.map(
        (invoice: any) => invoice.invoiceId
      );
      getQueries && getQueries({ query: GenerateInvoiceById(invoiceIds) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLegacyTransaction, transaction]);

  useEffect(() => {
    if (!loadingTransactionData && transactionData) {
      const invoiceTransactions =
        transactionData.spendTransaction.transactionDetail.reconciliation
          .invoiceTransactions;
      setSelectedForm({
        transaction: transactionData.spendTransaction.transactionDetail,
      });
      setReconcileList(
        invoiceTransactions?.map((trx: any) => ({
          ...trx,
          isSelected: false,
        })) ?? []
      );
      const invoiceIds = invoiceTransactions.map(
        (invoice: any) => invoice.invoiceId
      );
      getQueries && getQueries({ query: GenerateInvoiceById(invoiceIds) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionData, loadingTransactionData]);

  useEffect(() => {
    if (data && !loading) {
      const invoiceIds =
        transactionData.spendTransaction.transactionDetail.reconciliation.invoiceTransactions.map(
          (invoice: any) => invoice.invoiceId
        );
      const invoiceList = invoiceIds.map((id: any) => data[id]);
      setInvoiceList(invoiceList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);
  useEffect(() => {
    if (
      !unreconcileLoading &&
      unreconcileData?.spendTransactionInvoiceUnreconcile
    ) {
      closeModal();
    } else if (!unreconcileLoading && unreconcileError) {
      // show error
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreconcileData, unreconcileLoading, unreconcileError]);

  const closeModal = () => {
    undoApplyToPlayerToggle();
  };

  const handleCheck = (reconciled: SelectedReconciledInvoiceTransaction) => {
    const newReconciledList = reconcileList.map((rec) => ({
      ...rec,
      isSelected: reconciled.id === rec.id ? !rec.isSelected : rec.isSelected,
    }));
    setSelectedForm({
      ...selectedForm,
      distribution: newReconciledList
        .filter((rec) => rec.isSelected)
        .map((rec) => rec.id!),
    });
    setReconcileList(newReconciledList);
  };
  return (
    <CustomModal
      isOpen={undoApplyToPlayerOpen}
      toggle={closeModal}
      customStyle="lg:w-[70%]"
      title={"Undo Apply to Participant Invoice"}
      btn1={{
        text: "Undo Apply to Invoice",
        btnStyle: "primary",
        onClick: () => {
          if (Toast.isToastOpen) Toast.toggleToast();
          if (
            selectedForm.transaction &&
            selectedForm.transaction.reconciliation?.id &&
            selectedForm.distribution &&
            selectedForm.distribution.length > 0
          ) {
            unreconcileTransaction({
              variables: {
                input: {
                  reconciledTransactionId:
                    selectedForm.transaction.reconciliation.id,
                  reconciledInvoiceTransactionIds: selectedForm.distribution,
                },
              },
            });
          } else {
            Toast.setToastProps({
              message: "Please select at least one reconciliation to undo.",
              type: "danger",
            });
            Toast.toggleToast();
          }
        },
      }}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: () => {
          closeModal();
        },
      }}
    >
      <div className="modal-card">
        <p className="text-lg mt-4">Transaction Details</p>
        <p className="text-sm text-gray-500 mb-4">
          Review the invoices this transaction has been applied to and select
          any that have been applied in error. This will remove the connection
          to the selected invoices and the related reconciliation entries.
        </p>
        <p className="text-sm font-semibold text-gray-500 mb-1">
          <span className="font-normal">Transaction Amount</span>&nbsp;
          <span className="font-medium text-gray-800">
            {transaction?.amount && FormatMoney(transaction.amount)}
          </span>
        </p>
        <p className="text-sm font-semibold text-gray-500 mb-1">
          <span className="font-normal">Applied Amount</span>&nbsp;
          <span className="font-medium text-gray-800">
            {FormatMoney(transaction.totalReconciled)}
          </span>
        </p>
        <p className="text-sm font-semibold text-gray-500 mb-1">
          <span className="font-normal">Payment Date</span>&nbsp;
          <span className="font-medium text-gray-800">
            {transaction?.effective && FormatDate(transaction.effective)}
          </span>
        </p>
        <p className="text-sm font-semibold text-gray-500 mb-1">
          <span className="font-normal">Note</span>&nbsp;
          <span className="font-medium text-gray-800">
            {transaction?.transactionNote || "-"}
          </span>
        </p>
        <div key={"-container"}>
          {reconcileList &&
            reconcileList.map((reconciled) => {
              const invoice = invoiceList.find(
                (invoice) => invoice.id === reconciled.invoiceId
              );
              const particiantName =
                invoice?.groupRoster?.roster?.name ?? "Participant Name";
              const teamName = invoice?.groupRoster?.group?.name ?? "Team";
              const seasonName = invoice?.groupRoster?.season?.name ?? "Season";
              const invoiceDescription = invoice?.description ?? "";
              const dueDate = invoice?.dueDate ?? "";
              const amountApplied = reconciled.amount ?? 0;
              return (
                <DataCard
                  className={"flex"}
                  title={particiantName}
                  key={reconciled.id}
                  kvLeft={[
                    { key: "Group", value: teamName },
                    { key: "Season", value: seasonName },
                    { key: "Description", value: invoiceDescription },
                  ]}
                  kvRight={[
                    { key: "Due Date", value: FormatDate(dueDate) },
                    {
                      key: "Amount Applied",
                      value: FormatMoney(amountApplied),
                    },
                  ]}
                  action={0}
                  hasCheckbox={true}
                  isChecked={reconciled.isSelected}
                  checkboxAction={() => {
                    handleCheck(reconciled);
                  }}
                />
              );
            })}
        </div>
      </div>
    </CustomModal>
  );
}

export default UndoApplyToPlayerInvoice;
