import {
  DropdownItemSelected,
  DropdownMenuItem,
} from "@snap-mobile/snap-ui/dist/types/utils";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import useModal from "hooks/use-modal";
import React, { useState } from "react";
import DataCard from "shared-components/data-card";
import FixedModal from "shared-components/fixed-modal/fixed-modal";
import CustomModal from "shared-components/modal";
import { SnapDropDown, SnapInput } from "suit";
import { LabelValueObject } from "types/label-value-object";

function Pending() {
  const { isOpen: isDetailsOpen, toggle: detailsToggle } = useModal();
  const { isOpen: isApproveOpen, toggle: approveToggle } = useModal();
  const { isOpen: isDecline, toggle: declineToggle } = useModal();

  const [selectedSortOption, setSelectedSortOption] = useState(0);
  const [options] = useState<DropdownMenuItem[]>([
    {
      name: "Date Submitted: New to Old",
      text: "Date Submitted: New to Old",
      value: 0,
      selected: selectedSortOption === 0,
    },
    {
      name: "Date Submitted: Old to New",
      text: "Date Submitted: Old to New",
      value: 1,
      selected: selectedSortOption === 1,
    },
    {
      name: "Activity Type",
      text: "Activity Type",
      value: 2,
      selected: selectedSortOption === 2,
    },
    {
      name: "Group",
      text: "Group",
      value: 3,
      selected: selectedSortOption === 3,
    },
  ]);

  const [dummyData] = useState([
    {
      title: "Transfer",
      LeftData: [
        {
          key: "Type",
          value: "External",
        },
        {
          key: "SubmittedBy",
          value: "Sean Evans",
        },
      ],
      RightData: [
        {
          key: "Amount",
          value: "0",
        },
        {
          key: "Date Sumitted",
          value: "12/12/2023",
        },
      ],
    },
    {
      title: "Transfer",
      LeftData: [
        {
          key: "Type",
          value: "Internal",
        },
        {
          key: "SubmittedBy",
          value: "Regina George",
        },
      ],
      RightData: [
        {
          key: "Amount",
          value: "0",
        },
        {
          key: "Date Sumitted",
          value: "12/12/2023",
        },
      ],
    },
    {
      title: "Debit Card",
      LeftData: [
        {
          key: "Assignee",
          value: "Mary Joe",
        },
        {
          key: "SubmittedBy",
          value: "Rick Steves",
        },
      ],
      RightData: [
        {
          key: "Group",
          value: "Girls JV Basketball",
        },
        {
          key: "Date Sumitted",
          value: "12/12/2023",
        },
      ],
    },
    {
      title: "Send Check",
      LeftData: [
        {
          key: "Recipient",
          value: "Joe Dirt",
        },
        {
          key: "SubmittedBy",
          value: "Jane Doe",
        },
      ],
      RightData: [
        {
          key: "Amount",
          value: "0",
        },
        {
          key: "Date Sumitted",
          value: "12/12/2023",
        },
      ],
    },
  ]);

  const handleSort = (selectedItem: DropdownItemSelected) => {
    setSelectedSortOption(Number(selectedItem.value));
  };

  const handleToggleDetailModal = () => {
    detailsToggle();
  };

  const handleApprove = () => {
    //CALL APPROVE QUERY
    approveToggle();
    detailsToggle();
  };

  const handleDecline = () => {
    //CALL DECLINE QUERY
    declineToggle();
    detailsToggle();
  };

  const prepLeftData = () => {
    const items: LabelValueObject[] = [];

    items.push({
      key: "Type",
      value: "External",
    });
    items.push({
      key: "Submitted By",
      value: "Sean Evans",
    });
    return items;
  };
  const prepRightData = () => {
    const items: LabelValueObject[] = [];

    items.push({
      key: "Amount",
      value: FormatMoney(0),
    });
    items.push({
      key: "Date Submitted",
      value: FormatDate("12/12/2023", DateFormatSupported.Numbers),
    });
    return items;
  };

  return (
    <>
      <h2 className="text-lg font-semibold">Pending Approvals</h2>
      <div className="flex my-4">
        <p className="text-sm text-gray-500 mr-2">Sort</p>
        <SnapDropDown
          buttonText="Sort"
          leftHang
          options={options}
          modalType="drawer"
          onSnap-dropdown-item-selected={(e) => handleSort(e.detail)}
          className="flex items-center"
        />
      </div>
      {dummyData.map((item, idx) => {
        return (
          <DataCard
            key={idx}
            title={item.title}
            titleAction={() => {}}
            kvLeft={prepLeftData()}
            kvRight={prepRightData()}
            action={2}
            actionClick={() => handleToggleDetailModal()}
          />
        );
      })}
      <CustomModal
        isOpen={isDetailsOpen}
        toggle={detailsToggle}
        title="{TYPE} Request"
        btn1={{
          text: "Approve",
          onClick: approveToggle,
        }}
        btn2={{
          text: "Decline",
          onClick: declineToggle,
        }}
      ></CustomModal>
      <FixedModal
        isOpen={isApproveOpen}
        toggle={approveToggle}
        title="Approve {Activity}"
        icon="check-solid"
        iconColor="#D1FAE5"
        btn1={{
          text: "Approve",
          onClick: handleApprove,
        }}
        btn2={{
          text: "Cancel",
          onClick: approveToggle,
        }}
      >
        <p>Are you sure you want to approve this activity</p>
      </FixedModal>
      <CustomModal
        isOpen={isDecline}
        toggle={declineToggle}
        title="Decline Request"
        btn1={{
          text: "Decline Request",
          onClick: handleDecline,
        }}
        btn2={{
          text: "Cancel",
          onClick: declineToggle,
        }}
      >
        <div className="modal-card">
          <p className="font-medium">Why was this request declined?</p>
          <SnapInput
            _id="decline input"
            textarea
            placeholder="Please write a message."
          />
        </div>
      </CustomModal>
    </>
  );
}

export default Pending;
