import { gql } from "@apollo/client";

export const ARCHIVE_GROUP = gql`
  mutation SpendGroupArchive($spendGroupArchiveId: String!) {
    spendGroupArchive(id: $spendGroupArchiveId) {
      id
    }
  }
`;

export const CREATE_GROUP = gql`
  mutation SpendGroupCreate($input: SpendGroupInput!) {
    spendGroupCreate(input: $input) {
      id
    }
  }
`;
export const CREATE_GROUP_ROSTERS = gql`
  mutation SpendGroupRostersCreate($input: SpendGroupRostersInput!) {
    spendGroupRostersCreate(input: $input) {
      id
    }
  }
`;
export const COPY_GROUP = gql`
  mutation SpendGroupDuplicate($spendGroupDuplicateId: String!) {
    spendGroupDuplicate(id: $spendGroupDuplicateId) {
      id
    }
  }
`;

export const CREATE_NEW_SEASON = gql`
  mutation SpendSeasonCreate($input: SpendSeasonInput!) {
    spendSeasonCreate(input: $input) {
      id
    }
  }
`;

export const UPDATE_GROUP_AND_SEASON_SETTINGS_FORM = gql`
  mutation SpendGroupAndSeasonUpdate(
    $spendGroupInput: SpendGroupInput!
    $spendGroupUpdateId: String
    $spendSeasonUpdateId: String!
    $spendSeasonInput: SpendSeasonInput!
  ) {
    spendGroupUpdate(input: $spendGroupInput, id: $spendGroupUpdateId) {
      id
    }

    spendSeasonUpdate(id: $spendSeasonUpdateId, input: $spendSeasonInput) {
      id
    }
  }
`;

export const CREATE_GROUP_DEBIT_CARD = gql`
  mutation SpendGroupDebitCardCreate(
    $input: SpendDebitCardInput!
    $id: String
  ) {
    spendGroupDebitCardCreate(input: $input, id: $id) {
      id
    }
  }
`;

export const GROUP_EXTERNAL_TRANSFER = gql`
  mutation SpendGroupExternalTransfer(
    $input: SpendGroupExternalTransferInput!
  ) {
    spendGroupExternalTransfer(input: $input) {
      amount
      descriptor
      id
      status
    }
  }
`;

export const PARENT_INVOICE_AUTHORIZE = gql`
  mutation SpendPaymentMethodCreate($input: SpendPaymentMethodInput!) {
    spendPaymentMethodCreate(input: $input) {
      updatedInvoiceIds
      payment {
        status
        paymentId
      }
      groupRosterSetting {
        settingsId
        groupRosterId
      }
    }
  }
`;

export const UPDATE_SPEND_SEASON = gql`
  mutation SpendSeasonUpdate($id: String!, $input: SpendSeasonInput!) {
    spendSeasonUpdate(id: $id, input: $input) {
      id
    }
  }
`;

export const PATCH_GROUP_CHECK_DEPOSIT = gql`
  mutation SpendGroupCheckDepositPatch(
    $input: SpendGroupCheckDepositTagsInput!
  ) {
    spendGroupCheckDepositPatch(input: $input) {
      success
    }
  }
`;

export const USER_LEAVE_GROUP = gql`
  mutation SpendUserLeaveGroup($groupId: String!) {
    spendUserLeaveGroup(groupId: $groupId) {
      success
    }
  }
`;

export const ARCHIVE_PARTICIPANT_FROM_SEASON = gql`
  mutation SpendArchiveSeasonMembers($input: SpendArchiveSeasonMembersInput!) {
    spendArchiveSeasonMembers(input: $input) {
      success
    }
  }
`;
export const REMOVE_PARTICIPANT_FROM_GROUP_ROSTER = gql`
  mutation SpendRemoveParticipants($input: SpendRemoveSeasonMemberInput!) {
    spendRemoveParticipants(input: $input) {
      success
    }
  }
`;
export const ADD_GROUP_PAYEE = gql`
  mutation SpendGroupPayeeCreate($input: SpendPayeeInput!) {
    spendGroupPayeeCreate(input: $input) {
      payeeId
    }
  }
`;

export const SEND_GROUP_CHECK = gql`
  mutation SpendGroupCheckSend($input: SpendCheckInput!) {
    spendGroupCheckSend(input: $input) {
      id
      type
    }
  }
`;

export const CANCEL_GROUP_CHECK = gql`
  mutation SpendGroupCheckCancel($checkId: String!) {
    spendGroupCheckCancel(checkId: $checkId) {
      id
    }
  }
`;

export const UPDATE_GROUP_PAYEE = gql`
  mutation SpendGroupPayeeUpdate($input: SpendPayeeUpdateInput!) {
    spendGroupPayeeUpdate(input: $input) {
      payeeId
    }
  }
`;

export const UPDATE_GROUP_ROSTER = gql`
  mutation SpendUpdateGroupRoster($input: SpendRosterUserUpdate!) {
    spendGroupRosterUpdate(input: $input) {
      id
      invitesUpdated
    }
  }
`;

export const DELETE_GROUP_COUNTERPARTY = gql`
  mutation SpendGroupCounterpartyDelete(
    $counterpartyId: String!
    $groupId: String!
  ) {
    spendGroupCounterpartyDelete(
      counterpartyId: $counterpartyId
      groupId: $groupId
    ) {
      success
    }
  }
`;

export const GROUP_ACH_CREDIT = gql`
  mutation SpendGroupACHCredit($input: SpendGroupACHCredit) {
    spendGroupACHCredit(input: $input) {
      paymentId
    }
  }
`;
