import { useLazyQuery, useMutation } from "@apollo/client";
import DisplayContext from "context/display-context";
import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import ToastContext from "context/toast-context";
import {
  Maybe,
  SignupAgreement,
  SpendGroup,
  SpendGroupInput,
} from "graphql/generated";
import { CREATE_GROUP } from "graphql/mutations/group";
import { GET_GROUPS_FILTERED } from "graphql/queries/group";
import {
  DASHBOARD_GROUP_OVERVIEW,
  GET_ORGANIZATION_ACCOUNTS,
} from "graphql/queries/organization";
import { getDatePlusTime } from "helpers/current-time";
import { emptyStringCheck } from "helpers/empty-string-check";
import { useContext, useEffect, useState } from "react";
import SignUpLink from "shared-components/sign-up-link";
import {
  SnapCheckboxButton,
  SnapInput,
  SnapLabeledToggle,
  SnapRadioButton,
} from "suit";
import { defaultGroupInput } from "types/groups";
import ScrollableModal, {
  BtnState,
  BtnType,
} from "shared-components/modal/scrollable-modal";
import UnitComponentGenerator from "unit/unit-component-generator";
import DatePicker from "shared-components/date-picker/index";
import { FormatDate } from "helpers/format-date";

type AddGroupProps = {
  isAddGroupOpen: boolean;
  addGroupToggle: () => void;
  signupAgreement: Maybe<SignupAgreement> | undefined;
};

function AddGroup({
  isAddGroupOpen,
  addGroupToggle,
  signupAgreement,
}: AddGroupProps) {
  const Toast = useContext(ToastContext);
  const Group = useContext(GroupContext);
  const Display = useContext(DisplayContext);
  const Program = useContext(ProgramContext);

  const [createGroup, { loading: loadingGroup, data: groupData }] =
    useMutation(CREATE_GROUP);
  const [getGroup, { loading: loadingNewGroup, data: newGroupData }] =
    useLazyQuery(GET_GROUPS_FILTERED);
  const [getOrgAccounts, { loading: loadingAccounts, data: accountData }] =
    useLazyQuery(GET_ORGANIZATION_ACCOUNTS, {
      fetchPolicy: "network-only",
    });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSignupToggled, setIsSignupToggled] = useState(false);
  const [signupAgreementRequired, setSignupAgreementRequired] = useState(false);
  const [accountType, setAccountType] = useState("group");
  const [isBtnActive, setIsBtnActive] = useState(true);
  const [groupToAdd, setGroupToAdd] =
    useState<SpendGroupInput>(defaultGroupInput);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  useEffect(() => {
    if (
      !loadingAccounts &&
      accountData &&
      accountData.spendOrganizationAccounts
    ) {
      Program?.setAccounts(accountData.spendOrganizationAccounts.accounts);
    }
  }, [loadingAccounts, accountData, Program]);

  useEffect(() => {
    if (!loadingGroup && groupData?.spendGroupCreate) {
      Toast?.setToast({
        message: "Group has been created.",
        type: "success",
      });
      addGroupToggle();
      getGroup({
        variables: {
          where: {
            ids: [groupData.spendGroupCreate.id],
          },
        },
      });
      getOrgAccounts();
      setIsBtnActive(true);
      window.document
        .querySelector("unit-elements-account")
        ?.dispatchEvent(new CustomEvent("unitRequestRefresh"));
    }
    // eslint-disable-next-line
  }, [loadingGroup, groupData]);

  useEffect(() => {
    if (
      !loadingNewGroup &&
      newGroupData &&
      newGroupData.spendGroupsFiltered &&
      Group
    ) {
      let newGroups = [
        ...Group?.groups,
        newGroupData.spendGroupsFiltered.groups
          .map((group: SpendGroup) => {
            return {
              ...group,
              currentSeason: group.latestSeason,
            };
          })
          .at(0),
      ].filter((group) => group !== undefined);
      Display?.setDropdownOptions(
        Program?.organization,
        newGroups.filter((group) => !!group)
      );
      Group?.setGroups(newGroups);
    }
    // eslint-disable-next-line
  }, [loadingNewGroup, newGroupData]);

  const [hasErrors, setHasError] = useState({
    nameError: false,
    seasonStartError: false,
    seasonEndError: false,
    dateError: false,
    sameNameError: false,
    accountError: false,
  });
  const btn1: BtnType = {
    text: "Add Group",
    onClick: () => {
      let foundGroup = Group?.groups
        ?.filter((group) => group !== undefined)
        .find(
          (g) =>
            g.name?.toLowerCase().trim() ===
            groupToAdd.name.toLowerCase().trim()
        );

      if (emptyStringCheck(groupToAdd.name)) {
        setHasError({ ...hasErrors, nameError: true });
        hasErrors.nameError = true;
      }
      setHasError({
        ...hasErrors,
        nameError: !!foundGroup,
        seasonStartError: startDate === undefined,
        seasonEndError: endDate === undefined,
        dateError:
          new Date(FormatDate(startDate)) > new Date(FormatDate(endDate)),
      });
      hasErrors.seasonStartError = startDate === undefined;
      hasErrors.seasonEndError = endDate === undefined;
      hasErrors.dateError =
        new Date(FormatDate(startDate)) > new Date(FormatDate(endDate));

      if (accountType === "") {
        setHasError({
          ...hasErrors,
          accountError: true,
        });
      }
      let {
        nameError,
        seasonEndError,
        seasonStartError,
        dateError,
        accountError,
      } = hasErrors;
      if (nameError || seasonEndError || seasonStartError) {
        Toast?.setToastProps({
          message: "Please fill in all the input fields",
          type: "danger",
        });
        Toast?.toggleToast();
      } else if (dateError) {
        Toast?.setToastProps({
          message: "End Date cannot be before Start Date",
          type: "danger",
        });
        Toast?.toggleToast();
      } else if (foundGroup) {
        Toast?.setToastProps({
          message: "Group name already exists.",
          type: "danger",
        });
        Toast?.toggleToast();
      } else if (accountError) {
        Toast?.setToastProps({
          message: "Please select an account type",
          type: "danger",
        });
        Toast?.toggleToast();
      } else {
        let input = {
          ...groupToAdd,
          isRequireAgreement: signupAgreementRequired,
          isLinkEnabled: isSignupToggled,
          shareAccount: accountType === "program",
        };
        input = {
          ...input,
          seasonEndAt: getDatePlusTime(FormatDate(endDate) ?? ""),
          seasonStartAt: getDatePlusTime(FormatDate(startDate) ?? ""),
        };

        createGroup({
          variables: {
            input,
          },
          refetchQueries: [
            "SpendGroupsFiltered",
            {
              query: DASHBOARD_GROUP_OVERVIEW,
            },
          ],
          fetchPolicy: "no-cache",
          errorPolicy: "all",
        });
        setIsBtnActive(false);
        setGroupToAdd(defaultGroupInput);
      }
    },
    btnStyle: "primary",
    btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
  };

  return (
    <ScrollableModal
      isOpen={isAddGroupOpen}
      toggle={() => {
        if (Toast?.isToastOpen) {
          Toast?.toggleToast();
        }
        addGroupToggle();
        setHasError({
          nameError: false,
          seasonStartError: false,
          seasonEndError: false,
          dateError: false,
          sameNameError: false,
          accountError: false,
        });
        setGroupToAdd(defaultGroupInput);
        setIsBtnActive(true);
      }}
      title={"Add Group"}
      btn1={btn1}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: () => {
          if (Toast?.isToastOpen) {
            Toast?.toggleToast();
          }
          addGroupToggle();
          setHasError({
            nameError: false,
            seasonStartError: false,
            seasonEndError: false,
            dateError: false,
            sameNameError: false,
            accountError: false,
          });
          setGroupToAdd(defaultGroupInput);
          setIsBtnActive(true);
        },
      }}
    >
      <div className="modal-card">
        <div className="hidden">
          <UnitComponentGenerator
            type={"account"}
            data={{
              accountId: Group?.groups.at(0)?.accountId ?? "",
            }}
          />
        </div>
        <div className="px-4 pt-4 lg:px-0 lg:pt-0 ">
          <SnapInput
            _id={"group-name-input"}
            label="Group Name"
            error={hasErrors.nameError || hasErrors.sameNameError}
            onSnap-input-change={(e) =>
              (groupToAdd.name = e.detail.target.value)
            }
            onSnap-input-focus={() =>
              setHasError({ ...hasErrors, nameError: false })
            }
          />
          <div className="grid grid-cols-2 gap-4 mb-4">
            <>
              <DatePicker
                label="Season Start Date"
                className="mt-4"
                date={startDate}
                setDate={setStartDate}
                hasError={hasErrors.seasonStartError || hasErrors.dateError}
              />
              <DatePicker
                label="Season End Date"
                className="mt-4"
                date={endDate}
                setDate={setEndDate}
                hasError={hasErrors.seasonEndError || hasErrors.dateError}
              />
            </>
          </div>
          <SnapInput
            _id={"season-nickname-input"}
            label="Season Nickname"
            helpText="Season Nickname is optional. If there is no nickname, start and end dates will be used to indentify the season."
            onBlur={(e) => (groupToAdd.seasonName = e.target.value.trim())}
          />
        </div>
        <div className="mt-6">
          <p className="text-sm font-medium">Banking</p>
          <SnapRadioButton
            label="Store funds to group-specific account."
            className="mt-4"
            checked={accountType === "group"}
            onClick={() => {
              setAccountType("group");
              setHasError({
                ...hasErrors,
                accountError: false,
              });
            }}
          />
          <SnapRadioButton
            label="Store funds to program account"
            className="mt-4"
            checked={accountType === "program"}
            onClick={() => {
              setAccountType("program");
              setHasError({
                ...hasErrors,
                accountError: false,
              });
            }}
          />
        </div>

        <div className="flex flex-col lg:flex-row">
          <div className="flex flex-col">
            {signupAgreement && (
              <div className="flex mt-6 pl-4 lg:pl-0">
                <SnapCheckboxButton
                  onClick={(e) =>
                    setSignupAgreementRequired(!signupAgreementRequired)
                  }
                  checked={signupAgreementRequired}
                />
                <p className="tex-sm">
                  Require "{signupAgreement.name}" for parent sign up.{" "}
                  <span className="text-blue-600 font-medium">Learn More</span>
                </p>
              </div>
            )}

            <SnapLabeledToggle
              title="Sign Up Link"
              className="mt-6"
              label="Sign Up Link"
              onSnap-labeled-toggle-click={(e) => {
                setIsSignupToggled(e.detail.checked);
                groupToAdd.isLinkEnabled = e.detail.checked;
              }}
            />
          </div>
        </div>
        {isSignupToggled && <SignUpLink />}
      </div>
    </ScrollableModal>
  );
}

export default AddGroup;
