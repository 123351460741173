import { useMutation } from "@apollo/client";
import ProgramContext from "context/program-context";
import ToastContext from "context/toast-context";
import UserContext from "context/user-context";
import {
  useSpendUserNotificationSettingQuery,
  useSpendUserNotificationSettingUpdateMutation,
} from "graphql/generated";
import {
  UPDATE_ALL_GROUPS_SETTINGS,
  UPDATE_ORG_SETTINGS,
} from "graphql/mutations/organization";
import { GET_ORGANIZATION_SETTINGS } from "graphql/queries/organization";
import { GET_USER_NOTIF_SETTINGS } from "graphql/queries/settings";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import FixedModal from "shared-components/fixed-modal/fixed-modal";
import { SnapButton, SnapCheckboxButton } from "suit";
import { SpendPermissions } from "types/roles-permissions";
function NotificationSettings() {
  const program = useContext(ProgramContext);
  const toast = useContext(ToastContext);
  const user = useContext(UserContext);
  const { isOpen: isApplyAllOpen, toggle: applyAllToggle } = useModal();

  const [updateSettings] = useMutation(UPDATE_ORG_SETTINGS, {
    refetchQueries: [{ query: GET_ORGANIZATION_SETTINGS }],
    fetchPolicy: "no-cache",
  });
  const [
    updateAllSettings,
    { loading: loadingUpdateAll, data: updateAllData },
  ] = useMutation(UPDATE_ALL_GROUPS_SETTINGS, {
    refetchQueries: [{ query: GET_ORGANIZATION_SETTINGS }],
    fetchPolicy: "no-cache",
  });
  const { data, loading } = useSpendUserNotificationSettingQuery({
    skip: user?.isObserver() || user?.isParent(),
  });
  const [saveUserSettings] = useSpendUserNotificationSettingUpdateMutation({
    refetchQueries: [{ query: GET_USER_NOTIF_SETTINGS }],
    fetchPolicy: "no-cache",
  });
  const [notifyDueFrequencies, setNotifyDueFrequencies] = useState(
    program?.settings?.notifyDueFrequencies
  );
  const [upcomingNonUsers, setUpcomingNonUsers] = useState(
    program?.settings?.notifyUpcomingNonUsers
  );
  const [pastDueNonUsers, setPastDueNonUsers] = useState(
    program?.settings?.notifyPastDueNonUsers
  );
  const [failedPayment, setFailedPayment] = useState<boolean | undefined>();
  const [pastDueAdmins, setPastDueAdmins] = useState<boolean | undefined>();
  const [bankActivity, setBankActivity] = useState<boolean | undefined>();
  const [userNotifId, setUserNotifId] = useState<string | undefined>();

  useEffect(() => {
    if (program) {
      setNotifyDueFrequencies(program?.settings?.notifyDueFrequencies);
      setUpcomingNonUsers(program?.settings?.notifyUpcomingNonUsers);
      setPastDueNonUsers(program.settings.notifyPastDueNonUsers);
    }
    // eslint-disable-next-line
  }, [program]);

  useEffect(() => {
    if (
      !loadingUpdateAll &&
      updateAllData &&
      updateAllData.spendSettingsUpdateAll.success
    ) {
      toast?.setToast({
        message: "Settings have been applied to all groups",
        type: "success",
      });
      applyAllToggle();
    }
    // eslint-disable-next-line
  }, [loadingUpdateAll, updateAllData]);

  useEffect(() => {
    if (!loading && data?.spendUserNotificationSetting) {
      setUserNotifId(data.spendUserNotificationSetting.notificationSetting?.id);
      setBankActivity(
        data.spendUserNotificationSetting.notificationSetting
          ?.notifyOnBankActivity
      );
      setPastDueAdmins(
        data.spendUserNotificationSetting.notificationSetting
          ?.copyPastDueInvoices
      );
      setFailedPayment(
        data.spendUserNotificationSetting.notificationSetting
          ?.notifyOnFailedCardPayments
      );
    }
  }, [data, loading]);

  const canEditNotification =
    user?.checkSpendPermission(
      user?.isGroupLevel()
        ? SpendPermissions.groupSettingsUpdate
        : SpendPermissions.programSettingsUpdate
    ) ?? false;

  const handleUpdate = (
    option: string,
    isChecked?: boolean,
    pastDue?: string
  ) => {
    let input = {
      enableProgramAgreements: program?.settings?.enableProgramAgreements,
      notifyPastDueNonUsers:
        option === "pastDueNonUsers" ? isChecked : pastDueNonUsers,
      notifyUpcomingNonUsers:
        option === "upcomingNonUsers" ? isChecked : upcomingNonUsers,
      notifyDueFrequencies:
        option === "notifyDueFrequencies"
          ? handleFrequencies(pastDue ?? "")
          : notifyDueFrequencies,
    };
    updateSettings({
      variables: {
        input,
      },
    });
  };

  const handleUserUpdate = (option: string, isChecked?: boolean) => {
    if (userNotifId) {
      let input = {
        id: userNotifId,
        notifyOnBankActivity:
          option === "bankActivity" ? isChecked : bankActivity,
        notifyOnFailedCardPayments:
          option === "failed" ? isChecked : failedPayment,
        copyPastDueInvoices:
          option === "pastDueAdmins" ? isChecked : pastDueNonUsers,
      };
      saveUserSettings({
        variables: {
          input,
        },
      });
    }
  };

  const handleFrequencies = (pastDue: string) => {
    let tempFrequencies: string[] = [...(notifyDueFrequencies ?? [])];
    if (tempFrequencies?.includes(pastDue)) {
      tempFrequencies.splice(tempFrequencies.indexOf(pastDue), 1);
    } else {
      tempFrequencies.push(pastDue);
    }
    setNotifyDueFrequencies(tempFrequencies);
    return tempFrequencies;
  };

  const handleApplyAll = () => {
    let input = {
      enableProgramAgreements: program?.settings?.enableProgramAgreements,
      notifyPastDueNonUsers: pastDueNonUsers,
      notifyUpcomingNonUsers: upcomingNonUsers,
      notifyDueFrequencies,
    };
    updateAllSettings({
      variables: {
        input,
      },
    });
  };

  return (
    <div className="wrapper">
      <div className="card">
        <p className="text-lg font-medium">Notification Settings</p>
        <div className="border border-gray-200 rounded-lg p-4 mt-4 lg:mt-6 ">
          <p>Collections</p>
          <div className="lg:grid grid-cols-2 mt-4 text-sm font-medium">
            <div>
              <p className="text-gray-500">Past Due Invoice Notifications</p>
              <p className="mt-4">Notify parents when an invoice is:</p>
              <SnapCheckboxButton
                className="mt-3"
                label="1 day past due"
                disabled={!canEditNotification}
                checked={notifyDueFrequencies?.includes("1")}
                onClick={() =>
                  handleUpdate("notifyDueFrequencies", undefined, "1")
                }
              />
              <SnapCheckboxButton
                className="mt-3"
                label="7 days past due"
                disabled={!canEditNotification}
                checked={notifyDueFrequencies?.includes("7")}
                onClick={() =>
                  handleUpdate("notifyDueFrequencies", undefined, "7")
                }
              />
              <SnapCheckboxButton
                className="mt-3"
                label="14 days past due"
                disabled={!canEditNotification}
                checked={notifyDueFrequencies?.includes("14")}
                onClick={() =>
                  handleUpdate("notifyDueFrequencies", undefined, "14")
                }
              />
              <p className="mt-4">When sending past due notifications:</p>
              <SnapCheckboxButton
                className="mt-3"
                label="Include parents marked as Not Signed Up (only applies to required invoices)."
                disabled={!canEditNotification}
                checked={pastDueNonUsers ?? false}
                onClick={(e) => {
                  handleUpdate("pastDueNonUsers", e.currentTarget.checked);
                  setPastDueNonUsers(e.currentTarget.checked);
                }}
              />
              <SnapCheckboxButton
                className="mt-3"
                label="CC me on all past due invoice notifications sent to parents."
                disabled={!userNotifId}
                checked={pastDueAdmins ?? false}
                onClick={(e) => {
                  handleUserUpdate("pastDueAdmins", e.currentTarget.checked);
                  setPastDueAdmins(e.currentTarget.checked);
                }}
              />
            </div>
            <div>
              <p className="text-gray-500">Upcoming Invoice Notifications</p>
              <p className="mt-4">
                For signed up parents, we always send upcoming invoice reminders
                three days before a required invoice is due.
              </p>
              <SnapCheckboxButton
                className="mt-3"
                label="Automatically send upcoming invoice reminders to parents marked as Not Signed Up"
                disabled={!canEditNotification}
                checked={upcomingNonUsers ?? false}
                onClick={(e) => {
                  handleUpdate("upcomingNonUsers", e.currentTarget.checked);
                  setUpcomingNonUsers(e.currentTarget.checked);
                }}
              />
              {!user?.isObserver() && (
                <>
                  <p className="mt-4 text-gray-500">
                    Failed Card Payment Notifications
                  </p>
                  <p className="mt-4">
                    We will always contact you when an ACH payment fails.
                  </p>
                  <SnapCheckboxButton
                    className="mt-2"
                    label="Also contact me when a parent's card payment fails."
                    disabled={!userNotifId}
                    checked={failedPayment ?? false}
                    onClick={(e) => {
                      handleUserUpdate("failed", e.currentTarget.checked);
                      setFailedPayment(e.currentTarget.checked);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        {!user?.isObserver() && (
          <div className="border border-gray-200 rounded-lg p-4 mt-4 lg:mt-6 lg:grid grid-cols-2">
            <div>
              <p>Banking</p>
              <SnapCheckboxButton
                className="mt-3"
                label="Notify me whenever there is a debit card, send check, or transfer transaction on one of our accounts."
                disabled={!userNotifId}
                checked={bankActivity ?? false}
                onClick={(e) => {
                  handleUserUpdate("bankActivity", e.currentTarget.checked);
                  setBankActivity(e.currentTarget.checked);
                }}
              />
            </div>
          </div>
        )}
        {user && !user.isGroupLevel() && (
          <div className="mt-4 flex justify-end">
            <SnapButton variant="primary" onClick={applyAllToggle}>
              Apply to all groups
            </SnapButton>
          </div>
        )}
      </div>
      {isApplyAllOpen && (
        <FixedModal
          isOpen={isApplyAllOpen}
          toggle={applyAllToggle}
          title={"Confirm Apply All"}
          icon="exclamation-solid"
          iconColor="#FEF3C7"
          btn1={{
            text: "Confirm",
            onClick: handleApplyAll,
          }}
          btn2={{
            text: "Cancel",
            onClick: applyAllToggle,
          }}
        >
          <div className="modal-card">
            <p className="text-center">
              Clicking confirm will apply current selection to all groups for
              the inputs listed below!
            </p>
            <ul className="mt-3 list-disc">
              <li>Notify parents when an invoice is:</li>
              <li>
                Include parents marked as Not Signed Up (only applies to
                required invoices).
              </li>
              <li>
                Automatically send upcoming invoice reminders to parents marked
                as Not Signed Up
              </li>
            </ul>
          </div>
        </FixedModal>
      )}
    </div>
  );
}

export default NotificationSettings;
