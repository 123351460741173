import { useMutation } from "@apollo/client";
import { TransactionAttachment } from "graphql/generated";
import { DELETE_ATTACHMENT } from "graphql/mutations/transactions";
import { GET_ATTACHMENT_BY_PAYMENT_ID } from "graphql/queries/transactions";
import { useEffect, useState } from "react";
import DragAndDrop from "shared-components/drag-and-drop";
import { SnapIcon, SnapTable } from "suit";

type TransactionManageAttachmentsCardProps = {
  attachments: TransactionAttachment[];
  paymentId: string;
  canEditAttachments: boolean;
};

function TransactionManageAttachmentsCard({
  attachments,
  paymentId,
  canEditAttachments,
}: TransactionManageAttachmentsCardProps) {
  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT);

  const [uploadedFileHasError, setUploadedFileHasError] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setUploadedFileHasError(false);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [uploadedFileHasError]);

  const handleDelete = (attachmemntID: string) => {
    deleteAttachment({
      variables: {
        id: attachmemntID,
      },
      refetchQueries: [
        { query: GET_ATTACHMENT_BY_PAYMENT_ID, variables: { id: paymentId } },
      ],
    });
  };
  return (
    <>
      <div className="flex">
        <p className="mr-auto self-center">Attachments</p>
      </div>
      <SnapTable>
        <table className="ui celled table">
          <thead>
            <tr>
              <th className="w-1/3">File Name</th>
              <th>Date</th>
              <th className="action-cell">Delete</th>
            </tr>
          </thead>
          <tbody>
            {attachments.map((attachment, idx) => {
              return (
                <tr
                  className="lg:h-[52px] h-full flex flex-col lg:table-row lg:odd:bg-white"
                  key={idx}
                >
                  <td className="flex lg:table-cell border-t-0 border-b lg:border-b-0 border-gray-200 pb-4 lg:pb-0">
                    <a
                      href={attachment.url ?? ""}
                      download
                      className="text-blue-600 font-medium mr-auto lg:mr-0"
                    >
                      {attachment.name}
                    </a>
                    <SnapIcon
                      icon="trash-solid"
                      size="sm"
                      className="text-gray-400 flex lg:hidden cursor-pointer"
                      color="blue"
                      onClick={() => handleDelete(attachment?.id ?? "")}
                    ></SnapIcon>
                  </td>
                  <td className="flex text-sm font-medium pt-2 items-center">
                    <p className="mr-auto lg:hidden text-gray-500 font-normal text-sm">
                      Date
                    </p>
                    <p>{attachment.createdAt}</p>
                  </td>
                  {canEditAttachments && (
                    <td className="action-cell hidden lg:table-cell">
                      <SnapIcon
                        icon="trash-solid"
                        size="sm"
                        className="text-gray-400 cursor-pointer"
                        color="blue"
                        onClick={() => handleDelete(attachment?.id ?? "")}
                      ></SnapIcon>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        {canEditAttachments && (
          <>
            <DragAndDrop
              label={""}
              type={"image"}
              setUploadedFileHasError={setUploadedFileHasError}
              paymentId={paymentId}
            />
            <div className="flex justify-center text-sm mt-2 text-gray-500">
              <p
                className={`${uploadedFileHasError && "text-lg text-red-600"}`}
              >
                File size cannot exceed 3MBs
              </p>
            </div>
          </>
        )}
      </SnapTable>
    </>
  );
}

export default TransactionManageAttachmentsCard;
