import { useEffect, useState } from "react";

type InputMaskProps = {
  amount: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  hasError: boolean;
  onChange: () => void;
  label?: string;
  labelStyle?: string;
  disabled?: boolean;
};

function InputMask({
  amount,
  setAmount,
  hasError,
  onChange,
  label = "Amount",
  labelStyle,
  disabled,
}: InputMaskProps) {
  const fmtVal = (s: string) => {
    const value = s.replaceAll(",", "");
    if (!value) {
      return "";
    }
    let [whole, change] = value.split(".");
    if (change) {
      change = change.slice(0, 2);
    } else {
      change = "00";
    }
    return `${whole}.${change}`;
  };

  const [hasFocus, setHasFocus] = useState(false);
  useEffect(() => {
    if (!hasFocus) {
      const value = fmtVal(amount);
      if (value !== "") {
        setAmount(value);
      }
    }
  });
  const onLostFocus = () => {
    const value = fmtVal(amount);
    if (value !== "") {
      setAmount(value);
    }
  };
  const onKeyPress = (e: React.KeyboardEvent) => {
    const allowedKeys = [
      "Backspace",
      "Tab",
      "Shift",
      "ArrowLeft",
      "ArrowRight",
    ];
    const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|<>/?~]/;
    const alphaChars = /[A-Za-z]/;
    // VIP members
    if (allowedKeys.includes(e.key)) return;
    // Don't allow special characters or alpha characters
    if (specialChars.test(e.key) || alphaChars.test(e.key)) e.preventDefault();
    // Also no spaces and multiple dots, 1 is enough.
    if (e.key === " " || (amount.includes(".") && e.key === "."))
      e.preventDefault();
    const validated = amount.match(/^(\d*\.{0,1}\d{0,1}$)/);
    if (!validated) e.preventDefault();
  };
  return (
    <div className="flex flex-col w-full">
      <p className={labelStyle}>{label}</p>
      <div className="flex">
        <p className="flex items-center border-2 border-r-0 rounded-lg rounded-r-none px-2 text-sm text-gray-500 bg-gray-100">
          $
        </p>
        <input
          type="text"
          placeholder="0.00"
          onChange={(e) => {
            setAmount(e.target.value);
            onChange();
          }}
          onBlur={(_) => onLostFocus()}
          onFocus={(_) => setHasFocus(true)}
          onKeyDown={(e) => onKeyPress(e)}
          className={`border-2 h-10 rounded-l-none rounded-lg w-full px-4 ${
            hasError && "border-red-300 border"
          }`}
          value={amount}
          disabled={disabled || undefined}
        />
      </div>
    </div>
  );
}

export default InputMask;
