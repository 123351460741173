"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateUpdateSetupIntent = exports.validateConfirmSetupIntent = exports.validateCustomer = exports.validateUpdate = exports.validatePaymentIntentId = exports.validateCreate = exports.validateConfirm = exports.StripeLayout = exports.StripeIntent = exports.PaymentMethodType = exports.StripeEnvironment = void 0;
const yup_1 = require("yup");
var StripeEnvironment;
(function (StripeEnvironment) {
    StripeEnvironment["RAISE"] = "raise";
    StripeEnvironment["SPEND"] = "spend";
})(StripeEnvironment || (exports.StripeEnvironment = StripeEnvironment = {}));
var PaymentMethodType;
(function (PaymentMethodType) {
    PaymentMethodType["card"] = "CARD";
    PaymentMethodType["bank"] = "BANK";
    PaymentMethodType["other"] = "OTHER";
})(PaymentMethodType || (exports.PaymentMethodType = PaymentMethodType = {}));
var StripeIntent;
(function (StripeIntent) {
    StripeIntent[StripeIntent["PAYMENT"] = 0] = "PAYMENT";
    StripeIntent[StripeIntent["SETUP"] = 1] = "SETUP";
})(StripeIntent || (exports.StripeIntent = StripeIntent = {}));
var StripeLayout;
(function (StripeLayout) {
    StripeLayout[StripeLayout["ACCORDION"] = 0] = "ACCORDION";
    StripeLayout[StripeLayout["TABS"] = 1] = "TABS";
})(StripeLayout || (exports.StripeLayout = StripeLayout = {}));
const confirmPaymentIntentSchema = (0, yup_1.object)({
    returnUrl: (0, yup_1.string)().required(),
    idempotencyKey: (0, yup_1.string)().required(),
});
async function validateConfirm(data) {
    return confirmPaymentIntentSchema
        .validate(data)
        .then(({ returnUrl, idempotencyKey }) => ({
        return_url: returnUrl,
        idempotencyKey,
    }));
}
exports.validateConfirm = validateConfirm;
const createPaymentIntentSchema = (0, yup_1.object)({
    automaticPaymentMethods: (0, yup_1.object)({ enabled: (0, yup_1.bool)() }).optional(),
    customer: (0, yup_1.string)(),
    destination: (0, yup_1.string)().required(),
    finalDestination: (0, yup_1.string)(),
    externalPaymentId: (0, yup_1.string)().required(),
    metadata: (0, yup_1.object)().default({}),
    paymentMethod: (0, yup_1.string)(),
    description: (0, yup_1.string)(),
    permittedPaymentMethods: (0, yup_1.array)().of((0, yup_1.mixed)().defined()),
    snapAmount: (0, yup_1.number)().required().positive(),
    statementDescriptorSuffix: (0, yup_1.string)().max(22),
    setupFutureUsage: (0, yup_1.string)().oneOf(['off_session', 'on_session']).optional(),
    totalAmount: (0, yup_1.number)().required().positive(),
    idempotencyKey: (0, yup_1.string)().required(),
});
async function validateCreate(data) {
    return createPaymentIntentSchema
        .transform(({ amount, paymentMethod, customer, idempotencyKey, metadata }) => {
        const d = {
            amount,
            customer,
            idempotencyKey,
            metadata,
        };
        if (paymentMethod) {
            d.payment_method = paymentMethod;
        }
        return d;
    })
        .validate(data);
}
exports.validateCreate = validateCreate;
const paramsSchema = (0, yup_1.object)({ paymentIntentId: (0, yup_1.string)().required() });
async function validatePaymentIntentId(data) {
    return paramsSchema.validate(data);
}
exports.validatePaymentIntentId = validatePaymentIntentId;
const updatePaymentIntentSchema = (0, yup_1.object)({
    customer: (0, yup_1.string)(),
    metadata: (0, yup_1.object)().default({}),
    idempotencyKey: (0, yup_1.string)().required(),
    paymentMethod: (0, yup_1.string)(),
    snapAmount: (0, yup_1.number)().positive(),
    totalAmount: (0, yup_1.number)().positive(),
});
async function validateUpdate(data) {
    return updatePaymentIntentSchema
        .transform(({ customer, idempotencyKey, metadata, paymentMethod, snapAmount, totalAmount, }) => {
        const d = {
            amount: totalAmount,
            customer,
            idempotencyKey,
            metadata,
            application_fee_amount: snapAmount,
        };
        if (paymentMethod) {
            d.payment_method = paymentMethod;
        }
        return d;
    })
        .validate(data);
}
exports.validateUpdate = validateUpdate;
const getOrCreateCustomerSchema = (0, yup_1.object)({
    existingCustomerId: (0, yup_1.string)(),
    description: (0, yup_1.string)(),
    email: (0, yup_1.string)().required().email(),
    metadata: (0, yup_1.object)().default({}),
    name: (0, yup_1.string)(),
    idempotencyKey: (0, yup_1.string)().required(),
});
async function validateCustomer(data) {
    return getOrCreateCustomerSchema
        .transform(({ description, email, idempotencyKey, metadata, name }) => {
        const d = {
            email,
            idempotencyKey,
        };
        if (description) {
            d.description = description;
        }
        if (metadata) {
            d.metadata = metadata;
        }
        if (name) {
            d.name = name;
        }
        return d;
    })
        .validate(data);
}
exports.validateCustomer = validateCustomer;
const confirmSetupIntentSchema = (0, yup_1.object)({
    returnUrl: (0, yup_1.string)().required(),
    idempotencyKey: (0, yup_1.string)().required(),
});
async function validateConfirmSetupIntent(data) {
    return confirmSetupIntentSchema
        .validate(data)
        .then(({ returnUrl, idempotencyKey }) => ({
        idempotencyKey,
        return_url: returnUrl,
    }));
}
exports.validateConfirmSetupIntent = validateConfirmSetupIntent;
const createSetupIntentSchema = (0, yup_1.object)({
    automaticPaymentMethods: (0, yup_1.object)({ enabled: (0, yup_1.bool)() }).optional(),
    customer: (0, yup_1.string)(),
    destination: (0, yup_1.string)().required(),
    finalDestination: (0, yup_1.string)(),
    externalPaymentId: (0, yup_1.string)().required(),
    metadata: (0, yup_1.object)().default({}),
    paymentMethod: (0, yup_1.string)(),
    description: (0, yup_1.string)(),
    permittedPaymentMethods: (0, yup_1.array)().of((0, yup_1.mixed)().defined()),
    idempotencyKey: (0, yup_1.string)().required(),
    usage: (0, yup_1.string)().oneOf(['off_session', 'on_session']).optional(),
});
const updateSetupIntentSchema = (0, yup_1.object)({
    paymentMethod: (0, yup_1.string)(),
    customer: (0, yup_1.string)(),
    idempotencyKey: (0, yup_1.string)().required(),
});
async function validateUpdateSetupIntent(data) {
    return updateSetupIntentSchema
        .validate(data)
        .then(({ customer, paymentMethod }) => ({
        customer,
        payment_method: paymentMethod,
    }));
}
exports.validateUpdateSetupIntent = validateUpdateSetupIntent;
