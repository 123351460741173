import { useState } from "react";
import CustomModal, { BtnType } from "shared-components/modal";
import { SnapIcon, SnapInput } from "suit";
import { USER_LEAVE_GROUP } from "graphql/mutations/group";
import { useMutation } from "@apollo/client";
import { GET_GUARDIAN_HIGHLIGHT_2 } from "graphql/queries/user";
import { activeGroup } from "./ParentDashboard";
type ParentLeaveTeamModalProps = {
  isOpen: boolean;
  toggle: () => void;
  teamData: activeGroup | undefined;
};

function ParentLeaveTeamModal({
  isOpen,
  toggle,
  teamData,
}: ParentLeaveTeamModalProps) {
  const [leaveGroup] = useMutation(USER_LEAVE_GROUP, {
    onCompleted: toggle,
    refetchQueries: [
      {
        query: GET_GUARDIAN_HIGHLIGHT_2,
        fetchPolicy: "network-only",
      },
    ],
  });
  const [validateMe, setValidateMe] = useState("");
  const [hasError, setHasError] = useState(false);
  const handleValidation = () => {
    if (validateMe === "Leave") {
      setValidateMe("");
      setHasError(false);
      //TODO add logic to leave from the group here
      leaveGroup({
        variables: { groupId: teamData?.groupId },
      });
    } else {
      setHasError(true);
    }
  };

  let btn1: BtnType = {
    text: "Leave Group",
    onClick: handleValidation,
    btnStyle: "danger",
  };
  let btn2: BtnType = { text: "Cancel", onClick: toggle };

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      title={"Leave Group"}
      btn1={btn1}
      btn2={btn2}
    >
      <div className="modal-card">
        <div className="flex bg-red-100 py-4 pr-4 pl-4 rounded-lg">
          <SnapIcon
            icon="exclamation-solid"
            color="red"
            className="self-start pr-2"
          ></SnapIcon>
          <div>
            <p className="text-sm font-semibold">
              Leaving the group using Snap! Spend will cancel AutoPay for all
              remaining payments for the group, but this does not absolve you of
              financial responsibility to the program for these payments.
            </p>
            <p className="text-sm mt-2">
              Type "<b>Leave</b>" and then click "Leave Group" to leave the
              group.
            </p>
            <div className="lg:w-[65%]">
              <SnapInput
                _id={""}
                onSnap-input-change={(e) =>
                  setValidateMe(e.detail.target.value)
                }
                error={hasError}
              ></SnapInput>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
}

export default ParentLeaveTeamModal;
