import ToastContext from "context/toast-context";
import {
  Maybe,
  SpendGroupRoster,
  SpendInvoice,
  useSpendInvoiceArchiveMutation,
} from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { useContext, useEffect, useState } from "react";
import CustomModal, { BtnState } from "shared-components/modal";
import TableRowLabelValue from "shared-components/table-row-label-value";

type DeleteInvoiceProps = {
  deleteInvoiceModalOpen: boolean;
  deleteInvoiceModalToggle: () => void;
  participant: SpendGroupRoster;
  invoice: Maybe<SpendInvoice>;
};

function DeleteInvoice({
  deleteInvoiceModalOpen,
  deleteInvoiceModalToggle,
  participant,
  invoice,
}: DeleteInvoiceProps) {
  const Toast = useContext(ToastContext);
  const [isBtnActive, setIsBtnActive] = useState(true);

  const [archiveInvoice, { loading, data }] = useSpendInvoiceArchiveMutation();

  useEffect(() => {
    if (!loading && data && data.spendInvoiceArchive) {
      Toast?.setToast({
        message: "Invoice Successfully Archived",
        type: "success",
      });
      setIsBtnActive(true);
      deleteInvoiceModalToggle();
    }
    // eslint-disable-next-line
  }, [loading, data]);

  const handleCancel = () => {
    setIsBtnActive(true);
    deleteInvoiceModalToggle();
  };

  const handleArchive = () => {
    setIsBtnActive(false);
    archiveInvoice({
      variables: {
        spendInvoiceArchiveId: invoice?.id ?? "",
      },
      refetchQueries: ["GetParticipantsDetails"],
    });
  };

  return (
    <CustomModal
      isOpen={deleteInvoiceModalOpen}
      toggle={handleCancel}
      title={"Delete Invoice"}
      btn1={{
        text: "Delete Invoice",
        btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
        onClick: handleArchive,
      }}
      btn2={{
        text: "Cancel",
        onClick: handleCancel,
      }}
    >
      <div className="modal-card">
        <p className="text-gray-500">
          <span className="font-semibold">Note: </span>
          This will only delete the invoice for this participant and the parent
          will no longer receive notifications about this item
        </p>
        <table className="w-full mt-4 lg:ml-10 lg:w-[50%]">
          <tbody>
            <TableRowLabelValue
              label={"Participant"}
              value={participant.roster?.name}
            />
            <TableRowLabelValue
              label={"Group"}
              value={participant.group?.name}
            />
            <TableRowLabelValue
              label={"Description"}
              value={invoice?.description}
            />
            <TableRowLabelValue
              label={"Due Date"}
              value={FormatDate(invoice?.dueDate, DateFormatSupported.Words)}
            />
            <TableRowLabelValue
              label={"Amount"}
              value={FormatMoney(invoice?.amount)}
            />
          </tbody>
        </table>
        <p className="text-gray-500 font-bold mt-4">
          Are you sure you want to remove this invoice?
        </p>
      </div>
    </CustomModal>
  );
}
export default DeleteInvoice;
