import { ReactNode } from "react";
import { SnapIcon } from "suit";

type TooltipProps = {
  tooltipOpen: boolean;
  tooltipToggle: () => void;
  children: ReactNode;
  className?: string;
  tooltipClassName?: string;
};

function Tooltip({
  tooltipOpen,
  tooltipToggle,
  children,
  className,
  tooltipClassName,
}: TooltipProps) {
  return (
    <div
      className={`absolute top-10 bg-white border borger-gray-200 rounded-lg shadow-xl p-4 z-50 ${className}  ${
        tooltipOpen ? "scale-100" : "scale-0"
      }`}
    >
      <div
        className={`w-4 h-4 -mt-6 rotate-45 bg-white border-l border-t ${tooltipClassName}`}
      ></div>
      <div className="flex">
        <div className="pr-3">{children}</div>
        <div>
          <SnapIcon icon="x-solid" color="#2563EB" onClick={tooltipToggle} />
        </div>
      </div>
    </div>
  );
}

export default Tooltip;
