import { Filters } from "types/filter-types";

export const resetFilters = (filtersToReset: Filters[]) => {
  const defaultFilters = filtersToReset.map((filter) => {
    if (filter.filterName?.includes("Date")) {
      filter.placeholder = "MM / DD / YYYY";
    } else {
      filter.filterOptions = filter.filterOptions?.map((option) => {
        return { ...option, selected: false };
      });
    }
    return filter;
  });
  return defaultFilters;
};
