import { useLazyQuery } from "@apollo/client";
import DisplayContext from "context/display-context";
import ProgramContext from "context/program-context";
import {
  SpendBankAccount,
  SpendDebitCard,
  SpendInvite,
} from "graphql/generated";
import {
  GETorganization_BUDGET_SUMMARY,
  GET_SPEND_BUDGET_SUMMARY,
} from "graphql/queries/budgets";
import {
  GET_GROUP_BANK_ACCOUNTS,
  GET_GROUP_DEBIT_CARDS,
} from "graphql/queries/group";
import { GET_INVITES_FILTERED } from "graphql/queries/invites";
import {
  GET_ORGANIZATION_DEBIT_CARDS,
  GET_ORG_BANK_ACCOUNTS,
} from "graphql/queries/organization";
import { filterDebitCards } from "helpers/filter-debit-cards";
import { BudgetSummaryConvertDataToUiModels } from "helpers/summary-related";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import BudgetItemDetails from "shared-components/modal/budget-item-details";
import {
  BudgetSummaryRecord,
  BudgetTotalValue,
  budgetItemDetails,
} from "types/budget-summary";
import AvailableBalanceCard from "./available-balance-card";
import TablePieChart from "./table-pie-chart";
import TeamAssistantInvites from "./team-assistant-invites";
import TeamDebitCards from "./team-debit-cards";
import TeamsOverview from "./teams-overview";
import GroupContext from "context/group-context";

function AdminDashboard() {
  const { isOpen: isBudgetItemOpen, toggle: budgetItemToggle } = useModal();
  const Program = useContext(ProgramContext);
  const ActiveProgram = useContext(ProgramContext);
  const SelectedGroup = useContext(DisplayContext);
  const Group = useContext(GroupContext);
  const [getGroupSummary, { data: groupSummary, loading: groupLoading }] =
    useLazyQuery(GET_SPEND_BUDGET_SUMMARY, { fetchPolicy: "no-cache" });
  const [getProgramSummary, { data: programSummary, loading: programLoading }] =
    useLazyQuery(GETorganization_BUDGET_SUMMARY, { fetchPolicy: "no-cache" });
  const [getInvites, { data: invitesData, loading: invitesLoading }] =
    useLazyQuery(GET_INVITES_FILTERED);
  const [getOrgCards, { data: orgCardData, loading: orgCardLoading }] =
    useLazyQuery(GET_ORGANIZATION_DEBIT_CARDS, { fetchPolicy: "network-only" });
  const [getGroupCards, { data: groupCardData, loading: groupCardLoading }] =
    useLazyQuery(GET_GROUP_DEBIT_CARDS, { fetchPolicy: "network-only" });
  const [incomeBudgetData, setIncomeBudgetData] = useState<
    BudgetSummaryRecord[] | []
  >([]);
  const [expenseBudgetData, setExpenseBudgetData] = useState<
    BudgetSummaryRecord[] | []
  >([]);
  const [incomeBudgetTotal, setIncomeBudgetTotal] = useState<BudgetTotalValue>({
    budgetTotal: 0,
    reconciledTotal: 0,
    percent: 0,
  });
  const [expenseBudgetTotal, setExpenseBudgetTotal] =
    useState<BudgetTotalValue>({
      budgetTotal: 0,
      reconciledTotal: 0,
      percent: 0,
    });
  const [invites, setInvites] = useState<SpendInvite[]>([]);
  const [debitCards, setDebitCards] = useState<SpendDebitCard[]>([]);
  const [sharedAccount, setSharedAccount] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [accountType, setAccountType] = useState<"program" | "group">(
    "program"
  );
  const [groupId, setGroupId] = useState("");
  const [linkedAccount, setLinkedAccount] = useState<
    SpendBankAccount | undefined
  >(undefined);
  const [accountStatus, setAccountStatus] = useState("");
  const [selectedBudgetItem, setSelectedBudgetItem] =
    useState<budgetItemDetails>({
      budgetId: "",
      category: "",
      type: "",
      budgetItemName: "",
      dueDate: "",
      budgetAmount: 0,
      reconciledAmount: 0,
    });

  const [
    getProgramAccount,
    { loading: programAccountLoading, data: programAccountData },
  ] = useLazyQuery(GET_ORG_BANK_ACCOUNTS, {
    fetchPolicy: "network-only",
  });

  const [
    getGroupAccount,
    { loading: loadingGroupAccount, data: groupAccountData },
  ] = useLazyQuery(GET_GROUP_BANK_ACCOUNTS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (groupAccountData && groupAccountData.spendGroupBankAccounts) {
      setAccountStatus(groupAccountData.spendGroupBankAccounts.status);
      setLinkedAccount(
        groupAccountData.spendGroupBankAccounts.externalAccounts.at(0)
      );
    }
  }, [loadingGroupAccount, groupAccountData]);

  useEffect(() => {
    if (
      programAccountData &&
      programAccountData.spendOrganizationBankAccounts
    ) {
      setAccountStatus(programAccountData.spendOrganizationBankAccounts.status);
      setLinkedAccount(
        programAccountData.spendOrganizationBankAccounts.externalAccounts.at(0)
      );
    }
  }, [programAccountLoading, programAccountData]);

  useEffect(() => {
    if (
      SelectedGroup &&
      SelectedGroup?.selectedDropdownOption?.section === "Program"
    ) {
      getProgramAccount();
      setAccountType("program");
      setSharedAccount(false);
      getProgramSummary();
      getOrgCards();
      setAccountId(ActiveProgram?.getProgramAccount()?.id ?? "");
    } else if (
      SelectedGroup &&
      SelectedGroup?.selectedDropdownOption?.section === "Group"
    ) {
      getGroupAccount({
        variables: { groupId: groupId },
      });
      setGroupId(SelectedGroup.selectedDropdownOption.value.split("|")[0]);
      setAccountType("group");
      setSharedAccount(Group?.activeGroup?.sharedAccount || false);
      getGroupSummary({
        variables: {
          groupId: SelectedGroup?.selectedDropdownOption?.value.split("|")[0],
          seasonId: SelectedGroup?.selectedDropdownOption?.value.split("|")[1],
        },
      });
      getGroupCards({
        variables: {
          spendGroupDebitCardsId:
            SelectedGroup?.selectedDropdownOption?.value.split("|")[0],
        },
      });
      setAccountId(
        Program?.accounts?.find(
          (acc) => acc.name === SelectedGroup?.selectedDropdownOption?.name
        )?.id ?? ""
      );
    }
    getInvites();
  }, [
    Program?.accounts,
    Group?.activeGroup?.sharedAccount,
    SelectedGroup,
    SelectedGroup?.selectedDropdownOption,
    SelectedGroup?.selectedDropdownOption?.section,
    SelectedGroup?.selectedDropdownOption?.value,
    ActiveProgram,
    groupId,
    getProgramSummary,
    getGroupSummary,
    getInvites,
    getOrgCards,
    getGroupCards,
    getGroupAccount,
    getProgramAccount,
  ]);

  useEffect(() => {
    if (programSummary && programSummary.spendBudgetsProgramSummary) {
      BudgetSummaryConvertDataToUiModels(
        programSummary.spendBudgetsProgramSummary,
        setIncomeBudgetData,
        setExpenseBudgetData,
        setIncomeBudgetTotal,
        setExpenseBudgetTotal
      );
    }

    if (orgCardData && orgCardData.spendOrganizationDebitCards) {
      filterDebitCards(
        orgCardData.spendOrganizationDebitCards.cards,
        setDebitCards
      );
    } else {
      setDebitCards([]);
    }
  }, [programSummary, programLoading, orgCardLoading, orgCardData]);

  useEffect(() => {
    if (groupSummary && groupSummary.spendBudgetsSummary) {
      BudgetSummaryConvertDataToUiModels(
        groupSummary.spendBudgetsSummary,
        setIncomeBudgetData,
        setExpenseBudgetData,
        setIncomeBudgetTotal,
        setExpenseBudgetTotal
      );
    }

    if (groupCardData && groupCardData.spendGroupDebitCards) {
      filterDebitCards(groupCardData.spendGroupDebitCards.cards, setDebitCards);
    } else {
      setDebitCards([]);
    }
  }, [groupSummary, groupLoading, groupCardData, groupCardLoading]);

  useEffect(() => {
    if (invitesData && invitesData.spendInvitesFiltered) {
      let tempInvites = invitesData.spendInvitesFiltered.invites.filter(
        (inv: SpendInvite) => inv.type === "group_staff"
      );
      setInvites(tempInvites);
    }
  }, [
    invitesData,
    invitesLoading,
    SelectedGroup?.selectedDropdownOption?.value,
    SelectedGroup?.selectedDropdownOption?.section,
  ]);
  const prepPieData = (param: any[]) => {
    const colors = [
      "#F87171",
      "#FBBF24",
      "#34D399",
      "#60A5FA",
      "#CBD5E1",
      "#B91C1C",
      "#B45309",
      "#047857",
      "#1D4ED8",
      "#475569",
      "rgb(217,119,6)",
    ];

    let budgetedIncomeData = param.map((item, idx) => {
      return {
        category: item.name,
        value: item.budgetAmount / 100,
        color: colors[idx],
      };
    });
    let reconciledIncomeBudgetData = param.map((item, idx) => {
      return {
        category: item.name,
        value: item.budgetReconciled / 100,
        color: colors[idx],
      };
    });

    return { budgetedIncomeData, reconciledIncomeBudgetData };
  };

  const handleSetModal = (selectedItem: budgetItemDetails) => {
    setSelectedBudgetItem(selectedItem);
    budgetItemToggle();
  };

  return (
    <div className="wrapper">
      {!sharedAccount && (
        <AvailableBalanceCard
          debitCards={debitCards}
          accountId={accountId}
          accountType={accountType}
          groupId={groupId}
          linkedAccount={linkedAccount}
          accountStatus={accountStatus}
        />
      )}
      <div className="card">
        <TablePieChart
          budgetData={incomeBudgetData}
          budgetTotal={incomeBudgetTotal}
          summaryName="Income Summary"
          budgetedIncomeData={prepPieData(incomeBudgetData).budgetedIncomeData}
          reconciledIncomeBudgetData={
            prepPieData(incomeBudgetData).reconciledIncomeBudgetData
          }
          handleSetModal={handleSetModal}
          type={"Income"}
        />
        <TablePieChart
          budgetData={expenseBudgetData}
          budgetTotal={expenseBudgetTotal}
          summaryName="Expense Summary"
          budgetedIncomeData={prepPieData(expenseBudgetData).budgetedIncomeData}
          reconciledIncomeBudgetData={
            prepPieData(expenseBudgetData).reconciledIncomeBudgetData
          }
          handleSetModal={handleSetModal}
          type="Expense"
        />
      </div>
      <div className="lg:grid grid-cols-2">
        <TeamDebitCards
          programDebitCards={ActiveProgram?.organization?.debitCards}
        />
        <TeamAssistantInvites invites={invites} />
      </div>
      <TeamsOverview />
      {isBudgetItemOpen && (
        <BudgetItemDetails
          isBudgetItemOpen={isBudgetItemOpen}
          budgetItemToggle={budgetItemToggle}
          levelType={accountType}
          budgetItem={selectedBudgetItem}
        />
      )}
    </div>
  );
}

export default AdminDashboard;
