import { SpendGroup } from "graphql/generated";
import { FormatMoney } from "helpers/format-money";
import { SpinnerContainer } from "shared-components/spinner";
import { SnapIcon, SnapTable, SnapLink } from "suit";
import { AllBudgetSummary } from "types/budget-summary";

type TableProps = {
  groups: AllBudgetSummary[];
  className?: string;
  handleGroupClick: (selectedGroup: SpendGroup) => void;
  spendGroups: SpendGroup[];
  groupsLoaded?: boolean;
  budgetExporting?: boolean;
};

function Table({
  groups,
  className,
  handleGroupClick,
  spendGroups,
  groupsLoaded,
  budgetExporting,
}: TableProps) {
  return (
    <SnapTable>
      <table className={`celled ui ${className}`}>
        <thead>
          <tr>
            <th colSpan={1}></th>
            <th colSpan={2} align="center">
              Budgeted
            </th>
            <th colSpan={2} align="center">
              Reconciled
            </th>
            <th colSpan={2} align="center">
              Unreconciled
            </th>
          </tr>
          <tr>
            <th className="w-[20%]">Account Owner</th>
            <th align="center">Income</th>
            <th align="center">Expense</th>
            <th align="center">Income</th>
            <th align="center">Expense</th>
            <th align="center">Credits</th>
            <th align="right">Debits</th>
          </tr>
        </thead>
        <tbody>
          {groupsLoaded && !budgetExporting ? (
            groups.map((group, idx) => {
              return (
                <tr key={group.groupName && group.groupName + idx}>
                  <td className="py-4">
                    <SnapLink
                      className="text-sm text-blue-600 cursor-pointer"
                      sr-only="srOnly"
                      onClick={() => {
                        handleGroupClick(spendGroups[idx]);
                      }}
                    >
                      {group.groupName}
                    </SnapLink>
                    <p className="text-xs text-gray-500">{group.seasonName}</p>
                  </td>
                  <td>
                    <p className="flex justify-center">
                      {FormatMoney(group.budgetedIncomeTotal)}
                    </p>
                  </td>
                  <td>
                    <p className="flex justify-center">
                      {FormatMoney(group.budgetExpenseTotal)}
                    </p>
                  </td>
                  <td>
                    <p className="flex justify-center">
                      {FormatMoney(group.reconciledIncomeTotal)}
                    </p>
                  </td>
                  <td>
                    <p className="flex justify-center">
                      <SnapIcon
                        icon="exclamation-solid"
                        size="sm"
                        color="red"
                        hidden={
                          group.reconciledExpenseTotal <=
                          group.budgetExpenseTotal
                        }
                      />
                      {FormatMoney(group.reconciledExpenseTotal)}
                    </p>
                  </td>
                  <td>
                    <p className="flex justify-center">
                      {FormatMoney(group.unRecCredit)}
                    </p>
                  </td>
                  <td>
                    <p className="flex justify-end">
                      {FormatMoney(group.unRecDebit)}
                    </p>
                  </td>
                </tr>
              );
            })
          ) : (
            <SpinnerContainer
              loading={(!groupsLoaded || budgetExporting) ?? false}
              inTable
              colSpan={7}
            />
          )}
        </tbody>
      </table>
    </SnapTable>
  );
}

export default Table;
