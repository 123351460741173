import {
  PaymentsApiCustomerPaymentMethod,
  SpendBankAccount,
  SpendGroup,
  SpendInvoice,
  SpendSettings,
} from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { MapAndCalcSum } from "helpers/map-and-reduce";
import LabelValue from "pages/groups/collections/label-value";
import { useState } from "react";
import Divider from "shared-components/divider";
import DotProgress from "shared-components/dot-progress";
import { SnapCheckboxButton, SnapIcon, SnapTable } from "suit";
import { PaymentTimingValue } from "../../../types/invoice";
import DefaultPaymentMethod from "./default-payment-method";
import ParentInviteInfo from "../parent-invite-info";

type ConfirmPayNowPaymentsProps = {
  settings: SpendSettings;
  group: SpendGroup;
  invoices: SpendInvoice[];
  playerName: string;
  paymentTiming: PaymentTimingValue | undefined;
  selectedPaymentTypeOption: "Pay by: Card" | "Pay by: Bank" | undefined;
  total: number;
  agreed: boolean;
  setAgreed: React.Dispatch<React.SetStateAction<boolean>>;
  invoicesToPay: SpendInvoice[];
  bankAccount?: SpendBankAccount;
  paymentMethods: PaymentsApiCustomerPaymentMethod[];
  isAuthorized: boolean;
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
  dotState: number;
  dotMarker: number;
};

function ConfirmPayNowPayments({
  settings,
  group,
  invoicesToPay,
  playerName,
  total,
  selectedPaymentTypeOption,
  bankAccount,
  agreed,
  setAgreed,
  paymentMethods,
  isAuthorized,
  setIsAuthorized,
  dotState,
  dotMarker,
}: ConfirmPayNowPaymentsProps) {
  const [discountTotal] = useState(
    MapAndCalcSum(invoicesToPay, "discountAmount")
  );
  return (
    <div>
      <DotProgress state={dotState} markerCount={dotMarker} />
      <h2 className="lg:text-2xl font-semibold">Authorize Payments</h2>
      <ParentInviteInfo group={group} playerName={playerName} />
      <Divider isVisibleOnMobile className={"mt-6"} />
      <p className="mt-4 font-medium lg:text-lg lg:font-semibold">Invoices</p>
      <div className="lg:hidden">
        {invoicesToPay.map((invoice, idx) => {
          return (
            <div
              key={invoice.id + `${idx}`}
              className="border border-gray-200 p-4 rounded-lg mt-4 break-all"
            >
              <p>{invoice.description}</p>
              <Divider isVisibleOnMobile />
              <LabelValue
                label={"Amount"}
                value={FormatMoney(
                  (invoice.balanceDue || 0) + (invoice.discountAmount || 0)
                )}
                labelColor={"text-gray-500"}
                valueColor={""}
                className="mt-4"
              />
            </div>
          );
        })}

        {discountTotal > 0 && (
          <LabelValue
            label={"Discount"}
            value={`-${FormatMoney(discountTotal)}`}
            labelColor={"text-gray-500"}
            valueColor={""}
            className="mt-10 mx-4"
          />
        )}
        <LabelValue
          label={"Total"}
          value={FormatMoney(total)}
          labelColor={"text-gray-500"}
          valueColor={""}
          className="mt-5 mx-4"
        />
      </div>
      <SnapTable>
        <table className="ui celled hidden lg:table">
          <thead>
            <tr>
              <th className="w-[25%]">Description</th>
              <th align="right">Amount</th>
            </tr>
          </thead>
          <tbody>
            {invoicesToPay.map((invoice, idx) => {
              return (
                <tr key={invoice.id + `${idx}`} className="break-all">
                  <td className="py-4">
                    <p>{invoice.description}</p>
                  </td>
                  <td align="right">
                    {FormatMoney(
                      (invoice.balanceDue || 0) + (invoice.discountAmount || 0)
                    )}
                  </td>
                </tr>
              );
            })}
            <tr className="bg-gray-100">
              <td></td>
              <td className="flex flex-col justify-center items-end pr-4 h-18 pb-5 gap-4">
                {discountTotal > 0 && (
                  <p className="text-gray-500 font-medium">
                    DISCOUNT
                    <span className="ml-10 text-gray-800">
                      -{FormatMoney(discountTotal)}
                    </span>
                  </p>
                )}
                <p className="text-gray-500 font-medium">
                  TOTAL
                  <span className="ml-10 text-gray-800">
                    {FormatMoney(total)}
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </SnapTable>
      <Divider isVisibleOnMobile className="mt-6" />
      <DefaultPaymentMethod
        paymentMethods={paymentMethods}
        bankAccount={
          selectedPaymentTypeOption === "Pay by: Bank" ? bankAccount : undefined
        }
      />
      <div className="flex mt-4">
        <div className=" w-52">
          <p className="text-gray-500">Payment Timing</p>
        </div>
        <div>
          <p className="text-end">
            {FormatDate(new Date(), DateFormatSupported.Words)}
          </p>
        </div>
      </div>
      <Divider isVisibleOnMobile />
      <SnapCheckboxButton
        label="I authorize Snap! Spend to charge my payment method according to the above schedule."
        className="mt-4"
        checked={isAuthorized}
        onClick={() => setIsAuthorized(!isAuthorized)}
      />
      <Divider isVisibleOnMobile />
      {group.isRequireAgreement && (
        <>
          <p className="mt-4">Agreement with {group.name}</p>
          <div className="flex justify-between border border-gray-200 rounded-lg pt-2 pb-10 px-3 bg-gray-100 mt-1">
            <p>{settings.signUpAgreement?.content}</p>
            <SnapIcon
              icon="lock-closed-solid"
              size="sm"
              color="#94A3B8"
              className="items-start"
            />
          </div>
          <SnapCheckboxButton
            label={`I agree to the ${settings.signUpAgreement?.name} above.`}
            className="mt-4"
            checked={agreed}
            onClick={() => setAgreed(!agreed)}
          />
          <Divider isVisibleOnMobile />
        </>
      )}
      <h3 className="font-medium mt-4 md:mt-6">Summary</h3>
      <ul className="list-disc ml-6 mt-4 text-gray-500">
        <li>
          Click the button below to authorize a single transaction of{" "}
          {FormatMoney(total)} for the items listed above.
        </li>
      </ul>
    </div>
  );
}

export default ConfirmPayNowPayments;
