export const FormatMoney = (pennies: number | null | undefined) => {
  const amount = Number(((pennies || 0) / 100).toFixed(2));
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};
export const ParseMoney = (dollarAmount: string | null | undefined) => {
  const amount = dollarAmount?.replaceAll("$", "") || "0";
  const pennies = Math.round(Number(amount) * 100);
  return pennies;
};
