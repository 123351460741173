import { Dispatch, SetStateAction, useEffect, useState } from "react";
import InputMask from "shared-components/input-mask";
import { SnapInput, SnapSelectMenu } from "suit";
import { creditMemoStepOne } from "types/errors";
import { StepOneValues } from ".";
import DatePicker from "shared-components/date-picker";
import { getDatePickerValue, setDatePickerValue } from "helpers/date-picker";

type StepOneProps = {
  stepOneValues: StepOneValues;
  setStepOneValues: Dispatch<SetStateAction<StepOneValues>>;
  stepOneErrors: creditMemoStepOne;
  setStepOneErrors: React.Dispatch<React.SetStateAction<creditMemoStepOne>>;
};

function StepOne({
  stepOneValues,
  setStepOneValues,
  stepOneErrors,
  setStepOneErrors,
}: StepOneProps) {
  const [amount, setAmount] = useState("");
  useEffect(() => {
    if (amount && amount !== "") {
      setStepOneValues({
        ...stepOneValues,
        creditAmount: amount,
        titleCredit: amount,
      });
    }
    // eslint-disable-next-line
  }, [amount]);

  return (
    <div>
      <div className="flex">
        <p className="text-sm text-gray-500 font-medium mr-4 self-center">
          Participant
        </p>
        <p>{stepOneValues.participantName}</p>
      </div>
      <div className="lg:grid grid-cols-2 gap-4">
        <SnapSelectMenu
          label="Group"
          className="mt-4"
          placeholder="Select a group"
          selectMenuOptions={stepOneValues.groups}
          error={stepOneErrors.groupError}
          onSnap-select-menu-updated={(e) => {
            setStepOneValues({ ...stepOneValues, groups: e.detail });
            setStepOneErrors({ ...stepOneErrors, groupError: false });
          }}
        />
        <SnapSelectMenu
          label="Season"
          className="mt-4"
          placeholder="Select a season"
          selectMenuOptions={stepOneValues.seasons}
          error={stepOneErrors.seasonError}
          onSnap-select-menu-updated={(e) => {
            setStepOneValues({ ...stepOneValues, seasons: e.detail });
            setStepOneErrors({ ...stepOneErrors, seasonError: false });
          }}
        />
        <div className="mt-4">
          <InputMask
            label="Credit Amount"
            amount={amount}
            setAmount={setAmount}
            hasError={stepOneErrors.creditAmountError}
            onChange={() => {
              setStepOneErrors({
                ...stepOneErrors,
                creditAmountError: false,
              });
            }}
          />
        </div>
        <div className="mt-4">
          <DatePicker
            label={"Date"}
            date={getDatePickerValue(stepOneValues.date)}
            setDate={(e) => {
              const newDate = setDatePickerValue(e);
              setStepOneValues({
                ...stepOneValues,
                date: newDate,
              });
              setStepOneErrors({ ...stepOneErrors, dateError: false });
            }}
            hasError={stepOneErrors.dateError}
          />
        </div>
      </div>
      <div className="mt-4">
        <SnapInput
          basic
          _id={""}
          textarea
          label="Note"
          helpText="This note will be visible to the participant"
          cornerHint="Optional"
          onSnap-input-change={(e) => {
            setStepOneValues({ ...stepOneValues, note: e.detail.target.value });
          }}
          onBlur={(e) => {
            setStepOneValues({ ...stepOneValues, note: e.target.value });
          }}
        />
      </div>
    </div>
  );
}

export default StepOne;
