import { Maybe } from "graphql/jsutils/Maybe";
import { getBadgeColor } from "helpers/status-color";
import { SnapBadge } from "suit";

export enum HorizontalValueStyle {
  Text, //0
  Link, //1
  Badge, //2
  TextBadge, //3
}

function truncateText(str: string, length = 250) {
  return str.length > length ? str.substring(0, length) + "..." : str;
}

type HorizontalLabelValueProps = {
  label: string;
  labelAction?: () => void;
  value: Maybe<string> | Maybe<string>[] | undefined;
  secondValue?: Maybe<string> | Maybe<string>[] | undefined;
  valueStyle?: HorizontalValueStyle;
  customContainerStyle?: string;
  className?: string;
  isArchived?: boolean;
};

function HorizontalLabelValue({
  label,
  labelAction,
  value,
  secondValue,
  valueStyle = HorizontalValueStyle.Text,
  customContainerStyle,
  className,
  isArchived,
}: HorizontalLabelValueProps) {
  let textValue = "";
  let secondTextValue = "";
  let badgeValue = "";

  if (value) {
    if (typeof value === "string") {
      textValue = truncateText(value);
      if (typeof secondValue === "string") {
        secondTextValue = truncateText(secondValue);
      }
    } else {
      textValue = truncateText(value[0]!);
      badgeValue = truncateText(value[1]!);
    }
  }

  const baseTextClass = `text-sm ml-2 text-overflow-clip whitespace-pre-wrap`;

  return (
    <div className={`flex justify-start ${customContainerStyle}`}>
      {label !== "" && (
        <div className="text-gray-500 font-semibold text-sm">{label}</div>
      )}
      {
        {
          0: (
            <div
              className={`${baseTextClass} text-gray-800 ${className}`}
              onClick={labelAction}
            >
              {textValue}
            </div>
          ),
          1: (
            <div
              className={`${baseTextClass} text-blue-600 ${className}`}
              onClick={labelAction}
            >
              {textValue}
            </div>
          ), //TODO Needs to be updated to SnapLink
          2: (
            <div className={`my-auto flex ${className || ""}`}>
              <SnapBadge
                className={`${baseTextClass} capitalize`}
                title="Test"
                color={isArchived ? "gray" : getBadgeColor(textValue)}
                size="sm"
              >
                {textValue}
              </SnapBadge>
              {secondValue && (
                <SnapBadge
                  className={`${baseTextClass} capitalize`}
                  title="Test"
                  color={getBadgeColor(secondTextValue ?? "")}
                  size="sm"
                >
                  {secondTextValue}
                </SnapBadge>
              )}
            </div>
          ),
          3: (
            <div className="flex flex-col lg:flex-row">
              <div className={`${baseTextClass} text-gray-800 ${className}`}>
                {textValue}
              </div>
              <SnapBadge
                className="text-sm capitalize whitespace-nowrap lg:mt-1 lg:ml-2"
                title="Test"
                size="sm"
                color={getBadgeColor(badgeValue)}
              >
                {badgeValue}
              </SnapBadge>
            </div>
          ),
        }[valueStyle.valueOf()]
      }
    </div>
  );
}

export default HorizontalLabelValue;
