export const redirectToSso = () => {
  const redirectProp =
    window.location.pathname.startsWith(`/invite/`) ||
    window.location.pathname.startsWith(`/signup/`)
      ? `?redirectTo=${window.location.href}`
      : `?skipAssign=true&redirectTo=${window.location.href}`;
  window.location.replace(
    `${process.env.REACT_APP_SSO_HOST}/login${redirectProp}` || ""
  );
};
