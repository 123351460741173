import { InternalRefetchQueriesInclude, useMutation } from "@apollo/client";
import GroupContext from "context/group-context";
import SeasonContext from "context/season-context";
import ToastContext from "context/toast-context";
import { DELETE_BUDGET_ITEM } from "graphql/mutations/budget";
import {
  GETorganization_BUDGET_SUMMARY,
  GET_SPEND_BUDGET_SUMMARY,
} from "graphql/queries/budgets";
import { GET_GROUPS } from "graphql/queries/group";
import { useContext, useEffect, useState } from "react";
import FixedModal from "shared-components/fixed-modal/fixed-modal";
import { BtnState, BtnType } from "shared-components/modal";
import { SnapIcon } from "suit";
import { selectedBudgetItem } from "types/budgets";

type DeleteBudgetProps = {
  isOpen: boolean;
  toggle: () => void;
  dataToDelete: selectedBudgetItem;
};

function DeleteBudget({ isOpen, toggle, dataToDelete }: DeleteBudgetProps) {
  const Group = useContext(GroupContext);
  const Season = useContext(SeasonContext);
  const Toast = useContext(ToastContext);

  const [isBtnActive, setIsBtnActive] = useState(true);

  const refetchContent: InternalRefetchQueriesInclude = [
    !Group?.activeGroup
      ? { query: GETorganization_BUDGET_SUMMARY, fetchPolicy: "network-only" }
      : {
          query: GET_SPEND_BUDGET_SUMMARY,
          variables: {
            groupId: Group.activeGroup.id,
            seasonId: Season?.selectedSeason?.id,
          },
          fetchPolicy: "network-only",
        },
    {
      query: GET_GROUPS,
      fetchPolicy: "network-only",
    },
  ];
  const [deleteBudgetItem, { loading: deleteLoading, data: deleteData }] =
    useMutation(DELETE_BUDGET_ITEM, {
      refetchQueries: refetchContent,
    });

  useEffect(() => {
    if (!deleteLoading && deleteData) {
      Toast?.setToast({
        message: "Budget Item Deleted Successfully",
        type: "success",
      });
      toggle();
      setIsBtnActive(true);
    }
    // eslint-disable-next-line
  }, [deleteLoading, deleteData]);

  let btn1: BtnType = {
    text: "Delete",
    btnStyle: "danger",
    onClick: () => {
      setIsBtnActive(false);
      deleteBudgetItem({
        variables: {
          spendBudgetDeleteId: dataToDelete.budgetId,
        },
        refetchQueries: refetchContent,
      });
    },
    btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
  };
  let btn2: BtnType = { text: "Cancel", btnStyle: "tertiary", onClick: toggle };
  return (
    <FixedModal
      isOpen={isOpen}
      toggle={toggle}
      title={""}
      btn1={btn1}
      btn2={btn2}
    >
      <div className="flex flex-col justify-center gap-2 mx-auto text-center w-full modal-card pt-0">
        <SnapIcon
          className="mb-2"
          icon="exclamation-solid"
          state="rounded"
          size="xl"
          color="#EF4444"
          bgColor="#FEE2E2"
        />
        <h3 className="font-semibold">Delete Budget Item</h3>
        <p className="text-sm text-center font-medium text-gray-500">
          Are you sure you want to delete{" "}
          {dataToDelete.budgetItem ?? "this item"}?
        </p>
      </div>
    </FixedModal>
  );
}

export default DeleteBudget;
