type ColorBarProps = { percent: number; colorHex: string; className?: string };

function ColorBar({ percent, colorHex = "#FF0000", className }: ColorBarProps) {
  if (percent > 0 && percent < 2) percent = 2;
  if (percent < 100 && percent > 98) percent = 98;
  if (percent > 100) percent = 100;
  const roundedStyle = percent === 100 ? "rounded" : "rounded-l";
  return (
    <div className={`h-5 w-full rounded bg-gray-100 shadow-inner ${className}`}>
      <div
        className={`h-full ${roundedStyle} shadow-inner`}
        style={{ backgroundColor: colorHex, width: `${percent}%` }}
      ></div>
    </div>
  );
}

export default ColorBar;
