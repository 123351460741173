import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import { Filters } from "types/filter-types";

export const updateFilterSelection = (
  filters: Filters[],
  selectedFilterName: string,
  details: SnapSelectMenuOption[],
  date?: string
) => {
  const selectedFilter = filters.find(
    (filter) => filter.filterName === selectedFilterName
  );

  if (date) {
    let updatedDateObj = handleDate(filters, selectedFilterName, date);
    const startingIdx = filters.indexOf(selectedFilter || {});
    filters.splice(startingIdx, 1, { ...updatedDateObj });
  } else {
    let newFilterObj: Filters = {
      filterName: selectedFilter?.filterName,
      type: selectedFilter?.type,
      filterOptions: [...details],
      placeholder: selectedFilter?.placeholder,
      className: selectedFilter?.className,
    };
    filters.splice(filters.indexOf(selectedFilter || {}), 1, {
      ...newFilterObj,
    });
  }

  return filters;
};

const handleDate = (
  filters: Filters[],
  selectedFilterName: string,
  date?: string
) => {
  let selectedFilterObj = filters.find(
    (filter) => filter.filterName === selectedFilterName
  );
  let newDateObj: Filters = {
    filterName: selectedFilterObj?.filterName,
    type: selectedFilterObj?.type,
    placeholder: date,
    className: selectedFilterObj?.className,
  };
  return newDateObj;
};
