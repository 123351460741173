import UserContext from "context/user-context";
import { emptyStringCheck } from "helpers/empty-string-check";
import { getAuthTokenData } from "helpers/get-auth-token-data";
import { useContext } from "react";
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "unit-elements-card": {
        theme: string;
        "card-id": string;
        "customer-token"?: string;
        "hide-card-title"?: "true";
        "hide-actions-menu-button"?: "true";
        "hide-sensitive-data-button"?: "true";
      };
    }
    interface IntrinsicElements {
      "unit-elements-check-deposit": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
    interface IntrinsicElements {
      "unit-elements-account": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
    interface IntrinsicElements {
      "unit-elements-ach-credit-payment": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
    interface IntrinsicElements {
      "unit-elements-payee-management": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}
type UnitElementType = {
  type:
    | "account"
    | "card"
    | "check-deposit"
    | "ach-credit"
    | "ach-manage-payee";
  data: {
    cardId?: string;
    accountId?: string;
  };
  permRequired?: string[];
};

function UnitComponentGenerator({ type, data, permRequired }: UnitElementType) {
  const userContext = useContext(UserContext);
  const token = getAuthTokenData();

  const hideAction = () => {
    if (permRequired == null) {
      return undefined;
    }
    const hasPermission = permRequired.every(
      (p) => userContext?.checkSpendPermission(p) === true
    );
    return !hasPermission ? "true" : undefined;
  };
  const renderMessage = (message: string) => {
    return <div className="card text-center">{message}</div>;
  };
  const renderElement = () => {
    if (userContext?.isImpersonating()) {
      return renderMessage("Not visible during impersonation.");
    }
    if (emptyStringCheck(token)) {
      return renderMessage("Token invalid or expired.");
    }
    switch (type) {
      case "card":
        return (
          <div className="border rounded-lg border-gray-20 p-4 mt-4 mr-4">
            {emptyStringCheck(data.cardId) ? (
              renderMessage("Missing card Id")
            ) : (
              <unit-elements-card
                card-id={data.cardId!}
                customer-token={token}
                learn-more-url=""
                theme=""
                hide-actions-menu-button={hideAction()}
              />
            )}
          </div>
        );
      case "account":
        return (
          <>
            {emptyStringCheck(data.accountId) ? (
              renderMessage("Missing account Id")
            ) : (
              <unit-elements-account
                account-id={data.accountId}
                customer-token={token}
                hide-selection-menu-button="true"
              />
            )}
          </>
        );
      case "check-deposit":
        return (
          <>
            {emptyStringCheck(data.accountId) ? (
              renderMessage("Missing account Id")
            ) : (
              <unit-elements-check-deposit
                account-id={data.accountId}
                customer-token={token}
              />
            )}
          </>
        );
      case "ach-credit":
        return (
          <>
            {emptyStringCheck(data.accountId) ? (
              renderMessage("Missing account Id")
            ) : (
              <unit-elements-ach-credit-payment
                customer-token={token}
                account-id={data.accountId}
                is-auto-focus="true"
                with-plaid="false"
                initial-stage-back-button="true"
                final-stage-done-button="true"
              />
            )}
          </>
        );
      case "ach-manage-payee":
        return (
          <>
            <unit-elements-payee-management
              customer-token={token}
              account-id={data.accountId}
            />
          </>
        );

      default:
        return (
          <div className="card text-center">
            Unit component not yet supported.
          </div>
        );
    }
  };
  return renderElement() || null;
}

export default UnitComponentGenerator;
