import React, { MouseEvent, useEffect, useState } from 'react';
import {
  PaymentIntent,
  StripeExpressCheckoutElementClickEvent,
  StripeExpressCheckoutElementReadyEvent,
} from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { V2 as api } from '@snap-mobile/payments-widget-client';
import {
  GetOrCreateCustomerData,
  CreatePaymentIntentData,
  PaymentMethod,
  StripeEnvironment,
  StripePaymentMethod,
  StripePromise,
  User,
} from '@snap-mobile/payments-widget-utils';
import ExistingPaymentMethod from './ExistingPaymentMethod';
import ExpressCheckoutWidget from './ExpressCheckoutWidget';
import NewPaymentMethod from './NewPaymentMethod';
import {
  type ComboPaypalWidgetProps,
  type PaypalWidgetProps,
} from './PaypalPaymentWidget';
import Processing from './Processing';
import { PaymentsUser } from './helpers';
import {
  PaymentConfiguration,
  SubmitData,
  WidgetAppearance,
  makeStripeOptions,
} from './internal';

export interface OnSessionPaymentWidgetProps {
  appearance?: WidgetAppearance;
  description?: string;
  destination: string;
  disabledExpressOnClick?: (e: MouseEvent<HTMLDivElement>) => Promise<void>;
  express?: boolean;
  expressOnClick?: (e: StripeExpressCheckoutElementClickEvent) => any;
  expressOnReady?: (e: StripeExpressCheckoutElementReadyEvent) => any;
  externalPaymentId: string;
  finalDestination?: string;
  hideExisting?: boolean;
  idempotencyKey: string;
  invalid?: boolean;
  metadata?: object;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => Promise<void>;
  onError?: (message: string) => Promise<void>;
  onSuccess?: (data: PaymentIntent) => Promise<void>;
  paypal?: ComboPaypalWidgetProps;
  permittedPaymentMethods?: Array<StripePaymentMethod>;
  presetCustomerId?: string;
  presetPaymentMethod?: PaymentMethod;
  refresh?: boolean;
  returnUrl: string;
  cancelUrl: string;
  snapAmount: number;
  statementDescriptorSuffix?: string;
  stripeEnvironment?: StripeEnvironment;
  stripePromise: StripePromise;
  totalAmount: number;
  user?: PaymentsUser;
  walletsEnabled?: boolean;
}

export function OnSessionPaymentWidget({
  appearance,
  destination,
  disabledExpressOnClick,
  express,
  expressOnClick,
  expressOnReady,
  externalPaymentId,
  hideExisting,
  idempotencyKey,
  invalid,
  onClick,
  onError,
  onSuccess,
  metadata,
  paypal,
  permittedPaymentMethods,
  presetCustomerId,
  presetPaymentMethod,
  refresh,
  returnUrl,
  snapAmount,
  statementDescriptorSuffix,
  stripeEnvironment,
  stripePromise,
  totalAmount,
  user,
  finalDestination,
  description,
  walletsEnabled = true,
}: OnSessionPaymentWidgetProps) {
  const [customer, setCustomer] = useState<User | undefined>(undefined);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [useNewPm, setUseNewPm] = useState<boolean>(false);
  const [paymentConfiguration, setPaymentConfiguration] =
    useState<PaymentConfiguration>();
  const [hasExisting, setHasExisting] =
    useState<boolean>(!!presetPaymentMethod);
  const [showExisting, setShowExisting] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [attempsSetKeyCounts, setAttemptsKeyCounts] = useState(0);
  const [idempotencyKeyValue, setIdempotencyKeyValue] =
    useState<string>(idempotencyKey);

  const handleError = !!onError
    ? async (msg: string) => {
        await onError(msg);
      }
    : async (msg: string) => setError(msg);

  useEffect(() => {
    const gu = () => {
      if (!invalid) {
        let data: GetOrCreateCustomerData = {
          email: '',
          idempotencyKey: `${idempotencyKey}-customer`,
          metadata: {},
        };
        if (presetCustomerId) {
          data.existingCustomerId = presetCustomerId;
        } else if (user && user.email) {
          data.description = user.description;
          data.email = user.email;
          data.name = user.name;
        } else {
          return;
        }
        api
          .getOrCreateCustomer(data, { stripeEnvironment })
          .then((cus) => {
            // error from stripe
            if ((cus as any).message) {
              throw new Error((cus as any).message);
            }
            setCustomer(cus);
            setDisabled(false);
          })
          .catch((e) => handleError(e.message || 'Error creating customer.'));
      }
    };
    gu();
  }, [invalid, presetCustomerId, user]);

  useEffect(() => {
    const gpc = () => {
      api
        .getPaymentConfiguration(destination, { stripeEnvironment })
        .then((pc) => setPaymentConfiguration(pc))
        .catch((e) => handleError(e.message || 'Error getting configuration.'));
    };
    gpc();
  }, []);

  useEffect(() => {
    setDisabled(invalid || !user);
  }, [invalid, user]);

  useEffect(() => {
    setIdempotencyKeyValue(`${idempotencyKey}_${attempsSetKeyCounts}`);
  }, [attempsSetKeyCounts]);

  useEffect(() => {
    const he =
      !hideExisting &&
      (!!presetPaymentMethod || !!customer?.paymentMethods?.length);
    setHasExisting(he);
  }, [customer]);

  useEffect(() => {
    const se = hasExisting && !useNewPm;
    setShowExisting(se);
  }, [useNewPm, hasExisting]);

  const automatic =
    !permittedPaymentMethods?.length || !appearance?.paymentMethodOrder?.length;

  const submit = async ({
    elements,
    expressEvent,
    paymentMethodId,
    stripe,
  }: SubmitData) => {
    if (!stripe) {
      return;
    }
    if (invalid) {
      return;
    }
    const ee = expressEvent
      ? () => expressEvent.paymentFailed({ reason: 'fail' })
      : () => {};
    if (elements) {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        handleError(submitError.message ?? 'Error submitting elements.');
        ee();
        return;
      }
    }
    setDisabled(true);
    setProcessing(true);
    try {
      const data: CreatePaymentIntentData = {
        automaticPaymentMethods: { enabled: automatic },
        customer: customer!.customerId,
        description,
        destination: paymentConfiguration?.stripeAccountId ?? destination,
        externalPaymentId,
        finalDestination,
        idempotencyKey: idempotencyKeyValue,
        metadata: metadata ?? {},
        paymentMethod: paymentMethodId,
        permittedPaymentMethods,
        snapAmount,
        statementDescriptorSuffix,
        setupFutureUsage: 'on_session',
        totalAmount,
      };
      const pi = await api.createPaymentIntent(data, { stripeEnvironment });
      if (pi.message) {
        handleError(pi.message);
        setDisabled(false);
        setProcessing(false);
        return;
      }
      const redirect = refresh === false ? 'if_required' : undefined;
      const confirmData = {
        elements,
        clientSecret: pi.client_secret,
        confirmParams: { return_url: returnUrl ?? window.location.href },
        redirect: redirect as any,
      };
      const resp = await stripe.confirmPayment(confirmData);
      if (resp.error) {
        // we should attach a text to the idempotencyKey(prefix)\
        setAttemptsKeyCounts(attempsSetKeyCounts + 1);
        handleError(resp.error.message || 'Error confirming payment.');
        ee();
        setDisabled(false);
        setProcessing(false);
      } else if (onSuccess) {
        await onSuccess(resp.paymentIntent as any);
        setDisabled(false);
        setProcessing(false);
      }
    } catch (e: any) {
      handleError(e.message);
      ee();
      setDisabled(false);
      setProcessing(false);
    }
  };

  let paypalProps: PaypalWidgetProps | undefined;
  const externalProps: { externalPaymentMethodTypes?: Array<string> } = {};
  if (paypal) {
    paypalProps = {
      ...paypal,
      externalPaymentId,
      snapAmount,
      totalAmount,
      returnUrl: returnUrl ?? window.location.href,
      cancelUrl: returnUrl ?? window.location.href,
    };
    externalProps.externalPaymentMethodTypes = ['external_paypal'];
  }
  const { elementsOptions, paymentElementOptions, submitButtonContent } =
    makeStripeOptions(paymentConfiguration, appearance, walletsEnabled);
  const stripeOptions = Object.assign(
    {},
    elementsOptions,
    {
      amount: totalAmount,
      mode: 'payment' as any,
      setup_future_usage: 'on_session',
    },
    externalProps,
  );
  return (
    <div
      className="payments-widget"
      style={{ fontFamily: 'sans-serif !important' }}
    >
      {error && <div className="error">{error}</div>}
      <div>
        {processing && <Processing />}
        <Elements stripe={stripePromise} options={stripeOptions as any}>
          {!!express ? (
            <ExpressCheckoutWidget
              disabled={disabled}
              disabledOnClick={disabledExpressOnClick}
              onClick={expressOnClick}
              onReady={expressOnReady}
              submit={submit}
              paymentMethodOrder={paymentElementOptions.paymentMethodOrder}
              wallets={paymentElementOptions.wallets!}
            />
          ) : showExisting ? (
            <ExistingPaymentMethod
              disabled={disabled}
              onClick={onClick}
              presetPaymentMethod={presetPaymentMethod}
              setUseNewPm={setUseNewPm}
              submit={submit}
              submitButtonContent={submitButtonContent}
              useNewPm={useNewPm}
              user={customer}
            />
          ) : (
            <NewPaymentMethod
              disabled={disabled}
              elementOptions={paymentElementOptions}
              hasExistingMethods={hasExisting}
              onClick={onClick}
              paypal={paypalProps}
              setUseNewPm={setUseNewPm}
              submit={submit}
              submitButtonContent={submitButtonContent}
              useNewPm={useNewPm}
            />
          )}
        </Elements>
      </div>
    </div>
  );
}
