import {
  DropdownMenuItem,
  SnapSelectMenuOption,
} from "@snap-mobile/snap-ui/dist/types/utils";
import DisplayContext from "context/display-context";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { getBadgeColor } from "helpers/status-color";
import useModal from "hooks/use-modal";
import { useContext, useState } from "react";
import DataCard from "shared-components/data-card";
import Divider from "shared-components/divider";
import CustomModal from "shared-components/modal";
import { SearchInput } from "shared-components/search-input";
import ShowingResults from "shared-components/showing-results";
import {
  SnapBadge,
  SnapButton,
  SnapDropDown,
  SnapIcon,
  SnapInput,
  SnapSelectMenu,
} from "suit";
import { LabelValueObject } from "types/label-value-object";

function History() {
  const display = useContext(DisplayContext);
  const { isOpen: isMoreFiltersOpen, toggle: moreFiltersToggle } = useModal();
  const { isOpen: isDetailsOpen, toggle: detailsToggle } = useModal();

  // eslint-disable-next-line
  const [searchText, setSearchText] = useState("");
  const [selectedFilters, setSelectedFilters] = useState<string[]>([
    "Transfers",
    "Send Checks",
    "Debit Cards",
  ]);
  // eslint-disable-next-line
  const [groupOptions, setGroupOptions] = useState<SnapSelectMenuOption[]>([
    {
      name: "All",
      selected: true,
    },
  ]);
  // eslint-disable-next-line
  const [staffOptions, setStaffOptions] = useState<SnapSelectMenuOption[]>([]);
  // eslint-disable-next-line
  const [selectedSortOption, setSelectedSortOption] = useState(0);
  const [options] = useState<DropdownMenuItem[]>([
    {
      name: "Date Approved: New to Old",
      text: "Date Approved: New to Old",
      value: 0,
      selected: selectedSortOption === 0,
    },
    {
      name: "Date Approved: Old to New",
      text: "Date Approved: Old to New",
      value: 1,
      selected: selectedSortOption === 1,
    },
    {
      name: "Date Submitted: New to Old",
      text: "Date Submitted: New to Old",
      value: 2,
      selected: selectedSortOption === 2,
    },
    {
      name: "Date Submitted: Old to New",
      text: "Date Submitted: Old to New",
      value: 3,
      selected: selectedSortOption === 3,
    },
    {
      name: "Activity Type",
      text: "Activity Type",
      value: 4,
      selected: selectedSortOption === 4,
    },
    {
      name: "Group",
      text: "Group",
      value: 5,
      selected: selectedSortOption === 5,
    },
  ]);

  const activeStyle = "bg-blue-100";
  const sharedFilterStyle = `flex justify-center border border-gray-200 py-2 lg:px-6 text-sm rounded-lg mr-3 hover:${activeStyle}`;

  const handleFilterSelect = (selectedItem: string) => {
    const selected = selectedFilters.includes(selectedItem);
    let tempItems = [...selectedFilters];
    if (selected) {
      tempItems = tempItems.filter((i) => i !== selectedItem);
    } else {
      tempItems.push(selectedItem);
    }
    setSelectedFilters(tempItems);
  };

  const prepTitleExtra = () => {
    return (
      <div className="flex">
        <SnapBadge color={getBadgeColor("Approved")} className="ml-5">
          Approved
        </SnapBadge>
      </div>
    );
  };
  const prepLeftData = () => {
    const items: LabelValueObject[] = [];

    items.push({
      key: "Type",
      value: "External",
    });
    items.push({
      key: "Submitted By",
      value: "Sean Evans",
    });
    return items;
  };
  const prepRightData = () => {
    const items: LabelValueObject[] = [];

    items.push({
      key: "Amount",
      value: FormatMoney(0),
    });
    items.push({
      key: "Date Submitted",
      value: FormatDate("12/12/2023", DateFormatSupported.Numbers),
    });
    return items;
  };

  return (
    <div className="mt-4">
      <SearchInput setSearchValue={setSearchText} />
      <div className="lg:flex">
        <div className="grid grid-cols-3 cursor-pointer mt-4">
          <p
            onClick={() => handleFilterSelect("Transfers")}
            className={`${sharedFilterStyle} ${
              selectedFilters.includes("Transfers") && activeStyle
            }`}
          >
            Transfers
          </p>
          <p
            onClick={() => handleFilterSelect("Send Checks")}
            className={`${sharedFilterStyle} ${
              selectedFilters.includes("Send Checks") && activeStyle
            }`}
          >
            Send Checks
          </p>
          <p
            onClick={() => handleFilterSelect("Debit Cards")}
            className={`${sharedFilterStyle} ${
              selectedFilters.includes("Debit Cards") && activeStyle
            }`}
          >
            Debit Cards
          </p>
        </div>
        {display?.isMobile && <Divider isVisibleOnMobile />}
        <div
          className="flex items-center mt-4"
          onClick={() => {
            moreFiltersToggle();
          }}
        >
          <SnapIcon icon="filter-solid" size="sm" color="#3B82F6" />
          <p className="ml-1 text-blue-500 font-bold">
            {display?.isDesktop
              ? isMoreFiltersOpen
                ? "Less "
                : "More "
              : null}
            Filters
          </p>
          {display?.isDesktop && (
            <SnapIcon
              icon={isMoreFiltersOpen ? "chevron-up-line" : "chevron-down-line"}
              size="sm"
              color="#3B82F6"
            />
          )}
        </div>
        {display?.isDesktop && isMoreFiltersOpen && (
          <>
            <div className="grid grid-cols-4 gap-4 mt-6">
              <SnapInput _id="Date Input" _type="date" label="Start Date" />
              <SnapInput _id="Date Input" _type="date" label="End Date" />
              <SnapSelectMenu label="Groups" selectMenuOptions={groupOptions} />
              <SnapSelectMenu
                label="Staff"
                selectMenuOptions={staffOptions}
                placeholder="Select Staff"
              />
            </div>
            <div className="mt-4 flex justify-end gap-4">
              <SnapButton>Reset Filters</SnapButton>
              <SnapButton variant="primary">Apply Filters</SnapButton>
            </div>
          </>
        )}
      </div>

      <Divider />
      <h2 className="text-lg font-semibold mt-4">Completed Approvals</h2>
      <ShowingResults
        totalNumOfResults={97}
        startingNumOfResults={1}
        numOfResultsBeingDisplayed={10}
      />
      <Divider className="mt-0" isVisibleOnMobile />
      <div className="flex my-4">
        <p className="text-sm text-gray-500 mr-2">Sort</p>
        <SnapDropDown
          buttonText="Sort"
          leftHang
          options={options}
          modalType="drawer"
          onSnap-dropdown-item-selected={(e) => {}}
          className="flex items-center"
        />
      </div>
      {[1, 2, 3, 4].map((item) => {
        return (
          <DataCard
            title="Transfer"
            titleExtra={prepTitleExtra()}
            kvLeft={prepLeftData()}
            kvRight={prepRightData()}
            action={2}
            actionClick={() => {
              detailsToggle();
            }}
          />
        );
      })}
      <CustomModal
        isOpen={isMoreFiltersOpen}
        toggle={moreFiltersToggle}
        title="Filters"
        btn1={{
          text: "Apply Filters",
        }}
        btn2={{
          text: "Reset Filters",
        }}
      >
        <div className="modal-card flex flex-col">
          <SnapInput
            _id="Date Input"
            _type="date"
            label="Start Date"
            className="mt-4"
          />
          <SnapInput
            _id="Date Input"
            _type="date"
            label="End Date"
            className="mt-4"
          />
          <SnapSelectMenu
            label="Groups"
            selectMenuOptions={groupOptions}
            className="mt-4"
          />
          <SnapSelectMenu
            label="Staff"
            selectMenuOptions={staffOptions}
            placeholder="Select Staff"
            className="mt-4"
          />
        </div>
      </CustomModal>
      <CustomModal
        isOpen={isDetailsOpen}
        toggle={detailsToggle}
        title="Details"
        btn1={{
          text: "Close",
          btnStyle: "tertiary",
          onClick: detailsToggle,
        }}
      ></CustomModal>
    </div>
  );
}

export default History;
