import { useEffect } from "react";
import { SnapIcon } from "suit";
import { TOAST_TIMEOUT } from "../constants";
import { createPortal } from "react-dom";

type ToastMessageProps = {
  title?: string;
  message: string;
  isToastOpen: boolean;
  toggleToast: (toggle?: boolean) => void;
  type: "warning" | "danger" | "success" | "info";
  className?: string;
};

function ToastMessage({
  title,
  message,
  isToastOpen,
  toggleToast,
  type,
  className,
}: ToastMessageProps) {
  useEffect(() => {
    const timer = setTimeout(() => {
      toggleToast();
    }, TOAST_TIMEOUT);
    return () => {
      clearTimeout(timer);
    };
  }, [toggleToast]);

  const getStyle = (type: string) => {
    let style = "";
    let icon:
      | "exclamation-solid"
      | "x-circle-solid"
      | "check-circle-solid"
      | "information-circle-solid" = "exclamation-solid";
    let iconColor = "";
    let closeColor = "";
    switch (type) {
      case "warning":
        style = "bg-yellow-100 border-yellow-500";
        icon = "exclamation-solid";
        iconColor = "orange";
        closeColor = "text-orange-800";
        break;
      case "danger":
        style = "bg-red-100 border-red-500";
        icon = "x-circle-solid";
        iconColor = "red";
        closeColor = "text-red-800";
        break;
      case "success":
        style = "bg-green-100 border-green-500";
        icon = "check-circle-solid";
        iconColor = "green";
        closeColor = "text-green-800";
        break;
      case "info":
        style = "bg-blue-100 border-blue-500";
        icon = "information-circle-solid";
        iconColor = "blue";
        closeColor = "text-blue-800";
        break;
    }
    return { style, icon, iconColor, closeColor };
  };

  return isToastOpen
    ? createPortal(
        <>
          <div
            className={`fixed w-[90%] left-[5%] top-12 lg:top-14 lg:w-[50%] lg:left-[30%] py-2 px-4 rounded-lg border-l-4 z-[10000]  ${
              getStyle(type).style
            } ${className}`}
          >
            <div className="flex">
              <SnapIcon
                icon={getStyle(type).icon}
                color={getStyle(type).iconColor}
                size="sm"
                className="mr-4"
              />
              <div className="mr-auto">
                <p className="font-semibold mr-auto">{title}</p>
                {message !== "" && <p>{message}</p>}
              </div>
              <SnapIcon
                icon="x-solid"
                size="sm"
                color={getStyle(type).iconColor}
                onClick={() => toggleToast()}
              />
            </div>
          </div>
        </>,
        document.body
      )
    : null;
}

export default ToastMessage;
