import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import { Maybe, SpendSeason } from "graphql/generated";
import { useEffect, useState } from "react";

export default function useSeason() {
  const [selectedSeason, setSelectedSeason] = useState<
    Maybe<SpendSeason> | undefined
  >(undefined);
  const [seasons, setSeasons] = useState<
    Maybe<Maybe<SpendSeason>[]> | undefined
  >(undefined);
  const [seasonOptions, setSeasonOptions] = useState<DropdownMenuItem[]>([]);

  useEffect(() => {
    let fetchedGroup = localStorage.getItem("ActiveGroup");
    if (fetchedGroup) {
      try {
        let group = JSON.parse(fetchedGroup);
        handleSelectedSeason({ seasonsToSet: group.seasons });
      } catch {
        localStorage.removeItem("ActiveSeason");
      }
    } else {
      handleSelectedSeason();
    }
    // eslint-disable-next-line
  }, []);

  const handleSelectedSeason = (data?: {
    seasonId?: string;
    seasonsToSet?: Maybe<Maybe<SpendSeason>[]>;
  }) => {
    const seasonId = data?.seasonId;
    const seasonsToSet = data?.seasonsToSet;
    let options: DropdownMenuItem[] = [];
    let seasonOptions = seasons;

    if (seasonsToSet) {
      seasonOptions = seasonsToSet;
    }

    seasonOptions?.forEach((season, idx) => {
      let option: DropdownMenuItem = {
        name: season?.name ?? "",
        text: season?.name ?? "",
        value: season?.id ?? "",
        selected:
          seasonId && seasonId !== "" ? season?.id === seasonId : idx === 0,
      };
      const foundOption = options.find((o) => o.name === option.name);

      if (foundOption) {
        option.name = `${option.name} (${season?.id?.substring(
          season.id.length - 4
        )})`;
        option.text = `${option.text} (${season?.id?.substring(
          season.id.length - 4
        )})`;
      }

      if (option.selected) {
        setAndStoreActiveSeason(season);
      }

      options.push(option);
    });
    setSeasonOptions([...options]);
    setSeasons(seasonOptions);
  };

  const setAndStoreActiveSeason = (season: Maybe<SpendSeason> | undefined) => {
    localStorage.setItem("ActiveSeason", JSON.stringify(season));
    setSelectedSeason(season);
  };
  const multipleSeasons = () => {
    if (!seasons) {
      return false;
    }
    return seasons.length > 1;
  };

  return {
    selectedSeason,
    multipleSeasons,
    seasonOptions,
    handleSelectedSeason,
  };
}
