import ProgramContext from "context/program-context";
import { useContext, useEffect, useState } from "react";
import { SnapIcon, SnapButton } from "suit";
import QRCodeModal from "./qr-code";
import useModal from "hooks/use-modal";

function OrgSignupLink() {
  const program = useContext(ProgramContext);
  const { isOpen: qrCodeOpen, toggle: qrCodeToggle } = useModal();

  const url = program?.settings?.signUpLink;
  const [splitUrl, setUrlSplit] = useState("");

  useEffect(() => {
    let urlArr = url && url.split("https://");
    if (urlArr && urlArr?.length > 1) {
      setUrlSplit(urlArr[1]);
    } else {
      setUrlSplit(url ?? "");
    }
  }, [url]);

  return (
    <>
      <div className="card">
        <div className="my-4">
          <div className="flex">
            <p className="text-lg font-semibold mr-auto">Sign Up Link</p>
            <SnapButton variant="primary" size="sm" onClick={qrCodeToggle}>
              QR Code
            </SnapButton>
          </div>
          <div className="grid grid-cols-11 lg:grid-cols-12 my-2">
            <div className="border-2 border-gray-300 bg-gray-100 p-2 rounded-l-lg text-sm text-gray-500 col-span-2 lg:col-span-1">
              https://
            </div>
            <div className="flex border-y-2 border-r-2 lg:border-r-0 rounded-r-lg lg:rounded-r-none border-gray-300 bg-gray-100 p-2 text-sm text-gray-500 col-span-9 lg:col-span-7">
              <div className="mr-2 text-ellipsis whitespace-nowrap overflow-hidden">
                {splitUrl}
              </div>
              <SnapIcon icon="lock-closed-solid" size="xs" />
            </div>
            <div className="hidden lg:flex justify-center flex-nowrap border-y-2 border-l-2 border-gray-300 bg-gray-100 p-2 text-sm text-blue-500 text-center col-span-2 cursor-pointer">
              <SnapIcon icon="document-duplicate-solid" size="xs" />
              <div
                className="ml-2 font-medium cursor-pointer"
                onClick={() => navigator.clipboard.writeText(`${url}`)}
              >
                Copylink
              </div>
            </div>
            <div className="hidden lg:flex justify-center border-2 border-gray-300 bg-gray-100 p-2 rounded-r-lg text-sm text-blue-500 col-span-2 cursor-pointer">
              <SnapIcon icon="link-line" size="xs" />
              <div
                className="ml-2 font-medium whitespace-nowrap cursor-pointer"
                onClick={() => window.open(`${url}`, "signUpLink")}
              >
                Open URL
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 lg:hidden">
            <div className="flex justify-center border-2 border-r-0 border-gray-300 bg-gray-100 p-2 rounded-l-lg text-sm text-blue-500 cursor-pointer">
              <SnapIcon icon="document-duplicate-solid" size="xs" />
              <div
                className="ml-2 font-medium whitespace-nowrap cursor-pointer"
                onClick={() => navigator.clipboard.writeText(`${url}`)}
              >
                Copylink
              </div>
            </div>
            <div className="flex justify-center border-2 border-gray-300 bg-gray-100 p-2 rounded-r-lg text-sm text-blue-500 cursor-pointer">
              <SnapIcon icon="link-line" size="xs" />
              <div
                className="ml-2 font-medium whitespace-nowrap cursor-pointer"
                onClick={() => window.open(`${url}`, "signUpLink")}
              >
                Open URL
              </div>
            </div>
          </div>
          <p className="text-gray-500">
            <span className="text-semibold text-gray-800">Note:</span> Sign up
            link must be enabled on a group-by-group basis from the Group
            Setting page.
          </p>
        </div>
      </div>
      {qrCodeOpen && url && (
        <QRCodeModal
          isQrCodeOpen={qrCodeOpen}
          qrCodeToggle={qrCodeToggle}
          link={url}
        />
      )}
    </>
  );
}

export default OrgSignupLink;
