import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import {
  Maybe,
  PastDueSortOptions,
  SpendPastDueInterval,
  SpendPastDueInvoice,
  SpendPastDueSort,
  SpendSortOrderEnum,
  useSpendPastDueInvoicesQuery,
} from "graphql/generated";
import useToast from "hooks/use-toast";
import { useEffect, useState } from "react";
import Divider from "shared-components/divider";
import ShowingResults from "shared-components/showing-results";
import Sort from "shared-components/sort";
import ToastMessage from "shared-components/toast-message";
import { ITEMS_PER_PAGE } from "../../constants";
import PastDueListItemsV2 from "./past-due-list-item-v2";
import { SnapPagination } from "suit";

function PastDue() {
  const [loading, setLoading] = useState(false);
  const [pastDueInvoices, setPastDueInvoices] = useState<
    Maybe<SpendPastDueInvoice>[]
  >([]);
  const [interval, setInterval] = useState<SpendPastDueInterval | undefined>(
    undefined
  );
  const [icon, setIcon] = useState<
    "ascending-solid" | "descending-solid" | "selector-solid"
  >("selector-solid");
  const [sort, setSort] = useState<SpendPastDueSort | undefined>(undefined);
  const [page, setPage] = useState<number>(0);
  const [selectedSort] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("all");

  const [totalInvoices, setTotalInvoices] = useState(0);

  const { isToastOpen, toggleToast, ...toast } = useToast(
    "Email Sent",
    "success"
  );

  const { loading: loadingPastDueInvoices, data: pastDueInvoicesData } =
    useSpendPastDueInvoicesQuery({
      variables: {
        interval: interval,
        pagination: {
          limit: ITEMS_PER_PAGE,
          offset: page * ITEMS_PER_PAGE,
        },
        sort,
      },
    });

  useEffect(() => {
    if (
      !loadingPastDueInvoices &&
      pastDueInvoicesData &&
      pastDueInvoicesData.spendPastDueInvoices?.invoices
    ) {
      setPastDueInvoices(pastDueInvoicesData.spendPastDueInvoices?.invoices!);
      setTotalInvoices(pastDueInvoicesData.spendPastDueInvoices.count);
      setLoading(false);
    }
  }, [loadingPastDueInvoices, pastDueInvoicesData]);

  const sortOptions: DropdownMenuItem[] = [
    { text: "Groups A - Z", name: "Groups A - Z", value: "Groups A - Z" },
    { text: "Groups Z - A", name: "Groups Z - A", value: "Groups Z - A" },
    {
      text: "Participant A - Z",
      name: "Participant A - Z",
      value: "Participant A - Z",
    },
    {
      text: "Participant Z - A",
      name: "Participant Z - A",
      value: "Participant Z - A",
    },
    {
      text: "Invite Status A - Z",
      name: "Invite Status A - Z",
      value: "Invite Status A - Z",
    },
    {
      text: "Invite Status Z - A",
      name: "Invite Status Z - A",
      value: "Invite Status Z - A",
    },
    {
      text: "Due Date New to Old",
      name: "Due Date New to Old",
      value: "Due Date New to Old",
    },
    {
      text: "Due Date Old to New",
      name: "Due Date Old to New",
      value: "Due Date Old to New",
    },
    {
      text: "Amount Lowest to Highest",
      name: "Amount Lowest to Highest",
      value: "Amount Lowest to Highest",
    },
    {
      text: "Amount Highest to Lowest",
      name: "Amount Highest to Lowest",
      value: "Amount Highest to Lowest",
    },
  ];
  const activeStyle = "bg-blue-100";
  const sharedFilterStyle = "border border-gray-200 py-2 px-6 text-sm";

  const handleSort = (selectedSortOption?: PastDueSortOptions) => {
    let order = SpendSortOrderEnum.Asc;

    if (selectedSortOption !== sort?.sortBy) {
      order = SpendSortOrderEnum.Asc;
    } else if (sort?.order === SpendSortOrderEnum.Asc) {
      order = SpendSortOrderEnum.Desc;
    }

    setSort({
      sortBy: selectedSortOption,
      order,
    });
    if (sort?.sortBy && sort?.sortBy === selectedSortOption) {
      setIcon(
        sort?.order === SpendSortOrderEnum.Asc
          ? "ascending-solid"
          : "descending-solid"
      );
    }
  };

  const handleMobileSort = (selectedItem: string) => {
    switch (selectedItem) {
      case "Groups A - Z":
        setSort({
          sortBy: PastDueSortOptions.Groups,
          order: SpendSortOrderEnum.Asc,
        });
        break;
      case "Groups Z - A":
        setSort({
          sortBy: PastDueSortOptions.Groups,
          order: SpendSortOrderEnum.Desc,
        });
        break;
      case "Participant A - Z":
        setSort({
          sortBy: PastDueSortOptions.ParticipantName,
          order: SpendSortOrderEnum.Asc,
        });
        break;
      case "Participant Z - A":
        setSort({
          sortBy: PastDueSortOptions.ParticipantName,
          order: SpendSortOrderEnum.Desc,
        });
        break;
      case "Invite Status A - Z":
        setSort({
          sortBy: PastDueSortOptions.ParentStatus,
          order: SpendSortOrderEnum.Asc,
        });
        break;
      case "Invite Status Z - A":
        setSort({
          sortBy: PastDueSortOptions.ParentStatus,
          order: SpendSortOrderEnum.Desc,
        });
        break;
      case "Due Date New to Old":
        setSort({
          sortBy: PastDueSortOptions.DueDate,
          order: SpendSortOrderEnum.Desc,
        });
        break;
      case "Due Date Old to New":
        setSort({
          sortBy: PastDueSortOptions.DueDate,
          order: SpendSortOrderEnum.Asc,
        });
        break;
      case "Amount Lowest to Highest":
        setSort({
          sortBy: PastDueSortOptions.Amount,
          order: SpendSortOrderEnum.Asc,
        });
        break;
      case "Amount Highest to Lowest":
        setSort({
          sortBy: PastDueSortOptions.Amount,
          order: SpendSortOrderEnum.Desc,
        });
        break;
    }
  };

  const handleInterval = (
    intervalType: SpendPastDueInterval | undefined,
    selectedItem: string
  ) => {
    setInterval(intervalType);
    setSelectedFilter(selectedItem);
  };

  return (
    <div className="wrapper">
      <div className="card pb-10">
        <div className="flex cursor-pointer">
          <p
            onClick={() => handleInterval(undefined, "all")}
            className={`${sharedFilterStyle} ${
              selectedFilter === "all" && activeStyle
            } rounded-l-xl`}
          >
            All
          </p>
          <p
            onClick={() =>
              handleInterval(SpendPastDueInterval.Days1_15, "1-15")
            }
            className={`${sharedFilterStyle} ${
              selectedFilter === "1-15" && activeStyle
            } border-l-0`}
          >
            1-15 Days
          </p>
          <p
            onClick={() =>
              handleInterval(SpendPastDueInterval.Days16_30, "15-30")
            }
            className={`${sharedFilterStyle} ${
              selectedFilter === "15-30" && activeStyle
            } border-x-0`}
          >
            16-30 Days
          </p>
          <p
            onClick={() =>
              handleInterval(SpendPastDueInterval.Days31plus, "31+")
            }
            className={`${sharedFilterStyle} ${
              selectedFilter === "31+" && activeStyle
            } rounded-r-xl`}
          >
            31+ Days
          </p>
        </div>
        <Divider isVisibleOnMobile />
        <ShowingResults
          totalNumOfResults={totalInvoices}
          numOfResultsBeingDisplayed={
            pastDueInvoices.length <= 10
              ? pastDueInvoices.length
              : ITEMS_PER_PAGE * page + 10 >= pastDueInvoices.length
              ? pastDueInvoices.length
              : ITEMS_PER_PAGE * page + 10
          }
          startingNumOfResults={
            pastDueInvoices.length === 0 ? 0 : ITEMS_PER_PAGE * page + 1
          }
        />
        <Divider isVisibleOnMobile className="lg:hidden mb-6" />
        <Sort
          options={sortOptions}
          handleSort={(e) => handleMobileSort(e.name)}
          selectedSortOption={selectedSort}
        />

        <PastDueListItemsV2
          listItems={pastDueInvoices}
          loading={loading}
          handleSort={handleSort}
          sort={sort}
          icon={icon}
        />

        <SnapPagination
          currentPage={page}
          itemCount={totalInvoices}
          pageSize={ITEMS_PER_PAGE}
          onSnap-pagination-page-changed={(e) => {
            setPage(e.detail);
          }}
        />
      </div>

      {isToastOpen && (
        <ToastMessage
          message={toast.message}
          isToastOpen={isToastOpen}
          toggleToast={toggleToast}
          type={toast.type}
        />
      )}
    </div>
  );
}

export default PastDue;
