import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Button } from "./button";
import { Calendar as CalendarIcon } from "lucide-react";
import { Calendar } from "./calendar";
import { DateFormatSupported, FormatDate } from "helpers/format-date";

type DatePickerProps = {
  label: string;
  date: Date | undefined;
  setDate: (value: Date | undefined) => void;
  className?: string;
  hasError?: boolean;
};

function DatePicker({
  className,
  label,
  date,
  setDate,
  hasError,
}: DatePickerProps) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className={`flex flex-col ${className}`}>
          <p className="font-medium text-sm text-gray-700 mb-1">{label}</p>
          <Button variant={hasError ? "error" : "default"}>
            {date ? (
              FormatDate(date, DateFormatSupported.Numbers)
            ) : (
              <span>MM/DD/YYYY</span>
            )}
            <CalendarIcon className="ml-auto h-4 w-4" />
          </Button>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={(e) => {
            setDate(e);
          }}
          setDate={setDate}
        />
      </PopoverContent>
    </Popover>
  );
}

export default DatePicker;
