import queryString from 'query-string';
import * as Stripe from '@stripe/stripe-js';

export interface PaymentsUser {
  description?: string;
  email: string;
  metadata?: object;
  name?: string;
}

export interface StripePaymentIntentReturnParams {
  paymentIntentId?: string;
  paymentIntentClientSecret?: string;
  redirectSucceeded: boolean;
}

export interface StripeSetupIntentReturnParams {
  setupIntentId?: string;
  setupIntentClientSecret?: string;
  redirectSucceeded: boolean;
}

export function getPaymentIntentReturnParams(): StripePaymentIntentReturnParams {
  const { payment_intent, payment_intent_client_secret, redirect_status } =
    queryString.parse(window.location.search);
  const status = (redirect_status ?? '') as string;
  const paymentIntentId = (payment_intent ?? undefined) as string | undefined;
  const paymentIntentClientSecret = (payment_intent_client_secret ??
    undefined) as string | undefined;
  return {
    redirectSucceeded: status === 'succeeded',
    paymentIntentId,
    paymentIntentClientSecret,
  };
}

export function getSetupIntentReturnParams(): StripeSetupIntentReturnParams {
  const { setup_intent, setup_intent_client_secret, redirect_status } =
    queryString.parse(window.location.search);
  const status = (redirect_status ?? '') as string;
  const setupIntentId = (setup_intent ?? undefined) as string | undefined;
  const setupIntentClientSecret = (setup_intent_client_secret ?? undefined) as
    | string
    | undefined;
  return {
    redirectSucceeded: status === 'succeeded',
    setupIntentId,
    setupIntentClientSecret,
  };
}

export function StripeClient(
  stripeKey: string,
): ReturnType<typeof Stripe.loadStripe> {
  return Stripe.loadStripe(stripeKey);
}

export function getSrId(sr: any) {
  return sr.id;
}
