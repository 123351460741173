import { SpendInvoice } from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import DataCard from "shared-components/data-card";
import { HorizontalValueStyle } from "shared-components/horizontal-label-value";
import { SpinnerContainer } from "shared-components/spinner";
import { VerticalValueStyle } from "shared-components/vertical-label-value";
import { LabelValueObject } from "types/label-value-object";

type CardsProps = {
  listItems: SpendInvoice[] | undefined;
  handleToggleModal: (item: SpendInvoice) => void;
  handleNavGroup: (groupId: string, season: string) => void;
  handleNavigateParticipant: (userId: string) => void;
  loadingInvoices?: boolean;
  exporting?: boolean;
};

function Cards({
  listItems,
  handleToggleModal,
  handleNavGroup,
  handleNavigateParticipant,
  loadingInvoices,
  exporting,
}: CardsProps) {
  const prepLeftData = (d: SpendInvoice) => {
    const leftLabels: LabelValueObject[] = [];
    const playerName = `${d.groupRoster?.roster?.name}`;

    leftLabels.push({
      key: "Participant",
      value: playerName,
      valueStyle: HorizontalValueStyle.Link,
      customContainerStyle: "flex-col",
      className: "ml-0",
      labelAction: () =>
        handleNavigateParticipant(d.groupRoster?.roster?.id ?? ""),
    });
    leftLabels.push({
      key: "",
      value: d.groupRoster?.roster?.email || "",
      className: "ml-0",
      customContainerStyle: "my-2",
    });
    leftLabels.push({
      key: "Description ",
      value: d.description || "",
      customContainerStyle: "flex-col",
      className: "ml-0",
      labelAction: () => handleToggleModal(d),
    });

    return leftLabels;
  };

  const prepRightData = (d: SpendInvoice) => {
    const rightLabels: LabelValueObject[] = [];
    let dueDate = new Date(d.dueDate ?? "").toLocaleDateString();
    let todaysDate = new Date().toLocaleDateString();
    let warning = todaysDate === dueDate;

    rightLabels.push({
      key: "Status",
      value: d.status?.replace("_", " ") ?? "",
      valueStyle: VerticalValueStyle.Badge,
    });
    rightLabels.push({
      key: "Due Date",
      value: FormatDate(d.dueDate!, DateFormatSupported.Numbers),
    });
    rightLabels.push({
      key: "Invoice Amount",
      value: FormatMoney(d.amount || 0),
    });
    rightLabels.push({
      key: "Balance Due",
      value: FormatMoney(d.balanceDue!),
      hasWarning: warning,
      customContainerStyle: "lg:border-r-0",
    });
    return rightLabels;
  };

  return (
    <div className="lg:hidden mb-2">
      {!loadingInvoices && !exporting ? (
        listItems &&
        listItems.map((item: SpendInvoice, index: number) => {
          return (
            <DataCard
              key={index}
              title={item.groupRoster?.group?.name || ""}
              subTitle={item.groupRoster?.season?.name || ""}
              titleAction={() =>
                handleNavGroup(
                  item.groupRoster?.group?.id ?? "",
                  item.groupRoster?.season?.id ?? ""
                )
              }
              kvLeft={prepLeftData(item)}
              kvRight={prepRightData(item)}
              action={0}
              actionClick={() => handleToggleModal(item)}
              className="p-4 pb-0 lg:pb-4"
            />
          );
        })
      ) : (
        <SpinnerContainer loading={(loadingInvoices || exporting) ?? false} />
      )}
    </div>
  );
}

export default Cards;
