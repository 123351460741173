import {
  DocumentNode,
  FetchPolicy,
  useMutation,
  useQuery,
} from "@apollo/client";
import { PROGRAM, GROUP } from "../../constants";
import GroupContext from "context/group-context";
import ToastContext from "context/toast-context";
import { Maybe } from "graphql/generated";
import {
  CREATE_GROUP_PLAID_ACCESS,
  CREATE_ORGANIZATION_PLAID_ACCESS,
  CREATE_USER_PLAID_ACCESS,
  DELETE_GROUP_PLAID_ACCESS,
  DELETE_ORGANIZATION_PLAID_ACCESS,
  DELETE_USER_PLAID_ACCESS,
} from "graphql/mutations/plaid";
import { GET_GROUP_BANK_ACCOUNTS } from "graphql/queries/group";
import { GET_ORG_BANK_ACCOUNTS } from "graphql/queries/organization";
import {
  CREATE_GROUP_PLAID_LINK,
  CREATE_ORGANIZATION_PLAID_LINK,
  CREATE_USER_PLAID_LINK,
} from "graphql/queries/plaid";
import { USER_BANK_ACCOUNTS } from "graphql/queries/user";
import { ToastType } from "hooks/use-toast";
import React, { useContext, useEffect, useState } from "react";
import { PlaidLinkOptions } from "react-plaid-link";
import Spinner from "shared-components/spinner";
import VerticalLabelValue, {
  VerticalValueStyle,
} from "shared-components/vertical-label-value";

type LinkBankProps = {
  openPlaid: () => void;
  setPlaidConfig: React.Dispatch<React.SetStateAction<PlaidLinkOptions>>;
  toast?: Omit<ToastType, "isToastOpen">;
  type: "user" | "program" | "group";
  labelText: string;
  hasLabel?: boolean;
  groupId?: Maybe<string> | undefined;
};

const getPlaidLinkURL = (userType: string) => {
  let query;
  switch (userType) {
    case PROGRAM:
      query = CREATE_ORGANIZATION_PLAID_LINK;
      break;
    case GROUP:
      query = CREATE_GROUP_PLAID_LINK;
      break;
    default:
      query = CREATE_USER_PLAID_LINK;
      break;
  }

  return query;
};

const getPlaidAccessTokenURL = (userType: string) => {
  let query;

  switch (userType) {
    case PROGRAM:
      query = CREATE_ORGANIZATION_PLAID_ACCESS;
      break;
    case GROUP:
      query = CREATE_GROUP_PLAID_ACCESS;
      break;
    default:
      query = CREATE_USER_PLAID_ACCESS;
      break;
  }

  return query;
};

const getAccessVariables = (
  userType: string,
  publicToken: string,
  status: string,
  groupId?: string
) => {
  let variables;

  switch (userType) {
    case PROGRAM:
      variables = {
        publicToken: publicToken,
        status,
      };
      break;
    case GROUP:
      variables = {
        publicToken: publicToken,
        spendGroupBankAccessTokenCreateId: groupId,
        status,
      };
      break;
    default:
      variables = {
        publicToken: publicToken,
        groupId,
        status,
      };
      break;
  }

  return variables;
};

export const getDeletePlaidAccessUrl = (userType: string) => {
  let query;

  switch (userType) {
    case PROGRAM:
      query = DELETE_ORGANIZATION_PLAID_ACCESS;
      break;
    case GROUP:
      query = DELETE_GROUP_PLAID_ACCESS;
      break;
    default:
      query = DELETE_USER_PLAID_ACCESS;
      break;
  }

  return query;
};

type refetchType = {
  query: DocumentNode;
  fetchPolicy?: FetchPolicy;
  variables?: any;
};

export const getRefetchQueries = (
  userType: string,
  groupId?: string
): refetchType[] => {
  let refetch: refetchType[] = [];

  switch (userType) {
    case PROGRAM:
      refetch = [
        {
          query: GET_ORG_BANK_ACCOUNTS,
          fetchPolicy: "network-only",
        },
        {
          query: CREATE_ORGANIZATION_PLAID_LINK,
        },
      ];
      break;
    case GROUP:
      refetch = [
        {
          query: GET_GROUP_BANK_ACCOUNTS,
          variables: {
            groupId,
          },
          fetchPolicy: "network-only",
        },
        {
          query: CREATE_GROUP_PLAID_LINK,
          variables: {
            spendGroupBankLinkTokenCreateId: groupId,
          },
        },
      ];
      break;
    default:
      refetch = [
        {
          query: USER_BANK_ACCOUNTS,
          fetchPolicy: "network-only",
        },
        {
          query: CREATE_USER_PLAID_LINK,
        },
      ];
      break;
  }

  return refetch;
};

function LinkBank({
  openPlaid,
  setPlaidConfig,
  toast,
  type,
  labelText,
  hasLabel = true,
  groupId,
}: LinkBankProps) {
  const Toast = useContext(ToastContext);
  const ActiveGroup = useContext(GroupContext);
  const [linkStatus, setLinkStatus] = useState("");

  const { data, loading } = useQuery(getPlaidLinkURL(type), {
    variables:
      type === GROUP
        ? {
            spendGroupBankLinkTokenCreateId: ActiveGroup?.activeGroup?.id,
          }
        : {},
  });

  const [deletePlaidAccessToken] = useMutation(getDeletePlaidAccessUrl(type), {
    variables: { spendGroupBankAccessTokenDeleteId: groupId },
    refetchQueries: getRefetchQueries(type, ActiveGroup?.activeGroup?.id ?? ""),
    fetchPolicy: "network-only",
  });

  const [
    createOrgPlaidAccess,
    { data: createData, loading: createLoading, error: createErrors },
  ] = useMutation(getPlaidAccessTokenURL(type));

  useEffect(() => {
    let message =
      linkStatus === "pending_manual_verification"
        ? "Verification needed return in one day"
        : "Bank Linked Successfully";
    if (!createLoading && createData?.spendUserBankAccessTokenCreate) {
      toastHelper(message, "success", toast);
    }
    if (!createLoading && createData?.spendGroupBankAccessTokenCreate) {
      toastHelper(message, "success", toast);
    }
    if (!createLoading && createData?.spendOrganizationBankAccessTokenCreate) {
      toastHelper(message, "success", toast);
    }
    if (!createLoading && createErrors?.message) {
      const message = createErrors?.message;
      if (createErrors?.message === "Already Exists") {
        toastHelper(
          "An account is already linked with this name",
          "danger",
          toast
        );
      } else {
        toastHelper(message, "danger", toast);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createData, createLoading]);
  useEffect(() => {
    let config: PlaidLinkOptions = {
      onSuccess: (public_token, metadata) => {
        let status = metadata.accounts.at(0)?.verification_status ?? "";
        setLinkStatus(status);
        createOrgPlaidAccess({
          variables: getAccessVariables(
            type,
            public_token,
            status,
            groupId ?? ActiveGroup?.activeGroup?.id ?? ""
          ),
          refetchQueries: getRefetchQueries(
            type,
            ActiveGroup?.activeGroup?.id ?? ""
          ),
          fetchPolicy: "network-only",
        });
      },
      onExit: (err, metadata) => {
        if (err?.error_code === "TOO_MANY_VERIFICATION_ATTEMPTS") {
          deletePlaidAccessToken();
        }

        if (err?.display_message) {
          toastHelper(err?.display_message ?? "", "danger", toast);
        }
      },
      onEvent: (eventName, metadata) => {},
      token: "",
    };
    if (data && data.spendOrganizationBankLinkTokenCreate) {
      config.token = data.spendOrganizationBankLinkTokenCreate.linkToken;
      setPlaidConfig(config);
    }
    if (data && data.spendGroupBankLinkTokenCreate) {
      config.token = data.spendGroupBankLinkTokenCreate.linkToken;
      setPlaidConfig(config);
    }

    if (data && data.spendUserBankLinkTokenCreate) {
      config.token = data.spendUserBankLinkTokenCreate.linkToken;
      setPlaidConfig(config);
    }
    setLinkStatus("");
    // eslint-disable-next-line
  }, [data, loading, setPlaidConfig, createOrgPlaidAccess, type]);

  const toastHelper = (
    message: string,
    type: "success" | "danger",
    toast?: Omit<ToastType, "isToastOpen">
  ) => {
    if (toast) {
      toast.setToastProps({
        message,
        type,
      });
      toast.toggleToast();
    } else {
      Toast?.setToastProps({
        message,
        type,
      });
      Toast?.toggleToast();
    }
  };

  return (
    <>
      {createLoading ? (
        <Spinner className="w-20" />
      ) : (
        <VerticalLabelValue
          label={hasLabel ? "Linked Bank" : ""}
          labelStyle="text-sm font-normal"
          value=""
          valueStyle={VerticalValueStyle.ActionLabel}
          actionLabel={labelText}
          labelAction={openPlaid}
          customContainerStyle={"border-none lg:text-start flex flex-col"}
          actionLabelClassname="flex-col"
        />
      )}
    </>
  );
}

export default LinkBank;
