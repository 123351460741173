import { FormatMoney, ParseMoney } from "helpers/format-money";
import { MapAndCalcSum } from "helpers/map-and-reduce";
import { useState, useEffect } from "react";
import { SnapInput, SnapTable } from "suit";
import { SpendBudget, SpendGroup, SpendSeason } from "graphql/generated";
import { ApplyingBankTransaction } from "types/team-banking";

type TableProps = {
  selectedDetails: BudgetReconciliationForm;
  setSelectedDetails: React.Dispatch<
    React.SetStateAction<BudgetReconciliationForm>
  >;
  transactionAmount: number;
  className?: string;
};
export type BudgetReconciliationForm = {
  team?: SpendGroup;
  season?: SpendSeason;
  transaction?: ApplyingBankTransaction;
  distribution?: TransactionAmountDistribution[];
};
export type TransactionAmountDistribution = {
  budget: SpendBudget;
  amount: number;
};
function Table({
  selectedDetails,
  setSelectedDetails,
  className,
  transactionAmount,
}: TableProps) {
  const [initialAmounts] = useState<TransactionAmountDistribution[]>([
    ...(selectedDetails.distribution ?? []),
  ]);
  const [balance, setBalance] = useState<number>(
    (transactionAmount ?? 0) -
      MapAndCalcSum(selectedDetails.distribution ?? [], "amount")
  );
  const getValue = (id: string | undefined | null) => {
    if (id == null) return 0;
    return initialAmounts.find((amt) => amt.budget.id === id)?.amount ?? 0;
  };
  useEffect(() => {
    setBalance(
      (transactionAmount ?? 0) -
        Math.abs(MapAndCalcSum(selectedDetails.distribution ?? [], "amount"))
    );
  }, [initialAmounts, selectedDetails, transactionAmount]);
  return (
    <SnapTable>
      <table className={`ui celled ${className}`}>
        <thead>
          <tr>
            <th className="w-[30%]">Category</th>
            <th>Budget</th>
            <th className="flex items-center justify-end">Season</th>
            <th className="action-cell">Reconciled Amount</th>
          </tr>
        </thead>
        <tbody>
          {selectedDetails.distribution?.map((amtBudget) => {
            return (
              amtBudget && (
                <tr key={`distrib-table-${amtBudget.budget.id}`}>
                  <td>
                    <p>{amtBudget.budget.category.name}</p>
                  </td>
                  <td>
                    <p>{amtBudget.budget.description}</p>
                  </td>
                  <td className="flex items-center justify-end">
                    <p>{selectedDetails.season?.name}</p>
                  </td>
                  <td>
                    <SnapInput
                      _id={""}
                      _type="number"
                      _prepend="$"
                      value={`${(
                        getValue(amtBudget.budget.id ?? undefined) / 100
                      ).toFixed(2)}`}
                      onSnap-input-change={(e) => {
                        const distribution = selectedDetails.distribution?.find(
                          (target) => target.budget.id === amtBudget.budget.id
                        );
                        if (distribution) {
                          distribution.amount = ParseMoney(
                            e.detail.target.value
                          );
                          setSelectedDetails({ ...selectedDetails });
                        }
                      }}
                    />
                  </td>
                </tr>
              )
            );
          })}
          {selectedDetails.distribution && (
            <tr>
              <td></td>
              <td></td>
              <td className="pl-10">Balance Remaining</td>
              <td
                className={`flex justify-end items-center ${
                  balance !== 0 ? "text-red-500" : ""
                }`}
              >
                {FormatMoney(balance)}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </SnapTable>
  );
}

export default Table;
