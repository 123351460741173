"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPaymentConfiguration = exports.updateSetupIntent = exports.getSetupIntent = exports.confirmSetupIntent = exports.createSetupIntent = exports.getOrCreateCustomer = exports.updatePaymentIntent = exports.getPaymentIntent = exports.confirmPaymentIntent = exports.createPaymentIntent = void 0;
const utils_1 = require("./utils");
const paymentsEndpoint = `${utils_1.endpoint}/payment-intents`;
const customerEndpoint = `${utils_1.endpoint}/customers`;
const confirmPaymentIntentEndpoint = (id) => `${paymentsEndpoint}/${id}/confirm`;
const getPaymentIntentEndpoint = (id) => `${paymentsEndpoint}/${id}`;
const updatePaymentIntentEndpoint = (id) => `${paymentsEndpoint}/${id}`;
const setupIntentsEndpoint = `${utils_1.endpoint}/setup-intents`;
const confirmSetupIntentEndpoint = (id) => `${setupIntentsEndpoint}/${id}/confirm`;
const getSetupIntentEndpoint = (id) => `${setupIntentsEndpoint}/${id}`;
const updateSetupIntentEndpoint = (id) => `${setupIntentsEndpoint}/${id}`;
const paymentConfigurationEndpoint = (unitId) => `${utils_1.endpoint}/payment-configurations/${unitId}`;
async function createPaymentIntent(data, opts) {
    const payload = (0, utils_1.createPaymentIntentDataToPayload)(data);
    const resp = await fetch(paymentsEndpoint, {
        method: 'post',
        body: JSON.stringify(payload),
        ...(0, utils_1.stripeEnvHeader)(opts),
    });
    return resp.json();
}
exports.createPaymentIntent = createPaymentIntent;
async function confirmPaymentIntent(id, { idempotencyKey, returnUrl }, opts) {
    const resp = await fetch(confirmPaymentIntentEndpoint(id), {
        method: 'post',
        body: JSON.stringify({
            idempotencyKey: `${idempotencyKey}-confirm`,
            return_url: returnUrl,
        }),
        ...(0, utils_1.stripeEnvHeader)(opts),
    });
    return resp.json();
}
exports.confirmPaymentIntent = confirmPaymentIntent;
async function getPaymentIntent(id, opts) {
    const resp = await fetch(getPaymentIntentEndpoint(id), {
        method: 'GET',
        ...(0, utils_1.stripeEnvHeader)(opts),
    });
    return resp.json();
}
exports.getPaymentIntent = getPaymentIntent;
async function updatePaymentIntent(id, { customer, idempotencyKey, paymentMethod, snapAmount, totalAmount, }, opts) {
    const data = {
        customer,
        idempotencyKey: `${idempotencyKey}-update`,
        payment_method: paymentMethod,
    };
    if (totalAmount) {
        data.amount = totalAmount;
    }
    if (snapAmount) {
        data.application_fee_amount = snapAmount;
    }
    const resp = await fetch(updatePaymentIntentEndpoint(id), {
        method: 'put',
        body: JSON.stringify(data),
        ...(0, utils_1.stripeEnvHeader)(opts),
    });
    return resp.json();
}
exports.updatePaymentIntent = updatePaymentIntent;
async function getOrCreateCustomer(data, opts) {
    const resp = await fetch(customerEndpoint, {
        method: 'post',
        body: JSON.stringify(data),
        ...(0, utils_1.stripeEnvHeader)(opts),
    });
    return resp.json();
}
exports.getOrCreateCustomer = getOrCreateCustomer;
async function createSetupIntent(data, opts) {
    const payload = (0, utils_1.createSetupIntentDataToPayload)(data);
    const resp = await fetch(setupIntentsEndpoint, {
        method: 'post',
        body: JSON.stringify(payload),
        ...(0, utils_1.stripeEnvHeader)(opts),
    });
    return resp.json();
}
exports.createSetupIntent = createSetupIntent;
async function confirmSetupIntent(id, { idempotencyKey, returnUrl }, opts) {
    const resp = await fetch(confirmSetupIntentEndpoint(id), {
        method: 'post',
        body: JSON.stringify({
            idempotencyKey: `${idempotencyKey}-confirm`,
            return_url: returnUrl,
        }),
        ...(0, utils_1.stripeEnvHeader)(opts),
    });
    return resp.json();
}
exports.confirmSetupIntent = confirmSetupIntent;
async function getSetupIntent(id, opts) {
    const resp = await fetch(getSetupIntentEndpoint(id), {
        method: 'GET',
        ...(0, utils_1.stripeEnvHeader)(opts),
    });
    return resp.json();
}
exports.getSetupIntent = getSetupIntent;
async function updateSetupIntent(id, { customer, paymentMethod, idempotencyKey }, opts) {
    const resp = await fetch(updateSetupIntentEndpoint(id), {
        method: 'put',
        body: JSON.stringify({
            payment_method: paymentMethod,
            customer,
            idempotencyKey: `${idempotencyKey}-update`,
        }),
        ...(0, utils_1.stripeEnvHeader)(opts),
    });
    return resp.json();
}
exports.updateSetupIntent = updateSetupIntent;
async function getPaymentConfiguration(unitId, opts) {
    const resp = await fetch(paymentConfigurationEndpoint(unitId), {
        method: 'get',
        ...(0, utils_1.stripeEnvHeader)(opts),
    });
    return resp.json();
}
exports.getPaymentConfiguration = getPaymentConfiguration;
