import { useState } from "react";

export default function usePathToNav() {
  const [pathToNav, setPathToNav] = useState("");

  return {
    pathToNav,
    setPathToNav,
  };
}
