import { ReactNode } from "react";
import Modal from "react-modal";
import { SnapButton, SnapIcon } from "suit";

if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}
type ModalTabOptions = {
  currentSelectedValue: number;
  tabs?: TabOptions[];
};
export enum BtnState {
  BASE,
  DISABLED,
  HIDDEN,
}
type TabOptions = {
  text: string;
  value?: any;
  onClick?: () => void;
};

export type BtnType = {
  text: string;
  onClick?: () => void;
  btnState?: BtnState;
  btnStyle?:
    | "danger"
    | "grouped"
    | "primary"
    | "secondary"
    | "success"
    | "tertiary"
    | "warning";
};

type ModalType = {
  children?: ReactNode;
  isOpen: boolean;
  toggle: () => void;
  title: string;
  containerStyle?: string;
  contentStyle?: string;
  btn1?: BtnType;
  btn2?: BtnType;
  header?: string;
  tabOptions?: ModalTabOptions;
  hasBackBtn?: BtnType;
  customOverlay?: string;
};

function ScrollableModal({
  children,
  isOpen,
  toggle,
  title,
  containerStyle,
  contentStyle,
  btn1,
  btn2,
  header,
  tabOptions,
  hasBackBtn,
  customOverlay = "15",
}: ModalType) {
  return (
    <Modal
      className={`w-full lg:max-w-[880px] relative top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 lg:-mt-8 ${
        containerStyle || ""
      }`}
      isOpen={isOpen}
      style={{
        content: {
          border: "none",
          outline: "none",
        },
        overlay: {
          zIndex: customOverlay,
          backgroundColor: "rgba(0,0,0,0.2)",
        },
      }}
      bodyOpenClassName={"ReactModal__Body--open"}
    >
      <div
        className={`modalContent h-[95vh] lg:max-h-[80vh] lg:h-full flex flex-col bg-white lg:rounded-lg overflow-hidden shadow-md ${contentStyle}`}
      >
        <div className="lg:rounded-t-lg flex-col bg-white border-b-2 shadow-sm">
          <div className="flex w-full px-6 py-2 items-center">
            {hasBackBtn && (
              <div
                className="flex mr-4 font-medium cursor-pointer"
                onClick={hasBackBtn.onClick}
              >
                <SnapIcon icon="arrow-left-line" color="#3B82F6" size="sm" />
                <p className="text-blue-600 ml-2">Back</p>
              </div>
            )}
            <p className="mr-auto font-bold text-base capitalize">{title}</p>
            <span
              className={`text-[#64748B] hover:text-gray-800 inline-block leading-none cursor-pointer`}
              onClick={toggle}
            >
              <SnapIcon icon={"x-solid"} />
            </span>
          </div>
          {header && (
            <div className="flex w-full px-6 border-t border-gray-100 py-2">
              <p className="font-medium">{header}</p>
            </div>
          )}
          <div className="lg:flex grid grid-cols-2 overflow-x-scroll w-full no-scroll-bar px-6 pt-2">
            {tabOptions &&
              tabOptions.tabs &&
              tabOptions.tabs.map((prop: TabOptions, idx: number) => {
                return (
                  <button
                    key={idx}
                    onClick={prop.onClick}
                    className={`${
                      tabOptions.currentSelectedValue === prop.value
                        ? "text-gray-800 border-solid border-gray-800 border-b-2"
                        : "text-gray-500"
                    } whitespace-nowrap mr-8 pb-4 font-medium inline-block overflow-clip text-ellipsis hover:text-gray-600`}
                  >
                    {prop.text}
                  </button>
                );
              })}
          </div>
        </div>
        <div className="overflow-auto h-full">{children}</div>
        {btn1 && (
          <div className="bg-white flex border-t border-gray-200 py-2 px-4 lg:justify-end lg:px-6 lg:py-3">
            {btn2 && btn2.btnState !== BtnState.HIDDEN && (
              <>
                <SnapButton
                  variant={btn2.btnStyle || "tertiary"}
                  onClick={btn2.onClick}
                  className="mr-2 w-full lg:hidden"
                  fullWidth
                  disabled={btn2.btnState === BtnState.DISABLED}
                >
                  {btn2.text}
                </SnapButton>
                <SnapButton
                  variant={btn2.btnStyle || "tertiary"}
                  onClick={btn2.onClick}
                  className="mr-2 hidden lg:inline-block"
                  disabled={btn2.btnState === BtnState.DISABLED}
                >
                  {btn2.text}
                </SnapButton>
              </>
            )}
            {btn1.btnState !== BtnState.HIDDEN && (
              <>
                <SnapButton
                  variant={btn1.btnStyle || "primary"}
                  onClick={btn1.onClick}
                  className="w-full lg:hidden"
                  fullWidth
                  disabled={btn1.btnState === BtnState.DISABLED}
                >
                  {btn1.text}
                </SnapButton>
                <SnapButton
                  variant={btn1.btnStyle || "primary"}
                  onClick={btn1.onClick}
                  className="hidden lg:inline-block"
                  disabled={btn1.btnState === BtnState.DISABLED}
                >
                  {btn1.text}
                </SnapButton>
              </>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ScrollableModal;
