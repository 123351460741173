import { gql } from "@apollo/client";

export const CREATE_TRANSACTION = gql`
  mutation spendTransactionsCreate($input: SpendTransactionInput!) {
    spendTransactionsCreate(input: $input) {
      transactionIdList
    }
  }
`;

export const RECONCILE_TRANSACTION = gql`
  mutation SpendTransactionReconcile($input: SpendTransactionReconcileInput!) {
    spendTransactionReconcile(input: $input) {
      id
    }
  }
`;
export const RECONCILE_TRANSACTION_V2 = gql`
  mutation SpendTransactionReconcileV2(
    $input: SpendTransactionReconcileV2Input!
  ) {
    spendTransactionReconcileV2(input: $input) {
      id
    }
  }
`;

export const TRANSACTION_NOTE_CREATE = gql`
  mutation SpendTransactionNoteCreate($input: SpendTranscationNoteInput!) {
    spendTransactionNoteCreate(input: $input) {
      id
    }
  }
`;

export const TRANSACTION_NOTE_UPDATE = gql`
  mutation SpendTransactionNoteUpdate(
    $spendTransactionNoteUpdateId: String!
    $input: SpendTranscationNoteInput!
  ) {
    spendTransactionNoteUpdate(
      id: $spendTransactionNoteUpdateId
      input: $input
    ) {
      id
    }
  }
`;

export const UPLOAD_ATTACHMENTS = gql`
  mutation SpendTransactionAttachmentCreate(
    $input: SpendTransactionAttachmentInput!
  ) {
    spendTransactionAttachmentCreate(input: $input) {
      id
    }
  }
`;

export const DELETE_ATTACHMENT = gql`
  mutation SpendTransactionAttachmentDelete($id: String!) {
    spendTransactionAttachmentDelete(id: $id) {
      id
    }
  }
`;
