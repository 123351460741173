import * as paypal from '@paypal/paypal-js';
import { V2 } from '@snap-mobile/payments-widget-client';
import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import type { PayPalButtonsComponentProps } from '@paypal/react-paypal-js/dist/types/types';
import { usePaymentsWidgetContext } from '../context';

// public credential, no need for 12fa etc.
const PAYPAL_CLIENT_ID =
  'AVkgdPCdacthVN5bxdasTawhOgJEpK6WgTK25y9JpYU-_enKjF49Xg1xiBbFur5fdBg2ywigARFrxvNY';

export type ExternalPaypalPaymentMethodProps = Omit<
  PayPalButtonsComponentProps,
  'createOrder' | 'onApprove'
> & {
  product: string;
  secondaryId?: string;
  onSuccess?: (data: V2.PaypalOrderConfirmResponse) => void;
};

/**
 * Props for `ExternalPaypalPaymentMethod` Component
 * @prop {string} product - The identifier for the product being purchased.
 * @prop {string} [secondaryId] - Optional secondary identifier that can be used in the payment process.
 * @prop {(data: V2.PaypalOrderConfirmResponse) => void} [onSuccess] - Optional callback function triggered after a successful payment.
 *                                           Receives the payment response data as an argument.
 * @prop {PayPalButtonsComponentProps} - Additional PayPal button properties, such as 'shippingPreference' and 'onCancel',
 *                                                      excluding 'createOrder' and 'onApprove', which are handled internally.
 *
 * This component provides a PayPal payment method for the payment widget. It integrates with PayPal's
 * React SDK to render PayPal buttons and handle the PayPal payment process.
 * The component checks the `selectedPaymentMethod` from `usePaymentsWidgetContext`.
 * When the selected payment method is 'external_paypal', the component renders the PayPal button using `PayPalScriptProvider`.
 *
 * Usage:
 *  <PaymentsWidgetProvider {...props}>
 *    <PaymentMethod
 *     onSuccess={(data) => console.log('Payment Successful', data)}
 *    >
 *       <ExternalPaypalPaymentMethod
 *        product="exampleProduct"
 *        onSuccess={(data) => console.log('Payment Successful', data)}
 *       />
 *       <SubmitButton />
 *    </PaymentMethod>
 * </PaymentsWidgetProvider>
 */
export const ExternalPaypalPaymentMethod = (
  props: ExternalPaypalPaymentMethodProps
) => {
  const { selectedPaymentMethod } = usePaymentsWidgetContext();

  if (selectedPaymentMethod !== 'external_paypal') {
    return null;
  }

  return (
    <div
      className="payments-widget paypal-widget"
      style={{
        fontFamily:
          'sans-serif !important; width: 100% !important; min-width: 100% !important',
      }}
    >
      <PayPalScriptProvider
        options={{
          clientId: PAYPAL_CLIENT_ID,
          vault: true,
          components: 'buttons',
          disableFunding: 'bancontact,blik,paylater,card',
        }}
      >
        <div style={{ marginLeft: '10%' }}>
          <InnerPaypalWidget {...props} />
        </div>
      </PayPalScriptProvider>
    </div>
  );
};

function InnerPaypalWidget(props: ExternalPaypalPaymentMethodProps) {
  const { product, secondaryId, onSuccess, style, ...buttonProps } = props;

  const [{ isPending }] = usePayPalScriptReducer();

  const { paymentData, setProcessing } = usePaymentsWidgetContext();

  const createOrder = async () => {
    const data: V2.CreatePaypalOrderParams = {
      externalPaymentId: paymentData.externalPaymentId,
      product: product || 'snap',
      secondaryId: secondaryId,
      snapAmount: paymentData.snapAmount,
      totalAmount: paymentData.totalAmount,
      returnUrl: paymentData.returnUrl ?? window.location.href,
      cancelUrl: paymentData.returnUrl ?? window.location.href,
    };
    const resp = await V2.paypalCreateOrder(data);
    return resp.id;
  };

  const onApprove = async (data: paypal.OnApproveData) => {
    setProcessing(true);
    const { orderID, ...rest } = data;
    const resp = await V2.paypalApproveOrder(orderID!, {...rest, externalPaymentId: paymentData.externalPaymentId});
    if (resp) {
      onSuccess?.(resp);
    }
    setProcessing(false);
  };

  if (isPending) {
    return <div className="loading">Loading...</div>;
  } else {
    return (
      <div style={{ marginTop: '5px' }}>
        <PayPalButtons
          {...buttonProps}
          createOrder={createOrder}
          onApprove={onApprove}
          style={{
            ...(style || {}),
            layout: 'vertical',
            tagline: false,
          }}
        />
      </div>
    );
  }
}
