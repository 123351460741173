import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import ToastContext from "context/toast-context";
import {
  SpendGuardianInvoice,
  useSpendInvoiceOptInOrOutMutation,
} from "graphql/generated";
import {
  GET_GUARDIAN_INVOICES,
  GUARDIAN_INVOICES_V2,
  INVOICE_AUTOPAY,
} from "graphql/queries/invoices";
import { useContextStrict } from "helpers/context-strict";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { getBalanceDue } from "helpers/invoices";
import useModal from "hooks/use-modal";
import { StopPaymentFormType } from "pages/dashboard/parent/ParentDashboard";
import ParentStopPaymentModal from "pages/dashboard/parent/ParentStopPaymentModal";
import ParentInvoiceChangeRequestModal from "pages/dashboard/parent/parent-invoice-change-request-modal";
import { useState } from "react";
import DataCard from "shared-components/data-card";
import { HorizontalValueStyle } from "shared-components/horizontal-label-value";
import { LabelValueObject } from "types/label-value-object";

type ParentInvoiceV2ItemProps = {
  playerDatas: SpendGuardianInvoice[] | undefined;
  toggleToast: () => void;
};

const ParentInvoiceItemV2 = ({ playerDatas }: ParentInvoiceV2ItemProps) => {
  const { setToastProps, toggleToast } = useContextStrict(ToastContext);
  const [optInOrOut] = useSpendInvoiceOptInOrOutMutation();
  const prepLeftData = (d: SpendGuardianInvoice) => {
    let status = d.status.replace("_", " ");
    const playerName = `${d.rosterName}`;
    const leftLabels: LabelValueObject[] = [];
    leftLabels.push({
      key: "Season",
      value: d.seasonName,
      valueStyle: HorizontalValueStyle.Text,
    });
    leftLabels.push({
      key: "Participant",
      value: playerName,
      valueStyle: HorizontalValueStyle.Text,
    });
    leftLabels.push({
      key: "Description",
      value: `${d.description}${d.isOptional ? " [Optional]" : ""}`,
      valueStyle: HorizontalValueStyle.Text,
    });
    if (d.paymentMethodSource) {
      leftLabels.push({
        key: "Method",
        value:
          d.paymentMethodSource === "ACH"
            ? "BANK"
            : d.paymentMethodSource || "n/a",
        valueStyle: HorizontalValueStyle.Text,
      });
    }
    leftLabels.push({
      key: "Status",
      value: status,
      valueStyle: HorizontalValueStyle.Badge,
    });
    leftLabels.push({
      key: "Note",
      value: d.note ?? "",
    });

    d.creditMemos?.forEach((memo) => {
      leftLabels.push({
        key: "[CREDIT]",
        value: memo.title,
      });
    });

    return leftLabels;
  };

  const prepRightData = (d: SpendGuardianInvoice) => {
    const rightLabels: LabelValueObject[] = [];
    // Do the logic for has warning here
    rightLabels.push({
      key: "Due Date",
      value: FormatDate(d?.dueDate!, DateFormatSupported.Numbers),
    });
    rightLabels.push({
      key: "Date Paid",
      value: FormatDate(d?.paidDate!, DateFormatSupported.Numbers),
    });
    rightLabels.push({ key: "Invoice Amount", value: FormatMoney(d?.amount!) });
    if (d.discountAmount !== 0) {
      rightLabels.push({
        key: "Discount Amount",
        value: FormatMoney(d.discountAmount),
      });
    }
    const bd = getBalanceDue(d);
    rightLabels.push({
      key: "Amount Due",
      value: FormatMoney(bd),
      hasWarning: false,
    });
    if (d?.isOptional && !d.paid) {
      const invoiceIsOptedOut =
        d.isOptional && !d.optedIn && d.optedOutAt != null;

      let op = "Undecided";
      if (d.optedIn) {
        op = "Opted In";
      } else if (invoiceIsOptedOut) {
        op = "Opted Out";
      }

      rightLabels.push({
        key: "Optional",
        value: op,
      });
    }
    return rightLabels;
  };

  const prepMenuOptions = (invoice: SpendGuardianInvoice) => {
    const menuOptions: DropdownMenuItem[] = [];

    const dueDate = invoice?.dueDate ? new Date(invoice.dueDate) : null;
    const now = new Date();

    // Conditions for "Request Change" option
    const shouldAddRequestChange =
      !invoice?.isAutoPayAuthorized ||
      (dueDate &&
        dueDate.getTime() > now.getTime() &&
        !invoice?.paid &&
        invoice?.optedIn) ||
      invoice?.paid ||
      invoice?.isRefunded ||
      invoice?.optedIn === false;

    // Conditions for "Stop Payment" option
    const shouldAddStopPayment =
      dueDate &&
      dueDate.getTime() > now.getTime() &&
      !invoice?.paid &&
      invoice?.optedIn &&
      invoice.authorizedAt != null;

    if (shouldAddRequestChange) {
      menuOptions.push({
        name: "Request Change",
        text: "Request Change",
        value: "request-change",
        selected: false,
      });
    }
    const invoiceIsOptedOut =
      !invoice.paid &&
      invoice.isOptional &&
      !invoice.optedIn &&
      invoice.optedOutAt != null;
    const invoiceCanOptOut =
      !invoice.paid && invoice.isOptional && invoice.optedOutAt == null;
    if (invoiceIsOptedOut) {
      menuOptions.push({
        name: "Opt In",
        text: "Opt In",
        value: "opt-in",
        selected: false,
      });
    }
    if (invoiceCanOptOut) {
      menuOptions.push({
        name: "Opt Out",
        text: "Opt Out",
        value: "opt-out",
        selected: false,
      });
    }

    if (shouldAddStopPayment) {
      menuOptions.push({
        name: "Stop Payment",
        text: "Stop Payment",
        value: "stop-payment",
        selected: false,
      });
    }

    return menuOptions;
  };

  const [selectedInvoiceData, setSelectedInvoiceData] = useState<
    SpendGuardianInvoice | undefined
  >();
  const { isOpen: isStopPaymentModalOpen, toggle: toggleStopPaymentModalOpen } =
    useModal();
  const [noteValue, setNoteValue] = useState("");
  const {
    isOpen: isInvoiceChangeRequestModalOpen,
    toggle: toggleInvoiceChangeRequestModalOpen,
  } = useModal();
  const handleToggleModal = (
    selectedMenuItem: string,
    invoice: SpendGuardianInvoice
  ) => {
    setSelectedInvoiceData(invoice);
    if (selectedMenuItem === "request-change") {
      toggleInvoiceChangeRequestModalOpen();
    } else if (selectedMenuItem === "stop-payment") {
      toggleStopPaymentModalOpen();
    } else if (selectedMenuItem === "opt-out") {
      optInOrOut({
        variables: {
          input: {
            invoiceId: invoice.id,
            optedIn: false,
          },
        },
        refetchQueries: [
          {
            query: GET_GUARDIAN_INVOICES,
            fetchPolicy: "network-only",
          },
          {
            query: INVOICE_AUTOPAY,
            fetchPolicy: "network-only",
          },
          {
            query: GUARDIAN_INVOICES_V2,
            fetchPolicy: "network-only",
          },
        ],
      })
        .then(() => {
          setToastProps({
            type: "success",
            message: "You have opted out of this invoice.",
          });
          toggleToast();
        })
        .catch(() => {
          setToastProps({
            type: "danger",
            message:
              "Unable to opt out. Please contact support if this error continues.",
          });
          toggleToast();
        });
    } else if (selectedMenuItem === "opt-in") {
      optInOrOut({
        variables: {
          input: {
            invoiceId: invoice.id,
            optedIn: true,
          },
        },
        refetchQueries: [
          {
            query: GET_GUARDIAN_INVOICES,
            fetchPolicy: "network-only",
          },
          {
            query: INVOICE_AUTOPAY,
            fetchPolicy: "network-only",
          },
          {
            query: GUARDIAN_INVOICES_V2,
            fetchPolicy: "network-only",
          },
        ],
      })
        .then(() => {
          setToastProps({
            type: "success",
            message:
              "You have opted in for this invoice and the invoice is now awaiting approval.",
          });
          toggleToast();
        })
        .catch(() => {
          setToastProps({
            type: "danger",
            message:
              "Unable to opt in. Please contact support if this error continues.",
          });
          toggleToast();
        });
    }
  };

  if (!playerDatas) return null;

  return (
    <>
      {playerDatas.map((item, index) => (
        <DataCard
          key={item.id || `idx-${item.id}`}
          title={item.groupName}
          kvLeft={prepLeftData(item)}
          kvRight={prepRightData(item)}
          action={1}
          menuItems={prepMenuOptions(item)}
          menuClickListener={(e) => handleToggleModal(e, item)}
        />
      ))}

      <ParentInvoiceChangeRequestModal
        isOpen={isInvoiceChangeRequestModalOpen}
        toggle={toggleInvoiceChangeRequestModalOpen}
        formData={{
          invoice: selectedInvoiceData,
          message: noteValue,
          groupName: selectedInvoiceData?.groupName,
          seasonName: selectedInvoiceData?.seasonName,
        }}
      />

      <ParentStopPaymentModal
        isOpen={isStopPaymentModalOpen}
        toggle={toggleStopPaymentModalOpen}
        stopPaymentForm={{
          invoice: selectedInvoiceData,
          message: noteValue,
          groupName: selectedInvoiceData?.groupName,
          seasonName: selectedInvoiceData?.seasonName,
        }}
        setStopPaymentForm={(value) =>
          setNoteValue((value as StopPaymentFormType).message ?? "")
        }
      />
    </>
  );
};

export default ParentInvoiceItemV2;
