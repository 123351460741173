import {
  Maybe,
  SpendAccountLimitsResponse,
  SpendBudget,
  SpendGroup,
} from "graphql/generated";
import { SpendGroupType } from "hooks/use-program";
import { createContext, useContext } from "react";

const GroupContext = createContext<
  | {
      activeGroup: SpendGroupType | undefined;
      setAndStoreActiveGroup: (group: SpendGroupType | undefined) => void;
      participant: string;
      setParticipant: React.Dispatch<React.SetStateAction<string>>;
      getBudgets: (seasonId?: string, type?: string) => SpendBudget[];
      navigateGroupPage: (
        page:
          | "banking"
          | "collections"
          | "cards"
          | "budget"
          | "staff"
          | "settings"
          | string,
        groupId: string
      ) => void;
      switchActiveGroupId: (groupId: string) => boolean;
      isArchived: boolean;
      setIsArchived: React.Dispatch<React.SetStateAction<boolean>>;
      groups: SpendGroupType[];
      setGroups: React.Dispatch<React.SetStateAction<SpendGroupType[]>>;
      getGroupById: (id: string) => SpendGroupType | null;
      getGroup: () => SpendGroupType | undefined;
      getGroups: () => string[];
      loadedGroup: boolean;
      accountLimits: SpendAccountLimitsResponse | undefined;
      groupsWithSeasonsRoster: Maybe<Maybe<SpendGroup>[]> | undefined;
      setIncludeArchiveGroupsWithSeasons: React.Dispatch<
        React.SetStateAction<boolean>
      >;
    }
  | undefined
>(undefined);
export const useGroupContext = () => useContext(GroupContext);
export default GroupContext;
