import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";

export const dropDownMenuOptions: DropdownMenuItem[] = [
  {
    text: "Groups: A - Z",
    value: "name|asc",
    name: "Groups: A - Z",
  },
  {
    text: "Groups: Z - A",
    value: "name|desc",
    name: "Groups: Z - A",
  },
  {
    text: "Participants: High to Low",
    value: "playerCount|desc",
    name: "Participants: High to Low",
  },
  {
    text: "Participants: Low to High",
    value: "playerCount|asc",
    name: "Participants: Low to High",
  },
  {
    text: "Paid: High to Low",
    value: "paid|desc",
    name: "Paid: High to Low",
  },
  {
    text: "Paid: Low to High",
    value: "paid|asc",
    name: "Paid: Low to High",
  },
  {
    text: "Processing: High to Low",
    value: "processing|desc",
    name: "Processing: High to Low",
  },
  {
    text: "Processing: Low to High",
    value: "processing|asc",
    name: "Processing: Low to High",
  },
  {
    text: "Upcoming: High to Low",
    value: "upcoming|desc",
    name: "Upcoming: High to Low",
  },
  {
    text: "Upcoming: Low to High",
    value: "upcoming|asc",
    name: "Upcoming: Low to High",
  },
  {
    text: "Past Due: High to Low",
    value: "pastDue: High to Low",
    name: "Past Due: High to Low",
  },
  {
    text: "Past Due: Low to High",
    value: "pastDue: Low to High",
    name: "Past Due: Low to High",
  },
  {
    text: "Credited: High to Low",
    value: "credited|desc",
    name: "Credited: High to Low",
  },
  {
    text: "Credited: Low to High",
    value: "credited|asc",
    name: "Credited: Low to High",
  },
];
