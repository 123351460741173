import { SnapCheckboxButton } from "suit";

type ShowingResultsProps = {
  startingNumOfResults?: number;
  numOfResultsBeingDisplayed?: number;
  totalNumOfResults: number;
  hasCheckbox?: boolean;
  // TODO change type any to match mouse event
  checkboxAction?: (e: any) => void;
  hideCheckboxOnWeb?: boolean;
  className?: string;
  isNameBoxChecked?: boolean;
};

function ShowingResults({
  startingNumOfResults,
  numOfResultsBeingDisplayed,
  totalNumOfResults,
  hasCheckbox,
  checkboxAction,
  hideCheckboxOnWeb,
  className = "",
  isNameBoxChecked,
}: ShowingResultsProps) {
  return (
    <div className={`flex text-sm py-4 lg:py-6 ${className}`}>
      {hasCheckbox && checkboxAction && (
        <SnapCheckboxButton
          checked={isNameBoxChecked}
          onClick={(e) => checkboxAction(e)}
          className={`${hideCheckboxOnWeb ? "lg:hidden" : ""} items-start`}
        />
      )}
      <div className="flex font-medium">
        <p className="mr-1">{startingNumOfResults}</p>
        <p className="mr-1">to</p>
        <p className="mr-1">{numOfResultsBeingDisplayed}</p>
        <p className="mr-1">of</p>
        <p className="mr-1">{totalNumOfResults}</p>
        <p className="mr-1">Results</p>
      </div>
    </div>
  );
}

export default ShowingResults;
