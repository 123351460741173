"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class FEConfig {
    constructor() {
        const { env, paymentsApiUrl } = FEConfig.getEnv();
        this.env = env;
        this.paymentsApiUrl = paymentsApiUrl;
        console.log(`Payments Widget Environment: ${this.env}`);
    }
    static getEnv() {
        let hostname = 'localhost';
        if (typeof window !== 'undefined') {
            hostname = window.location.hostname;
        }
        if (FEConfig.PROD_REGEX.test(hostname)) {
            return FEConfig.PROD;
        }
        if (FEConfig.STAGING_REGEX.test(hostname)) {
            return FEConfig.STAGING;
        }
        if (FEConfig.DEV_REGEX.test(hostname)) {
            return FEConfig.DEV;
        }
        if (FEConfig.LOCAL_REGEX.test(hostname)) {
            return FEConfig.LOCAL;
        }
        throw new Error(`invalid hostname: ${hostname}`);
    }
}
FEConfig.DEV_API = 'https://payments-api.dev.snap.app/widget/v2';
FEConfig.LOCAL = {
    env: 'local',
    paymentsApiUrl: 'http://localhost:8080/widget/v2',
};
FEConfig.DEV = {
    env: 'dev',
    paymentsApiUrl: FEConfig.DEV_API,
};
FEConfig.STAGING = {
    env: 'staging',
    paymentsApiUrl: 'https://payments-api.staging.snap.app/widget/v2',
};
FEConfig.PROD = {
    env: 'prod',
    paymentsApiUrl: 'https://payments-api.snap.app/widget/v2',
};
FEConfig.LOCAL_REGEX = /(localhost|(\.ngrok(\.io|-free\.app)$))/;
FEConfig.DEV_REGEX = /^[a-z-]+\.dev\.snap\.app$/;
FEConfig.STAGING_REGEX = /^[a-z-]+\.staging\.snap\.app$/;
FEConfig.PROD_REGEX = /^[a-z-]+\.snap\.app$/;
const conf = new FEConfig();
exports.default = conf;
