import { useLazyQuery } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import ProgramContext from "context/program-context";
import UserContext from "context/user-context";
import {
  ExportFile,
  SpendBankTransaction,
  TransactionReportFilterEnum,
  useSpendTransactionsFilteredQuery,
} from "graphql/generated";
import { EXPORT_TRANSACTIONS } from "graphql/queries/transactions";
import { TransactionFilterOptions } from "helpers/dummy-data";
import { downloadFile } from "helpers/export-csv";
import { resetFilters } from "helpers/reset-filters";
import { updateFilterCount } from "helpers/update-filter-count";
import { updateFilterSelection } from "helpers/update-filter-selection";
import useModal from "hooks/use-modal";
import useToast from "hooks/use-toast";
import { useContext, useEffect, useState } from "react";
import DisplayFilterOptions from "shared-components/display-filter-options";
import Divider from "shared-components/divider";
import { LoadingBackdrop } from "shared-components/loading-backdrop";
import CustomModal from "shared-components/modal";
import ShowingResults from "shared-components/showing-results";
import Sort from "shared-components/sort";
import ToastMessage from "shared-components/toast-message";
import { SnapIcon, SnapIndicator } from "suit";
import { Filters } from "types/filter-types";
import { ITEMS_PER_PAGE } from "../../../constants";
import TransactionListItem from "./transaction-list-item";
import GroupContext from "context/group-context";
import { useSpendPagination } from "hooks/use-spend-pagination";
import { ArchiveName } from "helpers/archive-name";
import { isNullOrEmpty } from "helpers/null-or-empty";

function Transactions() {
  const { sort, toggleSort, toggleMobileSort } = useSpendPagination();
  const ActiveProgram = useContext(ProgramContext);
  const Groups = useContext(GroupContext);
  const User = useContext(UserContext);
  const { isOpen, toggle } = useModal();
  const { isToastOpen, toggleToast, ...toast } = useToast();

  const [transactions, setTransactions] = useState<
    SpendBankTransaction[] | undefined
  >(undefined);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedFilterCount, setSelectedFilterCount] = useState(0);
  const [activeFilterCount, setActiveFilterCount] = useState(0);
  const [transactionFilters, setTransactionFilters] = useState<Filters[]>(
    TransactionFilterOptions
  );
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [accOps, setAccOps] = useState<{ name: string; id: string }[]>([]);
  const [page, setPage] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState<
    { by: TransactionReportFilterEnum; value: string }[]
  >([]);
  const [exporting, setExporting] = useState(false);

  const [exportTransactions, { loading: exportLoading, data: exportData }] =
    useLazyQuery(EXPORT_TRANSACTIONS);

  const { loading: loadingTransactions, data: transactionData } =
    useSpendTransactionsFilteredQuery({
      variables: {
        pagination: {
          limit: ITEMS_PER_PAGE,
          offset: page * ITEMS_PER_PAGE,
        },
        disableCache: true,
        allAccounts: User?.isGroupLevel()
          ? false
          : appliedFilters.every(
              (filter) =>
                !filter.value.startsWith("spdorg_") &&
                !filter.value.startsWith("spdgrp_")
            ),
        filters: User?.isGroupLevel()
          ? [
              ...appliedFilters,
              {
                by: TransactionReportFilterEnum.GroupIdOrgId,
                value: Groups?.getGroup()?.id ?? "",
              },
            ]
          : appliedFilters,
        sort,
      },
    });

  useEffect(() => {
    if (exportData && exportData.spendTransactionsExport) {
      exportToCsv(exportData.spendTransactionsExport);
    }
  }, [exportLoading, exportData]);

  useEffect(() => {
    if (
      !loadingTransactions &&
      transactionData &&
      transactionData.spendTransactionsFiltered
    ) {
      Groups?.setIncludeArchiveGroupsWithSeasons(true);
      handleFilterPrep();
      setTotalTransactions(
        transactionData.spendTransactionsFiltered.count ?? 0
      );
      setTransactions(
        transactionData.spendTransactionsFiltered
          .transactions as SpendBankTransaction[]
      );
    }
    // eslint-disable-next-line
  }, [
    loadingTransactions,
    transactionData,
    ActiveProgram,
    Groups?.groupsWithSeasonsRoster,
  ]);

  const exportToCsv = (contentToExport: ExportFile) => {
    setExporting(true);
    let content = atob(contentToExport?.content ?? "");
    downloadFile({
      data: [content].join("\n"),
      fileName: contentToExport.fileName,
      fileType: "text/csv",
    });
    setExporting(false);
  };

  const handleFilterPrep = () => {
    let program = {
      name:
        ActiveProgram?.organization?.nickname ||
        ActiveProgram?.organization?.legalName ||
        "",
      id: ActiveProgram?.organization?.id ?? "",
    };
    let group: { name: string; id: string }[] | undefined = [];
    group = Groups?.groupsWithSeasonsRoster?.map((group) => {
      return {
        name: ArchiveName(group?.name ?? "", group?.isArchived ?? false),
        id: group?.id ?? "",
      };
    });

    let accounts = [program, ...(group ?? [])];
    if (User?.isGroupLevel()) {
      accounts = group ?? [];
    }

    setAccOps(accounts ?? []);
    let accountOptions = accounts.map((acc) => {
      return {
        name: acc.name,
        selected: false,
      };
    });

    const sortedAccountOptions = accountOptions.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );

    let allAccountOptions: Filters = {
      filterName: TransactionFilterOptions[0].filterName,
      type: TransactionFilterOptions[0].type,
      filterOptions: sortedAccountOptions,
      placeholder: TransactionFilterOptions[0].placeholder,
      className: TransactionFilterOptions[0].className,
    };
    TransactionFilterOptions.splice(0, 1, allAccountOptions);
  };

  const handleFilters = (
    selectedFilterName: string,
    details: SnapSelectMenuOption[],
    date?: string
  ) => {
    const updatedFilters = updateFilterSelection(
      transactionFilters,
      selectedFilterName,
      details,
      date
    );
    const activeFilterCount = updateFilterCount(updatedFilters);
    setSelectedFilterCount(activeFilterCount || 0);
    setTransactionFilters([...updatedFilters]);
  };

  const handleResetFilters = () => {
    const defaultFilters = resetFilters(transactionFilters);
    setTransactionFilters(defaultFilters);
    setSelectedFilterCount(0);
    setStartDate("");
    setEndDate("");
  };

  const handleGetSelectedFilters = (
    filters: Filters[] = transactionFilters
  ) => {
    let Filters: { by: TransactionReportFilterEnum; value: string }[] = [];
    let SelectedAccountOption = filters[0].filterOptions?.find(
      (option) => option.selected
    )?.name;
    let AccountId = accOps.find(
      (acc) => acc.name === SelectedAccountOption
    )?.id;
    let SelectedTransactionTypeOption = filters[1].filterOptions?.filter(
      (option) => option.selected
    );

    let SelectedStatusOption = filters[2].filterOptions?.filter(
      (option) => option.selected
    );

    let SelectedPaymentMethod = filters[5].filterOptions?.find(
      (option) => option.selected
    )?.name;
    if (AccountId) {
      Filters.push({
        by: TransactionReportFilterEnum.GroupIdOrgId,
        value: AccountId,
      });
    }
    if (SelectedTransactionTypeOption?.length) {
      let type = SelectedTransactionTypeOption.map(
        (option) => option.value
      ).join(" | ");
      Filters.push({
        by: TransactionReportFilterEnum.Type,
        value: type,
      });
    }
    if (SelectedStatusOption?.length) {
      let status = SelectedStatusOption.map((option) =>
        option.name.toLowerCase()
      ).join(" | ");
      Filters.push({
        by: TransactionReportFilterEnum.Status,
        value: status,
      });
    }
    if (!isNullOrEmpty(startDate)) {
      Filters.push({
        by: TransactionReportFilterEnum.DateAfter,
        value: startDate,
      });
    }
    if (!isNullOrEmpty(endDate)) {
      Filters.push({
        by: TransactionReportFilterEnum.DateBefore,
        value: endDate,
      });
    }
    if (SelectedPaymentMethod) {
      Filters.push({
        by: TransactionReportFilterEnum.Method,
        value: SelectedPaymentMethod,
      });
    }
    return Filters;
  };

  const handleApplyFilters = () => {
    const SelectedFilters = handleGetSelectedFilters();
    if (
      !isNullOrEmpty(startDate) &&
      !isNullOrEmpty(endDate) &&
      new Date(startDate).getTime() > new Date(endDate).getTime()
    ) {
      let tempFilters = [...transactionFilters];
      let filterOption: Filters = {
        ...transactionFilters[4],
        hasError: true,
      };
      tempFilters.splice(4, 1, filterOption);
      setTransactionFilters(tempFilters);
      toast.setToastProps({
        message: "End Date cannot be before Start Date",
        type: "danger",
      });
      toggleToast();
    } else {
      if (transactionFilters[4].hasError) {
        let tempFilters = [...transactionFilters];
        let filterOption: Filters = {
          ...transactionFilters[4],
          hasError: false,
        };
        tempFilters.splice(4, 1, filterOption);
        setTransactionFilters(tempFilters);
      }
      setAppliedFilters([...SelectedFilters]);
      setActiveFilterCount(SelectedFilters.length);
      setPage(0);
      toggle();
    }
  };
  const handleExport = () => {
    setExporting(true);
    exportTransactions({
      variables: {
        allAccounts: User?.isGroupLevel()
          ? false
          : appliedFilters.every(
              (filter) =>
                !filter.value.startsWith("spdorg_") &&
                !filter.value.startsWith("spdgrp_")
            ),
        filters: User?.isGroupLevel()
          ? [
              ...appliedFilters,
              {
                by: TransactionReportFilterEnum.GroupIdOrgId,
                value: Groups?.getGroup()?.id ?? "",
              },
            ]
          : appliedFilters,
      },
    }).then(() => setExporting(false));
  };

  const generateSortList = () => {
    const list = [
      { label: "Date", value: "date" },
      { label: "Type", value: "type" },
      { label: "Method", value: "method" },
      { label: "Status", value: "status" },
      { label: "Gross", value: "gross" },
      { label: "Net", value: "net" },
    ];
    const res = list.flatMap((item: any) => {
      return [
        {
          name: `${item.label} A-Z`,
          text: `${item.label} A-Z`,
          value: `${item.value} ASC`,
          selected: sort?.field === item.value && sort?.order === "ASC",
        },
        {
          name: `${item.label} Z-A`,
          text: `${item.label} Z-A`,
          value: `${item.value} DESC`,
          selected: sort?.field === item.value && sort?.order === "DESC",
        },
      ];
    });
    return res;
  };

  return (
    <div className="wrapper">
      <div className="card">
        <div className="flex" onClick={toggle}>
          <SnapIcon icon="filter-solid" size="sm" color="#3B82F6" />
          <p className="px-2 text-blue-600 font-bold cursor-pointer">
            All Filters
          </p>
          {activeFilterCount !== 0 && (
            <SnapIndicator
              text={activeFilterCount}
              color="blue"
              className="self-center"
            />
          )}
        </div>
        <Divider isVisibleOnMobile />
        {!exporting ? (
          <>
            <p className="mt-4 lg:font-semibold font-medium text-lg">
              Transactions
            </p>
            <div className="flex justify-between">
              <ShowingResults
                totalNumOfResults={totalTransactions}
                numOfResultsBeingDisplayed={
                  totalTransactions <= 10
                    ? transactions?.length
                    : ITEMS_PER_PAGE * page + 10 >= totalTransactions
                    ? totalTransactions
                    : ITEMS_PER_PAGE * page + 10
                }
                startingNumOfResults={
                  transactions?.length === 0 ? 0 : ITEMS_PER_PAGE * page + 1
                }
              />
              <div
                className="flex text-blue-600 font-bold lg:text-base sm:text-sm cursor-pointer"
                onClick={handleExport}
              >
                <SnapIcon icon="download-solid" size="sm"></SnapIcon>
                <p className="self-center">Export as CSV</p>
              </div>
            </div>
            <Divider isVisibleOnMobile className="lg:hidden mt-1 mb-5" />
            <Sort
              selectedSortOption={""}
              options={generateSortList()}
              handleSort={(e) => {
                let selectedArr = e.value.split(" ");
                toggleMobileSort(selectedArr[0], selectedArr[1]);
              }}
            />
            {!loadingTransactions &&
            transactions &&
            transactions.length === 0 ? (
              <div>
                <p>No Transactions</p>
              </div>
            ) : (
              <TransactionListItem
                listItems={transactions}
                totalTransactions={totalTransactions}
                page={page}
                setPage={setPage}
                isNotesReadOnly={false}
                loadingTransactions={loadingTransactions}
                exporting={exporting}
                toggleSort={toggleSort}
                currentSort={sort}
              />
            )}
          </>
        ) : (
          <LoadingBackdrop loading={exporting} />
        )}
      </div>

      <CustomModal
        isOpen={isOpen}
        toggle={toggle}
        title="Filters"
        btn1={{ text: "Apply Filters", onClick: handleApplyFilters }}
        btn2={{
          text: "Cancel",
          onClick: toggle,
        }}
        customStyle="lg:h-[520px]"
      >
        <div className="modal-card relative">
          <div className="flex">
            <div className="flex mr-auto text-sm">
              <p className="font-medium mr-1">Selected </p>
              <p className="font-semibold"> {selectedFilterCount} filters</p>
            </div>
            <p
              className=" text-base font-bold text-blue-600 cursor-pointer"
              onClick={handleResetFilters}
            >
              Reset Filters
            </p>
          </div>
          <DisplayFilterOptions
            filters={transactionFilters}
            isDate={true}
            handleFilters={handleFilters}
            handleResetFilters={handleResetFilters}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
          {isToastOpen && (
            <ToastMessage
              message={toast.message}
              isToastOpen={isToastOpen}
              toggleToast={toggleToast}
              type={toast.type}
              className={`lg:top-[125%] top-[100%]`}
            />
          )}
        </div>
      </CustomModal>
    </div>
  );
}

export default Transactions;
