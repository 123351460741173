import { useQuery } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import {
  Maybe,
  SpendBankTransactionDetail,
  SpendBudget,
  SpendCategory,
} from "graphql/generated";
import { GET_BUDGETS_SIMPLE } from "graphql/queries/budgets";
import { FormatMoney } from "helpers/format-money";
import React, { useEffect, useState } from "react";
import Divider from "shared-components/divider";
import TableRowLabelValue from "shared-components/table-row-label-value";
import VerticalLabelValue from "shared-components/vertical-label-value";
import { SnapAlert, SnapAlertTitle, SnapSelectMenu, SnapTable } from "suit";

type ChangeIncomeBudgetItemProps = {
  selectedTran: SpendBankTransactionDetail | undefined;
  idxTran: number;
  setSelectedBudgetItem: React.Dispatch<React.SetStateAction<string>>;
};

type TransactionDetails = {
  description: string;
  participantName: string;
  groupName: string;
  seasonName: string;
  seasonId: string;
  amount: number;
  budgetItemName: string;
  budgetItemId: string;
  categoryName: string;
  categoryId: string;
  groupId: string;
};

function ChangeIncomeBudgetItem({
  selectedTran,
  idxTran,
  setSelectedBudgetItem,
}: ChangeIncomeBudgetItemProps) {
  const [transactionDetail, setTransactionDetail] =
    useState<TransactionDetails>();
  const [dropdownBudget, setDropdownBudget] = useState<SnapSelectMenuOption[]>(
    []
  );

  const { loading: loadingBudgets, data: dataBudgets } = useQuery(
    GET_BUDGETS_SIMPLE,
    {
      variables: {
        groupId: transactionDetail?.groupId ?? "",
        seasonId: transactionDetail?.seasonId,
      },
    }
  );

  useEffect(() => {
    if (dataBudgets && dataBudgets.spendBudgetsSummary) {
      let selectOptions: SnapSelectMenuOption[] = [];
      dataBudgets.spendBudgetsSummary.summaryByCategory.forEach(
        (cat: SpendCategory) => {
          cat?.budgets?.forEach((budget: Maybe<SpendBudget>) => {
            if (cat.type === "income") {
              selectOptions.push({
                name: `${cat?.name} | ${budget?.description}`,
                value: `${budget?.id}`,
                selected: false,
              });
            }
          });
        }
      );
      setDropdownBudget(selectOptions);
    }
  }, [loadingBudgets, dataBudgets]);
  useEffect(() => {
    if (
      selectedTran?.reconciliation?.invoiceTransactions &&
      selectedTran.reconciliation.reconciledTo
    ) {
      let foundItem = selectedTran.reconciliation?.reconciledTo?.find(
        (RT) =>
          RT?.invoiceId ===
          selectedTran.reconciliation?.invoiceTransactions?.at(idxTran)
            ?.invoiceId
      );
      let transactionDetail: TransactionDetails = {
        description: foundItem?.description ?? "",
        participantName:
          selectedTran.reconciliation?.invoiceTransactions?.at(idxTran)
            ?.rosterName ?? "",
        groupName: foundItem?.groupName ?? "",
        seasonName: foundItem?.seasonName ?? "",
        seasonId: foundItem?.seasonId ?? "",
        amount: foundItem?.amount ?? 0,
        budgetItemName: foundItem?.budgetName ?? "",
        budgetItemId: foundItem?.budgetName ?? "",
        categoryName: foundItem?.categoryName ?? "",
        categoryId: foundItem?.categoryId ?? "",
        groupId: foundItem?.groupId ?? "",
      };
      setTransactionDetail(transactionDetail);
    }
  }, [selectedTran, idxTran]);
  return (
    <>
      <p className="pl-4 pt-2">Change Income Budget Item</p>
      <Divider className="border-2 border-gray-200 mt-2" isVisibleOnMobile />
      <div className="modal-card">
        <SnapAlert type="info">
          <SnapAlertTitle>
            Review the reconciliation for this invoice and change if there is an
            error.
          </SnapAlertTitle>
        </SnapAlert>
      </div>
      <div className="px-4 py-4">
        <p className="flex text-lg font-semibold">Transaction Details</p>
        <table className="w-full mt-4 lg:mt-0">
          <tbody>
            <TableRowLabelValue
              label={"Group"}
              value={transactionDetail?.groupName}
            />
            <TableRowLabelValue
              label={"Season"}
              value={transactionDetail?.seasonName}
            />
            <TableRowLabelValue
              label={"Description"}
              value={transactionDetail?.description}
            />
            <TableRowLabelValue
              label={"Participant"}
              value={transactionDetail?.participantName}
            />
            <TableRowLabelValue
              label={"Budget Item"}
              value={transactionDetail?.budgetItemName}
            />
            <TableRowLabelValue
              label={"Amount"}
              value={FormatMoney(transactionDetail?.amount)}
            />
          </tbody>
        </table>
      </div>
      <Divider isVisibleOnMobile className="lg:hidden" />
      {/* Mobile View */}
      <div className="lg:hidden border border-gray-200 p-4 mt-4 rounded-lg mx-4">
        <p className="font-semibold">{transactionDetail?.categoryName}</p>
        <Divider isVisibleOnMobile className="mt-2 mb-2" />
        <VerticalLabelValue
          label={"Budget Item"}
          value={transactionDetail?.budgetItemName}
          customContainerStyle="flex-col"
        />
        <VerticalLabelValue
          label={"Season"}
          value={transactionDetail?.seasonName}
          customContainerStyle="flex-col"
        />
        <VerticalLabelValue
          label={"Related Invoices"}
          value={transactionDetail?.description}
          customContainerStyle="flex-col"
        />
        <VerticalLabelValue
          label={"Reconciled Amount"}
          value={FormatMoney(transactionDetail?.amount)}
          customContainerStyle="flex-col"
        />
        <Divider isVisibleOnMobile className="mt-2 mb-2" />

        <SnapSelectMenu
          placeholder={transactionDetail?.budgetItemName}
          modalTitle="Income Budget Item"
          selectMenuOptions={dropdownBudget}
          onSnap-select-menu-updated={({ detail }) => {
            let selectedBudgetitem = detail.find(
              (selected) => selected.selected
            );
            setSelectedBudgetItem(selectedBudgetitem?.value ?? "");
          }}
        />
      </div>
      {/* Web View */}
      <div className="mx-4">
        <SnapTable>
          <table className="ui celled lg:table hidden">
            <thead>
              <tr>
                <th className="text-center">Category</th>
                <th className="text-center">Related Invoices</th>
                <th className="text-center">Season</th>
                <th className="text-center">Reconciled Amount</th>
                <th className="text-center">Budget Item</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">
                  {transactionDetail?.categoryName}
                </td>
                <td className="text-center">
                  {transactionDetail?.description}
                </td>
                <td className="text-center">{transactionDetail?.seasonName}</td>
                <td className="text-center py-5">
                  {FormatMoney(transactionDetail?.amount)}
                </td>
                <td className="text-center">
                  <SnapSelectMenu
                    className=" w-52"
                    placeholder={transactionDetail?.budgetItemName}
                    selectMenuOptions={dropdownBudget}
                    onSnap-select-menu-updated={({ detail }) => {
                      let selectedBudgetitem = detail.find(
                        (selected) => selected.selected
                      );
                      setSelectedBudgetItem(selectedBudgetitem?.value ?? "");
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </SnapTable>
      </div>
    </>
  );
}

export default ChangeIncomeBudgetItem;
