import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import DisplayContext from "context/display-context";
import { SpendCounterparty } from "graphql/generated";
import useModal from "hooks/use-modal";
import { useContext, useState } from "react";
import DataCard from "shared-components/data-card";
import { SnapButton, SnapDropDown, SnapPagination, SnapTable } from "suit";
import { LabelValueObject } from "types/label-value-object";
import DeleteCounterparty from "./delete-counterparty";
import { ITEMS_PER_PAGE } from "../../../constants";

type ManageCounterpartiesProps = {
  toggleAddPayee: () => void;
  counterparties: SpendCounterparty[];
  type: "Program" | "Group";
};

function ManageCounterparties({
  toggleAddPayee,
  counterparties,
  type,
}: ManageCounterpartiesProps) {
  const display = useContext(DisplayContext);
  const { isOpen: isDeleteOpen, toggle: toggleDelete } = useModal();

  const [page, setPage] = useState(0);
  const [selectedCounterparty, setSelectedCounterparty] =
    useState<SpendCounterparty>();
  const [options] = useState<DropdownMenuItem[]>([
    {
      text: "Delete",
      name: "Delete",
      value: "Delete",
    },
  ]);

  const prepLeftData = (counterparty: SpendCounterparty) => {
    const leftLVData: LabelValueObject[] = [];
    const customContainerStyle = "flex flex-col mt-4";
    const className = "ml-0";

    leftLVData.push({
      key: "Bank",
      value: counterparty.bankName,
      customContainerStyle,
      className,
    });
    leftLVData.push({
      key: "Account Number",
      value: `**${counterparty.accountLastFour}`,
      customContainerStyle,
      className,
    });
    leftLVData.push({
      key: "Routing Nuber",
      value: counterparty.routingNumber,
      customContainerStyle,
      className,
    });

    return leftLVData;
  };

  return (
    <>
      <div className="flex justify-end">
        <SnapButton
          variant="primary"
          className="lg:w-36 w-full mb-6 lg:mb-0"
          size="sm"
          fullWidth
          onClick={toggleAddPayee}
        >
          Add Payee
        </SnapButton>
      </div>
      {display?.isDesktop && (
        <SnapTable>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Bank</th>
                <th>Routing #</th>
                <th>Account #</th>
                <th>{/* Action */}</th>
              </tr>
            </thead>
            <tbody>
              {counterparties
                .slice(
                  page * ITEMS_PER_PAGE,
                  page * ITEMS_PER_PAGE + ITEMS_PER_PAGE
                )
                .map((counterparty) => {
                  return (
                    <tr key={counterparty.id}>
                      <td>{counterparty.name}</td>
                      <td>{counterparty.bankName}</td>
                      <td>{counterparty.routingNumber}</td>
                      <td className="flex justify-center items-center">
                        **{counterparty.accountLastFour}
                      </td>
                      <td>
                        <SnapDropDown
                          minimal
                          options={options}
                          onSnap-dropdown-item-selected={(e) => {
                            if (e.detail.value === "Delete") {
                              toggleDelete();
                            }
                            setSelectedCounterparty(counterparty);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </SnapTable>
      )}
      {display?.isMobile &&
        counterparties
          .slice(page * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE + ITEMS_PER_PAGE)
          .map((counterparty) => {
            return (
              <DataCard
                title={counterparty.name}
                kvLeft={prepLeftData(counterparty)}
                kvRight={[]}
                action={1}
                menuItems={options}
                key={counterparty.id}
              />
            );
          })}
      <SnapPagination
        itemCount={counterparties.length}
        currentPage={page}
        pageSize={ITEMS_PER_PAGE}
        onSnap-pagination-page-changed={(e) => {
          setPage(e.detail);
        }}
      />

      {isDeleteOpen && (
        <DeleteCounterparty
          isDeleteOpen={isDeleteOpen}
          toggleDelete={toggleDelete}
          selectedCounterparty={selectedCounterparty}
          type={type}
        />
      )}
    </>
  );
}

export default ManageCounterparties;
