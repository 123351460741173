import FixedModal, {
  BtnState,
} from "shared-components/fixed-modal/fixed-modal";

type RemoveStaffProps = {
  removeModal: boolean;
  removeToggle: () => void;
  handleRemoveStaff: () => void;
  isBtnActive: boolean;
};

function RemoveStaff({
  removeModal,
  removeToggle,
  handleRemoveStaff,
  isBtnActive,
}: RemoveStaffProps) {
  return (
    <FixedModal
      isOpen={removeModal}
      toggle={removeToggle}
      title={"Do you want to remove the user from this group?"}
      icon={"exclamation-solid"}
      iconColor="#FEE2E2"
      btn1={{
        text: "Remove Staff",
        btnStyle: "danger",
        onClick: handleRemoveStaff,
        btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
      }}
      btn2={{ text: "Cancel", onClick: removeToggle, btnStyle: "tertiary" }}
    >
      <p className=" text-gray-500 text-sm text-center">
        The user’s accounts will not be deleted but permissions for this group
        will be removed.
      </p>
    </FixedModal>
  );
}

export default RemoveStaff;
