type GroupFiltersProps = {
  setIsActionSheetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFilters: string[];
  isActionSheetOpen: boolean;
  fitlerOptions: string[];
  setSelectedFilters: React.Dispatch<React.SetStateAction<string[]>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

function GroupFilters({
  selectedFilters,
  fitlerOptions,
  setSelectedFilters,
  setPage,
}: GroupFiltersProps) {
  const activeStyle = "bg-blue-100";
  return (
    <div className="lg:flex mt-6 lg:mt-4 grid grid-cols-3">
      {fitlerOptions.map((option, idx) => {
        return (
          <div
            key={idx}
            className={`first:ml-0 last:mr-0 mx-2 border-2 border-gray-200 py-2 px-6 rounded-xl cursor-pointer flex justify-center ${
              selectedFilters.includes(option) && activeStyle
            } `}
            onClick={() => {
              const tempArr = [...selectedFilters];
              if (tempArr.includes(option)) {
                tempArr.splice(tempArr.indexOf(option), 1);
              } else {
                tempArr.push(option);
              }
              setSelectedFilters(tempArr);
              setPage(0);
            }}
          >
            {option}
          </div>
        );
      })}
    </div>
  );
}

export default GroupFilters;
