import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import { getBadgeColor } from "helpers/status-color";
import { SnapBadge, SnapDropDown, SnapTable } from "suit";
import {
  SpendInvite,
  SpendSortInput,
  SpendSortOrderEnum,
} from "graphql/generated";
import { cardStatusToText, inviteStatusToText } from "helpers/status-text";
import SortIcon from "shared-components/table/sort-icon";
import { SpinnerContainer } from "shared-components/spinner";

type StaffTableProps = {
  staffInfo: SpendInvite[];
  menuItems: DropdownMenuItem[];
  menuClickListener: (idxOfStaffToDelete: number) => void;
  sort: SpendSortInput | undefined;
  setSort: (sort: SpendSortInput) => void;
  canEditStaff: boolean;
  isArchived: boolean;
  loading?: boolean;
};

function StaffTable({
  staffInfo,
  menuItems,
  menuClickListener,
  sort,
  setSort,
  canEditStaff,
  isArchived,
  loading,
}: StaffTableProps) {
  const toggleSort = (sortField: string) => {
    setSort({
      field: sortField,
      order:
        sortField !== sort?.field
          ? SpendSortOrderEnum.Asc
          : sort?.order === SpendSortOrderEnum.Asc
          ? SpendSortOrderEnum.Desc
          : SpendSortOrderEnum.Asc,
    });
  };

  return (
    <SnapTable>
      <table className="ui celled lg:table hidden">
        <thead>
          <tr>
            <th>
              Staff
              <SortIcon
                field={"name"}
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            <th>
              Email
              <SortIcon
                field={"email"}
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            <th className="text-cell">
              Role
              <SortIcon
                field={"type"}
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            <th className="icon-cell w-72">
              Status
              <SortIcon
                field={"status"}
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            <th className="text-cell">
              Debit Card
              <SortIcon
                field={"status"}
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            {canEditStaff && <th className="action-cell"></th>}
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            staffInfo.map((staffMember, idx) => {
              let memberStatus = inviteStatusToText(
                staffMember.status || "",
                "N/A"
              );

              return (
                <tr key={idx}>
                  <td className="first-cell-header" data-label="Staff">
                    <p className="font-medium">
                      {staffMember.firstName} {staffMember.lastName}
                    </p>
                  </td>
                  <td data-label="Email">{staffMember.email}</td>
                  <td data-label="Role" className="text-cell capitalize">
                    {staffMember.type
                      ? staffMember.type.split("_").join(" ")
                      : "N/A"}
                  </td>
                  <td data-label="Status" className="icon-cell">
                    <SnapBadge
                      color={isArchived ? "gray" : getBadgeColor(memberStatus)}
                    >
                      {memberStatus}
                    </SnapBadge>
                  </td>
                  <td data-label="Debit Card" className="text-cell">
                    {staffMember?.debitCards &&
                      staffMember?.debitCards?.length > 0 && (
                        <ul className="text-sm leading-5 font-medium">
                          {staffMember.debitCards?.map((card, idx) => (
                            <li key={card?.id || idx}>
                              {cardStatusToText(card?.status || "") +
                                ` (${card?.lastFour})`}
                            </li>
                          ))}
                        </ul>
                      )}
                  </td>
                  {canEditStaff && (
                    <td className="action-cell">
                      <SnapDropDown
                        id="mobile-menu"
                        className="hidden lg:block"
                        left-hang="false"
                        size="lg"
                        minimal
                        search-field="false"
                        modal-type="fullscreen"
                        options={menuItems}
                        onSnap-dropdown-item-selected={() =>
                          menuClickListener(idx)
                        }
                      />
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <SpinnerContainer loading={loading} inTable colSpan={5} />
          )}
        </tbody>
      </table>
    </SnapTable>
  );
}

export default StaffTable;
