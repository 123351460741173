import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import { SpendPaymentSchedule } from "graphql/generated";
import { getDatePickerValue, setDatePickerValue } from "helpers/date-picker";
import { FormatMoney } from "helpers/format-money";
import React from "react";
import DatePicker from "shared-components/date-picker";
import { SnapIcon, SnapSelectMenu, SnapTable } from "suit";

type ImportTableProps = {
  selectedPaymentSchedule: (SpendPaymentSchedule & { deleted: boolean })[];
  categories: SnapSelectMenuOption[];
  setSelectedPaymentSchedule: React.Dispatch<
    React.SetStateAction<(SpendPaymentSchedule & { deleted: boolean })[]>
  >;
  CalculateFee: (amountDue: number) => number;
};

function ImportTable({
  selectedPaymentSchedule,
  categories,
  setSelectedPaymentSchedule,
  CalculateFee,
}: ImportTableProps) {
  return (
    <SnapTable>
      <table className="ui lg:table hidden table-fixed">
        <thead>
          <tr>
            <th className="w-[15%]">Description</th>
            <th className="w-[15%]">Due Date</th>
            <th className="w-[25%]">Reconcile To</th>
            <th>Collecting</th>
            <th align="center">Fees</th>
            <th className="w-[15%]">Guardian Pays</th>
            <th align="center">Optional?</th>
            <th className="w-[4%]"></th>
          </tr>
        </thead>
        <tbody>
          {selectedPaymentSchedule.map((paymentSchedule, idx) => {
            let options: SnapSelectMenuOption[] = categories.map(
              (cat: SnapSelectMenuOption) => {
                return {
                  ...cat,
                  selected: paymentSchedule.budgetItemId === cat.value,
                };
              }
            );

            return (
              <tr key={`${paymentSchedule.id}_${idx}`}>
                <td className="py-4">
                  <p>{paymentSchedule.description}</p>
                  <p className="text-xs">{paymentSchedule.note}</p>
                </td>
                <td>
                  <DatePicker
                    label={""}
                    date={getDatePickerValue(paymentSchedule.dueDate ?? "")}
                    setDate={(e) => {
                      const newDate = setDatePickerValue(e);
                      selectedPaymentSchedule.splice(idx, 1, {
                        ...paymentSchedule,
                        dueDate: newDate,
                      });
                      setSelectedPaymentSchedule([...selectedPaymentSchedule]);
                    }}
                  />
                </td>
                <td>
                  <SnapSelectMenu
                    placeholder="Select a budget"
                    selectMenuOptions={[...options]}
                    onSnap-select-menu-updated={(e) => {
                      const selected = e.detail.find(
                        (option) => option.selected
                      );
                      selectedPaymentSchedule.splice(idx, 1, {
                        ...paymentSchedule,
                        budgetItemId: selected?.value,
                      });
                      setSelectedPaymentSchedule([...selectedPaymentSchedule]);
                    }}
                  />
                </td>
                <td align="center">
                  <p className="text-green-600">
                    {FormatMoney(paymentSchedule.amountDue || 0)}
                  </p>
                </td>
                <td align="center">
                  {FormatMoney(CalculateFee(paymentSchedule.amountDue ?? 0))}
                </td>
                <td align="center">
                  {FormatMoney(
                    (paymentSchedule.amountDue ?? 0) -
                      CalculateFee(paymentSchedule.amountDue ?? 0)
                  )}
                </td>
                <td align="center">
                  {paymentSchedule.isOptional ? "Optional" : "Required"}
                </td>
                <td>
                  <SnapIcon icon="trash-solid" color="#2563EB" />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </SnapTable>
  );
}

export default ImportTable;
