import Divider from "shared-components/divider";
import CustomModal, { BtnType } from "shared-components/modal";
import { SnapInput } from "suit";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import {
  SpendGuardianInvoice,
  SpendInvoice,
  useSpendInvoiceChangeRequestMutation,
} from "../../../graphql/generated";

import UserBankMethod from "../../../shared-components/banking/user-bank-method";
import UserCardMethod from "../../../shared-components/banking/user-card-method";
import { useState } from "react";
import ToastMessage from "shared-components/toast-message";
import useToast from "hooks/use-toast";
import TableRowLabelValue from "shared-components/table-row-label-value";

export type ParentRequestInvoiceChangeRequestForm = {
  invoice?: SpendInvoice | SpendGuardianInvoice;
  message?: string;
  groupName?: string;
  seasonName?: string;
};

type ParentRequestInvoiceChangeRequestModalProps = {
  isOpen: boolean;
  toggle: () => void;
  formData: ParentRequestInvoiceChangeRequestForm;
};

function ParentRequestInvoiceChangeRequestModal({
  isOpen,
  toggle,
  formData,
}: ParentRequestInvoiceChangeRequestModalProps) {
  const [requestChangeInvoice] = useSpendInvoiceChangeRequestMutation({
    onCompleted: ({ spendInvoiceChangeRequest }) => {
      if (spendInvoiceChangeRequest?.id) {
        toggle();
      }
    },
  });
  const { isToastOpen, toggleToast, ...toast } = useToast();
  const [noteError, setNoteError] = useState(false);
  const [note, setNote] = useState(formData.message);
  const btn1: BtnType = {
    text: "Send Request",
    onClick: () => {
      if (note?.trim() === "") {
        toast.setToastProps({ message: "Please add a note", type: "danger" });
        toggleToast();
        setNoteError(true);
      } else {
        requestChangeInvoice({
          variables: {
            input: {
              invoiceId: formData.invoice?.id ?? "",
              note: formData.message ?? "",
            },
          },
        });
      }
    },
  };
  const btn2: BtnType = {
    text: "Cancel",
    btnStyle: "tertiary",
    onClick: toggle,
  };
  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      title={"Request to Change Invoice"}
      customStyle={"max-w-[1082px]"}
      btn1={btn1}
      btn2={btn2}
    >
      {formData.invoice && (
        <div className="modal-card">
          {isToastOpen && (
            <ToastMessage
              message={toast.message}
              isToastOpen={isToastOpen}
              toggleToast={toggleToast}
              type={toast.type}
              className="lg:w-[40%]"
            />
          )}

          <div className="flex flex-col">
            <p className="mb-3">Details</p>
            <table className="w-full">
              <tbody>
                <TableRowLabelValue
                  label={"Group"}
                  value={formData.groupName}
                />
                <TableRowLabelValue
                  label={"Season"}
                  value={formData.seasonName}
                />
                <TableRowLabelValue
                  label={"Description"}
                  value={formData.invoice.description}
                />
                <TableRowLabelValue
                  label={"Due Date"}
                  value={FormatDate(
                    formData.invoice?.dueDate ?? "",
                    DateFormatSupported.Numbers
                  )}
                />
                {formData.invoice.paymentMethodSource === "ACH" && (
                  <UserBankMethod />
                )}
                {formData.invoice.paymentMethodSource === "CARD" && (
                  <>
                    {formData.invoice.paymentMethodId ? (
                      <UserCardMethod
                        accountIds={[formData.invoice.paymentMethodId]}
                      />
                    ) : (
                      <TableRowLabelValue
                        label={"Method"}
                        value={"No Card on File"}
                      />
                    )}
                  </>
                )}
                <TableRowLabelValue
                  label={"Amount Due"}
                  value={FormatMoney(formData.invoice?.balanceDue ?? 0)}
                />
              </tbody>
            </table>
          </div>
          <Divider isVisibleOnMobile className="mb-3" />

          <SnapInput
            label="Add a note for the program organizer stating your change request."
            _id={"message"}
            placeholder={"Please Write a Message"}
            textarea={true}
            error={noteError}
            helpText={
              'For example, "Please split into two invoices, a week apart" or "I need another week to pay."'
            }
            onBlur={(e) => {
              formData.message = e.target.value;
              setNote(e.target.value);
              setNoteError(false);
            }}
          />
        </div>
      )}
    </CustomModal>
  );
}

export default ParentRequestInvoiceChangeRequestModal;
